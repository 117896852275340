import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconMedical = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 3C9.05742 3 6.4418 4.4168 4.8 6.6H7.24687C8.54414 5.46094 10.2316 4.8 12 4.8C13.7684 4.8 15.4559 5.46094 16.7531 6.6H19.2C17.5582 4.4168 14.9426 3 12 3ZM4.20938 7.5C3.44297 9.88359 3 12.6469 3 15.6C3 17.4914 3.17227 19.309 3.50625 21H20.4937C20.8277 19.309 21 17.4914 21 15.6C21 12.6469 20.557 9.88359 19.7906 7.5H4.20938ZM12 10.2C14.4855 10.2 16.5 12.2145 16.5 14.7C16.5 17.1855 14.4855 19.2 12 19.2C9.51445 19.2 7.5 17.1855 7.5 14.7C7.5 12.2145 9.51445 10.2 12 10.2ZM11.1 12V13.8H9.3V15.6H11.1V17.4H12.9V15.6H14.7V13.8H12.9V12H11.1Z"
        fill={fill}
      />
    </svg>
  );
};

IconMedical.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconMedical;
