import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconChild = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g clipPath="url(#clip0)">
        <path
          d="M14.2 13.934s2.103 2.106 2.108 2.109c.67.675.497 1.863-.139 2.51l-2.933 3.056a1.092 1.092 0 0 1-1.547-1.533l1.86-1.862-1.821-1.807 2.472-2.473zm-6.54 0s-2.1 2.106-2.1 2.109c-.676.675-.503 1.863.126 2.51l2.946 3.056c1.129 1.13 2.643-.439 1.549-1.533l-1.858-1.862 1.808-1.807-2.471-2.473zm3.343-7.832a3.05 3.05 0 1 0 0-6.102 3.052 3.052 0 1 0 0 6.102zm3.306 6.61l.044-2.08 2.537 2.365a1.082 1.082 0 0 0 1.526-.051 1.081 1.081 0 0 0-.052-1.527s-2.908-3.16-3.326-3.528c-.773-.686-1.908-1.075-3.748-1.075h-.578c-1.842 0-2.975.389-3.751 1.074-.417.368-3.325 3.529-3.325 3.529a1.081 1.081 0 0 0-.051 1.527c.212.226.5.34.789.34.265 0 .53-.092.738-.289l2.535-2.364.045 2.08h6.617z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconChild.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconChild;
