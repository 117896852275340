import React from "react";
import { NavLink } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import * as ROUTES from "constants/routes";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  toolbarTitle: {
    flex: 1
  }
});

const Nav = props => {
  const { classes } = props;

  return (
    <AppBar position="static" color="default" className={classes.appBar}>
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          XCurve
        </Typography>
        <Button>
          <NavLink to={ROUTES.ABOUT}>About</NavLink>
        </Button>
        <Button>
          <NavLink to={ROUTES.LANDING}>Consumers</NavLink>
        </Button>
        <Button>
          <NavLink to={ROUTES.LANDING}>Advisors</NavLink>
        </Button>
        <Button>
          <NavLink to={ROUTES.LANDING}>Contact Us</NavLink>
        </Button>
        <Button
          color="primary"
          variant="outlined"
          component={NavLink}
          to={ROUTES.LOG_IN}
        >
          Login
        </Button>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps({ firebase: { auth, profile } }) {
  return {
    auth,
    profile
  };
}
export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  withStyles(styles)
)(withFirebase(Nav));
