import React from "react";

import { colors } from "constants/styles";

const IconVehicle = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
      fill="none"
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77638 4.96181C5.21119 3.78298 6.33455 3 7.59101 3H16.409C17.6654 3 18.7888 3.78299 19.2236 4.96181L20.3443 8H21.5C21.7761 8 22 8.22386 22 8.5V9.5C22 9.77614 21.7761 10 21.5 10H20.9954C20.9985 10.0448 21 10.0898 21 10.1349V19C21 19.5523 20.5523 20 20 20H19C18.4477 20 18 19.5523 18 19V18H6V19C6 19.5523 5.55228 20 5 20H4C3.44772 20 3 19.5523 3 19V10.1349C3 10.0898 3.00152 10.0448 3.00455 10H2.5C2.22386 10 2 9.77614 2 9.5V8.5C2 8.22386 2.22386 8 2.5 8H3.65574L4.77638 4.96181ZM4 12.5C4 12.2239 4.22386 12 4.5 12H7C8.10457 12 9 12.8954 9 14V14.5C9 14.7761 8.77614 15 8.5 15H6C4.89543 15 4 14.1046 4 13V12.5ZM19.5 12C19.7761 12 20 12.2239 20 12.5V13C20 14.1046 19.1046 15 18 15H15.5C15.2239 15 15 14.7761 15 14.5V14C15 12.8954 15.8954 12 17 12H19.5ZM7.98806 5C7.10485 5 6.3262 5.57934 6.07241 6.4253L5.3862 8.71265C5.19372 9.35426 5.67417 10 6.34403 10H17.656C18.3258 10 18.8063 9.35426 18.6138 8.71265L17.9276 6.4253C17.6738 5.57934 16.8952 5 16.0119 5H7.98806Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconVehicle;
