import {
  addAdditionalConfiguration,
  optionsTaxCategory,
  prepareState
} from "../AssetsCommon/_formScheme";
import { GLOBAL_TYPE_USE_ASSETS as QueryGlobalUse } from "../HouseholdAssetsUse/formScheme";
import { RIGHT_HOUSEHOLD_MONEY_IN } from "../../constants/rights";
import { MAX_AGE } from "../../constants";

// export const GLOBAL_COLLECTION = "transactions";
export const GLOBAL_COLLECTION = "userAssets";
export const GLOBAL_TYPE_COLLECTION = "in";
export const GLOBAL_TYPE_USE_ASSETS = "household-money-in";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_MONEY_IN;
export const RIGHT_LEVEL = `${RIGHT_HOUSEHOLD_MONEY_IN}-can`;

const ENTITY_SALARIES = "salaries";
const ENTITY_RENT = "rents";
const ENTITY_OTHER = "other";
const ENTITY_GENERAL = "general";

export const ENTITIES = {
  ENTITY_SALARIES,
  ENTITY_RENT,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

const ENTITY_ADD_401K = "401k";
const ENTITY_ADD_SPENDING_ACCOUNT = "spendingAccount";

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.MONEY_IN.FORM_GROUP.TITLE": {
    title: "Income Type",
    ENTITIES: {
      ENTITY_SALARIES,
      ENTITY_RENT,
      ENTITY_OTHER,
      ENTITY_GENERAL
    }
  }
};

const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];
export const optionsOccurrenceValuesOnceEvery = {
  ONCE: "once",
  EVERY: "every"
};

const optionsOccurs = [
  {
    name: "Once",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.OCCURS.TYPES.ONCE.LABEL",
    value: optionsOccurrenceValuesOnceEvery.ONCE
  },
  {
    name: "Every",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.OCCURS.TYPES.EVERY.LABEL",
    value: optionsOccurrenceValuesOnceEvery.EVERY
  }
];

export const optionsOccursEveryValue = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  HALF_YEAR: "half_year",
  YEAR: "year"
};

export const optionsOccursEveryValueMult = {
  day: 365,
  week: 52,
  month: 12,
  quarter: 4,
  half_year: 2,
  year: 1
};

export const optionsOccursEvery = [
  {
    name: "Day",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.DAY.TYPES.LABEL",
    multiplier: 365,
    value: "day"
  },
  {
    name: "Week",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.WEEK.TYPES.LABEL",
    multiplier: 52,
    value: "week"
  },
  {
    name: "Month",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.MONTH.TYPES.LABEL",
    multiplier: 12,
    value: "month"
  },
  {
    name: "Quarter",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.QUARTER.TYPES.LABEL",
    multiplier: 4,
    value: "quarter"
  },
  {
    name: "Half a Year",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.HALF_YEAR.TYPES.LABEL",
    multiplier: 2,
    value: "half_year"
  },
  {
    name: "Year",
    nameI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.YEAR.TYPES.LABEL",
    multiplier: 1,
    value: "year"
  }
];

const repeatableSchemeGeneral = {
  description: {
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_SALARIES]: {
    icon: "IconSalaries",
    color: "green",
    title: "Salaries & Wages",
    titleI18n: "HOUSEHOLD.MONEY_IN.TILE.SALARIES_WAGES"
  },

  [ENTITIES.ENTITY_RENT]: {
    icon: "IconMoneyOwned",
    color: "green",
    title: "Rent",
    titleI18n: "HOUSEHOLD.MONEY_IN.TILE.RENT"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.MONEY_IN.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "General Assets",
    titleI18n: "HOUSEHOLD.MONEY_IN.TILE.GENERAL"
  }
};

export const ENTITIES_ADDITIONAL = {
  [ENTITY_SALARIES]: {
    [ENTITY_ADD_401K]: {
      "401kAccount": {
        isAdditionalAsset: "401k",
        additionalAssetField: "description",
        optionsCustomAsset: "savings",

        isEmptyHelper: "Please create first 401K account",
        isEmptyHelperI18n: "HOUSEHOLD.MONEY_IN.FORM.401K_ACCOUNT.EMPTY.LABEL",

        isSelect: true,

        label: "401K Account",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.401K_ACCOUNT.LABEL"
      },

      group401K: {
        isOnlyShow: true,
        isAdditional: true,
        isFullWidth: true,
        isGroupLabel: true,

        condition: [
          { fieldDebt: "401kAccount", isFilled: true, inner: ENTITY_ADD_401K }
        ],
        label: "401K Total",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.401K_TOTAL.LABEL"
      },

      self: {
        formWidth: "50",
        isAdditional: true,

        condition: [
          {
            field: "401kAccount",
            isFilled: true,
            inner: ENTITY_ADD_401K
          }
        ],

        isAmount: true,
        isNumber: true,
        label: "Self",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.401K_ACCOUNT.SELF.LABEL"
      },
      employer: {
        formWidth: "50",
        isAdditional: true,

        condition: [
          {
            field: "401kAccount",
            isFilled: true,
            inner: ENTITY_ADD_401K
          }
        ],
        isAmount: true,
        isNumber: true,
        label: "Employer",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.401K_ACCOUNT.EMPLOYER.LABEL"
      }
    },

    [ENTITY_ADD_SPENDING_ACCOUNT]: {
      groupSpending: {
        isOnlyShow: true,
        isAdditional: true,
        isFullWidth: true,
        isGroupLabel: true,

        condition: [
          {
            fieldDebt: "hsa",
            isFilled: true,
            inner: ENTITY_ADD_SPENDING_ACCOUNT
          },
          {
            fieldDebt: "fsa",
            isFilled: true,
            inner: ENTITY_ADD_SPENDING_ACCOUNT
          },
          {
            fieldDebt: "dca",
            isFilled: true,
            inner: ENTITY_ADD_SPENDING_ACCOUNT
          }
        ],
        label: "Spending Account",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.SPENDING_ACCOUNT.LABEL"
      },
      hsa: {
        isThirdWidth: true,
        isAdditional: true,
        isAmount: true,
        isNumber: true,
        label: "HSA",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.SPENDING_ACCOUNT.HSA.LABEL"
      },
      fsa: {
        isThirdWidth: true,
        isAdditional: true,
        isAmount: true,
        isNumber: true,
        label: "FSA",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.SPENDING_ACCOUNT.FSA.LABEL"
      },
      dca: {
        isThirdWidth: true,
        isAdditional: true,
        isAmount: true,
        isNumber: true,
        label: "DCA",
        labelI18n: "HOUSEHOLD.MONEY_IN.FORM.SPENDING_ACCOUNT.DCA.LABEL"
      }
    }
  }
};

export const formScheme = {
  [ENTITIES.ENTITY_SALARIES]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.SALARIES.DESCRIPTION.LABEL"
    },
    employeeName: {
      isSubTitle: true,
      isAdditional: true,
      isRequired: true,
      isSelect: true,
      options: optionsOwner,
      optionsCustom: "custom-members-adult",
      label: "Employee Name",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.EMPLOYEE_NAME.LABEL"
    },
    employerName: {
      isAdditional: true,
      isRequired: true,
      label: "Employer Name",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.EMPLOYER_NAME.LABEL"
    },
    earningsSalary: {
      isOnlyShow: true,
      isMain: true,

      calculate: { isEarningsIn: true },
      label: "Earning",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.EARNING.LABEL"
    },
    baseSalary: {
      isAdditional: true,

      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Base Salary",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.BASE_SALARY.LABEL"
    },
    variable: {
      isAdditional: true,
      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Variable",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.VARIABLE.LABEL"
    },
    otherCompensation: {
      isAdditional: true,
      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Other Compensation",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.OTHER_COMPENSATION.LABEL"
    },
    anticipatedEndAge: {
      isRequired: true,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isGeneralMemberAge: true },
      label: "Anticipated End Age",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.ANTICIPATED_END_AGE.LABEL"
    }
  },
  [ENTITIES.ENTITY_RENT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.RENT.DESCRIPTION.LABEL"
    },
    asset: {
      isSubTitle: true,
      isAdditional: true,

      isAdditionalAsset: "homes",
      additionalAssetField: "description",
      optionsCustomAsset: "homes",

      query: {
        globalAsset: QueryGlobalUse,
        entityType: "home"
      },
      isRequired: true,
      isSelect: true,
      // optionsCustomAsset: "homes",
      // optionsCustom: "custom-assets-external",
      label: "Asset",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.USE_ASSET.LABEL"
    },
    annualRent: {
      isOnlyShow: true,
      isMain: true,
      calculate: { isAnnualRate: true },
      label: "Annual Rent",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.ANNUAL_RENT.LABEL"
    },
    monthlyRent: {
      isAdditional: true,

      isRequired: true,
      isNumber: true,
      isAmount: true,
      label: "Monthly Rent",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.MONTHLY_RENT.LABEL"
    },
    averageVacancy: {
      isAdditional: true,

      isRequired: true,
      after: "DAYS",
      isNumber: true,
      label: "Average Vacancy",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.AVERAGE_VACANCY.LABEL"
    }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.OTHER.DESCRIPTION.LABEL",
      isSubTitle: true
    },
    amount: {
      isMainCondition: {
        condition: [
          {
            field: "occurs",
            value: "once"
          }
        ]
      },

      isRequired: true,
      isAmount: true,
      label: "Amount",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.AMOUNT.LABEL"
    },
    amountAnnual: {
      isOnlyShow: true,
      isMainCondition: {
        condition: [
          {
            field: "occurs",
            value: "every"
          }
        ]
      },
      calculate: { isAnnualMoneyInOther: true },
      labelCard: "Annual Amount",
      labelI18nCard: "HOUSEHOLD.MONEY_IN.FORM.ANNUAL_AMOUNT.LABEL"
    },
    occurs: {
      isAdditional: true,

      isRequired: true,
      isSelect: true,
      options: optionsOccurs,
      label: "Occurs",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.OCCURS.LABEL"
    },
    onDate: {
      isAdditional: true,

      condition: [
        {
          field: "occurs",
          value: "once"
        }
      ],
      isShowDate: "MMMM do, yyyy",
      isRequired: true,
      isDate: true,
      label: "On Date",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.ON_DATE.LABEL"
    },
    every: {
      // isAdditional: true,

      condition: [
        {
          field: "occurs",
          value: "every"
        }
      ],
      isRequired: true,
      isSelect: true,
      options: optionsOccursEvery,
      label: "Every",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.EVERY.LABEL"
    },
    taxCategory: {
      isAdditional: true,

      isRequired: true,
      isSelect: true,
      options: optionsTaxCategory,
      label: "Tax Category",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.LABEL"
    }
  },
  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.GENERAL.DESCRIPTION.LABEL"
    },
    value: {
      isMain: true,
      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Value",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.VALUE.LABEL"
    }
  }
};

addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  GLOBAL_TYPE_USE_ASSETS
);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.MONEY_IN",
        title: "Income"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.MONEY_IN.TITLE",
      title: "Money earned"
    },
    caption: {
      titleI18n: "HOUSEHOLD.MONEY_IN.CAPTION",
      title: "Add all your income sources to figure out your future earnings"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.MONEY_IN.EMPTY",
      title: "You have no assets,\n please add your first asset"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.MONEY_IN.H2",
      title: "Money in"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.MONEY_IN.TOAST.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.MONEY_IN.TOAST.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.MONEY_IN.TOAST.SAVED",
      title: "Asset Saved"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.MONEY_IN.SIDE_MENU_TITLE",
      title: "Add Assets"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.MONEY_IN.SIDE_MENU_TITLE.EDIT",
      title: "Edit Asset"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.MONEY_IN.FORM_GROUP.TITLE",
      title: "Asset Type"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.MONEY_IN.ASSET_TYPES.GENERAL_GROUP",
      title: "Group Of Assets",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Asset”",
      captionI18n: "HOUSEHOLD.MONEY_IN.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.MONEY_IN.FORM.VALUE.BUTTON.SAVE",
      title: "Save Asset"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.MONEY_IN.FORM.VALUE.BUTTON",
      title: "+ Add Asset"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.MONEY_IN.WHY.TITLE",
    textI18n: "HOUSEHOLD.MONEY_IN.WHY.ALL_TEXT"
  }
};
