import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import { compose } from "redux";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AssetCategoryCommon from "components/AssetsCommon/AssetCategoryCommon";
import AccessForbidden from "routeHandlers/AccessForbidden";

import {
  ENTITIES,
  ENTITIES_DEBT,
  ENTITIES_GROUPS,
  ENTITIES_ADDITIONAL,
  formScheme,
  designScheme,
  formsValidationScheme,
  formsInitialState,
  GLOBAL_TYPE_USE_ASSETS,
  GLOBAL_COLLECTION,
  RIGHT_LEVEL,
  RIGHT_SHARE,
  optionsRepaymentValues,
  translations
} from "./formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as GLOBAL_TYPE_ASSETS_USE,
  GLOBAL_COLLECTION_USE_ASSETS as COLLECTION_ASSETS_USE
} from "components/HouseholdAssetsUse/formScheme";
import {
  getNotDisabledEntities,
  itemsSorted
} from "../../store/selectors/firebase";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";
import {
  _helperCalcDebtRemainingAmortization,
  _helperCalcDebtRemainingInterest,
  _helperCalcDebtRemainingMonthly
} from "../_common/AssetCard";

const GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU = (
  <FormattedMessage
    id="DEBT.FORM.TOP.SHOW_CONTRIBUTION"
    defaultMessage="Show Contribution"
  />
);

export class HouseholdDebt extends Component {
  calculateSummary = () => {
    const { debts: assets } = this.props;

    if (!assets) {
      return null;
    }

    const summary = { total: 0 };

    Object.keys(designScheme).forEach(key => {
      summary[key] = 0;
    });

    assets.forEach(el => {
      if (el && !el.isDisabled && !el.isExpired && !el.isWhatIf && !el.isWhatIfTest) {
        let value = 0;

        if (el.entity === ENTITIES.ENTITY_GENERAL) {
          value = +el.loanAmount;
        }

        if (el.loanType === optionsRepaymentValues.AMORTIZATION) {
          value = +_helperCalcDebtRemainingAmortization(el);
        }
        if (el.loanType === optionsRepaymentValues.MONTHLY_AMOUNT) {
          value = +_helperCalcDebtRemainingMonthly(el);
        }
        if (el.loanType === optionsRepaymentValues.INTEREST_ONLY) {
          value = +_helperCalcDebtRemainingInterest(el);
        }

        summary[el.entity] += value;
        summary.total += value;
      }
    });

    return summary;
  };

  render() {
    const {
      auth,
      debts,
      profile,
      firestoreStatus,
      childs,
      useAssets,
      profileProvider
    } = this.props;

    if (
      !profile.isLoaded ||
      firestoreStatus.requesting.debts ||
      firestoreStatus.requesting.useAssets
    ) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareHousehold ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const isAccessEdit =
      isOwnProfile ||
      (this.props.rights && this.props.rights[RIGHT_LEVEL] === "1");

    const additionAssets = { homes: {}, vehicles: {}, others: {} };

    if (useAssets) {
      Object.keys(useAssets).forEach(assetKey => {
        const asset = useAssets[assetKey];

        if (asset) {
          if (asset.entity === "home") {
            additionAssets.homes[assetKey] = asset;
          }

          if (asset.entity === "vehicle") {
            additionAssets.vehicles[assetKey] = asset;
          }

          if (asset.entity === "other") {
            additionAssets.others[assetKey] = asset;
          }
        }
      });
    }

    return (
      <AssetCategoryCommon
        isDialog={this.props.isDialog}
        isAccessEdit={isAccessEdit}
        profile={profile}
        childs={childs}
        assets={debts}
        summaryData={this.calculateSummary()}
        formScheme={formScheme}
        auth={auth}
        ENTITIES={ENTITIES}
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES_DEBT={ENTITIES_DEBT}
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        designScheme={designScheme}
        formsInitialState={formsInitialState}
        formsValidationScheme={formsValidationScheme}
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={GLOBAL_COLLECTION}
        GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU={
          GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU
        }
        translations={translations}
        additionalAssets={additionAssets}
        updateFieldGlobalType={{
          debtToUse: {
            globalType: GLOBAL_TYPE_ASSETS_USE,
            collection: COLLECTION_ASSETS_USE,
            fieldId: "assetId",
            externalField: "debtId"
          }
        }}
      />
    );
  }
}

HouseholdDebt.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  debts: PropTypes.any
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { profileProvider }
}) {
  const rights = ordered && ordered.rights;

  return {
    auth,
    profile,
    profileProvider: profileProvider || auth.uid,
    debts: ordered && itemsSorted(ordered.debts),
    // useAssets: data && data.useAssets,
    useAssets: ordered && getNotDisabledEntities(ordered.useAssets), // TODO is only disabled?
    childs: ordered && ordered.childs,
    rights: rights && rights[0],
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: "userAssets",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_USE_ASSETS]
        ],
        storeAs: "debts",
        orderBy: ["entity", "asc"]
      },
      {
        collection: COLLECTION_ASSETS_USE,
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_ASSETS_USE]
        ],
        storeAs: "useAssets"
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(HouseholdDebt));
