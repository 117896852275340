import { prepareState, commonPrefixAfter } from "../AssetsCommon/_formScheme";
import { RIGHT_PLANS_GOALS } from "../../constants/rights";
import { MAX_AGE } from "../../constants";

export const GLOBAL_TYPE_USE_ASSETS = "goals";
export const GLOBAL_COLLECTION = "userAssets";
export const RIGHT_SHARE = RIGHT_PLANS_GOALS;
export const RIGHT_LEVEL = `${RIGHT_PLANS_GOALS}-can`;

const ENTITY_RETIREMENT = "retirement";
const ENTITY_HOME_PURCHASE = "homePurchase";
const ENTITY_LEGACY = "legacy";
const ENTITY_PROTECT_LOVED = "protectLoved";
const ENTITY_EDUCATION = "education";
const ENTITY_MAJOR_EXPANSE = "majorExpanse";
const ENTITY_OTHER = "other";
const ENTITY_GENERAL = "general";

export const ENTITIES = {
  ENTITY_RETIREMENT,
  ENTITY_HOME_PURCHASE,
  ENTITY_LEGACY,
  ENTITY_PROTECT_LOVED,
  ENTITY_EDUCATION,
  ENTITY_MAJOR_EXPANSE,
  // ENTITY_OTHER,
  // ENTITY_GENERAL
};

export const ENTITIES_GROUPS = {
  "PLANS.GOALS.FORM_GROUP.TITLE": {
    title: "Goal type",
    ENTITIES: {
      ...ENTITIES
    }
  }
};

const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];

const optionsRetirementStart = [
  {
    name: "Passive Annual Income",
    nameI18n: "PLANS.GOALS.FORM.RETIREMENT_START.TYPES.PASSIVE.LABEL",
    value: "passive"
  },
  {
    name: "At Age",
    nameI18n: "PLANS.GOALS.FORM.RETIREMENT_START.TYPES.AT_AGE.LABEL",
    value: "atAge"
  }
];

const repeatableSchemeGeneral = {
  description: {
    isHidden: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "PLANS.GOALS.FORM.DESCRIPTION.LABEL"
  },
  amount: {
    isMain: true,

    isAmount: true,
    isNumber: true,
    isRequired: true,
    label: "Amount",
    labelI18n: "PLANS.GOALS.FORM.AMOUNT.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_RETIREMENT]: {
    icon: "IconRetirement",
    iconBig: "IconBigRetirement",
    color: "green",
    title: "Retirement",
    titleI18n: "PLANS.GOALS.TILE.RETIREMENT",
    formTitle: "DASHBOARD.GOALS.FORM.RETIREMENT.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.RETIREMENT"
  },

  [ENTITIES.ENTITY_HOME_PURCHASE]: {
    icon: "IconHammer",
    iconBig: "IconBigHome",
    color: "green",
    title: "Home Purchase/Renovation",
    titleI18n: "PLANS.GOALS.TILE.HOME_PURCHASE_RENOVATION",
    formTitle: "DASHBOARD.GOALS.FORM.HOUSE.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.HOUSE"
    // title: "Home Purchase",
    // titleI18n: "PLANS.GOALS.TILE.HOME_PURCHASE"
  },

  [ENTITIES.ENTITY_LEGACY]: {
    icon: "IconPaycheque",
    iconBig: "IconBigBank",
    color: "green",
    title: "Legacy",
    titleI18n: "PLANS.GOALS.TILE.LEGACY",
    formTitle: "DASHBOARD.GOALS.FORM.LEGACY.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.LEGACY"
  },

  [ENTITIES.ENTITY_PROTECT_LOVED]: {
    icon: "IconProtect",
    iconBig: "IconBigInsurance",
    color: "green",
    title: "Protect Loved Ones",
    titleI18n: "PLANS.GOALS.TILE.PROTECT_LOVED",
    titleCard: "Life Insurance",
    titleCardI18n: "PLANS.GOALS.TILE.PROTECT_LOVED.CARD",
    formTitle: "DASHBOARD.GOALS.FORM.INSURANCE.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.INSURANCE"
  },

  [ENTITIES.ENTITY_EDUCATION]: {
    icon: "IconSaving",
    iconBig: "IconBigEducation",
    color: "green",
    title: "Education Savings",
    titleI18n: "PLANS.GOALS.TILE.EDUCATION_SAVINGS",
    formTitle: "DASHBOARD.GOALS.FORM.EDUCATION.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.EDUCATION"
  },

  [ENTITIES.ENTITY_MAJOR_EXPANSE]: {
    icon: "IconMoneyBag",
    iconBig: "IconBigLoan",
    color: "green",
    title: "Major Expense",
    titleI18n: "PLANS.GOALS.TILE.MAJOR_EXPENSE",
    formTitle: "DASHBOARD.GOALS.FORM.MAJOR.TITLE",
    dashboardFormMessage: "DASHBOARD.GOALS.FORM.MAJOR"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "PLANS.GOALS.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "General Goal",
    titleI18n: "PLANS.GOALS.ASSET_TYPES.GENERAL_GROUP"
  }
};

export const ENTITIES_ADDITIONAL = {};

export const formScheme = {
  [ENTITIES.ENTITY_RETIREMENT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.RETIREMENT.DESCRIPTION.LABEL"
    },
    starts: {
      isRequired: true,
      isSelect: true,
      default: "atAge",
      options: optionsRetirementStart,

      label: "Starts",
      labelI18n: "PLANS.GOALS.FORM.RETIREMENT_STARTS.LABEL"
    },
    atAge: {
      // isMain: true,
      isMainCondition: {
        condition: [
          {
            field: "starts",
            value: "atAge"
          }
        ]
      },
      condition: [
        {
          field: "starts",
          value: "atAge"
        }
      ],

      after: commonPrefixAfter.YRS,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isGeneralMemberAge: true },
      isNumber: true,
      isInt: true,
      isRequired: true,
      label: "Age",
      labelI18n: "PLANS.GOALS.FORM.AT_AGE.LABEL"
    },
    annualAmount: {
      isMainCondition: {
        condition: [
          {
            field: "starts",
            value: "passive"
          }
        ]
      },
      isAdditionalCondition: {
        condition: [
          {
            field: "starts",
            value: "atAge"
          }
        ]
      },
      condition: [
        {
          field: "starts",
          isFilled: true
        }
      ],

      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Annual Amount",
      labelI18n: "PLANS.GOALS.FORM.ANNUAL_AMOUNT.LABEL"
    },

    lifeExpectancy: {
      isAdditional: true,
      isRequired: true,
      isNumber: true,
      isInt: true,

      // TODO
      min: 0,
      max: MAX_AGE,
      after: commonPrefixAfter.YRS,
      label: "Planned Life Expectancy",
      labelI18n: "PLANS.GOALS.FORM.LIFE_EXPECTANCY.LABEL"
    }
  },
  [ENTITIES.ENTITY_HOME_PURCHASE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.HOME_PURCHASE.DESCRIPTION.LABEL"
    },
    cost: {
      isMain: true,

      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Cost",
      labelI18n: "PLANS.GOALS.FORM.COST.LABEL"
    },
    downPayment: {
      isAdditional: true,

      isRequired: true,
      prefix: "%",
      isNumber: true,
      min: 0,
      label: "Down Payment",
      labelI18n: "PLANS.GOALS.FORM.DOWN_PAYMENT.LABEL"
    },
    expectedAge: {
      isAdditional: true,

      isRequired: true,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isGeneralMemberAge: true },
      isNumber: true,
      isInt: true,
      label: "Expected Age",
      labelI18n: "PLANS.GOALS.FORM.EXPECTED_AGE.LABEL"
    }
  },
  [ENTITIES.ENTITY_LEGACY]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.LEGACY.DESCRIPTION.LABEL"
    },
    amountToLeave: {
      isMain: true,

      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Amount To Leave Behind",
      labelI18n: "PLANS.GOALS.FORM.AMOUNT_TO_LEAVE_BEHIND.LABEL",
      labelCard: "Amount To Leave",
      labelI18nCard: "PLANS.GOALS.FORM.AMOUNT_TO_LEAVE.LABEL"
    },
    expectedAge: {
      isAdditional: true,

      isRequired: true,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isGeneralMemberAge: true },
      isNumber: true,
      isInt: true,
      label: "Expected Age",
      labelI18n: "PLANS.GOALS.FORM.EXPECTED_AGE.LABEL"
    }
  },
  [ENTITIES.ENTITY_PROTECT_LOVED]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.PROTECT_LOVED.DESCRIPTION.LABEL"
    },
    personInsured: {
      isAdditional: true,

      isSelect: true,
      options: optionsOwner,
      optionsCustom: "custom-members-all",

      isRequired: true,
      label: "Person Insured",
      labelI18n: "PLANS.GOALS.FORM.PERSON_INSURED.LABEL",
      labelCard: "Insured",
      labelI18nCard: "PLANS.GOALS.FORM.INSURED.LABEL"
    },
    deathBenefit: {
      isMain: true,

      // isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Death Benefit Amount",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.DEATH_BENEFIT_AMOUNT.LABEL"
    },
    startAge: {
      isAdditional: true,

      // isRequired: true,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isOwner: true, field: "personInsured" },
      isNumber: true,
      isInt: true,
      label: "Start Age",
      labelI18n: "PLANS.GOALS.FORM.START_AGE.LABEL"
    }
  },
  [ENTITIES.ENTITY_EDUCATION]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.EDUCATION.DESCRIPTION.LABEL"
    },

    personAttending: {
      isAdditional: true,
      isFullWidth: true,

      isSelect: true,
      isRequired: true,
      options: optionsOwner,
      optionsCustom: "custom-members-all",
      label: "Person Attending",
      labelI18n: "PLANS.GOALS.FORM.PERSON_ATTENDING.LABEL"
    },

    ageAttending: {
      isAdditional: true,

      after: commonPrefixAfter.YRS,
      isRequired: true,
      min: 0,
      max: MAX_AGE,
      minFromCustom: { isOwner: true, field: "personAttending" },
      isNumber: true,
      isInt: true,
      label: "Age Attending",
      labelI18n: "PLANS.GOALS.FORM.AGE_ATTENDING.LABEL"
    },
    howManyYears: {
      isAdditional: true,

      min: 0,
      max: MAX_AGE,
      after: commonPrefixAfter.YRS,
      isRequired: true,
      isNumber: true,
      isInt: true,
      label: "How Many Years",
      labelI18n: "PLANS.GOALS.FORM.HOW_MANY_YEARS.LABEL"
    },
    amountPerYear: {
      isAdditional: true,

      isAmount: true,
      isNumber: true,
      isRequired: true,
      label: "Amount Per Year",
      labelI18n: "PLANS.GOALS.FORM.AMOUNT_PER_YEAR.LABEL"
    },
    totalAmount: {
      isOnlyShow: true,
      isMain: true,

      calculate: { isGoalsTotalAmount: true, field: "amountPerYear" },

      isAmount: true,
      isNumber: true,
      label: "Total Amount",
      labelI18n: "PLANS.GOALS.FORM.TOTAL_AMOUNT.LABEL"
    }
  },

  [ENTITIES.ENTITY_MAJOR_EXPANSE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.GOALS.FORM.MAJOR_EXPANSE.DESCRIPTION.LABEL"
    },
    amount: {
      ...repeatableSchemeGeneral.amount,
      label: "Amount Needed",
      labelI18n: "PLANS.GOALS.FORM.AMOUNT_NEEDED.LABEL"
    },
    targetAge: {
      isAdditional: true,

      min: 0,
      max: MAX_AGE,
      minFromCustom: { isGeneralMemberAge: true },
      isNumber: true,
      isInt: true,
      label: "Target Age",
      labelI18n: "PLANS.GOALS.FORM.TARGET_AGE.LABEL"
    }
  },

  // [ENTITIES.ENTITY_OTHER]: {
  //   description: {
  //     ...repeatableSchemeGeneral.description,
  //     isSubTitle: true,
  //     label: "Description",
  //     labelI18n: "PLANS.GOALS.FORM.OTHER.DESCRIPTION.LABEL"
  //   },
  //   amount: { ...repeatableSchemeGeneral.amount },
  //   targetAge: {
  //     isAdditional: true,
  //
  //     min: 0,
  //     max: MAX_AGE,
  //     minFromCustom: { isGeneralMemberAge: true },
  //     isNumber: true,
  //     isInt: true,
  //     label: "Target Age",
  //     labelI18n: "PLANS.GOALS.FORM.TARGET_AGE.LABEL"
  //   }
  // },
  // [ENTITIES.ENTITY_GENERAL]: {
  //   description: {
  //     ...repeatableSchemeGeneral.description,
  //     label: "Description",
  //     labelI18n: "PLANS.GOALS.FORM.GENERAL.DESCRIPTION.LABEL"
  //   },
  //   amount: { ...repeatableSchemeGeneral.amount },
  //   targetAge: {
  //     isAdditional: true,
  //
  //     min: 0,
  //     max: MAX_AGE,
  //     minFromCustom: { isGeneralMemberAge: true },
  //     isNumber: true,
  //     isInt: true,
  //     label: "Target Age",
  //     labelI18n: "PLANS.GOALS.FORM.TARGET_AGE.LABEL"
  //   }
  // }
};

const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.PLANS.GOALS",
        title: "Life Goals"
      }
    },
    header: {
      titleI18n: "PLANS.GOALS.TITLE",
      title: "What goals do you want to work towards?"
    },
    caption: {
      titleI18n: "PLANS.GOALS.CAPTION",
      title: "Add or edit you or your family’s goals"
    },
    emptyContent: {
      titleI18n: "PLANS.GOALS.EMPTY",
      title: "You have no goals,\n please add your first goal"
    },
    toolbarHeader: {
      titleI18n: "PLANS.GOALS.H2",
      title: "Goals"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "PLANS.GOALS.ADDED",
      title: "Goal Added"
    },
    assetRemoved: {
      titleI18n: "PLANS.GOALS.REMOVED",
      title: "Goal Removed"
    },
    assetSaved: {
      titleI18n: "APP.SUCCESSFULLY_SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "PLANS.GOALS.SIDE_MENU_TITLE",
      title: "Add Goals"
    },
    sideMenu: {
      titleI18n: "PLANS.GOALS.SIDE_MENU_TITLE.EDIT",
      title: "Edit Goal"
    },
    sideGroup: {
      titleI18n: "PLANS.GOALS.FORM_GROUP.TITLE",
      title: "Goal Type"
    },
    generalAsset: {
      titleI18n: "PLANS.GOALS.ASSET_TYPES.GENERAL_GROUP",
      title: "General Goal",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Goal”",
      captionI18n: "PLANS.GOALS.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "PLANS.GOALS.FORM.VALUE.BUTTON.SAVE",
      title: "Save Goal"
    },
    buttonAdd: {
      titleI18n: "PLANS.GOALS.FORM.VALUE.BUTTON",
      title: "+ Add Goal"
    }
  },
  whyNeedIt: {
    titleI18n: "PLANS.GOALS.WHY.TITLE",
    textI18n: "PLANS.GOALS.WHY.ALL_TEXT"
  }
};
