import React, { Component } from "react";

import { FormattedMessage, injectIntl } from "react-intl-v3";

import ContentHeader from "../ContainerApp/ContentHeader";
import ContentMain from "../ContainerApp/ContentMain";

import Button from "components/_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";

import { compose } from "redux";
import NavigationPrompt from "react-router-navigation-prompt";

import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";
import HouseholdCard from "../HouseholdMembers/HouseholdCard";
import FormControlInput from "../_common/FormControl/FormControlInput";
import FormControlSelect from "../_common/FormControl/FormControlSelect";

import {
  formsInitialState,
  formsValidationScheme,
  languageOptions,
  PROFILE_FORMS
} from "./formScheme";
import IconContainer from "../_common/IconContainer";
import toast from "../_common/toast";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { calcPasswordStrength, emailValid } from "../LogIn/helper";
import { REQUIRED_PASSWORD_STRENGTH } from "../../constants";
import DialogRemoveAsset from "../AssetsCommon/DialogRemoveAsset";
import DialogChangesNotSaved from "../AssetsCommon/DialogChangesNotSaved";
import CircularProgress from "@material-ui/core/CircularProgress";

import { functionsURL } from "config/config";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { CLOUD_FUNC_ENDPOINT } from "../../constants/endpoints";

const defaultLang = "en";

class Profile extends Component {


  state = {
    ...formsInitialState,
    lifeInsurance: false,
    health: false,
    securities: false,
    validationScheme: { ...formsValidationScheme },
    dialogCleanDataOpen: false,
    isResetting: false,
    isSendingInvite: false,
    isChanged: false
  };

  componentDidMount() {
    const { profile } = this.props;

    if (profile && profile.isLoaded) {
      this.updateStateFromBase();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { profile } = this.props;

    if (
      profile &&
      ((profile.isLoaded && !prevProps.profile.isLoaded) ||
        profile.language !== prevProps.profile.language)
    ) {
      this.updateStateFromBase();
    }
  }
  
  handleIsChange = state => {
    this.setState({
      isChanged: state || false
    });
  };

  /// Dialog Remove asset
  handleDialogRemoveAgree = () => {
    // this.handleDeleteAsset();

    this.handleResetWholeData();
    this.handleDialogRemoveClose();
  };

  handleDialogRemoveOpen = () => {
    this.setState({
      dialogCleanDataOpen: true
    });
  };

  handleDialogRemoveClose = () => {
    this.setState({
      dialogCleanDataOpen: false
    });
  };

  handleResetWholeData = async () => {
    this.setState({
      isResetting: true
    });

    const { firestore, auth } = this.props;

    const userUid = auth.uid;

    const collections = [
      { collection: "userAssets", userField: "user" },
      { collection: "transactions", userField: "user" },
      { collection: "wizard", userField: "user" },
      { collection: "configuration", userField: "clientId" }
      // { collection: "advisor", userField: "clientId" }
    ];

    let collectionsPromises = [];
    const batchGlobal = firestore.batch();

    collections.forEach(({ collection, userField }, key) => {
      collectionsPromises.push(
        firestore
          .collection(collection)
          .where(userField, "==", userUid)
          .get()
      );
    });

    collectionsPromises.push(
      firestore
        .collection("users")
        .doc(userUid)
        .collection("childs")
        .get()
    );

    const collectionsQueries = await Promise.all(collectionsPromises);

    if (collectionsQueries) {
      collectionsQueries.forEach(querySnapshot => {
        querySnapshot.forEach(doc => {
          batchGlobal.delete(doc.ref);
        });
      });
    }

    const docRefUser = firestore.collection("users").doc(userUid);

    batchGlobal.update(docRefUser, {
      birthday: "",
      displayName: "",
      interests: {},
      spouse: {},
      employedStatus: "",
      relationshipStatus: "",
      zipCode: "",
      childs: {},

      isAdminApproved: true,
      isDontShowAgainToStandard: false,
      isDontShowAgainToWhatIf: false,
      isWizardFinished: false
    });

    batchGlobal.commit().then(() => {
      toast(
        <FormattedMessage
          id="APP.CLEAN_ALL_DATA.SUCCESS"
          defaultMessage="All data is reset"
        />
      );

      this.setState({
        isResetting: false
      });

      firestore.get({ collection: "users", doc: userUid, storeAs: "family" });
    });
  };

  updateStateFromBase = () => {
    const { profile } = this.props;

    this.setState({
      PROFILE: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email
      },
      LANGUAGE: {
        language: profile.language || window.__localeId__ || defaultLang
      },
      lifeInsurance:
        profile && profile.advisorLicense
          ? profile.advisorLicense.lifeInsurance
          : false,
      health:
        profile && profile.advisorLicense
          ? profile.advisorLicense.health
          : false,
      securities:
        profile && profile.advisorLicense
          ? profile.advisorLicense.securities
          : false
    });
  };

  handleValidateData = entity => {
    let isValid = true;
    let newState = { ...this.state.validationScheme };

    const formValidation = { ...this.state.validationScheme[entity] };
    const form = this.state[entity];

    Object.keys(formValidation).forEach(keyField => {
      const scheme = formValidation[keyField];

      if (scheme.isRequired) {
        let isConditionAccept = true;
        const formEntityScheme = formsValidationScheme[entity][keyField];

        if (formEntityScheme.condition) {
          isConditionAccept = this.checkConditions(
            formEntityScheme.condition,
            form
          );
        }

        if (!form[keyField] && isConditionAccept) {
          if (
            formsValidationScheme[entity][keyField].isAmount &&
            form[keyField] === 0
          ) {
            newState[entity][keyField].isError = null;
          } else {
            isValid = false;

            newState[entity][keyField].isError = "error";
          }
        } else {
          newState[entity][keyField].isError = null;
        }

        newState[entity][keyField].isTouched = this.state.validationScheme[
          entity
        ][keyField].isTouched;

        newState[entity][keyField].isTouched = false;
        newState[entity][keyField].isRequired = true;
      }

      if (scheme.isEmail) {
        const isEmailValid = emailValid(form[keyField]);

        if (!isEmailValid) {
          isValid = false;

          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "error";

          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="SIGNUP.ERROR.INVALID_EMAIL"
              defaultMessage="You must enter valid email address"
            />
          );
        }
        newState[entity][keyField].isEmail = true;
      }
    });

    if (entity === "PASSWORD") {
      const isPasswordEnoughStrength =
        calcPasswordStrength(form.newPassword) > REQUIRED_PASSWORD_STRENGTH;

      if (!isPasswordEnoughStrength) {
        isValid = false;

        newState[entity].newPassword.isError = true;
        newState[entity].newPassword.errorMessageI18n = (
          <FormattedMessage
            id="SIGNUP.ERROR.INVALID_PASSWORD_STRENGTH"
            defaultMessage="You must enter strong password"
          />
        );
      }

      const isConfirmPasswordValid =
        !form.newPassword || form.newPassword === form.confirmPassword;

      if (!isConfirmPasswordValid) {
        isValid = false;
        newState[entity].confirmPassword.isError = true;
        newState[entity].confirmPassword.errorMessageI18n = (
          <FormattedMessage
            id="SIGNUP.ERROR.INVALID_CONFIRM_PASSWORD"
            defaultMessage="You must enter the same password"
          />
        );
      }
    }

    this.setState({ validationScheme: newState });
    return isValid;
  };

  handleLicenseCheckBoxChange = ev => {
    const { name, checked } = ev.target;

    this.setState({
      [name]: checked
    });
    this.handleIsChange(true);
  };

  handleInputChange = asset => ev => {
    const { name, value } = ev.target;

    const isRequired =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isRequired;
    const isEmail =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isEmail;

    this.setState(prevState => ({
      [asset]: { ...prevState[asset], [name]: value },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [name]: {
            isTouched: true,
            isError: false,
            isRequired: isRequired,
            isEmail: isEmail
          }
        }
      }
    }));
    this.handleIsChange(true);
  };

  handleInviteClient = async () => {
    const { firebase, firestore, profile, auth } = this.props;
    const { intl } = this.props;
    const currentUser = firebase.auth().currentUser;

    const KEY = "ADVISOR_INVITE";
    const formState = this.state[KEY];

    const isValid = this.handleValidateData(KEY);

    const message = intl.formatMessage(
      {
        id: "PROFILE.INVITE.EMAIL.MESSAGE",
        defaultMessage:
          "Hello, {name}. Please click to the link, to proceed to XCurve."
      },
      { name: formState.clientName }
    );

    const subject = intl.formatMessage({
      id: "PROFILE.INVITE.EMAIL.SUBJECT",
      defaultMessage: "XCurve"
    });
    const signUpLink = intl.formatMessage({
      id: "PROFILE.INVITE.EMAIL.SIGN_UP_LINK",
      defaultMessage: "signUpLink"
    });
    if (!isValid) {
      toast(
        <FormattedMessage
          id="APP.FORM.VALIDATION.FIX_ERRORS"
          defaultMessage="Please, fix all errors"
        />,
        { type: "error" }
      );

      return;
    }

    const query = await firestore
      .collection("advisor")
      .where("client", "==", formState.clientEmail)
      .where("advisor", "==", auth.email)
      .get();

    let dataAdvisor;
    query.forEach(doc => {
      dataAdvisor = { ...doc.data(), id: doc.id };
    });

    if (!dataAdvisor || (dataAdvisor && !dataAdvisor.clientId)) {
      this.setState({
        isSendingInvite: true
      });

      currentUser
        .getIdToken(true)
        .then(idToken => {
          const data = {
            advisorEmail: auth.email,
            clientEmail: formState.clientEmail,
            uid: currentUser.uid,
            name: profile.displayName,
            idToken,
            message: message,
            signUpLink: signUpLink,
            subject: subject,
            advisorEntityId: dataAdvisor && dataAdvisor.id
          };

          fetch(`${functionsURL}/${CLOUD_FUNC_ENDPOINT.sendMailInviteClient}`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
          })
            .then(res => {
              this.setState({
                isSendingInvite: false
              });

              toast(
                <FormattedMessage
                  id="PROFILE.INVITE.SENT"
                  defaultMessage="Email successfully sent"
                />
              );
            })
            .catch(err => {
              this.setState({
                isSendingInvite: false
              });
            });
        })
        .catch(error => {});
    } else {
      if (dataAdvisor && dataAdvisor.clientId) {
        toast(
          <FormattedMessage
            id="PROFILE.INVITE.ALREADY_CLIENT"
            defaultMessage="This Email already your client"
          />,
          { type: "error" }
        );
      } else {
        toast(
          <FormattedMessage
            id="PROFILE.INVITE.ALREADY_SENT"
            defaultMessage="Email already sent to this client"
          />,
          { type: "error" }
        );
      }
    }
  };

  handleUpdatePassword = KEY => ev => {
    const { firebase } = this.props;
    const user = firebase.auth().currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      this.state[KEY].password
    );

    const isValid = this.handleValidateData(KEY);

    if (!isValid) {
      toast(
        <FormattedMessage
          id="APP.FORM.VALIDATION.FIX_ERRORS"
          defaultMessage="Please, fix all errors"
        />,
        { type: "error" }
      );

      return;
    }

    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(res => {
        user
          .updatePassword(this.state[KEY].newPassword)
          .then(function() {
            toast(
              <FormattedMessage
                id="APP.SUCCESSFULLY_SAVED"
                defaultMessage="Saved successfully"
              />
            );
          })
          .catch(function(error) {});
      })
      .catch(error => {
        if (
          (error.error && error.error.message === "INVALID_PASSWORD") ||
          error.code === "auth/wrong-password"
        ) {
          this.setState(prevState => ({
            validationScheme: {
              ...prevState.validationScheme,
              [KEY]: {
                ...prevState.validationScheme[KEY],
                password: {
                  ...prevState.validationScheme[KEY].password,
                  isError: true,
                  errorMessageI18n: (
                    <FormattedMessage
                      id="PROFILE.CARD.PASSWORD.WRONG_PASS"
                      defaultMessage="Wrong password"
                    />
                  )
                }
              }
            }
          }));
        }
      });
  };

  handleUpdateLicense = () => {

    this.handleIsChange(false);
    const { firestore, firebase } = this.props;

    const userUid = firebase.auth().currentUser.uid;

    return firestore
      .update(`users/${userUid}`, {
        updated: new Date(),
        advisorLicense: {
          lifeInsurance: this.state.lifeInsurance,
          health: this.state.health,
          securities: this.state.securities
        }
      })
      .then(res => {
        toast(
          <FormattedMessage
            id="APP.SUCCESSFULLY_SAVED"
            defaultMessage="Saved successfully"
          />
        );
      });
  };

  handleUpdateData = KEY => ev => {
    ev.preventDefault();

    const isValid = this.handleValidateData(KEY);

    if (!isValid) {
      toast(
        <FormattedMessage
          id="APP.FORM.VALIDATION.FILL_ALL_REQUIRED"
          defaultMessage="Fill all required fields"
        />,
        { type: "error" }
      );

      return;
    }

    return this.handleSubmitEditAsset({ KEY });
  };

  handleSubmitEditAsset = ({ KEY }) => {
    const { firestore, firebase } = this.props;

    const userUid = firebase.auth().currentUser.uid;

    return firestore
      .update(`users/${userUid}`, {
        updated: new Date(),
        ...this.state[KEY]
      })
      .then(res => {
        toast(
          <FormattedMessage
            id="APP.SUCCESSFULLY_SAVED"
            defaultMessage="Saved successfully"
          />
        );
      });
  };

  _renderProfileInfo = () => {
    const { isMobile } = this.props;
    const KEY = "PROFILE";

    return (
      <form onSubmit={this.handleUpdateData(KEY)}>
        <HouseholdCard
          iconComp={
            <IconContainer color="brand" height={32} width={32}>
              <Icons.IconProfile color="brand" height={24} width={24} />
            </IconContainer>
          }
          title="Change Profile Info"
          titleI18n="PROFILE.CARD.INFO"
          style={isMobile ? {} : { width: 340 }}
        >
          <FormControlInput
            required
            error={
              !!this.state.validationScheme[KEY].firstName.isError &&
              !this.state.validationScheme[KEY].firstName.isTouched
            }
            fieldName="firstName"
            label="First Name"
            labelI18n="SIGNUP.FORM.FIRST_NAME"
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].firstName}
          />

          <FormControlInput
            required
            error={
              !!this.state.validationScheme[KEY].lastName.isError &&
              !this.state.validationScheme[KEY].lastName.isTouched
            }
            fieldName="lastName"
            label="Last Name"
            labelI18n="SIGNUP.FORM.LAST_NAME"
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].lastName}
          />

          <FormControlInput
            required
            fieldName="email"
            label="Email"
            labelI18n="SIGNUP.FORM.EMAIL"
            handleChange={this.handleInputChange(KEY)}
            disabled
            value={this.state[KEY].email}
          />

          <Button
            handleClick={this.handleUpdateData(KEY)}
            uiLight
            style={{ marginTop: 16 }}
          >
            <FormattedMessage
              id="APP.SAVE_CHANGES"
              defaultMessage="Save Changes"
            />
          </Button>
        </HouseholdCard>
      </form>
    );
  };

  _renderLanguage = () => {
    const { isMobile } = this.props;
    const KEY = "LANGUAGE";

    return (
      <form onSubmit={this.handleUpdateData(KEY)}>
        <HouseholdCard
          iconComp={
            <IconContainer color="brand" height={32} width={32}>
              <Icons.IconLanguage color="brand" height={24} width={24} />
            </IconContainer>
          }
          title="Language"
          titleI18n="PROFILE.CARD.LANGUAGE"
          style={isMobile ? {} : { width: 340 }}
        >
          <FormControlSelect
            required
            error={
              !!this.state.validationScheme[KEY].language.isError &&
              !this.state.validationScheme[KEY].language.isTouched
            }
            fieldName="language"
            label="Language"
            labelI18n="APP.LANGUAGE"
            options={languageOptions}
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].language}
            hideNoneOption
          />

          <Button
            handleClick={this.handleUpdateData(KEY)}
            uiLight
            style={{ marginTop: 16 }}
          >
            <FormattedMessage
              id="APP.SAVE_CHANGES"
              defaultMessage="Save Changes"
            />
          </Button>
        </HouseholdCard>
      </form>
    );
  };

  _renderPassword = () => {
    const { isMobile } = this.props;
    const KEY = "PASSWORD";

    return (
      <form onSubmit={this.handleUpdatePassword(KEY)}>
        <HouseholdCard
          iconComp={
            <IconContainer color="brand" height={32} width={32}>
              <Icons.IconPassword color="brand" height={24} width={24} />
            </IconContainer>
          }
          title="Change password"
          titleI18n="PROFILE.CARD.PASSWORD"
          style={isMobile ? {} : { width: 340 }}
        >
          <FormControlInput
            required
            error={
              !!this.state.validationScheme[KEY].password.isError &&
              !this.state.validationScheme[KEY].password.isTouched
            }
            helperText={
              !!this.state.validationScheme[KEY].password.isError &&
              !this.state.validationScheme[KEY].password.isTouched &&
              this.state.validationScheme[KEY].password.errorMessageI18n
                ? this.state.validationScheme[KEY].password.errorMessageI18n
                : null
            }
            fieldName="password"
            label="Old Password"
            labelI18n="PASSWORD.FORM.OLD_PASSWORD"
            type="password"
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].password}
          />

          <FormControlInput
            required
            error={
              !!this.state.validationScheme[KEY].newPassword.isError &&
              !this.state.validationScheme[KEY].newPassword.isTouched
            }
            helperText={
              !!this.state.validationScheme[KEY].newPassword.isError &&
              !this.state.validationScheme[KEY].newPassword.isTouched &&
              this.state.validationScheme[KEY].newPassword.errorMessageI18n
                ? this.state.validationScheme[KEY].newPassword.errorMessageI18n
                : null
            }
            fieldName="newPassword"
            label="New Password"
            labelI18n="PASSWORD.FORM.NEW_PASSWORD"
            type="password"
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].newPassword}
          />

          <FormControlInput
            required
            error={
              !!this.state.validationScheme[KEY].confirmPassword.isError &&
              !this.state.validationScheme[KEY].confirmPassword.isTouched
            }
            helperText={
              !!this.state.validationScheme[KEY].confirmPassword.isError &&
              !this.state.validationScheme[KEY].confirmPassword.isTouched &&
              this.state.validationScheme[KEY].confirmPassword.errorMessageI18n
                ? this.state.validationScheme[KEY].confirmPassword
                    .errorMessageI18n
                : null
            }
            fieldName="confirmPassword"
            label="Confirm password"
            labelI18n="PASSWORD.FORM.CONFIRM_PASSWORD"
            type="password"
            handleChange={this.handleInputChange(KEY)}
            value={this.state[KEY].confirmPassword}
          />

          <Button
            handleClick={this.handleUpdatePassword(KEY)}
            uiLight
            style={{ marginTop: 16 }}
          >
            <FormattedMessage
              id="APP.SAVE_CHANGES"
              defaultMessage="Save Changes"
            />
          </Button>
        </HouseholdCard>
      </form>
    );
  };

  _renderAdvisor = () => {
    const { profile, isMobile } = this.props;
    const KEY = "ADVISOR";

    if (!(profile && profile.isAdvisor)) {
      return null;
    }

    return (
      <form onSubmit={this.handleUpdateData(KEY)}>
        <HouseholdCard
          iconComp={
            <IconContainer color="brand" height={32} width={32}>
              <Icons.IconGlasses color="brand" height={24} width={24} />
            </IconContainer>
          }
          title="Advisor License"
          titleI18n="PROFILE.CARD.ADVISOR_LICENSE"
          style={isMobile ? {} : { width: 340 }}
        >
          <div className="profile-advisor">
            <FormControlLabel
              control={
                <Checkbox
                  name="lifeInsurance"
                  onChange={this.handleLicenseCheckBoxChange}
                  checked={this.state.lifeInsurance}
                />
              }
              label={
                <FormattedMessage
                  id={"PROFILE.CARD.ADVISOR_LICENSE.TYPE.LIFE_INSURANCE"}
                  defaultMessage="Life Insurance"
                />
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="health"
                  onChange={this.handleLicenseCheckBoxChange}
                  checked={this.state.health}
                />
              }
              label={
                <FormattedMessage
                  id={"PROFILE.CARD.ADVISOR_LICENSE.TYPE.HEALTH"}
                  defaultMessage="Health and Disability"
                />
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="securities"
                  onChange={this.handleLicenseCheckBoxChange}
                  checked={this.state.securities}
                />
              }
              label={
                <FormattedMessage
                  id={"PROFILE.CARD.ADVISOR_LICENSE.TYPE.SECURITIES"}
                  defaultMessage="Securities"
                />
              }
            />
          </div>

          <Button
            handleClick={this.handleUpdateLicense}
            uiLight
            style={{ marginTop: 16 }}
          >
            <FormattedMessage
              id="APP.SAVE_CHANGES"
              defaultMessage="Save Changes"
            />
          </Button>
        </HouseholdCard>
      </form>
    );
  };

  testCloud = () => {
    this.props.firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function(idToken) {
        const data = {
          email: "xxx@ccc.com",
          name: "Jane",
          idToken
        };

        fetch(`${functionsURL}/sendMail`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });
      })
      .catch(function(error) {
        // Handle error
      });
  };

  render() {
    const { isMobile } = this.props;

    const Header = (
      <ContentHeader
        header={
          <FormattedMessage id="PROFILE.TITLE" defaultMessage="Profile Setup" />
        }
      />
    );

    const AdvsisorPermLink = (
      <NavLink
        to={ROUTES.ADVISOR_PERMISSIONS}
        style={{
          color: "inherit",
          textDecoration: "none",
          outline: "none",
          width: "100%"
        }}
      >
        <Button
          white
          block
          style={{
            margin: 16,
            width: "calc(100% - 32px)"
          }}
        >
          <FormattedMessage
            id="DRAWER.PROFILE.ADVISOR_PERMISSIONS"
            deafultMessage="Advisor Permissions"
          />
        </Button>
      </NavLink>
    );

    return (
      <>
        {this.state.isChanged && (
        <NavigationPrompt
           when={(crntLocation, nextLocation) =>
           this.state.isChanged &&
           (!nextLocation || crntLocation.pathname !== nextLocation.pathname)}
        >
        {({ isActive, onCancel, onConfirm }) => (
          <DialogChangesNotSaved
            isOpen={isActive}
            handleAgreeAndClose={onConfirm}
            handleClose={onCancel}
          />
        )}
        </NavigationPrompt>)}
        <DialogRemoveAsset
          title={
            <FormattedMessage
              id="APP.CLEAN_ALL_DATA"
              defaultMessage="Are really want to reset all data?"
            />
          }
          isOpen={!!this.state.dialogCleanDataOpen}
          handleAgreeAndClose={this.handleDialogRemoveAgree}
          handleClose={this.handleDialogRemoveClose}
        />
        <main>
          <div className="main-content">
            {isMobile ? (
              Header
            ) : (
              <div className="profile-toolbar">
                {Header}

                <Button
                  handleClick={
                    this.state.isResetting ? null : this.handleDialogRemoveOpen
                  }
                  white
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  {this.state.isResetting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FormattedMessage
                      id="PROFILE.CARD.INFO.RESET_WHOLE_DATA"
                      defaultMessage="Reset whole data"
                    />
                  )}
                </Button>
              </div>
            )}

            <div style={{ marginTop: 28 }} />

            <ContentMain>
              <div className="household__forms household__forms--profile">
                {this._renderProfileInfo()}
                {this._renderPassword()}
                {/*{this._renderLanguage()}*/}
                {/*{this._renderInviteClient()}*/}
                {this._renderAdvisor()}

                {isMobile && AdvsisorPermLink}

                {isMobile && (
                  <Button
                    handleClick={
                      this.state.isResetting
                        ? null
                        : this.handleDialogRemoveOpen
                    }
                    white
                    block
                    style={{
                      margin: 16,
                      marginBottom: 64,
                      width: "calc(100% - 32px)"
                    }}
                  >
                    {this.state.isResetting ? (
                      <CircularProgress size={20} />
                    ) : (
                      <FormattedMessage
                        id="PROFILE.CARD.INFO.RESET_WHOLE_DATA"
                        defaultMessage="Reset whole data"
                      />
                    )}
                  </Button>
                )}
              </div>
            </ContentMain>
          </div>
        </main>
      </>
    );
  }
}

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, status },
  ui
}) {
  return {
    isMobile: ui.isMobile,
    auth,
    profile,
    firestoreStatus: status
  };
}
export default compose(
  firestoreConnect(props => {
    return [];
  }),
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  injectIntl
)(withFirebase(Profile));
