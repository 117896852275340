import { colors, styleMix } from "constants/styles";
import { BREAKPOINT_MD, BREAKPOINT_MD_MAX } from "constants/styles";

export const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3
    }
  },
  mainMessage: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    display: "flex",
    flexWrap: "wrap", // flexDirection: "column", // Fix IE 11 issue. // Fix IE 11 issue.
    alignItems: "stretch",
    overflow: "hidden",
    [theme.breakpoints.up(BREAKPOINT_MD)]: {
      width: 856,
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: {
      marginTop: theme.spacing.unit * 7,
      borderRadius: "0",
      boxShadow: "none",
      background: "transparent"
    }
  },
  titleWrap: {
    padding: "50px 45px 0 45px",
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: { padding: "0 0 16px 0" }
  },
  title: {
    color: colors.white,
    fontWeight: "700",
    fontSize: "30px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    paddingBottom: "45px",
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: {
      padding: "3px 0 0 8px",
      border: "none",
      fontSize: "20px",
      maxWidth: "180px"
    }
  },
  imgCont: {
    padding: "47px 0 0 45px",
    lineHeight: "0",
    fontSize: "0",
    textAlign: "right",
    marginRight: "-12%",
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: {
      flexGrow: "1",
      padding: "0",
      margin: "0",
      background: "url('/assets/img/bg-sign.svg') no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "100% 100%"
    },
    "& img": {
      [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: { display: "none" }
    }
  },
  logoBottom: {
    position: "absolute",
    bottom: "45px",
    left: "45px",
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: { display: "none" }
  },
  paperLeft: {
    width: "50%",
    // background: "linear-gradient(180deg, #363658 0%, #564DD0 100%)",
    background: "linear-gradient(180deg, #453074 0%, #564dd0 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    position: "relative",
    overflow: "hidden",

    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: {
      width: "100%",
      flexDirection: "row",
      padding: "16px 16px 56px 16px"
    }
  },
  paperRight: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 8}px ${theme
      .spacing.unit * 3}px`,
    [theme.breakpoints.down(BREAKPOINT_MD_MAX)]: {
      width: "100%",
      borderRadius: "4px",
      background: colors.white,
      margin: "-56px 16px 20px 16px",
      boxShadow: "0px 2px 4px rgba(30, 50, 90, 0.15)",
      position: "relative",
      padding: "32px 24px "
    }
  },
  paperCenter: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
    height: "50%",
    width: "100%"
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // marginTop: theme.spacing.unit, // Fix IE 11 issue.
    marginTop: "-16px"
  },
  formControl: {
    "& label:after": {
      content: "'*'",
      display: "block",
      width: "10px",
      height: "10px",
      color: "red",
      position: "absolute",
      top: "0",
      left: "auto",
      right: "-12px"
    },
    "& input:-webkit-text-fill-color": { background: colors.white }
  },
  note: { fontSize: "12px", color: "#8B90A0", marginTop: "-16px" },
  checkbox: {
    "&$checked": {
      color: colors.ui
    },
    disabled: {
      opacity: 0.6
    }
  },
  checked: {},
  checkoutControl: { color: colors.textSecondary },
  submit: { marginTop: theme.spacing.unit * 3 },
  forgotLink: {
    marginTop: 15,
    color: colors.brand,
    textDecoration: "underline"
  },
  Links: {
    color: colors.brand,
    textDecoration: "underline",
    "&:hover, &:focus": {
      outline: "none",
      opacity: 0.7
    }
  },
  ResendVerificationButton: {
    ...styleMix.ResetButton,
    "&:hover, &:focus": {
      outline: "none",
      opacity: 0.7
    },

    "&:disabled": {
      color: colors.textSecondary,
      cursor: "default"
    }
  },
  signupLink: { marginTop: 25, textAlign: "center" },
  typographyVerification: { marginTop: 16 },
  sendAgainContainer: { marginTop: 16 },
  sendAgainButtonContainer: { textAlign: "right" },
  forgotPasswordTitle: {
    fontWeight: 700,
    marginBottom: 30,
    color: colors.textPrimary
  },
  forgotPasswordStubtitle: {
    color: colors.textSecondary,
    marginBottom: 25
  },
  forgotPasswordEmail: { fontWeight: 500 },
  resendLink: { textDecoration: "none", color: colors.ui }
});
