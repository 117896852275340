import React, { Component } from "react";
import PropTypes from "prop-types";

import { setUI, bootData } from "AC/ui";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import ContainerAppBar from "./ConainerAppBar";
import AppDrawer from "./AppDrawer";

import styles from "./styles";
import { BREAKPOINT_MD_MAX } from "constants/styles";
import ContainerAppBarMobile from "./ConainerAppBarMobile";
import ContainerAppBarTopMobile from "./ContainerAppBarTopMobile";
import { compose } from "redux";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";
import {
  getAuthExpireInState,
  getAuthIsEmptyState,
  getAuthIsLoadedState,
  getAuthState,
  getMyAdvisorsState,
  getMyClientsState,
  getProfileIsAdminApprovedState,
  getProfileState
} from "../../store/selectors/firebase";
import { FormattedMessage } from "react-intl-v3";
import AppBarLogIn from "./AppBarLogIn";

import DialogWizard from "components/Quiz/DialogWizard";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
    // logErrorToMyService(error, info)
  }

  render() {
    return this.state.errorOccurred ? (
      <h1>Something went wrong!</h1>
    ) : (
      this.props.children
    );
  }
}

class AppContainer extends Component {
  state = {
    open: false,
    isMobile: window.innerWidth <= BREAKPOINT_MD_MAX,
    wizardClosed: false,
    wizardOpen: false,

    lastLoginSet: false,
    lastViewed: false
  };

  mainContainer = React.createRef();
  timer = null;
  isNotFirstOpen = false;

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // if (this.mainContainer && this.mainContainer.current) {
    //   this.mainContainer.current.addEventListener(
    //     "scroll",
    //     this.updateScrollEvent,
    //     false
    //   );
    // }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      auth,
      firestore,
      profileProvider,
      firestoreStatus,
      myClients,
      myAdvisors
    } = this.props;

    // if (!this.isNotFirstOpen && auth && auth.isLoaded) {
    //   this.handlePresence();
    // }

    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.mainContainer.current
    ) {
      this.mainContainer.current.scrollTop = 0;
    }

    if (this.props.profile.email && !prevProps.profile.email) {
      this.props.bootData({ email: this.props.profile.email });
    }

    if (
      auth &&
      auth.isLoaded &&
      !this.state.lastLoginSet &&
      firestoreStatus &&
      firestoreStatus.requested.myAdvisors
    ) {
      this.setState({ lastLoginSet: true });

      const batch = firestore.batch();

      const lastLogin = new Date();
      const docRef = firestore.collection("users").doc(auth.uid);
      batch.update(docRef, {
        lastLogin
      });

      if (myAdvisors) {
        Object.keys(myAdvisors).forEach(key => {
          const docRefAdvisor = firestore.collection("advisor").doc(key);

          batch.update(docRefAdvisor, {
            lastLogin
          });
        });
      }

      batch.commit();
    }

    if (
      profileProvider !== auth.uid &&
      profileProvider !== prevProps.profileProvider
    ) {
      this.setState({ lastViewed: false });
    }

    if (
      profileProvider &&
      profileProvider !== auth.uid &&
      myClients &&
      !this.state.lastViewed
    ) {
      const clientAdvisorId = Object.keys(myClients).find(
        key => myClients[key].clientId === profileProvider
      );

      if (clientAdvisorId) {
        this.setState({ lastViewed: true });

        firestore.update(`advisor/${clientAdvisorId}`, {
          lastViewed: new Date()
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.removeEventListener("scroll", this.updateScrollEvent);
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }

  handlePresence = () => {
    const { auth, firebase, firestore } = this.props;

    const uid = firebase && firebase.auth && firebase.auth().getUid();

    // firestore.doc(`/users/${userUid}`);
    // var myConnectionsRef = firestore.doc(`users/${uid}/connections`);
    const myConnectionsRef = firebase.ref(`users/${uid}/connections`);
    // var myConnectionsRef = firestore
    //   .collection("users")
    //   .doc(uid)
    //   .collection("connections");
    // (`users/${uid}/connections`);
    // var myConnectionsRef = firebase.database().ref(`users/${uid}/connections`);

    // stores the timestamp of my last disconnect (the last time I was seen online)
    // var lastOnlineRef = firestore
    //   .collection("users")
    //   .doc(uid)
    //   .collection("lastOnline");
    const lastOnlineRef = firebase.ref(`users/${uid}/lastOnline`);
    // var lastOnlineRef = firebase.database().ref(`users/${uid}/lastOnline`);

    // var connectedRef = firebase.database().ref(".info/connected");
    // var connectedRef = firestore.doc(".info/connected");
    const connectedRef = firebase.ref(".info/connected");

    connectedRef.on("value", function(snap) {
      if (snap.val() === true) {
        // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
        var con = myConnectionsRef.push();

        // When I disconnect, remove this device
        con.onDisconnect().remove();

        // Add this device to my connections list
        // this value could contain info about the device or a timestamp too
        con.set(true);

        // When I disconnect, update the last time I was seen online
        lastOnlineRef
          .onDisconnect()
          .set(firebase.database.ServerValue.TIMESTAMP);
      }
    });

    this.isNotFirstOpen = true;
  };

  handleOpenWizard = () => {
    this.setState({
      anchorEl: null,
      isProfileMenuOpen: false,
      wizardOpen: true
    });
  };

  updateScrollEvent = () => {
    const body = document.body;
    clearTimeout(this.timer);

    if (!body.classList.contains("disable-hover")) {
      body.classList.add("disable-hover");
    }

    this.timer = setTimeout(function() {
      body.classList.remove("disable-hover");
    }, 500);
  };

  updateWindowDimensions = () => {
    const isMobile = window.innerWidth <= BREAKPOINT_MD_MAX;

    if (isMobile !== this.state.isMobile || isMobile !== this.props.isMobile) {
      this.setState({ isMobile });
      this.props.setUI({ isMobile });
    }
  };

  handleDrawerOpen = () => {
    this.props.setUI(
      {
        isDrawerOpen: true
      },
      { email: this.props.profile ? this.props.profile.email : "" }
    );
  };

  handleDrawerClose = () => {
    this.props.setUI(
      {
        isDrawerOpen: false
      },
      { email: this.props.profile ? this.props.profile.email : "" }
    );
  };

  handleDrawerToggle = () => {
    if (this.props.uiSettings) {
      this.props.setUI(
        {
          isDrawerOpen: !this.props.uiSettings.isDrawerOpen
        },
        { email: this.props.profile.email }
      );
    }
  };

  render() {
    const {
      classes,
      isLoaded,
      isEmpty,
      firebase,
      firestore,
      isAdminApproved,
      profile,
      profileProvider,
      auth
    } = this.props;

    const emailVerified =
      firebase &&
      firebase.auth().currentUser &&
      firebase.auth().currentUser.emailVerified;

    const isLoggedIn = isLoaded && !isEmpty && emailVerified && isAdminApproved;

    const isWizardOpen =
      this.state.wizardOpen ||
      (this.state.wizardClosed
        ? false
        : (profile && profile.isFirstTime) || false);

    return (
      <div
        className={`${classes.root} ${
          this.props.uiSettings
            ? this.props.uiSettings.isDrawerOpen
              ? "drawer-opened"
              : "drawer-closed"
            : false
        }`}
      >
        <CssBaseline />

        {!isLoggedIn && isLoaded && (
          <AppBarLogIn
            rightButtonLink={"/login"}
            rightButtonText={
              <FormattedMessage id="APP.LOG_IN" defaultMessage="Log In" />
            }
            isMobile={this.state.isMobile}
          />
        )}

        {!!isLoggedIn && this.state.isMobile && (
          <>
            <ContainerAppBarMobile />
            <ContainerAppBarTopMobile
              handleDrawerToggle={this.handleDrawerToggle}
            />
          </>
        )}

        {!!isLoggedIn && !this.state.isMobile && (
          <ContainerAppBar
            handleOpenWizard={this.handleOpenWizard}
            handleDrawerToggle={this.handleDrawerToggle}
            isMobile={this.state.isMobile}
            location={this.props.location}
          />
        )}

        {!!isLoggedIn && (
          <AppDrawer
            open={
              this.props.uiSettings ? this.props.uiSettings.isDrawerOpen : false
            }
            handleDrawerToggle={this.handleDrawerToggle}
            isMobile={this.state.isMobile}
            isAdvisor={this.props.profile && this.props.profile.isAdvisor}
          />
        )}

        <main className={classes.content} ref={this.mainContainer}>
          <ErrorHandler>{this.props.children}</ErrorHandler>
        </main>

        {profile && !profile.isWizardFinished && (
          <DialogWizard
            isMobile={this.state.isMobile}
            open={isWizardOpen}
            onClose={() =>
              this.setState({ wizardClosed: true, wizardOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

AppContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  firestoreStatus: PropTypes.any,
  myAdvisors: PropTypes.any,
  myClients: PropTypes.any
};

function mapStateToProps(state) {
  return {
    isMobile: state.ui.isMobile,
    profileProvider: state.ui.profileProvider,
    uiSettings: state.ui,

    isLoaded: getAuthIsLoadedState(state),
    isEmpty: getAuthIsEmptyState(state),
    expires: getAuthExpireInState(state),
    isAdminApproved: getProfileIsAdminApprovedState(state),
    profile: getProfileState(state),
    auth: getAuthState(state),
    myClients: getMyClientsState(state),
    myAdvisors: getMyAdvisorsState(state),
    firestoreStatus: state.firestore.status
  };
}
export default compose(
  withStyles(styles),
  withFirestore,
  connect(
    mapStateToProps,
    { setUI, bootData }
  ),
  firestoreConnect(props => {
    const queries = [];

    if (props.auth && props.auth.email) {
      queries.push({
        collection: "advisor",
        where: [["client", "==", props.auth.email]],
        storeAs: "myAdvisors"
      });

      queries.push({
        collection: "advisor",
        where: [["advisor", "==", props.auth.email]],
        storeAs: "myClients"
      });
    }

    return queries;
  }),

  withRouter,
  withFirebase
)(AppContainer);
