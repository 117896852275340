import React, { Component } from "react";

import { compose } from "redux";
import { connect } from "react-redux";

import { withFirebase, withFirestore } from "react-redux-firebase";

import Quiz from "./Quiz";

class QuizConnected extends Component {
  render() {
    return <Quiz {...this.props} />;
  }
}

function mapStateToProps({ firebase: { auth, profile } }) {
  return {
    auth,
    profile
  };
}
export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  )
)(withFirebase(QuizConnected));
