import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconArrowDown = ({
  height = 7,
  width = 10,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 7"
      aria-labelledby={iconTitle}
      fill="none"
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path d="M1 1L5 5L9 1" stroke={fill} strokeWidth="2" opacity={opacity} />
    </svg>
  );
};

IconArrowDown.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconArrowDown;
