import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { List, ListItem, ListItemText, withStyles } from "@material-ui/core";
import styles from "./styles";
import Button from "@material-ui/core/Button/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AppBarBreadcrumbs from "./AppBarBreadcrumbs";
import ContainerAppBarLinks from "./ContainerAppBarLinks";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  getAuthState,
  getProfileFirstNameState,
  getProfileLastNameState,
  getProfileState
} from "store/selectors/firebase";
import { NavLink, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl-v3";

import { setUI } from "AC/ui";

import * as ROUTES from "constants/routes";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import FormControlSelect from "../_common/FormControl/FormControlSelect";
import * as Icons from "components/_common/icons";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogCommon from "../AssetsCommon/DialogCommon";
import ModalInviteClient from "./Modal/ModalInviteClient";
import { languageOptions } from "../Profile/formScheme";
import toast from "../_common/toast";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconEye } from "components/_common/icons";
import { IconClose } from "components/_common/icons";

const menuIconSvg = require("../Dashboard/assets/img/menuIcon.svg");

const timeoutLength = 300;
const defaultLang = "en";

class ContainerAppBar extends PureComponent {
  static propTypes = {
    handleDrawerToggle: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profile: PropTypes.object,
    myClients: PropTypes.any,
    profileProvider: PropTypes.any,
    firestoreStatus: PropTypes.any,
    location: PropTypes.any,
    isMobile: PropTypes.bool
  };

  state = {
    anchorEl: null,
    anchorClientEl: null,
    isProfileMenuOpen: false,

    ///
    anchorElHousehold: null,
    anchorElPlans: null,
    anchorElAssetsOwned: null,
    anchorElPayments: null,

    // Keep track of whether the mouse is over the button or menu
    mouseOverButton: false,
    mouseOverMenu: false,
    ////
    isOpenInviteClient: false,
    anchorLangEl: null,
    selectedIndex: null
  };

  componentDidMount() {
    const { profile } = this.props;

    if (profile) {
      const selectedIndex = profile.language
        ? languageOptions.findIndex(el => el.value === profile.language)
        : 0;

      this.setState({
        selectedIndex
      });
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      firestoreStatus,
      myClients,
      profileProvider,
      auth,
      profile
    } = this.props;

    if (
      // false &&
      auth.uid !== profileProvider &&
      firestoreStatus &&
      firestoreStatus.requested.myClients &&
      !firestoreStatus.requesting.myClients &&
      profileProvider
    ) {
      const isClient =
        myClients &&
        Object.values(myClients).find(
          client => client && client.clientId === profileProvider
        );

      if (!isClient) {
        const { setUI } = this.props;

        setUI(
          {
            profileProvider: auth.uid
          },
          { email: auth.email }
        );
      }
    }

    if (profile && !prevProps.profile) {
      const selectedIndex = profile.language
        ? languageOptions.findIndex(el => el.value === profile.language)
        : 0;

      this.setState({
        selectedIndex
      });
    }
  }

  handleProfileMenuOpen = ev => {
    const { currentTarget } = ev;
    this.setState({ anchorEl: currentTarget, isProfileMenuOpen: true });
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null, isProfileMenuOpen: false });
  };

  handleProfileProviderChange = ev => {
    const { value } = ev.target;
    const { setUI, auth } = this.props;

    if (value === "invite") {
      this.handleInviteModal();
      return;
    }

    setUI(
      {
        profileProvider: value
      },
      { email: auth.email }
    );
  };

  handleInviteModal = ev => {
    this.setState({ isOpenInviteClient: true });
  };

  handleOpenLanguage = ev => {
    this.setState({
      anchorLangEl: ev.currentTarget
    });
  };

  handleCloseLanguage = () => {
    this.setState({
      anchorLangEl: null
    });
  };

  changeLanguage = (lang, index) => () => {
    const { firestore, firebase } = this.props;

    const userUid = firebase.auth().currentUser.uid;
    this.handleCloseLanguage();

    firestore
      .update(`users/${userUid}`, {
        updated: new Date(),
        language: lang
      })
      .then(res => {
        this.setState({ selectedIndex: index, anchorEl: null });

        toast(
          <FormattedMessage
            id="APP.SUCCESSFULLY_SAVED"
            defaultMessage="Saved successfully"
          />
        );
      });
  };

  renderSelectLanguage = () => {
    const { classes } = this.props;

    return (
      <>
        <List component="nav">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            onClick={this.handleOpenLanguage}
            className={classes.langContainer}
          >
            {languageOptions[this.state.selectedIndex] ? (
              <ListItemText
                primary={languageOptions[this.state.selectedIndex].nameShort}
                className={classes.langShow}
                classes={{ primary: classes.langShow }}
              />
            ) : (
              "EN"
            )}

            <img
              className={classes.profileArrow}
              src={require("../Dashboard/assets/img/popupArrow.svg")}
              alt=""
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={this.state.anchorLangEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(this.state.anchorLangEl)}
          onClose={this.handleCloseLanguage}
        >
          {languageOptions.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === this.state.selectedIndex}
              onClick={this.changeLanguage(option.value, index)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };
  handleCloseClientAction = () => {
    this.setState({
      anchorClientEl: null,
      menuClientId: null
    });
  };

  handleClickClientMenu = id => ev => {
    ev.stopPropagation();

    this.setState({
      anchorClientEl: ev.currentTarget,
      menuClientId: id
    });
  };

  handleClientView = () => {
    this.handleProfileProviderChangeNew()();

    if (this.props.history) {
      this.props.history.push(ROUTES.HOME);
    }

    this.handleCloseClientAction();
  };

  handleClientConfig = () => {
    this.handleProfileProviderChangeNew()();

    if (this.props.history) {
      this.props.history.push(ROUTES.CONFIGURATION_ASSETS_DEPLETED);
    }

    this.handleCloseClientAction();
  };

  handleProfileProviderChangeNew = id => ev => {
    const value = id ? id : this.state.menuClientId;
    const { setUI, auth } = this.props;

    setUI(
      {
        profileProvider: value
      },
      { email: auth.email }
    );

    if (this.props.history) {
      this.props.history.push(ROUTES.HOME);
    }
  };
  renderSelectClient = () => {
    const { myClients, profileProvider, auth, profile } = this.props;

    if (!profile.isAdvisor) {
      return null;
    }

    let clientUid;
    let clientName;

    if (profileProvider && profileProvider !== auth.uid && myClients) {
      clientUid = profileProvider;
      Object.keys(myClients).forEach(key => {
        const advisor = myClients[key];

        if (advisor && advisor.clientId === profileProvider) {
          clientName = advisor.clientName || advisor.client;
        }
      });
    }

    const makeMenuClient = (
      <Menu
        id="lock-menu"
        anchorEl={this.state.anchorClientEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        getContentAnchorEl={null}
        open={Boolean(this.state.anchorClientEl)}
        onClose={this.handleCloseClientAction}
      >
        <MenuItem onClick={this.handleClientView}>
          <FormattedMessage id="APP.CONTAINER.VIEW" defaultMessage="View" />
        </MenuItem>
        <MenuItem onClick={this.handleClientConfig}>
          <FormattedMessage
            id="APP.CONTAINER.CONFIGURATION"
            defaultMessage="Configuration"
          />
        </MenuItem>
      </Menu>
    );

    const Container = myClients ? (
      <>
        <div
          className={`app-bar-link app-bar-link--provider ${
            this.state.anchorClientEl ? "app-bar-link--active" : ""
          }`}
        >
          {makeMenuClient}

          <span
            className={`app-bar-link__title app-bar-link__title--client ${
              clientName ? "app-bar-link__title--client-active" : ""
            }`}
          >
            <IconEye />

            {clientUid && clientName && (
              <span className="app-bar__client">{clientName}</span>
            )}
          </span>

          <ul className="app-bar-submenu">
            {Object.keys(myClients).map(key => {
              const client = myClients[key];

              if (
                !client ||
                (client.clientId !== profileProvider && !client.isFavorite)
              ) {
                return null;
              }

              return (
                <li
                  key={key}
                  className="app-bar-submenu__item app-bar-submenu__item--client"
                  onClick={this.handleProfileProviderChangeNew(client.clientId)}
                >
                  <span>{client.clientName || client.client}</span>

                  <span className="app-bar-submenu__item-button">
                    <IconButton
                      aria-label="More"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={this.handleClickClientMenu(client.clientId)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>

        {clientUid && clientName && (
          <span
            className="app-bar__client--reset"
            onClick={this.handleProfileProviderChangeNew()}
          >
            <IconClose color={"red"} iconTitle="" />
          </span>
        )}
      </>
    ) : null;

    return Container;
  };

  renderSelectClientOld = () => {
    const { classes, myClients, profile, profileProvider, auth } = this.props;

    if (!profile.isAdvisor) {
      return null;
    }

    let optionsClient = [{ name: "None", namei18N: "None", value: auth.uid }];
    if (myClients) {
      const clientsOption = Object.keys(myClients)
        .map(clientKey => {
          const advisorEntity = myClients[clientKey];

          if (
            !advisorEntity ||
            (!advisorEntity.isFavorite &&
              advisorEntity.clientId !== profileProvider)
          ) {
            return null;
          }
          return {
            name: advisorEntity.clientName || advisorEntity.client,
            value: advisorEntity.clientId
          };
        })
        .filter(el => !!el);

      optionsClient = [optionsClient[0], ...clientsOption];
    }

    return (
      <div className={classes.clientSelect}>
        <FormControlSelect
          fieldName="profileProvider"
          label="Profile Provider"
          labelI18n="ADVISOR.MAIN.APP_BAR.SELECT.LABEL"
          options={optionsClient}
          value={profileProvider || auth.uid}
          handleChange={this.handleProfileProviderChange}
          hideNoneOption
          MenuProps={{
            classes: { list: classes.clientInviteContainer }
          }}
          additionalBottomItem={
            <MenuItem
              classes={{
                root: classes.clientInvite
              }}
              key="invite"
              value={"invite"}
              onClick={this.handleInviteModal}
            >
              <FormattedMessage
                id="APP.INVITE_NEW_CLIENT"
                defaultMessage="Invite New Client"
              />
            </MenuItem>
          }
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      handleDrawerToggle,
      profile,
      profileProvider,
      auth,
      location,
      isMobile,
      firebase,
      firestore
    } = this.props;

    if (!profile.isLoaded) {
      return null;
    }

    const renderProfileMenu = (
      <Menu
        id="profile-menu"
        getContentAnchorEl={null}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: classes.topProfileMenu
        }}
        open={this.state.isProfileMenuOpen}
        onClose={this.handleProfileMenuClose}
      >
        <NavLink
          to={ROUTES.PROFILE}
          style={{ color: "inherit", textDecoration: "none", outline: "none" }}
        >
          <MenuItem onClick={this.handleProfileMenuClose}>
            <FormattedMessage id="APP.PROFILE" deafultMessage="Profile" />
          </MenuItem>
        </NavLink>

        <NavLink
          to={{
            pathname: ROUTES.CONFIGURATION_ASSETS_DEPLETED,
            search: `?selfAccount=true`
          }}
          style={{ color: "inherit", textDecoration: "none", outline: "none" }}
        >
          <MenuItem onClick={this.handleProfileMenuClose}>
            <FormattedMessage
              id="DRAWER.PROFILE.CONFIGURATION"
              deafultMessage="Configuration"
            />
          </MenuItem>
        </NavLink>

        <NavLink
          to={ROUTES.ADVISOR_PERMISSIONS}
          style={{ color: "inherit", textDecoration: "none", outline: "none" }}
        >
          <MenuItem onClick={this.handleProfileMenuClose}>
            <FormattedMessage
              id="DRAWER.PROFILE.ADVISOR_PERMISSIONS"
              deafultMessage="Advisor Permissions"
            />
          </MenuItem>
        </NavLink>

        {profile && !profile.isWizardFinished && (
          <MenuItem onClick={this.props.handleOpenWizard}>
            <FormattedMessage
              id="DRAWER.PROFILE.OPEN_WIZARD"
              deafultMessage="Open Wizard"
            />
          </MenuItem>
        )}

        <NavLink
          to={"/logout"}
          style={{ color: "inherit", textDecoration: "none", outline: "none" }}
        >
          <MenuItem onClick={this.handleProfileMenuClose}>
            <FormattedMessage id="APP.LOG_OUT2" defaultMessage={"Log Out"} />
          </MenuItem>
        </NavLink>
      </Menu>
    );

    const currentPath =
      location && location.pathname && `/${location.pathname.split("/")[1]}`;

    const handleClose = () => {
      this.setState({ isOpenInviteClient: false });
    };

    return (
      <>
        <DialogCommon
          isOpen={this.state.isOpenInviteClient}
          handleClose={handleClose}
          disableActions
          disableTitle
          contentClass={classes.clientInviteDialogContent}
          fullScreen={isMobile}
        >
          {this.state.isOpenInviteClient && (
            <ModalInviteClient
              profile={profile}
              isMobile={isMobile}
              firebase={firebase}
              firestore={firestore}
              auth={auth}
              handleClose={handleClose}
            />
          )}
        </DialogCommon>

        <AppBar position="fixed" color="default" className={classes.header}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classNames(classes.menuButton)}
            >
              <img src={menuIconSvg} alt="" />
            </IconButton>

            <img
              className={classes.logo}
              src="/assets/img/logo.png"
              alt="XCurve"
              width={89}
              height={16}
            />

            <AppBarBreadcrumbs />

            <ContainerAppBarLinks
              isAdvisor={profile.isAdvisor}
              currentPath={currentPath}
            />

            {this.renderSelectClient()}

            {/*{this.renderSelectClientOld()}*/}

            <Button
              aria-controls="profile-menu"
              className={classes.profileButton}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >
              <div className={classes.profileAva}>
                <Icons.IconAvatar
                  height={24}
                  width={24}
                  color={"textTetrieary"}
                />
              </div>

              <span className={classes.profileName}>
                {`${!!this.props.firstName ? this.props.firstName : ""} `}
              </span>

              <img
                className={classes.profileArrow}
                src={require("../Dashboard/assets/img/popupArrow.svg")}
                alt=""
              />
            </Button>

            {this.renderSelectLanguage()}
          </Toolbar>
        </AppBar>
        {renderProfileMenu}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    firebase: { auth },
    firestore: { data, status }
  } = state;

  return {
    auth,
    myClients: data && data.myClients,
    profileProvider: state.ui.profileProvider,
    firstName: getProfileFirstNameState(state),
    lastName: getProfileLastNameState(state),
    profile: getProfileState(state),
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    { setUI }
  ),
  withStyles(styles),
  withRouter
)(ContainerAppBar);
