import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import EventsSlider from "../PlansEvents/EventsSlider";

import * as Icons from "components/_common/icons";

import "./Operations.scss";
import IconContainer from "../_common/IconContainer";
import { formatMoneyIntWithSign } from "../../lib/helpers";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core";
import { colors } from "../../constants/styles";
import ReactResizeDetector from "react-resize-detector";

const styles = theme => ({
  switchLabel: {
    fontSize: 14,
    lineHeight: "21px",
    color: colors.textSecondary
  }
});

const ItemLowLevel = props => {
  const { data, transKey, resetLowLevelEntity, header } = props;

  const { all, ...restDefault } = data;

  return (
    <div className="op-body__item op-body__item--low">
      <div className="operations__item-head">
        <button
          className="operations__item-head-icon"
          onClick={resetLowLevelEntity}
        >
          <IconContainer width={32} height={32} color={"brand"}>
            <Icons.IconArrowLeft width={24} height={24} color={"brand"} />
          </IconContainer>
        </button>

        <div className="operations__item-head-title">
          <div className="operations__item-head-label">
            <FormattedMessage id={transKey} defaultMessage={header} />
          </div>
          <div className="operations__item-head-total">
            {formatMoneyIntWithSign(data.all)}
          </div>
        </div>

        <div className="operations__item-head-arrow">
          <Icons.IconDocEdit
            width={24}
            height={24}
            opacity={0.5}
            color={"brand"}
          />
        </div>
      </div>

      <div className="operations__item-body op-body">
        {Object.keys(restDefault).map(key => {
          const row = restDefault[key];

          return (
            <div key={key} className="op-body__item">
              <div className="operations__item-head operations__item-head-item">
                <div className="operations__item-head-title">
                  <div className="operations__item-head-label">
                    <FormattedMessage
                      id={`${transKey}.${key}`}
                      defaultMessage={key}
                    />
                  </div>
                  <div className="operations__item-head-total">
                    {formatMoneyIntWithSign(row)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const Item1stLevel = props => {
  const { data, selectLowLevel, header, headerI18n } = props;

  return (
    <div className="op-body__item">
      <div className="operations__item-head" onClick={selectLowLevel}>
        <div className="operations__item-head-title">
          <div className="operations__item-head-label operations__item-head-label--clickable">
            <FormattedMessage id={headerI18n} defaultMessage={header} />
          </div>
          <div className="operations__item-head-total">
            {formatMoneyIntWithSign(data.all)}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemTop1stLevel = props => {
  const { data, transKey } = props;

  const { all, ...restDefault } = data;

  return Object.keys(restDefault).map(key => {
    return (
      <div key={key} className="op-body__item">
        <div className="operations__item-head">
          <div className="operations__item-head-title">
            <div className="operations__item-head-label">
              <FormattedMessage id={transKey + key} defaultMessage={key} />
            </div>
            <div className="operations__item-head-total">
              {formatMoneyIntWithSign(restDefault[key])}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

const ItemTaxCategory = props => {
  const { data } = props;

  return (
    <div className="operations__item operations__item--always-open">
      <div className="operations__item-head operations__item-head--top">
        <div className="operations__item-head-icon">
          <IconContainer width={32} height={32} color={"yellow"}>
            <Icons.IconMoneyOut width={24} height={24} color={"yellow"} />
          </IconContainer>
        </div>

        <div className="operations__item-head-title">
          <div className="operations__item-head-label">
            <FormattedMessage
              id="DASHBOARD.STATISTIC.LABEL.GROUP.TAX_CATEGORY"
              defaultMessage="Tax Category"
            />
          </div>
          <div className="operations__item-head-total">
            {formatMoneyIntWithSign(data.TaxCategories.all)}
          </div>
        </div>
      </div>

      <div className="operations__item-body op-body">
        <ItemTop1stLevel
          data={data.TaxCategories}
          transKey="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES."
        />
      </div>
    </div>
  );
};

const ItemWealth = props => {
  const { data, isOpen, toggle, selectThirdLevel, dataThirdLevel } = props;

  if (isOpen && dataThirdLevel) {
    return (
      <ItemLowLevel
        selectThirdLevel={selectThirdLevel}
        resetLowLevelEntity={selectThirdLevel(null)}
        data={data[dataThirdLevel.data]}
        transKey={dataThirdLevel.transKey}
      />
    );
  }

  return (
    <div
      className={`operations__item ${
        isOpen ? "operations__item--opened" : ""
      } ${dataThirdLevel ? "operations__item--opened-low-level" : ""}`}
    >
      <div
        className="operations__item-head operations__item-head--top"
        onClick={toggle}
      >
        <div className="operations__item-head-icon">
          <IconContainer width={32} height={32} color={"green"}>
            <Icons.IconDeferredComp width={24} height={24} color={"green"} />
          </IconContainer>
        </div>

        <div className="operations__item-head-title">
          <div className="operations__item-head-label">
            <FormattedMessage
              id="DASHBOARD.STATISTIC.LABEL.GROUP.NET_WEALTH"
              defaultMessage="Net Wealth"
            />
          </div>
          <div className="operations__item-head-total">
            {formatMoneyIntWithSign(data.Assets.all - data.Liabilities.all)}
          </div>
        </div>

        <div
          className={`operations__item-head-arrow ${
            isOpen ? "operations__item-head-arrow--opened" : ""
          }`}
        >
          <Icons.IconArrowUp width={24} height={24} color={"textTetrieary"} />
        </div>
      </div>

      {isOpen && (
        <div className="operations__item-body op-body">
          <Item1stLevel
            data={data.Assets}
            selectLowLevel={selectThirdLevel(
              "Assets",
              "DASHBOARD.STATISTIC.LABEL.ASSETS"
            )}
            header="Assets"
            headerI18n="DASHBOARD.STATISTIC.LABEL.ASSETS"
          />

          <Item1stLevel
            data={data.Liabilities}
            selectLowLevel={selectThirdLevel(
              "Liabilities",
              "DASHBOARD.STATISTIC.LABEL.LIABILITIES"
            )}
            header="Liabilities"
            headerI18n="DASHBOARD.STATISTIC.LABEL.LIABILITIES"
          />
        </div>
      )}
    </div>
  );
};

const ItemSavings = props => {
  const { toggle, isOpen, data, selectThirdLevel, dataThirdLevel } = props;

  if (isOpen && dataThirdLevel) {
    const thirdLevelData =
      dataThirdLevel.data === "MoneyOutDetail.taxes"
        ? data.MoneyOutDetail.taxes
        : data[dataThirdLevel.data];

    return (
      <ItemLowLevel
        resetLowLevelEntity={selectThirdLevel(null)}
        data={thirdLevelData}
        transKey={dataThirdLevel.transKey}
      />
    );
  }

  return (
    <div
      className={`operations__item operations__item--first ${
        isOpen ? "operations__item--opened" : ""
      } ${dataThirdLevel ? "operations__item--opened-low-level" : ""}`}
    >
      <div
        className="operations__item-head operations__item-head--top"
        onClick={toggle}
      >
        <div className="operations__item-head-icon">
          <IconContainer width={32} height={32} color={"green"}>
            <Icons.IconSaving width={24} height={24} color={"green"} />
          </IconContainer>
        </div>

        <div className="operations__item-head-title">
          <div className="operations__item-head-label">
            <FormattedMessage
              id="DASHBOARD.STATISTIC.LABEL.GROUP.SAVINGS"
              defaultMessage="Savings"
            />
          </div>
          <div className="operations__item-head-total">
            {formatMoneyIntWithSign(data.MoneyIn.all - data.MoneyOut.all)}
          </div>
        </div>

        <div
          className={`operations__item-head-arrow ${
            isOpen ? "operations__item-head-arrow--opened" : ""
          }`}
        >
          <Icons.IconArrowUp width={24} height={24} color={"textTetrieary"} />
        </div>
      </div>

      {isOpen && (
        <div className="operations__item-body op-body">
          <Item1stLevel
            data={data.MoneyIn}
            selectLowLevel={selectThirdLevel(
              "MoneyIn",
              "DASHBOARD.STATISTIC.LABEL.MONEY_IN"
            )}
            header="Money In"
            headerI18n="DASHBOARD.STATISTIC.LABEL.MONEY_IN"
          />

          <Item1stLevel
            data={data.MoneyOut}
            selectLowLevel={selectThirdLevel(
              "MoneyOut",
              "DASHBOARD.STATISTIC.LABEL.MONEY_OUT"
            )}
            header="Money Out"
            headerI18n="DASHBOARD.STATISTIC.LABEL.MONEY_OUT"
          />

          <Item1stLevel
            data={data.MoneyOutDetail.taxes}
            selectLowLevel={selectThirdLevel(
              "MoneyOutDetail.taxes",
              "DASHBOARD.STATISTIC.LABEL.ESTIMATED_TAXES"
            )}
            header="Total Tax"
            headerI18n="DASHBOARD.STATISTIC.LABEL.TOTAL_TAX"
          />
        </div>
      )}
    </div>
  );
};

class Operations extends PureComponent {
  static propTypes = {
    isDashboard: PropTypes.bool,
    isEmpty: PropTypes.bool,
    propsState: PropTypes.object,
    activeYear: PropTypes.any,
    activeYears: PropTypes.any,
    isCombineDashboard: PropTypes.any
  };

  state = {
    cardsWidth: null,
    isOpenWealth: false,
    isOpenSavings: false,
    thirdLevelWealth: null,
    thirdLevelSavings: null
  };
  cardsRef = React.createRef();

  updateCardsDimensions = width => {
    this.setState({
      cardsWidth: width
    });
  };

  handleEditAsset = () => {};

  selectThirdLevel = type => (data, transKey) => () => {
    if (!type) {
      return;
    }

    if (!data) {
      return this.setState({
        [type]: null
      });
    }

    this.setState({
      [type]: { data, transKey }
    });
  };

  toggleWealth = () => {
    this.setState(prevState => ({
      isOpenWealth: !prevState.isOpenWealth
    }));
  };

  toggleSavings = () => {
    this.setState(prevState => ({
      isOpenSavings: !prevState.isOpenSavings
    }));
  };

  handleChangeNoOverdraft = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardNoOverdraft: !checked
      },
      { email: this.props.profile.email }
    );
  };

  _renderContent = () => {
    const {
      isMobile,
      propsState,
      dashboardNoOverdraft,
      activeYear,
      activeYears
    } = this.props;
    const { fullCalc, currentAge } = propsState;

    const years = Object.keys(fullCalc);

    const activeSlide = activeYear
      ? activeYears
        ? activeYears.indexOf(`${activeYear}`)
        : years.indexOf(`${activeYear}`)
      : null;

    const totalSlides =
      activeYears && activeYears.length ? activeYears.length : years.length;

    return (
      <div className="cards cards--slider" ref={this.cardsRef}>
        <ReactResizeDetector
          handleWidth
          onResize={this.updateCardsDimensions}
        />

        <div className="operations__caption">
          <span className="operations__caption-age">
            <FormattedMessage
              id="OPERATIONS.MAIN.LABEL.AGE"
              defaultMessage="Age"
            />
          </span>
        </div>

        <EventsSlider
          containerWidth={this.state.cardsWidth}
          isMobile={isMobile}
          ref={this.slider}
          totalSlides={totalSlides}
          activeSlide={activeSlide}

          // isLazyLoad={true}
        >
          {years.map(year => {
            if (
              year < currentAge ||
              (activeYears && !~activeYears.indexOf(year))
            ) {
              return null;
            }

            const yearData = dashboardNoOverdraft
              ? fullCalc[year].overdraft
              : fullCalc[year];

            return (
              <div key={year} className="operations__slide">
                <h3 className="operations__age-container">
                  <span className="operations__age">{year}</span>
                </h3>

                <div className="operations__card-container">
                  <ItemSavings
                    dataThirdLevel={this.state.thirdLevelSavings}
                    selectThirdLevel={this.selectThirdLevel(
                      "thirdLevelSavings"
                    )}
                    isOpen={this.state.isOpenSavings}
                    toggle={this.toggleSavings}
                    icon={"IconSaving"}
                    color={"green"}
                    header={"Savings"}
                    headerI18n={"DASHBOARD.STATISTIC.LABEL.GROUP.SAVINGS"}
                    data={yearData}
                  />

                  <ItemWealth
                    dataThirdLevel={this.state.thirdLevelWealth}
                    selectThirdLevel={this.selectThirdLevel("thirdLevelWealth")}
                    isOpen={this.state.isOpenWealth}
                    toggle={this.toggleWealth}
                    icon={"IconDeferredComp"}
                    color={"green"}
                    header={"Wealth"}
                    headerI18n={"DASHBOARD.STATISTIC.LABEL.GROUP.WEALTH"}
                    data={yearData}
                  />

                  <ItemTaxCategory
                    icon={"IconMoneyOut"}
                    color={"yellow"}
                    header={"Tax Category"}
                    headerI18n={"DASHBOARD.STATISTIC.LABEL.GROUP.TAX_CATEGORY"}
                    data={yearData}
                  />

                  <div />
                </div>
              </div>
            );
          })}
        </EventsSlider>
      </div>
    );
  };

  _renderEmpty = () => {
    return (
      <div className="operations__caption">
        <span className="operations__caption-age">
          <FormattedMessage
            id="OPERATIONS.MAIN.LABEL.EMPTY_DATA"
            defaultMessage="There no data to show"
          />
        </span>
      </div>
    );
  };

  render() {
    const {
      isEmpty,
      isDashboard,
      classes,
      dashboardNoOverdraft,
      isCombineDashboard
    } = this.props;

    if (isCombineDashboard) {
      return (
        <div
          className={`content-main content-main--operations ${
            isDashboard ? "content-main--operations-dashboard" : ""
          }`}
        >
          {isEmpty ? this._renderEmpty() : this._renderContent()}
        </div>
      );
    }

    return (
      <main className="main-container main-container--dashboard">
        <div className="main-content">
          <div className="content-header content-header--dashboard">
            <h1 className="content-header__title">
              <FormattedMessage
                id="OPERATIONS.MAIN.TITLE"
                defaultMessage="Operations"
              />
            </h1>

            <div className="dashboard__switch dashboard__switch--right">
              <FormLabel
                htmlFor="switch-no-overdraft"
                className={classes.switchLabel}
              >
                <FormattedMessage
                  id="DASHBOARD.MAIN.SWITCH.OVERDRAFT"
                  defaultMessage="Overdraft"
                />
              </FormLabel>
              <Switch
                id="switch-no-overdraft"
                checked={!dashboardNoOverdraft}
                name="noOverdraft"
                onChange={this.handleChangeNoOverdraft}
              />
              <FormLabel
                htmlFor="switch-no-overdraft"
                className={classes.switchLabel}
              >
                <FormattedMessage
                  id="DASHBOARD.MAIN.SWITCH.NO_OVERDRAFT"
                  defaultMessage="No Overdraft"
                />
              </FormLabel>
            </div>
          </div>

          <div className="content-main content-main--operations">
            {isEmpty ? this._renderEmpty() : this._renderContent()}
          </div>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(Operations);
