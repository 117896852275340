import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconElectrical = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M17.4688 2C17.2109 2 16.9453 2.08594 16.75 2.28125L13.9375 5.125L15.3438 6.53125L18.1875 3.71875C18.5781 3.32812 18.5781 2.67188 18.1875 2.28125C17.9922 2.08594 17.7266 2 17.4688 2ZM11.8125 4.40625L10.0938 6.125L10.8125 6.8125L8.40625 10.1875C7.74609 11.1133 7.76172 12.2617 8.4375 12.9375L11.0625 15.5625C11.7383 16.2383 12.8867 16.2578 13.8125 15.5938L17.1875 13.1875L17.875 13.9062L19.5938 12.1875L11.8125 4.40625ZM21 5.53125C20.7422 5.53125 20.4766 5.61719 20.2812 5.8125L17.4688 8.65625L18.875 10.0625L21.7188 7.25C22.1094 6.85938 22.1094 6.20312 21.7188 5.8125C21.5234 5.61719 21.2578 5.53125 21 5.53125ZM7.4375 14.4375L2.4375 19.4375L4.5625 21.5625L9.5625 16.5625L7.4375 14.4375Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconElectrical.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconElectrical;
