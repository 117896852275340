import React, { Component } from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles } from "@material-ui/core/styles";
import { colors } from "constants/styles";

import "./FormControl.scss";
import { injectIntl } from "react-intl-v3";

class FormControlSelect extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelI18n: PropTypes.string,
    classNameExtraContainer: PropTypes.string,
    classNameExtra: PropTypes.string,
    InputLabelProp: PropTypes.object,
    helperText: PropTypes.any,
    MenuProps: PropTypes.any,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    bgSecondary: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    classesAdditional: PropTypes.any,
    cleanGap: PropTypes.bool,
    required: PropTypes.bool,
    shrink: PropTypes.bool,
    error: PropTypes.bool,
    hideNoneOption: PropTypes.any,
    noneOptionLabel: PropTypes.any,
    showNoneName: PropTypes.any,
    autoFocus: PropTypes.bool,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    additionalBottomItem: PropTypes.any
  };

  prepareOptions = options => {
    const { intl } = this.props;

    return options.map(el => {
      return !!el.nameI18n
        ? {
            ...el,
            nameI18n: intl.formatMessage({
              id: el.nameI18n,
              defaultMessage: el.name
            })
          }
        : el;
    });
  };

  render() {
    const {
      classes,
      classesAdditional = "",
      classNameExtra,
      shrink,
      options,
      classNameContainer,
      fieldName,
      label,
      labelI18n,
      value,
      handleChange,
      bgSecondary,
      required,
      error,
      helperText,
      hideNoneOption,
      noneOptionLabel,
      showNoneName,
      nonAutoWidth,
      autoFocus = false,
      cleanGap,
      hideLabel,
      hideBorder,
      disabled,
      additionalBottomItem
    } = this.props;

    const { intl } = this.props;

    const labelIntl = labelI18n
      ? intl.formatMessage({
          id: labelI18n,
          defaultMessage: label
        })
      : label;

    return (
      <FormControl
        className={`${classes.formControl} ${
          !nonAutoWidth ? classes.fullWidth : ""
        } ${cleanGap ? classes.cleanGap : ""} ${
          classNameContainer ? classNameContainer : ""
        }`}
      >
        {!hideLabel && !!labelIntl && (
          <InputLabel
            shrink={shrink}
            htmlFor={fieldName}
            required={required}
            error={error}
          >
            {labelIntl}
          </InputLabel>
        )}

        <Select
          error={error}
          required={required}
          disabled={disabled}
          className={`${
            bgSecondary ? classes.selectBgSecondary : classes.select
          }  ${hideBorder ? classes.hideBorder : ""} ${classesAdditional} ${
            classNameExtra ? classNameExtra : ""
          }`}
          classes={{
            selectMenu: classes.selectMenu
          }}
          displayEmpty={false}
          autoWidth={!nonAutoWidth}
          value={value}
          onChange={handleChange}
          inputProps={{
            name: fieldName,
            id: fieldName
          }}
          autoFocus={autoFocus}
        >
          {!hideNoneOption && (
            <MenuItem value={showNoneName || ""} key="none">
              {noneOptionLabel || "None"}
            </MenuItem>
          )}
          {this.prepareOptions(options).map(el => {
            return (
              <MenuItem
                key={el.nameI18n || el.name || el.value}
                value={el.value}
              >
                {!!el.nameI18n ? el.nameI18n : el.name}
              </MenuItem>
            );
          })}

          {Boolean(additionalBottomItem) && additionalBottomItem}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  formControl: {
    display: "flex",

    margin: 0,
    marginBottom: 16,
    minWidth: 90
    // fullWidth: true
  },

  fullWidth: {
    width: "100%"
  },

  cleanGap: {
    marginBottom: 0
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },

  selectBgSecondary: {
    borderBottomColor: colors.divider,
    backgroundColor: colors.backgroundSecondary
  },

  select: {
    textAlign: "left",
    "&:before": {
      borderColor: colors.divider
    },

    "&:not(.important):not(.important):not(.important):not(.important):not(.important):hover:before": {
      borderColor: colors.ui,
      borderBottomWidth: 1
    }
  },
  selectMenu: {
    maxHeight: "50vh"
  },
  hideBorder: {
    color: colors.textTetrieary,
    "&focused": {
      backgroundColor: colors.yellow
    },
    "&.focused": {
      backgroundColor: colors.yellow
    },
    "&:not(.important):not(.important):not(.important):not(.important):not(.important):before": {
      height: 0,
      border: "none"
    },

    "&:not(.important):not(.important):not(.important):not(.important):not(.important):hover:before": {
      height: 0,
      border: "none"
    },
    "&:not(.important):not(.important):not(.important):not(.important):not(.important):hover:after": {
      height: 0,
      border: "none"
    },
    "&:not(.important):not(.important):not(.important):not(.important):not(.important):after": {
      height: 0,
      border: "none"
    }
  },

  cssUnderline: {
    "&:before": {
      borderBottomColor: colors.divider
    },
    "&:hover:before": {
      borderBottomColor: colors.ui,
      borderBottomWidth: 1
    }
  }
});

export default withStyles(styles)(injectIntl(FormControlSelect));
