export default {
  apiKey: "AIzaSyCgN6fVUe8svljEBWBCpcba5n-B0uun8UM",
  authDomain: "xcurve-dev.firebaseapp.com",
  databaseURL: "https://xcurve-dev.firebaseio.com",
  projectId: "xcurve-dev",
  storageBucket: "xcurve-dev.appspot.com",
  messagingSenderId: "984375122515"
};

export const functionsURL =
  "https://us-central1-xcurve-3a19e.cloudfunctions.net";
