import React from "react";

import { ToastContainer } from "react-toastify";
import "./ContainerToast.scss";
import NoSsr from "@material-ui/core/NoSsr/NoSsr";

const ContainerToast = ({ children }) => {
  return (
    <NoSsr>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      {children}
    </NoSsr>
  );
};

export default ContainerToast;
