import React, { Component } from "react";

import { withFirebase } from "react-redux-firebase";
import { actionTypes } from "redux-firestore";

import { Redirect } from "react-router";
import { connect } from "react-redux";

class LogoutDev extends Component {
  render() {
    this.props.firebase.logout();
    this.props.resetStore();

    return <Redirect to="/" />;
  }
}

const mapDispatchToProps = dispatch => ({
  resetStore: () => dispatch({ type: actionTypes.CLEAR_DATA })
});

export default connect(
  null,
  mapDispatchToProps
)(withFirebase(LogoutDev));
