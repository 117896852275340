import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  optionsLifeEventTypeValues,
  optionsOwnerEventLifeValues,
  optionsOwnerFamily,
  valuesMoneyInType
} from "../PlansEvents/formScheme";
import {
  ENTITIES as entitiesGoals,
  GLOBAL_TYPE_USE_ASSETS as globalTypeGoals
} from "../PlansGoals/formScheme";
import { trimString } from "../../lib/helpers";
import {
  ENTITIES as useEntities,
  GLOBAL_TYPE_USE_ASSETS as globalTypeUse
} from "../HouseholdAssetsUse/formScheme";
import { ENTITIES as debtEntities } from "../HouseholdDebt/formScheme";
import { FormattedMessage } from "react-intl-v3";
import toast from "../_common/toast";
import ButtonAddAsset from "../_common/Button/ButtonAddAsset";
import ButtonCommon from "../_common/Button/ButtonCommon";
import Button from "@material-ui/core/Button";

import * as Icons from "../_common/icons";
import AdditionalForm from "../_common/AdditionalForm";
import diffYears from "date-fns/differenceInCalendarYears";
import FormControlSelect from "../_common/FormControl/FormControlSelect";
import FormDatePicker from "../_common/FormControl/FormDatePicker";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlInput from "../_common/FormControl/FormControlInput";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogFinishWizard from "./DialogFinishWizard";
import DialogCommon from "./DialogCommon";
import ButtonSideItem from "../_common/Button/ButtonSideItem";
import ButtonForIcon from "../_common/Button/ButtonForIcon";
import IconContainer from "../_common/IconContainer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { BOX_SHADOW_3, colors } from "../../constants/styles";
import { withStyles } from "@material-ui/core";
import DialogRemoveAsset from "./DialogRemoveAsset";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonMUI from "@material-ui/core/Button/Button";

const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];

const optionsAsset = {};

class AssetForm extends Component {
  state = {
    debtOpened: [],

    ...this.props.formsInitialState,

    validationScheme: Object.assign({}, this.props.formsValidationScheme),
    // custom
    optionsOwner: optionsOwner,
    optionsOwnerAdults: optionsOwner,
    optionsAsset: optionsAsset,

    isDialogFinishWizardOpen: false,
    isWizardFinishing: false,

    isChanged: false,
    isDialogNotSaved: false,
    isDialogStdToWhatIf: false,
    isChangeToStandardDialog: false,
    isChangeToWhatIfDialog: false,
    isDontShowAgainToStandard: false,
    isDontShowAgainToWhatIf: false,

    isAddAsWhatIf: false,
    entityId: null
  };

  componentDidMount() {
    const {
      formEditOpened,
      duplicate,
      family,
      customValues,
      assetType,
      GLOBAL_TYPE_USE_ASSETS
    } = this.props;

    const uidAsset = (formEditOpened && formEditOpened.uidAsset) || duplicate;

    if (uidAsset) {
      this.handleEditAsset(uidAsset)();
    }

    if (customValues && Object.keys(customValues).length) {
      // custom new goal from dashboard
      if (GLOBAL_TYPE_USE_ASSETS === globalTypeGoals && customValues.startAge) {
        const customAge = customValues.startAge;
        delete customValues.startAge;

        if (
          assetType === entitiesGoals.ENTITY_GENERAL ||
          assetType === entitiesGoals.ENTITY_OTHER
        ) {
          customValues.targetAge = customAge;
        }

        if (
          assetType === entitiesGoals.ENTITY_HOME_PURCHASE ||
          assetType === entitiesGoals.ENTITY_LEGACY ||
          assetType === entitiesGoals.ENTITY_MAJOR_EXPANSE
        ) {
          customValues.expectedAge = customAge;
        }

        if (assetType === entitiesGoals.ENTITY_RETIREMENT) {
          customValues.atAge = customAge;
        }
        if (assetType === entitiesGoals.ENTITY_EDUCATION) {
          customValues.ageAttending = customAge;
        }

        if (assetType === entitiesGoals.ENTITY_PROTECT_LOVED) {
          // customValues.startAge = customAge;
        }
      }

      this.setState(prevState => ({
        [assetType]: {
          ...prevState[assetType],
          ...customValues
        }
      }));
    }

    if (family) {
      this.handleUpdateOwnersOptions();
    }

    if (this.props.additionalAssets) {
      this.handleUpdateAdditionalAssetsOptions();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      formEditOpened,
      additionalAssets,
      duplicate,
      family,
      childs
    } = this.props;
    // const { formEditOpenedPrev, duplicatePrev } = prevProps;

    const uidAsset = (formEditOpened && formEditOpened.uidAsset) || duplicate;

    if (
      (!prevProps.formEditOpened && formEditOpened) ||
      (!prevProps.duplicate && this.props.duplicate)
    ) {
      this.handleEditAsset(uidAsset)();
    }

    if (additionalAssets !== prevProps.additionalAssets) {
      this.handleUpdateAdditionalAssetsOptions();
    }

    if (
      (!prevProps.family && family) ||
      (!prevProps.childs && childs) ||
      (family &&
        family.displayName &&
        prevProps.family.displayName !== family.displayName)
    ) {
      this.handleUpdateOwnersOptions();
    }
  }

  /// Dialog Remove asset
  handleDialogRemoveAgree = () => {
    const { formEditOpened } = this.props;

    const entityId = formEditOpened && formEditOpened.uidAsset;

    this.handleDeleteAsset(entityId)(); // new

    this.handleDialogRemoveClose();

    this.props.onClose(true);
  };

  handleDialogRemoveOpen = () => () => {
    const { family, formEditOpened } = this.props;

    const entityId = formEditOpened && formEditOpened.uidAsset;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleDialogRemoveOpen(entityId)
      );
    }

    this.setState({
      entityId
    });
  };

  handleDeleteAsset = uidAsset => () => {
    const {
      firestore,
      translations,
      GLOBAL_COLLECTION,
      updateFieldGlobalType,
      assets
    } = this.props;

    const collection = GLOBAL_COLLECTION || "userAssets";

    if (updateFieldGlobalType) {
      if (updateFieldGlobalType.debtToUse) {
        const asset = assets && assets.find(el => el.debtId === uidAsset);

        if (asset) {
          this.helperUpdateExternalFields(null, asset.id, { asset });
        }
      }
    }

    firestore
      .delete(`${collection}/${uidAsset}`)
      .then(res => {
        // remove connected asset
        if (updateFieldGlobalType && updateFieldGlobalType.useToDebt) {
          const asset = assets && assets.find(el => el.id === uidAsset);
          const externalId =
            asset && asset[updateFieldGlobalType.useToDebt.externalField];

          if (externalId) {
            firestore.delete(
              `${updateFieldGlobalType.useToDebt.collection}/${externalId}`
            );
          }
        }

        toast(
          <FormattedMessage
            id={translations.toast.assetRemoved.titleI18n}
            defaultMessage={translations.toast.assetRemoved.title}
          />
        );
      })
      .catch(err => {
        toast(
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage="Error has occurred"
          />,
          { type: "error" }
        );
        console.log("---", err);
      });
  };
  ////////////////

  handleDialogRemoveClose = () => {
    this.setState({
      entityId: ""
    });
  };

  handleCloseFormDialog = () => {
    if (this.state.isChanged) {
      return this.handleDialogNotSavedOpen();
    }

    this.props.onClose();
  };

  /// Dialog finish wizard
  handleFinishWizard = () => {
    const {
      auth,
      firestore,
      assetsAll,
      transactionsAll,
      profileProvider
    } = this.props;

    const userUid = profileProvider || auth.uid;

    const batch = firestore.batch();
    this.setState({ isWizardFinishing: true });

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("userAssets").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    if (transactionsAll) {
      Object.keys(transactionsAll).forEach(assetKey => {
        const asset = transactionsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("transactions").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    // family
    if (userUid) {
      const docRefUser = firestore.collection("users").doc(userUid);
      batch.update(docRefUser, {
        isWizardFinished: true
      });
    }

    batch
      .commit()
      .then(() => {
        if (this.state.funcIfAccept) {
          this.state.funcIfAccept();
        }
        this.handleDialogFinishWizardClose();
      })
      .catch(err => {})
      .finally(res => {
        this.handleDialogFinishWizardClose();
        this.setState({ isWizardFinishing: false });
      });
  };

  handleDialogFinishWizardAgree = () => {
    this.handleFinishWizard();
  };

  handleDialogFinishWizardOpen = funcIfAccept => {
    this.setState({
      isDialogFinishWizardOpen: true,
      funcIfAccept: funcIfAccept
    });
  };

  handleToStandardOrWhatIfAgree = mode => () => {
    const { auth, firestore } = this.props;

    if (mode === "standard" && this.state.isDontShowAgainToStandard) {
      firestore.update(`users/${auth.uid}`, {
        updated: new Date(),
        isDontShowAgainToStandard: true
      });
    }

    if (mode === "what-if" && this.state.isDontShowAgainToWhatIf) {
      firestore.update(`users/${auth.uid}`, {
        updated: new Date(),
        isDontShowAgainToWhatIf: true
      });
    }

    if (this.state.funcIfAccept) {
      this.state.funcIfAccept();
    }

    this.handleDialogToWhatIfOrStandardClose();
  };

  handleDialogToStandardOpen = funcIfAccept => {
    this.setState({
      isChangeToStandardDialog: true,
      funcIfAccept: funcIfAccept
    });
  };
  handleDialogToWhatIfOpen = funcIfAccept => {
    this.setState({
      isChangeToWhatIfDialog: true,
      funcIfAccept: funcIfAccept
    });
  };
  handleDialogToWhatIfOrStandardClose = () => {
    this.setState({
      isChangeToWhatIfDialog: false,
      isChangeToStandardDialog: false,
      funcIfAccept: null
    });
  };

  handleDialogFinishWizardClose = () => {
    this.setState({
      isDialogFinishWizardOpen: false,
      funcIfAccept: null
    });
  };

  handleDialogNotSavedOpen = () => {
    this.setState({
      isDialogNotSaved: true
    });
  };

  handleDialogNotSavedClose = () => {
    this.setState({
      isDialogNotSaved: false
    });
  };
  handleDialogClose = dialog => () => {
    this.setState({
      [dialog]: false
    });
  };

  handleDialogNotSavedAgree = () => {
    this.props.onClose();
  };
  /////////
  handleUpdateOwnersOptions = () => {
    const { childs, family } = this.props;

    const currentProfile = family;
    const template = [];
    let templateAll = [];

    if (currentProfile) {
      const birthdayGeneral = currentProfile.birthday;

      const ageGeneral =
        birthdayGeneral && birthdayGeneral.seconds
          ? diffYears(Date.now(), birthdayGeneral.seconds * 1000)
          : 0;

      template[0] = {
        name: currentProfile.displayName,
        value: "You",
        age: ageGeneral
      };
    }

    if (
      currentProfile &&
      currentProfile.spouse &&
      currentProfile.spouse.spouseName &&
      (currentProfile.relationshipStatus === "married" ||
        currentProfile.relationshipStatus === "established")
    ) {
      let age = 0;
      if (
        currentProfile.spouse.birthday &&
        currentProfile.spouse.birthday.seconds
      ) {
        age = diffYears(
          Date.now(),
          currentProfile.spouse.birthday.seconds * 1000
        );
      }
      template[1] = {
        name: currentProfile.spouse.spouseName,
        value: "Spouse",
        age
      };
    }

    templateAll = [...template];

    if (childs) {
      childs.forEach(child => {
        const fullYears =
          child.birthday && child.birthday.seconds
            ? diffYears(Date.now(), child.birthday.seconds * 1000)
            : 0;

        const childOption = {
          name: child.childName,
          value: child.id,
          age: fullYears
        };

        templateAll.push(childOption);

        if (fullYears > 16) {
          template.push(childOption);
        }
      });
    }

    this.setState({ optionsOwnerAdults: template, optionsOwner: templateAll });
  };

  handleUpdateAdditionalAssetsOptions = () => {
    const { additionalAssets } = this.props;

    const template = {};

    Object.keys(additionalAssets).forEach(entityKey => {
      const entities = additionalAssets[entityKey];

      if (entities) {
        Object.keys(entities).forEach(assetKey => {
          const asset = entities[assetKey];

          const entityId = Number.isInteger(+assetKey) ? asset.id : assetKey;

          if (!asset) {
            return;
          }

          if (!template[entityKey]) {
            template[entityKey] = [];
          }

          template[entityKey].push({
            name: `${
              asset.type ? `${asset.type.toUpperCase()} ` : ""
            }${trimString(asset.description, 45)}`,
            value: entityId
          });
        });
      }
    });

    this.setState({ optionsAsset: template });
  };

  handleCleanForm = entity => {
    if (!entity) {
      return;
    }
    const { ENTITIES_ADDITIONAL } = this.props;

    const cleanAdditional = this.helperGetAdditionalFieldsInitialState(entity);

    const additionalFields = {};

    if (ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(additKey => {
        if (!additionalFields[`${entity}_${additKey}`]) {
          additionalFields[`${entity}_${additKey}`] = {
            ...this.props.formsValidationScheme[`${entity}_${additKey}`]
          };
        }
      });
    }
    const newStateValidation = this.state.validationScheme[entity];
    const initialValidation = { ...this.props.formsValidationScheme[entity] };

    Object.keys(newStateValidation).forEach(field => {
      if (newStateValidation[field] && initialValidation[field]) {
        newStateValidation[field].isError = false;
        newStateValidation[field].isTouched = false;
        newStateValidation[field].isRequired =
          initialValidation[field].isRequired;
        newStateValidation[field].min = initialValidation[field].min;
        newStateValidation[field].max = initialValidation[field].max;
      }
    });

    if (!this.props.duplicate) {
      this.setState(prevState => ({
        ...cleanAdditional,
        [entity]: this.props.formsInitialState[entity],

        validationScheme: {
          ...prevState.validationScheme,
          [entity]: { ...newStateValidation },
          ...additionalFields
        }
      }));
    }
  };

  handleCleanFormAndCloseEdit = entity => () => {
    this.handleCleanForm(entity);

    this.handleCloseForms();
  };

  handleCloseForms = () => {
    this.setState({
      formOpened: null,
      formEditOpened: null,
      isDebtOpened: false,
      openRightDrawer: false
    });
  };

  handleToggleDebt = (formKey, state) => {
    let newArray = [...this.state.debtOpened];
    if (state) {
      newArray.push(formKey);
    } else {
      const indexToRemove = this.state.debtOpened.findIndex(
        el => el === formKey
      );
      newArray.splice(indexToRemove, 1);
    }

    this.setState(prevState => ({
      isDebtOpened: !prevState.isDebtOpened,
      debtOpened: newArray
    }));
  };

  helperGetAdditionalFieldsState = entity => {
    const { ENTITIES_ADDITIONAL } = this.props;
    const additionalFields = {};

    if (ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(addFormKey => {
        additionalFields[addFormKey] = this.state[`${entity}_${addFormKey}`];
      });
    }

    return additionalFields;
  };

  helperOptionsHomeIsPrimary = ({ isEdit }) => {
    const { assets } = this.props;

    // check is edit home with type "primary"  or is home with Primary type already added
    const isPrimaryExists =
      !(isEdit && this.state.home.type === "primary") &&
      assets &&
      Object.keys(assets).length &&
      Object.keys(assets).find(
        key =>
          assets[key] &&
          assets[key].entity === "home" &&
          assets[key].type === "primary"
      );

    return isPrimaryExists;
  };

  helperOptionsEvent = type => {
    const options = [];

    if (this.state[type].type) {
      const currentType = this.state[type] && this.state[type].type;

      if (
        !currentType ||
        (currentType !== valuesMoneyInType.ANNUITY &&
          currentType !== valuesMoneyInType.RENT &&
          currentType !== valuesMoneyInType.SALE)
      ) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.assetsAll
        ) {
          Object.keys(this.props.additionalAssets.assetsAll).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.assetsAll[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.ANNUITY) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.annuities
        ) {
          Object.keys(this.props.additionalAssets.annuities).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.annuities[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.RENT) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.homesRent
        ) {
          Object.keys(this.props.additionalAssets.homesRent).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.homesRent[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.SALE) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.useAssetsAll
        ) {
          Object.keys(this.props.additionalAssets.useAssetsAll).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.useAssetsAll[
                entityKey
              ];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }
    }

    return options;
  };

  helperOptionsHomeDebt = field => {
    const options = this.state.optionsAsset[field.optionsCustomAsset];
    const { additionalAssets } = this.props;

    if (this.state.debt.asset && this.state.debt.asset !== "general") {
      const assetId = this.state.debt.asset;
      const asset = additionalAssets.useAssets[assetId];

      if (asset && asset.entity === useEntities.ENTITY_HOME) {
        const customOptions = this.state.optionsAsset[
          field.optionsCustomAsset
        ].filter(el => el.value === asset.debtId);
        const customValue = asset.debtId;

        return {
          options: customOptions,
          value: customValue
        };
      }
    }

    if (this.state.debt.asset === "general") {
      const customOptions = this.state.optionsAsset[
        field.optionsCustomAsset
      ].filter(el => {
        const debt = additionalAssets.debts.find(debt => debt.id === el.value);

        return (
          !debt || (!debt.assetId || debt.entity !== debtEntities.ENTITY_HOME)
        );
      });

      return {
        options: customOptions
      };
    }

    return { options };
  };

  helperGetAdditionalFieldsInitialState = entity => {
    const { ENTITIES_ADDITIONAL } = this.props;
    const additionalFields = {};

    if (ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(addFormKey => {
        additionalFields[
          `${entity}_${addFormKey}`
        ] = this.props.formsInitialState[`${entity}_${addFormKey}`];
      });
    }

    return additionalFields;
  };

  getCustomMinValidation = (field, formEntity, { defaultMin = 0 } = {}) => {
    let age = defaultMin;
    if (field.minFromCustom.isGeneralMemberAge) {
      const minAgeOwner = this.state.optionsOwner.find(
        el => el.value === "You"
      );

      age = (minAgeOwner && minAgeOwner.age) || 0;
    }

    if (field.minFromCustom.isOwner) {
      const fieldOwner = field.minFromCustom.field;
      const form = this.state[formEntity];
      const owners = this.state.optionsOwner;

      optionsOwner.find(el => el.value === "You");
      const minAgeOwner = owners.find(el => el.value === form[fieldOwner]);

      age = (minAgeOwner && minAgeOwner.age) || 0;
    }

    return age;
  };

  handleValidateData = (entity, { isEdit, isAdditionFields } = {}) => {
    const { formScheme } = this.props;
    let isValid = true;
    let newState = { ...this.state.validationScheme };

    const formValidation = { ...this.state.validationScheme[entity] };

    Object.keys(formValidation).forEach(keyField => {
      const form = this.state[entity];

      const InitialValidFieldScheme = this.props.formsValidationScheme[entity][
        keyField
      ];
      const scheme = formValidation[keyField];

      if (scheme.isRequired) {
        let isConditionAccept = true;
        const formEntityScheme = formScheme[entity][keyField];

        if (formEntityScheme.condition) {
          const formInner = formEntityScheme.condition.inner
            ? this.state[`${entity}_${formEntityScheme.condition.inner}`]
            : null;

          isConditionAccept = this.checkConditions(
            formEntityScheme.condition,
            form,
            { entity, formInner: formInner }
          );
        }

        if (!form[keyField] && isConditionAccept) {
          if (formScheme[entity][keyField].isAmount && form[keyField] === 0) {
            newState[entity][keyField].isError = null;
          } else {
            isValid = false;

            newState[entity][keyField].isError = "error";
          }
        } else {
          newState[entity][keyField].isError = null;
        }

        newState[entity][keyField].isTouched = this.state.validationScheme[
          entity
        ][keyField].isTouched;

        newState[entity][keyField].isTouched = false;
        newState[entity][keyField].isRequired = true;
      }

      if (form[keyField] !== "" && typeof scheme.min !== "undefined") {
        let min = scheme.min;

        if (InitialValidFieldScheme.minFromCustom) {
          min = this.getCustomMinValidation(InitialValidFieldScheme, entity, {
            defaultMin: min
          });
        }

        if (form[keyField] < min) {
          isValid = false;
          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "min";
          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="APP.FORM.VALIDATION.MIN"
              defaultMessage={`Enter number greater or equal {value}`}
              values={{ value: min }}
            />
          );
        }
      }

      if (form[keyField] !== "" && typeof scheme.max !== "undefined") {
        if (form[keyField] > scheme.max) {
          isValid = false;
          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "max";
          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="APP.FORM.VALIDATION.MAX"
              defaultMessage={`Enter number less or equal {value}`}
              values={{ value: scheme.max }}
            />
          );
        }
      }
    });

    this.setState({ validationScheme: newState });
    return isValid;
  };

  checkConditions = (conditions, form, { keyField, entity, formInner }) => {
    let isConditionAccept = true;

    conditions.forEach(condition => {
      if (condition) {
        let assetField;

        if (condition.fieldDebt || condition.inner) {
          assetField = this.state[entity][
            condition.fieldDebt || condition.field
          ];
        } else {
          assetField = form[condition.field];
        }

        if (condition.isFilled) {
          isConditionAccept = !!assetField;
        }

        if (condition.isNotFilled) {
          isConditionAccept = !assetField;
        }

        if (condition.value) {
          // eslint-disable-next-line
          isConditionAccept = assetField == condition.value;
        }

        if (condition.valueIn) {
          // eslint-disable-next-line
          isConditionAccept = condition.valueIn.indexOf(assetField) !== -1;
        }
      }
    });

    return isConditionAccept;
  };

  handleSubmit = ({
    entity,
    assetDebt,
    isEdit,
    isChangeModeAgreed = false,
    isWhatIfTest = false,
    isStandard = false
  }) => ev => {
    const {
      translations,
      ENTITIES_ADDITIONAL,
      family,
      formEditOpened,
      formOpened,
      profile
    } = this.props;

    if (ev) {
      ev.preventDefault();
    }

    const isEditLocal = formEditOpened && !formOpened;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleSubmit({
          entity,
          assetDebt,
          isEdit: isEditLocal,
          isWhatIfTest: isWhatIfTest,
          isStandard
        })
      );
    }

    const assetOriginal = this.handleGetAsset();

    if (
      assetOriginal &&
      !profile.isDontShowAgainToStandard &&
      !isChangeModeAgreed &&
      assetOriginal.isWhatIf &&
      !isWhatIfTest
    ) {
      return this.handleDialogToStandardOpen(
        this.handleSubmit({
          entity,
          assetDebt,
          isEdit: isEditLocal,
          isWhatIfTest: isWhatIfTest,
          isStandard,
          isChangeModeAgreed: true
        })
      );
    }

    if (
      assetOriginal &&
      !profile.isDontShowAgainToWhatIf &&
      !isChangeModeAgreed &&
      !assetOriginal.isWhatIf &&
      isWhatIfTest
    ) {
      return this.handleDialogToWhatIfOpen(
        this.handleSubmit({
          entity,
          assetDebt,
          isEdit: isEditLocal,
          isWhatIfTest: isWhatIfTest,
          isStandard,
          isChangeModeAgreed: true
        })
      );
    }

    const additional = ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entity];

    const isValid = this.handleValidateData(entity, { isEdit: isEditLocal });

    let isValidAdd = true;

    if (additional && Object.keys(additional)) {
      Object.keys(additional).forEach(additKey => {
        const isOpened = this.state.debtOpened.find(
          el => el === `${entity}_${additKey}`
        );

        isValidAdd =
          isValidAdd &&
          (!isOpened ||
            this.handleValidateData(`${entity}_${additKey}`, {
              isEdit: isEditLocal,
              isAdditionFields: true
            }));
      });
    }

    if (!(isValid && isValidAdd)) {
      toast(
        <FormattedMessage
          id={translations.form.fillAllRequired.titleI18n}
          defaultMessage={translations.form.fillAllRequired.title}
        />,
        { type: "error" }
      );

      return;
    }

    const additionalFields = this.helperGetAdditionalFieldsState(entity);

    if (!isEditLocal) {
      let assetObject = {
        entity: entity,
        ...this.state[entity],
        ...additionalFields
      };

      if (isWhatIfTest) {
        assetObject.isWhatIf = true;
      }
      if (isStandard) {
        assetObject.isWhatIf = false;
      }

      return this.handleSubmitAddAsset(assetObject, assetDebt, {
        isWhatIfTest: isWhatIfTest,
        isStandard: isStandard
      });
    }

    return this.handleSubmitEditAsset({
      entity,
      additionalFields,
      isWhatIfTest: isWhatIfTest,
      isStandard: isStandard
    });
  };

  handleInputChangeDate = (asset, field) => date => {
    this.setState(prevState => ({
      isChanged: true,
      [asset]: { ...prevState[asset], [field]: date },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [field]: {
            isTouched: true,
            isError: false
          }
        }
      }
    }));
  };

  handleDisableEnable = ev => {
    const { name, checked } = ev.target;

    const { assetType } = this.props;

    this.setState({
      [assetType]: {
        ...this.state[assetType],
        [name]: checked
      }
    });
  };

  handleInputChange = asset => ev => {
    const { name, value } = ev.target;

    const isRequired =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isRequired;

    const min =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      typeof this.state.validationScheme[asset][name].min !== "undefined" &&
      this.state.validationScheme[asset][name].min;

    const max =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      typeof this.state.validationScheme[asset][name].max !== "undefined" &&
      this.state.validationScheme[asset][name].max;

    const additional = {};
    if (typeof min === "number") {
      additional.min = min;
    }
    if (typeof max === "number") {
      additional.max = max;
    }

    this.setState(prevState => ({
      isChanged: true,
      [asset]: { ...prevState[asset], [name]: value },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [name]: {
            isTouched: true,
            isError: false,
            isRequired: isRequired,
            ...additional
          }
        }
      }
    }));
  };

  handleGetAsset = ({ customId } = {}) => {
    const {
      GLOBAL_COLLECTION,
      formEditOpened,
      duplicate,
      assets,
      assetsAll,
      transactionsAll,
      additionalAssets,
      customValues
    } = this.props;
    const entityId =
      customId || (formEditOpened && formEditOpened.uidAsset) || duplicate;

    let asset;
    if (assets && entityId) {
      asset = assets.find(el => el.id === entityId);
    }

    if (!asset && GLOBAL_COLLECTION === "transactions" && transactionsAll) {
      asset = transactionsAll.find(el => el.id === entityId);
    }

    if (!asset && GLOBAL_COLLECTION === "userAssets" && assetsAll) {
      asset = assetsAll.find(el => el.id === entityId);
    }

    // CUSTOM USE ASSETS debt
    if (
      asset &&
      asset.globalType === globalTypeUse &&
      asset.debtId &&
      additionalAssets.debts &&
      additionalAssets.debts[asset.debtId]
    ) {
      asset.debt = additionalAssets.debts[asset.debtId];
    }

    return asset;
  };

  handleSubmitEditAsset = async ({
    entity,
    additionalFields,
    isWhatIfTest = false,
    isStandard = false
  }) => {
    const {
      firestore,
      translations,
      GLOBAL_COLLECTION,
      GLOBAL_TYPE_COLLECTION,
      additionalSaveGlobalType,
      updateFieldGlobalType,
      formEditOpened
    } = this.props;

    // const entityId = this.state.formEditOpened.uidAsset;
    const entityId = formEditOpened.uidAsset;

    const asset = this.handleGetAsset();

    const collection = GLOBAL_COLLECTION || "userAssets";

    if (GLOBAL_TYPE_COLLECTION) {
      additionalFields.entityType = GLOBAL_TYPE_COLLECTION;
    }

    // custom for debt <-> use assets
    let additionsSaves = this.helperSaveAdditions({
      additionalFields,
      entity,
      entityId,
      additionId: asset.debtId || null,
      isWhatIfTest,
      isStandard
    });

    if (additionalSaveGlobalType) {
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        delete additionalFields[additKey];
      });
    }

    let debtData =
      !asset.debtId && additionsSaves ? await additionsSaves : null;
    debtData = debtData ? { debtId: debtData.id } : {};

    const assetObject = this.state[entity];

    delete assetObject.isWhatIf;
    delete assetObject.isWizard;
    // delete assetObject.isDisabled;

    if (this.state.isAddAsWhatIf || isWhatIfTest) {
      assetObject.isWhatIf = true;
    }

    if (isStandard) {
      assetObject.isWhatIf = false;
    }

    return firestore
      .update(`${collection}/${entityId}`, {
        ...assetObject,
        ...additionalFields,
        ...debtData,
        updated: new Date(),
        globalType: this.props.GLOBAL_TYPE_USE_ASSETS
      })
      .then(res => {
        if (updateFieldGlobalType && updateFieldGlobalType.debtToUse) {
          const debtToUseId = this.state[entity][
            updateFieldGlobalType.debtToUse.fieldId
          ];

          if (debtToUseId) {
            this.helperUpdateExternalFields(entityId, debtToUseId);
          }
        }

        toast(
          <FormattedMessage
            id={translations.toast.assetSaved.titleI18n}
            defaultMessage={translations.toast.assetSaved.title}
          />
        );

        this.props.onClose(true);
      });
  };

  helperUpdateExternalFields = (id, externalId, { externalAsset } = {}) => {
    const { updateFieldGlobalType, firestore, additionalAssets } = this.props;

    if (updateFieldGlobalType) {
      if (updateFieldGlobalType.debtToUse) {
        const useAsset = this.handleGetAsset({ customId: externalId });

        // check is home has primary debt
        let currentExternalValue;
        if (additionalAssets && additionalAssets.homes) {
          Object.values(additionalAssets.homes).forEach(home => {
            if (home.id === externalId) {
              currentExternalValue = home.debtId;
            }
          });
        }

        if (!currentExternalValue) {
          return firestore.update(
            `${updateFieldGlobalType.debtToUse.collection}/${externalId}`,
            {
              updated: new Date(),
              [updateFieldGlobalType.debtToUse.externalField]: id
            }
          );
        }
      }

      if (updateFieldGlobalType.useToDebt) {
        return firestore.update(
          `${updateFieldGlobalType.useToDebt.collection}/${externalId}`,
          {
            updated: new Date(),
            [updateFieldGlobalType.useToDebt.fieldId]: id
            // debt ID update
          }
        );
      }
    }
  };

  helperSaveAdditions = ({
    additionalFields,
    entity,
    entityId,
    additionId,
    isWhatIfTest = false
  }) => {
    const {
      additionalSaveGlobalType,
      firestore,
      auth,
      profileProvider
    } = this.props;
    const { uid } = auth;
    const userUid = profileProvider || uid;
    const userRef = firestore.doc(`/users/${userUid}`);

    let additionsSaves = null;
    let promise = null;

    // custom for debt <-> use assets
    if (additionalSaveGlobalType) {
      additionsSaves = {};
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        additionsSaves[additKey] = { ...additionalFields[additKey] };

        if (
          !additionsSaves[additKey] ||
          !additionsSaves[additKey].description
        ) {
          return Promise.resolve(true);
        }

        additionsSaves[additKey].entity = entity; // USE ASSETS HOME VEHICLE OTHER
        additionsSaves[additKey][
          additionalSaveGlobalType[additKey].externalField
        ] = entityId;
        additionsSaves[additKey].globalType =
          additionalSaveGlobalType[additKey].globalType; // USE ASSETS HOME VEHICLE OTHER

        delete additionalFields[additKey];

        if (isWhatIfTest) {
          additionsSaves[additKey].isWhatIf = true;
        }

        if (additionId) {
          promise = firestore.update(
            `/${additionalSaveGlobalType[additKey].collection}/${additionId}`,
            {
              updated: new Date(),
              ...additionsSaves[additKey]
            }
          );
        } else {
          promise = firestore.add(
            `/${additionalSaveGlobalType[additKey].collection}`,
            {
              created: new Date(),
              user: userUid,
              userRef,
              ...additionsSaves[additKey]
            }
          );
        }
      });
    }

    return promise;
  };

  handleSubmitAddAsset = async (
    assetObject,
    assetDebt,
    { isWhatIfTest = false } = {}
  ) => {
    const {
      firestore,
      auth,
      translations,
      GLOBAL_COLLECTION,
      GLOBAL_TYPE_COLLECTION,
      additionalSaveGlobalType,
      updateFieldGlobalType,
      profileProvider
    } = this.props;

    const collection = GLOBAL_COLLECTION || "userAssets";

    const additionalFields = {};
    if (GLOBAL_TYPE_COLLECTION) {
      additionalFields.entityType = GLOBAL_TYPE_COLLECTION;
    }

    const { uid } = auth;
    const userUid = profileProvider || uid;

    const userRef = firestore.doc(`/users/${userUid}`);

    let additionalFieldsSaved = {};

    if (additionalSaveGlobalType) {
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        additionalFieldsSaved[additKey] = { ...assetObject[additKey] };

        delete assetObject[additKey];
      });
    }

    delete assetObject.id;

    if (this.props.GLOBAL_TYPE_USE_ASSETS === globalTypeGoals) {
      assetObject.position = Infinity;
    }

    firestore
      .add(`/${collection}`, {
        ...assetObject,
        created: new Date(),
        user: userUid,
        userRef,
        globalType: this.props.GLOBAL_TYPE_USE_ASSETS
      })
      .then(async docAsset => {
        let additionsSaves = this.helperSaveAdditions({
          additionalFields: additionalFieldsSaved,
          entity: assetObject.entity,
          entityId: docAsset.id,
          additionId: null,
          isWhatIfTest: isWhatIfTest
        });

        if (updateFieldGlobalType && updateFieldGlobalType.debtToUse) {
          const debtToUseId =
            assetObject[updateFieldGlobalType.debtToUse.fieldId];

          if (debtToUseId) {
            this.helperUpdateExternalFields(docAsset.id, debtToUseId, {
              userRef,
              userUid
            });
          }
        }

        let debtData = {};
        try {
          debtData = additionsSaves ? await additionsSaves : null;
          // custom for use asset debtID
          debtData = debtData ? { debtId: debtData.id } : {};
        } catch (e) {}

        try {
          await firestore.update(`/${collection}/${docAsset.id}`, {
            ...debtData
          });
        } catch (e) {}

        if (this.props.onClose) {
          this.props.onClose(true);
        }

        toast(
          <FormattedMessage
            id={translations.toast.assetAdded.titleI18n}
            defaultMessage={translations.toast.assetAdded.title}
          />
        );
      })
      .catch(err => {
        console.error("-catch(err--", err);
        toast(
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage="Error has occurred"
          />,
          { type: "error" }
        );
      });
  };

  _renderButtonSaveOrAdd = ({ entity, isEdit, saveAsTest }) => {
    const {
      translations,
      isAccessEdit,
      isMobile,
      classes,
      formEditOpened,
      formOpened,
      isDashboardGoal,
      classesGoals
    } = this.props;

    if (!isAccessEdit) {
      return null;
    }

    const entityId = formEditOpened && formEditOpened.uidAsset;

    const isAssetWhatIf =
      (!formOpened && this.state[entity] && this.state[entity].isWhatIf) ||
      formOpened;

    const buttonStyle = {
      minWidth: 125,
      marginTop: 8,
      paddingLeft: 12,
      paddingRight: 12
    };

    if (isDashboardGoal) {
      return (
        <>
          <ButtonCommon
            white
            type="submit"
            style={buttonStyle}
            className={classesGoals.wizardButtonBack}
            onClick={this.props.onBack}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </ButtonCommon>

          <ButtonMUI
            className={classesGoals.wizardButtonSave}
            onClick={this.handleSubmit({
              entity,
              isEdit,
              isStandard: true
            })}
            // fullWidth={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage
              id={translations.edit.buttonSave.titleI18n}
              defaultMessage={translations.edit.buttonSave.title}
            />
          </ButtonMUI>
        </>
      );
    }

    return (
      <>
        {entityId && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.ButtonFooter}
            onClick={this.handleDialogRemoveOpen()}
          >
            <FormattedMessage id="APP.REMOVE" defaultMessage="Erase" />
          </Button>
        )}

        <ButtonCommon
          ui
          type="submit"
          style={buttonStyle}
          onClick={this.handleSubmit({
            entity,
            isEdit,
            isWhatIfTest: true
          })}
        >
          <FormattedMessage
            id="FORM.LABELS.SAVE.TEST_WHAT_IF"
            defaultMessage="Save as What-If"
          />
        </ButtonCommon>

        <ButtonAddAsset
          type="submit"
          style={buttonStyle}
          onClick={this.handleSubmit({
            entity,
            isEdit,
            isStandard: true
          })}
        >
          <FormattedMessage
            id={translations.edit.buttonSave.titleI18n}
            defaultMessage={translations.edit.buttonSave.title}
          />
        </ButtonAddAsset>
      </>
    );
  };

  _renderFormWoDebt = (
    entityType,
    isEdit,
    {
      title = "Other",
      titleI18n = "",
      caption = "",
      captionI18n = "",
      assetDebt = null,
      bgSecondary = false
    } = {}
  ) => {
    const { classes, ENTITIES_ADDITIONAL, scheme } = this.props;

    const additionalForms =
      ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entityType]
        ? Object.keys(ENTITIES_ADDITIONAL[entityType]).map(addFormKey => {
            return this._renderDebtForAsset(`${entityType}_${addFormKey}`, {
              isEdit,
              typeCheckEntity: entityType,
              addFormKey
            });
          })
        : null;

    const isSchemeExists =
      scheme && Object.keys(scheme) && Object.keys(scheme).length;

    const formFields = isSchemeExists
      ? Object.keys(scheme).map((fieldKey, i) => {
          const field = scheme[fieldKey];

          return this._renderFormFields(field, fieldKey, entityType, {
            isEdit,
            isFirst: i === 0
          });
        })
      : null;

    const MainTitle = (
      <FormattedMessage id="APP.FORM.GENERAL" defaultMessage="General data" />
    );

    return (
      <form
        className={classes.Form}
        noValidate
        onSubmit={this.handleSubmit({
          entity: entityType,
          isEdit
        })}
      >
        <AdditionalForm
          key={entityType}
          formKey={entityType}
          mainColor={"main"}
          title={MainTitle}
          isStatic
        >
          {formFields}
        </AdditionalForm>

        {additionalForms}

        <button
          type="submit"
          hidden
          onClick={this.handleSubmit({
            entity: entityType,
            isEdit
          })}
        />
      </form>
    );
  };

  _renderFormFields = (
    field,
    fieldKey,
    type,
    { typeCheckEntity, isEdit, isFirst = false } = {}
  ) => {
    const { isAccessEdit, isDashboardGoal, classesGoals } = this.props;
    if (field.isOnlyShow) {
      return null;
    }

    const form = this.state[typeCheckEntity || type];

    if (field.condition) {
      let isPassedConditions = true;

      isPassedConditions = this.checkConditions(field.condition, form, {
        entity: type
      });

      if (!isPassedConditions) {
        return null;
      }
    }

    const isError =
      this.state.validationScheme[type] &&
      this.state.validationScheme[type][fieldKey] &&
      !!this.state.validationScheme[type][fieldKey].isError &&
      !this.state.validationScheme[type][fieldKey].isTouched;

    if (field.isSelect) {
      let valueCustom;
      const { GLOBAL_TYPE_USE_ASSETS } = this.props;

      let options = field.options;

      // move to other func
      if (field.optionsCustom === "custom-members-all") {
        options = [...this.state.optionsOwner];
      }

      if (field.optionsCustom === "custom-members-adult") {
        options = [...this.state.optionsOwnerAdults];
      }

      if (field.optionsCustom === "custom-assets-external") {
        options = [...this.state.optionsAsset];
      }

      if (field.optionsCustom === "custom-event-moneyIn") {
        options = this.helperOptionsEvent(type);
      }

      if (field.optionsCustomAsset) {
        if (this.state.optionsAsset[field.optionsCustomAsset]) {
          // Custom money out -> assets -> debt
          if (field.assignedHomeDebt) {
            const customOptions = this.helperOptionsHomeDebt(field);
            options = customOptions.options;
            valueCustom = customOptions.value;
          } else {
            options = [...this.state.optionsAsset[field.optionsCustomAsset]];
          }
        } else {
          options = [];
        }
      }

      // Custom use assets -> home
      if (
        GLOBAL_TYPE_USE_ASSETS === "use-assets" &&
        fieldKey === "type" &&
        type === "home"
      ) {
        const isPrimaryExists = this.helperOptionsHomeIsPrimary({ isEdit });
        if (isPrimaryExists) {
          options = field.optionsHomeWoPrimary;
        }
      }

      if (
        field.optionsCustomAsset &&
        !options.length &&
        field.isEmptyHelperI18n
      ) {
        return (
          <FormattedMessage
            key={fieldKey}
            id={field.isEmptyHelperI18n}
            defaultMessage={field.isEmptyHelper}
          />
        );
      }

      let helperText;
      if (field.showHelperJobLoss && field.showHelperJobLoss.id) {
        if (form.type === optionsLifeEventTypeValues.JOB_LOSS) {
          helperText = (
            <FormattedMessage
              id={field.showHelperJobLoss.id}
              defaultMessage={field.showHelperJobLoss.message}
            />
          );
        }
      }

      let defaultCondition;

      if (field.conditionDefault) {
        if (
          (field.optionsCustomRelocation || field.optionsCustomNewBorn) &&
          (form.type === optionsLifeEventTypeValues.CHILDBIRTH ||
            form.type === optionsLifeEventTypeValues.RELOCATION)
        ) {
          // custom event life
          if (form.type === optionsLifeEventTypeValues.CHILDBIRTH) {
            defaultCondition = optionsOwnerEventLifeValues.NEWBORN;
            options = field.optionsCustomNewBorn;
          }

          if (form.type === optionsLifeEventTypeValues.RELOCATION) {
            defaultCondition = optionsOwnerEventLifeValues.FAMILY;
            options = field.optionsCustomRelocation;
          }

          if (this.state[type][fieldKey] !== defaultCondition) {
            this.setState(prevState => ({
              [type]: {
                ...prevState[type],
                [fieldKey]: defaultCondition
              }
            }));
          }
        } else {
          if (form.type === optionsLifeEventTypeValues.OTHER) {
            options = [...this.state.optionsOwner, ...optionsOwnerFamily];
          }

          if (
            this.state[type][fieldKey] ===
              optionsLifeEventTypeValues.CHILDBIRTH ||
            this.state[type][fieldKey] === optionsLifeEventTypeValues.RELOCATION
          ) {
            options = [...this.state.optionsOwner];

            this.setState(prevState => ({
              [type]: {
                ...prevState[type],
                [fieldKey]: ""
              }
            }));
          }
        }
      }

      let valueShow =
        this.state[type][fieldKey] || field.default || defaultCondition || "";

      if (field.optionsCustom) {
        const key = this.state[type][fieldKey];
        const found = options.find(el => el.value === key);

        if (!found) {
          valueShow = field.default || defaultCondition || "";
        }
      }

      if (valueCustom) {
        valueShow = valueCustom;
      }

      return (
        <FormControlSelect
          required={field.isRequired}
          disabled={!isAccessEdit}
          error={isError}
          helperText={helperText}
          key={fieldKey}
          fieldName={fieldKey}
          classNameContainer={
            isDashboardGoal
              ? classesGoals.DashbGoalFormControlContainer
              : undefined
          }
          classNameExtra={
            isDashboardGoal ? classesGoals.DashbGoalFormControl : undefined
          }
          nonAutoWidth={isDashboardGoal}
          label={!isDashboardGoal ? field.label : undefined}
          labelI18n={!isDashboardGoal ? field.labelI18n : undefined}
          handleChange={this.handleInputChange(type)}
          type="select"
          options={options}
          value={valueShow}
          bgSecondary={field.bgSecondary}
          autoFocus={isFirst}
          hideNoneOption={!field.isShowNoneOption}
          isShowNoneLabel={field.isShowNoneOption}
          noneOptionLabel={field.isShowNoneLabel || field.isShowNoneOption}
          showNoneName={field.isShowNoneOption}
        />
      );
    }

    if (field.isDate) {
      return (
        <FormDatePicker
          required={field.isRequired}
          disabled={!isAccessEdit}
          error={isError}
          key={fieldKey}
          fieldName={fieldKey}
          notFullWidth={isDashboardGoal}
          classNameContainer={
            isDashboardGoal
              ? classesGoals.DashbGoalFormControlContainer
              : undefined
          }
          classNameExtra={
            isDashboardGoal ? classesGoals.DashbGoalFormControl : undefined
          }
          label={!isDashboardGoal ? field.label : undefined}
          labelI18n={!isDashboardGoal ? field.labelI18n : undefined}
          handleChange={this.handleInputChangeDate(type, fieldKey)}
          type="date"
          isFuture={field.isFuture}
          isPast={field.isPast}
          value={
            this.state[type][fieldKey] && this.state[type][fieldKey].seconds
              ? this.state[type][fieldKey].seconds * 1000
              : this.state[type][fieldKey]
              ? this.state[type][fieldKey]
              : null
          }
          isShowYear={true}
          bgSecondary={field.bgSecondary}
          autoFocus={isFirst}
        />
      );
    }

    const additionalProps = {};
    let currentValue = this.state[type][fieldKey];

    if (field.isAmount) {
      additionalProps.InputProps = {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
      };

      additionalProps.step = "0.05";
      additionalProps.min = "0";
    }

    if (field.prefix) {
      additionalProps.InputProps = {
        startAdornment: (
          <InputAdornment position="start">{field.prefix}</InputAdornment>
        )
      };
    }

    if (field.isNumber) {
      additionalProps.type = "number";
      if (!additionalProps.inputProps) {
        additionalProps.inputProps = {};
      }

      if (typeof field.min !== "undefined") {
        additionalProps.inputProps.min = field.min;
      }

      if (typeof field.max !== "undefined") {
        additionalProps.inputProps.max = field.max;
      }

      // TODO Chrome bug with comma
      if (field.isInt) {
        additionalProps.type = "text";
      }

      if (field.minFromCustom) {
        const customMin = this.getCustomMinValidation(
          field,
          typeCheckEntity || type
        );

        additionalProps.inputProps.min = customMin || 0;
      }
    }

    if (field.after && !isDashboardGoal) {
      additionalProps.InputProps = {
        endAdornment: (
          <InputAdornment position="end">{field.after}</InputAdornment>
        )
      };
    }

    if (!currentValue && !field.isAmount) {
      currentValue = "";
    }

    return (
      <FormControlInput
        key={fieldKey}
        disabled={!isAccessEdit}
        classNameContainer={`${
          isDashboardGoal ? classesGoals.DashbGoalFormControlContainer : ""
        } ${
          isDashboardGoal && fieldKey === "description"
            ? classesGoals.DashbGoalFormControlContainerDesc
            : ""
        }`}
        classNameExtra={
          isDashboardGoal ? classesGoals.DashbGoalFormControl : undefined
        }
        label={!isDashboardGoal ? field.label : undefined}
        labelI18n={!isDashboardGoal ? field.labelI18n : undefined}
        error={isError}
        helperText={
          isError &&
          this.state.validationScheme[type][fieldKey].errorMessageI18n
            ? this.state.validationScheme[type][fieldKey].errorMessageI18n
            : null
        }
        required={field.isRequired}
        fieldName={fieldKey}
        handleChange={this.handleInputChange(type)}
        value={currentValue}
        bgSecondary={field.bgSecondary}
        {...additionalProps}
        autoFocus={isFirst}
        isAmount={field.isAmount}
        fieldScheme={field}
        formWidth={field.formWidth}
      />
    );
  };

  _renderDebtForAsset = (
    entityType,
    { typeCheckEntity, isEdit, addFormKey }
  ) => {
    const { GLOBAL_TYPE_USE_ASSETS } = this.props;

    const scheme = this.props.formScheme[entityType];
    const isSchemeExists =
      scheme && Object.keys(scheme) && Object.keys(scheme).length;

    const formFields = isSchemeExists
      ? Object.keys(scheme).map(fieldKey => {
          const field = scheme[fieldKey];

          return this._renderFormFields(field, fieldKey, entityType, {
            typeCheckEntity,
            isEdit
          });
        })
      : null;

    const isFieldsNotEmpty = formFields ? formFields.find(el => !!el) : null;

    if (!formFields || !isFieldsNotEmpty) {
      return null;
    }

    const formColor = addFormKey === "configuration" ? "yellow" : "brand";

    const showButtonText =
      addFormKey === "configuration" ? (
        <FormattedMessage
          id={`ADDITIONAL_FORMS_LABELS.CONFIGURATION`}
          defaultMessage={"Show Configuration"}
        />
      ) : (
        <FormattedMessage
          id={`ADDITIONAL_FORMS_LABELS.${GLOBAL_TYPE_USE_ASSETS}.${entityType}`}
          defaultMessage={"Show More"}
        />
      );

    return (
      <AdditionalForm
        key={entityType}
        formKey={entityType}
        mainColor={formColor}
        handleIsOpen={this.handleToggleDebt}
        title={showButtonText}
        isStatic
      >
        {formFields}
      </AdditionalForm>
    );
  };

  handleEditAsset = uidAsset => () => {
    const { isMobile, ENTITIES_ADDITIONAL, duplicate } = this.props;

    // const assetOriginal = assets.find(asset => asset.id === uidAsset);
    const assetOriginal = this.handleGetAsset();

    let newAsset;
    if (assetOriginal) {
      const { created, id, debt, ...newAssetClean } = assetOriginal;
      newAsset = newAssetClean;
    }

    let asset = assetOriginal;
    if (duplicate) {
      asset = newAsset;
    }

    if (asset && asset.entity) {
      this.handleCleanForm(asset.entity);

      const { debt, ...onlyAsset } = asset;

      const additionalFields = {};
      if (ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[asset.entity]) {
        Object.keys(ENTITIES_ADDITIONAL[asset.entity]).forEach(addFormKey => {
          additionalFields[`${asset.entity}_${addFormKey}`] =
            asset[addFormKey] || {};
        });
      }

      this.setState({
        formEditOpened: {
          uidAsset,
          entity: asset.entity
        },
        [asset.entity]: onlyAsset,
        ...additionalFields,
        openRightDrawer: isMobile
      });
    }
  };

  _renderHeader = ({ isAssetDisabled, isAssetWhatIf }) => {
    const {
      isMobile,
      isAccessEdit,
      designScheme,
      classes,
      GLOBAL_TYPE_USE_ASSETS,
      translations,
      onBack
    } = this.props;

    let Icon = Icons[designScheme.icon];
    let color = isAccessEdit ? designScheme.color : "textTetrieary";

    if (isAssetWhatIf) {
      color = "yellow";
      Icon = Icons.IconHelpCircle;
    }
    if (isAssetDisabled) {
      color = "textSecondary";
    }

    const category = translations.mainContent.section && (
      <FormattedMessage
        id={translations.mainContent.section.name.titleI18n}
        defaultMessage={translations.mainContent.section.name.title}
      />
    );

    const title = designScheme.title;
    const titleI18n = designScheme.titleI18n;
    const titleToShow = (
      <FormattedMessage id={titleI18n} defaultMessage={title} />
    );
    const isOpen = true;

    const top = (
      <div
        className={`${classes.Header} ${
          isAssetWhatIf ? classes.HeaderWhatIf : ""
        } ${isAssetDisabled ? classes.HeaderDisabled : ""}`}
      >
        {isMobile && isOpen && !!this.handleCloseFormDialog && (
          <div className="side-item__button-back">
            <ButtonForIcon // topRight={true}
              isOpen={true}
              handleClick={this.handleCloseFormDialog}
            >
              <Icons.IconArrowLeft />
            </ButtonForIcon>
          </div>
        )}

        {onBack && (
          <IconContainer
            onClick={onBack}
            color={"white"}
            additionalClass={classes.HeaderBack}
          >
            <Icons.IconArrowLeft color={"textTetrieary"} />
          </IconContainer>
        )}

        <div className="side-item__icon">
          <IconContainer width={32} height={32} color={color}>
            {!Icon ? (
              <Icons.IconOther width={24} height={24} color={color || "ui"} />
            ) : (
              <Icon width={24} height={24} color={color} />
            )}
          </IconContainer>
        </div>

        <div className="side-item__label">
          <div className="side-item__label-category">{category}</div>

          {titleToShow || "Asset"}
          {isAssetWhatIf && (
            <>
              {` `}
              <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-if" />
            </>
          )}
        </div>

        {isMobile && !isOpen && !!this.handleCloseFormDialog && (
          <ButtonSideItem
            type="button"
            isOpen={isOpen}
            handleClick={this.handleCloseFormDialog}
          />
        )}

        {!isMobile && !!this.handleCloseFormDialog && (
          <ButtonSideItem
            type="button"
            isOpen={isOpen}
            handleClick={this.handleCloseFormDialog}
          />
        )}
      </div>
    );

    return top;
  };

  _renderEditMenu = () => {
    const { formEditOpened, formOpened, classes } = this.props;

    const entityType =
      formEditOpened && formEditOpened.entity
        ? formEditOpened.entity
        : formOpened;

    const isAssetWhatIf =
      !formOpened && this.state[entityType] && this.state[entityType].isWhatIf;
    const isAssetDisabled =
      this.state[entityType] && this.state[entityType].isDisabled;

    return (
      <>
        <DialogTitle disableTypography className={classes.HeaderTop}>
          {this._renderHeader({ isAssetDisabled, isAssetWhatIf })}
        </DialogTitle>
        <DialogContent className={classes.Content}>
          {!!entityType &&
            this._renderFormWoDebt(entityType, true, {
              isEdit: true
            })}
        </DialogContent>
        <DialogActions className={classes.Footer}>
          <div className={classes.FooterDisableSwitch}>
            <FormLabel
              htmlFor="switch-isDisabled"
              className={classes.switchLabel}
            >
              <FormattedMessage id="APP.ENABLED" defaultMessage="Enabled" />
            </FormLabel>
            <Switch
              id="switch-isDisabled"
              checked={isAssetDisabled}
              name="isDisabled"
              onChange={this.handleDisableEnable}
            />
            <FormLabel
              htmlFor="switch-isDisabled"
              className={classes.switchLabel}
            >
              <FormattedMessage id="APP.DISABLED" defaultMessage="Disabled" />
            </FormLabel>
          </div>

          <div className={classes.FooterButtonContainer}>
            {this._renderButtonSaveOrAdd({
              entity: entityType,
              isEdit: !formOpened
            })}
          </div>
        </DialogActions>
      </>
    );
  };

  _renderDialogToStandard = () => {
    const { classes } = this.props;

    const changeTypeToStandardDialog = {
      text: (
        <FormattedMessage
          id="APP.FORM.SAVE.TO_STANDARD.TEXT"
          defaultMessage="You change asset to standard mode. Are you sure?"
        />
      ),
      title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
      okButton: <FormattedMessage id="APP.YES" defaultMessage="Yes" />
    };

    const isOpen = Boolean(this.state.isChangeToStandardDialog);
    const change = () => {
      this.setState(state => ({
        isDontShowAgainToStandard: !state.isDontShowAgainToStandard
      }));
    };

    return (
      <DialogCommon
        isOpen={isOpen}
        title={changeTypeToStandardDialog.title}
        text={changeTypeToStandardDialog.text}
        okButton={changeTypeToStandardDialog.okButton}
        handleAgreeAndClose={this.handleToStandardOrWhatIfAgree("standard")}
        handleClose={this.handleDialogClose("isChangeToStandardDialog")}
      >
        {isOpen && (
          <div>
            <p>{changeTypeToStandardDialog.text}</p>

            <div className="dashboard__switch dashboard__switch--right">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isDontShowAgainToStandard}
                    name="toStandard"
                    onChange={change}
                  />
                }
                label={
                  <FormattedMessage
                    id="APP.WARNING.NEVER_SHOW_THIS"
                    defaultMessage="Never show again this warning"
                  />
                }
              />
            </div>
          </div>
        )}
      </DialogCommon>
    );
  };
  _renderDialogToWhatIf = () => {
    const { classes } = this.props;

    const changeTypeToWhatIfDialog = {
      text: (
        <FormattedMessage
          id="APP.FORM.SAVE.TO_WHAT_IF.TEXT"
          defaultMessage="You change asset to what-if mode. Are you sure?"
        />
      ),
      title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
      okButton: <FormattedMessage id="APP.YES" defaultMessage="Yes" />
    };

    const isOpen = Boolean(this.state.isChangeToWhatIfDialog);
    const change = () => {
      this.setState(state => ({
        isDontShowAgainToWhatIf: !state.isDontShowAgainToWhatIf
      }));
    };

    return (
      <DialogCommon
        isOpen={isOpen}
        title={changeTypeToWhatIfDialog.title}
        text={changeTypeToWhatIfDialog.text}
        okButton={changeTypeToWhatIfDialog.okButton}
        handleAgreeAndClose={this.handleToStandardOrWhatIfAgree("what-if")}
        handleClose={this.handleDialogClose("isChangeToWhatIfDialog")}
      >
        {isOpen && (
          <div>
            <p>{changeTypeToWhatIfDialog.text}</p>

            <div className="dashboard__switch dashboard__switch--right">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isDontShowAgainToWhatIf}
                    name="toWhatIf"
                    onChange={change}
                  />
                }
                label={
                  <FormattedMessage
                    id="APP.WARNING.NEVER_SHOW_THIS"
                    defaultMessage="Never show again this warning"
                  />
                }
              />
            </div>
          </div>
        )}
      </DialogCommon>
    );
  };

  _renderOnlyInputs = () => {
    const {
      classes,
      ENTITIES_ADDITIONAL,
      scheme,
      formEditOpened,
      formOpened
    } = this.props;

    const isEdit = true;

    const entityType =
      formEditOpened && formEditOpened.entity
        ? formEditOpened.entity
        : formOpened;

    const additionalForms =
      ENTITIES_ADDITIONAL && ENTITIES_ADDITIONAL[entityType]
        ? Object.keys(ENTITIES_ADDITIONAL[entityType]).map(addFormKey => {
            return this._renderDebtForAsset(`${entityType}_${addFormKey}`, {
              isEdit,
              typeCheckEntity: entityType,
              addFormKey
            });
          })
        : null;

    const isSchemeExists =
      scheme && Object.keys(scheme) && Object.keys(scheme).length;

    const formFields = isSchemeExists
      ? Object.keys(scheme).map((fieldKey, i) => {
          const field = scheme[fieldKey];

          return this._renderFormFields(field, fieldKey, entityType, {
            isEdit,
            isFirst: i === 0
          });
        })
      : null;

    return { additionalForms, formFields };
  };

  _renderInputsGoal = () => {
    const {
      formEditOpened,
      formOpened,
      classesGoals,
      formBottomTooltip,
      designScheme
    } = this.props;

    const { formFields } = this._renderOnlyInputs();

    const formFieldsObject = {
      break: () => <br />,
      flexBreak: msg => <div style={{ flex: 1 }}>{msg}</div>,
      linebreak: () => <br />
    };

    formFields.forEach(el => {
      if (el && el.key) {
        formFieldsObject[el.key] = el;
      }
    });

    const entityType =
      formEditOpened && formEditOpened.entity
        ? formEditOpened.entity
        : formOpened;

    const form = this._renderFormWoDebt(entityType, true, {
      isEdit: true
    });

    const Icon = designScheme.iconBig ? Icons[designScheme.iconBig] : null;

    const formNew = (
      <DialogContent
        className={`${classesGoals.wizardBody} ${classesGoals.wizardBodyGoals}`}
      >
        {designScheme.formTitle && (
          <div
            className={`${classesGoals.wizardHeader2} ${classesGoals.wizardHeaderGoal2}`}
          >
            <FormattedMessage id={designScheme.formTitle} />
          </div>
        )}

        <div className={classesGoals.goalsFormIcon}>
          <Icon />
        </div>

        <div
          className={`${classesGoals.wizardBodyContent} ${classesGoals.wizardBodyContentGoal}`}
        >
          <div className={classesGoals.DashbGoalForm}>
            <FormattedMessage
              tagName={React.Fragment}
              id={designScheme.dashboardFormMessage}
              values={formFieldsObject}
            />

            <div
              className={classesGoals.DashbGoalForm}
              style={{ minWidth: "100%", marginTop: 16 }}
            >
              <FormattedMessage
                tagName={React.Fragment}
                id="DASHBOARD.GOALS.FORM.COMMON.DESCRIPTION"
                values={formFieldsObject}
              />
            </div>
          </div>
        </div>

        {formBottomTooltip}
      </DialogContent>
    );

    return (
      <>
        {formNew}
        <DialogActions
          className={`${classesGoals.wizardFooter} ${classesGoals.wizardFooterGoals}`}
        >
          {this._renderButtonSaveOrAdd({
            entity: entityType,
            isEdit: !formOpened
          })}
        </DialogActions>
      </>
    );
  };

  render() {
    const {
      isMobile,
      classes,
      isDashboardGoal,
      designScheme,
      ///
      formEditOpened,
      formOpened
    } = this.props;

    const notSavedDialog = {
      text: (
        <FormattedMessage
          id="APP.FORM.NOT_SAVED.TEXT"
          defaultMessage="You have unsaved changes. Do You want to discard your changes?"
        />
      ),
      title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
      okButton: <FormattedMessage id="APP.DISCARD" defaultMessage="Discard" />
    };

    if (isDashboardGoal && designScheme && designScheme.dashboardFormMessage) {
      return this._renderInputsGoal();
    }

    return (
      <>
        <DialogFinishWizard
          isOpen={
            !!this.state.isDialogFinishWizardOpen ||
            this.state.isWizardFinishing
          }
          handleAgreeAndClose={this.handleDialogFinishWizardAgree}
          handleClose={this.handleDialogFinishWizardClose}
          isLoading={this.state.isWizardFinishing}
        />

        <DialogRemoveAsset
          isOpen={!!this.state.entityId}
          handleAgreeAndClose={this.handleDialogRemoveAgree}
          handleClose={this.handleDialogRemoveClose}
        />

        <DialogCommon
          isOpen={this.state.isDialogNotSaved}
          title={notSavedDialog.title}
          text={notSavedDialog.text}
          okButton={notSavedDialog.okButton}
          handleAgreeAndClose={this.handleDialogNotSavedAgree}
          handleClose={this.handleDialogNotSavedClose}
        />

        {this._renderDialogToStandard()}
        {this._renderDialogToWhatIf()}

        <Dialog
          maxWidth={"xl"}
          scroll="paper"
          fullScreen={isMobile}
          open={this.props.open}
          onClose={this.handleCloseFormDialog}
          BackdropProps={{
            classes: {
              root: classes.BackdropSmooth
            }
          }}
        >
          {this._renderEditMenu()}
        </Dialog>
      </>
    );
  }
}

AssetForm.propTypes = {
  closeForm: PropTypes.any,
  onClose: PropTypes.any,
  onBack: PropTypes.func,
  //
  isAccessEdit: PropTypes.any,
  // formsInitialState: PropTypes.any,
  assetType: PropTypes.any,
  scheme: PropTypes.any,
  formScheme: PropTypes.any,
  formsValidationScheme: PropTypes.any,
  validationScheme: PropTypes.any,
  designScheme: PropTypes.any,
  formsInitialState: PropTypes.any,
  translations: PropTypes.any,
  //
  GLOBAL_TYPE_USE_ASSETS: PropTypes.any,
  GLOBAL_COLLECTION: PropTypes.any,
  //
  formEditOpened: PropTypes.any,
  open: PropTypes.any,
  // assets: PropTypes.any,
  assetsAll: PropTypes.any,
  transactionsAll: PropTypes.any,
  //
  profileProvider: PropTypes.any,
  family: PropTypes.any,
  childs: PropTypes.any,
  //
  firestore: PropTypes.any,
  auth: PropTypes.any,
  isMobile: PropTypes.any,
  /// GOALS DASHB
  formBottomTooltip: PropTypes.any,
  isDashboardGoal: PropTypes.bool
};
export const styles = theme => ({
  BackdropSmooth: {
    background: "rgba(7, 5, 29, 0.5)"
    // backdropFilter: "blur(5px)"
  },
  container: {
    padding: "",
    textAlign: "center",
    background: colors.white,
    boxShadow: BOX_SHADOW_3,
    borderRadius: 4
  },

  ButtonWelcome: { marginBottom: 48 },

  HeaderTop: {
    padding: 0,
    height: 56,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottom: "1px solid " + colors.divider
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    width: "100%",
    padding: "10px 24px"
  },
  HeaderBack: {
    marginRight: 10
  },
  HeaderWhatIf: {
    backgroundColor: colors.backgroundSecondary
  },
  HeaderDisabled: {
    backgroundColor: colors.backgroundDisabledAsset
  },

  Content: {
    maxWidth: "100%",
    paddingBottom: 0,
    paddingRight: 5
  },

  Form: {
    paddingTop: 16,
    paddingBottom: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "& > *": { flex: 1, width: 350, minWidth: 270, maxWidth: 400 }
  },

  Footer: {
    paddingLeft: 24,
    paddingRight: 24,
    display: "block",
    // justifyContent: "flex-end",
    borderTop: `1px solid ${colors.divider}`,

    alignItems: "center"
  },
  FooterSwitches: {
    display: "flex"
  },
  switchLabel: {
    fontSize: 14,
    lineHeight: "21px",
    color: colors.textSecondary
  },
  FooterDisableSwitch: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: -3,
    marginBottom: -10
  },
  FooterButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "auto",

    "& > *:not(:last-child)": {
      marginRight: 16
    }
  },
  ButtonFooter: {
    marginTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,

    lineHeight: "21px",
    textDecoration: "none",

    borderColor: colors.brand,
    color: colors.brand
  }
});

export default withStyles(styles)(AssetForm);
