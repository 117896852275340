import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconHomeMulti = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M9 3L2 8.21164V18.5348V19.0278C2 20.1179 2.89453 21 4 21H20.5C21.3164 21 22 20.3259 22 19.5209V19.0278V8.21164L15.0352 3.02696L11.8477 5.12241L9 3ZM14.9648 5.44597L20 9.19388V19.0278H16V17.0556H18V15.0835H16V13.1113H18V11.1391H16V8.21164L13.543 6.38198L14.9648 5.44597ZM6 9.16692H8V11.1391H6V9.16692ZM10 9.16692H12V11.1391H10V9.16692ZM6 13.1113H8V15.0835H6V13.1113ZM10 13.1113H12V15.0835H10V13.1113ZM6 17.0556H8V19.0278H6V17.0556ZM10 17.0556H12V19.0278H10V17.0556Z"
        fill={fill}
      />
      <path d="M4 21C2.89453 21 2 20.1179 2 19.0278V21H4Z" fill={fill} />
      <path
        d="M22 19.5209C22 20.3259 21.3164 21 20.5 21H22V19.5209Z"
        fill={fill}
      />
    </svg>
  );
};

IconHomeMulti.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconHomeMulti;
