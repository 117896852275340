export default {
  apiKey: "AIzaSyCWQQYJLE8Thy4PmKaJaFByOllibdJLIkY",
  authDomain: "xcurve-3a19e.firebaseapp.com",
  databaseURL: "https://xcurve-3a19e.firebaseio.com",
  projectId: "xcurve-3a19e",
  storageBucket: "xcurve-3a19e.appspot.com",
  messagingSenderId: "1092436618574"
};
//vojjanfzsamdtqlm
//ddvrzkujbosxegqf
export const functionsURL = "https://us-central1-xcurve-3a19e.cloudfunctions.net";