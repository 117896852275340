import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./ButtonForIcon.scss";

class ButtonForIcon extends PureComponent {
  render() {
    const { handleClick } = this.props;

    return (
      <button className="button--icon" onClick={handleClick}>
        {this.props.children}
      </button>
    );
  }
}

ButtonForIcon.propTypes = {
  handleClick: PropTypes.func
};

export default ButtonForIcon;
