import React from "react";

import { colors } from "constants/styles";

const IconUmbrella = ({
  height = 40,
  width = 40,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M20 3.33325C23.9782 3.33325 27.7936 4.9136 30.6066 7.72665C33.4196 10.5397 35 14.355 35 18.3333H21.6667V31.6666C21.6667 32.9927 21.1399 34.2644 20.2022 35.2021C19.2645 36.1398 17.9927 36.6666 16.6667 36.6666C15.3406 36.6666 14.0688 36.1398 13.1311 35.2021C12.1935 34.2644 11.6667 32.9927 11.6667 31.6666V29.9999H15V31.6666C15 32.1086 15.1756 32.5325 15.4882 32.8451C15.8007 33.1577 16.2246 33.3333 16.6667 33.3333C17.1087 33.3333 17.5326 33.1577 17.8452 32.8451C18.1577 32.5325 18.3333 32.1086 18.3333 31.6666V18.3333H5C5 14.355 6.58035 10.5397 9.3934 7.72665C12.2064 4.9136 16.0218 3.33325 20 3.33325Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconUmbrella;
