import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconGeneralSavings = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M5 2C3.34766 2 2 3.34766 2 5V19C2 20.6523 3.34766 22 5 22H19C20.6523 22 22 20.6523 22 19V5C22 3.34766 20.6523 2 19 2H5ZM5 4H19C19.5508 4 20 4.44922 20 5V19C20 19.5508 19.5508 20 19 20H5C4.44922 20 4 19.5508 4 19V5C4 4.44922 4.44922 4 5 4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4H19C19.5508 4 20 4.44922 20 5V19C20 19.5508 19.5508 20 19 20H5C4.44922 20 4 19.5508 4 19V5C4 4.44922 4.44922 4 5 4ZM7 9V7H17V9H7ZM7 13V11H17V13H7ZM7 17V15H17V17H7Z"
        fill={fill}
      />
    </svg>
  );
};

IconGeneralSavings.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconGeneralSavings;
