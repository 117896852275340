import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconLeftArrow = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "Back",
  opacity = 1,
  strokeWidth = 2,
  style = {},
  className
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
      style={style}
      className={className}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={opacity}>
          <path
            d="M15.4099 16.58L10.8299 12L15.4099 7.41L13.9999 6L7.99991 12L13.9999 18L15.4099 16.58Z"
            fill={fill}
          />
        </g>
      </svg>
    </svg>
  );
};

IconLeftArrow.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconLeftArrow;
