import {
  addAdditionalConfiguration,
  prepareState,
  repeatableFields as repeatableCommon
} from "../AssetsCommon/_formScheme";
import { GLOBAL_TYPE_USE_ASSETS as QueryGlobalUse } from "../HouseholdAssetsUse/formScheme";
import {
  RIGHT_HOUSEHOLD_MONEY_OUT,
  RIGHT_HOUSEHOLD_MONEY_OUT_ASSETS
} from "../../constants/rights";
import { MONEY_OUT_ENDING_SOON } from "../MoneyOutPersonal/formScheme";

export const GLOBAL_COLLECTION = "transactions";
export const GLOBAL_TYPE_COLLECTION = "out";
export const GLOBAL_TYPE_USE_ASSETS = "money-out-assets";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_MONEY_OUT;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_MONEY_OUT_ASSETS;

const ENTITY_INSURANCE = "insurance";
const ENTITY_UTILITIES = "utilities";
const ENTITY_MAINTENANCE = "maintenance";
// const ENTITY_USE = "use";
const ENTITY_DEBT = "debt";
const ENTITY_OTHER = "other";

const ENTITY_GENERAL = "general";

export const ENTITIES = {
  ENTITY_INSURANCE,
  ENTITY_UTILITIES,
  ENTITY_MAINTENANCE,
  ENTITY_DEBT,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.MONEY_OUT.ASSETS.FORM_GROUP.TITLE": {
    title: "What do people in your family spend?",
    ENTITIES: {
      ENTITY_INSURANCE,
      ENTITY_UTILITIES,
      ENTITY_MAINTENANCE,
      ENTITY_DEBT,
      ENTITY_OTHER
    }
  }
};

// const optionsOwnerAll = [
//   {
//     name: "You",
//     value: "You"
//   }
// ];

const repeatableSchemeCommon = {
  description: {
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.MONEY_OUT.FORM.DESCRIPTION.LABEL"
  }
};
const repeatableSchemeGeneral = {
  annualCost: {
    isMain: true,

    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Annual Cost",
    labelI18n: "HOUSEHOLD.MONEY_OUT.FORM.ANNUAL_COST.LABEL"
  },
  // fromAge: { ...repeatableCommon.fromAge },
  // toAge: { ...repeatableCommon.toAge },
  startDate: {
    isDate: true,
    label: "Start Date",
    labelI18n: "HOUSEHOLD.MONEY_OUT.FORM.START_DATE.LABEL"
  },
  durationYears: {
    ...repeatableCommon.durationYears,

    // calculate: { isYearsLeftPeriod: true, fieldDate: "startDate" },
    expiredCalculate: { isYearsLeftPeriod: true, fieldDate: "startDate" },
    endingSoonCalculate: {
      monthsLeftPeriod: MONEY_OUT_ENDING_SOON,
      fieldDate: "startDate",
      MoneyOutYears: true
    },

    condition: [
      {
        field: "startDate",
        isFilled: true
      }
    ]
  },
  asset: {
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,
    isFullWidth: true,

    isAdditionalAsset: "useAssets",
    additionalAssetField: "description",
    optionsCustomAsset: "useAssets",

    query: {
      globalAsset: QueryGlobalUse
    },
    isSelect: true,
    default: "general",
    isShowNoneOption: "general",
    isShowNoneLabel: "General",

    isRequired: true,
    label: "Asset",
    labelI18n: "HOUSEHOLD.MONEY_OUT.FORM.ASSET_USE.LABEL"
  }
};

// const repeatableSchemeGeneralWoOwner = {
//   description: repeatableSchemeGeneral.description,
//   value: repeatableSchemeGeneral.value
// };

export const designScheme = {
  [ENTITIES.ENTITY_INSURANCE]: {
    icon: "IconUmbrella",
    color: "green",
    title: "Insurance",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.INSURANCE"
  },
  [ENTITIES.ENTITY_UTILITIES]: {
    icon: "IconElectrical",
    color: "green",
    title: "Utilities",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.UTILITIES"
  },

  [ENTITIES.ENTITY_MAINTENANCE]: {
    icon: "IconSupport",
    color: "green",
    title: "Maintenance",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.MAINTENANCE"
  },

  [ENTITIES.ENTITY_DEBT]: {
    icon: "IconAccount",
    color: "green",
    title: "Debt",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.DEBT"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "General Assets",
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TILE.GENERAL"
  }
};

export const ENTITIES_ADDITIONAL = {};

export const formScheme = {
  [ENTITIES.ENTITY_INSURANCE]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.INSURANCE.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_UTILITIES]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.UTILITIES.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_MAINTENANCE]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.MAINTENANCE.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_DEBT]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.DEBT.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral,
    debtId: {
      isAdditional: true,
      isFullWidth: true,

      isAdditionalAsset: "debts",
      additionalAssetField: "description",
      optionsCustomAsset: "debts",
      fieldCustom: "debtId",
      assignedHomeDebt: "asset",

      isSelect: true,
      default: "general",
      isShowNoneOption: "general",
      isShowNoneLabel: "General",

      label: "Debt",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.DEBT.LABEL"
    }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.OTHER.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },

  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.ASSET.FORM.GENERAL.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  }
};

addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  GLOBAL_TYPE_USE_ASSETS
);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS",
        title: "Property Expenses"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TITLE",
      title: "Ok. Now let’s dig into the cost of your assets"
    },
    caption: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.CAPTION",
      title:
        "We’ll help make sure you always have enough to maintain the use of your assets"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.EMPTY",
      title:
        "You have no personal money out,\n please add your first assets money out"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.H2",
      title: "Assets"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TOAST.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TOAST.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.TOAST.SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.SIDE_MENU_TITLE",
      title: "Add Expenses For Assets"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.SIDE_MENU_TITLE.EDIT",
      title: "Edit Personal Expenses"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.FORM_GROUP.TITLE",
      title: "What do people in your family spend?"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.GENERAL_GROUP",
      title: "General Expenses",
      caption:
        "Sure, you can enter the total value of all your spend expanses, but we will not be able to predict their future value. To continue anyway, enter a value and select “General Expanses”",
      captionI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.VALUE.BUTTON.SAVE",
      title: "Save Expense"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.VALUE.BUTTON",
      title: "Add"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.WHY.TITLE",
    textI18n: "HOUSEHOLD.MONEY_OUT.ASSETS.WHY.ALL_TEXT"
  }
};
