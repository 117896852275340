import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconClockRetry = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 2C6.48438 2 2 6.48438 2 12C2 17.5156 6.48438 22 12 22C17.5156 22 22 17.5156 22 12H20C20 16.4102 16.4102 20 12 20C7.58984 20 4 16.4102 4 12C4 7.58984 7.58984 4 12 4C14.4609 4 16.6562 5.125 18.125 6.875L16 9H22V3L19.5312 5.46875C17.6953 3.35547 15.0117 2 12 2ZM11 6V11.5859L8.29297 14.293L9.70703 15.707L13 12.4141V6H11Z"
        fill={fill}
      />
    </svg>
  );
};

IconClockRetry.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconClockRetry;
