export const RIGHT_PLANS_INTERESTS = "PLANS-INTERESTS";
export const RIGHT_PLANS_GOALS = "PLANS-GOALS";
export const RIGHT_PLANS_EVENTS = "PLANS-EVENTS";
export const RIGHT_HOUSEHOLD_MEMBERS = "HOUSEHOLD-MEMBERS";
export const RIGHT_HOUSEHOLD_ASSETS = "HOUSEHOLD-ASSETS";
export const RIGHT_HOUSEHOLD_ASSETS_USE = "HOUSEHOLD-ASSETS-USE";
export const RIGHT_HOUSEHOLD_ASSETS_SAVING = "HOUSEHOLD-ASSETS-SAVING";
export const RIGHT_HOUSEHOLD_ASSETS_INVESTED = "HOUSEHOLD-ASSETS-INVESTED";
export const RIGHT_HOUSEHOLD_DEBT = "HOUSEHOLD-DEBT";
export const RIGHT_HOUSEHOLD_ACCOUNTS = "HOUSEHOLD-ACCOUNTS";
export const RIGHT_HOUSEHOLD_MONEY_IN = "HOUSEHOLD-MONEY_IN";
export const RIGHT_HOUSEHOLD_MONEY_OUT = "HOUSEHOLD-MONEY_OUT";
export const RIGHT_HOUSEHOLD_MONEY_OUT_PERSONAL =
  "HOUSEHOLD-MONEY_OUT-PERSONAL";
export const RIGHT_HOUSEHOLD_MONEY_OUT_ASSETS = "HOUSEHOLD-MONEY_OUT-ASSETS";
export const RIGHT_HOUSEHOLD_MONEY_OUT_OTHER = "HOUSEHOLD-MONEY_OUT-OTHER";
