import { repeatableForUse as debtFullScheme } from "../HouseholdDebt/formScheme";

import {
  addAdditionalConfiguration,
  prepareState
} from "../AssetsCommon/_formScheme";
import {} from "constants/inflation";

import {
  RIGHT_HOUSEHOLD_ASSETS,
  RIGHT_HOUSEHOLD_ASSETS_USE
} from "../../constants/rights";
// import { optionsPaidFrequencyMQY } from "../AssetsCommon/_formScheme";

export const GLOBAL_TYPE_USE_ASSETS = "use-assets";
export const GLOBAL_COLLECTION_USE_ASSETS = "userAssets";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_ASSETS;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_ASSETS_USE;

const ENTITY_HOME = "home";
const ENTITY_VEHICLE = "vehicle";
const ENTITY_OTHER = "other";
const ENTITY_GENERAL = "general";

const ENTITY_DEBT_HOME = "homeDebt";
const ENTITY_DEBT_VEHICLE = "vehicleDebt";
const ENTITY_DEBT_OTHER = "otherDebt";

export const ENTITIES = {
  ENTITY_HOME,
  ENTITY_VEHICLE,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

export const ENTITY_ADD_DEBT = "debt";
export const ENTITY_ADD_CONFIGURATION = "configuration";

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.ASSETS.USE.FORM_GROUP.TITLE": {
    title: "Asset Type",
    ENTITIES: {
      ENTITY_HOME,
      ENTITY_VEHICLE,
      ENTITY_OTHER
    }
  }
};

export const ENTITIES_DEBT = {
  ENTITY_DEBT_HOME,
  ENTITY_DEBT_VEHICLE,
  ENTITY_DEBT_OTHER
};

export const defaultHomeTaxRate = 1.1;

export const optionsValuesHomeTaxType = {
  INTEREST: "interest",
  AMOUNT: "amount"
};

const optionsHomeTaxType = [
  {
    name: "Assessed interest",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TAX_TYPES.INTEREST",
    value: optionsValuesHomeTaxType.INTEREST
  },
  {
    name: "Amount",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TAX_TYPES.AMOUNT",
    value: optionsValuesHomeTaxType.AMOUNT
  }
];

export const optionsValuesHome = {
  PRIMARY: "primary",
  VACATION: "vacation",
  INVESTMENT: "investment",
  MULTIPLE: "multiple",
  OTHER: "other"
};

const optionsHome = [
  {
    name: "Primary Residence",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPES.PRIMARY",
    value: optionsValuesHome.PRIMARY
  },
  {
    name: "Vacation",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPES.VACATION",
    value: optionsValuesHome.VACATION
  },
  {
    name: "Investment",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPES.INVESTMENT",
    value: optionsValuesHome.INVESTMENT
  },
  {
    name: "Multiple Homes",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPES.MULTIPLE",
    value: optionsValuesHome.MULTIPLE
  },
  {
    name: "Other",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPES.OTHER",
    value: optionsValuesHome.OTHER
  }
];
const optionsHomeWoPrimary = optionsHome.filter(el => el.value !== "primary");
const optionsVehicle = [
  {
    name: "Commuter Vehicle",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPES.COMMUTER",
    value: "commuter"
  },
  {
    name: "Multiple Vehicles",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPES.MULTIPLE",
    value: "multiple"
  },
  {
    name: "Recreational Vehicle",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPES.RECREATIONAL",
    value: "recreational"
  },
  {
    name: "Collector Vehicle",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPES.COLLECTOR",
    value: "collector"
  },
  {
    name: "Other",
    nameI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPES.OTHER",
    value: "other"
  }
];

const repeatableSchemeGeneral = {
  description: {
    isSubTitle: true,
    isFullWidth: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  },
  descriptionNotSubTitle: {
    isFullWidth: true,
    isSecondary: true,

    isRequired: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_HOME]: {
    icon: "IconHome",
    iconMulti: "IconHomeMulti",
    color: "green",
    title: "Home",
    titleI18n: "HOUSEHOLD.ASSETS.USE.TILE.HOME"
  },
  [ENTITIES.ENTITY_VEHICLE]: {
    icon: "IconVehicle",
    iconMulti: "IconVehicleMulti",
    color: "green",
    title: "Vehicle",
    titleI18n: "HOUSEHOLD.ASSETS.USE.TILE.VEHICLE"
  },
  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.ASSETS.USE.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconCarHome",
    color: "red",
    title: "Group of Assets",
    titleI18n: "HOUSEHOLD.ASSETS.USE.TILE.GROUP"
  }
};

export const ENTITIES_ADDITIONAL = {
  [ENTITY_HOME]: {
    [ENTITY_ADD_DEBT]: { ...debtFullScheme },
    [ENTITY_ADD_CONFIGURATION]: {
      taxType: {
        isSelect: true,
        options: optionsHomeTaxType,
        default: optionsValuesHomeTaxType.INTEREST,

        label: "Tax Type",
        labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.CONFIGURATION.TAX_TYPE"
      },
      taxRate: {
        isRequired: true,
        isNumber: true,

        condition: [
          {
            field: "taxType",
            value: optionsValuesHomeTaxType.INTEREST,
            inner: "configuration"
          }
        ],

        default: defaultHomeTaxRate,
        prefix: "%",
        label: "Tax Rate",
        labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.CONFIGURATION.TAX_RATE"
      },
      taxAmount: {
        isRequired: true,
        isAmount: true,
        isNumber: true,
        condition: [
          {
            field: "taxType",
            value: optionsValuesHomeTaxType.AMOUNT,
            inner: "configuration"
          }
        ],

        label: "Value",
        labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.CONFIGURATION.TAX_AMOUNT"
      }
      // annualChange: {
      //   isNumber: true,
      //
      //   default: 10,
      //   prefix: "%",
      //   label: "Annual Change",
      //   labelI18n: "HOUSEHOLD.COMMON.FORM.ANNUAL_CHANGE.LABEL"
      // }
    }
  },
  [ENTITY_VEHICLE]: {
    [ENTITY_ADD_DEBT]: { ...debtFullScheme }
  },
  [ENTITY_OTHER]: {
    [ENTITY_ADD_DEBT]: { ...debtFullScheme }
  },
  [ENTITY_GENERAL]: {
    // debt: { ...debtFullScheme }
  }
};

export const formScheme = {
  [ENTITIES.ENTITY_HOME]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.HOME.LABEL"
    },
    type: {
      isTitle: true,
      isRequired: true,
      isSelect: true,
      options: optionsHome,
      optionsHomeWoPrimary: optionsHomeWoPrimary,
      label: "Home Use",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.TYPE.LABEL"
    },
    purchasePrice: {
      isAdditional: true,

      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Purchase Price",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.PURCHASE_PRICE.LABEL"
    },
    purchaseDate: {
      isAdditional: true,

      condition: [
        {
          field: "type",
          valueIn: [
            optionsValuesHome.PRIMARY,
            optionsValuesHome.OTHER,
            optionsValuesHome.INVESTMENT,
            optionsValuesHome.VACATION
          ]
        }
      ],

      isShowDate: "MMMM do, yyyy",
      isRequired: true,
      isDate: true,
      label: "Purchase Date",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.PURCHASE_DATE.LABEL"
    },
    value: {
      isMain: true,

      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Value",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.HOME.LABEL"
    },
    countyAssessedValue: {
      isAdditional: true,
      calculate: { isUseHomeEstimatedTax: true },

      isRequired: true,
      isAmount: true,
      isNumber: true,
      labelI18nCard: "HOUSEHOLD.ASSETS.USE.FORM.ESTIMATED_TAX.HOME.LABEL",
      labelCard: "Estimated Tax",
      label: "County assessed value",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.COUNTY_ASSESSED_VALUE.HOME.LABEL"
    },
    debtId: {
      isHidden: true,
      isOnlyShow: true,
      label: "",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.HOME.LABELAAA"
    }
  },
  [ENTITIES.ENTITY_VEHICLE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.VEHICLE.LABEL"
    },
    type: {
      isTitle: true,
      isHidden: true,
      isRequired: true,
      isSelect: true,
      options: optionsVehicle,
      label: "Vehicle Use",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VEHICLE.TYPE"
    },
    value: {
      isMain: true,

      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Value",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.VEHICLE.LABEL"
    },
    debtId: {
      isHidden: true,
      isOnlyShow: true
    }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.OTHER.LABEL"
    },
    value: {
      isMain: true,
      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Value",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.OTHER.LABEL"
    },
    debtId: {
      isHidden: true,
      isOnlyShow: true
    }
  },
  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.GENERAL.LABEL"
    },
    value: {
      isMain: true,
      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Value",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.GENERAL.LABEL"
    }
  }
};

addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  GLOBAL_TYPE_USE_ASSETS
);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.ASSETS.USE",
        title: "Homes & Vehicles"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.TITLE",
      title: "All of your saving, protection and retirement assets in one"
    },
    caption: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.CAPTION",
      title: "Add or edit use assets you have"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.EMPTY",
      title: "You have no assets, please add your first asset"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.H2",
      title: "Use Assets"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "APP.ASSET.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "APP.ASSET.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "APP.SUCCESSFULLY_SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.SIDE_MENU_TITLE",
      title: "Add Assets"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.SIDE_MENU_TITLE.EDIT",
      title: "Edit Asset"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.FORM_GROUP.TITLE",
      title: "Asset Type"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.ASSET_TYPES.GENERAL_GROUP",
      title: "Group Of Assets",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Asset”",
      captionI18n: "HOUSEHOLD.ASSETS.USE.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.BUTTON.SAVE",
      title: "Save Asset"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.BUTTON",
      title: "+ Add Asset"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.ASSETS.USE.WHY.TITLE",
    textI18n: "HOUSEHOLD.ASSETS.USE.WHY.ALL_TEXT"
  }
};
