import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { connect } from "react-redux";
import { bootData } from "../../AC/ui";
import { withFirebase, withFirestore } from "react-redux-firebase";
import * as ROUTES from "../../constants/routes";
import CircularProgress from "@material-ui/core/CircularProgress";

class AdminApproveAdvisor extends Component {
  state = {
    isLoading: false,
    isUpdated: false
  };

  componentDidMount() {
    const { firebase, location, firestore } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const emailAdvisor = urlParams.get("email");
    const uidAdvisor = urlParams.get("uid");

    if (uidAdvisor) {
      this.setState({
        isLoading: true
      });

      firestore
        .doc("users/" + uidAdvisor)
        .update({
          isAdminApproved: true
        })
        .then(res => {
          this.setState({
            isUpdated: true,
            isLoading: false
          });
        })
        .catch(err => {
          this.setState({
            isError: true,
            isLoading: false
          });

          console.log("---", err);
        });
    }
  }

  render() {
    const { location, firebase } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const emailAdvisor = urlParams.get("email");

    return (
      <div style={{ padding: 40, paddingTop: 20 }}>
        {this.state.isUpdated ? (
          <h1>Advisor Approved!</h1>
        ) : (
          <h1>Advisor approving</h1>
        )}

        {!!this.state.isError && <div>error</div>}

        {!!emailAdvisor && <h2>{emailAdvisor}</h2>}

        {this.state.isLoading && <CircularProgress />}
      </div>
    );
  }
}

AdminApproveAdvisor.propTypes = {};
AdminApproveAdvisor.defaultProps = {};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, status }
}) {
  return {
    auth,
    profile,
    firestoreStatus: status
  };
}
export default compose(
  connect(
    mapStateToProps,
    { bootData }
  ),
  withFirestore,
  withFirebase
)(AdminApproveAdvisor);
