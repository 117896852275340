import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconMoneyOut = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path d="M8.5 2L13 6H10V10H7V6H4L8.5 2Z" fill={fill} opacity={opacity} />
      <path
        d="M4 8H5V11.9999H12V8H19C19.9428 8 20.4142 8 20.7071 8.29289C21 8.58579 21 9.05719 21 10V11.0254C20.742 11 20.4172 11 20 11H16C15.0572 11 14.5858 11 14.2929 11.2929C14 11.5858 14 12.0572 14 13V16C14 16.9428 14 17.4142 14.2929 17.7071C14.5858 18 15.0572 18 16 18H20C20.4172 18 20.742 18 21 17.9746V19C21 19.9428 21 20.4142 20.7071 20.7071C20.4142 21 19.9428 21 19 21H4C3.05719 21 2.58579 21 2.29289 20.7071C2 20.4142 2 19.9428 2 19V10C2 9.05719 2 8.58579 2.29289 8.29289C2.58579 8 3.05719 8 4 8Z"
        fill={fill}
        opacity={opacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13C15 12.5286 15 12.2929 15.1464 12.1464C15.2929 12 15.5286 12 16 12H21C21.4714 12 21.7071 12 21.8536 12.1464C22 12.2929 22 12.5286 22 13V16C22 16.4714 22 16.7071 21.8536 16.8536C21.7071 17 21.4714 17 21 17H16C15.5286 17 15.2929 17 15.1464 16.8536C15 16.7071 15 16.4714 15 16V13ZM18.5 14.5C18.5 15.0523 18.0523 15.5 17.5 15.5C16.9477 15.5 16.5 15.0523 16.5 14.5C16.5 13.9477 16.9477 13.5 17.5 13.5C18.0523 13.5 18.5 13.9477 18.5 14.5Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconMoneyOut.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconMoneyOut;
