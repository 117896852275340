import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconCart = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M3 3V4.8H5.05368L7.41681 11.8313L6.06645 13.6312H6.03832C5.62336 14.2113 5.60578 14.9848 5.89765 15.5437C6.18953 16.1027 6.80493 16.5 7.50121 16.5H19.2044V14.7H7.50121C7.50473 14.6965 7.49066 14.6859 7.50121 14.6719L8.85157 12.9H17.3195C17.6782 12.9 18.0439 12.7242 18.1353 12.3656L20.9204 6.06562C21.1912 5.43633 20.7341 4.8 20.1046 4.8H6.93856L6.5447 3.61875L6.34777 3H3ZM7.50121 17.4C6.50602 17.4 5.70073 18.2051 5.70073 19.2C5.70073 20.1949 6.50602 21 7.50121 21C8.4964 21 9.30169 20.1949 9.30169 19.2C9.30169 18.2051 8.4964 17.4 7.50121 17.4ZM17.4039 17.4C16.4087 17.4 15.6034 18.2051 15.6034 19.2C15.6034 20.1949 16.4087 21 17.4039 21C18.3991 21 19.2044 20.1949 19.2044 19.2C19.2044 18.2051 18.3991 17.4 17.4039 17.4Z"
        fill={fill}
      />
    </svg>
  );
};

IconCart.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconCart;
