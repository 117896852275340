import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "components/_common/Button/ButtonCommon";

import "./AdditionalForm.scss";
import { FormattedMessage } from "react-intl-v3";
import * as Icons from "../icons";

class AdditionalForm extends Component {
  static propTypes = {
    formKey: PropTypes.string,
    handleIsOpen: PropTypes.func,
    isStatic: PropTypes.bool,
    addConfiguration: PropTypes.func,
    isConfiguration: PropTypes.bool,
    actionComponent: PropTypes.any,
    mainColor: PropTypes.oneOf([
      "none",
      "ui",
      "brand",
      "yellow",
      "green",
      "red",
      "yellow",
      "textTetrieary",
      "white",
      "main"
    ])
  };

  state = {
    isOpen: true
  };

  handleToggle = () => {
    const { handleIsOpen, formKey, isStatic } = this.props;
    if (isStatic) {
      return null;
    }

    if (handleIsOpen && formKey) {
      handleIsOpen(formKey, !this.state.isOpen);
    }

    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const {
      actionFullTop,
      alwaysOpen,
      mainColor,
      isConfiguration,
      addConfiguration,
      isStatic
    } = this.props;

    const rest = {};

    return (
      <section
        className={`add-form ${
          mainColor === "yellow" ? "add-form--yellow" : ""
        } ${mainColor === "ui" ? "add-form--ui" : ""} ${mainColor === "main" ? "add-form--main" : ""}`}
      >
        <div
          className={`add-form__top ${
            actionFullTop && !isStatic ? "add-form__top--full-handle" : ""
          } ${isStatic ? "add-form__top--static" : ""}`}
          {...rest}
          onClick={actionFullTop || this.handleToggle}
        >
          <div className="add-form__top-title">{this.props.title}</div>

          {!!isStatic ? null : (
            <div className="add-form__top-action">
              {this.props.actionComponent ? (
                this.props.actionComponent
              ) : this.state.isOpen ? (
                <Icons.IconArrowUp color={mainColor} />
              ) : (
                <Icons.IconArrowDown color={mainColor} />
              )}
            </div>
          )}
        </div>

        {(alwaysOpen || this.state.isOpen) &&
          (!!this.props.children || isConfiguration) && (
            <div className="add-form__content">
              {this.props.children}

              {isConfiguration && (
                <Button type={"button"} onClick={addConfiguration}>
                  <FormattedMessage
                    id="ADDITIONAL_FORMS_LABELS.CONFIGURATION.ADD_ANOTHER"
                    defaultMessage="+ Add Configuration"
                  />
                </Button>
              )}
            </div>
          )}
      </section>
    );
  }
}

export default AdditionalForm;
