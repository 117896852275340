import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import ButtonMUI from "@material-ui/core/Button";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

class WizardNeeds extends Component {
  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderItem = (field, icon, label) => {
    const { classes, formState, handleInputNeedsChange } = this.props;

    const Icon = Icons[icon];

    return (
      <button
        className={`${classes.wizardNeedsItem} ${
          formState[field] ? classes.wizardNeedsItemChecked : ""
        }`}
        onClick={handleInputNeedsChange("needs", {
          name: field,
          checked: !formState[field]
        })}
      >
        <span className={classes.wizardNeedsItemText}>{label}</span>

        <span
          className={`${
            formState[field] ? classes.wizardNeedsItemIconChecked : ""
          }`}
        >
          <Icon />
        </span>

        {formState[field] && (
          <span className={` ${classes.wizardNeedsItemIconAccepted}`}>
            <Icons.IconBigAccepted color={"green"} />
          </span>
        )}
      </button>
    );
  };

  _renderContent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.wizardBodyForm} ref={this.body}>
        <div className={classes.wizardNeeds}>
          {this._renderItem(
            "savedMoney",
            "IconBigMoneyBag",
            <FormattedMessage
              id="QUIZ.QUESTION1"
              defaultMessage="Grow my money"
            />
          )}

          {this._renderItem(
            "planEvents",
            "IconBigEmployee",
            <FormattedMessage
              id="QUIZ.QUESTION2"
              defaultMessage="Ensure my family is protected"
            />
          )}

          {this._renderItem(
            "unexpectedEvents",
            "IconBigStrategy",
            <FormattedMessage
              id="QUIZ.QUESTION3"
              defaultMessage="Save for a major expense or event"
            />
          )}

          {this._renderItem(
            "needPlanning",
            "IconBigProfit",
            <FormattedMessage
              id="QUIZ.QUESTION4"
              defaultMessage="Build financial independence"
            />
          )}

          {this._renderItem(
            "needAdvice",
            "IconBigPlanning",
            <FormattedMessage
              id="QUIZ.QUESTION5"
              defaultMessage="Better tracking and control"
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      prevStep,
      nextStep,
      handleChangeStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.GOALS.LABEL"
                defaultMessage="Goals"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={1} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.GOALS.HEADING2"
              defaultMessage="Using this basic information we can forecast your spending needs and tax liabilities for the years to come"
            />
          </div>

          <div className={classes.wizardHeader3}>
            <FormattedMessage
              id="WIZARD.GOALS.HEADING3"
              defaultMessage="Check all that apply"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardNeeds.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardNeeds;
