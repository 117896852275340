import React from "react";

import "./ContentMain.scss";

const ContentMain = ({ children, isEvents, isNoGap }) => {
  return (
    <div
      className={`content-main ${isEvents ? "content-main--events" : ""} ${
        isNoGap ? "content-main--wo-gap" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ContentMain;
