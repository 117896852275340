import React, { Component } from "react";
import PropTypes from "prop-types";

import HouseholdCard from "../HouseholdMembers/HouseholdCard";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import * as Icons from "../_common/icons";
import arrayMove from "array-move";
import { entitiesFull, entitiesScheme } from "../AssetsCommon/formSchemeFull";
import { FormattedMessage } from "react-intl-v3";
import "components/Configuration/ConfigurationOverdraft.scss";
import { withStyles } from "@material-ui/core";
import { styles } from "./DahboardActionAndGoals.styles";
import ButtonCommon from "../_common/Button/ButtonCommon";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { formatMoneyIntWithSign } from "../../lib/helpers";

const GOAL_LIMIT = 3;

class AssetItem extends Component {
  static propTypes = {
    DragHandle: PropTypes.any,
    handleOpenEditGoal: PropTypes.func,
    handleDialogRemoveOpen: PropTypes.func,
    handleEnabledChange: PropTypes.func,
    members: PropTypes.any,
    asset: PropTypes.any
  };

  state = {
    anchorActionEl: null
  };

  handleCloseAction = () => {
    this.setState({
      anchorActionEl: null
    });
  };

  handleClickAction = ev => {
    this.setState({
      anchorActionEl: ev.currentTarget
    });
  };

  render() {
    const {
      asset,
      members,
      DragHandle,
      handleOpenEditGoal,
      handleDialogRemoveOpen
    } = this.props;
    const anchorActionEl = this.state.anchorActionEl;

    const categoryScheme = entitiesScheme[asset.globalType];
    const designScheme = categoryScheme.designScheme;
    const designSchemeEntity = designScheme[asset.entity];

    if (!designSchemeEntity) {
      return null;
    }

    const formScheme = categoryScheme.formScheme[asset.entity];

    const Icon = Icons[designSchemeEntity.iconBig || designSchemeEntity.icon];

    let values = { linebreak: () => null };

    Object.keys(asset).forEach(key => {
      const fieldScheme = formScheme[key];
      const value = asset[key];

      if (!fieldScheme) return;

      if (fieldScheme.isAmount) {
        values[key] = formatMoneyIntWithSign(asset[key]);
        return;
      }

      if (fieldScheme.isSelect) {
        if (members && members[value]) {
          values[key] = members[value].name || members[value].childName;
          return;
        }

        values[key] = asset[key];

        return;
      }

      values[key] = asset[key];
    });

    const text = designSchemeEntity.dashboardFormMessage ? (
      <FormattedMessage
        id={designSchemeEntity.dashboardFormMessage}
        values={{ ...values }}
      />
    ) : (
      <FormattedMessage
        id={designSchemeEntity.titleI18n}
        defaultMessage={designSchemeEntity.title}
      />
    );

    const color = designSchemeEntity.color;

    return (
      <div className="drag-item drag-item--goals">
        <DragHandle />

        <div className="drag-item__entity">
          <Icon color={color} width={48} height={48} />

          <div className="drag-item__title--goals">
            <div className="drag-item__title--goal-dsc">
              {asset.description}
            </div>

            <div className="drag-item__title--entity">{text}</div>
          </div>
        </div>

        <div className="drag-item__enabled">
          <IconButton
            aria-label="More"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={this.handleClickAction}
          >
            <MoreHorizIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorActionEl}
            keepMounted
            open={Boolean(anchorActionEl)}
            onClose={this.handleCloseAction}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <MenuItem onClick={handleOpenEditGoal(asset.id, asset.entity)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.EDIT"
                defaultMessage="Edit"
              />
            </MenuItem>

            <MenuItem onClick={handleDialogRemoveOpen(asset.id)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.ERASE"
                defaultMessage="Erase"
              />
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}

class DashboardGoals extends Component {
  static propTypes = {
    classes: PropTypes.any,
    members: PropTypes.any,
    goalsAll: PropTypes.any,
    firestore: PropTypes.any,
    handleAddTileGoalDialogOpen: PropTypes.func,
    handleOpenEditGoal: PropTypes.func,
    handleDialogRemoveOpen: PropTypes.func,
    profileProvider: PropTypes.any
  };

  state = {
    isPositionsUpdating: false,
    assetsTemp: null,
    isShowAll: false
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const { firestore, goalsAll } = this.props;
    const batch = firestore.batch();

    const newAssets = arrayMove(goalsAll, oldIndex, newIndex);

    const min = Math.min(newIndex, oldIndex);
    const max = Math.max(newIndex, oldIndex);

    for (let i = min; i <= max; i++) {
      const asset = newAssets[i];

      if (asset.position !== i) {
        const docRef = firestore.collection("userAssets").doc(asset.id);
        batch.update(docRef, {
          ...asset,
          position: i
        });
      }
    }

    this.setState({ isPositionsUpdating: true, assetsTemp: newAssets });

    batch.commit().then(res => {});
  };

  toggleShowAllGoals = () => {
    this.setState(state => ({ isShowAll: !state.isShowAll }));
  };

  _renderItems = () => {
    const {
      goalsAll,
      classes,
      handleOpenEditGoal,
      handleDialogRemoveOpen,
      members
    } = this.props;

    const compareGoals = (a, b) => {
      return a.position - b.position;
    };

    const goals = goalsAll.sort(compareGoals);
    const goalsFiltered = goals.filter(
      el => el.entity !== "general" && el.entity !== "other"
    );

    const SortableItem = SortableElement(({ assetEl, DragHandle, index }) => {
      return assetEl;
    });

    const DragHandle = SortableHandle(() => (
      <div className="drag-handle">
        <Icons.IconMenu width={24} height={24} color={"textTetrieary"} />
      </div>
    ));

    const SortableAssets = SortableContainer(({ items }) => {
      return (
        <div className="drag-container drag-container--goals">
          {items
            .sort(el => el.position)
            .filter(el => el.entity !== "general" && el.entity !== "other")
            .map((asset, index) => {
              if (!asset.globalType) {
                return null;
              }

              if (!this.state.isShowAll && index > GOAL_LIMIT) {
                return null;
              }

              return (
                <SortableItem
                  key={asset.id}
                  index={index}
                  assetEl={
                    <AssetItem
                      key={asset.id}
                      handleOpenEditGoal={handleOpenEditGoal}
                      handleDialogRemoveOpen={handleDialogRemoveOpen}
                      // handleEnabledChange={this.handleEnabledChange(index)}
                      DragHandle={DragHandle}
                      members={members}
                      asset={asset}
                    />
                  }
                />
              );
            })}
        </div>
      );
    });

    return (
      <>
        <SortableAssets
          useDragHandle
          items={this.state.assetsTemp || goals}
          onSortEnd={this.onSortEnd}
          onSortStart={this.handleOnDragStart}
        />
        {goalsFiltered && goalsFiltered.length > GOAL_LIMIT && (
          <div
            onClick={this.toggleShowAllGoals}
            className={classes.ActionButtonCollapse}
          >
            {this.state.isShowAll ? (
              <>
                <FormattedMessage
                  id="DASHBOARD.GOALS.SHOW_NOT_ALL"
                  defaultMessage="Collapse"
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id="DASHBOARD.GOALS.SHOW_ALL"
                  defaultMessage="Click to view the entire list"
                />
              </>
            )}
          </div>
        )}
      </>
    );
  };

  _renderEmpty = () => {
    const { classes, handleOpenEditGoal } = this.props;

    return (
      <div className={classes.GoalsEmptyContainer}>
        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_RETIREMENT
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigRetirement width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.RETIREMENT"
              defaultMessage="Retirement"
            />
          </span>
        </button>

        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_HOME_PURCHASE
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigHome width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.HOUSE"
              defaultMessage="Home Purchase/Renovation"
            />
          </span>
        </button>

        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_LEGACY
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigBank width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.LEGACY"
              defaultMessage="Legacy"
            />
          </span>
        </button>

        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_PROTECT_LOVED
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigInsurance width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.INSURANCE"
              defaultMessage="Protect Loved Ones"
            />
          </span>
        </button>

        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_EDUCATION
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigEducation width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.EDUCATION"
              defaultMessage="Education Savings"
            />
          </span>
        </button>

        <button
          className={classes.GoalsAddButton}
          onClick={handleOpenEditGoal(
            null,
            entitiesFull.entitiesGoals.ENTITY_MAJOR_EXPANSE
          )}
        >
          <div className={classes.GoalsAddButtonIconPlus}>
            <Icons.IconPlusNew width={68} height={68} color={"brand"} />
          </div>

          <div className={classes.GoalsAddButtonIcon}>
            <Icons.IconBigLoan width={76} height={76} />
          </div>

          <span className={classes.GoalsAddButtonTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD.MAJOR"
              defaultMessage="Major Expense"
            />
          </span>
        </button>
      </div>
    );
  };

  render() {
    const { goalsAll, classes, handleAddTileGoalDialogOpen } = this.props;

    const isGoalsExists = Boolean(
      goalsAll &&
        goalsAll.filter(el => el.entity !== "general" && el.entity !== "other")
          .length
    );

    const title = (
      <div className={classes.TitleContainer}>
        <div>
          <div className={classes.title}>
            <FormattedMessage
              id="DASHBOARD.GOALS.TITLE"
              defaultMessage="Goals"
            />
          </div>

          <div className={classes.subTitle}>
            <FormattedMessage
              id="DASHBOARD.GOALS.CAPTION"
              defaultMessage="Set and track goals to get closer to your dream future."
            />
          </div>
        </div>

        {isGoalsExists && (
          <ButtonCommon brand onClick={handleAddTileGoalDialogOpen}>
            <FormattedMessage
              id="DASHBOARD.GOALS.ADD_NEW"
              defaultMessage="+ Add another goal"
            />
          </ButtonCommon>
        )}
      </div>
    );

    return (
      <HouseholdCard
        classNames={classes.ActionAndGoalsBlock}
        titleEl={title}
        style={{ flex: 2 }}
      >
        {isGoalsExists && this._renderItems()}

        {!isGoalsExists && this._renderEmpty()}
      </HouseholdCard>
    );
  }
}

export default withStyles(styles)(DashboardGoals);
