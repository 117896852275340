import {
  WIZARD_FAMILY,
  WIZARD_ASSETS,
  WIZARD_APPROXIMATION,
  WIZARD_ASSETS_DEBT,
  WIZARD_MONEY,
  WIZARD_DEFAULTS_DEFAULT
} from "constants/wizardSetDefault";

// custom wizard templates
import * as wizardSets from "wizardTemplates/wizardSets";

export const INVESTMENT_RENT_LIMIT = 50000;

export const WIZARD_DEFAULTS = {
  default: {
    ...WIZARD_DEFAULTS_DEFAULT
  }
};

Object.keys(wizardSets).forEach(key => {
  const wizardTemplate = wizardSets[key];

  WIZARD_DEFAULTS[wizardTemplate.nameDB] = wizardTemplate;
});

export const optionsWizardSets = Object.keys(WIZARD_DEFAULTS).map(key => {
  const name =
    (WIZARD_DEFAULTS[key].meta && WIZARD_DEFAULTS[key].meta.title) || key;

  const nameI18n = `WIZARD.DEFAULT_SET.${key}.TITLE`;

  return { value: key, name, nameI18n };
});

export const WIZARD_SETS_COLLECTION = "wizardSets";
