import { Provider as ReduxProvider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import React from "react";
import PropTypes from "prop-types";
import IntlProvider from "./IntlProvider";
import { rrfProps } from "./firebase";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./muiTheme";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

const Providers = ({ store, children }) => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ReduxProvider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps} dispatch={store.dispatch}>
          <IntlProvider>{children}</IntlProvider>
        </ReactReduxFirebaseProvider>
      </ReduxProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.object.isRequired
};

export default Providers;
