import { createSelector } from "reselect";
import { GLOBAL_TYPE_USE_ASSETS as typeEvents } from "components/PlansEvents/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as typeGoals } from "components/PlansGoals/formScheme";

export const getAuthIsLoadedState = state => state.firebase.auth.isLoaded;
export const getAuthIsEmptyState = state => state.firebase.auth.isEmpty;
export const getAuthExpireInState = state => state.firebase.auth.expirationTime;
export const getAuthIsVerifiedState = state =>
  state.firebase.auth.emailVerified;

export const getNotDisabledEntities = entities => {
  if (!entities) {
    return entities;
  }

  const result = {};
  Object.keys(entities).forEach(key => {
    if (entities[key] && !entities[key].isDisabled && !entities[key].isWhatIf) {
      result[key] = entities[key];
    }
  });

  return result;
};

export const getNotDisabledEntitiesOrdered = entities => {
  if (!entities) {
    return entities;
  }

  return entities.filter(entity => !entity.isDisabled);
};

export const getProfileIsLoadedState = state => state.firebase.profile.isLoaded;
export const getAuthState = state => state.firebase.auth;
export const getProfileState = state => state.firebase.profile;
export const getMyClientsAllState = state =>
  state.firestore.data && state.firestore.data.myClientsAll;
export const getMyClientsAllOrderedState = state =>
  state.firestore.ordered && state.firestore.ordered.myClientsAll;
export const getProfileLastNameState = state => state.firebase.profile.lastName;
export const getProfileFirstNameState = state =>
  state.firebase.profile.firstName;
export const getProfileDisplayNameState = state =>
  state.firebase.profile.displayName;
export const getProfileIsAdminApprovedState = state =>
  state.firebase.profile.isAdminApproved;

export const getMyClients = state =>
  state.firestore && state.firestore.data && state.firestore.data.myClients;
export const getMyClientsState = createSelector(
  getMyClients,
  items => items
);
export const getMyAdvisors = state =>
  state.firestore && state.firestore.data && state.firestore.data.myAdvisors;
export const getMyAdvisorsState = createSelector(
  getMyAdvisors,
  items => items
);

const sortItems = items => items;

export const itemsSorted = createSelector(
  sortItems,
  items => (items ? items.slice().sort(sortAssets) : null)
);

function sortAssets(it1, it2) {
  if ((it1.isDisabled || false) > (it2.isDisabled || false)) return 1;
  if ((it1.isDisabled || false) < (it2.isDisabled || false)) return -1;

  if (it1.entity > it2.entity) return 1;
  if (it1.entity < it2.entity) return -1;

  if (it1.type > it2.type) return 1;
  if (it1.type < it2.type) return -1;

  return 0;
}

const getTransactionsAll = data => data && data.transactionsAll;

const getAssetsAll = data => data && data.assetsAll;
export const getAssetsAllData = createSelector(
  getAssetsAll,
  items => items
);
// const getAssetsAllTest = data => data && data.assetsAll;
export const getAssetsAllDataTest = createSelector(
  getAssetsAll,
  items => (items ? items.filter(item => item.isWhatIfTest) : null)
);
const getAssetsFull = data => data && data.assetsFull;
export const getAssetsFullData = createSelector(
  getAssetsFull,
  items => items
);
const getAssetsAllWizard = data => data && data.assetsAllWizard;
export const getAssetsAllWizardData = createSelector(
  getAssetsAllWizard,
  items => items
);

export const getAssetsAllEventsData = createSelector(
  getTransactionsAll,
  items => (items ? items.filter(item => item.globalType === typeEvents) : null)
);
export const getAssetsAllEventsDataTest = createSelector(
  getTransactionsAll,
  items =>
    items
      ? items.filter(
          item => item.isWhatIfTest && item.globalType === typeEvents
        )
      : null
);
export const getAssetsAllGoalsData = createSelector(
  getAssetsAll,
  items => (items ? items.filter(item => item.globalType === typeGoals) : null)
);

export const getAssetsAllGoalsDataTest = createSelector(
  getAssetsAll,
  items =>
    items
      ? items.filter(item => item.isWhatIfTest && item.globalType === typeGoals)
      : null
);

export const getTransactionsAllData = createSelector(
  getTransactionsAll,
  items => items
);
export const getTransactionsAllDataTest = createSelector(
  getTransactionsAll,
  items => (items ? items.filter(item => item.isWhatIfTest) : null)
);

const getTransactionsAllWizard = data => data && data.transactionsAllWizard;
export const getTransactionsAllDataWizard = createSelector(
  getTransactionsAllWizard,
  items => items
);

const getDebtsNotHomeAndGeneral = ordered => ordered && ordered.debts;

export const debtsNotHomeNGeneral = createSelector(
  getDebtsNotHomeAndGeneral,
  items => {
    return items
      ? items.filter(
          el =>
            !el.isDisabled && el.entity !== "home" && el.entity !== "general"
        )
      : null;
  }
);
