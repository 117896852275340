import React, { Component } from "react";
import PropTypes from "prop-types";

// import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";

import Button from "components/_common/Button/ButtonAddAsset";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { NavLink, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl-v3";

import { emailValid } from "./helper";
import * as ROUTES from "constants/routes";
import IsLoading from "routeHandlers/IsLoadingAuth";
import AppBarLogIn from "components/ContainerApp/AppBarLogIn";
import { functionsURL } from "config/config";
import { CLOUD_FUNC_ENDPOINT } from "../../constants/endpoints";
import toast from "../_common/toast";
import { CircularProgress } from "@material-ui/core";
import { colors } from "../../constants/styles";

class Login extends Component {
  static propTypes = {
    isAdminApproved: PropTypes.bool,
    isLoadedProfile: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isEmpty: PropTypes.bool,
    bootData: PropTypes.func
  };

  state = {
    email: "",
    password: "",

    isVerified: false,
    isLoading: false,
    isLoggedIn: false,
    error: "",
    errorMessage: "",
    isEmailValid: true,
    isPasswordValid: true,
    loginSuccess: false,
    isApprovalNotifySent: false,
    isApprovalNotifySending: false,
    isSubmitted: false,

    emailSending: false
  };

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { isLoaded, profile, firebase } = this.props;

    // this.props.expires;
    if (!isLoaded && !this.state.isLoading) {
      return this.setState({
        isLoading: true
      });
    }

    const isEmailVerified =
      firebase &&
      firebase.auth().currentUser &&
      firebase.auth().currentUser.emailVerified;

    // TODO CHECK EXPIRES
    const isLoggedIn = profile.isLoaded && !profile.isEmpty && isEmailVerified;

    if (
      isLoggedIn &&
      firebase &&
      firebase.auth().currentUser &&
      isEmailVerified !== this.state.isVerified &&
      !this.state.isVerified
    ) {
      this.setState({
        isVerified: isEmailVerified
      });
    }

    if (isLoggedIn !== this.state.isLoggedIn) {
      this.setState({
        isLoggedIn
      });
    }

    if (isLoggedIn) {
      this.handleSendAdminNotify();
    }
  }
  isApprovalNotifySending = false;
  isApprovalNotifySent = false;

  handleValidateData = () => {
    const isEmailValid = emailValid(this.state.email);
    const isPasswordValid = !!this.state.password;

    this.setState({ isEmailValid, isPasswordValid });

    return isEmailValid && isPasswordValid;
  };

  handleSendAdminNotify = () => {
    const { firebase, firestore, profile } = this.props;
    const currentUser = firebase.auth().currentUser;

    if (
      profile &&
      profile.isAdvisor &&
      !profile.isAdminApproved &&
      !profile.isApprovalSent &&
      !this.state.isApprovalNotifySent &&
      !this.state.isApprovalNotifySending &&
      !this.isApprovalNotifySent &&
      !this.isApprovalNotifySending &&
      currentUser &&
      currentUser.emailVerified
    ) {
      this.isApprovalNotifySending = true;
      this.setState({ isApprovalNotifySending: true });

      currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          const data = {
            email: currentUser.email,
            uid: currentUser.uid,
            name: profile.displayName,
            idToken,
            message: "Notify Admin"
          };

          fetch(
            `${functionsURL}/${CLOUD_FUNC_ENDPOINT.sendMailAdminApprovalLink}`,
            {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            }
          ).then(res => {
            this.isApprovalNotifySent = true;
            this.isApprovalNotifySending = false;

            this.setState({
              isApprovalNotifySent: true,
              isApprovalNotifySending: false
            });

            firestore.update(`users/${currentUser.uid}`, {
              updated: new Date(),
              isApprovalSent: true
            });
          });
        })
        .catch(error => {
          this.isApprovalNotifySending = false;
          this.setState({
            isApprovalNotifySending: false
          });
        });
    }
  };

  handleSubmit = ev => {
    ev.preventDefault();

    const { firebase } = this.props;

    const { email, password } = this.state;

    if (!this.handleValidateData()) {
      return;
    }

    this.setState({
      error: "",
      errorMessage: ""
    });

    firebase
      .login({ email, password })
      .then(({ user, ...rest }) => {
        this.setState({
          loginSuccess: true,
          isSubmitted: true,
          isLoggedIn: true
        });

        const isEmailVerified =
          firebase &&
          firebase.auth().currentUser &&
          firebase.auth().currentUser.emailVerified;

        if (isEmailVerified) {
          this.handleSendAdminNotify();

          this.props.bootData({ email: user.email });
        }
      })
      .catch(err => {
        console.log("---", err);

        this.setState({
          error: err.code,
          errorMessage: err.message,
          isSubmitted: true
        });
      });
  };

  handleSendVerificationEmailCustom = () => {
    const { intl } = this.props;
    const { firebase, profile } = this.props;

    const { firstName, lastName, email } = profile;

    const currentUser = firebase.auth().currentUser;

    if (currentUser && !currentUser.emailVerified) {
      this.setState({ emailSending: true });

      const data = {
        repeat: true,
        firstName: firstName,
        lastName: lastName,
        email: currentUser.email,
        uid: currentUser.uid,
        subject: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.SUBJECT",
          defaultMessage: "Verify your email for XCurve"
        }),
        greet: intl.formatMessage(
          {
            id: "EMAIL.CONFIRMATION.MESSAGE.GREET",
            defaultMessage: "Hello {firstName} {lastName},"
          },
          { firstName: firstName, lastName: lastName }
        ),
        linkDesc: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.LINK_DESC",
          defaultMessage: "Follow this link to verify your email address."
        }),
        caution: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.CAUTION",
          defaultMessage:
            "If you didn’t ask to verify this address, you can ignore this email."
        }),
        thanks: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.THANKS",
          defaultMessage: "Thanks,"
        }),
        yourTeam: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.YOUR_TEAM",
          defaultMessage: "Your XCurve team."
        })
      };

      fetch(`${functionsURL}/${CLOUD_FUNC_ENDPOINT.sendWelcomeEmailNew}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(res => {
          this.setState({ emailSending: false });

          toast(
            <FormattedMessage
              id="LOGIN.LINK.SEND_VERIFICATION_EMAIL_SENT"
              defaultMessage="Email was sent"
            />
          );
        })
        .catch(err => {
          this.setState({
            isCreating: false,
            emailSending: false
          });
        });
    }
  };

  handleInputChange = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value,
      isEmailValid: true,
      isPasswordValid: true
    });
  };

  handleSendVerificationEmail = () => {
    const { firebase } = this.props;

    const currentUser = firebase.auth().currentUser;

    if (currentUser && !currentUser.emailVerified) {
      currentUser.sendEmailVerification().then(() => {
        this.setState({ isEmailSent: true });
      });
    }
  };

  _renderForm = () => {
    const {
      classes,
      isLoaded,
      isEmpty,
      isLoadedProfile,
      isAdminApproved
    } = this.props;

    const { isSubmitted } = this.state;

    const isAccountCreated = isLoaded && !isEmpty;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        <FormControl
          className={classes.formControl}
          margin="normal"
          required
          fullWidth
        >
          <TextField
            id="email"
            name="email"
            label={
              <FormattedMessage
                id="SIGNUP.FORM.EMAIL"
                defaultMessage="E-mail"
              />
            }
            autoComplete="email"
            value={this.state.email}
            autoFocus
            onChange={this.handleInputChange}
            error={
              isSubmitted && (!this.state.isEmailValid || !!this.state.error)
            }
            helperText={
              isSubmitted && !this.state.isEmailValid ? (
                <FormattedMessage
                  id="SIGNUP.ERROR.INVALID_EMAIL"
                  defaultMessage="You must enter valid email address"
                />
              ) : null
            }
          />
        </FormControl>
        <FormControl
          className={classes.formControl}
          margin="normal"
          required
          fullWidth
        >
          <TextField
            id="password"
            name="password"
            type="password"
            label={
              <FormattedMessage
                id="SIGNUP.FORM.PASSWORD"
                defaultMessage="Password"
              />
            }
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleInputChange}
            error={
              isSubmitted && (!this.state.isPasswordValid || !!this.state.error)
            }
            helperText={
              isSubmitted && !this.state.isPasswordValid ? (
                <FormattedMessage
                  id="SIGNUP.ERROR.INVALID_PASSWORD"
                  defaultMessage="You must input password"
                />
              ) : null
            }
          />
        </FormControl>

        {isSubmitted && isAccountCreated && !this.state.isVerified && (
          <Typography style={{ color: "#FF3B30" }}>
            <FormattedMessage
              id="LOGIN.ERROR.EMAIL_NOT_VERIFIED"
              defaultMessage="You must verified your email. Please check your email and push the confirmation link."
            />
          </Typography>
        )}

        {isSubmitted &&
          this.state.isLoggedIn &&
          isLoadedProfile &&
          !isAdminApproved && (
            <Typography style={{ color: "#FF3B30" }}>
              <FormattedMessage
                id="APP.WAIT_FOR_ADMIN_APPROVE"
                defaultMessage="Wait for admin approve"
              />
            </Typography>
          )}

        {isSubmitted && this.state.error && this.state.errorMessage && (
          <Typography style={{ color: "#FF3B30" }}>
            <FormattedMessage
              id="LOGIN.FORM.ERROR.LOGIN_OR_PASS"
              defaultMessage="Wrong email or password"
            />
          </Typography>
        )}

        {isSubmitted && isAccountCreated && !this.state.isVerified && (
          <button
            className={classes.ResendVerificationButton}
            onClick={this.handleSendVerificationEmailCustom}
            disabled={this.state.emailSending}
          >
            <Typography className={classes.forgotLink}>
              <FormattedMessage
                id="LOGIN.LINK.SEND_VERIFICATION_EMAIL_AGAIN"
                defaultMessage="Send verification email again"
              />

              {this.state.emailSending && (
                <>
                  {` `}
                  <CircularProgress size={12} color={colors.brand} />
                </>
              )}
            </Typography>
          </button>
        )}

        <NavLink to={ROUTES.FORGOT_PASSWORD} className={classes.Links}>
          <Typography className={classes.forgotLink}>
            <FormattedMessage
              id="LOGIN.LINK.FORGOT_PASS"
              defaultMessage="Don't have an account yet? Create now"
            />
          </Typography>
        </NavLink>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.handleSubmit}
        >
          <FormattedMessage
            id="LOGIN.FORM.SUBMIT"
            defaultMessage="Log In to XCurve"
          />
        </Button>

        <Typography className={classes.signupLink}>
          <NavLink to={ROUTES.SIGN_UP}>
            <FormattedMessage
              id="LOGIN.LINK.SIGNUP"
              defaultMessage="Don't have an account yet? Create now"
            />
          </NavLink>
        </Typography>
      </form>
    );
  };

  render() {
    const { classes, isLoadedProfile, isAdminApproved, firebase } = this.props;

    const { isVerified } = this.state;
    if (
      this.state.isLoggedIn &&
      isLoadedProfile &&
      isAdminApproved &&
      isVerified
    ) {
      return <Redirect to={ROUTES.DASHBOARD} />;
    }

    if (!isLoadedProfile) {
      return <IsLoading />;
    }

    return (
      <main className={classes.main}>
        <CssBaseline />

        <AppBarLogIn
          rightButtonLink={"/signup"}
          rightButtonText={
            <FormattedMessage
              id="APP.CREATE_ACCOUNT"
              defaultMessage="Create Account"
            />
          }
        />

        <Paper className={classes.paper}>
          <div className={classes.paperLeft}>
            <div className={classes.titleWrap}>
              <Typography component="h1" variant="h5" className={classes.title}>
                <FormattedMessage id="LOGIN.HEADING" defaultMessage="Log in" />
              </Typography>
            </div>
            <div className={classes.imgCont}>
              <img src="/assets/img/bg-sign.svg" alt="" />
            </div>
            <img
              className={classes.logoBottom}
              src="/assets/img/logo-orange-white.svg"
              alt=""
            />
          </div>

          <div className={classes.paperRight}>{this._renderForm()}</div>
        </Paper>
        <style>{`
            @keyframes autofill {
                 to { background: #ffffff; }
            }

            input:-webkit-autofill {
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
            }
        `}</style>
      </main>
    );
  }
}

export default withStyles(styles)(injectIntl(Login));
