import React from "react";

import { colors } from "constants/styles";

const IconPlans = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          d="M8.53125 3.0625L5.03125 6.21875L3.5 4.6875L2.09375 6.09375L4.28125 8.3125L4.96875 8.96875L5.65625 8.34375L9.875 4.53125L8.53125 3.0625ZM12 6V8H22V6H12ZM8.53125 9.0625L5.03125 12.2188L3.5 10.6875L2.09375 12.0938L4.28125 14.3125L4.96875 14.9688L5.65625 14.3438L9.875 10.5312L8.53125 9.0625ZM12 12V14H22V12H12ZM8.53125 15.0625L5.03125 18.2188L3.5 16.6875L2.09375 18.0938L4.28125 20.3125L4.96875 20.9688L5.65625 20.3438L9.875 16.5312L8.53125 15.0625ZM12 18V20H22V18H12Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconPlans;
