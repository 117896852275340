import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconGlasses = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M2.99997 10C2.75997 10 2.54997 10.09 2.40997 10.25C2.26997 10.4 2.20997 10.62 2.23997 10.86L2.73997 13.85C2.81997 14.5 3.39997 15 3.99997 15H6.99997C7.63997 15 8.35997 14.44 8.49997 13.82L9.55997 10.63C9.59997 10.5 9.56997 10.31 9.49997 10.19C9.38997 10.07 9.21997 10 8.99997 10H2.99997ZM6.99997 17H3.99997C2.37997 17 0.959971 15.74 0.759971 14.14L0.259971 11.15C0.149971 10.3 0.389971 9.5 0.909971 8.92C1.42997 8.34 2.18997 8 2.99997 8H8.99997C9.82997 8 10.58 8.35 11.06 8.96C11.17 9.11 11.27 9.27 11.35 9.45C11.78 9.36 12.22 9.36 12.64 9.45C12.72 9.27 12.82 9.11 12.94 8.96C13.41 8.35 14.16 8 15 8H21C21.81 8 22.57 8.34 23.09 8.92C23.6 9.5 23.84 10.3 23.74 11.11L23.23 14.18C23.04 15.74 21.61 17 20 17H17C15.44 17 13.92 15.81 13.54 14.3L12.64 11.59C12.26 11.31 11.73 11.31 11.35 11.59L10.43 14.37C10.07 15.82 8.55997 17 6.99997 17ZM15 10C14.78 10 14.61 10.07 14.5 10.19C14.42 10.31 14.4 10.5 14.45 10.7L15.46 13.75C15.64 14.44 16.36 15 17 15H20C20.59 15 21.18 14.5 21.25 13.89L21.76 10.82C21.79 10.62 21.73 10.4 21.59 10.25C21.45 10.09 21.24 10 21 10H15Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconGlasses.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconGlasses;
