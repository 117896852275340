import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconWhatIf = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M2 18C2 19.1055 2.89453 20 4 20C5.10547 20 6 19.1055 6 18C6 17.9141 5.98047 17.832 5.96875 17.75L7.0625 16.9375C8.32812 18.2031 10.0664 19 12 19C12.1797 19 12.3555 18.9805 12.5312 18.9687L12.6562 19.5312C12.2578 19.8984 12 20.4141 12 21C12 22.1055 12.8945 23 14 23C15.1055 23 16 22.1055 16 21C16 20.1016 15.4062 19.3477 14.5937 19.0937L14.4687 18.5312C15.5781 18.1133 16.5547 17.418 17.3125 16.5312L18 16.9375C18 16.957 18 16.9805 18 17C18 18.1055 18.8945 19 20 19C21.1055 19 22 18.1055 22 17C22 15.8945 21.1055 15 20 15C19.6602 15 19.3437 15.1016 19.0625 15.25L18.4062 14.8125C18.7852 13.9531 19 13 19 12C19 10.2617 18.3594 8.69141 17.3125 7.46875L18.5937 5.96875C18.7227 5.99609 18.8633 6 19 6C20.1055 6 21 5.10547 21 4C21 2.89453 20.1055 2 19 2C17.8945 2 17 2.89453 17 4C17 4.23047 17.0547 4.44922 17.125 4.65625L15.8125 6.125C14.7148 5.41016 13.4102 5 12 5C10.4219 5 8.98437 5.52344 7.8125 6.40625L5.9375 4.5C5.98047 4.33984 6 4.17187 6 4C6 2.89453 5.10547 2 4 2C2.89453 2 2 2.89453 2 4C2 5.10547 2.89453 6 4 6C4.17187 6 4.33984 5.98047 4.5 5.9375L6.40625 7.8125C5.52344 8.98437 5 10.4219 5 12C5 13.207 5.30469 14.3516 5.84375 15.3437L4.78125 16.1562C4.54297 16.0547 4.27734 16 4 16C2.89453 16 2 16.8945 2 18ZM7 12.5312L7 11.2187L8.15625 11.2187C8.25781 10.7031 8.68359 9.45703 10.5937 9.40625L10.5937 10.9375C10.1719 10.9375 9.34375 11.0156 9.34375 11.875C9.34375 12.0312 9.37891 12.7812 10.25 12.7812C11.7461 12.7812 11.2461 9.40625 13.8437 9.40625C14.1367 9.40625 15.4805 9.44531 15.875 11.2187L17 11.2187L17 12.5312L15.9062 12.5312C15.5703 14.5391 13.875 14.5937 13.4687 14.5937L13.4687 13.0625C13.7187 13.0625 14.75 13.0508 14.75 11.9375C14.75 11.0156 13.9727 10.9375 13.8437 10.9375C12.1094 10.9375 12.9805 14.3125 10.25 14.3125C8.74219 14.3125 8.3125 13.1328 8.1875 12.5312L7 12.5312Z"
        fill={fill}
      />
    </svg>
  );
};

IconWhatIf.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconWhatIf;
