import differenceInMonths from "date-fns/differenceInCalendarMonths";
import differenceInDays from "date-fns/differenceInCalendarDays";
import addYears from "date-fns/addYears";
import addMonths from "date-fns/addMonths";

import { DEBT_ENDING_SOON } from "components/HouseholdDebt/formScheme";

export function expiredCalculate(
  asset,
  { expiredCalc, endingSoonCalc, field, keyField, calcDate = Date.now() }
) {
  // deprecated
  if (expiredCalc.isDateExpired) {
    if (field && field.seconds) {
      const monthsLeft = differenceInMonths(field.seconds * 1000, calcDate);

      if (monthsLeft <= 0) {
        return { isExpired: true };
      }
    }
  }

  if (expiredCalc.isDebtMonthlyExp) {
    const res = helperIsDebtMonthlyExp(asset, { calcDate });
    const resEnding = helperIsDebtMonthlyEndingSoon(asset, { calcDate });

    return {
      isExpired: res,
      isEndingSoon: resEnding
    };
  }

  if (expiredCalc.isRemainingVestingPeriod) {
    if (helperIsRemainingVestingPeriod(asset, { calcDate })) {
      return {
        isExpired: true
      };
    }
  }

  if (expiredCalc.isMonthsLeftPeriod) {
    return helperIsMonthsLeftPeriod(asset, {
      expiredCalc,
      endingSoonCalc,
      keyField,
      calcDate
    });
  }

  if (expiredCalc.isYearsLeftPeriod) {
    let res = {};

    // endingSoonCalc
    const fieldDate = asset[expiredCalc.fieldDate];

    if (fieldDate && fieldDate.seconds && asset[keyField]) {
      const endedDate = addYears(fieldDate.toDate(), asset[keyField]);
      const daysDiff = differenceInDays(endedDate, calcDate);

      if (endingSoonCalc) {
        const monthsDiff = differenceInMonths(endedDate, calcDate);

        if (monthsDiff <= endingSoonCalc.monthsLeftPeriod) {
          res.isEndingSoon = true;
        }
      }

      if (daysDiff <= 0) {
        res.isExpired = true;
      }
    }
    return res;
  }

  if (expiredCalc.isPeriodsEnded) {
    const fieldDate = asset[expiredCalc.fieldDate];
    const newState = {};

    if (field < 0) {
      return {
        [expiredCalc.fieldDate]: null,
        [keyField]: 0,
        isExpired: true
      };
    }

    if (fieldDate && fieldDate.seconds) {
      const daysLeft = differenceInDays(fieldDate.seconds * 1000, calcDate);

      if (daysLeft < 0) {
        newState[keyField] = asset[keyField] - 1;

        if (newState[keyField] < 0) {
          newState[expiredCalc.fieldDate] = null;
          newState[keyField] = 0;
          newState.isExpired = true;
        } else {
          newState[expiredCalc.fieldDate] = addYears(
            fieldDate.seconds * 1000,
            1
          );
        }

        return newState;
      }
    }
  }

  return {};
}

export function helperIsRemainingVestingPeriod(
  asset,
  { calcDate = Date.now() } = {}
) {
  if (asset.awardDate && asset.awardDate.seconds) {
    const remainingPeriods =
      asset.remainingVestingPeriod -
      Math.floor(
        differenceInDays(calcDate, asset.awardDate.seconds * 1000) / 365
      );

    if (remainingPeriods < 0) {
      return true;
    }
  }

  return false;
}

export function helperIsMonthsLeftPeriod(
  asset,
  { expiredCalc, endingSoonCalc, keyField, calcDate = Date.now() }
) {
  const fieldDate = asset[expiredCalc.fieldDate];
  let res = {};

  if (fieldDate && fieldDate.seconds && asset[keyField]) {
    const endedDate = addMonths(fieldDate.toDate(), asset[keyField]);
    const daysDiff = differenceInDays(endedDate, calcDate);

    if (endingSoonCalc) {
      const monthsDiff = differenceInMonths(endedDate, calcDate);

      if (monthsDiff <= endingSoonCalc.monthsLeftPeriod) {
        res.isEndingSoon = true;
      }
    }

    if (daysDiff <= 0) {
      res.isExpired = true;
      return res;
    }
  }

  return res;
}

export function helperIsDebtMonthlyEndingSoon(
  asset,
  { calcDate = Date.now() } = {}
) {
  const fieldDate = asset.loanDate;

  if (fieldDate && fieldDate.seconds && asset.monthlyPayment) {
    const monthsToPay = asset.loanAmount / asset.monthlyPayment;
    const monthsDiff = Math.abs(
      differenceInMonths(fieldDate.seconds * 1000, calcDate)
    );

    if (monthsToPay - monthsDiff <= DEBT_ENDING_SOON) {
      return true;
    }
  }

  return false;
}

export function helperIsDebtMonthlyExp(asset, { calcDate = Date.now() } = {}) {
  const fieldDate = asset.loanDate;

  if (fieldDate && fieldDate.seconds && asset.monthlyPayment) {
    const monthsToPay = asset.loanAmount / asset.monthlyPayment;
    const monthsDiff = Math.abs(
      differenceInMonths(fieldDate.seconds * 1000, calcDate)
    );

    if (monthsToPay < monthsDiff) {
      return true;
    }
  }

  return false;
}
