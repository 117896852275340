import React from "react";
import PropTypes from "prop-types";

import "./IconContainer.scss";

const classColor = {
  none: "",
  // transparent: "side-item__icon--bg_transparent",
  brand: "side-item__icon--bg_brand",
  white: "side-item__icon--bg_white",
  ui: "side-item__icon--bg_ui",
  red: "side-item__icon--bg_red",
  green: "side-item__icon--bg_green",
  yellow: "side-item__icon--bg_yellow",
  textTetrieary: "side-item__icon--bg_tetriary",
  textSecondary: "side-item__icon--bg_secondary",
  hoverNoToBrand: "side-item__icon--bg_no-brand",
  hoverBrandToFullBrand: "side-item__icon--bg_brand-full"
};

const IconContainer = ({
  color = "none",
  colorHover = "none",
  children,
  width = 32,
  height = 32,
  borderRadius = 10,
  topRight,
  rounded,
  onClick,
  additionalClass = ""
}) => {
  return (
    <div
      className={`icon-container ${classColor[color] || classColor.brand} ${
        rounded ? "icon-container--rounded" : ""
      } ${additionalClass}`}
      style={{ width, height, borderRadius }}
      onClick={onClick ? onClick : null}
    >
      {!!topRight && (
        <div className="icon-container__top-right">{topRight}</div>
      )}
      {children}
    </div>
  );
};

IconContainer.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "white",
    "hoverNoToBrand",
    "hoverBrandToFullBrand"
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  rounded: PropTypes.bool,
  additionalClass: PropTypes.any
};

export default IconContainer;
