import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconStar = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 16.8789L18.832 21L17.0195 13.2305L23.0547 8.00391L15.1055 7.32812L12 0L8.89453 7.32812L0.945312 8.00391L6.98047 13.2305L5.16797 21L12 16.8789Z"
        fill={fill}
      />
    </svg>
  );
};

IconStar.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconStar;
