import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import * as ROUTES from "constants/routes";
import { FormattedMessage } from "react-intl-v3";
import { NavLink } from "react-router-dom";
import * as Icons from "components/_common/icons";
import { compose } from "redux";
import { connect } from "react-redux";

import "./AppBar.scss";
import { getIsAccessEditView } from "../../lib/helpers";
import { entitiesScheme } from "../AssetsCommon/formSchemeFull";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeUse } from "../HouseholdAssetsUse/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeSavings } from "../HouseholdAssetsSavings/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeInvestments } from "../HouseholdAssetsInvested/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeDebt } from "../HouseholdDebt/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeAccount } from "../HouseholdAccounts/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyIn } from "../HouseholdMoneyIn/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutPersonal } from "../MoneyOutPersonal/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutAssets } from "../MoneyOutAssets/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutOther } from "../MoneyOutOther/formScheme";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonCommon from "../_common/Button/ButtonCommon";

class ContainerAppBarMobile extends PureComponent {
  static propTypes = {
    mobileMenuHandler: PropTypes.any
  };

  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { mobileMenuHandler } = this.props;

    return (
      <div
        className={`app-bar ${mobileMenuHandler ? "app-bar--with-handle" : ""}`}
      >
        <div className="app-bar__wrapper">
          <div className="app-bar__links">
            <NavLink to={ROUTES.HOME} className="app-bar__link">
              <Icons.IconDashboard width={24} height={24} />
              <FormattedMessage id="APP.LINKS.HOME" defaultMessage="Home" />
            </NavLink>

            <NavLink to={ROUTES.HOUSEHOLD_MAIN} className="app-bar__link">
              <Icons.IconHousehold width={24} height={24} />
              <FormattedMessage
                id="APP.LINKS.HOUSEHOLD"
                defaultMessage="Household"
              />
            </NavLink>

            <NavLink to={ROUTES.PLANS} className="app-bar__link">
              <Icons.IconPlans width={24} height={24} />
              <FormattedMessage id="APP.LINKS.PLANS" defaultMessage="Plans" />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ ui: { mobileMenuHandler } }) {
  return {
    mobileMenuHandler
  };
}

export default compose(
  connect(
    mapStateToProps,
    {}
  )
)(ContainerAppBarMobile);
