import React, { Component } from "react";
import PropTypes from "prop-types";

import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "constants/styles";
import DateFnsUtils from "@date-io/date-fns";

import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import zhLocale from "date-fns/locale/zh-CN";

import "./FormControl.scss";
import { FormattedMessage, injectIntl } from "react-intl-v3";

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  zh: zhLocale
};

const birthdayProps = {
  disableFuture: true,
  openTo: "year",
  views: ["year", "month", "day"]
};

const showYearProps = {
  openTo: "year",
  views: ["year", "month", "day"],
  format: "MMMM do, yyyy"
};

class FormDatePicker extends Component {
  static propTypes = {
    notFullWidth: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelI18n: PropTypes.string.isRequired,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    bgSecondary: PropTypes.bool,
    isBirthday: PropTypes.bool,
    isShowYear: PropTypes.bool,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool
  };

  render() {
    const {
      classes,
      fieldName,
      label,
      labelI18n,
      value,
      handleChange,
      bgSecondary,
      isBirthday,
      isFuture,
      isPast,
      isShowYear,
      required,
      error,
      autoFocus,
      notFullWidth,
      disabled
      // ...rest
    } = this.props;

    const { intl } = this.props;

    const labelIntl = labelI18n
      ? intl.formatMessage({
          id: labelI18n,
          defaultMessage: label
        })
      : label;

    let additionalProps = isBirthday ? { ...birthdayProps } : {};

    if (isFuture) {
      additionalProps.disablePast = true;
    }

    if (isPast) {
      additionalProps.disableFuture = true;
    }

    additionalProps = isShowYear
      ? { ...additionalProps, ...showYearProps }
      : { ...additionalProps };

    return (
      <div className="form-control__wrapper">
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[window.__localeId__] || localeMap.en}
        >
          <DatePicker
            clearable
            required={required}
            error={error}
            disabled={disabled}
            className={
              bgSecondary ? classes.textFieldBgSecondary : classes.textField
            }
            label={labelIntl}
            name={fieldName}
            value={value}
            onChange={handleChange}
            fullWidth={!notFullWidth}
            style={{ marginBottom: 16 }}
            InputProps={{
              classes: {
                underline: classes.underline
              }
            }}
            autoFocus={autoFocus}
            clearLabel={
              <FormattedMessage
                id="FORM.CONTROL.DATE_PICKER.CLEAR"
                defaultMessage="Clear"
              />
            }
            cancelLabel={
              <FormattedMessage
                id="FORM.CONTROL.DATE_PICKER.CANCEL"
                defaultMessage="Cancel"
              />
            }
            okLabel={
              <FormattedMessage
                id="FORM.CONTROL.DATE_PICKER.OK"
                defaultMessage="Ok"
              />
            }
            {...additionalProps}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const styles = theme => ({
  MuiPickersToolbar: {
    toolbar: {}
  },
  textField: {
    borderBottomColor: colors.divider,

    "&:before": {
      borderBottomColor: colors.divider
    }
  },
  textFieldBgSecondary: {
    borderBottomColor: colors.divider,
    backgroundColor: colors.backgroundSecondary
  },
  underline: {
    "&:before": {
      borderBottomColor: colors.divider
    },
    "&:not(.important):not(.important):not(.important):not(.important):not(.important):hover:before": {
      borderBottomColor: colors.divider
    }
  }
});

export default withStyles(styles)(injectIntl(FormDatePicker));
