import React, { Component } from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";
import TooltipTitle from "../../_common/TooltipTitle";
import Slider from "@material-ui/lab/Slider";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { formatMoneyIntWithSign } from "../../../lib/helpers";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { WIZARD_APPROXIMATION } from "constants/wizardSetDefault";
import ButtonMUI from "@material-ui/core/Button/Button";

class WizardApproximation extends Component {
  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderContent = () => {
    const { classes, formState, handleInputChange } = this.props;

    const totalApproximation =
      (+formState.groceries || 0) +
      (+formState.diningOut || 0) +
      (+formState.dependentCare || 0) +
      (+formState.medicalAndHealth || 0) +
      (+formState.transportation || 0) +
      (+formState.insurance || 0) +
      (+formState.other || 0);

    return (
      <div className={classes.wizardBodyForm}>
        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.GROCERIES"
                  defaultMessage="How much do you spend monthly on groceries?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="groceries"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.groceries}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.GROCERIES_MIN}
                  max={
                    formState.groceries > WIZARD_APPROXIMATION.GROCERIES_MAX
                      ? formState.groceries
                      : WIZARD_APPROXIMATION.GROCERIES_MAX
                  }
                  step={1}
                  onChange={handleInputChange("approximation", "groceries")}
                  value={+formState.groceries}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.GROCERIES" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.DINING_OUT"
                  defaultMessage="How much do you spend monthly on dining out?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="diningOut"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.diningOut}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.DINING_MIN}
                  max={
                    formState.diningOut > WIZARD_APPROXIMATION.DINING_MAX
                      ? formState.diningOut
                      : WIZARD_APPROXIMATION.DINING_MAX
                  }
                  step={1}
                  onChange={handleInputChange("approximation", "diningOut")}
                  value={+formState.diningOut}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.DINING_OUT" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.DEPENDENT_CARE"
                  defaultMessage="How much do you spend monthly on dependent care?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="dependentCare"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.dependentCare}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.DEPENDENT_MIN}
                  max={
                    formState.groceries > WIZARD_APPROXIMATION.DEPENDENT_MAX
                      ? formState.dependentCare
                      : WIZARD_APPROXIMATION.DEPENDENT_MAX
                  }
                  step={1}
                  onChange={handleInputChange("approximation", "dependentCare")}
                  value={+formState.dependentCare}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.DEPENDENT_CARE" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.MEDICAL_HEALTH"
                  defaultMessage="How much do you spend monthly on medical and health care bills?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="medicalAndHealth"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.medicalAndHealth}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.MEDICAL_MIN}
                  max={
                    formState.medicalAndHealth >
                    WIZARD_APPROXIMATION.MEDICAL_MAX
                      ? formState.medicalAndHealth
                      : WIZARD_APPROXIMATION.MEDICAL_MAX
                  }
                  step={1}
                  onChange={handleInputChange(
                    "approximation",
                    "medicalAndHealth"
                  )}
                  value={+formState.medicalAndHealth}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.MEDICAL_HEALTH" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.TRANSPORTATION"
                  defaultMessage="How much do you spend monthly on transportation?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="transportation"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.transportation}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.TRANSPORTATION_MIN}
                  max={
                    formState.transportation >
                    WIZARD_APPROXIMATION.TRANSPORTATION_MAX
                      ? formState.transportation
                      : WIZARD_APPROXIMATION.TRANSPORTATION_MAX
                  }
                  step={1}
                  onChange={handleInputChange(
                    "approximation",
                    "transportation"
                  )}
                  value={+formState.transportation}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.TRANSPORTATION" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.INSURANCE"
                  defaultMessage="How much do you spend monthly on life insurance bills?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="insurance"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.insurance}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.INSURANCE_MIN}
                  max={
                    formState.insurance > WIZARD_APPROXIMATION.INSURANCE_MAX
                      ? formState.insurance
                      : WIZARD_APPROXIMATION.INSURANCE_MAX
                  }
                  step={1}
                  onChange={handleInputChange("approximation", "insurance")}
                  value={+formState.insurance}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.INSURANCE" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.APPROXIMATION.FORM.OTHER"
                  defaultMessage="How much do you spend monthly on other bills?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="other"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("approximation")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.other}
                />
                <Slider
                  min={WIZARD_APPROXIMATION.OTHER_MIN}
                  max={
                    formState.other > WIZARD_APPROXIMATION.OTHER_MAX
                      ? formState.other
                      : WIZARD_APPROXIMATION.OTHER_MAX
                  }
                  step={1}
                  onChange={handleInputChange("approximation", "other")}
                  value={+formState.other}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.APPROXIMATION.FORM.GROCERIES" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <FormattedMessage
                id="WIZARD.APPROXIMATION.FORM.TOTAL"
                defaultMessage="Total monthly expenses"
              />
              <span className={classes.wizardBodySummaryCount}>
                {formatMoneyIntWithSign(totalApproximation)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      handleChangeStep,
      prevStep,
      nextStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.APPROXIMATION.LABEL"
                defaultMessage="Money making and spending"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={4} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.APPROXIMATION.HEADING2"
              defaultMessage="We need to understand an approximation of how you are making money and what you are spending it on"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardApproximation.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardApproximation;
