import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { FormattedMessage } from "react-intl-v3";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import MobileStepper from "@material-ui/core/MobileStepper";

const styles = {
  // dialog: {
  //   // maxWidth: "436px"
  // },
  container: {
    padding: "40px 20px 30px 20px"
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "55px"
  },
  contentData: {
    marginLeft: "30px",
    marginRight: "50px",
    maxWidth: "205px"
  },

  label: {
    marginBottom: "16px",
    color: "#8B90A0"
  },
  heading: {
    marginBottom: "16px",
    color: "#232735"
  },
  text: {
    marginBottom: "16px",
    color: "#8B90A0"
  },
  button: {
    // marginTop: "30px"
  }
};

const tipsStep = [
  {
    label: "Household",
    labelI18n: "quiz.tips.1.label",
    heading: "Set up your household",
    headingI18n: "quiz.tips.1.heading",
    text: "Information used to identify individuals in your household",
    textI18n: "quiz.tips.1.text",
    imgPath: "/assets/img/quiz/tip1.png"
  },
  {
    label: "Assets owned",
    labelI18n: "quiz.tips.2.label",
    heading: "Add the physical and financial assets you own",
    headingI18n: "quiz.tips.2.heading",
    text: "Something you own that can provide financial benefit",
    textI18n: "quiz.tips.2.text",
    imgPath: "/assets/img/quiz/tip1.png"
  },
  {
    label: "Money owned",
    labelI18n: "quiz.tips.3.label",
    heading: "Figure out what you owe for your assets",
    headingI18n: "quiz.tips.3.heading",
    text: "Your obligations to pay",
    textI18n: "quiz.tips.3.text",
    imgPath: "/assets/img/quiz/tip1.png"
  },
  {
    label: "Accounts",
    labelI18n: "quiz.tips.4.label",
    heading:
      "List the accounts that hold your income and pay for your expenses",
    headingI18n: "quiz.tips.4.heading",
    text: "System that hold personal or financial records",
    textI18n: "quiz.tips.4.text",
    imgPath: "/assets/img/quiz/tip1.png"
  },
  {
    label: "Money in",
    labelI18n: "quiz.tips.5.label",
    heading: "Define your sources of income",
    headingI18n: "quiz.tips.5.heading",
    text: "Money received though any means",
    textI18n: "quiz.tips.5.text",
    imgPath: "/assets/img/quiz/tip1.png"
  },
  {
    label: "Money out",
    labelI18n: "quiz.tips.6.label",
    heading: "Track your spending",
    headingI18n: "quiz.tips.6.heading",
    text: "Money expensed for any reason",
    textI18n: "quiz.tips.6.text",
    imgPath: "/assets/img/quiz/tip1.png"
  }
];

class DialogWelcome extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    name: PropTypes.string
  };

  state = {
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleCloseAndReset = () => {
    this.setState({
      activeStep: 0
    });

    this.props.onClose();
  };

  _renderFormattedMessage = (id, defaultMessage) => {
    return <FormattedMessage id={id} defaultMessage={defaultMessage} />;
  };

  render() {
    const { activeStep } = this.state;
    const maxSteps = tipsStep.length;

    const { classes, onClose, open } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.container}>
          <div className={classes.content}>
            <div>
              <img
                className={classes.img}
                src={tipsStep[activeStep].imgPath}
                alt={tipsStep[activeStep].label}
              />
            </div>

            <div className={classes.contentData}>
              <Typography className={classes.label}>
                <FormattedMessage
                  id={tipsStep[activeStep].labelI18n}
                  defaultMessage={tipsStep[activeStep].label}
                />
              </Typography>
              <Typography className={classes.heading}>
                <FormattedMessage
                  id={tipsStep[activeStep].headingI18n}
                  defaultMessage={tipsStep[activeStep].heading}
                />
              </Typography>
              <Typography className={classes.text}>
                <FormattedMessage
                  id={tipsStep[activeStep].textI18n}
                  defaultMessage={tipsStep[activeStep].text}
                />
              </Typography>
            </div>
          </div>

          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={
                  activeStep === maxSteps - 1
                    ? this.handleCloseAndReset
                    : this.handleNext
                }
              >
                <FormattedMessage id="QUIZ.BUTTON.CONTINUE" />
              </Button>
            }
            backButton={
              <Button size="small" onClick={onClose} variant="outlined">
                <FormattedMessage id="QUIZ.BUTTON.SKIP" />
              </Button>
            }
          />
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWelcome);
