export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";
export const RESET_ERROR = "_RESET_ERROR";

export const AUTH_USER = "AUTH_USER";
export const AUTH_FETCH_TOKEN = "AUTH_FETCH_TOKEN";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOG_OUT = "LOG_OUT";

export const REQUIRED_PASSWORD_STRENGTH = 33;
export const MIN_CALC_AGE = 18;
export const MAX_AGE = 100;
export const DEFAULT_EXPECTED_RETIREMENT_AGE = 67;

export const SET_UI = "SET_UI";
export const SET_BOOT_DATA = "SET_BOOT_DATA";

export const SECRET = "Secret Passphrase";
