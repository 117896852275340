import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import AppBarLink from "./AppBarLink";
import * as ROUTES from "../../constants/routes";
import { FormattedMessage } from "react-intl-v3";
import { NavLink } from "react-router-dom";
import * as Icons from "../_common/icons";

const Links = props => {
  const {
    classes,
    isAdvisor,
    // menuItemHousehold,
    // menuItemPlans,
    currentPath
  } = props;

  const assetsOwnMenu = (
    <ul className="app-bar-submenu app-bar-submenu--2">
      <NavLink to={ROUTES.ASSETS_OWNED_USE}>
        <li className="app-bar-submenu__item">
          <Icons.IconCarHome color="ui" />

          <FormattedMessage
            id="DRAWER.HOUSEHOLD.ASSETS.USE"
            defaultMessage="Homes & Vehicles"
          />
        </li>
      </NavLink>

      <NavLink to={ROUTES.ASSETS_OWNED_SAVING}>
        <li className="app-bar-submenu__item">
          <Icons.IconSunLounger color="ui" />
          <FormattedMessage
            id="DRAWER.HOUSEHOLD.ASSETS.SAVING"
            defaultMessage="Savings & Retirement"
          />
        </li>
      </NavLink>

      <NavLink to={ROUTES.ASSETS_OWNED_INVESTED}>
        <li className="app-bar-submenu__item">
          <Icons.IconInvestment color="ui" />

          <FormattedMessage
            id="DRAWER.HOUSEHOLD.ASSETS.INVESTED"
            defaultMessage="Investments"
          />
        </li>
      </NavLink>
    </ul>
  );
  const assetsPayments = (
    <ul className="app-bar-submenu app-bar-submenu--2">
      <NavLink to={ROUTES.MONEY_OUT_PERSONAL}>
        <li className="app-bar-submenu__item">
          <Icons.IconCart color="ui" />
          <FormattedMessage
            id="DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL"
            defaultMessage="Living Expenses"
          />
        </li>
      </NavLink>

      <NavLink to={ROUTES.MONEY_OUT_ASSETS}>
        <li className="app-bar-submenu__item">
          <Icons.IconMoneyOwned color="ui" />

          <FormattedMessage
            id="DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS"
            defaultMessage="Property Expenses"
          />
        </li>
      </NavLink>
      <NavLink to={ROUTES.MONEY_OUT_OTHER}>
        <li className="app-bar-submenu__item">
          <Icons.IconDollar color="ui" />

          <FormattedMessage
            id="DRAWER.HOUSEHOLD.MONEY_OUT.OTHER"
            defaultMessage="Rent & Lease"
          />
        </li>
      </NavLink>
    </ul>
  );
  const assetsMenu = (
    <ul className="app-bar-submenu">
      <NavLink to={ROUTES.HOUSEHOLD_MEMBERS}>
        <li className="app-bar-submenu__item">
          <Icons.IconHousehold color="ui" />

          <FormattedMessage
            id="DRAWER.HOUSEHOLD.MEMBERS"
            defaultMessage="Family"
          />
        </li>
      </NavLink>

      <li className="app-bar-submenu__item app-bar-submenu__item--sub">
        <Icons.IconMoneyOwned color="ui" />

        <FormattedMessage
          id="DRAWER.HOUSEHOLD.ASSETS"
          defaultMessage="Assets Owned"
        />
        <Icons.IconArrowRight color="textTetrieary" />

        {assetsOwnMenu}
      </li>

      <NavLink to={ROUTES.DEBT}>
        <li className="app-bar-submenu__item">
          <Icons.IconDeferredComp color="ui" />

          <FormattedMessage id="DRAWER.HOUSEHOLD.DEBT" defaultMessage="Debt" />
        </li>
      </NavLink>

      <NavLink to={ROUTES.ACCOUNTS}>
        <li className="app-bar-submenu__item">
          <Icons.IconAccount color="ui" />
          <FormattedMessage
            id="DRAWER.HOUSEHOLD.ACCOUNTS"
            defaultMessage="Bank Accounts"
          />
        </li>
      </NavLink>

      <NavLink to={ROUTES.MONEY_IN}>
        <li className="app-bar-submenu__item">
          <Icons.IconSalaries color="ui" />
          <FormattedMessage
            id="DRAWER.HOUSEHOLD.MONEY_IN"
            defaultMessage="Income"
          />
        </li>
      </NavLink>

      <li className="app-bar-submenu__item app-bar-submenu__item--sub app-bar-submenu__item--sub-bottom">
        <Icons.IconMoneyOut color="ui" />

        <FormattedMessage
          id="DRAWER.HOUSEHOLD.MONEY_OUT"
          defaultMessage="Payments"
        />

        <Icons.IconArrowRight color="textTetrieary" />

        {assetsPayments}
      </li>
    </ul>
  );

  return (
    <div
      className={`${classes.navLinks} ${isAdvisor ? "navLinksAdvisor" : ""}`}
    >
      <AppBarLink
        to={ROUTES.HOME}
        i18nStringId="APP.LINKS.HOME"
        defaultMessage="Home"
      />

      <div
        tabIndex="0"
        className={`app-bar-link ${
          currentPath === ROUTES.PLANS ? "active" : ""
        }`}
      >
        <span className="app-bar-link__title">
          <FormattedMessage id="DRAWER.PLANS" defaultMessage="Plans" />
        </span>

        <span className="mark" />
        <ul className="app-bar-submenu">
          <NavLink to={ROUTES.INTERESTS}>
            <li className="app-bar-submenu__item">
              <Icons.IconInterests color="ui" />

              <FormattedMessage
                id="DRAWER.PLANS.INTERESTS"
                defaultMessage="Interests"
              />
            </li>
          </NavLink>

          <NavLink to={ROUTES.GOALS}>
            <li className="app-bar-submenu__item">
              <Icons.IconGoal color="ui" />
              <FormattedMessage
                id="DRAWER.PLANS.GOALS"
                defaultMessage="Goals"
              />
            </li>
          </NavLink>

          <NavLink to={ROUTES.EVENTS}>
            <li className="app-bar-submenu__item">
              <Icons.IconEvent color="ui" />
              <FormattedMessage
                id="DRAWER.PLANS.EVENTS"
                defaultMessage="Events"
              />
            </li>
          </NavLink>
        </ul>
      </div>

      <div
        tabIndex="0"
        className={`app-bar-link ${
          currentPath === ROUTES.HOUSEHOLD_MAIN ? "active" : ""
        }`}
      >
        <span className="app-bar-link__title">
          <FormattedMessage
            id="APP.LINKS.HOUSEHOLD"
            defaultMessage="Household"
          />
        </span>

        <span className="mark" />
        {assetsMenu}
      </div>

      {isAdvisor && (
        <div
          tabIndex="0"
          className={`app-bar-link ${
            currentPath === ROUTES.MY_ADVISOR ? "active" : ""
          }`}
        >
          <span className="app-bar-link__title">
            <FormattedMessage
              id="APP.LINKS.MY_ADVISOR"
              defaultMessage="Advisor"
            />
          </span>

          <span className="mark" />
          <ul className="app-bar-submenu">
            <NavLink to={ROUTES.ADVISOR_MANAGE_CLIENTS}>
              <li className="app-bar-submenu__item">
                <Icons.IconFormatList color="ui" />

                <FormattedMessage
                  id="DRAWER.ADVISOR.MANAGE_CLIENTS"
                  defaultMessage="Clients and Invites"
                />
              </li>
            </NavLink>
          </ul>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(Links);
