import { colors, BOX_SHADOW_3, styleMix } from "../../constants/styles";

export const commonWizardStyles = {
  dialogWizardPaper: {
    height: 890,
    maxHeight: "95vh"
  },
  dialogWizardPaperWelcome: {
    maxWidth: 852
  },
  wizardHeaderWelcome: {
    position: "relative",
    padding: 48,
    background: "linear-gradient(180deg, #363658 0%, #564DD0 100%)",
    borderRadius: "4px 4px 0px 0px",
    textAlign: "center"
  },
  wizardHeaderWelcomeHeader: {
    ...styleMix.title,
    color: colors.white
  },
  wizardHeaderWelcomeHeader2: {
    marginTop: 8,
    fontSize: 18,
    lineHeight: "30px",
    color: colors.white
  },
  wizardBodyWelcome: {
    paddingTop: 12,
    paddingBottom: 48,
    margin: "0 auto",
    maxWidth: 620,
    textAlign: "center",
    "& > p": {
      marginTop: 0,
      marginBottom: 24,
      fontSize: 16,
      lineHeight: "24px",
      color: colors.textSecondary
    }
  },
  wizardBodyWelcomeTitle: {
    ...styleMix.title,
    margin: 0,
    textAlign: "center",
    color: colors.brand
  },

  wizardBodyWelcomeTitle2: {
    ...styleMix.h2,
    margin: 0,
    marginTop: 16,
    textAlign: "center"
  },

  wizardButtonWelcome: {
    // marginBottom: 48
  },
  wizardButtonBack: {
    fontWeight: 500,

    "&  svg": {
      position: "relative",
      left: -10
    }
  },
  wizardButtonNext: {
    minWidth: 200,
    fontWeight: 500,

    "&  svg": {
      position: "relative",
      right: -10
    }
  },
  wizardButtonSummaryFinish: {
    marginLeft: "auto"
  },
  wizardButtonSave: {
    minHeight: 40,
    marginLeft: "auto",

    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "0.5px",

    color: colors.brand,
    border: `1px solid #6A68FB`,
    borderRadius: 4
  },
  wizardButtonFinish: {
    minHeight: 40,
    marginLeft: "auto",
    marginRight: 24,

    fontSize: 14,
    fontWeight: 500,
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "0.5px",

    color: colors.brand,
    border: `1px solid #6A68FB`,
    borderRadius: 4
  },
  wizardHeaderTopClose: {
    position: "absolute",
    right: 24,
    top: 12,

    cursor: "pointer"
  },
  wizardHeader: {
    position: "relative",
    padding: 0,
    borderBottom: `1px solid ${colors.divider}`,
    backgroundPosition: "right bottom, center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "250px 150px, 100%",
    borderRadius: "4px 4px 0px 0px",
    textAlign: "left"
  },
  wizardHeaderTop: {
    paddingLeft: 24,
    paddingRigh: 24,
    height: 56,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  wizardHeaderTopBackIcon: {
    height: 24,
    marginLeft: 8,
    marginRight: 4,
    marginTop: -24
  },

  wizardHeaderTopLabel: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    color: colors.textPrimary
  },
  wizardHeaderStepState: {
    width: "100%",
    height: 2,
    background: colors.ui,
    opacity: 0.35
  },
  wizardHeaderStepStateCommon: {
    marginTop: -3,
    background: colors.ui,
    height: 4
  },
  wizardHeaderStepState1: { width: `${100 / 7}%` },
  wizardHeaderStepState2: { width: `${(100 / 7) * 2}%` },
  wizardHeaderStepState3: { width: `${(100 / 7) * 3}%` },
  wizardHeaderStepState4: { width: `${(100 / 7) * 4}%` },
  wizardHeaderStepState5: { width: `${(100 / 7) * 5}%` },
  wizardHeaderStepState6: { width: `${(100 / 7) * 6}%` },
  wizardHeaderStepState7: { width: "100%" },
  wizardHeaderFinish2: {
    ...styleMix.title,
    background:
      "linear-gradient(180deg, #F0F3FD 0%, rgba(255, 255, 255, 0) 100%)",
    // padding: "45px 300px 45px 48px",
    padding: "32px 48px",
    textAlign: "left"
  },
  goalsFormIcon: {
    position: "absolute",
    right: 48,
    top: 30
  },
  wizardHeaderGoal2: {
    paddingRight: 130
  },

  wizardHeader2: {
    ...styleMix.title,
    background:
      "linear-gradient(180deg, #F0F3FD 0%, rgba(255, 255, 255, 0) 100%)",
    // padding: "45px 300px 45px 48px",
    padding: "32px 48px",
    paddingBottom: 8,
    textAlign: "left"
  },
  wizardHeader3: {
    marginTop: -10,
    padding: "0 300px 16px 48px",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "30px",
    color: colors.textSecondary,
    textAlign: "left"
  },
  wizardFinishHeader: {
    textAlign: "center",
    paddingRight: 48
  },
  wizardBody: { padding: 0 },
  wizardBodyGoals: { position: "relative" },
  wizardBodyContentGoal: { marginTop: 32 },
  wizardBodyContent: {
    display: "flex",
    padding: "0 48px 32px 48px"
  },
  DashbGoalForm: {
    ...styleMix.textSubtitle,
    paddingRight: 120,
    lineHeight: "40px",
    // display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& > *": {
      display: "inline-block"
    }
  },
  DashbGoalFormControlContainerDesc: {
    "&:not(.important)": {
      maxWidth: "none",
      flex: 1
    }
  },
  DashbGoalFormControlContainer: {
    width: "auto",
    maxWidth: 150,
    minWidth: 100,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0
  },
  DashbGoalFormControl: {
    width: "100%",
    marginTop: 3,
    marginBottom: 0,

    "& input": {
      fontSize: 18,
      lineHeight: 1
    }
  },
  GoalFormTooltip: {
    ...styleMix.uiText,
    marginTop: 32,
    margin: "32px 48px",
    padding: "16px 24px",
    background: colors.white,
    border: `1px solid rgba(106, 104, 251, 0.5)`,
    borderRadius: 4
  },

  wizardBodyForm: { width: "100%" },
  wizardFormContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",

    "&:not(:first-child)": {
      borderTop: `1px solid ${colors.divider}`
    }
  },
  wizardFormLeft: { flexGrow: 1, padding: "24px 0 0" },
  wizardFormRight: {},
  wizardBodySummary: {
    color: colors.brand,
    height: "auto",
    width: 220,
    padding: 24,
    // marginTop: 24,
    // background: colors.backgroundSecondary,
    borderRadius: 4,
    textAlign: "left",
    textTransform: "uppercase",
    fontSize: 11,
    lineHeight: "16px",
    fontWeight: 500
  },
  wizardBodySummaryItem: {
    "&:not(:first-child)": { marginTop: 24 }
  },
  wizardBodySummaryCount: {
    display: "block",
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 700
  },
  wizardFormItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    marginBottom: 24
  },
  wizardFormLabel: {
    marginTop: 4,
    width: "55%",
    fontSize: 16,
    lineHeight: "24px",
    color: colors.textPrimary,
    textAlign: "left"
  },
  sliderRange: { backgroundColor: colors.brand },
  sliderTrackBefore: { backgroundColor: colors.brand },
  sliderTrackAfter: { backgroundColor: colors.inputUnderline },
  wizardFormControl: { width: "15%", textAlign: "left" },
  wizardFormControlLarge: { width: "25%", textAlign: "left" },
  inputWithRange: {
    marginBottom: 5,
    "& > div": {
      "&:not(.important):not(.important):not(.important)::before": {
        opacity: 0
      },
      "&:not(.important):not(.important):not(.important)::after": {
        opacity: 0
      }
    }
  },
  inputSelect: { marginBottom: 50 },
  wizardTotalTitle: {
    marginBottom: 20,
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 700,
    color: colors.textPrimary,
    // borderBottom: `1px solid ${colors.divider}`,
    textAlign: "left"
  },
  wizardTotalTitleDivider: { marginTop: 45 },
  wizardTotalItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 8
  },

  wizardTotalItemDivider: { marginBottom: 25 },
  wizardTotalItemLabel: {
    width: "60%",
    fontSize: 14,
    lineHeight: "21px",
    color: colors.textSecondary,
    textAlign: "left"
  },
  wizardTotalItemValue: {
    width: "40%",
    fontSize: 14,
    lineHeight: "21px",
    fontWeight: 500,
    color: colors.textPrimary,
    textAlign: "left"
  },
  wizardFooterGoals: {
    "&:not(.important)": {
      height: 65
    }
  },
  wizardFooter: {
    paddingLeft: 48,
    paddingRight: 48,
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 65,
    borderTop: `1px solid ${colors.divider}`
  },
  wizardNeeds: {
    display: "flex",
    flexWrap: "wrap"
  },
  wizardNeedsItem: {
    ...styleMix.ResetButton,
    ...styleMix.boxShadow1,
    width: 407,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 24px",
    marginRight: 16,
    marginBottom: 16,

    background: colors.white,
    // border: `1px solid rgba(106, 104, 251, 0.5)`,
    boxSizing: "border-box",
    borderRadius: 4,

    borderColor: colors.white,
    transition: "border-color 0.2s ease-out",

    "&:focus": {
      outline: "none"
      // border: `2px solid ${colors.green}`,
      // padding: "16px 22px"
    },
    "&:hover": {
      border: `2px solid ${colors.green}`,
      padding: "16px 22px"
    }
  },
  wizardNeedsItemGoal: {
    "&:focus": {
      outline: "none"
      // border: `2px solid ${colors.brand}`
    },
    "&:hover": {
      border: `2px solid ${colors.brand}`
    }
  },
  wizardNeedsItemChecked: {
    position: "relative",

    // border: `2px solid ${colors.brand}`,
    // background:
    // "linear-gradient(0deg, rgba(106, 104, 251, 0.1), rgba(106, 104, 251, 0.1)), #FFFFFF",
    background:
      "linear-gradient(0deg, rgba(76, 196, 97, 0.1), rgba(76, 196, 97, 0.1)), #FFFFFF",
    border: `2px solid ${colors.green}`,
    padding: "16px 22px"
  },
  wizardNeedsItemText: {
    ...styleMix.uiText,
    marginRight: 32,
    fontWeight: 700,
    textAlign: "left"
  },
  wizardNeedsItemIconAccepted: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  wizardNeedsItemIconChecked: {
    opacity: 0.2
  }
};

export const styles = theme => ({
  ...commonWizardStyles,
  toleranceTooltip: {
    width: 20,
    height: 20,
    marginLeft: 16,
    marginTop: 8,
    // marginBottom: 16,
    paddingBottom: 1,
    borderRadius: "50%",
    backgroundColor: colors.textTetrieary,
    fontSize: 12,
    lineHeight: "16px",
    color: colors.white,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    [theme.breakpoints.down("md")]: { marginTop: 1 }
  },
  container: {
    // padding: "",
    textAlign: "center",
    // background: colors.white,
    boxShadow: BOX_SHADOW_3,
    borderRadius: 4
  },

  welcomeImage: {},

  WizardFinishMainChartContainer: {
    position: "relative"
  },

  WizardFinishMainChartDataAnnual: {
    position: "absolute",
    top: 0,
    right: 0,
    left: "50%",
    marginLeft: 20,
    textAlign: "left",
    "& > div": {
      marginBottom: 10
    }
  },
  WizardFinishMainChartData: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  WizardFinishFamilyLabelImg: {
    position: "relative"
  },
  WizardFinishChildrenImg: {
    marginLeft: -12
  },
  WizardFinishFamilyChildrenImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    marginLeft: 8
  },

  // FINISH
  WizardFinishFamilyLabel: {
    ...styleMix.labelCard,
    textAlign: "center",
    display: "flex",
    justifyContent: "center"
  },
  WizardFinishFamilyTotal: {
    ...styleMix.title,
    textAlign: "center"
  },
  wizardFinishHeader2: {
    paddingTop: 16
  },
  WizardFinishContainer: {
    width: "100%"
  },
  WizardFinishSubContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },

  WizardFinishGraphContainer: {
    width: 285
  },
  WizardFinishGraphContainer2: {
    width: 195
  },
  WizardFinishGraph: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    height: 220,
    borderBottom: `1px solid ${colors.green}`,
    marginLeft: -4,
    paddingLeft: 4,
    paddingRight: 4
  },
  WizardFinishGraphBottom: {
    marginTop: 16,
    height: 140
  },
  WizardFinishGraphDivider: {
    borderBottom: `1px solid ${colors.divider}`
  },
  WizardFinishGraphYellow: {
    borderBottom: `1px solid ${colors.yellow}`
  },
  WizardFinishGraphBrand: {
    borderBottom: `1px solid ${colors.brand}`
  },
  WizardFinishGraphItem0: {
    height: 220
  },
  WizardFinishGraphItem1: {
    height: 160
  },
  WizardFinishGraphItem2: {
    height: 110
  },

  WizardFinishGraphItemBottom0: {
    height: 140
  },

  WizardFinishGraphItemBottom1: {
    height: 90
  },
  WizardFinishGraphItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",

    marginLeft: 4,
    width: 90,
    minHeight: 100,
    background:
      " linear-gradient(180deg, rgba(76, 196, 97, 0) 0%, rgba(76, 196, 97, 0.3) 100%)"
  },
  WizardFinishGraphItemYellow: {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 157, 10, 0.3) 100%)"
  },
  WizardFinishGraphItemNoBg: {
    width: "auto",
    flex: 1,
    background: "none"
  },
  WizardFinishGraphItemBrand: {
    background:
      "linear-gradient(180deg, rgba(104, 133, 238, 0) 0%, rgba(104, 133, 238, 0.3) 100%)"
  },
  WizardFinishGraphItemTitle: {
    ...styleMix.labelCard,
    marginTop: 8
  },
  WizardFinishGraphItemAmount: {
    ...styleMix.uiTextBold,
    marginBottom: 8
  },
  WizardFinishGraphTotalPriorities: {
    paddingTop: 8,
    borderTop: `1px solid ${colors.divider}`
  },
  WizardFinishGraphTotal: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    paddingLeft: 4,
    paddingRight: 8
  },
  WizardFinishNeeds: {
    display: "flex",
    justifyContent: "flex-start"
  },

  WizardFinishCheck: {
    marginRight: 10
  },
  WizardFinishGraphTotalLabel: {
    ...styleMix.headCard
  },
  WizardFinishGraphTotalAmount: {
    ...styleMix.bodyBold
  },
  subheading: { marginBottom: "16px", color: "#232735" },
  desc: { marginBottom: "16px", color: "#232735" },
  button: { marginTop: "30px" }
});
