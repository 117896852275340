import { optionsTaxCategoryValues } from "../AssetsCommon/_formScheme";
import {
  entitiesScheme,
  globalTypes,
  entitiesFull
} from "../AssetsCommon/formSchemeFull";

export const optionHighlightsValues = {
  "10": "10",
  "20": "20",
  "50": "50",
  all: "to100",
  to100: "to100"
};
export const optionHighlights = [
  // {
  //   name: "Highlights",
  //   nameI18n: "DASHBOARD.MAIN.SELECT.HIGHLIGHTS",
  //   value: "highlights"
  // },
  {
    name: "10 Years",
    nameI18n: "DASHBOARD.MAIN.SELECT.10_YEARS",
    value: "10"
  },
  {
    name: "20 Years",
    nameI18n: "DASHBOARD.MAIN.SELECT.20_YEARS",
    value: "20"
  },
  {
    name: "50 Years",
    nameI18n: "DASHBOARD.MAIN.SELECT.50_YEARS",
    value: "50"
  },
  {
    name: "To 100",
    nameI18n: "DASHBOARD.MAIN.SELECT.TILL_100",
    value: "to100"
  }
];

export const optionHighlightsArr = optionHighlights.map(el => el.value);

export const valuesStatTypes = {
  SUMMARY: "summary",
  DETAILS: "details",
  OPERATIONS: "operations",
  EVENTS: "events"
};

export const optionStatTypes = [
  {
    name: "Summary",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.SUMMARY",
    value: valuesStatTypes.SUMMARY
  },
  {
    name: "Details",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.DETAILS",
    value: valuesStatTypes.DETAILS
  },
  {
    name: "Operations",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.OPERATIONS",
    value: valuesStatTypes.OPERATIONS
  },
  {
    name: "Events",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.EVENTS",
    value: valuesStatTypes.EVENTS
  }
];
export const optionStatTypesUser = [
  {
    name: "Summary",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.SUMMARY",
    value: valuesStatTypes.SUMMARY
  },
  {
    name: "Details",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.DETAILS",
    value: valuesStatTypes.DETAILS
  },
  {
    name: "Operations",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.OPERATIONS",
    value: valuesStatTypes.OPERATIONS
  },
  {
    name: "Events",
    nameI18n: "DASHBOARD.MAIN.STAT.TYPES.EVENTS",
    value: valuesStatTypes.EVENTS
  }
];

export const initialYearAssetsState = {
  use: {
    home: {},
    vehicle: {},
    other: {},
    general: {}
  },

  //Retirement & Savings->Other->value(c)=Retirement & Savings->Other->value(c-1) where tax category=no tax
  // Retirement & Savings->Other->value(c)=Retirement & Savings->Other->value(c-1) where tax category=capital gains
  // Retirement & Savings->IRA(c)=Retirement & Savings->IRA(c-1)
  // Retirement & Savings->Qual annuity(c)=Retirement & Savings->Qual annuity(c-1)
  // Retirement & Savings->401K(c)=Retirement & Savings->401K(c-1)
  // Retirement & Savings->Deferred Comp(c)=Retirement & Savings->Deferred Comp(c-1)
  // Retirement & Savings->529 college fund(c)=Retirement & Savings->529 college fund(c-1)
  savings: {
    "401k": {},
    IRA: {},
    annuityQualified: {},
    annuityUnqualified: {},
    deferredComp: {},
    "529college": {},
    lifeInsurance: {},
    otherNoTax: {},
    otherCapital: {},
    otherOrdinary: {},
    otherUnknown: {},
    general: {}
  },

  investments: {
    RSU: {},
    ESPP: {},
    mutualFunds: {},
    stocks: {},
    treasuries: {},
    businessInterest: {},
    other: {},
    general: {}
  },

  debt: {
    home: {},
    vehicle: {},
    creditCard: {},
    student: {},
    medical: {},
    other: {},
    general: {}
  },

  accounts: {
    checking: {},
    savings: {},
    moneyMarket: {},
    cd: {}
  },

  moneyIn: {
    salary: {},
    rent: {},
    other: {},
    general: {}
  },

  moneyOut: {
    family: {},
    asset: {},
    rentLease: {}
  },
  events: {
    moneyOut: {},
    moneyIn: {}
  }
};

export const optionsOtherTypeCalc = {
  [optionsTaxCategoryValues.ORDINARY_INCOME]: "otherOrdinary",
  [optionsTaxCategoryValues.CAPITAL_GAIN]: "otherCapital",
  [optionsTaxCategoryValues.NO_TAX]: "otherNoTax",
  [optionsTaxCategoryValues.UNKNOWN]: "otherUnknown",
  "": "otherUnknown"
};

const deductArray = {
  balance: [
    ["Assets", "cash"],
    ["Assets", "all"],
    ["AssetsDetail", "cash", "accounts"],
    ["AssetsDetail", "cash", "all"]
  ],
  LICashValue: [
    ["Assets", "cash"],
    ["Assets", "all"],
    ["AssetsDetail", "cash", "lifeInsurance"],
    ["AssetsDetail", "cash", "all"]
  ],
  LIRetirementCashValue: [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "lifeInsurance"],
    ["AssetsDetail", "retirement", "all"]
  ],
  mutualFunds: [
    ["Assets", "investments"],
    ["Assets", "all"],
    ["AssetsDetail", "investments", "mutualFunds"],
    ["AssetsDetail", "investments", "all"]
  ],
  treasuries: [
    ["Assets", "investments"],
    ["Assets", "all"],
    ["AssetsDetail", "investments", "treasuries"],
    ["AssetsDetail", "investments", "all"]
  ],
  stocks: [
    ["Assets", "investments"],
    ["Assets", "all"],
    ["AssetsDetail", "investments", "stocks"],
    ["AssetsDetail", "investments", "all"]
  ],
  IRA: [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "IRA"],
    ["AssetsDetail", "retirement", "all"]
  ],
  deferredComp: [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "deferredComp"],
    ["AssetsDetail", "retirement", "all"]
  ],
  "529college": [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "529college"],
    ["AssetsDetail", "retirement", "all"]
  ],
  "401K": [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "401K"],
    ["AssetsDetail", "retirement", "all"]
  ],
  savingsRetirementOther: [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "other"],
    ["AssetsDetail", "retirement", "all"]
  ],
  annuity: [
    ["Assets", "retirement"],
    ["Assets", "all"],
    ["AssetsDetail", "retirement", "annuity"],
    ["AssetsDetail", "retirement", "all"]
  ],
  useAsset: [
    ["Assets", "use"],
    ["Assets", "all"]
    // ["AssetsDetail", "cash", "accounts"],
    // ["AssetsDetail", "cash", "all"]
  ],
  debt: [["Liabilities", "all"], ["Liabilities", "mortgage"]]
};

export const overdraftPriorityScheme = {
  [`${globalTypes.globalTypeAccounts}-${entitiesFull.entitiesAccounts.ENTITY_CHECKING}`]: {
    path: ["accounts", "checking"],
    key: "balance",
    deduct: deductArray.balance
  },
  [`${globalTypes.globalTypeAccounts}-${entitiesFull.entitiesAccounts.ENTITY_SAVINGS}`]: {
    path: ["accounts", "savings"],
    key: "balance",
    deduct: deductArray.balance,
    condition: null
  },
  [`${globalTypes.globalTypeAccounts}-${entitiesFull.entitiesAccounts.ENTITY_MONEY_MARKET}`]: {
    path: ["accounts", "moneyMarket"],
    key: "balance",
    deduct: deductArray.balance,
    condition: null
  },
  [`${globalTypes.globalTypeAccounts}-${entitiesFull.entitiesAccounts.ENTITY_CD}`]: {
    path: ["accounts", "cd"],
    key: "balance",
    deduct: deductArray.balance
  },
  ///
  [`${globalTypes.globalTypeSavings}-otherNoTax`]: {
    condition: "taxNoTax",
    path: ["savings", "otherNoTax"],
    key: "value",
    deduct: deductArray.savingsRetirementOther
  },
  [`${globalTypes.globalTypeSavings}-otherCapital`]: {
    path: ["savings", "otherCapital"],
    key: "value",
    deduct: deductArray.savingsRetirementOther
  },
  [`${globalTypes.globalTypeSavings}-otherOrdinary`]: {
    path: ["savings", "otherOrdinary"],
    key: "value",
    deduct: deductArray.savingsRetirementOther
  },
  [`${globalTypes.globalTypeSavings}-${entitiesFull.entitiesSavings.ENTITY_DEFERRED_COMPENSATION}`]: {
    path: ["savings", "deferredComp"],
    key: "value",
    deduct: deductArray.deferredComp
  },
  [`${globalTypes.globalTypeSavings}-${entitiesFull.entitiesSavings.ENTITY_IRA}`]: {
    path: ["savings", "IRA"],
    key: "value",
    deduct: deductArray.IRA
  },
  [`${globalTypes.globalTypeSavings}-${entitiesFull.entitiesSavings.ENTITY_401K}`]: {
    path: ["savings", "401k"],
    key: "value",
    deduct: deductArray["401K"]
  },
  [`${globalTypes.globalTypeSavings}-${entitiesFull.entitiesSavings.ENTITY_529_COLLEGE_SAVINGS}`]: {
    path: ["savings", "529college"],
    key: "value",
    deduct: deductArray["529college"]
  },
  [`${globalTypes.globalTypeSavings}-${entitiesFull.entitiesSavings.ENTITY_LIFE_INSURANCE}`]: {
    path: ["savings", "lifeInsurance"],
    key: "cashValue",
    key2: "cashIulValue",
    conditionCustom: "isLifeInsurance", // todo to common scheme
    deduct: deductArray.LIRetirementCashValue
  },
  [`${globalTypes.globalTypeSavings}-annuityUnqualified`]: {
    path: ["savings", "annuityUnqualified"],
    key: "value",
    deduct: deductArray.annuity,
    isOverdraftWas: true,
    isOverdrafted: true
  },
  [`${globalTypes.globalTypeSavings}-annuityQualified`]: {
    path: ["savings", "annuityQualified"],
    key: "value",
    deduct: deductArray.annuity,
    isOverdraftWas: true,
    isOverdrafted: true
  },
  ////
  [`${globalTypes.globalTypeInvestments}-${entitiesFull.entitiesInvestments.ENTITY_TREASURIES}`]: {
    path: ["investments", "treasuries"],
    key: "value",
    deduct: deductArray.treasuries
  },
  [`${globalTypes.globalTypeInvestments}-${entitiesFull.entitiesInvestments.ENTITY_MUTUAL_FUNDS}`]: {
    path: ["investments", "mutualFunds"],
    key: "value",
    deduct: deductArray.mutualFunds
  },
  [`${globalTypes.globalTypeInvestments}-${entitiesFull.entitiesInvestments.ENTITY_STOCKS}`]: {
    path: ["investments", "stocks"],
    key: "value",
    deduct: deductArray.stocks
  },
  [`${globalTypes.globalTypeUse}-${entitiesFull.entitiesUse.ENTITY_HOME}`]: {
    path: ["use", "home"],
    pathDebt: ["debt", "home"],
    key: "value",
    keyDebt: "value",
    deduct: deductArray.useAsset,
    deductDebt: deductArray.debt,
    isFull: true
  }
};

export const overdraftPriorityDefault = [
  // TODO CHECK CONDITION & ORDER BY
  // TODO isNumeric isDate and so on
  {
    id: 0,
    isEnabled: true,
    condition: null,
    orderBy: "amount",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    order: "desc",

    globalType: globalTypes.globalTypeAccounts,
    entity: entitiesFull.entitiesAccounts.ENTITY_CHECKING
  },
  {
    id: 1,
    isEnabled: true,
    condition: null,
    orderBy: "amount",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    order: "desc",

    globalType: globalTypes.globalTypeAccounts,
    entity: entitiesFull.entitiesAccounts.ENTITY_SAVINGS
  },
  {
    id: 2,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high interest rate",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_INTEREST_RATE",
    orderBy: "interestRateDefault", // todo  low to high interest rate %
    order: "desc",

    globalType: globalTypes.globalTypeAccounts,
    entity: entitiesFull.entitiesAccounts.ENTITY_MONEY_MARKET
  },
  {
    id: 3,
    isEnabled: true,
    condition: null,
    orderByTitle: "Sooner to later maturity date",
    orderByTitleI18n:
      "CONFIGURATION.OVERDRAFT.ORDER.SOONER_TO_LATER_MATURITY_DATE",
    orderBy: "monthsLeft", // todo sooner to later maturity date
    order: "asc",

    globalType: globalTypes.globalTypeAccounts,
    entity: entitiesFull.entitiesAccounts.ENTITY_CD
  },

  {
    id: 4,
    isEnabled: true,
    condition: "LifeMore7", // todo today-issuance date>=7 years
    conditionTitle: "Life insurance date >= 7 years",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.LI_MORE_7",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "cashValue", // low to high amounts
    order: "desc",

    customTitle: "LifeIns >= 7",
    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_LIFE_INSURANCE
  },
  {
    id: 5,
    isEnabled: true,
    condition: "eventDontStart",
    conditionTitle: "Event didn't start",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.EVENT_DIDNT_START",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_ANNUITY,
    entityCustom: "annuityUnqualified"
  },
  {
    id: 6,
    isEnabled: true,
    condition: "iraTypeRoth", // TYPE IRA ROTH
    conditionTitle: "Where type ROTH",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.IRA_TYPE_ROTH",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_IRA
  },
  {
    id: 7,
    isEnabled: true,
    condition: "taxNoTax",
    conditionTitle: "Where tax category no tax",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_NO_TAX",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_OTHER,
    entityCustom: "otherNoTax"
  },
  {
    id: 8,
    isEnabled: true,
    condition: "taxNoTaxGeneral",
    conditionTitle: "Where tax category no tax",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_NO_TAX",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_GENERAL,
    entityCustom: "otherNoTax"
  },
  {
    id: 9,
    isEnabled: true,
    condition: "taxCapital",
    conditionTitle: "Where tax category capital gain",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_CAPITAL",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_OTHER,
    entityCustom: "otherCapital"
  },
  {
    id: 10,
    isEnabled: true,
    condition: "taxCapitalGeneral",
    conditionTitle: "Where tax category capital gain",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_CAPITAL",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_GENERAL,
    entityCustom: "otherCapital"
  },
  {
    id: 11,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeInvestments,
    entity: entitiesFull.entitiesInvestments.ENTITY_MUTUAL_FUNDS
    // entityCustom: "mutualFunds"
  },

  {
    id: 12,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeInvestments,
    entity: entitiesFull.entitiesInvestments.ENTITY_TREASURIES
  },
  {
    id: 13,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeInvestments,
    entity: entitiesFull.entitiesInvestments.ENTITY_STOCKS
  },
  {
    id: 14,
    isEnabled: true,
    condition: "ageMore59.5", // AGE > 59.5
    conditionTitle: "When age > 59.5",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.AGE_MORE_59_5",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_IRA
  },
  {
    id: 15,
    isEnabled: true,
    condition: null,
    conditionTitle: "Event didn't start",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.EVENT_DIDNT_START",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_ANNUITY,
    entityCustom: "annuityQualified"
  },
  {
    id: 16,
    isEnabled: true,
    condition: "ageMore59.5", // AGE > 59.5
    conditionTitle: "When age > 59.5",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.AGE_MORE_59_5",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_401K
    // entityCustom: "401k"
  },
  {
    id: 17,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_DEFERRED_COMPENSATION
    // entityCustom: "deferredComp"
  },
  {
    id: 18,
    isEnabled: true,
    condition: null,
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_529_COLLEGE_SAVINGS
    // entityCustom: "529college"
  },
  {
    id: 19,
    isEnabled: true,
    condition: null, // todo today-issuance date < 7 years
    conditionTitle: "Life insurance date < 7 years",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.LI_LESS_7",
    orderByTitle: "Closest to 7 years",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LI_CLOSEST_TO_7_YEARS",
    orderBy: "insuranceDate", // closest to 7 years first
    order: "desc",

    customTitle: "LifeIns < 7",
    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_LIFE_INSURANCE
  },
  {
    id: 20,
    isEnabled: true,
    condition: "ageLess59.5", // AGE > 59.5
    conditionTitle: "When age < 59.5",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.AGE_LESS_59_5",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_IRA
  },
  {
    id: 21,
    isEnabled: true,
    condition: "ageLess59.5", // AGE > 59.5
    conditionTitle: "When age < 59.5",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.AGE_LESS_59_5",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_401K
    // entityCustom: "401k"
  },
  {
    id: 22,
    isEnabled: true,
    condition: "taxOrdinary",
    conditionTitle: "Where tax category ordinary",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_ORDINARY",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_OTHER,
    entityCustom: "otherOrdinary"
  },
  {
    id: 23,
    isEnabled: true,
    condition: "taxOrdinaryGeneral",
    conditionTitle: "Where tax category ordinary",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.TAX_ORDINARY",
    orderByTitle: "Low to high amounts",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_AMOUNTS",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeSavings,
    entity: entitiesFull.entitiesSavings.ENTITY_GENERAL,
    entityCustom: "otherOrdinary"
  },
  // Home
  {
    id: 24,
    isEnabled: true,
    condition: "homeTypeVacation",
    conditionTitle: "Type Vacation",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.HOME_VACATION",
    orderByTitle: "Debt high to low. Then low to high value",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.DEBT_HIGH_TO_LOW_VALUE",
    orderBy: "remainingDebt",
    order: "desc",

    globalType: globalTypes.globalTypeUse,
    entity: entitiesFull.entitiesUse.ENTITY_HOME
  },
  {
    id: 25,
    isEnabled: true,
    condition: "homeTypeOther",
    conditionTitle: "Type Other",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.HOME_OTHER",
    orderByTitle: "Low to high value",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.DEBT_HIGH_TO_LOW_VALUE",
    orderBy: "remainingDebt",
    order: "desc",

    globalType: globalTypes.globalTypeUse,
    entity: entitiesFull.entitiesUse.ENTITY_HOME
  },
  {
    id: 26,
    isEnabled: true,
    condition: "homeTypeInvestment",
    conditionTitle: "Type Investment",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.HOME_INVESTMENT",
    orderByTitle: "Low to high monthly income minus monthly debt",
    orderByTitleI18n:
      "CONFIGURATION.OVERDRAFT.ORDER.INCOME_DEBT_LOW_TO_HIGH_VALUE",
    orderBy: "incomeRent",
    orderByCustom: "incomeRent",
    order: "asc",
    // order: "desc",

    globalType: globalTypes.globalTypeUse,
    entity: entitiesFull.entitiesUse.ENTITY_HOME
  },
  {
    id: 27,
    isEnabled: true,
    condition: "homeTypeMultiple",
    conditionTitle: "Type Multiple homes",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.HOME_MULTIPLE",
    orderByTitle: "Low to high value",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.DEBT_HIGH_TO_LOW_VALUE",
    orderBy: "remainingDebt",
    order: "desc",

    globalType: globalTypes.globalTypeUse,
    entity: entitiesFull.entitiesUse.ENTITY_HOME
  },
  {
    id: 28,
    isEnabled: true,
    condition: "homeTypePrimary",
    conditionTitle: "Type Primary residence",
    conditionTitleI18n: "CONFIGURATION.OVERDRAFT.CONDITION.HOME_PRIMARY",
    orderByTitle: "Low to high value",
    orderByTitleI18n: "CONFIGURATION.OVERDRAFT.ORDER.LOW_TO_HIGH_VALUE",
    orderBy: "value",
    order: "desc",

    globalType: globalTypes.globalTypeUse,
    entity: entitiesFull.entitiesUse.ENTITY_HOME
  }
];

export const overdraftPriority = [
  {
    path: ["accounts", "checking"],
    key: "balance",
    orderBy: "amount",
    deduct: deductArray.balance
  },
  {
    path: ["accounts", "savings"],
    key: "balance",
    deduct: deductArray.balance
  },
  {
    path: ["accounts", "moneyMarket"],
    key: "balance",
    deduct: deductArray.balance
  },
  {
    path: ["accounts", "cd"],
    key: "balance",
    deduct: deductArray.balance
  },
  // {
  //   path: ["savings", "lifeInsurance"],
  //   key: "cashValue",
  //   condition: "isLifeInsurance",
  //   deduct: deductArray.LICashValue
  // },
  {
    condition: "LifeMore7", // todo today-issuance date>=7 years
    orderBy: "cashValue", // low to high amounts
    path: ["savings", "lifeInsurance"],
    key: "cashValue",
    key2: "cashIulValue",
    conditionCustom: "isLifeInsurance",
    deduct: deductArray.LIRetirementCashValue
  },

  {
    condition: "eventDontStart",
    orderBy: "value",
    order: "desc",
    path: ["savings", "annuityUnqualified"],
    key: "value",
    deduct: deductArray.annuity,
    isOverdraftWas: true,
    isOverdrafted: true
  },

  {
    path: ["savings", "otherNoTax"],
    key: "value",
    deduct: deductArray.savingsRetirementOther
  },
  {
    path: ["savings", "otherCapital"],
    key: "value",
    deduct: deductArray.savingsRetirementOther
  },
  {
    path: ["investments", "mutualFunds"],
    key: "value",
    deduct: deductArray.mutualFunds
  },
  {
    path: ["investments", "treasuries"],
    key: "value",
    deduct: deductArray.treasuries
  },
  {
    path: ["investments", "stocks"],
    key: "value",
    deduct: deductArray.stocks
  },
  {
    path: ["savings", "IRA"],
    key: "value",
    deduct: deductArray.IRA
  },
  {
    path: ["savings", "annuityQualified"],
    key: "value",
    deduct: deductArray.annuity,
    isOverdraftWas: true,
    isOverdrafted: true
  },
  {
    path: ["savings", "401k"],
    key: "value",
    deduct: deductArray["401K"]
  },
  {
    path: ["savings", "deferredComp"],
    key: "value",
    deduct: deductArray.deferredComp
  },

  {
    path: ["savings", "529college"],
    key: "value",
    deduct: deductArray["529college"]
  }
  // {
  //   path: ["use", "vehicle"],
  //   key: "value",
  //   deduct: deductArray.useAsset,
  //   isFull: true
  // },
  // {
  //   path: ["use", "other"],
  //   key: "value",
  //   deduct: deductArray.useAsset,
  //   // isFull: true
  // },
  // {
  //   path: ["use", "home"],
  //   key: "value",
  //   deduct: deductArray.useAsset,
  //   isFull: true
  // },
];

export const initialCalcStateWhatIf = {
  chartDataWhatIf: {
    //NetWealth
    NetWealth: 0,
    HomesAndVehicles: 0,
    MonetaryAssets: 0,
    Liabilities: 0,
    Taxes: 0,

    // Income
    Savings: 0,
    PreTaxDeductions: 0,
    Expenses: 0,
    TaxesIncome: 0
  },
  MoneyInDetailWhatIf: {
    other: {
      other: 0,
      businessInterest: 0,
      all: 0
    }
  },
  MoneyInWhatIf: {
    salary: 0,
    realEstate: 0,
    other: 0,
    events: 0,

    all: 0
  },
  MoneyOutWhatIf: {
    personal: 0,
    asset: 0,
    savings: 0,
    debtRepayment: 0,
    other: 0,
    events: 0,
    taxes: 0,

    all: 0
  },

  MoneyOutDetailWhatIf: {
    taxes: {
      income: 0,
      capital: 0,
      state: 0,
      FICA: 0,
      property: 0,

      all: 0
    },
    savings: {
      IRA: 0,
      annuity: 0,
      "529college": 0,
      lifeInsurance: 0,
      other: 0,
      all: 0
    }
  },

  AssetsWhatIf: {
    savings: 0,
    cash: 0,
    investments: 0,
    retirement: 0,
    use: 0,

    all: 0
  },

  AssetsDetailWhatIf: {
    cash: {
      accounts: 0,
      lifeInsurance: 0,
      all: 0
    },
    investments: {
      mutualFunds: 0,
      stocks: 0,
      treasuries: 0,
      unvestedRSU: 0,
      ESPP: 0,
      all: 0
    },
    retirement: {
      "401K": 0,
      IRA: 0,
      deferredComp: 0,
      annuity: 0,
      lifeInsurance: 0,
      "529college": 0,
      other: 0,
      all: 0
    },
    taxable: {
      stocks: 0,
      mutualAndTreasuries: 0,
      EsppAndRsu: 0,
      estateInvestments: 0,
      estatePrimaryRes: 0,

      all: 0
    },
    taxDeferred: {
      "401K": 0,
      IRA: 0,
      annuity: 0,
      "529college": 0,
      deferredComp: 0,
      lifeInsurance: 0,
      other: 0,

      all: 0
    },
    taxAdvantage: {
      IRA: 0,
      annuity: 0,
      lifeInsurance: 0,
      other: 0,

      all: 0
    },
    use: {
      home: 0,
      vehicle: 0,
      other: 0
    }
  },

  AssetsTaxWhatIf: {
    deferred: 0,
    advantage: 0
  },

  LiabilitiesWhatIf: {
    mortgage: 0,
    loans: 0,
    capitalTax: 0,
    deferredIncomeTax: 0,

    all: 0
  },

  TaxCategoriesWhatIf: {
    taxable: 0,
    taxDeferred: 0,
    taxAdvantage: 0,

    all: 0
  },

  TempValuesWhatIf: {
    investments: {
      RST_ESPP: 0
    },
    savings: {
      currYear: 0
    }
  }
};
