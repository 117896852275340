import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import { compose } from "redux";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AssetCategoryCommon from "components/AssetsCommon/AssetCategoryCommon";

import "./events.scss";

import {
  GLOBAL_TYPE_USE_ASSETS,
  GLOBAL_COLLECTION,
  GLOBAL_TYPE_COLLECTION,
  ENTITIES,
  ENTITIES_GROUPS,
  ENTITIES_ADDITIONAL,
  formScheme,
  designScheme,
  formsValidationScheme,
  formsInitialState,
  RIGHT_LEVEL,
  RIGHT_SHARE,
  translations
} from "./formScheme";

import {
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalGoals,
  GLOBAL_COLLECTION as queryCollectionGoals
} from "../PlansGoals/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalDebts,
  GLOBAL_COLLECTION as queryCollectionDebts
} from "../HouseholdDebt/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalAssets,
  GLOBAL_COLLECTION_USE_ASSETS as queryCollectionAssets,
  ENTITIES as entitiesUse,
  optionsValuesHome
} from "../HouseholdAssetsUse/formScheme";

import { ENTITIES as entitiesSavings } from "../HouseholdAssetsSavings/formScheme";
import AccessForbidden from "../../routeHandlers/AccessForbidden";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";
import {
  getNotDisabledEntities,
  getNotDisabledEntitiesOrdered
} from "../../store/selectors/firebase";

const GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU = (
  <FormattedMessage
    id="DEBT.FORM.TOP.SHOW_CONTRIBUTION"
    defaultMessage="Show Contribution"
  />
);

export class MoneyOutOther extends PureComponent {
  state = {
    years: [],
    yearsWithEvents: {}
  };

  componentDidUpdate(prevProps, prevState, prevContext) {
    // eslint-disable-next-line
    if (prevProps.assets != this.props.assets) {
      this.prepareEvents();
    }
  }

  prepareEvents = () => {
    const { assets } = this.props;

    const years = [];
    const yearsWithEvents = {};

    if (assets) {
      assets.forEach(asset => {
        const year = +asset.startAge;
        if (years.indexOf(year) === -1) {
          years.push(year);
        }

        if (!yearsWithEvents[year]) {
          yearsWithEvents[year] = [];
        }

        yearsWithEvents[year].push(asset.id);

        if (asset.years) {
          for (let i = year + 1; i < year + +asset.years; i++) {
            if (years.indexOf(i) === -1) {
              years.push(i);
            }

            if (!yearsWithEvents[i]) {
              yearsWithEvents[i] = [];
            }

            yearsWithEvents[i].push(asset.id);
          }
        }
      });

      this.setState({
        years: years.sort((a, b) => a - b),
        yearsWithEvents
      });
    }
  };

  render() {
    const {
      auth,
      assets,
      profile,
      firestoreStatus,
      childs,
      goals,
      debts,
      useAssets,
      profileProvider,
      assetsAll,
      //
      isDashboard,
      activeYear,
      activeYears,
      isCombineDashboard
    } = this.props;

    if (
      !profile.isLoaded ||
      firestoreStatus.requesting.assets ||
      firestoreStatus.requesting.goals
    ) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.sharePlans ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const isAccessEdit =
      isOwnProfile ||
      (this.props.rights && this.props.rights[RIGHT_LEVEL] === "1");

    const homesRent = {};
    const useAssetsAll = {};
    const annuities = {};

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];
        if (asset && asset.entity === entitiesSavings.ENTITY_ANNUITY) {
          annuities[asset.id] = asset;
        }

        if (
          asset &&
          asset.type === optionsValuesHome.INVESTMENT &&
          asset.entity === entitiesUse.ENTITY_HOME
        ) {
          homesRent[asset.id] = asset;
        }

        if (
          asset &&
          asset.entity !== entitiesUse.ENTITY_GENERAL &&
          asset.globalType === QueryGlobalAssets
        ) {
          useAssetsAll[asset.id] = asset;
        }
      });
    }

    return (
      <AssetCategoryCommon
        isAccessEdit={isAccessEdit}
        isEvents={true}
        eventsYears={this.state.years}
        eventsYearsFull={this.state.yearsWithEvents}
        profile={profile}
        assets={assets}
        childs={childs}
        formScheme={formScheme}
        auth={auth}
        ENTITIES={ENTITIES}
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        designScheme={designScheme}
        formsInitialState={formsInitialState}
        formsValidationScheme={formsValidationScheme}
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={GLOBAL_COLLECTION}
        GLOBAL_TYPE_COLLECTION={GLOBAL_TYPE_COLLECTION}
        GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU={
          GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU
        }
        translations={translations}
        isHideSort={true}
        headerWithoutPadding
        additionalAssets={{
          goals,
          debts,
          assets: useAssets,
          useAssetsAll,
          annuities,
          homesRent,
          assetsAll
        }}
        customOptionsValues={{ isEvents: true }}
        ////
        isDashboard={isDashboard}
        activeYear={activeYear}
        activeYears={activeYears}
        isCombineDashboard={isCombineDashboard}
      />
    );
  }
}



MoneyOutOther.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  assets: PropTypes.any,
  goals: PropTypes.any,
  debts: PropTypes.any,
  profileProvider: PropTypes.any,
  rights: PropTypes.any,
  useAssets: PropTypes.any,

  isDashboard: PropTypes.bool,
  activeYear: PropTypes.any,
  activeYears: PropTypes.any,
  isCombineDashboard: PropTypes.bool
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { profileProvider }
}) {
  const rights = ordered && ordered.rights;

  return {
    auth,
    profile,
    profileProvider: profileProvider || auth.uid,
    assetsAll: ordered && getNotDisabledEntitiesOrdered(ordered.assetsAll),
    assets: ordered && ordered.plansEvents,

    childs: ordered && ordered.childs,
    goals: ordered && getNotDisabledEntities(ordered.goals),
    debts: ordered && getNotDisabledEntities(ordered.debts),
    useAssets: ordered && getNotDisabledEntities(ordered.useAssets),
    rights: rights && rights[0],
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: GLOBAL_COLLECTION || "userAssets",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_USE_ASSETS]
        ],
        storeAs: "plansEvents",
        orderBy: ["entity", "asc"]
      },

      {
        collection: "userAssets",
        where: [["user", "==", profileUid]],
        storeAs: "assetsAll"
      },

      {
        collection: queryCollectionGoals,
        storeAs: "goals",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", QueryGlobalGoals]
        ],
        orderBy: ["position", "asc"]
      },
      {
        collection: queryCollectionDebts,
        storeAs: "debts",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", QueryGlobalDebts]
        ]
      },
      {
        collection: queryCollectionAssets,
        storeAs: "useAssets",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", QueryGlobalAssets]
        ]
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(MoneyOutOther));
