import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconTrophy = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M21 3H18V2H6V3H3C2.4 3 2 3.4 2 4V6.4C2 8.7 3.7 10.6 6 10.9V11C6 13.9 8 16.3 10.7 16.9L10 19H7.7C7.3 19 6.9 19.3 6.8 19.7L6 22H18L17.2 19.7C17.1 19.3 16.7 19 16.3 19H14L13.3 16.9C16 16.3 18 13.9 18 11V10.9C20.3 10.6 22 8.7 22 6.4V4C22 3.4 21.6 3 21 3ZM6 8.9C4.9 8.6 4 7.6 4 6.4V5H6V8.9ZM20 6.4C20 7.6 19.1 8.7 18 8.9V5H20V6.4Z"
        fill={fill}
      />
    </svg>
  );
};

IconTrophy.propTypes = {
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconTrophy;
