import React from "react";

import { withFirebase } from "react-redux-firebase";

import SignUp from "./SignUp";

const SignUpConnected = props => {
  return <SignUp {...props} />;
};

export default withFirebase(SignUpConnected);
