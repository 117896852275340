// import { IntlProvider as ReactIntlProvider, addLocaleData } from "react-intl-v3";
import { IntlProvider as ReactIntlProvider } from "react-intl-v3";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";

import language from "./getLanguage";
import intlDefaultTag from "./intlDefaultTag";

// import locale_en from "react-intl/locale-data/en";
// import locale_ru from "react-intl/locale-data/ru";
// import locale_zh from "react-intl/locale-data/zh";

import messages_en from "../translations/en.json";
import messages_ru from "../translations/ru.json";
import messages_zh from "../translations/zh.json";

import { compose } from "redux";
import { connect } from "react-redux";

// const dateFnslocales = {
//   en: require("date-fns/locale/en-US"),
//   ru: require("date-fns/locale/ru"),
//   zh: require("date-fns/locale/zh-CN")
// };

// import { defaultLanguage } from 'config/dev.conf';
// import CONFIG from "config/dev.conf";

// const { defaultLanguage } = CONFIG;
const defaultLanguage = "en";

// addLocaleData([...locale_en, ...locale_ru, ...locale_zh]);

// Fallback to English before defaultMessage
const messages = {
  en: messages_en,
  ru: Object.assign({ ...messages_en }, messages_ru),
  zh: Object.assign({ ...messages_en }, messages_zh)
};

const isLanguageSupport = lang => Object.keys(messages).indexOf(lang) !== -1;

class IntlProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    getClientInfo: PropTypes.func
  };

  // componentDidMount() {
  //   const { firebase, profile } = this.props;
  //
  //   // if (!locale && isLoggedIn) {
  //   //   getClientInfo({ uid });
  //   // }
  //   //
  //   // if (!locale && !isLoggedIn) {
  //   //   fetchSession();
  //   // }
  //   // const lang = this._getCurrentLanguage();
  // }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { profile } = this.props;

    const lang = this._getCurrentLanguage(profile && profile.language);

    if (document && lang && document.documentElement.lang !== lang) {
      document.documentElement.lang = lang;
    }
  }

  _getCurrentLanguage = definedLocale => {
    const { profile } = this.props;

    return isLanguageSupport(definedLocale || (profile && profile.language))
      ? definedLocale || (profile && profile.language)
      : isLanguageSupport(language)
      ? language
      : defaultLanguage || "en";
  };

  render() {
    const lang = this._getCurrentLanguage();

    window.__localeId__ = lang;

    return (
      <ReactIntlProvider
        locale={lang}
        messages={messages[lang]}
        textComponent={intlDefaultTag}
      >
        {this.props.children}
      </ReactIntlProvider>
    );
  }
}

function mapStateToProps({ firebase: { auth, profile } }) {
  return {
    auth,
    profile
  };
}
export default compose(
  connect(
    mapStateToProps,
    {}
  )
)(withFirebase(IntlProvider));
