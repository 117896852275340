import "react-app-polyfill/ie11";

// eslint-disable-next-line
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import routes from "./routes";
import { ie11 } from "./lib/helpers";
import "./assets/scss/scaffolding.scss";
import "core-js/es/string";
import "core-js/es/object";
import "core-js/es/array";

function ensureIntlSupport() {
  ie11();
  if (window.Intl) return Promise.resolve();
  return new Promise(resolve => {
    resolve(require("intl"));
  }).then(() => Promise.all([require("intl/locale-data/jsonp/en.js")]));
}

if (!Intl.PluralRules) {
  require("intl-pluralrules");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/include-aliases"); // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en"); // Add locale data for de
}

const rootEl = document.getElementById("root");

// ensureIntlSupport().then(() => {
//
// });

ReactDOM.render(routes, rootEl);

if (process.env.NODE_ENV === "development") {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept();
}
