export const PROFILE_SETUP_INFO = {
  firstName: {
    isRequired: true,
    label: "First Name",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  },
  lastName: {
    isRequired: true,
    label: "First Name",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  },
  email: {
    isRequired: true,
    disabled: true,
    label: "First Name",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
  }
};

export const PROFILE_SETUP_PASSWORD = {
  password: {
    isRequired: true,
    isPassword: true,
    label: "Old Password",
    labelI18n: "PASSWORD.CURRENT"
  },
  newPassword: {
    isRequired: true,
    isPassword: true,
    label: "New Password",
    labelI18n: "PASSWORD.FORM.NEW_PASSWORD"
  },
  confirmPassword: {
    isRequired: true,
    isPassword: true,
    label: "Confirm password",
    labelI18n: "PASSWORD.FORM.CONFIRM_PASSWORD"
  }
};
export const PROFILE_ADVISOR_INVITE = {
  clientName: {
    isRequired: true,
    label: "Client Name",
    labelI18n: "PROFILE.CARD.ADVISOR_INVITE.NAME"
  },
  clientEmail: {
    isEmail: true,
    isRequired: true,
    label: "Client Email",
    labelI18n: "PROFILE.CARD.ADVISOR_INVITE.EMAIL"
  }
};

export const languageOptions = [
  {
    name: "English",
    nameShort: "EN",
    value: "en"
  },
  {
    name: "Chinese",
    nameShort: "ZH",
    value: "zh"
  },
  {
    name: "Russian",
    nameShort: "RU",
    value: "ru"
  }
];

export const PROFILE_SETUP_LANGUAGE = {
  language: {
    isRequired: true,
    isSelect: true,
    options: languageOptions,
    label: "Language",
    labelI18n: "APP.LANGUAGE"
  }
};

const PROFILE = "PROFILE";
const PASSWORD = "PASSWORD";
const LANGUAGE = "LANGUAGE";
const ADVISOR_INVITE = "ADVISOR_INVITE";

export const PROFILE_FORMS = {
  [PROFILE]: PROFILE_SETUP_INFO,
  [PASSWORD]: PROFILE_SETUP_PASSWORD,
  [LANGUAGE]: PROFILE_SETUP_LANGUAGE,
  [ADVISOR_INVITE]: PROFILE_ADVISOR_INVITE
};

const formsInitialStateInit = {};
const formsValidationSchemeInit = {};
Object.keys(PROFILE_FORMS).forEach(entityKey => {
  Object.keys(PROFILE_FORMS[entityKey]).forEach(field => {
    if (!formsInitialStateInit[entityKey]) {
      formsInitialStateInit[entityKey] = {};
    }
    if (!formsValidationSchemeInit[entityKey]) {
      formsValidationSchemeInit[entityKey] = {};
    }

    if (PROFILE_FORMS[entityKey][field].isAmount) {
      formsInitialStateInit[entityKey][field] = 0;
    } else {
      formsInitialStateInit[entityKey][field] =
        PROFILE_FORMS[entityKey][field].default || "";
    }

    if (PROFILE_FORMS[entityKey][field].isRequired) {
      formsValidationSchemeInit[entityKey][field] = {
        isRequired: true,
        isError: null,
        isTouched: null
      };
    }

    if (PROFILE_FORMS[entityKey][field].isEmail) {
      formsValidationSchemeInit[entityKey][field] = {
        ...formsValidationSchemeInit[entityKey][field],
        isEmail: true
      };
    }
  });
});

export const formsInitialState = { ...formsInitialStateInit };
export const formsValidationScheme = { ...formsValidationSchemeInit };
