import React from "react";

import { colors } from "constants/styles";

const IconQuizManWork = ({
  width = 46,
  height = 113,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 46 113"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 24C29.6274 24 35 18.6274 35 12C35 5.37258 29.6274 0 23 0C16.3726 0 11 5.37258 11 12C11 18.6274 16.3726 24 23 24ZM17.3576 28H17.3577H28.6424H28.6424C32.6924 27.9998 34.9657 27.9997 36.8455 28.5818C40.9422 29.8504 44.1496 33.0578 45.4182 37.1545C46.0003 39.0343 46.0002 41.3076 46 45.3577L46 71C46 74.3137 43.3137 77 40 77C38.4633 77 37.0615 76.4223 36 75.4722V106C36 109.866 32.866 113 29 113C26.4537 113 24.2249 111.64 23 109.608C21.7751 111.64 19.5463 113 17 113C13.134 113 10 109.866 10 106V75.4722C8.9385 76.4223 7.53672 77 6.00002 77C2.68631 77 1.51131e-05 74.3137 1.51131e-05 71L7.48374e-06 45.3577C-0.00019541 41.3076 -0.000309587 39.0343 0.581791 37.1545C1.85039 33.0578 5.05784 29.8504 9.15453 28.5818C11.0343 27.9997 13.3076 27.9998 17.3576 28Z"
        fill="#333456"
        fillOpacity="0.6"
      />
      <circle
        cx="23"
        cy="12"
        r="11"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.5 29H17.5882C13.3191 29 11.1845 29 9.45033 29.537C5.66876 30.708 2.70804 33.6688 1.53702 37.4503C1 39.1845 1 41.3191 1 45.5882V71C1 73.7614 3.23858 76 6 76V76C8.76142 76 11 73.7614 11 71V46"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5 29H28.4118C32.6809 29 34.8155 29 36.5497 29.537C40.3312 30.708 43.292 33.6688 44.463 37.4503C45 39.1845 45 41.3191 45 45.5882V71C45 73.7614 42.7614 76 40 76V76C37.2386 76 35 73.7614 35 71V46"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20 34L22 31H24L26 34L24 37L26 60L23 62L20 60L22 37L20 34Z"
        fill="#2A293D"
      />
      <path
        d="M11 64V106C11 109.314 13.6863 112 17 112V112C20.3137 112 23 109.314 23 106V74"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35 64V106C35 109.314 32.3137 112 29 112V112C25.6863 112 23 109.314 23 106V74"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconQuizManWork;
