import React from "react";

import Header from "./Header";
import CssBaseline from "@material-ui/core/CssBaseline";

import { withFirebase } from "react-redux-firebase";

const LandingPage = props => {
  return (
    <>
      <CssBaseline />
      <Header />
      <main>
        <h1>Landing</h1>
      </main>
    </>
  );
};

export default withFirebase(LandingPage);
