import React, { Component } from "react";

import ChangePassword from "./ChangePassword";
import { withFirebase } from "react-redux-firebase";

class ChangePasswordConnected extends Component {
  render() {
    return <ChangePassword {...this.props} />;
  }
}

export default withFirebase(ChangePasswordConnected);
