import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconAccount = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M20 5L5 11.6667V15H35V11.6667L20 5ZM26.6667 16.6667V28.3333H31.6667V16.6667H26.6667ZM5 35H35V30H5V35ZM17.5 16.6667V28.3333H22.5V16.6667H17.5ZM8.33333 16.6667V28.3333H13.3333V16.6667H8.33333Z"
        fill={fill}
      />
    </svg>
  );
};

IconAccount.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconAccount;
