import {
  commonPrefixAfter,
  addAdditionalConfiguration,
  prepareState
} from "components/AssetsCommon/_formScheme";

import {
  GLOBAL_COLLECTION_USE_ASSETS as COLLECTION_USE_ASSETS,
  GLOBAL_TYPE_USE_ASSETS as TYPE_USE_ASSETS
} from "components/HouseholdAssetsUse/formScheme";

import { ASSETS_OWNED_USE, DEBT } from "constants/routes";
import { RIGHT_HOUSEHOLD_DEBT } from "../../constants/rights";

export const GLOBAL_TYPE_USE_ASSETS = "household-debt";
export const GLOBAL_COLLECTION = "userAssets";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_DEBT;
export const RIGHT_LEVEL = `${RIGHT_HOUSEHOLD_DEBT}-can`;

const ENTITY_HOME = "home";
const ENTITY_VEHICLE = "vehicle";
const ENTITY_CREDIT_CARD = "creditCard";
const ENTITY_STUDENT = "student";
const ENTITY_MEDICAL = "medical";
const ENTITY_OTHER = "other";
const ENTITY_GENERAL = "general";

export const DEBT_ENDING_SOON = 3;

export const ENTITIES = {
  ENTITY_HOME,
  ENTITY_VEHICLE,
  ENTITY_CREDIT_CARD,
  ENTITY_STUDENT,
  ENTITY_MEDICAL,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

export const ENTITIES_DEBT = {};
// const ENTITY_ADD_REPAYMENT = "Repayment";

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.ASSETS.DEBT.SUBTITLE": {
    title: "Select the things for which you owe",
    ENTITIES: {
      ENTITY_HOME,
      ENTITY_VEHICLE,
      ENTITY_CREDIT_CARD,
      ENTITY_STUDENT,
      ENTITY_MEDICAL,
      ENTITY_OTHER
    }
  }
};

export const optionsRepaymentValues = {
  AMORTIZATION: "amortization",
  FIXED_PAYMENTS: "fixedPayments",
  INTEREST_ONLY: "interestOnly",
  MONTHLY_AMOUNT: "monthly"
};

const optionsRepaymentType = [
  {
    name: "Amortization",
    nameI18n: "HOUSEHOLD.ASSETS.DEBT.REPAYMENT.TYPES.AMORTIZATION",
    value: optionsRepaymentValues.AMORTIZATION
  },
  // {
  //   name: "Fixed Payments",
  //   nameI18n: "HOUSEHOLD.ASSETS.DEBT.REPAYMENT.TYPES.FIXED_PAYMENTS",
  //   value: optionsRepaymentValues.FIXED_PAYMENTS
  // },
  {
    name: "Interest only",
    nameI18n: "HOUSEHOLD.ASSETS.DEBT.REPAYMENT.TYPES.INTEREST_ONLY",
    value: optionsRepaymentValues.INTEREST_ONLY
  },
  {
    name: "Monthly amount",
    nameI18n: "HOUSEHOLD.ASSETS.DEBT.REPAYMENT.TYPES.MONTHLY_AMOUNT",
    value: optionsRepaymentValues.MONTHLY_AMOUNT
  }
];

export const repeatableSchemeGeneral = {
  description: {
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.DESCRIPTION.LABEL"
  },
  loanType: {
    isSelect: true,
    options: optionsRepaymentType,
    isRequired: true,
    label: "Repayment Type",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.REPAYMENT_TYPE.LABEL"
  },
  loanDate: {
    isDate: true,
    isRequired: true,
    label: "Loan Issue Date",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.LOAN_ISSUE_DATE.LABEL"
  },
  loanAmount: {
    isRequired: true,
    isAmount: true,
    isNumber: true,

    label: "Loan Amount",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.LOAN_AMOUNT.LABEL"
  },

  /// todo move to additional form
  term: {
    expiredCalculate: { isMonthsLeftPeriod: true, fieldDate: "loanDate" },
    endingSoonCalculate: {
      monthsLeftPeriod: DEBT_ENDING_SOON,
      fieldDate: "loanDate"
    },

    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ]
      }
    ],
    isRequired: true,
    isNumber: true,
    isInt: true,
    min: 0,
    after: commonPrefixAfter.MNTH,

    label: "Term",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.TERM.LABEL"
  },

  interestRate: {
    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ]
      }
    ],

    isRequired: true,
    prefix: "%",
    isNumber: true,
    min: 0,
    label: "Interest Rate",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.INTEREST_RATE.LABEL"
  },
  debtRemainingAmortizationInterest: {
    isOnlyShow: true,
    isMain: true,
    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ]
      }
    ],
    calculate: { isDebtRemainingAmortizationInterest: true },
    label: "Remaining Debt",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.REMAINING_DEBT.LABEL"
  },
  annualAmount: {
    isOnlyShow: true,
    isAdditional: true,
    calculate: { isAnnualPaymentDebt: true },
    labelCard: "Annual payment",
    labelI18nCard: "HOUSEHOLD.ASSETS.DEBT.ANNUAL_PAYMENT.LABEL"
  },
  monthlyPayment: {
    // isAdditional: true,
    expiredCalculate: { isDebtMonthlyExp: true, fieldDate: "loanDate" },
    endingSoonCalculate: {
      monthsLeftPeriod: DEBT_ENDING_SOON,
      fieldDate: "loanDate"
    },
    condition: [
      { field: "loanType", value: optionsRepaymentValues.MONTHLY_AMOUNT }
    ],

    isRequired: true,
    isAmount: true,

    label: "Monthly payment",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.MONTHLY_PAYMENT.LABEL"
  },
  debtRemainingMonthly: {
    isOnlyShow: true,
    isMain: true,
    condition: [
      { field: "loanType", value: optionsRepaymentValues.MONTHLY_AMOUNT }
    ],
    calculate: {
      isDebtRemainingMonthly: true,
      isDebtRemainingMonthlySoon: true
    },
    label: "Remaining Debt",
    labelI18n: "HOUSEHOLD.ASSETS.DEBT.REMAINING_DEBT.LABEL"
  }

  // numberOfMonths: {
  //   condition: [
  //     { field: "loanType", value: optionsRepaymentValues.AMORTIZATION }
  //   ],
  //
  //   isRequired: true,
  //   after: "MNTH",
  //   isNumber: true,
  //   isInt: true,
  //   label: "Number of Months",
  //   labelI18n: "HOUSEHOLD.ASSETS.DEBT.NUMBER_OF_MONTHS.LABEL"
  // },
  // annualPayment: {
  //   isOnlyShow: true,
  //   isAdditional: true,
  //   condition: [
  //     { field: "loanType", value: optionsRepaymentValues.AMORTIZATION }
  //   ],
  //   calculate: { isDebtAnnualPayAmortization: true },
  //
  //   isAmount: true,
  //   labelCard: "Annual payment",
  //   labelI18nCard: "HOUSEHOLD.ASSETS.DEBT.ANNUAL_PAYMENT.LABEL"
  // },
  // frequency: {
  //   isMain: true,
  //
  //   condition: [
  //     { field: "loanType", value: optionsRepaymentValues.FIXED_PAYMENTS }
  //   ],
  //   calculate: { isDebtRemainingFixed: true },
  //
  //   isSelect: true,
  //   options: optionsPaidFrequencyMQY,
  //   isRequired: true,
  //   labelCard: "Remaining Debt",
  //   labelI18nCard: "HOUSEHOLD.ASSETS.DEBT.REMAINING_DEBT.LABEL",
  //   label: "Frequency",
  //   labelI18n: "HOUSEHOLD.ASSETS.DEBT.FREQUENCY.LABEL"
  // },
  // periodsRemaining: {
  //   isAdditional: true,
  //   condition: [
  //     { field: "loanType", value: optionsRepaymentValues.FIXED_PAYMENTS }
  //   ],
  //   calculate: { isDebtAnnualPayFixed: true },
  //
  //   isRequired: true,
  //   isNumber: true,
  //   isInt: true,
  //
  //   labelCard: "Annual payment",
  //   labelI18nCard: "HOUSEHOLD.ASSETS.DEBT.ANNUAL_PAYMENT.LABEL",
  //   label: "Periods Remaining",
  //   labelI18n: "HOUSEHOLD.ASSETS.DEBT.PERIODS_REMAINING.LABEL"
  // },
  // periodicPayment: {
  //   condition: [
  //     { field: "loanType", value: optionsRepaymentValues.FIXED_PAYMENTS }
  //   ],
  //   isRequired: true,
  //   isAmount: true,
  //   isNumber: true,
  //   label: "Periodic Payment",
  //   labelI18n: "HOUSEHOLD.ASSETS.DEBT.PERIODIC_PAYMENT.LABEL"
  // }
};
export const repeatableExport = { ...repeatableSchemeGeneral };
const repeatableAdditional = {
  loanType: repeatableSchemeGeneral.loanType,
  term: repeatableSchemeGeneral.term,
  interestRate: repeatableSchemeGeneral.interestRate,
  annualAmount: repeatableSchemeGeneral.annualAmount,
  monthlyPayment: repeatableSchemeGeneral.monthlyPayment
};

export const designScheme = {
  [ENTITIES.ENTITY_HOME]: {
    icon: "IconHome",
    color: "green",
    title: "Home",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.HOME"
  },

  [ENTITIES.ENTITY_VEHICLE]: {
    icon: "IconVehicle",
    color: "green",
    title: "Vehicle",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.VEHICLE"
  },

  [ENTITIES.ENTITY_CREDIT_CARD]: {
    icon: "IconCreditCard",
    color: "green",
    title: "Credit Card",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.CREDIT_CARD"
  },

  [ENTITIES.ENTITY_STUDENT]: {
    icon: "IconStudent",
    color: "green",
    title: "Student Loan",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.STUDENT_LOAN"
  },

  [ENTITIES.ENTITY_MEDICAL]: {
    icon: "IconMedical",
    color: "green",
    title: "Medical",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.MEDICAL"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "Collective Debt",
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.TILE.COLLECTIVE_DEBT"
  }
};

//inner: ENTITY_ADD_401K
export const repeatableForUse = {
  description: {
    ...repeatableSchemeGeneral.description,
    isCardLink: DEBT,
    label: "Debt",
    labelI18n: "HOUSEHOLD.ASSETS.USE_DEBT.DESCRIPTION.LABEL"
  },
  debtRemainingAmortizationInterest: {
    ...repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    isMain: false,
    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ],
        inner: "debt"
      }
    ],
    calculate: { isDebtRemainingAmortizationInterest: true, inner: "debt" }
  },
  debtRemainingMonthly: {
    ...repeatableSchemeGeneral.debtRemainingMonthly,
    isMain: false,
    condition: [
      {
        field: "loanType",
        value: optionsRepaymentValues.MONTHLY_AMOUNT,
        inner: "debt"
      }
    ],
    calculate: { isDebtRemainingMonthly: true, inner: "debt" }
  },
  loanAmount: repeatableSchemeGeneral.loanAmount,
  loanDate: repeatableSchemeGeneral.loanDate,
  loanType: repeatableSchemeGeneral.loanType,
  term: {
    ...repeatableSchemeGeneral.term,
    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ],
        inner: "debt"
      }
    ]
  },
  interestRate: {
    ...repeatableSchemeGeneral.interestRate,
    condition: [
      {
        field: "loanType",
        valueIn: [
          optionsRepaymentValues.AMORTIZATION,
          optionsRepaymentValues.INTEREST_ONLY
        ],
        inner: "debt"
      }
    ]
  },
  annualAmount: {
    ...repeatableSchemeGeneral.annualAmount,
    calculate: { isAnnualPaymentDebt: true, inner: "debt" }
  },
  monthlyPayment: {
    ...repeatableSchemeGeneral.monthlyPayment,
    condition: [
      {
        field: "loanType",
        value: optionsRepaymentValues.MONTHLY_AMOUNT,
        inner: "debt"
      }
    ]
  }
};

export const ENTITIES_ADDITIONAL = {
  // [ENTITY_HOME]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
  // [ENTITY_VEHICLE]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
  // [ENTITY_CREDIT_CARD]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
  // [ENTITY_STUDENT]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
  // [ENTITY_MEDICAL]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
  // [ENTITY_OTHER]: {
  //   [ENTITY_ADD_REPAYMENT]: {
  //     ...repeatableAdditional
  //   }
  // },
};

export const formScheme = {
  [ENTITIES.ENTITY_HOME]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.HOME.DESCRIPTION.LABEL"
    },
    assetId: {
      isAdditional: true,
      isSecondary: true,
      isFullWidth: true,

      isAdditionalAsset: "homes",
      additionalAssetField: "description",
      fieldCustom: "assetId",
      // additionalAssetField: "type",
      optionsCustomAsset: "homes",

      // isRequired: true,
      isSelect: true,
      isShowNoneOption: "None",

      additionSave: {
        collection: COLLECTION_USE_ASSETS,
        globalType: TYPE_USE_ASSETS,
        field: "debtId"
      },

      isCardLink: ASSETS_OWNED_USE,
      label: "Asset",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.HOME.ASSET.LABEL"
    },
    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },
  [ENTITIES.ENTITY_VEHICLE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.VEHICLE.DESCRIPTION.LABEL"
    },
    assetId: {
      isAdditional: true,
      isSecondary: true,
      isFullWidth: true,

      isAdditionalAsset: "vehicles",
      additionalAssetField: "description",
      fieldCustom: "assetId",
      optionsCustomAsset: "vehicles",

      // isRequired: true,
      isSelect: true,
      isShowNoneOption: "None",

      additionSave: {
        collection: COLLECTION_USE_ASSETS,
        globalType: TYPE_USE_ASSETS,
        field: "id"
      },

      isCardLink: ASSETS_OWNED_USE,
      label: "Asset",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.VEHICLE.ASSET.LABEL"
    },

    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },
  [ENTITIES.ENTITY_CREDIT_CARD]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.CARD.DESCRIPTION.LABEL"
    },
    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },
  [ENTITIES.ENTITY_STUDENT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.STUDENT.DESCRIPTION.LABEL"
    },
    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },
  [ENTITIES.ENTITY_MEDICAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.MEDICAL.DESCRIPTION.LABEL"
    },
    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },

  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.OTHER.DESCRIPTION.LABEL"
    },
    assetId: {
      isAdditional: true,
      isSecondary: true,
      isFullWidth: true,

      isAdditionalAsset: "others",
      additionalAssetField: "description",
      fieldCustom: "assetId",
      optionsCustomAsset: "others",

      // isRequired: true,
      isSelect: true,
      isShowNoneOption: "None",

      additionSave: {
        collection: COLLECTION_USE_ASSETS,
        globalType: TYPE_USE_ASSETS,
        field: "id"
      },

      isCardLink: ASSETS_OWNED_USE,
      label: "Asset",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.OTHER.ASSET.LABEL"
    },
    debtRemainingAmortizationInterest:
      repeatableSchemeGeneral.debtRemainingAmortizationInterest,
    debtRemainingMonthly: repeatableSchemeGeneral.debtRemainingMonthly,
    loanAmount: repeatableSchemeGeneral.loanAmount,
    loanDate: repeatableSchemeGeneral.loanDate,
    ...repeatableAdditional
  },

  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL.DESCRIPTION.LABEL"
    },
    loanAmount: {
      ...repeatableSchemeGeneral.loanAmount,
      isMain: true,
      label: "Total Debt",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.TOTAL_DEBT.LABEL"
    },
    interestRate: {
      isRequired: true,
      prefix: "%",
      isNumber: true,
      min: 0,
      label: "Interest Rate",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL.INTEREST_RATE.LABEL"
    },
    annualAccrual: {
      isOnlyShow: true,
      isAdditional: true,
      calculate: { isDebtAnnualAccrual: true },
      label: "Additional Annual Accrual",
      labelI18n: "HOUSEHOLD.ASSETS.DEBT.ANNUAL_ACCRUAL.LABEL"
    }
  }
};

// deprecated
// addAdditionalConfiguration(ENTITIES, ENTITIES_ADDITIONAL, GLOBAL_TYPE_USE_ASSETS);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.DEBT",
        title: "Debt"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.TITLE",
      title: "What you owe for what you own"
    },
    caption: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.CAPTION",
      title: "The debt payments you carry for the assets you use"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.EMPTY",
      title: "You have no money owed,\nplease add your money owed"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.H2",
      title: "Dept"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.TOAST.ADDED",
      title: "Debt Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.TOAST.REMOVED",
      title: "Debt Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.TOAST.SAVED",
      title: "Debt Saved"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.SIDE_MENU_TITLE",
      title: "Add Debt"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.SIDE_MENU_TITLE.EDIT",
      title: "Edit Debt"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.SUBTITLE",
      title: "Select the things for which you owe"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL_GROUP",
      title: "Group Of Dept",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Dept”",
      captionI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.BUTTON.SAVE_DEBT",
      title: "Save Debt"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.DEBT.BUTTON.ADD_DEBT",
      title: "+ Add Debt"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.ASSETS.DEBT.WHY.TITLE",
    textI18n: "HOUSEHOLD.ASSETS.DEBT.WHY.ALL_TEXT"
  }
};
