export const questions = {
  1: {
    i18nId: "QUIZ.QUESTION1",
    defaultMessage: "I have some money I saved that I am not sure to do with",
    name: "savedMoney"
  },
  2: {
    i18nId: "QUIZ.QUESTION2",
    defaultMessage: "I want to plan for major life events (e.g. childbirth)",
    name: "planEvents"
  },
  3: {
    i18nId: "QUIZ.QUESTION3",
    defaultMessage:
      "I want to be well prepared for an unexpected turn for events (e.g. loss of work, death)",
    name: "unexpectedEvents"
  },
  4: {
    i18nId: "QUIZ.QUESTION4",
    defaultMessage:
      "I need help planning my retirement or building passive income",
    name: "needPlanning"
  },
  5: {
    i18nId: "QUIZ.QUESTION5",
    defaultMessage: "I need general advice on how to manage my money",
    name: "needAdvice"
  }
};

const questionState = {};
Object.keys(questions).forEach(key => {
  questionState[questions[key].name] = false;
});
export const questionInitialState = questionState;
