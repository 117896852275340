import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconOperating = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M5 2C3.30078 2 2 3.30078 2 5C2 6.69922 3.30078 8 5 8C6.69922 8 8 6.69922 8 5C8 3.30078 6.69922 2 5 2ZM19 2C17.3438 2 16 3.34375 16 5C16 6.65625 17.3438 8 19 8C20.6562 8 22 6.65625 22 5C22 3.34375 20.6562 2 19 2ZM12 2.5V4H9V6H12V7.5L14.9062 5L12 2.5ZM5 3.59375C5.80078 3.59375 6.40625 4.19922 6.40625 5C6.40625 5.80078 5.80078 6.40625 5 6.40625C4.19922 6.40625 3.59375 5.80078 3.59375 5C3.59375 4.19922 4.19922 3.59375 5 3.59375ZM18 9V12H16.5L19 14.9062L21.5 12H20V9H18ZM5 9.09375L2.5 12H4V15H6V12H7.5L5 9.09375ZM2 16V22H8V16H2ZM19 16L16 19L19 22L22 19L19 16Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconOperating.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconOperating;
