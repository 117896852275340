import React, { Component } from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";

import { optionsFillingStatusValues } from "./WizardFamily";

import subYears from "date-fns/subYears";
import { formatMoneyIntWithSign } from "../../../lib/helpers";
import {
  optionsEmployedStatusNames,
  optionsEmployedStatusValues
} from "../../HouseholdMembers/HouseholdMembers";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Cell, Pie, PieChart } from "recharts";
import { colors } from "../../../constants/styles";
import { INVESTMENT_RENT_LIMIT } from "../../../constants/wizard";
import { taxPropertyValue } from "../../../constants/taxesAndDashboard";
import { nFormatter } from "../../Dashboard/Charts";

const familyManWorkImg = (
  <img
    src={require("assets/img/icon/wizard/QuizManWork.png")}
    width={46}
    height={113}
    alt=""
  />
);
const familyManImg = (
  <img
    src={require("assets/img/icon/wizard/QuizMan.png")}
    width={46}
    height={113}
    alt=""
  />
);
const FamilyChildImg = ({ classes }) => (
  <img
    className={classes.WizardFinishChildrenImg}
    src={require("assets/img/icon/wizard/QuizChild.png")}
    width={32}
    height={78}
    alt=""
  />
);
const familySpouseWorkImg = (
  <img
    src={require("assets/img/icon/wizard/QuizSpouseWork.png")}
    width={58}
    height={113}
    alt=""
  />
);
const familySpouseImg = (
  <img
    src={require("assets/img/icon/wizard/QuizSpouse.png")}
    width={58}
    height={113}
    alt=""
  />
);

const amountFormatter = val => {
  const digits = Math.abs(val) > 1000000 ? 1 : 0;
  return `$${nFormatter(val, digits)}`;
};

class WizardFinish extends Component {
  _calcAssets = () => {};

  calcSavings = () => {};

  calcHouses = () => {};

  calcDebt = () => {};

  _renderLeftPanel = () => {
    const { classes, fullState } = this.props;

    const filling = fullState.family.taxesStatus;

    const birthday = subYears(Date.now(), fullState.family.age);
    const ageEl = birthday.getFullYear();

    const fillingEl = (
      <FormattedMessage
        id={optionsFillingStatusValues[filling].nameI18n}
        defaultMessage={optionsFillingStatusValues[filling].name}
      />
    );

    return (
      <Grid className="wizard-grid wizard-grid--left" item xs={12} lg={6}>
        <div className={classes.wizardTotalTitle}>
          <FormattedMessage
            id="WIZARD.FINISH.HOUSEHOLD"
            defaultMessage="Household"
          />
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.BIRTH"
              defaultMessage="Birth year:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>{ageEl}</div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.NUMBER_DEPENDENTS"
              defaultMessage="Number of dependents:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {fullState.family.dependents}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.FILING"
              defaultMessage="Filing:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>{fillingEl}</div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.EMPLOYMENT"
              defaultMessage="Employment:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            <FormattedMessage
              id={
                optionsEmployedStatusNames[fullState.family.employmentStatus]
                  .nameI18n
              }
              defaultMessage={
                optionsEmployedStatusNames[fullState.family.employmentStatus]
                  .name
              }
            />
          </div>
        </div>

        {filling === "married" && (
          <div className={classes.wizardTotalItem}>
            <div className={classes.wizardTotalItemLabel}>
              <FormattedMessage
                id="WIZARD.FINISH.SPOUSE_EMPLOYMENT"
                defaultMessage="Spouse employment:"
              />
            </div>
            <div className={classes.wizardTotalItemValue}>
              <FormattedMessage
                id={
                  optionsEmployedStatusNames[fullState.family.spouseStatus]
                    .nameI18n
                }
                defaultMessage={
                  optionsEmployedStatusNames[fullState.family.spouseStatus].name
                }
              />
            </div>
          </div>
        )}

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.TOTAL_SALARY"
              defaultMessage="Total salary:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.money.totalSalary)}
          </div>
        </div>

        <div
          className={`${classes.wizardTotalItem} ${classes.wizardTotalItemDivider}`}
        >
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.ADDITIONAL_INCOME"
              defaultMessage="Additional income:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.money.collectingRents)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.PRIMARY_RESIDENCE"
              defaultMessage="Primary residence:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {fullState.money.primaryResidence === "own" ? (
              <FormattedMessage
                id="WIZARD.MONEY.OPTIONS.RESIDENCE.OWN"
                defaultMessage="Own"
              />
            ) : (
              <FormattedMessage
                id="WIZARD.MONEY.OPTIONS.RESIDENCE.RENT"
                defaultMessage="Rent"
              />
            )}
          </div>
        </div>

        {fullState.money.primaryResidence === "own" && (
          <div className={classes.wizardTotalItem}>
            <div className={classes.wizardTotalItemLabel}>
              <FormattedMessage
                id="WIZARD.FINISH.PROPERTY_TAX"
                defaultMessage="Property tax:"
              />
            </div>
            <div className={classes.wizardTotalItemValue}>
              {formatMoneyIntWithSign(fullState.money.propertyTax)}
            </div>
          </div>
        )}

        {fullState.money.primaryResidence === "rent" && (
          <div className={classes.wizardTotalItem}>
            <div className={classes.wizardTotalItemLabel}>
              <FormattedMessage
                id="WIZARD.FINISH.MONTHLY_RENT"
                defaultMessage="Monthly rent:"
              />
            </div>
            <div className={classes.wizardTotalItemValue}>
              {formatMoneyIntWithSign(fullState.money.monthlyRent)}
            </div>
          </div>
        )}

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.HOA_INSURANCE_MAINTENANCE"
              defaultMessage="HOA, insurance and maintenance:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.money.maintenance)}
          </div>
        </div>

        {/*<div*/}
        {/*  className={`${classes.wizardTotalItem} ${classes.wizardTotalItemDivider}`}*/}
        {/*>*/}
        {/*  <div className={classes.wizardTotalItemLabel}>*/}
        {/*    <FormattedMessage*/}
        {/*      id="WIZARD.FINISH.UTILITIES"*/}
        {/*      defaultMessage="Utilities:"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className={classes.wizardTotalItemValue}>*/}
        {/*    {formatMoneyIntWithSign(fullState.money.utilities)}*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.VACATION_SPEND"
              defaultMessage="Vacation spend:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.money.vacation)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.MONTHLY_EXPENSES"
              defaultMessage="Monthly expenses:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(
              ((fullState.money.primaryResidence === "own"
                ? +fullState.money.propertyTax
                : +fullState.money.monthlyRent) +
                +fullState.money.maintenance +
                // +fullState.money.utilities +
                +fullState.money.vacation) /
                12 || 0
            )}
          </div>
        </div>
      </Grid>
    );
  };

  _renderAssets = () => {
    const { classes, fullState } = this.props;

    return (
      <>
        <div className={classes.wizardTotalTitle}>
          <FormattedMessage id="WIZARD.FINISH.ASSETS" defaultMessage="Assets" />
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.HOMES_VEHICLES"
              defaultMessage="Homes & vehicles:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.homesVehicles)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.RETIREMENT_FUNDS"
              defaultMessage="Retirement funds (e.g. 401K, IRA):"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.retirementFunds)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.SAVINGS"
              defaultMessage="Savings:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.purposelySaved)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.DEATH_BENEFIT"
              defaultMessage="Death benefit:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.deathBenefit)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.INVESTMENTS"
              defaultMessage="Investments:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.invested)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage id="WIZARD.FINISH.CASH" defaultMessage="Cash:" />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assets.checkingOrSaving)}
          </div>
        </div>
      </>
    );
  };

  _renderLiabilities = () => {
    const { classes, fullState } = this.props;

    return (
      <>
        <div
          className={`${classes.wizardTotalTitle} ${classes.wizardTotalTitleDivider}`}
        >
          <FormattedMessage
            id="WIZARD.FINISH.LIABILITIES"
            defaultMessage="Liabilities"
          />
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.MORTGAGE"
              defaultMessage="Mortgage"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assetsDebt.remains)}
          </div>
        </div>

        <div className={classes.wizardTotalItem}>
          <div className={classes.wizardTotalItemLabel}>
            <FormattedMessage
              id="WIZARD.FINISH.HIGH_INTEREST_DEBT"
              defaultMessage="High-interest debt:"
            />
          </div>
          <div className={classes.wizardTotalItemValue}>
            {formatMoneyIntWithSign(fullState.assetsDebt.interestDebt)}
          </div>
        </div>
      </>
    );
  };

  _renderChart = () => {
    const { classes, fullState } = this.props;
    const data = [{ name: "temp", value: 400 }];

    const money = fullState.money;
    const approximation = fullState.approximation;
    const salary = +money.totalSalary;
    const incomeRent = +money.collectingRents;

    const annualIncome = salary + incomeRent;
    const propertyTax = +fullState.money.propertyTax
      ? fullState.money.propertyTax
      : 0;

    let annualExpenses =
      (fullState.money.primaryResidence === "own"
        ? propertyTax || 0
        : (+fullState.money.monthlyRent || 0) * 12) +
      (+fullState.money.maintenance || 0) +
      // +fullState.money.utilities * 12 +
      (+fullState.money.vacation || 0);

    const totalGroceries =
      ((+approximation.groceries || 0) +
        (+approximation.diningOut || 0) +
        (+approximation.dependentCare || 0) +
        (+approximation.medicalAndHealth || 0) +
        (+approximation.transportation || 0) +
        (+approximation.insurance || 0) +
        (+approximation.other || 0)) *
      12;
    annualExpenses += totalGroceries;

    // Family
    const family = fullState.family;
    const familyImgArr = [];

    if (family.employmentStatus === optionsEmployedStatusValues.UNEMPLOYED) {
      familyImgArr.push(familyManImg);
    } else {
      familyImgArr.push(familyManWorkImg);
    }

    if (family.taxesStatus === "married") {
      if (family.spouseStatus === optionsEmployedStatusValues.UNEMPLOYED) {
        familyImgArr.push(familySpouseImg);
      } else {
        familyImgArr.push(familySpouseWorkImg);
      }
    }

    let childrenImgArr = [];
    if (+family.dependents) {
      childrenImgArr = Array(+family.dependents).fill(
        <FamilyChildImg classes={classes} />
      );
    }

    const retirement = +fullState.assets.retirementFunds;
    const college = +fullState.assets.purposelySaved;
    const totalSavings = retirement + college;

    const mortgage = +fullState.assetsDebt.remains;
    const loans = +fullState.assetsDebt.interestDebt;
    const otherDebt = +fullState.assetsDebt.otherDebt;
    const totalDebt = mortgage + loans + otherDebt;

    const houseValue = +fullState.money.propertyTax / taxPropertyValue || 0;
    const propertyValue = +fullState.assets.homesVehicles || 0;
    const totalProperty =
      propertyValue > houseValue ? propertyValue : houseValue;

    const totalInvesments =
      +fullState.assets.checkingOrSaving +
      +fullState.assets.invested +
      +fullState.assets.deathBenefit;

    let totalAmount =
      totalProperty + totalInvesments + +totalSavings - totalDebt;

    const chart = (
      <PieChart width={380} height={380}>
        <Pie
          dataKey="value"
          data={data}
          startAngle={30}
          endAngle={-270}
          innerRadius={150}
          outerRadius={150}
        >
          <Cell
            fill={colors.yellow}
            stroke={colors.yellow}
            strokeWidth={16}
            strokeLinejoin="round"
            strokeLinecap="round"
          />
        </Pie>

        <Pie
          dataKey="value"
          data={data}
          startAngle={30}
          endAngle={-270}
          innerRadius={180}
          outerRadius={180}
        >
          <Cell
            fill={colors.green}
            stroke={colors.green}
            strokeWidth={16}
            strokeLinejoin="round"
            strokeLinecap="round"
          />
        </Pie>
      </PieChart>
    );

    const dataAnnual = (
      <div className={classes.WizardFinishMainChartDataAnnual}>
        <div>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.ANNUAL_INCOME"
            defaultMessage="Annual income"
          />{" "}
          <span className={classes.WizardFinishGraphTotalAmount}>
            {amountFormatter(annualIncome)}
          </span>
        </div>

        <div>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.ANNUAL_EXPENSES"
            defaultMessage="Annual expenses"
          />{" "}
          <span className={classes.WizardFinishGraphTotalAmount}>
            {amountFormatter(annualExpenses)}
          </span>
        </div>
      </div>
    );

    const dataFamily = (
      <div>
        <div className={classes.WizardFinishFamilyLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.WEALTH"
            defaultMessage="Wealth"
          />
        </div>

        <div className={classes.WizardFinishFamilyTotal}>
          {formatMoneyIntWithSign(totalAmount)}
        </div>
      </div>
    );

    const dataFamilyContent = (
      <div className={classes.WizardFinishMainChartData}>
        <div className={classes.WizardFinishFamilyLabelImg}>
          {familyImgArr}
          <div className={classes.WizardFinishFamilyChildrenImg}>
            {childrenImgArr}
          </div>
        </div>
        {/*{familyImg}*/}

        {dataFamily}
      </div>
    );

    return (
      <div className={classes.WizardFinishMainChartContainer}>
        {dataAnnual}

        {dataFamilyContent}

        {chart}
      </div>
    );
  };

  _renderInvest = () => {
    const { classes, fullState } = this.props;

    const cash = +fullState.assets.checkingOrSaving;
    const invested = +fullState.assets.invested;
    const lifeInsurance = +fullState.assets.deathBenefit;

    const total = cash + invested + lifeInsurance;
    // const total = cash + invested;

    const items = [
      {
        key: "cash",
        content: (
          <>
            <Icons.IconBalances width={32} height={32} color={"green"} />
            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.CASH"
                defaultMessage="Cash"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(cash)}
            </div>
          </>
        ),
        value: cash
      },
      {
        key: "invested",
        content: (
          <>
            <Icons.IconInvestment width={32} height={32} color={"green"} />
            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.STOCKS"
                defaultMessage="Stocks"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(invested)}
            </div>
          </>
        ),
        value: invested
      }
      // {
      //   key: "lifeInsurance",
      //   content: (
      //     <>
      //       <Icons.IconUmbrella width={32} height={32} color={"green"} />
      //       <div className={classes.WizardFinishGraphItemTitle}>
      //         <FormattedMessage
      //           id="WIZARD.FINISH.LABEL.LIFE_INS"
      //           defaultMessage="Life ins"
      //         />
      //       </div>
      //       <div className={classes.WizardFinishGraphItemAmount}>
      //         {amountFormatter(lifeInsurance)}
      //       </div>
      //     </>
      //   ),
      //   value: lifeInsurance
      // }
    ];

    const graph = (
      <div className={classes.WizardFinishGraph}>
        {items
          .sort((a, b) => b.value - a.value)
          .map((el, index) => {
            return (
              <div
                key={el.key}
                className={`${classes.WizardFinishGraphItem} ${
                  classes[`WizardFinishGraphItem${index}`]
                }`}
              >
                {el.content}
              </div>
            );
          })}
      </div>
    );

    const totalContent = (
      <div className={classes.WizardFinishGraphTotal}>
        <div className={classes.WizardFinishGraphTotalLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.INVESTABLE_ASSETS"
            defaultMessage="Investable assets"
          />
        </div>
        <div className={classes.WizardFinishGraphTotalAmount}>
          {formatMoneyIntWithSign(total)}
        </div>
      </div>
    );

    const content = (
      <div className={classes.WizardFinishGraphContainer2}>
        {graph}

        {totalContent}
      </div>
    );

    return content;
  };

  _renderDebt = () => {
    const { classes, fullState } = this.props;

    const mortgage = +fullState.assetsDebt.remains;
    const loans = +fullState.assetsDebt.interestDebt;
    const otherDebt = +fullState.assetsDebt.otherDebt;

    const total = mortgage + loans + otherDebt;

    const items = [
      {
        key: "mortgage",
        content: (
          <>
            <Icons.IconMoneyOwned width={32} height={32} color={"yellow"} />
            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.MORTGAGE"
                defaultMessage="Mortgage"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(mortgage)}
            </div>
          </>
        ),
        value: mortgage
      },
      {
        key: "loans",
        content: (
          <>
            <Icons.IconDeferredComp width={32} height={32} color={"yellow"} />

            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.LOAN"
                defaultMessage="Loan"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(loans)}
            </div>
          </>
        ),
        value: loans
      },
      {
        key: "otherDebt",
        content: (
          <>
            <Icons.IconStudent width={32} height={32} color={"yellow"} />

            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.OTHER_DEBT"
                defaultMessage="Student"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(otherDebt)}
            </div>
          </>
        ),
        value: otherDebt
      }
    ];

    const graph = (
      <div
        className={`${classes.WizardFinishGraph} ${classes.WizardFinishGraphYellow}`}
      >
        {items
          .sort((a, b) => a.value - b.value)
          .map((el, index) => {
            return (
              <div
                key={el.key}
                className={`${classes.WizardFinishGraphItem} ${
                  classes.WizardFinishGraphItemYellow
                } ${classes[`WizardFinishGraphItem${2 - index}`]}`}
              >
                {el.content}
              </div>
            );
          })}
      </div>
    );

    const totalContent = (
      <div className={classes.WizardFinishGraphTotal}>
        <div className={classes.WizardFinishGraphTotalLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.DEBT"
            defaultMessage="Debt"
          />
        </div>
        <div className={classes.WizardFinishGraphTotalAmount}>
          {formatMoneyIntWithSign(total)}
        </div>
      </div>
    );

    const content = (
      <div className={classes.WizardFinishGraphContainer}>
        {graph}

        {totalContent}
      </div>
    );

    return content;
  };

  _renderSavings = () => {
    const { classes, fullState } = this.props;

    const retirement = +fullState.assets.retirementFunds;
    const college = +fullState.assets.purposelySaved;

    const total = retirement + college;

    const items = [
      {
        key: "retirement",
        content: (
          <>
            <Icons.IconSunLounger width={32} height={32} color={"brand"} />
            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.RETIREMENT"
                defaultMessage="Retirement"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(retirement)}
            </div>
          </>
        ),
        value: retirement
      },
      {
        key: "college",
        content: (
          <>
            <Icons.IconStudent width={32} height={32} color={"brand"} />
            <div className={classes.WizardFinishGraphItemTitle}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL.COLLEGE"
                defaultMessage="College"
              />
            </div>
            <div className={classes.WizardFinishGraphItemAmount}>
              {amountFormatter(college)}
            </div>
          </>
        ),
        value: college
      }
    ];

    const contentBody = (
      <div
        className={`${classes.WizardFinishGraph} ${classes.WizardFinishGraphBrand} ${classes.WizardFinishGraphBottom}`}
      >
        {items
          .sort((a, b) => b.value - a.value)
          .map((el, index) => {
            return (
              <div
                key={el.key}
                className={`${classes.WizardFinishGraphItem} ${
                  classes.WizardFinishGraphItemBrand
                } ${classes[`WizardFinishGraphItemBottom${index}`]}`}
              >
                {el.content}
              </div>
            );
          })}
      </div>
    );

    const totalContent = (
      <div className={classes.WizardFinishGraphTotal}>
        <div className={classes.WizardFinishGraphTotalLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.SAVINGS"
            defaultMessage="Your savings"
          />
        </div>
        <div className={classes.WizardFinishGraphTotalAmount}>
          {formatMoneyIntWithSign(total)}
        </div>
      </div>
    );

    const content = (
      <div
        className={classes.WizardFinishGraphContainer2}
        style={{ marginLeft: -60 }}
      >
        {contentBody}

        {totalContent}
      </div>
    );

    return content;
  };

  _renderPriorities = () => {
    const { classes, fullState } = this.props;

    const needs = fullState.needs;

    const contentBody =
      needs.savedMoney ||
      needs.needPlanning ||
      needs.planEvents ||
      needs.needAdvice ||
      needs.unexpectedEvents ? (
        <>
          {!!needs.savedMoney && (
            <div className={classes.WizardFinishNeeds}>
              <div className={classes.WizardFinishCheck}>
                <Icons.IconCheck color={"brand"} />
              </div>
              <FormattedMessage id="WIZARD.FINISH.LABEL.GROW_MONEY" />
            </div>
          )}
          {!!needs.needPlanning && (
            <div className={classes.WizardFinishNeeds}>
              <div className={classes.WizardFinishCheck}>
                <Icons.IconCheck color={"brand"} />
              </div>
              <FormattedMessage id="WIZARD.FINISH.LABEL.INDEPENDENCE" />
            </div>
          )}
          {!!needs.planEvents && (
            <div className={classes.WizardFinishNeeds}>
              <div className={classes.WizardFinishCheck}>
                <Icons.IconCheck color={"brand"} />
              </div>
              <FormattedMessage id="WIZARD.FINISH.LABEL.PROTECT" />
            </div>
          )}
          {!!needs.needAdvice && (
            <div className={classes.WizardFinishNeeds}>
              <div className={classes.WizardFinishCheck}>
                <Icons.IconCheck color={"brand"} />
              </div>
              <FormattedMessage id="WIZARD.FINISH.LABEL.CONTROL" />
            </div>
          )}
          {!!needs.unexpectedEvents && (
            <div className={classes.WizardFinishNeeds}>
              <div className={classes.WizardFinishCheck}>
                <Icons.IconCheck color={"brand"} />
              </div>
              <FormattedMessage id="WIZARD.FINISH.LABEL.SAVING" />
            </div>
          )}
        </>
      ) : (
        <FormattedMessage
          id="WIZARD.FINISH.LABEL.NO_PRIORITIES"
          defaultMessage="You have no priorities"
        />
      );

    const totalContent = (
      <div
        className={`${classes.WizardFinishGraphTotal} ${classes.WizardFinishGraphTotalPriorities}`}
      >
        <div className={classes.WizardFinishGraphTotalLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.PRIORITIES"
            defaultMessage="Your priorities"
          />
        </div>
      </div>
    );

    const content = (
      <div
        className={classes.WizardFinishGraphContainer}
        style={{ marginLeft: 100 }}
      >
        {contentBody}

        {totalContent}
      </div>
    );

    return content;
  };

  _renderHouses = () => {
    const { classes, fullState } = this.props;

    const moneyState = fullState.money;
    const assetsState = fullState.assets;

    const investmentCount = Math.ceil(
      moneyState.collectingRents / INVESTMENT_RENT_LIMIT
    );

    const houseCount = moneyState.propertyTax ? 1 : 0;

    const contentBody = (
      <div
        className={`${classes.WizardFinishGraph} ${classes.WizardFinishGraphDivider}`}
        style={{ height: "auto" }}
      >
        <div
          className={`${classes.WizardFinishGraphItem} ${classes.WizardFinishGraphItemNoBg}`}
        >
          <Icons.IconHome width={32} height={32} color={"brand"} />

          <div className={classes.WizardFinishGraphItemTitle}>
            <FormattedMessage
              id="WIZARD.FINISH.LABEL.RESIDENCE"
              defaultMessage="Residence"
            />
          </div>

          <div className={classes.WizardFinishGraphItemAmount}>
            {houseCount}x
          </div>
        </div>

        <div
          className={`${classes.WizardFinishGraphItem} ${classes.WizardFinishGraphItemNoBg}`}
        >
          <Icons.IconMoneyOwned width={32} height={32} color={"brand"} />

          <div className={classes.WizardFinishGraphItemTitle}>
            <FormattedMessage
              id="WIZARD.FINISH.LABEL.INVESTMENT_HOMES"
              defaultMessage="Investment homes"
            />
          </div>

          <div className={classes.WizardFinishGraphItemAmount}>
            {investmentCount}x
          </div>
        </div>
      </div>
    );

    const primaryValue = +moneyState.propertyTax / taxPropertyValue || 0;
    const propertyValue = +assetsState.homesVehicles;

    const propertyTotal =
      primaryValue < propertyValue ? propertyValue : primaryValue;
    const totalContent = (
      <div className={classes.WizardFinishGraphTotal}>
        <div className={classes.WizardFinishGraphTotalLabel}>
          <FormattedMessage
            id="WIZARD.FINISH.LABEL.YOUR_PROPERTY"
            defaultMessage="Your property"
          />
        </div>
        <div className={classes.WizardFinishGraphTotalAmount}>
          {formatMoneyIntWithSign(propertyTotal)}
        </div>
      </div>
    );

    const content = (
      <div
        className={classes.WizardFinishGraphContainer}
        style={{ marginRight: 60, width: 265 }}
      >
        {contentBody}
        {totalContent}
      </div>
    );

    return content;
  };

  _renderContent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.WizardFinishContainer}>
        <div className={classes.WizardFinishSubContainer}>
          <div className={classes.WizardFinishInvest}>
            {this._renderInvest()}
          </div>

          <div className={classes.WizardFinishChart}>{this._renderChart()}</div>

          <div className={classes.WizardFinishDebt}>{this._renderDebt()}</div>
        </div>

        <div className={classes.WizardFinishSubContainer}>
          <div className={classes.WizardFinishPriorities}>
            {this._renderPriorities()}
          </div>
          <div className={classes.WizardFinishSavings}>
            {this._renderSavings()}
          </div>
          <div className={classes.WizardFinishHouses}>
            {this._renderHouses()}
          </div>
        </div>
      </div>
    );
  };

  _renderContentOld = () => {
    return (
      <Grid
        item
        lg={12}
        container
        spacing={0}
        className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
      >
        {this._renderLeftPanel()}
        <Grid className="wizard-grid wizard-grid--right" item xs={12} lg={6}>
          {this._renderAssets()}

          {this._renderLiabilities()}
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      prevStep,
      handleChangeStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.FINISH.LABEL"
                defaultMessage="Assets and liabilities"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={7} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div
            className={`${classes.wizardHeader2} ${classes.wizardFinishHeader2} ${classes.wizardFinishHeader}`}
          >
            <FormattedMessage
              id="WIZARD.FINISH.HEADING2"
              defaultMessage="Here is what we’ve got so far."
            />
          </div>
          <div
            className={`${classes.wizardHeader3} ${classes.wizardFinishHeader}`}
          >
            <FormattedMessage
              id="WIZARD.FINISH.HEADING3"
              defaultMessage="If this look correct, click Finish"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {/*{this._renderContentOld()}*/}

            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            block={isMobile}
            style={{ width: 260 }}
            className={classes.wizardButtonWelcome}
            onClick={handleChangeStep(prevStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <Button
            ui
            block={isMobile}
            style={{ width: 260 }}
            className={classes.wizardButtonSummaryFinish}
            onClick={handleFinishAndSaveAssets}
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardFinish.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  fullState: PropTypes.any,
  handleInputChange: PropTypes.func,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardFinish;
