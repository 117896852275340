import { createSelector } from "reselect";

export const getProfileUidOrClientUid = props =>
  (props && (props.profile.isAdvisor && props.profileProvider)) ||
  props.auth.uid;

export const getProfileUidOrClientUidData = createSelector(
  getProfileUidOrClientUid,
  uid => uid
);
