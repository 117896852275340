// import React, { Component} from "react";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

import Statistic from "./Statistic";
import Charts, { nFormatter } from "./Charts";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { FormattedMessage } from "react-intl-v3";

import "components/AssetsCommon/Summary.scss";
import AccessForbidden from "../../routeHandlers/AccessForbidden";
import IconContainer from "../_common/IconContainer";
import {
  cloneObject,
  formatMoneyInt,
  formatMoneyIntWithSign
} from "../../lib/helpers";
import * as Icons from "components/_common/icons";
import ReactResizeDetector from "react-resize-detector";
import diffYears from "date-fns/differenceInCalendarYears";
import {
  DEFAULT_EXPECTED_RETIREMENT_AGE,
  MAX_AGE,
  MIN_CALC_AGE
} from "../../constants";
import { INFLATION, INFLATION_PERCENT } from "constants/taxesAndDashboard";
import { Dialog, withStyles, LinearProgress } from "@material-ui/core";

import { colors } from "../../constants/styles";

import { defaultDataAdvisor } from "../DashboardAdvisor/DashboardAdvisor";
import {
  ENTITIES as entitiesGoals,
  GLOBAL_TYPE_USE_ASSETS as globalTypeGoal
} from "../PlansGoals/formScheme";
import {
  ENTITIES as entitiesSavings,
  GLOBAL_TYPE_USE_ASSETS as globalTypeSavings
} from "../HouseholdAssetsSavings/formScheme";
import toast from "../_common/toast";
import {
  ENTITIES as entitiesUse,
  GLOBAL_TYPE_USE_ASSETS as globalTypeUse,
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalAssets,
  optionsValuesHome
} from "../HouseholdAssetsUse/formScheme";
import {
  ENTITIES as entitiesDebt,
  GLOBAL_TYPE_USE_ASSETS as globalTypeDebt
} from "../HouseholdDebt/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyIn } from "../HouseholdMoneyIn/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutAssets } from "../MoneyOutAssets/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutPersonal } from "../MoneyOutPersonal/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeEvents } from "../PlansEvents/formScheme";
import DashboardAdvisorOverall from "../DashboardAdvisor/DashboardAdvisorOverall";
import DashboardAdvisorCheckList from "../DashboardAdvisor/DashboardAdvisorCheckList";
import Operations from "../Operations/Operations";
import Events from "../PlansEvents/Events";
import AssetForm from "../AssetsCommon/AssetForm";
import { AddTile } from "../AssetsCommon/AssetCategoryCommon";
import "./Dashboard.scss";

import { valuesStatTypes } from "./CalcContainer.scheme";
import Button from "@material-ui/core/Button";
import {
  entitiesFull,
  entitiesScheme,
  globalTypes
} from "components/AssetsCommon/formSchemeFull";

import TooltipMUI from "@material-ui/core/Tooltip";

import HouseholdAssetsUse from "../HouseholdAssetsUse/HouseholdAssetsUse";
import HouseholdAssetsSavings from "../HouseholdAssetsSavings/HouseholdAssetsSavings";
import HouseholdAssetsInvested from "../HouseholdAssetsInvested/HouseholdAssetsInvested";
import HouseholdDebt from "../HouseholdDebt/HouseholdDebt";
import HouseholdAccounts from "../HouseholdAccounts/HouseholdAccounts";
import HouseholdMoneyIn from "../HouseholdMoneyIn/HouseholdMoneyIn";
import MoneyOutPersonal from "../MoneyOutPersonal/MoneyOutPersonal";
import MoneyOutAssets from "../MoneyOutAssets/MoneyOutAssets";
import MoneyOutOther from "../MoneyOutOther/MoneyOutOther";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { menuObjects } from "../../constants/menu";
import ButtonSideItem from "../_common/Button/ButtonSideItem";
import AssetCard from "../_common/AssetCard";
import ButtonCommon from "../_common/Button/ButtonCommon";
import DialogRemoveAsset from "../AssetsCommon/DialogRemoveAsset";
import DialogCommon from "../AssetsCommon/DialogCommon";
import DashboardActions from "./DashboardActions";
import DashboardGoals from "./DashboardGoals";
import { styles } from "./Dashboard.styles";

const categoriesComponents = {
  householdUse: HouseholdAssetsUse,
  householdSavings: HouseholdAssetsSavings,
  householdInvested: HouseholdAssetsInvested,
  householdDebt: HouseholdDebt,
  householdAccounts: HouseholdAccounts,
  householdMoneyIn: HouseholdMoneyIn,
  householdMoneyOutPersonal: MoneyOutPersonal,
  householdMoneyOutAssets: MoneyOutAssets,
  householdMoneyOutOther: MoneyOutOther
};

const subCategoryState = {
  moneyOutStatState: {
    taxes: "MoneyOutTaxesAll",
    savings: "MoneyOutTaxesAll"
  },
  assetsStatState: {
    cash: "AssetsDetail",
    investments: "AssetsDetail",
    retirement: "AssetsDetail"
  },
  taxCategoryStatState: {
    taxable: "TaxCategoryDetail",
    taxDeferred: "TaxCategoryDetail",
    taxAdvantage: "TaxCategoryDetail"
  }
};

const COLORS_PIE = [colors.blue, colors.green, colors.yellowLight, colors.red];
const STAT_HEADER_HEIGHT = 54;

const saveAllWhatIf = {
  text: (
    <FormattedMessage
      id="DASHBOARD.MAIN.WHAT_IF.SAVE_ALL.WARN"
      defaultMessage="Are you sure you want to save all What-if assets as Standard?"
    />
  ),
  title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
  okButton: <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
};

const eraseAllWhatIf = {
  text: (
    <FormattedMessage
      id="DASHBOARD.MAIN.WHAT_IF.REMOVE_ALL.WARN"
      defaultMessage="Are you sure you want to erase all What-if assets?"
    />
  ),
  title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
  okButton: <FormattedMessage id="APP.REMOVE" defaultMessage="Erase" />
};

const goalsDefaultState = {
  goalsForm: {
    retirement: {
      retirementAge: "",
      annualAmount: "",
      lifeExpectancy: "",
      description: ""
    },
    education: {
      personAttending: "",
      howManyYears: "",
      amountPerYear: "",
      ageAttending: ""
    }
  }
};

class Dashboard extends PureComponent {
  static propTypes = {
    dashboardChartTerm: PropTypes.any,
    dashboardIfChecked: PropTypes.bool,
    propsState: PropTypes.any,
    transactionsAll: PropTypes.any,
    transactionsAllObj: PropTypes.any,
    assetsAll: PropTypes.any,
    assetsAllObj: PropTypes.any,
    goalsAll: PropTypes.any,
    eventsALl: PropTypes.any,
    profile: PropTypes.object,
    rights: PropTypes.any,
    family: PropTypes.any,
    fullCalc: PropTypes.any,
    uiSettings: PropTypes.any
  };

  state = {
    ...goalsDefaultState,
    // ADVISOR
    forms: {
      lifeInsurance: {
        notes: "",
        userMortgage: "",
        userOtherDebt: "",
        userHighEducation: "",
        userAnnualIncome: "",
        userAnnualInflation: INFLATION_PERCENT,
        userRecommendedAmount: "",
        userOpp: "",

        spouseMortgage: "",
        spouseOtherDebt: "",
        spouseHighEducation: "",
        spouseAnnualIncome: "",
        spouseAnnualInflation: INFLATION_PERCENT,
        spouseRecommendedAmount: "",
        spouseOpp: "",

        isSaved: false,
        isDone: false
      },
      cashDebt: {
        notes: "",
        cashOpp: "",
        debtOpp: "",

        isSaved: false,
        isDone: false
      },
      realEstate: {
        notes: "",
        ownedOpp: "",
        rentedOpp: "",

        isSaved: false,
        isDone: false
      },
      higheducationNeeds: {
        notes: "",
        advisor: "no",

        isSaved: false,
        isDone: false
      },
      emergencyFund: {
        notes: "",
        monthlyIncome: "",
        emergencyMultiple: "",
        opp: "",
        existingFund: 0,
        recommendedAmount: 0,

        isSaved: false,
        isDone: false
      },
      taxAsset: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      investmentDiversification: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      majorIncome: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      financialIndependence: {
        notes: "",
        lifeExpectancy: "",
        annualInflation: "",
        estimatedLifetime: 0,
        estimatedLifetimeNet: 0,
        gapToIndependence: 0,
        gapToIndependenceWhatIf: 0,
        calculatedIndependenceAge: 0,
        calculatedIndependenceAgeWhatIf: 0,
        desiredIndependenceAge: 0,

        ageFullDepletion: MAX_AGE,
        opportunity: "",

        isSaved: false,
        isDone: false
      },
      temp: {
        goals: {
          education: 0,
          retirement: 0,
          home: 0,
          legacy: 0,
          major: 0,
          other: 0,
          general: 0
        }
      }
    },
    members: {},
    isSaving: false,
    expanded: "panel1",
    data: defaultDataAdvisor,
    //
    stat: null,
    marks: null,
    chartData: null,
    chartDataOverdraft: null,

    whatIfChecked: true,
    moneyOutStatState: null,
    assetsStatState: null,
    taxCategoryStatState: null,
    activeYear: this.props.propsState.currentAge || MIN_CALC_AGE,
    advisorOverallOpen: false,
    dashboardWhatIfOpen: true,
    dashboardWhatIfOpenChanged: false,

    isAssetFormOpen: false,
    assetAddTile: false,
    assetAddTileGoal: false,
    assetAddTileGoalType: "",
    returnToTypes: false,
    formProps: {},
    anchorElAddCat: null,
    anchorElAddAssetsOwned: null,
    anchorElAddPayments: null,

    containerWidth: 0,
    advisorCalculated: false,
    categoryDialog: "",
    allWhatIfDialog: false,
    dialogSaveAllWhatIf: false,
    dialogRemoveAllWhatIf: false,
    cardsWidth: null,

    calcTimestamp: null,
    pieTooltipActive: null,
    statTableHead1_1: null,
    statTableHead1_2: null,
    statTableHead2_1: null,
    statTableHead2_2: null,

    isVisibleScrollLeftArrow: false,
    isVisibleScrollRightArrow: false
  };

  isTopCategoriesRender = false;

  componentDidMount() {
    const { family, profile } = this.props;

    if (family) {
      this.setInitialActiveYear();

      this.updateStateAdvisor();

      if (profile && profile.isLoaded) {
        this.handleUpdateOwnersOptions();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { family, profile } = this.props;

    const { assetsAll, transactionsAll, profileProvider } = this.props;
    const assetsAllPrev = prevProps.assetsAll;
    const transactionsAllPrev = prevProps.transactionsAll;

    const isUpdated =
      family &&
      (profileProvider !== prevProps.profileProvider ||
        assetsAll !== assetsAllPrev ||
        transactionsAll !== transactionsAllPrev ||
        (family && !prevProps.family) ||
        (family &&
          prevProps.family &&
          (family.id !== prevProps.family.id ||
            family.displayName !== prevProps.family.displayName)));

    const isFamilyChanged =
      family &&
      prevProps.family &&
      (family.id !== prevProps.family.id ||
        family.displayName !== prevProps.family.displayName);

    if (
      (this.props.family && !prevProps.family) ||
      (this.props.members && !prevProps.members) ||
      isFamilyChanged ||
      isUpdated
    ) {
      this.setInitialActiveYear();
    }

    if (
      this.props.dashboardChartTerm !== prevProps.dashboardChartTerm ||
      !this.state.chartDataOverdraft ||
      isUpdated ||
      this.props.propsState !== prevProps.propsState
    ) {
      const chartDataOverdraft = this.prepareChartData({
        isOverdraft: true
      });

      const chartDataOverdraftSecondary = this.prepareChartData({
        isOverdraft: true,
        isSecondary: true
      });

      this.setState({
        // chartData,
        chartDataOverdraft,
        // chartDataSecondary,
        chartDataOverdraftSecondary
      });
    }

    if (
      (this.props.profile &&
        ((!prevProps.advisorDashboard && !!this.props.advisorDashboard) ||
          (prevProps.advisorDashboard &&
            this.props.advisorDashboard &&
            prevProps.advisorDashboard.id !==
              this.props.advisorDashboard.id))) ||
      (this.props.family && !prevProps.family) ||
      (this.props.members && !prevProps.members) ||
      profileProvider !== prevProps.profileProvider ||
      assetsAll !== assetsAllPrev ||
      transactionsAll !== transactionsAllPrev ||
      isUpdated ||
      isFamilyChanged ||
      this.props.dashboardNoOverdraft !== prevProps.dashboardNoOverdraft ||
      this.props.dashboardIfChecked !== prevProps.dashboardIfChecked ||
      !this.state.advisorCalculated ||
      this.state.calcTimestamp !== this.props.propsState.calcTimestamp
    ) {
      this.updateStateAdvisor();
      this.setInitialActiveYear();
    }

    if (
      profile &&
      profile.displayName &&
      prevProps.profile.displayName !== profile.displayName
    ) {
      this.handleUpdateOwnersOptions();
    }

    let element = this.refs.categoriesScroll;

    if (element && !this.isTopCategoriesRender) {
      if (element.scrollWidth !== element.offsetWidth) {
        this.isTopCategoriesRender = true;
        this.checkScrollPosition();
      }
    }
  }

  checkScrollPosition = e => {
    let element = this.refs.categoriesScroll;

    if (
      element.scrollLeft + element.offsetWidth !== element.scrollWidth &&
      element.scrollLeft !== 0
    ) {
      this.setState({ isVisibleScrollLeftArrow: true });
      this.setState({ isVisibleScrollRightArrow: true });
    } else if (
      element.scrollLeft === 0 &&
      element.scrollWidth !== element.offsetWidth
    ) {
      this.setState({ isVisibleScrollLeftArrow: false });
      this.setState({ isVisibleScrollRightArrow: true });
    } else if (element.scrollWidth !== element.offsetWidth) {
      this.setState({ isVisibleScrollLeftArrow: true });
      this.setState({ isVisibleScrollRightArrow: false });
    } else {
      this.setState({ isVisibleScrollLeftArrow: false });
      this.setState({ isVisibleScrollRightArrow: false });
    }
  };

  handleScrollToLeft = () => {
    this.refs.categoriesScroll.scrollLeft = 0;
  };

  handleScrollToRight = () => {
    this.refs.categoriesScroll.scrollLeft =
      this.refs.categoriesScroll.scrollWidth -
      this.refs.categoriesScroll.scrollLeft;
  };

  handleSetHeightStatHeader = header => height => {
    if (this.state.header !== height) {
      this.setState({ [header]: height });
    }
  };

  handleOpenAllWhatIf = () => {
    this.setState({
      allWhatIfDialog: true
    });
  };

  handleCloseAllWhatIfAgreement = () => {
    this.setState({ dialogRemoveAllWhatIf: false, dialogSaveAllWhatIf: false });
  };

  // show all what if
  handleUpdateOwnersOptions = () => {
    const { childs, family } = this.props;

    const currentProfile = family;
    const template = [];
    let templateAll = [];

    if (currentProfile) {
      const birthdayGeneral = currentProfile.birthday;

      const ageGeneral =
        birthdayGeneral && birthdayGeneral.seconds
          ? diffYears(Date.now(), birthdayGeneral.seconds * 1000)
          : 0;

      template[0] = {
        name: currentProfile.displayName,
        value: "You",
        age: ageGeneral
      };
    }

    if (
      currentProfile &&
      currentProfile.spouse &&
      currentProfile.spouse.spouseName &&
      (currentProfile.relationshipStatus === "married" ||
        currentProfile.relationshipStatus === "established")
    ) {
      let age = 0;
      if (
        currentProfile.spouse.birthday &&
        currentProfile.spouse.birthday.seconds
      ) {
        age = diffYears(
          Date.now(),
          currentProfile.spouse.birthday.seconds * 1000
        );
      }
      template[1] = {
        name: currentProfile.spouse.spouseName,
        value: "Spouse",
        age
      };
    }

    templateAll = [...template];

    if (childs) {
      const childsArr = Object.keys(childs).map(id => ({ id, ...childs[id] }));

      childsArr.forEach(child => {
        const fullYears =
          child.birthday && child.birthday.seconds
            ? diffYears(Date.now(), child.birthday.seconds * 1000)
            : 0;

        const childOption = {
          name: child.childName,
          value: child.id,
          age: fullYears
        };

        templateAll.push(childOption);

        if (fullYears > 16) {
          template.push(childOption);
        }
      });
    }

    this.setState({ optionsOwnerAdults: template, optionsOwner: templateAll });
  };

  handleGetAdditionalAssets = entityGlobalType => {
    const { assetsAll, transactionsAll } = this.props;

    const homesRent = {};
    const homes = {};
    const vehicles = {};
    const others = {}; // use Other
    const useAssetsAll = {};
    const annuities = {};
    let debts = {};
    const goals = {};
    const savings = {};

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];
        if (asset && asset.entity === entitiesSavings.ENTITY_ANNUITY) {
          annuities[asset.id] = asset;
        }

        if (
          asset &&
          asset.globalType === globalTypeUse &&
          asset.entity === entitiesUse.ENTITY_HOME
        ) {
          homes[asset.id] = asset;

          if (asset.type === optionsValuesHome.INVESTMENT) {
            homesRent[asset.id] = asset;
          }
        }

        if (
          asset &&
          asset.entity !== entitiesUse.ENTITY_GENERAL &&
          asset.globalType === QueryGlobalAssets
        ) {
          useAssetsAll[asset.id] = asset;
        }

        if (asset && asset.globalType === globalTypeDebt) {
          debts[asset.id] = asset;
        }

        if (
          entityGlobalType === globalTypeMoneyIn &&
          asset.globalType === globalTypeSavings &&
          asset.entity === entitiesSavings.ENTITY_401K
        ) {
          savings[asset.id] = asset;
        }

        if (
          entityGlobalType === globalTypeDebt &&
          asset.globalType === globalTypeUse
        ) {
          if (asset.entity === entitiesUse.ENTITY_VEHICLE) {
            vehicles[assetKey] = asset;
          }

          if (asset.entity === entitiesUse.ENTITY_OTHER) {
            others[assetKey] = asset;
          }
        }
      });
    }

    if (transactionsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];

        if (asset && asset.globalType === globalTypeGoal) {
          goals[asset.id] = asset;
        }
      });
    }

    if (
      entityGlobalType === globalTypeMoneyOutPersonal ||
      entityGlobalType === globalTypeMoneyOutAssets
    ) {
      if (debts) {
        let debtsArr = [];

        Object.keys(debts).forEach(key => {
          const debt = debts[key];

          if (
            debt &&
            (entityGlobalType !== globalTypeMoneyOutPersonal ||
              (entityGlobalType === globalTypeMoneyOutPersonal &&
                debt.entity !== entitiesDebt.ENTITY_HOME &&
                debt.entity !== entitiesDebt.ENTITY_GENERAL))
          ) {
            debtsArr.push({
              ...debt,
              id: key
            });
          }
        });

        debts = debtsArr;
      }
    }

    return {
      homesRent,
      homes,
      useAssetsAll,
      useAssets: useAssetsAll,
      annuities,
      debts,
      assetsAll,
      goals,
      vehicles,
      others,
      savings
    };
  };
  ///////////////////////////////////////////////////////////////////////////
  // ASSET FORM OR CARD
  // handleDialogRemoveOpen = assetUid => () => {
  //   const { family } = this.props;
  //
  //   if (family && !family.isWizardFinished) {
  //     return this.handleDialogFinishWizardOpen(
  //       this.handleDialogRemoveOpen(assetUid)
  //     );
  //   }
  //
  //   this.setState({
  //     assetUid
  //   });
  // };
  handleToggleWhatIfAsset = uidAsset => () => {
    const { firestore, assetsAll, transactionsAll, family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleToggleWhatIfAsset(uidAsset)
      );
    }

    let asset = assetsAll.find(asset => asset.id === uidAsset);
    if (!asset) {
      asset = transactionsAll.find(asset => asset.id === uidAsset);
    }

    if (asset) {
      const scheme = entitiesScheme[asset.globalType];
      if (scheme) {
        firestore.update(
          `${scheme.assetCollection || "userAssets"}/${uidAsset}`,
          {
            isWhatIf: !asset.isWhatIf
          }
        );
      }
    }
  };
  /// Dialog Remove asset
  handleDeleteAsset = uidAsset => () => {
    const { firestore, assetsAll, transactionsAll } = this.props;

    let asset = assetsAll.find(asset => asset.id === uidAsset);
    if (!asset) {
      asset = transactionsAll.find(asset => asset.id === uidAsset);
    }

    if (asset) {
      const scheme = entitiesScheme[asset.globalType];

      if (scheme) {
        firestore
          .delete(`${scheme.assetCollection}/${uidAsset}`)
          .then(res => {
            // remove connected asset
            // TODO is need here

            toast(
              <FormattedMessage
                id={scheme.translations.toast.assetRemoved.titleI18n}
                defaultMessage={scheme.translations.toast.assetRemoved.title}
              />
            );
          })
          .catch(err => {
            toast(
              <FormattedMessage
                id="APP.ERROR_HAS_OCCURRED"
                defaultMessage="Error has occurred"
              />,
              { type: "error" }
            );
            console.log("---", err);
          });
      }
    }
  };

  handleDialogRemoveAgree = () => {
    this.handleDeleteAsset(this.state.assetUid)(); // new

    this.handleDialogRemoveClose();
  };

  handleDialogRemoveOpen = assetUid => () => {
    const { family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleDialogRemoveOpen(assetUid)
      );
    }

    this.setState({
      assetUid
    });
  };

  handleDialogRemoveClose = () => {
    this.setState({
      assetUid: ""
    });
  };
  handleToggleDisableAsset = uidAsset => () => {
    const { firestore, assetsAll, transactionsAll, family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleToggleWhatIfAsset(uidAsset)
      );
    }

    let asset = assetsAll.find(asset => asset.id === uidAsset);
    if (!asset) {
      asset = transactionsAll.find(asset => asset.id === uidAsset);
    }

    if (asset) {
      const scheme = entitiesScheme[asset.globalType];
      if (scheme) {
        firestore.update(
          `${scheme.assetCollection || "userAssets"}/${uidAsset}`,
          {
            isDisabled: !asset.isDisabled
          }
        );
      }
    }
  };

  handleDuplicateAsset = uidAsset => () => {
    const { assetsAll, transactionsAll } = this.props;

    let asset = assetsAll.find(el => el.id === uidAsset);

    if (!asset) {
      asset = transactionsAll.find(el => el.id === uidAsset);
    }

    if (!asset) {
      return;
    }

    const { created, id, debt, ...newAsset } = asset;

    const scheme = entitiesScheme[asset.globalType];
    if (!scheme) {
      return null;
    }

    const conf = {
      ...scheme,
      customValues: { ...newAsset },
      assetType: asset.entity
    };

    this.handleOpenForm(null, conf);
  };

  handleEditAsset = asset => ev => {
    if (!asset) {
      return null;
    }

    const scheme = entitiesScheme[asset.globalType];
    if (!scheme) {
      return null;
    }

    const conf = {
      ...scheme,
      assetType: asset.entity
    };

    this.handleOpenForm(asset.id, conf);
  };

  handleOpenForm = (id, conf = {}) => {
    this.setState({
      isAssetFormOpen: true,
      formProps: {
        ...conf,
        id
      }
    });
  };

  handleCloseFormAndBackToSelectType = backAssetAddTile => () => {
    this.setState({
      assetAddTile: backAssetAddTile,
      isAssetFormOpen: false
      // formProps: {}
    });
  };

  handleCloseForm = () => {
    this.setState({
      assetAddTile: null,
      isAssetFormOpen: false,
      formProps: {}
    });
  };

  handleToggleOverall = () => {
    this.setState(prevState => ({
      advisorOverallOpen: !prevState.advisorOverallOpen
    }));
  };

  handleToggleWhatIf = () => {
    this.setState(prevState => ({
      dashboardWhatIfOpenChanged: true,
      dashboardWhatIfOpen: !prevState.dashboardWhatIfOpen
    }));
  };

  updateProducts = firstYear => {
    const { propsState } = this.props;
    const { members } = propsState;

    const defaultProducts = cloneObject(defaultDataAdvisor.products);
    const collegeFund = {};

    const debt = { primary: 0, vacation: 0, investment: 0, other: 0, all: 0 };
    const home = { primary: 0, vacation: 0, investment: 0, other: 0, all: 0 };

    if (firstYear) {
      Object.keys(firstYear.assetsAll.savings).forEach(elKey => {
        const entities = firstYear.assetsAll.savings[elKey];

        if (
          elKey === "otherUnknown" ||
          elKey === "otherOrdinary" ||
          elKey === "otherCapital" ||
          elKey === "otherNoTax" ||
          elKey === "529college"
        ) {
          Object.keys(entities).forEach(entityKey => {
            const entity = entities[entityKey];

            if (elKey === "529college") {
              defaultProducts.other["529college"] += entity.value;

              collegeFund[entity.owner] =
                (collegeFund[entity.owner] || 0) + entity.value;
            } else {
              defaultProducts.other.other += entity.value;
            }
            defaultProducts.other.all += entity.value;
          });
        } else {
          if (elKey === "IRA") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members &&
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.IRA += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse.IRA += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }

          if (elKey === "401k") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members &&
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You["401K"] += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse["401K"] += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }
          if (elKey === "lifeInsurance") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members &&
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.lifeInsurance += entity.deathBenefit;
                defaultProducts.You.all += entity.deathBenefit;
              } else {
                defaultProducts.Spouse.lifeInsurance += entity.deathBenefit;
                defaultProducts.Spouse.all += entity.deathBenefit;
              }
            });
          }
          if (elKey === "annuityUnqualified" || elKey === "annuityQualified") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members &&
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.annuity += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse.annuity += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }
        }
      });

      Object.keys(firstYear.assetsAll.debt.home).forEach(entityKey => {
        const entity = firstYear.assetsAll.debt.home[entityKey];

        debt[
          entity.assetType === optionsValuesHome.MULTIPLE
            ? "other"
            : entity.assetType
        ] += entity.value;
        debt.all += entity.value;
      });
      Object.keys(firstYear.assetsAll.use.home).forEach(entityKey => {
        const entity = firstYear.assetsAll.use.home[entityKey];

        home[
          entity.type === optionsValuesHome.MULTIPLE ? "other" : entity.type
        ] += entity.value;
        home.all += entity.value;
      });
    }

    return { defaultProducts, collegeFund, debt, home };
  };

  getEventsFor10Yrs = () => {
    const { propsState } = this.props;

    const { fullCalc } = propsState ? propsState : null;

    const events = [];

    if (fullCalc) {
      const tempValues = Object.values(fullCalc);

      for (let i = 0; i < 10; i++) {
        const tempYear = tempValues[i];

        if (tempYear) {
          Object.keys(tempYear.assetsAll.events.moneyIn).forEach(eventKey => {
            const event = tempYear.assetsAll.events.moneyIn[eventKey];

            if (!event.isWhatIf) {
              events.push({
                id: eventKey,
                year: tempYear.year,
                value: event.value,
                description: event.description,
                yearsUntilOccurs: i
              });
            }
          });
        }
      }
    }

    return events;
  };
  updateStateAdvisor = () => {
    const { advisorDashboard, childs, propsState } = this.props;
    // const isWhatIf = uiSettings.dashboardIfChecked;

    const { members } = propsState;
    const { fullCalc } = propsState ? propsState : null;

    ///////// CALC DATA //////////////////////////////////////////////////////////
    const newStateData = cloneObject(defaultDataAdvisor);
    const educationInflation = {
      you: 0,
      spouse: 0
    };
    const firstYear = Object.values(fullCalc)[0];

    const { defaultProducts, collegeFund, debt, home } = this.updateProducts(
      firstYear
    );

    newStateData.products = defaultProducts;
    newStateData.assets["529college"] = collegeFund;
    newStateData.assets.debt = debt;
    newStateData.assets.home = home;
    newStateData.assets.educationWithUntilAttend = [];

    const events = this.getEventsFor10Yrs();

    if (this.props.assetsAll && firstYear) {
      let isEducationGoalExist = false;

      this.props.assetsAll.forEach(el => {
        if (el.isDisabled || el.isWhatIf) {
          return;
        }

        if (el.globalType === globalTypeGoal) {
          if (el.entity === entitiesGoals.ENTITY_GENERAL) {
            newStateData.temp.goals.general += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_OTHER) {
            newStateData.temp.goals.other += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_MAJOR_EXPANSE) {
            newStateData.temp.goals.major += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_LEGACY) {
            newStateData.temp.goals.legacy += +el.amountToLeave || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_HOME_PURCHASE) {
            newStateData.temp.goals.home += +el.cost || 0;
          }

          if (el.entity === entitiesGoals.ENTITY_RETIREMENT) {
            newStateData.financialIndependence.lifeExpectancy =
              el.lifeExpectancy || MAX_AGE;

            if (el.atAge && el.starts === "atAge") {
              newStateData.financialIndependence.desiredIndependenceAge =
                el.atAge;
            }

            if (el.starts === "passive" && el.annualAmount) {
              newStateData.financialIndependence.passiveIncome =
                el.annualAmount;
            }

            if (el.annualAmount) {
              newStateData.temp.goals.retirement += +el.annualAmount || 0;
            }
          }

          if (el.entity === entitiesGoals.ENTITY_EDUCATION) {
            isEducationGoalExist = true;

            const untilAttend =
              el.ageAttending && members && members[el.personAttending]
                ? el.ageAttending - members[el.personAttending].age
                : 0;

            if (untilAttend >= 0) {
              const educationAmount = el.howManyYears * el.amountPerYear;
              newStateData.temp.goals.education += educationAmount;

              const withInflation =
                Math.pow(1 + INFLATION, untilAttend) * educationAmount;

              newStateData.assets.educationTotal += educationAmount;
              newStateData.assets.educationWithUntilAttend.push({
                untilAttend,
                educationAmount,
                personAttending: el.personAttending
              });

              if (el.personAttending === "You") {
                newStateData.lifeInsurance.userHighEducation += educationAmount;

                educationInflation.you += withInflation;
              }

              if (el.personAttending === "Spouse") {
                newStateData.lifeInsurance.spouseHighEducation += educationAmount;

                educationInflation.spouse += withInflation;
              }

              if (
                el.personAttending !== "You" &&
                el.personAttending !== "Spouse"
              ) {
                newStateData.lifeInsurance.spouseHighEducation += educationAmount;
                newStateData.lifeInsurance.userHighEducation += educationAmount;

                educationInflation.spouse += withInflation;
                educationInflation.you += withInflation;
              }
            }
          }
        }

        if (
          el.entity === entitiesSavings.ENTITY_LIFE_INSURANCE &&
          el.globalType === globalTypeSavings
        ) {
          const deathBenefit = +el.deathBenefit || 0;
          newStateData.assets.lifeInsurance.cash += +el.cashValue || 0;

          if (el.owner === "You") {
            newStateData.lifeInsurance.userExistingInsurance += deathBenefit;
          }

          if (el.owner === "Spouse") {
            newStateData.lifeInsurance.spouseExistingInsurance += deathBenefit;
          }
        }
      });

      if (!isEducationGoalExist && childs && childs.length) {
        const educationDefault = childs.length * 4 * 35000;
        newStateData.lifeInsurance.spouseHighEducation = educationDefault;
        newStateData.lifeInsurance.userHighEducation = educationDefault;
      }

      newStateData.lifeInsurance.userMortgage =
        firstYear && firstYear.overdraft.Liabilities
          ? firstYear.overdraft.Liabilities.mortgage
          : 0;
      newStateData.lifeInsurance.userOtherDebt =
        firstYear && firstYear.overdraft.Liabilities
          ? firstYear.overdraft.Liabilities.loans
          : 0;
      newStateData.lifeInsurance.spouseMortgage =
        newStateData.lifeInsurance.userMortgage;
      newStateData.lifeInsurance.spouseOtherDebt =
        newStateData.lifeInsurance.userOtherDebt;

      newStateData.lifeInsurance.userAnnualIncome =
        firstYear && firstYear.TempValues
          ? firstYear.overdraft.TempValues.MoneyIn.salary.you
          : 0;

      newStateData.lifeInsurance.spouseAnnualIncome =
        firstYear && firstYear.overdraft.TempValues
          ? firstYear.overdraft.TempValues.MoneyIn.salary.spouse
          : 0;

      newStateData.lifeInsurance.userRecommendedAmount =
        newStateData.lifeInsurance.userMortgage +
        newStateData.lifeInsurance.userOtherDebt +
        educationInflation.you +
        10 * newStateData.lifeInsurance.userAnnualIncome;
      newStateData.lifeInsurance.spouseRecommendedAmount =
        newStateData.lifeInsurance.spouseMortgage +
        newStateData.lifeInsurance.spouseOtherDebt +
        educationInflation.spouse +
        10 * newStateData.lifeInsurance.spouseAnnualIncome;

      newStateData.lifeInsurance.userOpp =
        newStateData.lifeInsurance.userExistingInsurance /
        newStateData.lifeInsurance.userRecommendedAmount;
      newStateData.lifeInsurance.spouseOpp =
        newStateData.lifeInsurance.spouseExistingInsurance /
        newStateData.lifeInsurance.spouseRecommendedAmount;

      //// existing insurance/recommended amount

      newStateData.emergencyFund.existingFund = firstYear
        ? firstYear.overdraft.TempValues.accounts.savings +
          firstYear.overdraft.TempValues.accounts.checking
        : 0;
      newStateData.emergencyFund.monthlyIncome = firstYear
        ? firstYear.overdraft.MoneyIn.all / 12
        : 0;
      newStateData.emergencyFund.recommendedAmount = firstYear
        ? newStateData.emergencyFund.emergencyMultiple *
          newStateData.emergencyFund.monthlyIncome
        : 0;

      ////////////////////////////////////

      this.calcFinancialIndependence(
        newStateData.financialIndependence,
        fullCalc,
        firstYear
      );
    }
    ///////////////////////////////////////////////////////////////////

    const newFormsState = this.state.forms;
    Object.keys(this.state.forms).forEach(formId => {
      const form = this.state.forms[formId];

      const isSaved = advisorDashboard && advisorDashboard[`${formId}-isSaved`];
      if (isSaved) {
        Object.keys(form).forEach(fieldKey => {
          newFormsState[formId][fieldKey] = advisorDashboard
            ? advisorDashboard[`${formId}-${fieldKey}`]
            : "";
        });

        if (formId === "lifeInsurance") {
          const {
            educationWithInflationUser,
            educationWithInflationSpouse
          } = this.calcEducationWithInflation(
            newFormsState.lifeInsurance,
            newStateData.assets.educationTotal,
            newStateData.assets.educationWithUntilAttend
          );

          newFormsState.lifeInsurance.userRecommendedAmount =
            +newFormsState.lifeInsurance.userMortgage +
            +newFormsState.lifeInsurance.userOtherDebt +
            educationWithInflationUser +
            10 * newFormsState.lifeInsurance.userAnnualIncome;

          newFormsState.lifeInsurance.spouseRecommendedAmount =
            +newFormsState.lifeInsurance.spouseMortgage +
            +newFormsState.lifeInsurance.spouseOtherDebt +
            educationWithInflationSpouse +
            10 * newFormsState.lifeInsurance.spouseAnnualIncome;
        }

        if (formId === "emergencyFund") {
          newFormsState.emergencyFund.recommendedAmount = firstYear
            ? newFormsState.emergencyFund.emergencyMultiple *
              newFormsState.emergencyFund.monthlyIncome
            : 0;
        }

        if (formId === "financialIndependence") {
          this.calcFinancialIndependence(
            newFormsState.financialIndependence,
            fullCalc,
            firstYear
          );
        }
      } else {
        Object.keys(form).forEach(fieldKey => {
          newFormsState[formId][fieldKey] = newStateData[formId]
            ? newStateData[formId][fieldKey]
            : "";
        });
      }
    });

    newStateData.events = events;

    this.setState(prevState => ({
      ...prevState,
      firstYear,

      forms: newFormsState,
      data: newStateData,
      calcTimestamp: this.props.propsState.calcTimestamp,

      advisorCalculated: true
    }));
  };
  calcFinancialIndependence = (newStateData, fullCalc, firstYear) => {
    // const { uiSettings } = this.props;
    // const isWhatIf = uiSettings.dashboardIfChecked;

    if (fullCalc) {
      const firstYear = Object.values(fullCalc)[0];

      // const estimatedLifetimeOverdraft =
      //   fullCalc && fullCalc[newStateData.lifeExpectancy]
      //     ? uiSettings.dashboardNoOverdraft
      //       ? fullCalc[newStateData.lifeExpectancy].overdraft &&
      //         fullCalc[newStateData.lifeExpectancy].overdraft.TempValues
      //           .MoneyOut.cumulative
      //       : fullCalc[newStateData.lifeExpectancy].TempValues.MoneyOut
      //           .cumulative
      //     : 0;
      const estimatedLifetimeOverdraft =
        fullCalc && fullCalc[newStateData.lifeExpectancy]
          ? fullCalc[newStateData.lifeExpectancy].overdraft &&
            fullCalc[newStateData.lifeExpectancy].overdraft.TempValues.MoneyOut
              .cumulative
          : 0;

      const estimatedLifetimeOverdraftWhatIf =
        fullCalc && fullCalc[newStateData.lifeExpectancy]
          ? fullCalc[newStateData.lifeExpectancy].overdraft &&
            fullCalc[newStateData.lifeExpectancy].overdraft.TempValuesWhatIf
              .MoneyOut.cumulative
          : 0;

      newStateData.estimatedLifetime = estimatedLifetimeOverdraft
        ? estimatedLifetimeOverdraft
        : 0;

      // current monetary assets
      // newStateData.estimatedLifetimeNet =
      //   fullCalc && fullCalc[this.props.propsState.currentAge]
      //     ? uiSettings.dashboardNoOverdraft
      //       ? fullCalc[this.props.propsState.currentAge].overdraft.chartData
      //           .MonetaryAssets
      //       : fullCalc[this.props.propsState.currentAge].chartData
      //           .MonetaryAssets
      //     : 0;
      newStateData.estimatedLifetimeNet =
        fullCalc && fullCalc[this.props.propsState.currentAge]
          ? fullCalc[this.props.propsState.currentAge].overdraft.chartData
              .MonetaryAssets
          : 0;

      const currentMonetaryAssets = firstYear
        ? firstYear.overdraft.chartData.MonetaryAssets
        : 0;
      const currentMonetaryAssetsWhatIf = firstYear
        ? firstYear.overdraft.chartDataWhatIf.MonetaryAssets
        : 0;

      newStateData.gapToIndependence =
        currentMonetaryAssets - estimatedLifetimeOverdraft;

      newStateData.gapToIndependenceWhatIf =
        currentMonetaryAssetsWhatIf - estimatedLifetimeOverdraftWhatIf;

      // deprecated
      // const ageDiff =
      //         newStateData.lifeExpectancy - this.props.propsState.currentAge;
      // newStateData.gapToIndependence =
      //   currentMonetaryAssets -
      //   newStateData.estimatedLifetime /
      //     Math.pow(1 + +newStateData.annualInflation / 100, ageDiff);

      let isFoundIndependenceAge = false;
      let isFoundIndependenceAgeWhatIf = false;
      let isFoundAgeDepletion = false;
      let isFoundAgeDesiredIndAge = false;

      let prevYearEstimatedLifetime = 0;
      let prevYearEstimatedLifetimeWhatIf = 0;

      Object.keys(fullCalc).forEach(ageKey => {
        const tempYear = fullCalc[ageKey];

        const monetaryAssetOverdraft =
          tempYear.overdraft.chartData.MonetaryAssets;
        const monetaryAssetOverdraftWhatIf =
          tempYear.overdraft.chartDataWhatIf.MonetaryAssets;

        if (!isFoundAgeDepletion && monetaryAssetOverdraft <= 0) {
          isFoundAgeDepletion = true;

          newStateData.ageFullDepletion = ageKey;
        }

        const estimatedLifetimeOverdraftCurr =
          estimatedLifetimeOverdraft - prevYearEstimatedLifetime;

        const estimatedLifetimeOverdraftCurrWhatIf =
          estimatedLifetimeOverdraftWhatIf - prevYearEstimatedLifetimeWhatIf;

        if (
          !isFoundIndependenceAge &&
          monetaryAssetOverdraft >= estimatedLifetimeOverdraftCurr
        ) {
          isFoundIndependenceAge = true;

          newStateData.calculatedIndependenceAge = ageKey;
        }

        if (
          !isFoundIndependenceAgeWhatIf &&
          monetaryAssetOverdraftWhatIf >= estimatedLifetimeOverdraftCurrWhatIf
        ) {
          isFoundIndependenceAgeWhatIf = true;

          newStateData.calculatedIndependenceAgeWhatIf = ageKey;
        }

        // Find the age where Income from rent+bank interest+other+income events (everything not salary)
        if (
          !isFoundAgeDesiredIndAge &&
          !newStateData.desiredIndependenceAge &&
          (newStateData.passiveIncome &&
            tempYear.MoneyIn.all - tempYear.MoneyIn.salary >=
              newStateData.passiveIncome)
        ) {
          isFoundAgeDesiredIndAge = true;
          newStateData.desiredIndependenceAge = ageKey;
        }

        prevYearEstimatedLifetime =
          tempYear.overdraft &&
          tempYear.overdraft.TempValues.MoneyOut.cumulative;
        prevYearEstimatedLifetimeWhatIf =
          tempYear.overdraft &&
          tempYear.overdraft.TempValuesWhatIf.MoneyOut.cumulative;
      });

      if (!newStateData.desiredIndependenceAge) {
        newStateData.desiredIndependenceAge = DEFAULT_EXPECTED_RETIREMENT_AGE;
      }
    }
  };

  calcEducationWithInflation = (
    lifeInsuranceState,
    educationTotal,
    educationWithUntilAttend
  ) => {
    const educationDiff =
      (lifeInsuranceState.userHighEducation - educationTotal) /
      educationWithUntilAttend.length;
    const educationDiffSpouse =
      (lifeInsuranceState.spouseHighEducation - educationTotal) /
      educationWithUntilAttend.length;
    let educationWithInflationUser = 0;
    let educationWithInflationSpouse = 0;

    educationWithUntilAttend.forEach(
      ({ untilAttend, educationAmount, personAttending }) => {
        const inflationFactor = Math.pow(
          1 + lifeInsuranceState.userAnnualInflation / 100,
          untilAttend
        );

        const newAmountWithDiff = educationAmount + educationDiff;
        const newAmountWithDiffSpouse = educationAmount + educationDiffSpouse;
        const withInflation =
          inflationFactor * (newAmountWithDiff > 0 ? newAmountWithDiff : 0);
        const withInflationSpouse =
          inflationFactor *
          (newAmountWithDiffSpouse > 0 ? newAmountWithDiffSpouse : 0);

        if (personAttending === "You") {
          return (educationWithInflationUser += withInflation);
        }

        if (personAttending === "Spouse") {
          return (educationWithInflationSpouse += withInflationSpouse);
        }

        if (personAttending !== "You" && personAttending !== "Spouse") {
          educationWithInflationSpouse += withInflationSpouse;
          educationWithInflationUser += withInflation;
        }
      }
    );

    return { educationWithInflationUser, educationWithInflationSpouse };
  };

  calcScrollBarWidth = () => {
    let scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    const container = document.querySelector(".main-container");
    if (container) {
      container.appendChild(scrollDiv);

      // Get the scrollbar width
      let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      // Delete the DIV
      container.removeChild(scrollDiv);
      return scrollbarWidth;
    }

    return 0;
  };

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleSaveSection = formId => () => {
    const { firestore, advisorDashboard, profileProvider, auth } = this.props;

    const saveObject = {};

    if (this.state.forms[formId]) {
      Object.keys(this.state.forms[formId]).forEach(key => {
        const dbKey = `${formId}-${key}`;
        const value = this.state.forms[formId][key];

        saveObject[dbKey] = typeof value !== "undefined" ? value : "";
      });
    }

    saveObject[`${formId}-isSaved`] = true;
    saveObject[`${formId}-isDone`] = false;

    const newDate = new Date();
    if (advisorDashboard && advisorDashboard.id) {
      firestore
        .update(`advisorDashboard/${advisorDashboard.id}`, {
          updated: new Date(),
          ...saveObject
        })
        .then(() => {
          toast(
            <FormattedMessage
              id="APP.SUCCESSFULLY_SAVED"
              defaultMessage="Saved successfully"
            />
          );
        });
    } else {
      firestore
        .add(
          { collection: "advisorDashboard" },
          {
            created: newDate,
            updated: newDate,

            advisorId: auth.uid,
            clientId: profileProvider,

            ...saveObject
          }
        )
        .then(() => {
          toast(
            <FormattedMessage
              id="APP.SUCCESSFULLY_SAVED"
              defaultMessage="Saved successfully"
            />
          );
        });
    }
  };

  expandPanel = panel => () => {
    this.setState({
      expanded: panel
    });
  };

  handleInputChangePanel = (formId, category) => ev => {
    const { name, value } = ev.target;

    const prevState = this.state;

    let additions = {};
    additions = {
      ...prevState.forms[formId],
      [name]: value
    };

    if (formId === "lifeInsurance") {
      const {
        educationWithInflationUser,
        educationWithInflationSpouse
      } = this.calcEducationWithInflation(
        additions,
        this.state.data.assets.educationTotal,
        this.state.data.assets.educationWithUntilAttend
      );
      ////////////////////////////////////////////////////////////////////

      additions.userRecommendedAmount =
        +additions.userMortgage +
        +additions.userOtherDebt +
        // +additions.userHighEducation +
        educationWithInflationUser +
        10 * additions.userAnnualIncome;

      additions.spouseRecommendedAmount =
        +additions.spouseMortgage +
        +additions.spouseOtherDebt +
        // +additions.spouseHighEducation +
        educationWithInflationSpouse +
        10 * additions.spouseAnnualIncome;
    }

    if (formId === "financialIndependence") {
      additions.gapToIndependence =
        +additions.estimatedLifetimeNet - +additions.estimatedLifetime;
    }
    if (formId === "emergencyFund") {
      additions.recommendedAmount =
        +additions.emergencyMultiple * +additions.monthlyIncome;
    }

    if (formId === "financialIndependence") {
      const { propsState } = this.props;
      const { fullCalc } = propsState;
      const firstYear = Object.values(fullCalc)[0];

      this.calcFinancialIndependence(additions, fullCalc, firstYear);
    }

    this.setState(prevState => {
      prevState.forms[formId] = {
        ...prevState.forms[formId],
        ...additions,
        [name]: value
      };

      return prevState;
    });
  };
  ///////////////////////////////////////////////////////////////////////////

  setInitialActiveYear = () => {
    const { family } = this.props;

    const currentAge =
      family && family.birthday && family.birthday.seconds
        ? diffYears(Date.now(), family.birthday.seconds * 1000)
        : MIN_CALC_AGE;

    const marks = this.prepareGoalsNEvents();

    let newState = {};

    if (this.state.currentAge !== currentAge) {
      newState = {
        activeYear: currentAge,
        currentAge: currentAge
      };
    }

    this.setState({
      ...newState,
      marks
    });
  };

  updateDashboardDimensions = (width, height) => {
    const { isMobile } = this.props;

    let calcWidth = width;

    this.setState({
      containerWidth: isMobile ? calcWidth - 32 : calcWidth - 80, // padding left + right 80px or 32px on mobile
      containerHeight: height,
      cardsWidth: width
    });
  };

  handleSetActiveYear = activeYear => {
    this.setState(prevState => ({
      activeYear: activeYear || prevState.activeYear
    }));
  };

  handleChangeCommonControl = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value
    });
  };
  handleChangeCommonSwitch = ev => {
    const { name, checked } = ev.target;

    this.setState({
      [name]: checked
    });
  };

  handleChangeStatTab = value => ev => {
    this.props.setUI(
      {
        advisorSummaryType: value
      },
      { email: this.props.profile.email }
    );
  };

  handleChangeCommonRedux = ev => {
    const { value, name } = ev.target;

    this.props.setUI(
      {
        [name]: value
      },
      { email: this.props.profile.email }
    );
  };

  handleChangeToDetailMode = () => {
    this.props.setUI(
      {
        advisorSummaryType: valuesStatTypes.DETAILS
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeChartTerm = ev => {
    const { value } = ev.target;

    this.props.setUI(
      {
        dashboardChartTerm: value
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeNoOverdraft = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardNoOverdraft: !checked
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeWealthIncome = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardWealthIncome: checked
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeAgeYear = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardAgeYear: checked
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeWhatIf = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardIfChecked: checked
      },
      { email: this.props.profile.email }
    );
  };

  prepareGoalsNEvents = () => {
    // const { intl } = this.props;
    const { goalsAll, eventsAll, propsState, members } = this.props;
    const { currentAge, lifeEvents, fullCalc } = propsState;

    const specialEvents = lifeEvents && lifeEvents.special;
    const marks = {};

    if (goalsAll) {
      goalsAll.forEach(el => {
        if (!el.isDisabled) {
          let age =
            el.atAge ||
            el.startAge ||
            el.ageAttending ||
            el.expectedAge ||
            el.targetAge;

          if (members) {
            if (
              el.entity === entitiesGoals.ENTITY_EDUCATION &&
              members[el.personAttending]
            ) {
              age =
                +currentAge +
                +el.ageAttending -
                members[el.personAttending].age;
            }

            if (
              el.entity === entitiesGoals.ENTITY_PROTECT_LOVED &&
              members[el.personInsured]
            ) {
              age = +currentAge + +el.startAge - members[el.personInsured].age;
            }
          }

          if (age) {
            if (!marks[age]) {
              marks[age] = { goal: [], count: 0, countWhatIf: 0 };
            }

            if (!marks[age].goal) {
              marks[age] = [];
            }

            marks[age].goal.push(el);

            if (!el.isWhatIf) {
              marks[age].count++;
            }
            marks[age].countWhatIf++;
          }
        }
      });
    }

    if (eventsAll) {
      eventsAll.forEach(el => {
        if (!el.isDisabled) {
          const age = el.startAge;

          let isValueExists = false;
          let isNotActive = false;

          if (age) {
            const lastsYears = (el.occurs === "annual" && el.years) || 1;

            for (
              let calcedAge = age;
              calcedAge <= +age + +lastsYears - 1;
              calcedAge++
            ) {
              if (!marks[calcedAge]) {
                marks[calcedAge] = { event: [], count: 0, countWhatIf: 0 };
              }

              if (!marks[calcedAge].event) {
                marks[calcedAge].event = [];
              }

              if (
                +el.amount &&
                fullCalc[calcedAge] &&
                fullCalc[calcedAge].overdraft &&
                fullCalc[calcedAge].overdraft.assetsAll &&
                fullCalc[calcedAge].overdraft.assetsAll.events &&
                fullCalc[calcedAge].overdraft.assetsAll.events.moneyIn &&
                fullCalc[calcedAge].overdraft.assetsAll.events.moneyIn[el.id]
              ) {
                if (!isValueExists) {
                  isValueExists = true;
                }

                const eventCalc =
                  fullCalc[calcedAge].overdraft.assetsAll.events.moneyIn[el.id];

                if (!+eventCalc.value) {
                  isNotActive = true;
                }
              }

              if (
                isValueExists &&
                !fullCalc[calcedAge].overdraft.assetsAll.events.moneyIn[el.id]
              ) {
                isNotActive = true;
              }

              marks[calcedAge].event.push({ ...el, isNotActive });

              if (!el.isWhatIf) {
                marks[calcedAge].count++;
              }

              marks[calcedAge].countWhatIf++;
            }
          }
        }
      });
    }

    if (specialEvents) {
      Object.keys(specialEvents).forEach(age => {
        const specialEvent = specialEvents[age];

        if (!marks[age]) {
          marks[age] = { specialEvents: [], count: 0, countWhatIf: 0 };
        }

        if (!marks[age].specialEvents) {
          marks[age].specialEvents = [];
        }

        marks[age].specialEvents = specialEvent;

        marks[age].count++;
        marks[age].countWhatIf++;
      });
    }

    return marks;
  };

  handleResetStatCategory = category => ev => {
    this.setState({
      [category]: null
    });
  };

  handleSelectStatSubCategory = category => subcategory => ev => {
    this.setState({
      [category]: subcategory
    });
  };

  prepareChartData = ({ isOverdraft = false, isSecondary = false } = {}) => {
    const { propsState, dashboardChartTerm } = this.props;
    const { fullCalc } = propsState;

    const { currentAge } = propsState;
    // const chartTerm = isSecondary
    //   ? uiSettings.dashboardChartTermSecondary
    //   : dashboardChartTerm;
    const chartTerm = dashboardChartTerm;

    let maxCalcAge = MAX_AGE;

    if (+chartTerm === 10) {
      maxCalcAge = currentAge + 10 > MAX_AGE ? MAX_AGE : currentAge + 10;
    }
    if (+chartTerm === 20) {
      maxCalcAge = currentAge + 20 > MAX_AGE ? MAX_AGE : currentAge + 20;
    }
    if (+chartTerm === 50) {
      maxCalcAge = currentAge + 50 > MAX_AGE ? MAX_AGE : currentAge + 50;
    }

    if (chartTerm === "to100") {
      maxCalcAge = MAX_AGE;
    }

    const temp = {};

    for (let i = currentAge; i <= maxCalcAge; i++) {
      const fullData = isOverdraft
        ? fullCalc[i] && fullCalc[i].overdraft
          ? fullCalc[i].overdraft
          : fullCalc[i]
        : fullCalc[i];
      // temp[i] =
      //   fullCalc[i] && isOverdraft
      //     ? fullCalc[i].overdraft
      //     : fullCalc[i];
      temp[i] = fullData;
    }

    return temp;
  };

  _renderSummaryBlock = () => {
    const { uiSettings } = this.props;

    const { activeYear } = this.state;
    const advisorSummaryType = uiSettings.advisorSummaryType;

    // const chartData = dashboardNoOverdraft
    //   ? this.state.chartDataOverdraft
    //   : this.state.chartData;
    const chartData = this.state.chartDataOverdraft;

    const activeYears = chartData ? Object.keys(chartData) : [];

    return (
      <div className="dashboard__combine">
        <div className="stat-container">
          {this._renderStatSideToolbar()}

          <div className="stat-content">
            {advisorSummaryType === valuesStatTypes.SUMMARY &&
              this._renderStatisticsSummary()}

            {advisorSummaryType === valuesStatTypes.DETAILS &&
              this._renderStatistics()}

            {advisorSummaryType === valuesStatTypes.OPERATIONS && (
              <Operations
                {...this.props}
                isEmpty={false}
                isDashboard
                isCombineDashboard
                activeYear={activeYear}
                activeYears={activeYears}
              />
            )}

            {advisorSummaryType === valuesStatTypes.EVENTS && (
              <Events
                isDashboard
                isCombineDashboard
                activeYear={activeYear}
                activeYears={activeYears}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  _renderStatisticsSummary = () => {
    const { intl } = this.props;
    const { uiSettings, propsState, classes } = this.props;
    const { fullCalc } = propsState;

    const { activeYear } = this.state;

    if (!activeYear || !fullCalc) {
      return null;
    }

    // const currentData = dashboardNoOverdraft
    //   ? fullCalc[activeYear]
    //     ? fullCalc[activeYear].overdraft
    //     : null
    //   : fullCalc[activeYear];
    //
    // const currentDataWIf = dashboardNoOverdraft
    //   ? fullCalc[activeYear]
    //     ? fullCalc[activeYear].overdraft
    //     : null
    //   : fullCalc[activeYear];

    const currentData = fullCalc[activeYear] && fullCalc[activeYear].overdraft;

    const currentDataWIf =
      fullCalc[activeYear] && fullCalc[activeYear].overdraft;

    if (!currentData) {
      return null;
    }

    // const prevData = dashboardNoOverdraft
    //   ? fullCalc[activeYear - 1]
    //     ? fullCalc[activeYear - 1].overdraft
    //     : null
    //   : fullCalc[activeYear - 1] || null;
    //
    // const prevDataWIf = dashboardNoOverdraft
    //   ? fullCalc[activeYear - 1]
    //     ? fullCalc[activeYear - 1].overdraft
    //     : null
    //   : fullCalc[activeYear - 1] || null;

    const prevData =
      fullCalc[activeYear - 1] && fullCalc[activeYear - 1].overdraft;

    const prevDataWIf =
      fullCalc[activeYear - 1] && fullCalc[activeYear - 1].overdraft;

    const dashboardIfChecked =
      uiSettings.dashboardIfChecked ||
      (uiSettings.dashboardCompareTo && uiSettings.dashboardIfCheckedSecondary);

    const prevYearCompares = {};

    const chartsData = {
      SavingsMax: dashboardIfChecked
        ? Math.max(
            currentData.MoneyIn.all,
            currentData.MoneyIn.all,
            currentDataWIf.MoneyOutWhatIf.all,
            currentDataWIf.MoneyOutWhatIf.all
          ) * 1.15
        : Math.max(currentData.MoneyIn.all, currentData.MoneyOut.all) * 1.15,
      WealthMax: dashboardIfChecked
        ? Math.max(
            currentData.Assets.all,
            currentData.Liabilities.all,
            currentDataWIf.AssetsWhatIf.all,
            currentDataWIf.LiabilitiesWhatIf.all
          ) * 1.15
        : Math.max(currentData.Assets.all, currentData.Liabilities.all) * 1.15
    };

    chartsData.MoneyInPercent = parseInt(
      ((currentData.MoneyIn.all > 0 ? currentData.MoneyIn.all : 0) /
        chartsData.SavingsMax) *
        100,
      10
    );
    chartsData.MoneyInPercentWif = parseInt(
      ((currentDataWIf.MoneyInWhatIf.all > 0
        ? currentDataWIf.MoneyInWhatIf.all
        : 0) /
        chartsData.SavingsMax) *
        100,
      10
    );
    chartsData.MoneyOutPercent = parseInt(
      ((currentData.MoneyOut.all > 0 ? currentData.MoneyOut.all : 0) /
        chartsData.SavingsMax) *
        100,
      10
    );
    chartsData.MoneyOutPercentWif = parseInt(
      ((currentDataWIf.MoneyOutWhatIf.all > 0
        ? currentDataWIf.MoneyOutWhatIf.all
        : 0) /
        chartsData.SavingsMax) *
        100,
      10
    );

    chartsData.AssetsPercent = parseInt(
      ((currentData.Assets.all > 0 ? currentData.Assets.all : 0) /
        chartsData.WealthMax) *
        100,
      10
    );
    chartsData.AssetsPercentWif = parseInt(
      ((currentDataWIf.AssetsWhatIf.all > 0
        ? currentDataWIf.AssetsWhatIf.all
        : 0) /
        chartsData.WealthMax) *
        100,
      10
    );

    chartsData.LiabilitiesPercent = parseInt(
      ((currentData.Liabilities.all > 0 ? currentData.Liabilities.all : 0) /
        chartsData.WealthMax) *
        100,
      10
    );
    chartsData.LiabilitiesPercentWif = parseInt(
      ((currentDataWIf.LiabilitiesWhatIf.all > 0
        ? currentDataWIf.LiabilitiesWhatIf.all
        : 0) /
        chartsData.WealthMax) *
        100,
      10
    );

    chartsData.taxes = {
      taxable:
        parseInt(
          ((currentData.TaxCategories.taxable > 0
            ? currentData.TaxCategories.taxable
            : 0) /
            (currentData.TaxCategories.all || 0)) *
            100,
          10
        ) || 0,
      taxableWif:
        parseInt(
          ((currentDataWIf.TaxCategoriesWhatIf.taxable > 0
            ? currentDataWIf.TaxCategoriesWhatIf.taxable
            : 0) /
            currentDataWIf.TaxCategoriesWhatIf.all) *
            100,
          10
        ) || 0,
      taxAdvantage:
        parseInt(
          ((currentData.TaxCategories.taxAdvantage > 0
            ? currentData.TaxCategories.taxAdvantage
            : 0) /
            (currentData.TaxCategories.all || 0)) *
            100,
          10
        ) || 0,
      taxAdvantageWif:
        parseInt(
          ((currentDataWIf.TaxCategoriesWhatIf.taxAdvantage > 0
            ? currentDataWIf.TaxCategoriesWhatIf.taxAdvantage
            : 0) /
            currentDataWIf.TaxCategoriesWhatIf.all) *
            100,
          10
        ) || 0,
      taxDeferred:
        parseInt(
          ((currentData.TaxCategories.taxDeferred > 0
            ? currentData.TaxCategories.taxDeferred
            : 0) /
            (currentData.TaxCategories.all || 0)) *
            100,
          10
        ) || 0,
      taxDeferredWif:
        parseInt(
          ((currentDataWIf.TaxCategoriesWhatIf.taxDeferred > 0
            ? currentDataWIf.TaxCategoriesWhatIf.taxDeferred
            : 0) /
            currentDataWIf.TaxCategoriesWhatIf.all) *
            100,
          10
        ) || 0
    };

    const data = [
      {
        entity: "taxable",
        color: COLORS_PIE[0],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxable",
          defaultMessage: "Taxable now"
        }),
        value: currentData.TaxCategories.taxable
      },
      {
        entity: "taxDeferred",
        color: COLORS_PIE[1],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxDeferred",
          defaultMessage: "Tax deferred"
        }),
        value: currentData.TaxCategories.taxDeferred
      },
      {
        entity: "taxAdvantage",
        color: COLORS_PIE[2],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxAdvantage",
          defaultMessage: "Tax advantage"
        }),
        value: currentData.TaxCategories.taxAdvantage
      }
    ];
    const data2 = [
      {
        entity: "taxable",
        color: COLORS_PIE[0],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxable",
          defaultMessage: "Taxable now"
        }),
        value: currentDataWIf.TaxCategoriesWhatIf.taxable
      },
      {
        entity: "taxDeferred",
        color: COLORS_PIE[1],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxDeferred",
          defaultMessage: "Tax deferred"
        }),
        value: currentDataWIf.TaxCategoriesWhatIf.taxDeferred
      },
      {
        entity: "taxAdvantage",
        color: COLORS_PIE[2],
        name: intl.formatMessage({
          id: "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxAdvantage",
          defaultMessage: "Tax advantage"
        }),
        value: currentDataWIf.TaxCategoriesWhatIf.taxAdvantage
      }
    ];

    if (prevData) {
      if (
        prevData.MoneyIn.all - prevData.MoneyOut.all <
        currentData.MoneyIn.all - currentData.MoneyOut.all
      ) {
        prevYearCompares.Savings = "<";
      }
      if (
        prevData.MoneyIn.all - prevData.MoneyOut.all >
        currentData.MoneyIn.all - currentData.MoneyOut.all
      ) {
        prevYearCompares.Savings = ">";
      }

      if (
        prevDataWIf.MoneyInWhatIf.all - prevDataWIf.MoneyOutWhatIf.all <
        currentDataWIf.MoneyInWhatIf.all - currentDataWIf.MoneyOutWhatIf.all
      ) {
        prevYearCompares.SavingsWIf = "<";
      }
      if (
        prevDataWIf.MoneyInWhatIf.all - prevDataWIf.MoneyOutWhatIf.all >
        currentDataWIf.MoneyInWhatIf.all - currentDataWIf.MoneyOutWhatIf.all
      ) {
        prevYearCompares.SavingsWIf = ">";
      }
      /////////////////////////////
      if (
        prevData.Assets.all - prevData.Liabilities.all <
        currentData.Assets.all - currentData.Liabilities.all
      ) {
        prevYearCompares.Wealth = "<";
      }
      if (
        prevData.Assets.all - prevData.Liabilities.all >
        currentData.Assets.all - currentData.Liabilities.all
      ) {
        prevYearCompares.Wealth = ">";
      }
      if (
        prevDataWIf.AssetsWhatIf.all - prevDataWIf.LiabilitiesWhatIf.all <
        currentDataWIf.AssetsWhatIf.all - currentDataWIf.LiabilitiesWhatIf.all
      ) {
        prevYearCompares.WealthWIf = "<";
      }
      if (
        prevDataWIf.AssetsWhatIf.all - prevDataWIf.LiabilitiesWhatIf.all >
        currentDataWIf.AssetsWhatIf.all - currentDataWIf.LiabilitiesWhatIf.all
      ) {
        prevYearCompares.WealthWIf = ">";
      }
    }

    const yTickFormatter = val => {
      const digits = Math.abs(val) > 1000000 ? 1 : 0;
      return nFormatter(val, digits);
    };

    return (
      <div className="stat-summary">
        <div className="stat-summary__section">
          <div className="stat-summary__header">
            <span className="stat-summary__title">
              <FormattedMessage
                id="DASHBOARD.CHART.LABEL.TYPES.savings"
                defaultMessage="Savings"
              />
            </span>
            <div className="stat-summary__header-data">
              <div className="stat-summary__data stat-summary__data--head">
                {formatMoneyIntWithSign(
                  currentData.MoneyIn.all - currentData.MoneyOut.all
                )}
                <span
                  className={`stat-summary__data--comparison ${
                    prevYearCompares.Savings
                      ? prevYearCompares.Savings === ">"
                        ? "stat-summary__data--comparison-greater"
                        : "stat-summary__data--comparison-less"
                      : "stat-summary__data--comparison-hide"
                  }`}
                />
              </div>

              {dashboardIfChecked && (
                <div className="stat-summary__data stat-summary__data--brand">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                      defaultMessage="What-if"
                    />
                    {`  `}
                  </span>
                  {formatMoneyIntWithSign(
                    currentDataWIf.MoneyInWhatIf.all -
                      currentDataWIf.MoneyOutWhatIf.all
                  )}
                  <span
                    className={`stat-summary__data--comparison ${
                      prevYearCompares.SavingsWIf
                        ? prevYearCompares.SavingsWIf === ">"
                          ? "stat-summary__data--comparison-greater"
                          : "stat-summary__data--comparison-less"
                        : "stat-summary__data--comparison-hide"
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="stat-summary__item">
            <div className="stat-summary__item-content">
              <div className="stat-summary__item-content--std">
                <span className="stat-summary__label">
                  <FormattedMessage
                    id="DASHBOARD.STATISTIC.LABEL.MONEY_IN"
                    defaultMessage="Income"
                  />
                </span>
                <div className="stat-summary__data stat-summary__data--green stat-summary__data--item text-left">
                  {formatMoneyIntWithSign(currentData.MoneyIn.all)}
                </div>

                <LinearProgress
                  variant={"determinate"}
                  classes={{
                    colorPrimary: classes.linearColorGreen,
                    barColorPrimary: classes.linearBarColorGreen
                  }}
                  value={chartsData.MoneyInPercent}
                />
              </div>
              {dashboardIfChecked && (
                <div className="stat-summary__item-content--wif text-right">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="APP.WHAT_IF"
                      defaultMessage="What-If"
                    />
                  </span>

                  <div className="stat-summary__data stat-summary__data--brand stat-summary__data--item">
                    {formatMoneyIntWithSign(currentDataWIf.MoneyInWhatIf.all)}
                  </div>

                  <LinearProgress
                    variant={"determinate"}
                    classes={{
                      colorPrimary: classes.linearColorBrand,
                      barColorPrimary: classes.linearBarColorBrand
                    }}
                    value={chartsData.MoneyInPercentWif}
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="stat-summary__item">
            <div className="stat-summary__item-content">
              <div className="stat-summary__item-content--std">
                <span className="stat-summary__label">
                  <FormattedMessage
                    id="DASHBOARD.STATISTIC.LABEL.MONEY_OUT"
                    defaultMessage="Income"
                  />
                </span>
                <div className="stat-summary__data stat-summary__data--yellow stat-summary__data--item text-left">
                  {formatMoneyIntWithSign(currentData.MoneyOut.all)}
                </div>

                <LinearProgress
                  variant={"determinate"}
                  classes={{
                    colorPrimary: classes.linearColorYellow,
                    barColorPrimary: classes.linearBarColorYellow
                  }}
                  value={chartsData.MoneyOutPercent}
                />
              </div>
              {dashboardIfChecked && (
                <div className="stat-summary__item-content--wif text-right">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="APP.WHAT_IF"
                      defaultMessage="What-If"
                    />
                  </span>

                  <div className="stat-summary__data stat-summary__data--brand stat-summary__data--item">
                    {formatMoneyIntWithSign(currentDataWIf.MoneyOutWhatIf.all)}
                  </div>

                  <LinearProgress
                    variant={"determinate"}
                    classes={{
                      colorPrimary: classes.linearColorBrand,
                      barColorPrimary: classes.linearBarColorBrand
                    }}
                    value={chartsData.MoneyOutPercentWif}
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="stat-summary__section">
          <div className="stat-summary__header">
            <span className="stat-summary__title">
              <FormattedMessage
                id="DASHBOARD.STATISTIC.LABEL.GROUP.WEALTH"
                defaultMessage="Wealth"
              />
            </span>
            <div className="stat-summary__header-data">
              <div className="stat-summary__data stat-summary__data--head">
                {formatMoneyIntWithSign(
                  currentData.Assets.all - currentData.Liabilities.all
                )}
                <span
                  className={`stat-summary__data--comparison ${
                    prevYearCompares.Wealth
                      ? prevYearCompares.Wealth === ">"
                        ? "stat-summary__data--comparison-greater"
                        : "stat-summary__data--comparison-less"
                      : "stat-summary__data--comparison-hide"
                  }`}
                />
              </div>

              {dashboardIfChecked && (
                <div className="stat-summary__data stat-summary__data--brand">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                      defaultMessage="What-if"
                    />
                    {`  `}
                  </span>
                  {formatMoneyIntWithSign(
                    currentData.AssetsWhatIf.all -
                      currentData.LiabilitiesWhatIf.all
                  )}
                  <span
                    className={`stat-summary__data--comparison ${
                      prevYearCompares.WealthWIf
                        ? prevYearCompares.WealthWIf === ">"
                          ? "stat-summary__data--comparison-greater"
                          : "stat-summary__data--comparison-less"
                        : "stat-summary__data--comparison-hide"
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="stat-summary__item">
            <div className="stat-summary__item-content">
              <div className="stat-summary__item-content--std">
                <span className="stat-summary__label">
                  <FormattedMessage
                    id="DASHBOARD.STATISTIC.LABEL.ASSETS"
                    defaultMessage="Assets"
                  />
                </span>
                <div className="stat-summary__data stat-summary__data--green stat-summary__data--item text-left">
                  {formatMoneyIntWithSign(currentData.Assets.all)}
                </div>

                <LinearProgress
                  variant={"determinate"}
                  classes={{
                    colorPrimary: classes.linearColorGreen,
                    barColorPrimary: classes.linearBarColorGreen
                  }}
                  value={chartsData.AssetsPercent}
                />
              </div>
              {dashboardIfChecked && (
                <div className="stat-summary__item-content--wif text-right">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="APP.WHAT_IF"
                      defaultMessage="What-If"
                    />
                  </span>

                  <div className="stat-summary__data stat-summary__data--brand stat-summary__data--item">
                    {formatMoneyIntWithSign(currentDataWIf.AssetsWhatIf.all)}
                  </div>

                  <LinearProgress
                    variant={"determinate"}
                    classes={{
                      colorPrimary: classes.linearColorBrand,
                      barColorPrimary: classes.linearBarColorBrand
                    }}
                    value={chartsData.AssetsPercentWif}
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="stat-summary__item">
            <div className="stat-summary__item-content">
              <div className="stat-summary__item-content--std">
                <span className="stat-summary__label">
                  <FormattedMessage
                    id="DASHBOARD.STATISTIC.LABEL.LIABILITIES"
                    defaultMessage="Debt"
                  />
                </span>
                <div className="stat-summary__data stat-summary__data--yellow stat-summary__data--item text-left">
                  {formatMoneyIntWithSign(currentData.Liabilities.all)}
                </div>

                <LinearProgress
                  variant={"determinate"}
                  classes={{
                    colorPrimary: classes.linearColorYellow,
                    barColorPrimary: classes.linearBarColorYellow
                  }}
                  value={chartsData.LiabilitiesPercent}
                />
              </div>
              {dashboardIfChecked && (
                <div className="stat-summary__item-content--wif text-right">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="APP.WHAT_IF"
                      defaultMessage="What-If"
                    />
                  </span>

                  <div className="stat-summary__data stat-summary__data--brand stat-summary__data--item">
                    {formatMoneyIntWithSign(
                      currentDataWIf.LiabilitiesWhatIf.all
                    )}
                  </div>

                  <LinearProgress
                    variant={"determinate"}
                    classes={{
                      colorPrimary: classes.linearColorBrand,
                      barColorPrimary: classes.linearBarColorBrand
                    }}
                    value={chartsData.LiabilitiesPercentWif}
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="stat-summary__section stat-summary__section--short">
          <div className="stat-summary__header">
            <span className="stat-summary__title">
              <FormattedMessage
                id="DASHBOARD.STATISTIC.LABEL.GROUP.ASSETS"
                defaultMessage="Assets"
              />
            </span>
            <div className="stat-summary__header-data">
              <div className="stat-summary__data stat-summary__data--head">
                {formatMoneyIntWithSign(currentData.TaxCategories.all)}
              </div>

              {dashboardIfChecked && (
                <div className="stat-summary__data stat-summary__data--brand">
                  <span className="stat-summary__data stat-summary__data--caption">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                      defaultMessage="What-if"
                    />
                    {`  `}
                  </span>
                  {formatMoneyIntWithSign(
                    currentDataWIf.TaxCategoriesWhatIf.all
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="stat-summary__item stat-summary__item--tax">
            <div className="stat-summary__item-content">
              <div className="stat-summary__item-content--std">
                <div
                  className={`stat-summary__tax-item stat-summary__tax-item--blue  ${
                    this.state.pieTooltipActive === "taxable"
                      ? "stat-summary__tax-item--active"
                      : ""
                  }`}
                >
                  <span className="stat-summary__label">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxable"
                      defaultMessage="Taxable now"
                    />
                  </span>
                  <div>
                    <span className="stat-summary__data stat-summary__data--green">
                      {chartsData.taxes.taxable}%
                    </span>
                    {dashboardIfChecked && (
                      <span className="stat-summary__data">
                        {` / `}
                        <span className="stat-summary__data stat-summary__data--brand">
                          {chartsData.taxes.taxableWif}%
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={`stat-summary__tax-item stat-summary__tax-item--green ${
                    this.state.pieTooltipActive === "taxDeferred"
                      ? "stat-summary__tax-item--active"
                      : ""
                  }`}
                >
                  <span className="stat-summary__label">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxDeferred"
                      defaultMessage="Tax deferred"
                    />
                  </span>
                  <div>
                    <span className="stat-summary__data stat-summary__data--green">
                      {chartsData.taxes.taxDeferred}%
                    </span>
                    {dashboardIfChecked && (
                      <span className="stat-summary__data">
                        {` / `}
                        <span className="stat-summary__data stat-summary__data--brand">
                          {chartsData.taxes.taxDeferredWif}%
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={`stat-summary__tax-item stat-summary__tax-item--light-yellow ${
                    this.state.pieTooltipActive === "taxAdvantage"
                      ? "stat-summary__tax-item--active"
                      : ""
                  }`}
                >
                  <span className="stat-summary__label">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.taxAdvantage"
                      defaultMessage="Tax advantage"
                    />
                  </span>

                  <div>
                    <span className="stat-summary__data stat-summary__data--green">
                      {chartsData.taxes.taxAdvantage}%
                    </span>
                    {dashboardIfChecked && (
                      <span className="stat-summary__data">
                        {` / `}
                        <span className="stat-summary__data stat-summary__data--brand">
                          {chartsData.taxes.taxAdvantageWif}%
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="stat-summary__item-content--std"
                style={{ position: "relative" }}
              >
                {dashboardIfChecked && (
                  <div className="stat-summary__chart-wif">
                    <FormattedMessage
                      id="APP.WHAT_IF"
                      defaultMessage="WHAT-IF"
                    />
                  </div>
                )}
                <PieChart width={155} height={155}>
                  <Tooltip
                    // isAnimationActive={false}
                    labelFormatter={yTickFormatter}
                    content={this._renderPieTooltip({
                      currentData,
                      currentDataWIf,
                      dashboardIfChecked
                    })}
                  />
                  {dashboardIfChecked && (
                    <Pie
                      // isAnimationActive={false}
                      dataKey="value"
                      data={data2}
                      innerRadius={33}
                      outerRadius={49}
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={entry.entity}
                          fill={entry.color}
                          stroke="transparent"
                        />
                      ))}
                    </Pie>
                  )}

                  <Pie
                    // isAnimationActive={false}
                    dataKey="value"
                    data={data}
                    innerRadius={59}
                    outerRadius={75}
                  >
                    {data2.map((entry, index) => (
                      <Cell key={entry.entity} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderPieTooltip = ({
    currentData,
    currentDataWIf,
    dashboardIfChecked
  }) => props => {
    const { payload } = props;

    if (!payload) {
      return null;
    }

    const innerPayload = payload[0];
    if (!innerPayload) {
      return null;
    }
    const entity = innerPayload.payload.entity;

    if (
      props.active &&
      (!this.state.pieTooltipActive || this.state.pieTooltipActive !== entity)
    ) {
      this.setState({
        pieTooltipActive: entity
      });
    }

    if (!props.active && this.state.pieTooltipActive) {
      this.setState({
        pieTooltipActive: entity
      });
    }

    return (
      <section className="recharts-default-tooltip tooltip-pie">
        <div className="tooltip-pie__standard">
          {formatMoneyIntWithSign(currentData.TaxCategories[entity])}
        </div>

        {dashboardIfChecked && (
          <div className="tooltip-pie__wif">
            <div className="tooltip-pie__wif--label">
              <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-If" />
            </div>
            {formatMoneyIntWithSign(currentDataWIf.TaxCategoriesWhatIf[entity])}
          </div>
        )}
      </section>
    );
  };

  _renderStatistics = () => {
    const { uiSettings, propsState } = this.props;
    const { fullCalc } = propsState;
    const isDetailed =
      uiSettings.advisorSummaryType === valuesStatTypes.DETAILS;

    const { activeYear } = this.state;

    if (!activeYear || !fullCalc) {
      return null;
    }

    // const currentData = dashboardNoOverdraft
    //   ? fullCalc[activeYear]
    //     ? fullCalc[activeYear].overdraft
    //     : null
    //   : fullCalc[activeYear];
    //
    // const currentDataWIf = dashboardNoOverdraft
    //   ? fullCalc[activeYear]
    //     ? fullCalc[activeYear].overdraft
    //     : null
    //   : fullCalc[activeYear];
    const currentData = fullCalc[activeYear] && fullCalc[activeYear].overdraft;

    const currentDataWIf =
      fullCalc[activeYear] && fullCalc[activeYear].overdraft;

    // const prevData = dashboardNoOverdraft
    //   ? fullCalc[activeYear - 1]
    //     ? fullCalc[activeYear - 1].overdraft
    //     : null
    //   : fullCalc[activeYear - 1] || null;
    //
    // const prevDataWIf = dashboardNoOverdraft
    //   ? fullCalc[activeYear - 1]
    //     ? fullCalc[activeYear - 1].overdraft
    //     : null
    //   : fullCalc[activeYear - 1] || null;

    if (!currentData) {
      return null;
    }

    const dashboardIfChecked =
      uiSettings.dashboardIfChecked ||
      (uiSettings.dashboardCompareTo && uiSettings.dashboardIfCheckedSecondary);

    return (
      <Grid
        className={`statistic ${!isDetailed ? "statistic--short" : ""}`}
        container
        spacing={8}
        alignItems="stretch"
      >
        <Grid
          className="statistic"
          lg={9}
          item
          container
          spacing={8}
          alignItems="stretch"
        >
          <Grid
            item
            container
            xs={12}
            lg={6}
            className={`statistic__grid-item-group ${
              !isDetailed ? "statistic__grid-item-group--short" : ""
            }`}
          >
            <div className="statistic__item">
              <div
                className={`statistic__item-header statistic__item-header-icon ${
                  !isDetailed ? "statistic__item-header-short" : ""
                }`}
              >
                <div className="statistic__item-icon">
                  <IconContainer color={"green"}>
                    <Icons.IconSaving color={"green"} width={24} height={24} />
                  </IconContainer>
                </div>

                <div className="statistic__item-row">
                  <div className="statistic__item-title">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.GROUP.SAVINGS"
                      defaultMessage="Savings"
                    />
                  </div>

                  {dashboardIfChecked && (
                    <div className="statistic__item-title statistic__item-title--if">
                      <FormattedMessage
                        id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                        defaultMessage="What-If"
                      />
                    </div>
                  )}
                </div>

                <div className="statistic__item-row">
                  <div className="statistic__item-count">
                    $
                    {formatMoneyInt(
                      currentData.MoneyIn.all - currentData.MoneyOut.all
                    )}
                  </div>

                  {dashboardIfChecked && (
                    <div className="statistic__item-count statistic__index-count--if">
                      $
                      {formatMoneyInt(
                        currentDataWIf.MoneyInWhatIf.all -
                          currentDataWIf.MoneyOutWhatIf.all
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="statistic__item-body statistic__item-body--group">
                <Statistic
                  reactResizeHeader={this.handleSetHeightStatHeader(
                    "statTableHead1_1"
                  )}
                  maxHeight={
                    this.state.statTableHead1_1 > STAT_HEADER_HEIGHT ||
                    this.state.statTableHead1_2 > STAT_HEADER_HEIGHT
                      ? Math.max(
                          this.state.statTableHead1_1,
                          this.state.statTableHead1_2
                        )
                      : null
                  }
                  data={{
                    default: currentData.MoneyIn,
                    whatIf: currentDataWIf.MoneyInWhatIf
                  }}
                  header="Money In"
                  headerI18n="DASHBOARD.STATISTIC.LABEL.MONEY_IN"
                  transKey="DASHBOARD.STATISTIC.LABEL.MONEY_IN."
                  icon="IconMoneyIn"
                  iconColor="green"
                  isWhatIf={dashboardIfChecked}
                  isDetailed={isDetailed}
                />

                {!isDetailed || !this.state.moneyOutStatState ? (
                  <Statistic
                    reactResizeHeader={this.handleSetHeightStatHeader(
                      "statTableHead1_2"
                    )}
                    maxHeight={
                      this.state.statTableHead1_1 > STAT_HEADER_HEIGHT ||
                      this.state.statTableHead1_2 > STAT_HEADER_HEIGHT
                        ? Math.max(
                            this.state.statTableHead1_1,
                            this.state.statTableHead1_2
                          )
                        : null
                    }
                    data={{
                      default: currentData.MoneyOut,
                      whatIf: currentDataWIf.MoneyOutWhatIf
                    }}
                    header="Money Out"
                    headerI18n="DASHBOARD.STATISTIC.LABEL.MONEY_OUT"
                    transKey="DASHBOARD.STATISTIC.LABEL.MONEY_OUT."
                    icon="IconMoneyOut"
                    iconColor="yellow"
                    isWhatIf={dashboardIfChecked}
                    subCategoryState={subCategoryState.moneyOutStatState}
                    handleSelectStatCategory={this.handleSelectStatSubCategory(
                      "moneyOutStatState"
                    )}
                    isDetailed={isDetailed}
                  />
                ) : (
                  <>
                    <Statistic
                      data={{
                        default:
                          currentData.MoneyOutDetail[
                            this.state.moneyOutStatState
                          ],
                        whatIf:
                          currentDataWIf.MoneyOutDetailWhatIf[
                            this.state.moneyOutStatState
                          ]
                      }}
                      header="Estimated Taxes"
                      headerI18n={`DASHBOARD.STATISTIC.LABEL.MONEY_OUT.${this.state.moneyOutStatState}`}
                      transKey="DASHBOARD.STATISTIC.LABEL.MONEY_OUT."
                      icon="IconDeferredComp"
                      iconColor="yellow"
                      isWhatIf={dashboardIfChecked}
                      handleResetStatCategory={this.handleResetStatCategory(
                        "moneyOutStatState"
                      )}
                      isDetailed
                    />
                  </>
                )}
              </div>
              {!isDetailed && (
                <div
                  className="statistic__item-body--caption"
                  onClick={this.handleChangeToDetailMode}
                >
                  <span>
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.SWITCH_TO_DETAIL"
                      defaultMessage="Switch to details view for more information"
                    />
                    <Icons.IconArrowDown color="ui" />
                  </span>
                </div>
              )}
            </div>
          </Grid>

          <Grid
            item
            container
            xs={12}
            lg={6}
            className={`statistic__grid-item-group ${
              !isDetailed ? "statistic__grid-item-group--short" : ""
            }`}
          >
            <div className="statistic__item">
              <div className="statistic__item-header statistic__item-header-icon">
                <div className="statistic__item-icon">
                  <IconContainer color={"green"}>
                    <Icons.IconDeferredComp
                      color={"green"}
                      width={24}
                      height={24}
                    />
                  </IconContainer>
                </div>

                <div className="statistic__item-row">
                  <div className="statistic__item-title">
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.LABEL.GROUP.NET_WEALTH"
                      defaultMessage="Net Wealth"
                    />
                  </div>

                  {dashboardIfChecked && (
                    <div className="statistic__item-title statistic__item-title--if">
                      <FormattedMessage
                        id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                        defaultMessage="What-If"
                      />
                    </div>
                  )}
                </div>
                <div className="statistic__item-row">
                  <div className="statistic__item-count ">
                    $
                    {formatMoneyInt(
                      currentData.Assets.all - currentData.Liabilities.all
                    )}
                  </div>

                  {dashboardIfChecked && (
                    <div className="statistic__item-count statistic__index-count--if">
                      $
                      {formatMoneyInt(
                        currentDataWIf.AssetsWhatIf.all -
                          currentDataWIf.LiabilitiesWhatIf.all
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="statistic__item-body statistic__item-body--group">
                {!isDetailed || !this.state.assetsStatState ? (
                  <Statistic
                    reactResizeHeader={this.handleSetHeightStatHeader(
                      "statTableHead2_1"
                    )}
                    maxHeight={
                      this.state.statTableHead2_1 > STAT_HEADER_HEIGHT ||
                      this.state.statTableHead2_2 > STAT_HEADER_HEIGHT
                        ? Math.max(
                            this.state.statTableHead2_1,
                            this.state.statTableHead2_2
                          )
                        : null
                    }
                    data={{
                      default: currentData.Assets,
                      whatIf: currentDataWIf.AssetsWhatIf
                    }}
                    header="Assets"
                    headerI18n="DASHBOARD.STATISTIC.LABEL.ASSETS"
                    transKey="DASHBOARD.STATISTIC.LABEL.ASSETS."
                    icon="IconCarHome"
                    iconColor="green"
                    isWhatIf={dashboardIfChecked}
                    subCategoryState={subCategoryState.assetsStatState}
                    handleSelectStatCategory={this.handleSelectStatSubCategory(
                      "assetsStatState"
                    )}
                    isDetailed={isDetailed}
                  />
                ) : (
                  <Statistic
                    data={{
                      default:
                        currentData.AssetsDetail[this.state.assetsStatState],
                      whatIf:
                        currentDataWIf.AssetsDetailWhatIf[
                          this.state.assetsStatState
                        ]
                    }}
                    header="Assets"
                    headerI18n={`DASHBOARD.STATISTIC.LABEL.ASSETS.${this.state.assetsStatState}`}
                    transKey="DASHBOARD.STATISTIC.LABEL.ASSETS."
                    icon="IconCarHome"
                    iconColor="green"
                    isWhatIf={dashboardIfChecked}
                    handleResetStatCategory={this.handleResetStatCategory(
                      "assetsStatState"
                    )}
                    isDetailed
                  />
                )}

                <Statistic
                  reactResizeHeader={this.handleSetHeightStatHeader(
                    "statTableHead2_2"
                  )}
                  maxHeight={
                    // this.state.statTableHead2_1 !==
                    //   this.state.statTableHead2_2 &&
                    this.state.statTableHead2_1 > STAT_HEADER_HEIGHT ||
                    this.state.statTableHead2_2 > STAT_HEADER_HEIGHT
                      ? Math.max(
                          this.state.statTableHead2_1,
                          this.state.statTableHead2_2
                        )
                      : null
                  }
                  headersHeight={{
                    statTableHead2_1: this.state.statTableHead2_1,
                    statTableHead2_2: this.state.statTableHead2_2
                  }}
                  data={{
                    default: currentData.Liabilities,
                    whatIf: currentDataWIf.LiabilitiesWhatIf
                  }}
                  header="Liabilities"
                  headerI18n="DASHBOARD.STATISTIC.LABEL.LIABILITIES"
                  transKey="DASHBOARD.STATISTIC.LABEL.LIABILITIES."
                  icon="IconMoneyOut"
                  iconColor="yellow"
                  isWhatIf={dashboardIfChecked}
                  isDetailed={isDetailed}
                />
              </div>

              {!isDetailed && (
                <div
                  className="statistic__item-body--caption"
                  onClick={this.handleChangeToDetailMode}
                >
                  <span>
                    <FormattedMessage
                      id="DASHBOARD.STATISTIC.SWITCH_TO_DETAIL"
                      defaultMessage="Switch to details view for more information"
                    />
                    <Icons.IconArrowDown color="ui" />
                  </span>
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          className="statistic"
          lg={3}
          item
          container
          spacing={8}
          alignItems="stretch"
        >
          <Grid item container lg={12} className="statistic__grid-item-group">
            {!isDetailed || !this.state.taxCategoryStatState ? (
              <Statistic
                data={{
                  default: currentData.TaxCategories,
                  whatIf: currentDataWIf.TaxCategoriesWhatIf
                }}
                header="Assets Tax Categories"
                headerI18n="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES"
                transKey="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES."
                icon="IconAccount"
                iconColor="green"
                isShowGraph={isDetailed}
                isWhatIf={dashboardIfChecked}
                subCategoryState={subCategoryState.taxCategoryStatState}
                handleSelectStatCategory={this.handleSelectStatSubCategory(
                  "taxCategoryStatState"
                )}
                isDetailed={isDetailed}
                isTaxes
              />
            ) : (
              <>
                <Statistic
                  data={{
                    default:
                      currentData.AssetsDetail[this.state.taxCategoryStatState],
                    whatIf:
                      currentDataWIf.AssetsDetailWhatIf[
                        this.state.taxCategoryStatState
                      ]
                  }}
                  headerI18n={`DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.${this.state.taxCategoryStatState}`}
                  transKey="DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES."
                  icon="IconAccount"
                  iconColor="green"
                  isWhatIf={dashboardIfChecked}
                  handleResetStatCategory={this.handleResetStatCategory(
                    "taxCategoryStatState"
                  )}
                  isDetailed
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  _renderCharts = () => {
    const {
      dashboardChartTerm,
      dashboardAgeYear,
      dashboardIfChecked,
      propsState,
      dashboardWealthIncome,
      uiSettings,
      family,
      profile,
      isMobile
    } = this.props;
    const { fullCalc, lifeEvents } = propsState;

    const { activeYear, currentAge, forms } = this.state;

    // const houses = fullCalc.overdraft.assetsAll.use.home;

    let independenceAgeObject = {
      independenceAgeWhatIf:
        forms.financialIndependence.calculatedIndependenceAgeWhatIf,
      independenceAge: forms.financialIndependence.calculatedIndependenceAge
    };

    if (
      independenceAgeObject.independenceAgeWhatIf &&
      fullCalc[independenceAgeObject.independenceAgeWhatIf]
    ) {
      const housesWhatIf =
        fullCalc[independenceAgeObject.independenceAgeWhatIf].overdraft
          .assetsAllWhatIf.use.home;

      if (housesWhatIf) {
        const primaryHome = Object.values(housesWhatIf).find(
          el => el.isHomePrimary && el.value === 0
        );

        if (primaryHome && primaryHome.value === 0) {
          independenceAgeObject.primaryHomeSoldWhatIf = true;
        }
      }
    }

    if (
      independenceAgeObject.independenceAge &&
      fullCalc[independenceAgeObject.independenceAge]
    ) {
      const houses =
        fullCalc[independenceAgeObject.independenceAge].overdraft.assetsAll.use
          .home;

      if (houses) {
        const primaryHome = Object.values(houses).find(el => el.isHomePrimary);

        if (primaryHome && primaryHome.value === 0) {
          independenceAgeObject.primaryHomeSold = true;
        }
      }
    }

    const chartMarks = this.state.marks;

    if (!activeYear || !fullCalc) {
      return null;
    }

    const chartDataOverdraft = this.state.chartDataOverdraft;
    const chartDataOverdraftSecondary = this.state.chartDataOverdraftSecondary;
    const chartDataSecondary = this.state.chartDataSecondary;
    const chartData = this.state.chartData;

    // this.prepareEventsIsActive();

    return (
      <Grid className="graphics" container spacing={8}>
        <Grid item container xs={12} lg={12} className="statistic__grid-item">
          <Charts
            setUI={this.props.setUI}
            isMobile={isMobile}
            data={chartData}
            dataOverdraft={chartDataOverdraft}
            dataOverdraftSecondary={chartDataOverdraftSecondary}
            dataSecondary={chartDataSecondary}
            isWhatIfShow={dashboardIfChecked}
            isIncome={dashboardWealthIncome}
            dashboardAgeYear={dashboardAgeYear}
            chartMarks={chartMarks}
            lifeEvents={lifeEvents}
            activeYear={this.state.activeYear}
            handleSetActiveYear={this.handleSetActiveYear}
            isHighlight={dashboardChartTerm === "highlights"}
            containerWidth={this.state.containerWidth}
            dashboardChartTerm={dashboardChartTerm}
            currentAge={currentAge}
            // TimeLineTermElement={TimeLineTermElement}
            uiSettings={uiSettings}
            family={family}
            profile={profile}
            handleOpenEditGoal={this.handleOpenEditGoal}
            handleOpenForm={this.handleOpenForm}
            handleSetAddTileDialogType={this.handleSetAddTileDialogType}
            handleAddTileGoalDialogOpen={this.handleAddTileGoalDialogOpen}
            independenceAgeObject={independenceAgeObject}
          />
        </Grid>
      </Grid>
    );
  };

  handleToggleAddForm = (form, { globalType } = {}) => ev => {
    ev.stopPropagation();

    const conf = {
      ...entitiesScheme[globalType],
      assetType: form
    };

    this.handleOpenForm(null, conf);
  };

  _renderAddTile = globalType => {
    const { isMobile } = this.props;
    const isAccessEdit = true; // TODO

    return (
      <AddTile
        ENTITIES_GROUPS={entitiesScheme[globalType].ENTITIES_GROUPS}
        ENTITIES={entitiesScheme[globalType].entities}
        globalType={entitiesScheme[globalType].assetGlobalType}
        isAccessEdit={isAccessEdit}
        designScheme={entitiesScheme[globalType].designScheme}
        isMobile={isMobile}
        translations={entitiesScheme[globalType].translations}
        handleToggleAddForm={this.handleToggleAddForm}
        isAlwaysShow
        isDialog
      />
    );
  };

  _renderStatSideToolbar = isMain => {
    const { uiSettings } = this.props;

    const tabs = (
      <ul className="toolbar-stat__tabs">
        <li className="toolbar-stat__tab ">
          <button
            disabled={uiSettings.advisorSummaryType === valuesStatTypes.SUMMARY}
            className={`toolbar-stat__tab-button ${
              uiSettings.advisorSummaryType === valuesStatTypes.SUMMARY
                ? "toolbar-stat__tab-button--active"
                : ""
            }`}
            onClick={this.handleChangeStatTab(valuesStatTypes.SUMMARY)}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.STAT.TYPES.SUMMARY"
              defaultMessage="Summary"
            />
          </button>
        </li>

        <li className="toolbar-stat__tab">
          <button
            disabled={uiSettings.advisorSummaryType === valuesStatTypes.DETAILS}
            className={`toolbar-stat__tab-button ${
              uiSettings.advisorSummaryType === valuesStatTypes.DETAILS
                ? "toolbar-stat__tab-button--active"
                : ""
            }`}
            onClick={this.handleChangeStatTab(valuesStatTypes.DETAILS)}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.STAT.TYPES.DETAILS"
              defaultMessage="Details"
            />
          </button>
        </li>

        <li className="toolbar-stat__tab">
          <button
            disabled={
              uiSettings.advisorSummaryType === valuesStatTypes.OPERATIONS
            }
            className={`toolbar-stat__tab-button ${
              uiSettings.advisorSummaryType === valuesStatTypes.OPERATIONS
                ? "toolbar-stat__tab-button--active"
                : ""
            }`}
            onClick={this.handleChangeStatTab(valuesStatTypes.OPERATIONS)}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.STAT.TYPES.OPERATIONS"
              defaultMessage="Multi-Year"
            />
          </button>
        </li>

        <li className="toolbar-stat__tab">
          <button
            disabled={uiSettings.advisorSummaryType === valuesStatTypes.EVENTS}
            className={`toolbar-stat__tab-button ${
              uiSettings.advisorSummaryType === valuesStatTypes.EVENTS
                ? "toolbar-stat__tab-button--active"
                : ""
            }`}
            onClick={this.handleChangeStatTab(valuesStatTypes.EVENTS)}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.STAT.TYPES.EVENTS"
              defaultMessage="Events"
            />
          </button>
        </li>
      </ul>
    );

    // const getIsAccessEditByType = globalType => {
    //   return getIsAccessEditView(globalType, entitiesScheme, rights, {
    //     auth,
    //     profileProvider,
    //     isEdit: true
    //   });
    // };
    //
    // const addButtons = (
    //   <div className="toolbar-buttons">
    //     {this._renderAddCategoriesMenu()}
    //
    //     {getIsAccessEditByType(globalTypeGoal) && (
    //       <div className="toolbar-buttons__add">
    //         <Button
    //           variant={"outlined"}
    //           className={classes.ButtonOutUi}
    //           fullWidth
    //           onClick={this.handleSetAddTileDialogType(globalTypeGoal)}
    //         >
    //           <FormattedMessage
    //             id="DASHBOARD.MAIN.ADD_ASSET.GOAL"
    //             defaultMessage="+ Set Goal"
    //           />
    //         </Button>
    //       </div>
    //     )}
    //
    //     {getIsAccessEditByType(globalTypeEvents) && (
    //       <div className="toolbar-buttons__add">
    //         <Button
    //           variant={"outlined"}
    //           className={classes.ButtonOutBrand}
    //           fullWidth
    //           onClick={this.handleSetAddTileDialogType(globalTypeEvents)}
    //         >
    //           <FormattedMessage
    //             id="DASHBOARD.MAIN.ADD_ASSET.EVENT"
    //             defaultMessage="+ Future Event"
    //           />
    //         </Button>
    //       </div>
    //     )}
    //   </div>
    // );

    return <section className="chart-toolbar toolbar-stat">{tabs}</section>;
  };

  handleMenuAddCatMenuOpen = menu => ev => {
    const { currentTarget } = ev;
    this.setState({ [menu]: currentTarget });
  };

  handleMenuAddCatMenuClose = menu => () => {
    this.setState({ [menu]: null });
  };

  handleTileGoalFormDialogClose = () => {
    this.setState({
      assetAddTileGoalType: false,
      isAssetFormOpen: false,
      formProps: {}
    });
  };

  handleAddTileGoalDialogClose = () => {
    this.setState({
      assetAddTileGoal: false,
      assetAddTile: null,
      assetAddTileCustomValues: null
    });
  };

  handleAddTileGoalDialogOpen = () => {
    this.setState({ assetAddTileGoal: true, assetAddTileGoalType: false });
  };

  handleAddTileDialogClose = () => {
    this.setState({ assetAddTile: null, assetAddTileCustomValues: null });
  };

  handleSetAddTileDialogType = (
    globalType,
    { customValues = null, returnToTypes } = {}
  ) => () => {
    this.setState({
      assetAddTile: globalType,
      assetAddTileCustomValues: customValues,
      anchorElAddPayments: false,
      anchorElAddAssetsOwned: false,
      anchorElAddCat: false,
      returnToTypes
    });
  };

  saveOrDeleteAllWhatIf = (assets, { isDelete = false } = {}) => () => {
    const { firestore } = this.props;

    const batch = firestore.batch();
    this.setState({
      isSavingAllAsStandard: true
    });

    assets.forEach(asset => {
      if (asset && asset.isWhatIf && asset.id) {
        const schemeCategory = entitiesScheme[asset.globalType];

        if (schemeCategory) {
          const assetCollection = schemeCategory.assetCollection;

          const docRef = firestore
            .collection(assetCollection || "userAssets")
            .doc(asset.id);

          if (isDelete) {
            batch.delete(docRef);
          } else {
            batch.update(docRef, {
              isWhatIf: false
            });
          }
        }
      }
    });

    batch
      .commit()
      .then(res => {
        this.setState({ isSavingAllAsStandard: false, allWhatIfDialog: false });
        if (isDelete) {
          toast(
            <FormattedMessage
              id="DASHBOARD.MAIN.WHAT_IF.REMOVED_ALL"
              defaultMessage="All assets erased"
            />
          );
        } else {
          toast(
            <FormattedMessage
              id="DASHBOARD.MAIN.WHAT_IF.SAVED_ALL_STANDARD"
              defaultMessage="All assets saved as standard"
            />
          );
        }
      })
      .catch(() => {
        this.setState({ isSavingAllAsStandard: false });

        toast(
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage="Error has occurred"
          />
        );
      });
  };

  _renderAssetAddTile = () => {
    if (globalTypes.globalTypeGoals === this.state.assetAddTile) {
      return this._renderAssetAddTileGoal();
    }

    return (
      <Dialog
        open={Boolean(this.state.assetAddTile)}
        onClose={this.handleAddTileDialogClose}
      >
        {this._renderAddTile(this.state.assetAddTile)}
      </Dialog>
    );
  };

  _renderAddTileGoalFormTooltip = () => {
    const { family } = this.props;
    const { forms } = this.state;

    const goalType = this.state.assetAddTileGoalType;

    if (goalType === entitiesFull.entitiesGoals.ENTITY_RETIREMENT) {
      const independenceAgeShow =
        forms &&
        forms.financialIndependence &&
        forms.financialIndependence.calculatedIndependenceAge;

      if (independenceAgeShow) {
        return (
          <FormattedMessage
            id="DASHBOARD.GOALS.FORM.TIPS.RETIREMENT.1"
            values={{ independenceAge: independenceAgeShow }}
          />
        );
      }

      return <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.RETIREMENT.2" />;
    }

    if (goalType === entitiesFull.entitiesGoals.ENTITY_EDUCATION) {
      const common = (
        <FormattedMessage
          id="DASHBOARD.GOALS.FORM.TIPS.EDUCATION.OTHER"
          values={{ break: msg => <br /> }}
        />
      );

      if (family && +family.zipCode >= 90001 && +family.zipCode <= 96162) {
        return (
          <>
            <div>
              <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.EDUCATION.CA" />
            </div>
            {common}
          </>
        );
      }

      if (family && +family.zipCode >= 98001 && +family.zipCode <= 99403) {
        return (
          <>
            <div>
              <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.EDUCATION.WA" />
            </div>
            {common}
          </>
        );
      }

      return common;
    }

    if (goalType === entitiesFull.entitiesGoals.ENTITY_HOME_PURCHASE) {
      const { propsState } = this.props;
      const { fullCalc, currentAge } = propsState;

      let spendAmount;
      let debtAmount;
      if (fullCalc && fullCalc[currentAge] && fullCalc[currentAge].overdraft) {
        const incomeMonthly = fullCalc[currentAge].overdraft.MoneyIn.all / 12;
        spendAmount = incomeMonthly * 0.28;
        debtAmount = incomeMonthly * 0.36;
      }

      return spendAmount && debtAmount ? (
        <FormattedMessage
          id="DASHBOARD.GOALS.FORM.TIPS.HOUSE.2"
          values={{
            amount: formatMoneyIntWithSign(spendAmount),
            mortgage: formatMoneyIntWithSign(debtAmount)
          }}
        />
      ) : (
        <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.HOUSE.1" />
      );
    }

    if (goalType === entitiesFull.entitiesGoals.ENTITY_PROTECT_LOVED) {
      return <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.INSURANCE.1" />;
    }

    if (goalType === entitiesFull.entitiesGoals.ENTITY_LEGACY) {
      return <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.LEGACY.1" />;
    }

    if (goalType === entitiesFull.entitiesGoals.ENTITY_MAJOR_EXPANSE) {
      return <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.MAJOR.1" />;
    }

    return <FormattedMessage id="DASHBOARD.GOALS.FORM.TIPS.DEFAULT" />;
  };

  handleOpenEditGoal = (id, entity) => ev => {
    this.setState({
      assetAddTileGoalType: entity,
      assetAddTileGoalId: id
    });
  };

  _renderAssetFormGoal = () => {
    const {
      classes,
      isMobile,
      assetsAll,
      transactionsAll,
      profileProvider,
      family,
      childsArr,
      firestore,
      auth,
      profile
    } = this.props;

    const goalType = this.state.assetAddTileGoalType;
    const goalId = this.state.assetAddTileGoalId;
    const isAccessEdit = true;

    const scheme = entitiesScheme[globalTypes.globalTypeGoals];

    if (!scheme) {
      return null;
    }

    // id
    const formProps = {
      ...scheme,
      assetType: goalType,
      id: goalId
    };

    const additionalAssets = this.handleGetAdditionalAssets(
      formProps && formProps.assetGlobalType
    );

    const formBottomTooltip = (
      <div className={classes.GoalFormTooltip}>
        {this._renderAddTileGoalFormTooltip()}
      </div>
    );

    const form = (
      <AssetForm
        onClose={this.handleTileGoalFormDialogClose}
        classesGoals={classes}
        isAccessEdit={isAccessEdit}
        // //
        assetType={formProps.assetType}
        // customValues={this.state.assetAddTileCustomValues || customValues}
        formsInitialState={formProps.formsInitial}
        scheme={formProps.formScheme[formProps.assetType]}
        formScheme={formProps.formScheme}
        formsValidationScheme={formProps.validationScheme} //
        validationScheme={formProps.validationScheme}
        designScheme={formProps.designScheme[formProps.assetType]}
        translations={formProps.translations}
        // //
        GLOBAL_TYPE_USE_ASSETS={formProps.assetGlobalType}
        GLOBAL_COLLECTION={formProps.assetCollection}
        // //
        ENTITIES_ADDITIONAL={formProps.entitiesAdditional}
        formEditOpened={{ uidAsset: formProps.id, entity: formProps.assetType }}
        formOpened={!formProps.id ? formProps.assetType : null}
        // open={true}
        // //
        additionalSaveGlobalType={formProps.additionalSaveGlobalType}
        updateFieldGlobalType={formProps.updateFieldGlobalType}
        // // assets={assetsAll}
        assetsAll={assetsAll}
        transactionsAll={transactionsAll}
        // //
        additionalAssets={additionalAssets}
        profileProvider={profileProvider}
        family={family}
        childs={childsArr}
        // //
        firestore={firestore}
        auth={auth}
        profile={profile}
        isMobile={isMobile}
        //
        formBottomTooltip={formBottomTooltip}
        isDashboardGoal
        onBack={this.handleAddTileGoalDialogOpen}
      />
    );

    return (
      <Dialog
        open={Boolean(this.state.assetAddTileGoalType)}
        onClose={this.handleTileGoalFormDialogClose}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth={"md"}
        scroll="paper"
      >
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="DASHBOARD.GOALS.TITLE"
                defaultMessage="Goals"
              />
            </div>
          </div>

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.handleTileGoalFormDialogClose}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        {form}
      </Dialog>
    );
  };

  handleSetAddTileGoal = type => () => {
    this.setState({
      assetAddTileGoalType: type,
      assetAddTileGoal: null
    });
  };

  _renderAssetAddTileGoal = () => {
    const { classes, isMobile } = this.props;

    return (
      <Dialog
        open={Boolean(this.state.assetAddTileGoal || this.state.assetAddTile)}
        onClose={this.handleAddTileGoalDialogClose}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth={"md"}
        scroll="paper"
      >
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="DASHBOARD.GOALS.TITLE"
                defaultMessage="Goals"
              />
            </div>
          </div>

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.handleAddTileGoalDialogClose}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="DASHBOARD.GOALS.DIALOG.CHOOSE.CAPTION"
              defaultMessage="Choose a goal you would like to set"
            />
          </div>

          <div
            className={`${classes.wizardBodyContent} ${classes.wizardBodyContentGoal}`}
          >
            {this._renderAddTileGoal(this.state.assetAddTileGoal)}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  _renderItemGoal = (type, icon, label) => {
    const { classes, formState, handleInputNeedsChange } = this.props;

    const Icon = Icons[icon];

    return (
      <button
        className={`${classes.wizardNeedsItem} ${classes.wizardNeedsItemGoal}`}
        onClick={this.handleSetAddTileGoal(type)}
      >
        <span className={classes.wizardNeedsItemText}>{label}</span>

        <span>
          <Icon />
        </span>
      </button>
    );
  };

  _renderAddTileGoal = globalType => {
    const { classes } = this.props;
    // const isAccessEdit = true; // TODO

    return (
      <div className={classes.wizardNeeds}>
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_RETIREMENT,
          "IconBigRetirement",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.RETIREMENT"
            defaultMessage="Retirement or financial independence"
          />
        )}
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_PROTECT_LOVED,
          "IconBigInsurance",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.INSURANCE"
            defaultMessage="Protect loved ones in case of unexpected turn of events"
          />
        )}
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_HOME_PURCHASE,
          "IconBigHome",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.HOUSE"
            defaultMessage="Buy a new house or another expensive property"
          />
        )}
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_EDUCATION,
          "IconBigEducation",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.EDUCATION"
            defaultMessage="Save for kids higher education needs"
          />
        )}
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_LEGACY,
          "IconBigBank",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.LEGACY"
            defaultMessage="Leave a legacy behind after i die"
          />
        )}
        {this._renderItemGoal(
          entitiesFull.entitiesGoals.ENTITY_MAJOR_EXPANSE,
          "IconBigLoan",
          <FormattedMessage
            id="DASHBOARD.GOALS.DIALOG.TYPE.MAJOR"
            defaultMessage="Save or be ready for any other large expense"
          />
        )}
      </div>
    );
  };

  _renderAdvisorOverall = () => {
    const { firstYear, data } = this.state;
    const { propsState } = this.props;
    const { members } = propsState;

    if (!firstYear) {
      return null;
    }

    let scrollBarWidth = this.calcScrollBarWidth();

    return (
      <div className="dashboard-advisor__overall">
        <TooltipMUI
          title={
            <FormattedMessage
              id="APP.TOOLTIP.ID_CARD"
              defaultMessage="Customer ID card"
            />
          }
          placement="left"
          // disableFocusListener
          enterTouchDelay={50}
          leaveTouchDelay={5000}
        >
          <button
            className={`dashboard-advisor__overall-button ${
              this.state.advisorOverallOpen
                ? "dashboard-advisor__overall-button--opened"
                : ""
            }`}
            style={{
              right: scrollBarWidth
            }}
            onClick={this.handleToggleOverall}
          >
            <div className="summary__total-arrow">
              <Icons.IconDoubleChevronLeft color={"yellow"} />
            </div>
          </button>
        </TooltipMUI>

        {!!this.state.advisorOverallOpen && (
          <div className="dashboard-advisor__container">
            <div
              className="dashboard-advisor__content"
              style={{
                width: this.state.containerWidth + 81,
                right: scrollBarWidth
              }}
            >
              <DashboardAdvisorOverall
                scrollBarWidth={scrollBarWidth}
                firstYear={firstYear}
                members={members}
                data={data}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  _renderAdvisorCheckList = () => {
    const { firstYear, data, forms } = this.state;
    const { propsState } = this.props;
    const { members } = propsState;

    if (!firstYear) {
      return null;
    }

    return (
      <DashboardAdvisorCheckList
        forms={forms}
        handleSaveSection={this.handleSaveSection}
        handleInputChangePanel={this.handleInputChangePanel}
        firstYear={firstYear}
        members={members}
        data={data}
      />
    );
  };

  _renderAssetForm = () => {
    const {
      classes,
      assetsAll,
      transactionsAll,
      isMobile,
      family,
      childsArr,
      firestore,
      auth,
      profile,
      profileProvider
    } = this.props;

    const formProps = this.state.formProps;
    let backAssetAddTile = null;

    if (this.state.assetAddTile) {
      backAssetAddTile = this.state.assetAddTile;
    }

    // TODO
    const isAccessEdit = true;

    const additionalAssets = this.handleGetAdditionalAssets(
      formProps && formProps.assetGlobalType
    );

    const isGoal = formProps.assetGlobalType === globalTypeGoal;
    const formBottomTooltip = isGoal ? (
      <div className={classes.GoalFormTooltip}>
        {this._renderAddTileGoalFormTooltip()}
      </div>
    ) : null;

    const customValues =
      formProps &&
      !formProps.id &&
      (formProps.assetGlobalType === globalTypeGoal ||
        formProps.assetGlobalType === globalTypeEvents)
        ? { startAge: this.state.activeYear }
        : null;

    const handleClose = this.state.returnToTypes
      ? this.handleCloseFormAndBackToSelectType(backAssetAddTile)
      : this.handleCloseForm;

    const form = (
      <AssetForm
        // closeForm={this.handleCloseForm}
        closeForm={this.handleCloseForm}
        // onBack={}
        onClose={this.handleCloseForm}
        // onClose={this.handleCloseForm}
        //
        isAccessEdit={isAccessEdit}
        //
        assetType={formProps.assetType}
        customValues={
          this.state.assetAddTileCustomValues ||
          customValues ||
          formProps.customValues
        }
        formsInitialState={formProps.formsInitial}
        scheme={formProps.formScheme[formProps.assetType]}
        formScheme={formProps.formScheme}
        formsValidationScheme={formProps.validationScheme} //
        validationScheme={formProps.validationScheme}
        designScheme={formProps.designScheme[formProps.assetType]}
        translations={formProps.translations}
        //
        GLOBAL_TYPE_USE_ASSETS={formProps.assetGlobalType}
        GLOBAL_COLLECTION={formProps.assetCollection}
        //
        ENTITIES_ADDITIONAL={formProps.entitiesAdditional}
        formEditOpened={{ uidAsset: formProps.id, entity: formProps.assetType }}
        formOpened={!formProps.id ? formProps.assetType : null}
        open={true}
        //
        additionalSaveGlobalType={formProps.additionalSaveGlobalType}
        updateFieldGlobalType={formProps.updateFieldGlobalType}
        // assets={assetsAll}
        assetsAll={assetsAll}
        transactionsAll={transactionsAll}
        //
        additionalAssets={additionalAssets}
        profileProvider={profileProvider}
        family={family}
        childs={childsArr}
        //
        firestore={firestore}
        auth={auth}
        profile={profile}
        isMobile={isMobile}
        //
        // isGoal
        formBottomTooltip={formBottomTooltip}
        classesGoals={classes}
        isDashboardGoal={isGoal}
        onBack={
          isGoal
            ? this.handleAddTileGoalDialogOpen
            : this.handleCloseFormAndBackToSelectType(backAssetAddTile)
        }
      />
    );

    if (!isGoal) {
      return form;
    }

    return (
      <Dialog
        open={
          Boolean(this.state.assetAddTileGoalType) || this.state.isAssetFormOpen
        }
        onClose={this.handleTileGoalFormDialogClose}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth={"md"}
        scroll="paper"
      >
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="DASHBOARD.GOALS.TITLE"
                defaultMessage="Goals"
              />
            </div>
          </div>

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.handleTileGoalFormDialogClose}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        {form}
      </Dialog>
    );
  };

  _renderTopCategories = () => {
    // const IconAssetOwned = Icons[menuObjects.householdAssetsOwned.iconMainLink];
    const IconUse = Icons[menuObjects.householdUse.iconMainLink];
    const IconSavings = Icons[menuObjects.householdSavings.iconMainLink];
    const IconInvested = Icons[menuObjects.householdInvested.iconMainLink];
    const IconDebt = Icons[menuObjects.householdDebt.iconMainLink];
    const IconAccounts = Icons[menuObjects.householdAccounts.iconMainLink];
    const IconMoneyIn = Icons[menuObjects.householdMoneyIn.iconMainLink];
    const IconMoneyOutPersonal =
      Icons[menuObjects.householdMoneyOutPersonal.iconMainLink];
    const IconMoneyOutAssets =
      Icons[menuObjects.householdMoneyOutAssets.iconMainLink];
    const IconMoneyOutOther =
      Icons[menuObjects.householdMoneyOutOther.iconMainLink];

    // householdUse: HouseholdAssetsUse,
    // householdSavings: HouseholdAssetsSavings,
    // householdInvested: HouseholdAssetsInvested,
    // householdDebt: HouseholdDebt,
    // householdAccounts: HouseholdAccounts,
    // householdMoneyIn: HouseholdMoneyIn,
    // householdMoneyOutPersonal: MoneyOutPersonal,
    // householdMoneyOutAssets: MoneyOutAssets,
    // householdMoneyOutOther: MoneyOutOther

    return (
      <div className="dashboard__panel-categories">
        <div className="dash-categories">
          <div className="dashboard__panel-categories__title">
            <FormattedMessage id="APP.ADD/EDIT" defaultMessage="Add/Edit" />
          </div>

          {this.state.isVisibleScrollLeftArrow && (
            <button
              className="dash-categories-arrow dash-categories-arrow--left"
              onClick={this.handleScrollToLeft}
            ></button>
          )}

          {this.state.isVisibleScrollRightArrow && (
            <button
              className="dash-categories-arrow dash-categories-arrow--right"
              onClick={this.handleScrollToRight}
            ></button>
          )}

          <div
            className="dash-categories__full"
            ref="categoriesScroll"
            onScroll={this.checkScrollPosition}
          >
            <div className="dashboard__panel-categories__title">
              <FormattedMessage id="APP.ADD/EDIT" defaultMessage="Add/Edit" />
            </div>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdUse")}
            >
              <IconUse color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.USE"
                defaultMessage="Homes & Vehicles"
              />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.PROPERTIES"
                defaultMessage="Properties"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdSavings")}
            >
              <IconSavings color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.SAVING"
                defaultMessage="Savings & Retirement"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdInvested")}
            >
              <IconInvested color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.INVESTED"
                defaultMessage="Investments"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdDebt")}
            >
              <IconDebt color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.DEBT"
                defaultMessage="Debt"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdAccounts")}
            >
              <IconAccounts color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ACCOUNTS"
                defaultMessage="Bank Accounts"
              />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.ACCOUNTS"
                defaultMessage="Accounts"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdMoneyIn")}
            >
              <IconMoneyIn color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.MONEY_IN"
                defaultMessage="Income"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog(
                "householdMoneyOutPersonal"
              )}
            >
              <IconMoneyOutPersonal color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.MONEY_OUT.PERSONAL"
                defaultMessage="Living Expenses"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdMoneyOutAssets")}
            >
              <IconMoneyOutAssets color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.MONEY_OUT.ASSETS"
                defaultMessage="Property Expenses"
              />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.ASSETS.EXPENSES"
                defaultMessage="Expenses"
              />
            </button>

            <button
              className="dash-categories__item"
              onClick={this.handleOpenCategoryDialog("householdMoneyOutOther")}
            >
              <IconMoneyOutOther color={"white"} />

              <FormattedMessage
                id="DASHBOARD.MAIN_TILES.CATEGORY.MONEY_OUT.OTHER"
                defaultMessage="Rent & Lease"
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  handleOpenCategoryDialog = category => () => {
    this.setState({ categoryDialog: category });
  };

  _renderTopTiles = () => {
    const { propsState, uiSettings, classes } = this.props;

    const { fullCalc, currentAge } = propsState;
    const { activeYear } = this.state;

    if (!(fullCalc || activeYear)) {
      return null;
    }

    if (!fullCalc[currentAge]) {
      return null;
    }

    // const whatIfSuffix = !uiSettings.dashboardIfChecked ? "" : "WhatIf";
    //////////////
    // let FullDepletion;
    // Object.keys(fullCalc).forEach(ageKey => {
    //   const tempYear = fullCalc[ageKey];
    //
    //   // const monetaryAssetOverdraft = uiSettings.dashboardNoOverdraft
    //   //   ? tempYear.overdraft[`chartData${whatIfSuffix}`].MonetaryAssets
    //   //   : tempYear[`chartData${whatIfSuffix}`].MonetaryAssets;
    //   const monetaryAssetOverdraft =
    //     tempYear.overdraft[`chartData${whatIfSuffix}`].MonetaryAssets;
    //
    //   if (!FullDepletion && monetaryAssetOverdraft <= 0) {
    //     FullDepletion = ageKey;
    //   }
    // });

    ///////////////

    // const currentYear = uiSettings.dashboardNoOverdraft
    //   ? fullCalc[currentAge].overdraft
    //   : fullCalc[currentAge];
    // const currentYear = fullCalc[currentAge].overdraft;
    //
    // const lifeExpectancy = this.state.forms.financialIndependence
    //   .lifeExpectancy;

    /// TAX
    // const currentTax =
    //   currentYear.Liabilities.capitalTax +
    //   currentYear.Liabilities.deferredIncomeTax +
    //   currentYear.MoneyOut.taxes;
    //
    // // const lifetimeTax_2 = fullCalc[lifeExpectancy]
    // //   ? uiSettings.dashboardNoOverdraft
    // //     ? fullCalc[lifeExpectancy].overdraft.Liabilities.capitalTax
    // //     : fullCalc[lifeExpectancy].Liabilities.capitalTax
    // //   : 0;
    // //
    // // const lifetimeTax_3 = fullCalc[lifeExpectancy]
    // //   ? uiSettings.dashboardNoOverdraft
    // //     ? fullCalc[lifeExpectancy].overdraft.TempValues.MoneyOut.estimatedTaxes
    // //     : fullCalc[lifeExpectancy].TempValues.MoneyOut.estimatedTaxes
    // //   : 0;
    // const lifetimeTax_2 =
    //   (fullCalc[lifeExpectancy] &&
    //     fullCalc[lifeExpectancy].overdraft.Liabilities.capitalTax) ||
    //   0;
    //
    // const lifetimeTax_3 =
    //   (fullCalc[lifeExpectancy] &&
    //     fullCalc[lifeExpectancy].overdraft.TempValues.MoneyOut
    //       .estimatedTaxes) ||
    //   0;
    //
    // const lifetimeTax =
    //   currentYear.Liabilities.capitalTax + lifetimeTax_2 + lifetimeTax_3;

    /// GOALS
    // const goals = this.state.forms.temp && this.state.forms.temp.goals;
    // const savingsRetirement =
    //   goals.retirement *
    //   (lifeExpectancy && lifeExpectancy > currentAge
    //     ? Math.pow(1 + INFLATION, lifeExpectancy - currentAge)
    //     : 1);
    //
    // const savingNeeds =
    //   goals.education +
    //   goals.general +
    //   goals.other +
    //   goals.home +
    //   goals.legacy +
    //   goals.major +
    //   savingsRetirement;
    //
    // const currentActualSavings =
    //   currentYear.Assets.cash +
    //   currentYear.Assets.investments +
    //   currentYear.Assets.retirement;

    const { forms } = this.state;

    const gapToIndependence =
      forms.financialIndependence &&
      forms.financialIndependence.gapToIndependence &&
      forms.financialIndependence.gapToIndependence < 0
        ? forms.financialIndependence.gapToIndependence
        : null;

    const gapToIndependenceWhatIf =
      forms.financialIndependence &&
      forms.financialIndependence.gapToIndependenceWhatIf &&
      forms.financialIndependence.gapToIndependenceWhatIf < 0
        ? forms.financialIndependence.gapToIndependenceWhatIf
        : null;

    //////////////////////// TODO to app bar
    const gapToIndependenceShow = uiSettings.dashboardIfChecked
      ? gapToIndependenceWhatIf
      : gapToIndependence;

    const independenceAgeShow = uiSettings.dashboardIfChecked
      ? forms.financialIndependence.calculatedIndependenceAgeWhatIf
      : forms.financialIndependence.calculatedIndependenceAge;

    return (
      <>
        {/*<div className="home-top-tiles__app-bar">*/}
        {/*  <div className="home-top-tiles__app-bar--icon">*/}
        {/*    <Icons.IconSunLounger color="brand" height={32} width={32} />*/}
        {/*  </div>*/}

        {/*  <div className="home-top-tiles__app-bar--label">*/}
        {/*    <FormattedMessage*/}
        {/*      id="DASHBOARD.MAIN_TILES.REACH_INDEPENDENCE"*/}
        {/*      defaultMessage="To reach financial independence you need"*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <span className="home-top-tiles__value">*/}
        {/*    {gapToIndependence ? (*/}
        {/*      formatMoneyIntWithSign(Math.abs(gapToIndependenceShow))*/}
        {/*    ) : (*/}
        {/*      <FormattedMessage*/}
        {/*        id="DASHBOARD.MAIN_TILES.RETIREMENT.CAN_RETIRE"*/}
        {/*        defaultMessage="You can retire already"*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </span>*/}
        {/*</div>*/}

        <div
          className={`home-top-tiles ${
            !this.props.uiSettings.isDrawerOpen ? "home-top-tiles--full" : ""
          }`}
        >
          {this._renderTopCategories()}

          {this._renderTopWhatIf()}
        </div>
      </>
    );
  };

  _renderTopWhatIf = () => {
    const { assetsAll, transactionsAll } = this.props;

    const createIcon = asset => {
      const scheme = entitiesScheme[asset.globalType];
      if (!scheme || !asset.isWhatIf) {
        return null;
      }

      const designScheme = scheme.designScheme[asset.entity];

      const Icon = Icons[designScheme.icon];
      return (
        <TooltipMUI
          key={asset.id}
          title={asset.description}
          placement="top"
          // disableFocusListener
          enterTouchDelay={50}
          leaveTouchDelay={5000}
        >
          <div
            className="dashboard__panel-what-if__asset cursor-pointer"
            onClick={this.handleEditAsset(asset)}
          >
            <Icon
              color={asset.isDisabled ? "textSecondary" : "ui"}
              width={24}
              height={24}
            />
          </div>
        </TooltipMUI>
      );
    };

    const sortByUpdated = (a, b) => {
      if ((a.isDisabled || false) > (b.isDisabled || false)) return 1;
      if ((a.isDisabled || false) < (b.isDisabled || false)) return -1;

      const aDate = a.updated ? a.updated.seconds : 0;
      const bDate = b.updated ? b.updated.seconds : 0;

      return bDate - aDate;
    };

    const assetsA = assetsAll ? assetsAll : [];
    const transactionsA = transactionsAll ? transactionsAll : [];

    const assetsMerged = [...assetsA, ...transactionsA];

    const assetsPrepared = assetsMerged
      .filter(asset => !!asset.isWhatIf)
      .sort(sortByUpdated);

    const assets = assetsPrepared.map(createIcon);
    const isAssetsEmpty = !assets || !assets.length;

    // if (isAssetsEmpty) {
    //   return null;
    // }

    return (
      <div
        className={`dashboard__panel-what-if ${
          isAssetsEmpty && this.state.dashboardWhatIfOpen
            ? "dashboard__panel-what-if--reverse"
            : ""
        }
            ${
              this.state.dashboardWhatIfOpen
                ? "dashboard__panel-what-if--opened"
                : ""
            }`}
      >
        <div className="dashboard__panel-what-if__left">
          <div
            className={`dashboard__panel-what-if-button ${
              isAssetsEmpty && this.state.dashboardWhatIfOpen
                ? "dashboard__panel-what-if-button--full"
                : ""
            }`}
          >
            <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-if" />
          </div>

          {!isAssetsEmpty && (
            <button
              className="dashboard__panel-what-if__content-link"
              onClick={this.handleOpenAllWhatIf}
            >
              <FormattedMessage
                id="DASHBOARD.MAIN.WHAT_IF.MORE"
                defaultMessage="More"
              />
            </button>
          )}
        </div>

        <button
          className={`dashboard__panel-what-if-button ${
            this.state.dashboardWhatIfOpen
              ? "dashboard__panel-what-if-button--opened"
              : ""
          }`}
          onClick={this.handleToggleWhatIf}
        >
          <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-if" />

          <div className="summary__total-arrow">
            <Icons.IconDoubleChevronLeft color={"ui"} />
          </div>
        </button>

        {isAssetsEmpty && !!this.state.dashboardWhatIfOpen && (
          <div className="dashboard__panel-what-if__content">
            <span className="dashboard__panel-what-if__content__addlink">
              <FormattedMessage
                id="DASHBOARD.MAIN.WHAT_IF.PREDICT.BY.ADDING"
                defaultMessage="Predict the financial future by adding 'What-if'"
              />
            </span>
          </div>
        )}

        {!isAssetsEmpty && !!this.state.dashboardWhatIfOpen && (
          <div className="dashboard__panel-what-if__content">
            {assets.slice(0, 10)}

            {!isAssetsEmpty && (
              <button
                className="dashboard__panel-what-if__content-link"
                onClick={this.handleOpenAllWhatIf}
              >
                <FormattedMessage
                  id="DASHBOARD.MAIN.WHAT_IF.MORE"
                  defaultMessage="More"
                />
              </button>
            )}

            {!isAssetsEmpty && this.state.allWhatIfDialog && (
              <>
                <DialogCommon
                  isOpen={this.state.dialogRemoveAllWhatIf}
                  title={eraseAllWhatIf.title}
                  text={eraseAllWhatIf.text}
                  okButton={eraseAllWhatIf.okButton}
                  handleAgreeAndClose={this.saveOrDeleteAllWhatIf(
                    assetsPrepared,
                    {
                      isDelete: true
                    }
                  )}
                  handleClose={this.handleCloseAllWhatIfAgreement}
                />

                <DialogCommon
                  isOpen={this.state.dialogSaveAllWhatIf}
                  title={saveAllWhatIf.title}
                  text={saveAllWhatIf.text}
                  okButton={saveAllWhatIf.okButton}
                  handleAgreeAndClose={this.saveOrDeleteAllWhatIf(
                    assetsPrepared
                  )}
                  handleClose={this.handleCloseAllWhatIfAgreement}
                />

                {this._renderAllWhatIf(assetsPrepared)}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  _renderAllWhatIf = assets => {
    const { classes, isMobile, family } = this.props;

    const content = (
      <div className="main-content  main-content--dialog">
        <div className="content-main  ">
          <div className="cards" ref={this.cardsRef}>
            {assets &&
              assets.map(assetOriginal => {
                const asset = { ...assetOriginal };

                const isAccessEdit = true; // TODO
                const schemeCategory = entitiesScheme[asset.globalType];

                const { id } = asset;
                const designSchemeAsset =
                  schemeCategory.designScheme[asset.entity];
                const formSchemeAsset = schemeCategory.formScheme[asset.entity];

                if (!designSchemeAsset || !formSchemeAsset) {
                  return null;
                }

                const formSchemeAdditional =
                  schemeCategory.entitiesAdditional[asset.entity];

                if (
                  asset.owner ||
                  asset.employeeName ||
                  asset.personInsured ||
                  asset.personAttending
                ) {
                  const owner =
                    this.state.optionsOwner &&
                    this.state.optionsOwner.find(
                      el =>
                        el.value === asset.owner ||
                        el.value === asset.employeeName ||
                        el.value === asset.personInsured ||
                        el.value === asset.personAttending
                    );

                  if (asset.owner) {
                    asset.owner = owner ? owner.name : "";
                  }
                  if (asset.personAttending) {
                    asset.personAttending = owner ? owner.name : "";
                  }
                  if (asset.employeeName) {
                    asset.employeeName = owner ? owner.name : "";
                  }
                }
                const isPrimaryResidence =
                  asset.globalType === "use-assets" &&
                  asset.entity === "home" &&
                  asset.type === "primary";

                return (
                  <AssetCard
                    isAccessEdit={isAccessEdit}
                    isAllWhatIfMode
                    isWizardFinished={family && family.isWizardFinished}
                    key={id}
                    asset={asset}
                    handleEditAsset={this.handleEditAsset(asset)}
                    handleToggleWhatIfAsset={this.handleToggleWhatIfAsset(
                      asset.id
                    )}
                    handleToggleDisableAsset={this.handleToggleDisableAsset(
                      asset.id
                    )}
                    handleDeleteAsset={this.handleDialogRemoveOpen(asset.id)}
                    handleDuplicateAsset={
                      isPrimaryResidence
                        ? null
                        : this.handleDuplicateAsset(asset.id)
                    }
                    // handleSelectAsset={this.handleSelectAsset(id)}
                    // isSelected={this.state.selectedAsset === id}
                    designScheme={designSchemeAsset}
                    formScheme={formSchemeAsset}
                    formSchemeAdditional={formSchemeAdditional}
                    isMobile={isMobile}
                    containerWidth={this.state.cardsWidth}
                    // additionalAssets={additionalAssets}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );

    const isAssetsEmpty = !assets || !assets.length;

    return (
      <Dialog
        open={Boolean(this.state.allWhatIfDialog)}
        onClose={this.handleCategoryDialogClose}
        fullWidth
        maxWidth={"lg"}
        fullScreen={isMobile}
        classes={{
          paperScrollPaper: isMobile ? "" : classes.categoryPaperScrollPaper
        }}
      >
        <DialogTitle
          disableTypography
          className={`${classes.categoryHead} ${classes.categoryHeadWhatIf}`}
        >
          <div
            className={`${classes.categoryHeadTitle} ${classes.categoryHeadTitleWhatIf}`}
          >
            <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-if" />
          </div>

          <ButtonSideItem
            type="button"
            isOpen
            color={"white"}
            handleClick={this.handleCategoryDialogClose}
          />
        </DialogTitle>

        <DialogContent className={classes.categoryBody}>
          {content}
        </DialogContent>

        <DialogActions className={classes.categoryFooterWhatIf}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.ButtonFooter}
            onClick={() => {
              this.setState({ dialogRemoveAllWhatIf: true });
            }}
            disabled={isAssetsEmpty}
            style={{ marginRight: 16 }}
          >
            <FormattedMessage id="APP.DELETE.ALL" defaultMessage="Delete All" />
          </Button>

          <ButtonCommon
            yellow
            type="submit"
            // onClick={this.saveOrDeleteAllWhatIf(assets)}
            onClick={() => {
              this.setState({ dialogSaveAllWhatIf: true });
            }}
            disabled={
              isAssetsEmpty || Boolean(this.state.isSavingAllAsStandard)
            }
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.WHAT_IF.SAVE_ALL_STANDARD"
              defaultMessage="Save All as standard"
            />
          </ButtonCommon>
        </DialogActions>
      </Dialog>
    );
  };

  _renderCategory = () => {
    const { classes, isMobile } = this.props;
    const category = this.state.categoryDialog;

    const Component = categoriesComponents[category];
    const Icon = menuObjects[category]
      ? Icons[menuObjects[category].iconMainLink]
      : Icons.IconHousehold;

    const title = menuObjects[category] ? (
      <FormattedMessage
        id={menuObjects[category].nameI18n}
        defaultMessage={menuObjects[category].name}
      />
    ) : null;

    return (
      <Dialog
        open={Boolean(this.state.categoryDialog)}
        onClose={this.handleCategoryDialogClose}
        fullWidth
        maxWidth={"lg"}
        fullScreen={isMobile}
        classes={{
          paperScrollPaper: isMobile ? "" : classes.categoryPaperScrollPaper
        }}
      >
        <DialogTitle disableTypography className={classes.categoryHead}>
          <IconContainer color={"ui"}>
            <Icon color="ui" />
          </IconContainer>

          <div className={classes.categoryHeadTitle}>{title}</div>

          <ButtonSideItem
            type="button"
            isOpen
            handleClick={this.handleCategoryDialogClose}
          />
        </DialogTitle>
        <DialogContent className={classes.categoryBody}>
          <Component isDialog containerHeight={this.state.containerHeight} />
        </DialogContent>
      </Dialog>
    );
  };

  handleCategoryDialogClose = () => {
    this.setState({
      categoryDialog: "",
      allWhatIfDialog: false
    });
  };

  _renderActionsAndGoals = () => {
    const { classes } = this.props;

    return (
      <div className={classes.ActionAndGoals}>
        {this._renderActions()}

        {this._renderGoals()}
      </div>
    );
  };

  _renderGoals = () => {
    const { goalsAll, firestore, profileProvider, members } = this.props;

    return (
      <DashboardGoals
        members={members}
        handleOpenEditGoal={this.handleOpenEditGoal}
        handleDialogRemoveOpen={this.handleDialogRemoveOpen}
        handleAddTileGoalDialogOpen={this.handleAddTileGoalDialogOpen}
        goalsAll={goalsAll}
        firestore={firestore}
        profileProvider={profileProvider}
      />
    );
  };

  handleFixChart = age => () => {
    const { setUI, uiSettings } = this.props;

    if (uiSettings.dashboardChartTerm !== "to100") {
      setUI({ dashboardChartTerm: "to100" });
    }

    setUI({ fixDashboardAgeChart: age });
  };

  _renderActions = () => {
    const { propsState, configuration, auth, firestore } = this.props;
    const { fullCalc } = propsState;

    //////////////////////
    let monetaryNegativeYear = null;
    let monetaryNegativeYearWhatIf = null;

    if (fullCalc) {
      Object.keys(fullCalc).forEach(age => {
        if (fullCalc[age] && fullCalc[age].overdraft) {
          if (
            !monetaryNegativeYear &&
            fullCalc[age].overdraft.chartData.MonetaryAssets <= 0
          ) {
            monetaryNegativeYear = age;
          }

          if (
            !monetaryNegativeYearWhatIf &&
            fullCalc[age].overdraft.chartDataWhatIf.MonetaryAssets <= 0
          ) {
            monetaryNegativeYearWhatIf = age;
          }
        }
      });
    }

    return (
      <DashboardActions
        configuration={configuration}
        auth={auth}
        firestore={firestore}
        family={this.props.family}
        members={this.props.members}
        assetsAll={this.props.assetsAll}
        transactionsAll={this.props.transactionsAll}
        myAdvisors={this.props.myAdvisors}
        handleOpenCategoryDialog={this.handleOpenCategoryDialog}
        handleOpenEditGoal={this.handleOpenEditGoal}
        handleFixChart={this.handleFixChart}
        monetaryNegativeYear={monetaryNegativeYear}
        monetaryNegativeYearWhatIf={monetaryNegativeYearWhatIf}
      />
    );
  };

  render() {
    const { profileProvider, auth, profile, classes, isMobile } = this.props;

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareDashboard)
    ) {
      return <AccessForbidden />;
    }

    const isAdvisorMode =
      profile &&
      profile.isAdvisor &&
      profileProvider &&
      profileProvider !== auth.uid;

    return (
      <main className="main-container main-container--dashboard">
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDashboardDimensions}
        />

        {Boolean(this.state.categoryDialog) && this._renderCategory()}

        {this.state.isAssetFormOpen && this._renderAssetForm()}

        {!this.state.isAssetFormOpen &&
          this.state.assetAddTile &&
          this._renderAssetAddTile()}

        {this.state.assetAddTileGoal && this._renderAssetAddTileGoal()}
        {this.state.assetAddTileGoalType && this._renderAssetFormGoal()}

        <div className="main-content">
          <div className="content-main">
            <DialogRemoveAsset
              isOpen={!!this.state.assetUid}
              handleAgreeAndClose={this.handleDialogRemoveAgree}
              handleClose={this.handleDialogRemoveClose}
            />

            {this._renderTopTiles()}

            {!!isAdvisorMode && this._renderAdvisorOverall()}

            {this._renderCharts()}

            {this._renderSummaryBlock()}

            {this._renderActionsAndGoals()}

            {!!isAdvisorMode ? this._renderAdvisorCheckList() : null}
          </div>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(Dashboard);
