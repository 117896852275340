import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconAvatar = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM19 20.5C18.336 18.527 16.198 16 14 16H10C7.802 16 5.664 18.527 5 20.5C2.977 18.669 1 14.937 1 12C1 6.486 6.486 1 12 1C17.514 1 23 6.486 23 12C23 14.936 21.022 18.669 19 20.5Z"
          fill={fill}
        />
        <path
          d="M11.9998 5.00055C9.79076 5.00055 7.99976 6.79155 7.99976 9.00055V10.0005C7.99976 12.2095 9.79076 14.0005 11.9998 14.0005C14.2088 14.0005 15.9998 12.2095 15.9998 10.0005V9.00055C15.9998 6.79155 14.2088 5.00055 11.9998 5.00055Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

IconAvatar.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconAvatar;
