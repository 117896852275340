import React, { useState } from "react";
import PropTypes from "prop-types";

import IconContainer from "../_common/IconContainer";
import * as Icons from "../_common/icons";

import { formatMoneyIntWithSign } from "../../lib/helpers";
import { FormattedMessage } from "react-intl-v3";
import "./Summary.scss";

const Summary = props => {
  const {
    designScheme,
    summary,
    containerWidth,
    isMobile,
    isDialog,
    calcScrollBarWidth
  } = props;
  const [isOpen, toggleOpen] = useState(!isMobile);
  const toggle = () => toggleOpen(!isOpen);

  if (!summary) {
    return null;
  }

  const content = Object.keys(summary).map(key => {
    if (key === "total") {
      return null;
    }

    if (!designScheme[key]) {
      return null;
    }

    const { icon, title, titleI18n, color } = designScheme[key] || {};
    const Icon = Icons[icon];

    return (
      <div key={key} className="summary__content-item">
        <div className="summary__content-icon">
          <IconContainer width={32} height={32} color={color}>
            <Icon width={24} height={24} color={color} />
          </IconContainer>
        </div>
        <div className="summary__content-title">
          <div className="summary__content-label">
            <FormattedMessage id={titleI18n} defaultMessage={title} />
          </div>
          <div className="summary__content-count">
            {formatMoneyIntWithSign(summary[key])}
          </div>
        </div>
      </div>
    );
  });

  const calcedContainerWidth =
    containerWidth < 805 ? containerWidth + 50 : containerWidth - 110;

  return (
    <section
      className={`summary ${isOpen ? "summary--opened" : ""} ${
        isDialog ? "summary--dialog" : ""
      }`}
      style={isDialog ? { width: `calc(100% - ${calcScrollBarWidth}px)` } : {}}
    >
      <div className="summary__wrapper">
        <div
          className="summary__content"
          style={{ maxWidth: isOpen ? calcedContainerWidth : 1 }}
        >
          {content}
        </div>

        <div className="summary__total" onClick={toggle}>
          <div className="summary__total-wrapper">
            <div className="summary__total-title">
              <div className="summary__total-label">
                <FormattedMessage id="APP.TOTAL" defaultMessage={"Total"} />
              </div>
              <div className="summary__total-count">
                {formatMoneyIntWithSign(summary.total)}
              </div>
            </div>

            <div className="summary__total-arrow">
              <Icons.IconDoubleChevronLeft color={"yellow"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Summary.propTypes = {
  mainContentRef: PropTypes.any,
  summary: PropTypes.any,
  isMobile: PropTypes.bool,
  isDialog: PropTypes.bool,
  calcScrollBarWidth: PropTypes.any,
  containerWidth: PropTypes.any,
  designScheme: PropTypes.any
};

export default Summary;
