import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconEye = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 4C4 4 1 12 1 12C1 12 4 20 12 20C20 20 23 12 23 12C23 12 20 4 12 4ZM12 7C14.7617 7 17 9.23828 17 12C17 14.7617 14.7617 17 12 17C9.23828 17 7 14.7617 7 12C7 9.23828 9.23828 7 12 7ZM12 9C10.3438 9 9 10.3438 9 12C9 13.6562 10.3438 15 12 15C13.6562 15 15 13.6562 15 12C15 10.3438 13.6562 9 12 9Z"
        fill={fill}
      />
    </svg>
  );
};

IconEye.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconEye;
