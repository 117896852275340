export const valuesInflation = {
  INFLATION: "inflation",
  INFLATION_BY: "inflationBy",
  SANDP: "sandp",
  SANDP_BY: "sandpBy",
  CUSTOM: "custom"
};
export const INFLATION = 2;
export const SAndP = 8;

export const defaultChanges = {
  "use-assets": {
    default: INFLATION,
    vehicle: -3,
    home: INFLATION
  },
  "savings-retirement": {
    default: -2,
    LifeInsurance: 5.5
  },
  "use-invested": {
    default: SAndP
  },
  "household-accounts ": {
    default: 0.1,
    cd: INFLATION,
    moneyMarket: INFLATION
  },
  // "household-debt": {
  //   default: 4,
  //   creditCard: 17
  // },
  "household-money-in": {
    default: INFLATION,
    salaries: 1
  },
  "money-out-personal": {
    default: INFLATION
  },
  "money-out-assets": {
    default: INFLATION
  },
  "money-out-other": {
    default: INFLATION
  }
};
export const defaultChangesType = {
  "use-assets": {
    default: valuesInflation.INFLATION,
    home: valuesInflation.INFLATION,
    vehicle: valuesInflation.CUSTOM //- 3
  },
  "savings-retirement": {
    default: valuesInflation.SANDP_BY, //- 2,
    LifeInsurance: valuesInflation.CUSTOM
  },
  "use-invested": {
    default: valuesInflation.SANDP
  },
  "household-accounts ": {
    default: valuesInflation.CUSTOM,
    cd: valuesInflation.INFLATION,
    moneyMarket: valuesInflation.INFLATION
  },
  // "household-debt": {
  //   default: valuesInflation.CUSTOM,
  //   creditCard: valuesInflation.CUSTOM
  // },
  "household-money-in": {
    default: valuesInflation.INFLATION, // +1
    salaries: valuesInflation.INFLATION_BY // +1
  },
  "money-out-personal": {
    default: valuesInflation.INFLATION
  },
  "money-out-assets": {
    default: valuesInflation.INFLATION
  },
  "money-out-other": {
    default: valuesInflation.INFLATION
  }
};
