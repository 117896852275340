import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "components/_common/Button/ButtonAddAsset";

import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "../LogIn/styles";
import { FormattedMessage, injectIntl } from "react-intl-v3";
import { emailValid, calcPasswordStrength } from "../LogIn/helper";
import { REQUIRED_PASSWORD_STRENGTH } from "../../constants";
import AppBarLogIn from "../ContainerApp/AppBarLogIn";
import { CLOUD_FUNC_ENDPOINT } from "../../constants/endpoints";
import { functionsURL } from "../../config/config";
import { CircularProgress } from "@material-ui/core";
import { colors } from "../../constants/styles";

class SignUp extends Component {
  static propTypes = {
    firebase: PropTypes.object.isRequired
  };



  state = {
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAdvisor: true,

    isEmailValid: true,
    isEmailAlreadyFree: true,
    isLastNameValid: true,
    isFirstNameValid: true,
    isPasswordValid: true,
    isPasswordEnoughStrength: true,
    isConfirmPasswordValid: true,

    isCreating: false
  };

  handleSendVerificationEmail = () => {
    const { intl } = this.props;
    const { firebase, location } = this.props;

    const currentUser = firebase.auth().currentUser;

    const urlParams = new URLSearchParams(location.search);
    // const advisorEmail = urlParams.get("advisorEmail");
    const code = urlParams.get("code");

    if (currentUser && !currentUser.emailVerified) {
      this.setState({ isCreating: true });

      const data = {
        code: code || "",
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: currentUser.email,
        uid: currentUser.uid,
        subject: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.SUBJECT",
          defaultMessage: "Verify your email for XCurve"
        }),
        greet: intl.formatMessage(
          {
            id: "EMAIL.CONFIRMATION.MESSAGE.GREET",
            defaultMessage: "Hello {firstName} {lastName},"
          },
          { firstName: this.state.firstName, lastName: this.state.lastName }
        ),
        linkDesc: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.LINK_DESC",
          defaultMessage: "Follow this link to verify your email address."
        }),
        caution: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.CAUTION",
          defaultMessage:
            "If you didn’t ask to verify this address, you can ignore this email."
        }),
        thanks: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.THANKS",
          defaultMessage: "Thanks,"
        }),
        yourTeam: intl.formatMessage({
          id: "EMAIL.CONFIRMATION.MESSAGE.YOUR_TEAM",
          defaultMessage: "Your XCurve team."
        })
      };

      fetch(`${functionsURL}/${CLOUD_FUNC_ENDPOINT.sendWelcomeEmailNew}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(res => {
          this.setState({ isEmailSent: true });
        })
        .catch(err => {
          this.setState({
            isCreating: false
          });
        });

      ///////////
      // currentUser.sendEmailVerification().then(() => {
      //   this.setState({ isEmailSent: true });
      // });
    }
  };

  handleSubmit = ev => {
    ev.preventDefault();

    if (!this.handleValidateData()) {
      return;
    }

    const { firebase, isAdvisorSignup } = this.props;
    const { email, password, lastName, firstName, isAdvisor } = this.state;

    firebase
      .createUser(
        { email, password },
        {
          email,
          lastName,
          firstName,
          displayName: `${firstName} ${lastName}`,
          isAdminApproved: false,
          isAdvisor: isAdvisorSignup ? isAdvisor : false,
          isFirstTime: true
        }
      )
      .then(() => {
        this.handleSendVerificationEmail();
      })
      .catch(err => {
        if (err.code === "auth/email-already-in-use") {
          this.setState({
            isEmailAlreadyFree: false
          });
        } else {
          console.warn("---", err);
        }
      });
  };
  handleChangeCheckbox = ev => {
    const { name, checked } = ev.target;

    this.setState({
      [name]: checked
    });
  };

  handleInputChange = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value,
      isEmailValid: true,
      isLastNameValid: true,
      isFirstNameValid: true,
      isPasswordValid: true,
      isPasswordEnoughStrength: true,
      isConfirmPasswordValid: true
    });
  };

  handleValidateData = () => {
    const isEmailValid = emailValid(this.state.email);
    const isLastNameValid = !!this.state.lastName;
    const isFirstNameValid = !!this.state.firstName;
    const isPasswordValid = !!this.state.password;
    const isPasswordEnoughStrength =
      calcPasswordStrength(this.state.password) > REQUIRED_PASSWORD_STRENGTH;
    const isConfirmPasswordValid =
      !this.state.password ||
      this.state.password === this.state.confirmPassword;

    this.setState({
      isEmailValid,
      isLastNameValid,
      isFirstNameValid,
      isPasswordValid,
      isPasswordEnoughStrength,
      isConfirmPasswordValid
    });

    return (
      isEmailValid &&
      isLastNameValid &&
      isFirstNameValid &&
      isPasswordValid &&
      isPasswordEnoughStrength &&
      isConfirmPasswordValid
    );
  };

  _renderForm = () => {
    const { classes, isAdvisorSignup } = this.props;

    return (
      <>
        <div className={classes.paperLeft}>
          <div className={classes.titleWrap}>
            <Typography component="h1" variant="h5" className={classes.title}>
              <FormattedMessage
                id="SIGNUP.HEADING"
                defaultMessage="Create your XCurve account"
              />
            </Typography>
          </div>
          <div className={classes.imgCont}>
            <img src="/assets/img/bg-sign.svg" alt="" />
          </div>
          <img
            className={classes.logoBottom}
            src="/assets/img/logo-orange-white.svg"
            alt=""
          />
        </div>
        <div className={classes.paperRight}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formControl}
            >
              <TextField
                id="firstName"
                name="firstName"
                label={
                  <FormattedMessage
                    id="SIGNUP.FORM.FIRST_NAME"
                    defaultMessage="First name"
                  />
                }
                value={this.state.firstName}
                autoFocus
                onChange={this.handleInputChange}
                error={!this.state.isFirstNameValid}
                helperText={
                  !this.state.isFirstNameValid ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_FIRST_NAME"
                      defaultMessage="You must enter first name"
                    />
                  ) : null
                }
              />
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formControl}
            >
              <TextField
                id="lastName"
                name="lastName"
                label={
                  <FormattedMessage
                    id="SIGNUP.FORM.LAST_NAME"
                    defaultMessage="Last name"
                  />
                }
                value={this.state.lastName}
                onChange={this.handleInputChange}
                error={!this.state.isLastNameValid}
                helperText={
                  !this.state.isLastNameValid ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_LAST_NAME"
                      defaultMessage="You must enter last name"
                    />
                  ) : null
                }
              />
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formControl}
            >
              <TextField
                id="email"
                name="email"
                type="email"
                label={
                  <FormattedMessage
                    id="SIGNUP.FORM.EMAIL"
                    defaultMessage="Email"
                  />
                }
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                error={
                  !this.state.isEmailValid || !this.state.isEmailAlreadyFree
                }
                helperText={
                  (!this.state.isEmailValid ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_EMAIL"
                      defaultMessage="You must enter valid email address"
                    />
                  ) : null) ||
                  (!this.state.isEmailAlreadyFree ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_EMAIL.ALREADY_IN_USE"
                      defaultMessage="The email address is already in use"
                    />
                  ) : null)
                }
              />
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formControl}
            >
              <TextField
                name="password"
                type="password"
                id="password"
                label={
                  <FormattedMessage
                    id="SIGNUP.FORM.PASSWORD"
                    defaultMessage="Password"
                  />
                }
                value={this.state.password}
                onChange={this.handleInputChange}
                error={
                  !this.state.isPasswordValid ||
                  !this.state.isPasswordEnoughStrength
                }
                helperText={
                  (!this.state.isPasswordValid ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_PASSWORD"
                      defaultMessage="You must enter password"
                    />
                  ) : null) ||
                  (!this.state.isPasswordEnoughStrength ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_PASSWORD_STRENGTH"
                      defaultMessage="You must enter strong password"
                    />
                  ) : null)
                }
              />
              <Typography component="p" className={classes.note}>
                <FormattedMessage
                  id="LOGIN.PASSWORD.CAPTION"
                  defaultMessage="At least 6 characters"
                />
              </Typography>
            </FormControl>

            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formControl}
            >
              <TextField
                name="confirmPassword"
                type="password"
                id="confirmPassword"
                label={
                  <FormattedMessage
                    id="SIGNUP.FORM.CONFIRM_PASSWORD"
                    defaultMessage="Confirm password"
                  />
                }
                value={this.state.confirmPassword}
                onChange={this.handleInputChange}
                error={!this.state.isConfirmPasswordValid}
                helperText={
                  !this.state.isConfirmPasswordValid ? (
                    <FormattedMessage
                      id="SIGNUP.ERROR.INVALID_CONFIRM_PASSWORD"
                      defaultMessage="You must enter the same password"
                    />
                  ) : null
                }
              />
              <Typography component="p" className={classes.note}>
                <FormattedMessage
                  id="LOGIN.PASSWORD.CAPTION"
                  defaultMessage="At least 6 characters"
                />
              </Typography>
            </FormControl>

            {isAdvisorSignup && (
              <FormControl margin="normal" required fullWidth>
                <FormControlLabel
                  className={classes.checkoutControl}
                  control={
                    <Checkbox
                      name={"isAdvisor"}
                      checked={this.state.isAdvisor}
                      onChange={this.handleChangeCheckbox}
                    />
                  }
                  label={
                    <FormattedMessage
                      id="SIGNUP.FORM.IS_ADVISOR"
                      defaultMessage="I am a financial advisor"
                    />
                  }
                />
              </FormControl>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
              disabled={this.state.isCreating}
            >
              {this.state.isCreating ? (
                <CircularProgress size={20} color={colors.white} />
              ) : (
                <FormattedMessage
                  id="SIGNUP.FORM.SUBMIT"
                  defaultMessage="Continue"
                />
              )}
            </Button>
          </form>
          <style>{`
            @keyframes autofill {
                 to { background: #ffffff; }
            }

            input:-webkit-autofill {
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
            }
        `}</style>
        </div>
      </>
    );
  };

  _renderAccountCreated = () => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.paperLeft}>
          <div className={classes.titleWrap} />
          <div className={classes.imgCont}>
            <img src="/assets/img/bg-sign.svg" alt="" />
          </div>
          <img
            className={classes.logoBottom}
            src="/assets/img/logo-orange-white.svg"
            alt=""
          />
        </div>
        <div className={classes.paperRight}>
          <div>
            <h1>
              <FormattedMessage
                id="SIGNUP.SUCCESS.HEADING"
                defaultMessage="Account was created"
              />
            </h1>
            <p>
              <FormattedMessage
                id="SIGNUP.SUCCESS.TEXT"
                defaultMessage="On specified email was sent confirmation link. Please check your mail."
              />
            </p>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <main
        className={!this.state.isEmailSent ? classes.main : classes.mainMessage}
      >
        <CssBaseline />
        <AppBarLogIn
          rightButtonLink={"/login"}
          rightButtonText={
            <FormattedMessage id="APP.LOG_IN" defaultMessage="Log In" />
          }
        />

        <Paper className={classes.paper}>
          {!this.state.isEmailSent
            ? this._renderForm()
            : this._renderAccountCreated()}
        </Paper>
      </main>
    );
  }
}

SignUp.defaultProps = {
  isAdvisorSignup: PropTypes.bool
};

export default withStyles(styles)(injectIntl(SignUp));
