import React from "react";
import "./ButtonSaveChanges.scss";

const Button = props => {
  return (
    <button
      className={`button-save ${props.block ? "button--block" : ""} ${
        props.round ? "button--round" : ""
      }`}
      style={props.style || {}}
      onClick={props.handleClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
