import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "components/_common/Button/ButtonAddAsset";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "../LogIn/styles";
import { FormattedMessage } from "react-intl-v3";
import { calcPasswordStrength } from "../LogIn/helper";

import { REQUIRED_PASSWORD_STRENGTH } from "../../constants";
import { Redirect } from "react-router";
import * as ROUTES from "constants/routes";
import AppBarLogIn from "../ContainerApp/AppBarLogIn";

const INITIAL_STATE = {
  password: "",
  confirmPassword: "",

  isPasswordValid: true,
  isPasswordEnoughStrength: true,
  isConfirmPasswordValid: true
};

class SignUp extends Component {
  static propTypes = {
    firebase: PropTypes.object.isRequired
  };



  state = INITIAL_STATE;

  handleSubmit = ev => {
    ev.preventDefault();

    if (!this.handleValidateData()) {
      return;
    }

    const { firebase, location } = this.props;
    const { password } = this.state;

    const urlParams = new URLSearchParams(location.search);
    const oobCodeQuery = urlParams.get("oobCode");

    const oobCode =
      location.state && location.state.code ? location.state.code : "";

    firebase
      .confirmPasswordReset(oobCode || oobCodeQuery, password)
      .then(user => {
        this.setState({
          passwordChanged: true
        });
      })
      .catch(err => {
        // TODO ERROR HANDLE AND SHOW!! at first just show message before button
        console.warn("---", err);
      });
  };

  handleInputChange = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value,
      isPasswordValid: true,
      isPasswordEnoughStrength: true,
      isConfirmPasswordValid: true
    });
  };

  handleValidateData = () => {
    const isPasswordValid = !!this.state.password;
    const isPasswordEnoughStrength =
      calcPasswordStrength(this.state.password) > REQUIRED_PASSWORD_STRENGTH;
    const isConfirmPasswordValid =
      !this.state.password ||
      this.state.password === this.state.confirmPassword;

    this.setState({
      isPasswordValid,
      isPasswordEnoughStrength,
      isConfirmPasswordValid
    });

    return (
      isPasswordValid && isPasswordEnoughStrength && isConfirmPasswordValid
    );
  };

  _renderForm = () => {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.paper}>
          <div className={classes.paperLeft}>
            <div className={classes.titleWrap}>
              <Typography component="h1" variant="h5" className={classes.title}>
                <FormattedMessage
                  id="CHANGE_PASSWORD.HEADING"
                  defaultMessage="Change password"
                />
              </Typography>
            </div>
            <div className={classes.imgCont}>
              <img src="/assets/img/bg-sign.svg" alt="" />
            </div>
            <img
              className={classes.logoBottom}
              src="/assets/img/logo-orange-white.svg"
              alt=""
            />
          </div>

          <div className={classes.paperRight}>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  name="password"
                  type="password"
                  id="password"
                  label={
                    <FormattedMessage
                      id="SIGNUP.FORM.PASSWORD"
                      defaultMessage="Password"
                    />
                  }
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  error={
                    !this.state.isPasswordValid ||
                    !this.state.isPasswordEnoughStrength
                  }
                  helperText={
                    (!this.state.isPasswordValid ? (
                      <FormattedMessage
                        id="SIGNUP.ERROR.INVALID_PASSWORD"
                        defaultMessage="You must enter password"
                      />
                    ) : null) ||
                    (!this.state.isPasswordEnoughStrength ? (
                      <FormattedMessage
                        id="SIGNUP.ERROR.INVALID_PASSWORD_STRENGTH"
                        defaultMessage="You must enter password"
                      />
                    ) : null)
                  }
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <TextField
                  required
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  label={
                    <FormattedMessage
                      id="SIGNUP.FORM.CONFIRM_PASSWORD"
                      defaultMessage="Confirm password"
                    />
                  }
                  value={this.state.confirmPassword}
                  onChange={this.handleInputChange}
                  error={!this.state.isConfirmPasswordValid}
                  helperText={
                    !this.state.isConfirmPasswordValid ? (
                      <FormattedMessage
                        id="SIGNUP.ERROR.INVALID_CONFIRM_PASSWORD"
                        defaultMessage="You must enter enter the same password"
                      />
                    ) : null
                  }
                />
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleSubmit}
              >
                <FormattedMessage
                  id="CHANGE_PASSWORD.SUBMIT"
                  defaultMessage="Change"
                />
              </Button>
            </form>
          </div>
        </Paper>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.passwordChanged) {
      return <Redirect to={ROUTES.LOG_IN} />;
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <AppBarLogIn
          rightButtonLink={"/login"}
          rightButtonText={
            <FormattedMessage id="APP.LOG_IN" defaultMessage="Log In" />
          }
        />
        {this._renderForm()}
      </main>
    );
  }
}

SignUp.defaultProps = {};

export default withStyles(styles)(SignUp);
