import {
  ENTITIES as entitiesEvents,
  GLOBAL_COLLECTION as collectionEvents,
  GLOBAL_TYPE_USE_ASSETS as globalTypeEvents,
  ENTITIES_ADDITIONAL as entitiesAdditionalEvents,
  formScheme as formSchemeEvents,
  designScheme as designSchemeEvents,
  formsValidationScheme as validationSchemeEvents,
  formsInitialState as formsInitialEvents,
  translations as translationsEvents,
  //
  ENTITIES_GROUPS as entitiesGroupsEvents,
  RIGHT_LEVEL as rightLevelEvents,
  RIGHT_SHARE as rightShareEvents
} from "components/PlansEvents/formScheme";

import {
  ENTITIES as entitiesGoals,
  GLOBAL_COLLECTION as collectionGoals,
  GLOBAL_TYPE_USE_ASSETS as globalTypeGoals,
  ENTITIES_ADDITIONAL as entitiesAdditionalGoals,
  formScheme as formSchemeGoals,
  designScheme as designSchemeGoals,
  formsValidationScheme as validationSchemeGoals,
  formsInitialState as formsInitialGoals,
  translations as translationsGoals,
  //
  ENTITIES_GROUPS as entitiesGroupsGoals,
  RIGHT_LEVEL as rightLevelGoals,
  RIGHT_SHARE as rightShareGoals
} from "components/PlansGoals/formScheme";

import {
  ENTITIES as entitiesUse,
  GLOBAL_TYPE_USE_ASSETS as globalTypeUse,
  GLOBAL_COLLECTION_USE_ASSETS as collectionUse,
  ENTITIES_ADDITIONAL as entitiesAdditionalUse,
  formScheme as formSchemeUse,
  designScheme as designSchemeUse,
  formsValidationScheme as validationSchemeUse,
  formsInitialState as formsInitialUse,
  translations as translationsUse,
  //
  ENTITIES_GROUPS as entitiesGroupsUse,
  RIGHT_LEVEL as rightLevelUse,
  RIGHT_SHARE as rightShareUse
} from "components/HouseholdAssetsUse/formScheme";
import {
  ENTITIES as entitiesSavings,
  GLOBAL_TYPE_USE_ASSETS as globalTypeSavings,
  GLOBAL_COLLECTION_USE_ASSETS as collectionSavings,
  ENTITIES_ADDITIONAL as entitiesAdditionalSavings,
  formScheme as formSchemeSavings,
  designScheme as designSchemeSavings,
  formsValidationScheme as validationSchemeSavings,
  formsInitialState as formsInitialSavings,
  translations as translationsSavings,
  //
  ENTITIES_GROUPS as entitiesGroupsSavings,
  RIGHT_LEVEL as rightLevelSavings,
  RIGHT_SHARE as rightShareSavings
} from "components/HouseholdAssetsSavings/formScheme";
import {
  ENTITIES as entitiesInvestments,
  GLOBAL_TYPE_USE_ASSETS as globalTypeInvestments,
  GLOBAL_COLLECTION_USE_ASSETS as collectionInvestments,
  ENTITIES_ADDITIONAL as entitiesAdditionalInvestments,
  formScheme as formSchemeInvestments,
  designScheme as designSchemeInvestments,
  formsValidationScheme as validationSchemeInvestments,
  formsInitialState as formsInitialInvestments,
  translations as translationsInvestments,
  //
  ENTITIES_GROUPS as entitiesGroupsInvestments,
  RIGHT_LEVEL as rightLevelInvestments,
  RIGHT_SHARE as rightShareInvestments
} from "components/HouseholdAssetsInvested/formScheme";
import {
  ENTITIES as entitiesDebt,
  GLOBAL_TYPE_USE_ASSETS as globalTypeDebt,
  GLOBAL_COLLECTION as collectionDebt,
  ENTITIES_ADDITIONAL as entitiesAdditionalDebt,
  formScheme as formSchemeDebt,
  designScheme as designSchemeDebt,
  formsValidationScheme as validationSchemeDebt,
  formsInitialState as formsInitialDebt,
  translations as translationsDebt,
  //
  ENTITIES_GROUPS as entitiesGroupsDebt,
  RIGHT_LEVEL as rightLevelDebt,
  RIGHT_SHARE as rightShareDebt
} from "components/HouseholdDebt/formScheme";
import {
  ENTITIES as entitiesAccounts,
  GLOBAL_TYPE_USE_ASSETS as globalTypeAccounts,
  GLOBAL_COLLECTION as collectionAccounts,
  ENTITIES_ADDITIONAL as entitiesAdditionalAccounts,
  formScheme as formSchemeAccounts,
  designScheme as designSchemeAccounts,
  formsValidationScheme as validationSchemeAccounts,
  formsInitialState as formsInitialAccounts,
  translations as translationsAccounts,
  //
  ENTITIES_GROUPS as entitiesGroupsAccounts,
  RIGHT_LEVEL as rightLevelAccounts,
  RIGHT_SHARE as rightShareAccounts
} from "components/HouseholdAccounts/formScheme";
import {
  ENTITIES as entitiesMoneyIn,
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyIn,
  GLOBAL_COLLECTION as collectionMoneyIn,
  ENTITIES_ADDITIONAL as entitiesAdditionalMoneyIn,
  formScheme as formSchemeMoneyIn,
  designScheme as designSchemeMoneyIn,
  formsValidationScheme as validationSchemeMoneyIn,
  formsInitialState as formsInitialMoneyIn,
  translations as translationsMoneyIn,
  //
  ENTITIES_GROUPS as entitiesGroupsMoneyIn,
  RIGHT_LEVEL as rightLevelMoneyIn,
  RIGHT_SHARE as rightShareMoneyIn
} from "components/HouseholdMoneyIn/formScheme";
import {
  ENTITIES as entitiesMoneyOutPersonal,
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutPersonal,
  GLOBAL_COLLECTION as collectionMoneyOutPersonal,
  ENTITIES_ADDITIONAL as entitiesAdditionalMoneyOutPersonal,
  formScheme as formSchemeMoneyOutPersonal,
  designScheme as designSchemeMoneyOutPersonal,
  formsValidationScheme as validationSchemeMoneyOutPersonal,
  formsInitialState as formsInitialMoneyOutPersonal,
  translations as translationsMoneyOutPersonal,
  //
  ENTITIES_GROUPS as entitiesGroupsMoneyOutPersonal,
  RIGHT_LEVEL as rightLevelMoneyOutPersonal,
  RIGHT_SHARE as rightShareMoneyOutPersonal
} from "components/MoneyOutPersonal/formScheme";
import {
  ENTITIES as entitiesMoneyOutAssets,
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutAssets,
  GLOBAL_COLLECTION as collectionMoneyOutAssets,
  ENTITIES_ADDITIONAL as entitiesAdditionalMoneyOutAssets,
  formScheme as formSchemeMoneyOutAssets,
  designScheme as designSchemeMoneyOutAssets,
  formsValidationScheme as validationSchemeMoneyOutAssets,
  formsInitialState as formsInitialMoneyOutAssets,
  translations as translationsMoneyOutAssets,
  //
  ENTITIES_GROUPS as entitiesGroupsMoneyOutAssets,
  RIGHT_LEVEL as rightLevelMoneyOutAssets,
  RIGHT_SHARE as rightShareMoneyOutAssets
} from "components/MoneyOutAssets/formScheme";

import {
  ENTITIES as entitiesMoneyOutOther,
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutOther,
  GLOBAL_COLLECTION as collectionMoneyOutOther,
  ENTITIES_ADDITIONAL as entitiesAdditionalMoneyOutOther,
  formScheme as formSchemeMoneyOutOther,
  designScheme as designSchemeMoneyOutOther,
  formsValidationScheme as validationSchemeMoneyOutOther,
  formsInitialState as formsInitialMoneyOutOther,
  translations as translationsMoneyOutOther,
  //
  ENTITIES_GROUPS as entitiesGroupsMoneyOutOther,
  RIGHT_LEVEL as rightLevelMoneyOutOther,
  RIGHT_SHARE as rightShareMoneyOutOther
} from "components/MoneyOutOther/formScheme";

export const collectionsFull = {
  ASSETS: "userAssets",
  TRANSACTIONS: "transactions",
  ADVISOR: "advisor"
};

export const globalTypes = {
  globalTypeEvents: globalTypeEvents,
  globalTypeGoals: globalTypeGoals,
  globalTypeUse: globalTypeUse,
  globalTypeSavings: globalTypeSavings,
  globalTypeInvestments: globalTypeInvestments,
  globalTypeDebt: globalTypeDebt,
  globalTypeAccounts: globalTypeAccounts,
  globalTypeMoneyIn: globalTypeMoneyIn,
  globalTypeMoneyOutPersonal: globalTypeMoneyOutPersonal,
  globalTypeMoneyOutAssets: globalTypeMoneyOutAssets,
  globalTypeMoneyOutOther: globalTypeMoneyOutOther
};
export const entitiesFull = {
  entitiesEvents: entitiesEvents,
  entitiesGoals: entitiesGoals,
  entitiesUse: entitiesUse,
  entitiesSavings: entitiesSavings,
  entitiesInvestments: entitiesInvestments,
  entitiesDebt: entitiesDebt,
  entitiesAccounts: entitiesAccounts,
  entitiesMoneyIn: entitiesMoneyIn,
  entitiesMoneyOutPersonal: entitiesMoneyOutPersonal,
  entitiesMoneyOutAssets: entitiesMoneyOutAssets,
  entitiesMoneyOutOther: entitiesMoneyOutOther
};

export const entitiesScheme = {
  //events
  [globalTypeEvents]: {
    entities: entitiesEvents,
    assetGlobalType: globalTypeEvents,
    assetCollection: collectionEvents,
    formScheme: formSchemeEvents,
    designScheme: designSchemeEvents,
    validationScheme: validationSchemeEvents,
    formsInitial: formsInitialEvents,
    translations: translationsEvents,
    //
    ENTITIES_GROUPS: entitiesGroupsEvents,
    entitiesAdditional: entitiesAdditionalEvents,
    rightLevel: rightLevelEvents,
    rightShare: rightShareEvents
  },

  //goals
  [globalTypeGoals]: {
    entities: entitiesGoals,
    assetGlobalType: globalTypeGoals,
    assetCollection: collectionGoals,
    formScheme: formSchemeGoals,
    designScheme: designSchemeGoals,
    validationScheme: validationSchemeGoals,
    formsInitial: formsInitialGoals,
    translations: translationsGoals,
    //
    ENTITIES_GROUPS: entitiesGroupsGoals,
    entitiesAdditional: entitiesAdditionalGoals,
    rightLevel: rightLevelGoals,
    rightShare: rightShareGoals
  },

  //use
  [globalTypeUse]: {
    entities: entitiesUse,
    assetGlobalType: globalTypeUse,
    assetCollection: collectionUse,
    formScheme: formSchemeUse,
    designScheme: designSchemeUse,
    validationScheme: validationSchemeUse,
    formsInitial: formsInitialUse,
    translations: translationsUse,
    //
    ENTITIES_GROUPS: entitiesGroupsUse,
    entitiesAdditional: entitiesAdditionalUse,

    rightLevel: rightLevelUse,
    rightShare: rightShareUse,
    additionalSaveGlobalType: {
      debt: {
        globalType: globalTypeDebt,
        collection: collectionDebt,
        fieldId: "debtId",
        externalField: "assetId"
      }
    },
    updateFieldGlobalType: {
      useToDebt: {
        globalType: globalTypeUse,
        collection: collectionUse,
        fieldId: "assetId",
        externalField: "debtId"
      }
    }
  },
  //savings
  [globalTypeSavings]: {
    entities: entitiesSavings,
    assetGlobalType: globalTypeSavings,
    assetCollection: collectionSavings,
    formScheme: formSchemeSavings,
    designScheme: designSchemeSavings,
    validationScheme: validationSchemeSavings,
    formsInitial: formsInitialSavings,
    translations: translationsSavings,
    //
    ENTITIES_GROUPS: entitiesGroupsSavings,
    entitiesAdditional: entitiesAdditionalSavings,
    rightLevel: rightLevelSavings,
    rightShare: rightShareSavings
  },
  //investments
  [globalTypeInvestments]: {
    entities: entitiesInvestments,
    assetGlobalType: globalTypeInvestments,
    assetCollection: collectionInvestments,
    formScheme: formSchemeInvestments,
    designScheme: designSchemeInvestments,
    validationScheme: validationSchemeInvestments,
    formsInitial: formsInitialInvestments,
    translations: translationsInvestments,
    //
    ENTITIES_GROUPS: entitiesGroupsInvestments,
    entitiesAdditional: entitiesAdditionalInvestments,
    rightLevel: rightLevelInvestments,
    rightShare: rightShareInvestments
  },
  //debts
  [globalTypeDebt]: {
    entities: entitiesDebt,
    assetGlobalType: globalTypeDebt,
    assetCollection: collectionDebt,
    formScheme: formSchemeDebt,
    designScheme: designSchemeDebt,
    validationScheme: validationSchemeDebt,
    formsInitial: formsInitialDebt,
    translations: translationsDebt,
    //
    ENTITIES_GROUPS: entitiesGroupsDebt,
    entitiesAdditional: entitiesAdditionalDebt,
    rightLevel: rightLevelDebt,
    rightShare: rightShareDebt,

    updateFieldGlobalType: {
      debtToUse: {
        globalType: globalTypeUse,
        collection: collectionUse,
        fieldId: "assetId",
        externalField: "debtId"
      }
    }
  },

  //accounts
  [globalTypeAccounts]: {
    entities: entitiesAccounts,
    assetGlobalType: globalTypeAccounts,
    assetCollection: collectionAccounts,
    formScheme: formSchemeAccounts,
    designScheme: designSchemeAccounts,
    validationScheme: validationSchemeAccounts,
    formsInitial: formsInitialAccounts,
    translations: translationsAccounts,
    //
    ENTITIES_GROUPS: entitiesGroupsAccounts,
    entitiesAdditional: entitiesAdditionalAccounts,
    rightLevel: rightLevelAccounts,
    rightShare: rightShareAccounts
  },
  //Money In income
  [globalTypeMoneyIn]: {
    entities: entitiesMoneyIn,
    assetGlobalType: globalTypeMoneyIn,
    assetCollection: collectionMoneyIn,
    formScheme: formSchemeMoneyIn,
    designScheme: designSchemeMoneyIn,
    validationScheme: validationSchemeMoneyIn,
    formsInitial: formsInitialMoneyIn,
    translations: translationsMoneyIn,
    //
    ENTITIES_GROUPS: entitiesGroupsMoneyIn,
    entitiesAdditional: entitiesAdditionalMoneyIn,
    rightLevel: rightLevelMoneyIn,
    rightShare: rightShareMoneyIn
  },
  //Money Out living exp
  [globalTypeMoneyOutPersonal]: {
    entities: entitiesMoneyOutPersonal,
    assetGlobalType: globalTypeMoneyOutPersonal,
    assetCollection: collectionMoneyOutPersonal,
    formScheme: formSchemeMoneyOutPersonal,
    designScheme: designSchemeMoneyOutPersonal,
    validationScheme: validationSchemeMoneyOutPersonal,
    formsInitial: formsInitialMoneyOutPersonal,
    translations: translationsMoneyOutPersonal,
    //
    ENTITIES_GROUPS: entitiesGroupsMoneyOutPersonal,
    entitiesAdditional: entitiesAdditionalMoneyOutPersonal,
    rightLevel: rightLevelMoneyOutPersonal,
    rightShare: rightShareMoneyOutPersonal
  },
  //Money Out property expenses
  [globalTypeMoneyOutAssets]: {
    entities: entitiesMoneyOutAssets,
    assetGlobalType: globalTypeMoneyOutAssets,
    assetCollection: collectionMoneyOutAssets,
    formScheme: formSchemeMoneyOutAssets,
    designScheme: designSchemeMoneyOutAssets,
    validationScheme: validationSchemeMoneyOutAssets,
    formsInitial: formsInitialMoneyOutAssets,
    translations: translationsMoneyOutAssets,
    //
    ENTITIES_GROUPS: entitiesGroupsMoneyOutAssets,
    entitiesAdditional: entitiesAdditionalMoneyOutAssets,
    rightLevel: rightLevelMoneyOutAssets,
    rightShare: rightShareMoneyOutAssets
  },
  //Money Out rent & lease
  [globalTypeMoneyOutOther]: {
    entities: entitiesMoneyOutOther,
    assetGlobalType: globalTypeMoneyOutOther,
    assetCollection: collectionMoneyOutOther,
    formScheme: formSchemeMoneyOutOther,
    designScheme: designSchemeMoneyOutOther,
    validationScheme: validationSchemeMoneyOutOther,
    formsInitial: formsInitialMoneyOutOther,
    translations: translationsMoneyOutOther,
    //
    ENTITIES_GROUPS: entitiesGroupsMoneyOutOther,
    entitiesAdditional: entitiesAdditionalMoneyOutOther,
    rightLevel: rightLevelMoneyOutOther,
    rightShare: rightShareMoneyOutOther
  }
};
