import {
  addAdditionalConfiguration,
  commonPrefixAfter,
  prepareState,
  repeatableFields as repeatableCommon
} from "../AssetsCommon/_formScheme";
import {
  RIGHT_HOUSEHOLD_MONEY_OUT,
  RIGHT_HOUSEHOLD_MONEY_OUT_OTHER
} from "constants/rights";
import { MONEY_OUT_ENDING_SOON } from "../MoneyOutPersonal/formScheme";
import { optionsValuesMaturity } from "../HouseholdAccounts/formScheme";

export const GLOBAL_COLLECTION = "transactions";
export const GLOBAL_TYPE_COLLECTION = "out";
export const GLOBAL_TYPE_USE_ASSETS = "money-out-other";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_MONEY_OUT;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_MONEY_OUT_OTHER;

const ENTITY_EXPENSES = "expenses";
const ENTITY_HOUSE_RENT = "houseRent";
const ENTITY_CAR_LEASE = "carLease";
const ENTITY_OTHER = "other";

export const ENTITIES = {
  ENTITY_EXPENSES,
  ENTITY_HOUSE_RENT,
  ENTITY_CAR_LEASE,
  ENTITY_OTHER
};

// const ENTITY_ADD_CONTRIBUTION = "";

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.MONEY_OUT.OTHER.FORM_GROUP.TITLE": {
    title: "Other Expenses",
    ENTITIES: {
      // ENTITY_EXPENSES,
      ENTITY_HOUSE_RENT,
      ENTITY_CAR_LEASE,
      ENTITY_OTHER
    }
  }
};

const optionsMaturity = [
  {
    name: "Renew",
    nameI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.FORM.MATURITY.TYPES.RENEW",
    value: optionsValuesMaturity.RENEW
  },
  {
    name: "End",
    nameI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.FORM.MATURITY.TYPES.END",
    value: optionsValuesMaturity.END
  }
];

const repeatableSchemeGeneral = {
  description: {
    isAdditional: true,
    isSecondary: true,
    isSubTitle: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.DESCRIPTION.TITLE"
  },
  annualCost: {
    isMain: true,

    isRequired: true,
    isAmount: true,
    isNumber: true,
    calculate: { isMOutOtherAnnual: true },

    label: "Annual Cost",
    labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.ANNUAL_COST.TITLE"
  },

  contractStartDate: {
    isDate: true,
    label: "Contract Start Date",
    labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.CONTRACT_START_DATE.TITLE"
  },

  durationMonths: {
    ...repeatableCommon.durationYears,

    isAdditional: true,
    calculate: { isMonthsLeftPeriod: true, fieldDate: "contractStartDate" },
    expiredCalculate: {
      isMonthsLeftPeriod: true,
      fieldDate: "contractStartDate"
    },
    endingSoonCalculate: {
      isMonthsLeftPeriod: true,
      monthsLeftPeriod: MONEY_OUT_ENDING_SOON,
      fieldDate: "contractStartDate"
    },

    condition: [
      {
        field: "contractStartDate",
        isFilled: true
      }
    ],

    isRequired: true,
    isNumber: true,
    isInt: true,
    min: 0,
    after: commonPrefixAfter.MNTH,
    labelCard: "Months Left",
    labelI18nCard: "HOUSEHOLD.COMMON.FORM.MONTHS_LEFT.LABEL",
    label: "Duration",
    labelI18n: "HOUSEHOLD.COMMON.FORM.DURATION.LABEL"
  },
  maturity: {
    isSelect: true,
    condition: [
      {
        field: "contractStartDate",
        isFilled: true
      }
    ],
    options: optionsMaturity,

    label: "On Maturity",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.MATURITY.LABEL"
  }

  // fromAge: { ...repeatableCommon.fromAge },
  // toAge: { ...repeatableCommon.toAge }
};

export const designScheme = {
  // [ENTITIES.ENTITY_EXPENSES]: {
  //   icon: "IconMoneyOut",
  //   color: "green",
  //   title: "Expenses",
  //   titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TILE.EXPENSES"
  // },
  [ENTITIES.ENTITY_HOUSE_RENT]: {
    icon: "IconHome",
    color: "green",
    title: "House Rent",
    titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TILE.HOUSE_RENT"
  },
  [ENTITIES.ENTITY_CAR_LEASE]: {
    icon: "IconVehicle",
    color: "green",
    title: "Car Lease",
    titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TILE.CAR_LEASE"
  },
  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TILE.OTHER"
  }
};

export const ENTITIES_ADDITIONAL = {};

export const formScheme = {
  // [ENTITIES.ENTITY_EXPENSES]: {
  //   description: {
  //     ...repeatableSchemeGeneral.description,
  //     label: "Description",
  //     labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.EXPENSES.DESCRIPTION.LABEL"
  //   },
  //   annualCost: { ...repeatableSchemeGeneral.annualCost },
  //   contractStartDate: { ...repeatableSchemeGeneral.contractStartDate },
  //   durationMonths: { ...repeatableSchemeGeneral.durationMonths }
  // },
  [ENTITIES.ENTITY_HOUSE_RENT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.EXPENSES.DESCRIPTION.LABEL"
    },
    annualCost: { ...repeatableSchemeGeneral.annualCost },
    contractStartDate: { ...repeatableSchemeGeneral.contractStartDate },
    durationMonths: { ...repeatableSchemeGeneral.durationMonths },
    maturity: { ...repeatableSchemeGeneral.maturity }
  },
  [ENTITIES.ENTITY_CAR_LEASE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.EXPENSES.DESCRIPTION.LABEL"
    },
    annualCost: { ...repeatableSchemeGeneral.annualCost },
    contractStartDate: { ...repeatableSchemeGeneral.contractStartDate },
    durationMonths: { ...repeatableSchemeGeneral.durationMonths },
    maturity: { ...repeatableSchemeGeneral.maturity }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.EXPENSES.DESCRIPTION.LABEL"
    },
    annualCost: { ...repeatableSchemeGeneral.annualCost },
    contractStartDate: { ...repeatableSchemeGeneral.contractStartDate },
    durationMonths: { ...repeatableSchemeGeneral.durationMonths },
    maturity: { ...repeatableSchemeGeneral.maturity }
  }
};

addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  GLOBAL_TYPE_USE_ASSETS
);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER",
        title: "Rent & Lease"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TITLE",
      title: "Ok. Now let’s dig into the other cost"
    },
    caption: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.CAPTION",
      title:
        "We’ll make sure you are contributing to funds that make sense for you"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.EMPTY",
      title:
        "You have no personal other money out,\n please add your first other money out"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.H2",
      title: "Other Expenses"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TOAST.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TOAST.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.TOAST.SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.SIDE_MENU_TITLE",
      title: "Add Other Expenses"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.SIDE_MENU_TITLE.EDIT",
      title: "Edit Other Expenses"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM_GROUP.TITLE",
      title: "What do people in your family spend?"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.GENERAL_GROUP",
      title: "General Expenses",
      caption:
        "Sure, you can enter the total value of all your spend expanses, but we will not be able to predict their future value. To continue anyway, enter a value and select “General Expanses”",
      captionI18n: "HOUSEHOLD.MONEY_OUT.OTHER.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.VALUE.BUTTON.SAVE",
      title: "Save"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.FORM.VALUE.BUTTON",
      title: "+ Add"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.MONEY_OUT.OTHER.WHY.TITLE",
    textI18n: "HOUSEHOLD.MONEY_OUT.OTHER.WHY.ALL_TEXT"
  }
};
