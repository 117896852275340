import React, { Component } from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";
import TooltipTitle from "../../_common/TooltipTitle";
import Slider from "@material-ui/lab/Slider";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { formatMoneyIntWithSign, roundToThousand } from "../../../lib/helpers";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { WIZARD_ASSETS } from "constants/wizardSetDefault";
import ButtonMUI from "@material-ui/core/Button/Button";
import { taxPropertyValue } from "../../../constants/taxesAndDashboard";

class WizardAssets extends Component {
  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderContent = () => {
    const { classes, formState, handleInputChange, fullState } = this.props;
    const primaryHomeValue =
      fullState.money.primaryResidence === "own" && fullState.money.propertyTax
        ? roundToThousand(fullState.money.propertyTax / taxPropertyValue)
        : 0;

    const retirementTotal =
      (+formState.purposelySaved || 0) + (+formState.retirementFunds || 0);

    const investTotal =
      (+formState.invested || 0) + (+formState.checkingOrSaving || 0);

    return (
      <div className={classes.wizardBodyForm}>
        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.HOMES_VEHICLES"
                  defaultMessage="What is the value of all of your homes and vehicles?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="homesVehicles"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.homesVehicles}
                />
                <Slider
                  min={WIZARD_ASSETS.HOMES_VEHICLES_MIN + +primaryHomeValue}
                  max={
                    primaryHomeValue > WIZARD_ASSETS.HOMES_VEHICLES_MAX
                      ? primaryHomeValue
                      : WIZARD_ASSETS.HOMES_VEHICLES_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "homesVehicles")}
                  value={+formState.homesVehicles}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS.FORM.HOMES_VEHICLES" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.ASSETS.TOTAL.HOMES_VEHICLES"
                  defaultMessage="Homes & vehicles"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(+formState.homesVehicles || 0)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.RETIREMENT_FUNDS"
                  defaultMessage="What is the value of all of your retirement funds (e.g. 401K, IRA)?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="retirementFunds"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.retirementFunds}
                />
                <Slider
                  min={WIZARD_ASSETS.RETIREMENT_MIN}
                  max={
                    formState.retirementFunds > WIZARD_ASSETS.RETIREMENT_MAX
                      ? formState.retirementFunds
                      : WIZARD_ASSETS.RETIREMENT_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "retirementFunds")}
                  value={+formState.retirementFunds || 0}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS.FORM.RETIREMENT_FUNDS" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.PURPOSELY_SAVED"
                  defaultMessage="How much do you have purposely saved (e.g. 529 for college)?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="purposelySaved"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.purposelySaved}
                />
                <Slider
                  min={WIZARD_ASSETS.SAVED_MIN}
                  max={
                    formState.purposelySaved > WIZARD_ASSETS.SAVED_MAX
                      ? formState.purposelySaved
                      : WIZARD_ASSETS.SAVED_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "purposelySaved")}
                  value={+formState.purposelySaved}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS.FORM.PURPOSELY_SAVED" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.ASSETS.TOTAL.RETIREMENT_SAVING"
                  defaultMessage="Retirement & Savings"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(retirementTotal)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.CHECKING_OR_SAVING"
                  defaultMessage="How much cash do you have in checking or saving accounts?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="checkingOrSaving"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  isInt
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.checkingOrSaving}
                />
                <Slider
                  min={WIZARD_ASSETS.CHECKING_SAVING_MIN}
                  max={
                    formState.checkingOrSaving >
                    WIZARD_ASSETS.CHECKING_SAVING_MAX
                      ? formState.checkingOrSaving
                      : WIZARD_ASSETS.CHECKING_SAVING_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "checkingOrSaving")}
                  value={+formState.checkingOrSaving}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS.FORM.CHECKING_OR_SAVING" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.INVESTED"
                  defaultMessage="How much do you invested in stocks, mutual funds and alike?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="invested"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.invested}
                />
                <Slider
                  min={WIZARD_ASSETS.INVESTED_MIN}
                  max={
                    formState.invested > WIZARD_ASSETS.INVESTED_MAX
                      ? formState.invested
                      : WIZARD_ASSETS.INVESTED_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "invested")}
                  value={+formState.invested}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={<TooltipTitle labelI18n="WIZARD.ASSETS.FORM.INVESTED" />}
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.ASSETS.TOTAL.INVESTMENTS"
                  defaultMessage="Investments"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(investTotal)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS.FORM.DEATH_BENEFIT"
                  defaultMessage="If you have life insurance, what’s the death benefit?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="deathBenefit"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assets")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.deathBenefit}
                />
                <Slider
                  min={WIZARD_ASSETS.DEATH_MIN}
                  max={
                    formState.deathBenefit > WIZARD_ASSETS.DEATH_MAX
                      ? formState.deathBenefit
                      : WIZARD_ASSETS.DEATH_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assets", "deathBenefit")}
                  value={+formState.deathBenefit}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS.FORM.DEATH_BENEFIT" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.ASSETS.TOTAL.RISK"
                  defaultMessage="Risk transfer"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(+formState.deathBenefit || 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      handleChangeStep,
      prevStep,
      nextStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.ASSETS.LABEL"
                defaultMessage="Assets and liabilities"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={5} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.ASSETS.HEADING2"
              defaultMessage="The value of your Assets minus your debt help determine how much you can rely on what you own in the future"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardAssets.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  fullState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardAssets;
