import React, { PureComponent } from "react";

import PropTypes from "prop-types";
import Slider from "react-slick/lib";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} events__buttons events__next-mobile`}
      style={{ ...style }}
      onClick={onClick}
    >
      Next >
    </div>
  );
};

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} events__buttons events__prev-mobile`}
      style={{ ...style }}
      onClick={onClick}
    >
      {`< Prev`}
    </div>
  );
};

class EventsSlider extends PureComponent {
  state = {
    currentSlide: 0,
    currentSlideScroll: 0,
    lastChangeScroll: false,
    timestamp: null,
    slidesToShowCalc: 1
  };

  static propTypes = {
    totalSlides: PropTypes.number,
    activeSlide: PropTypes.any,
    hideScroll: PropTypes.bool,
    isMobile: PropTypes.bool,
    containerWidth: PropTypes.any,
    ref: PropTypes.any,
    isLazyLoad: PropTypes.any,
    addTile: PropTypes.any,
    handleEventSliderHeight: PropTypes.func,
    handleSliderCurrentSlide: PropTypes.func
  };

  slider = React.createRef();

  componentDidMount() {
    const slider = this.slider.current;

    const list = slider.innerSlider.list.getBoundingClientRect();

    if (list) {
      this.handleUpdateSlidesToShow();
    }

    if (this.props.handleEventSliderHeight) {
      this.props.handleEventSliderHeight(list.height);
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (this.props.activeSlide !== prevProps.activeSlide) {
      const activeSlideCalc = this.handleGetActiveSlideCalc();
      this.slider.current.slickGoTo(
        activeSlideCalc || this.props.activeSlide,
        true
      );
    }

    const slider = this.slider.current;
    const list = slider.innerSlider.list.getBoundingClientRect();

    if (list) {
      this.handleUpdateSlidesToShow();
    }

    if (this.props.handleEventSliderHeight) {
      this.props.handleEventSliderHeight(list.height);
    }
  }

  handleUpdateSlidesToShow = () => {
    const { addTile } = this.props;
    const slider = this.slider.current;

    const isDashboard = !!document.querySelector(".dashboard__combine");

    const slickSlide = slider.innerSlider.list.querySelector(
      ".slick-slide:not(:first-child)"
    );

    if (slickSlide) {
      const list = slider.innerSlider.list.getBoundingClientRect();

      const slideRect = slickSlide.getBoundingClientRect();

      const listCalcWidth = Math.min(
        document.body.clientWidth - 32,
        list.width
      );

      const listWidth =
        !isDashboard && addTile
          ? listCalcWidth - slideRect.width
          : listCalcWidth;

      const slidesToShowCalc = parseInt(listWidth / slideRect.width);

      if (this.state.slidesToShowCalc !== slidesToShowCalc) {
        this.setState({ slidesToShowCalc });
      }
    }
  };

  handleGetActiveSlideCalc = () => {
    const { totalSlides, activeSlide } = this.props;

    let activeSlideCalc;

    if (totalSlides - this.state.slidesToShowCalc < activeSlide) {
      activeSlideCalc = totalSlides - this.state.slidesToShowCalc;
    }

    return activeSlideCalc;
  };

  handleNextSlide = () => {
    if (this.slider && this.slider.current && this.slider.current.slickNext) {
      this.slider.current.slickNext();
    }
  };

  handlePrevSlide = () => {
    if (this.slider && this.slider.current && this.slider.current.slickPrev) {
      this.slider.current.slickPrev();
    }
  };

  handleSliderChange = ev => {
    const { value } = ev.target;

    this.setState(prevState => ({
      currentSlide: value,
      currentSlideScroll: value,
      lastChangeScroll: true,
      timestamp: Date.now()
    }));

    this.slider.current.slickGoTo(value, true);
  };

  handleSliderChanged = (index, ...rest) => {
    this.setState(prevState => ({
      currentSlide: index,
      lastChangeScroll: false
    }));

    if (this.props.handleSliderCurrentSlide) {
      this.props.handleSliderCurrentSlide(index);
    }
  };

  render() {
    const {
      isMobile,
      totalSlides,
      hideScroll,
      isLazyLoad,
      activeSlide,
      addTile
    } = this.props;

    const slidesToShow =
      isMobile || totalSlides === 1
        ? 1
        : Math.min(totalSlides, this.state.slidesToShowCalc);

    let activeSlideCalc = activeSlide;

    let centerModeCalc =
      (!!activeSlide && activeSlide > 1 && this.state.currentSlide > 1) ||
      false;

    if (this.state.slidesToShowCalc && activeSlide) {
      centerModeCalc =
        this.state.slidesToShowCalc / 2 < activeSlide &&
        totalSlides - this.state.slidesToShowCalc > activeSlide;

      if (totalSlides - this.state.slidesToShowCalc < activeSlide) {
        activeSlideCalc = totalSlides - this.state.slidesToShowCalc;
      }
    }

    if (
      this.state.currentSlide !== activeSlide &&
      (this.state.slidesToShowCalc / 2 < this.state.currentSlide ||
        totalSlides - this.state.slidesToShowCalc > this.state.currentSlide)
    ) {
      centerModeCalc = false;
    }

    if (!activeSlideCalc || activeSlideCalc < 0) {
      activeSlideCalc = 0;
    }

    const settings = {
      autoplay: false,
      dots: false,
      infinite: false,
      speed: 0,
      initialSlide: activeSlideCalc || 0,

      lazyLoad: isLazyLoad ? "progressive" : null, // bug with autoplay back from last
      swipeToSlide: !isMobile,
      // swipeToSlide: false,
      centerMode: centerModeCalc && activeSlide === activeSlideCalc,
      variableWidth: !isMobile,

      slidesToShow: slidesToShow,
      ///
      nextArrow: isMobile ? <NextArrow /> : null,
      prevArrow: isMobile ? <PrevArrow /> : null,

      afterChange: this.handleSliderChanged
    };

    const diffTime = +Date.now() - this.state.timestamp;

    const maxSlideIndex =
      this.state.slidesToShowCalc && activeSlide
        ? totalSlides - this.state.slidesToShowCalc
        : totalSlides - slidesToShow;
    const isLastSlide = this.state.currentSlide === maxSlideIndex;
    const isFirstSlide = this.state.currentSlide === 0;

    return (
      <>
        <div
          className="slider-scroll__container"
          style={{ width: this.props.containerWidth }}
        >
          <div
            className={`slider-arrow slider-arrow__prev ${
              isFirstSlide ? "slider-arrow--disabled" : ""
            }`}
            onClick={isFirstSlide ? null : this.handlePrevSlide}
          />

          {!hideScroll && (
            <input
              type="range"
              min={0}
              max={maxSlideIndex}
              value={
                diffTime < 800
                  ? this.state.currentSlideScroll
                  : this.state.currentSlide
              }
              className="slider-scroll"
              onChange={this.handleSliderChange}
            />
          )}

          <div
            className={`slider-arrow slider-arrow__next ${
              isLastSlide ? "slider-arrow--disabled" : ""
            }`}
            onClick={isLastSlide ? null : this.handleNextSlide}
          />
        </div>

        {addTile && <div className="slider-add-tile">{addTile}</div>}

        <Slider {...settings} ref={this.slider}>
          {this.props.children}
        </Slider>
      </>
    );
  }
}

export default EventsSlider;
