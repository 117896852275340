import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconProtect = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M17.25 4H6.75C5.78613 4 5 4.80859 5 5.8V15.7C5 16.2414 5.26318 16.7793 5.70068 17.1414L12 22L18.2993 17.1414C18.7368 16.7793 19 16.2414 19 15.7V5.8C19 4.80859 18.2139 4 17.25 4ZM10.25 15.7L6.75 12.1L7.79932 11.0207L10.25 13.5414L16.2007 7.4207L17.25 8.5L10.25 15.7Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconProtect.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconProtect;
