export const INFLATION = 0.025;
export const INFLATION_PERCENT = INFLATION * 100;

export const incomeTaxTable = {
  single: {
    sum: [0, 9700, 39475, 84200, 160275, 204100, 510300],
    rates: [10, 12, 22, 24, 32, 35, 37]
  },
  married: {
    sum: [0, 19400, 78950, 168400, 321450, 408200, 612350],
    rates: [10, 12, 22, 24, 32, 35, 37]
  }
};

const calcBaseAmounts = taxObject => {
  let prevSum = 0;

  const baseAmountsArray = taxObject.sum.map((sum, index) => {
    const nextSum = incomeTaxTable.single.sum[index + 1];
    const taxResult = nextSum
      ? (nextSum - sum) * (incomeTaxTable.single.rates[index] / 100)
      : Infinity;

    prevSum = taxResult + prevSum;

    return prevSum;
  });

  return baseAmountsArray;
};

incomeTaxTable.single.baseAmounts = [
  0,
  ...calcBaseAmounts(incomeTaxTable.single)
];

incomeTaxTable.married.baseAmounts = [
  0,
  ...calcBaseAmounts(incomeTaxTable.married)
];

// todo taxes from overdraft
// const incomeTaxTableOverdraftSingle = incomeTaxTable.single.sum.map(
//   (sum, index) => {
//     const nextSum = incomeTaxTable.single.sum[index + 1];
//
//     const OL = index === 0 ? 0 : sum + 1;
//     const OU = nextSum + 0.999999;
//
//     const OA = sum + 0.999999;
//     // const BT = prevSum;
//     const BT = incomeTaxTable.single.baseAmounts[index];
//     const OR = incomeTaxTable.single.rates[index] / 100;
//
//     // MIN_AMOUNT = OL - (BT + (OL - OA) * OR / 100)
//     const min = OL - (BT + (OL - OA) * OR);
//     // MAX_AMOUNT = OU - (BT + (OU - OA) * OR / 100)
//     const max = OU - (BT + (OU - OA) * OR);
//
//     return min;
//   }
// );


export const capitalGainTable = {
  single: {
    sum: [0, 39375, 434550],
    rates: [0, 15, 20]
  },
  married: {
    sum: [0, 78750, 488850],
    rates: [0, 15, 20]
  }
};

export const stateTable = {
  single: {
    sum: [0, 8533, 20255, 31969, 44377, 56085, 286492, 343788, 572980],
    rates: [1, 2, 4, 6, 8, 9.3, 10.3, 11.3, 12.3]
  },
  married: {
    sum: [0, 17088, 40510, 63938, 88754, 112170, 572984, 687576, 1145960],
    rates: [1, 2, 4, 6, 8, 9.3, 10.3, 11.3, 12.3]
  }
};

export const taxPropertyValue = 0.0119;
export const taxPropertyPurchaseMult = 1.02;
export const taxFICAValue = 0.0765;

export const taxPrimaryHomePrice = {
  married: 500000,
  single: 250000
};

export const highlightedYearsInitial = [
  18,
  22,
  50,
  55,
  59,
  62,
  65,
  67,
  70,
  71,
  80,
  90
];
