import React from "react";

const CommonTopStep = ({ classes, step }) => {
  return (
    <>
      <div className={classes.wizardHeaderStepState} />
      <div
        className={`${classes[`wizardHeaderStepState${step}`]} ${
          classes.wizardHeaderStepStateCommon
        }`}
      />
    </>
  );
};

export default CommonTopStep;
