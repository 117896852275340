import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { setUI } from "AC/ui";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { FormattedMessage } from "react-intl-v3";
import ReactResizeDetector from "react-resize-detector";

import MainToolbar from "components/_common/MainToolbar";
import SideMenu from "components/_common/SideMenu";
import SideGroup from "components/_common/SideGroup";
import SideGroupItem from "components/_common/SideGroupItem";
import ButtonAddAsset from "components/_common/Button/ButtonAddAsset";
import AssetForm from "./AssetForm";

import * as Icons from "components/_common/icons";

import AssetCard from "../_common/AssetCard";
import { compose } from "redux";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";

import FormControlInput from "../_common/FormControl/FormControlInput";
import FormDatePicker from "../_common/FormControl/FormDatePicker";
import FormControlSelect from "../_common/FormControl/FormControlSelect";
import ContentHeader from "../ContainerApp/ContentHeader";
import InputAdornment from "@material-ui/core/InputAdornment";
import ContentMain from "../ContainerApp/ContentMain";

import SideRight from "./SideRight";

import toast from "components/_common/toast";
import ButtonSaveChanges from "components/_common/Button/ButtonSaveChanges";
import DialogWhyNeedIt from "./DialogWhyNeedIt";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AdditionalForm from "../_common/AdditionalForm";
import DialogRemoveAsset from "../AssetsCommon/DialogRemoveAsset";
import DialogFinishWizard from "../AssetsCommon/DialogFinishWizard";
import diffYears from "date-fns/differenceInCalendarYears";

import { styles } from "./styles";
import "./AssetsCommon.scss";

import { expiredCalculate } from "./helperExpiredCalculate";
import { trimString } from "../../lib/helpers";

import EventsSlider from "../PlansEvents/EventsSlider";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";
import { ENTITIES as debtEntities } from "../HouseholdDebt/formScheme";
import { ENTITIES as useEntities } from "../HouseholdAssetsUse/formScheme";

import Summary from "./Summary";
import { valuesMoneyInType } from "../PlansEvents/formScheme";
import {
  getAssetsAllData,
  getTransactionsAllData
} from "../../store/selectors/firebase";

import { MIN_CALC_AGE } from "../../constants";
import IconContainer from "../_common/IconContainer";
import EmptyPanel from "../_common/EmptyPanel";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import GoalsDraggableHoc from "../_common/AssetCard/GoalsDraggableHoc";
// import DialogActions from "@material-ui/core/DialogActions";

const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];

const optionsAsset = {};

export const AddTile = props => {
  const {
    ENTITIES_GROUPS,
    ENTITIES,
    isAccessEdit,
    globalType,
    designScheme,
    isAlwaysShow,
    isDialog,
    translations,
    isEvents,
    handleToggleAddForm,
    isHorizontal
  } = props;

  let bottomItem = null;
  if (ENTITIES && ENTITIES.ENTITY_GENERAL) {
    let title = translations.edit.generalAsset.title || "General";
    const titleI18n = translations.edit.generalAsset.titleI18n;
    let titleToShow = title;

    if (titleI18n) {
      titleToShow = <FormattedMessage id={titleI18n} defaultMessage={title} />;
    }

    const Icon = Icons[designScheme[ENTITIES.ENTITY_GENERAL].icon];
    const color = isAccessEdit
      ? designScheme[ENTITIES.ENTITY_GENERAL].color
      : "textTetrieary";

    bottomItem = (
      <button
        className="asset__add-item asset__add-item--general"
        onClick={
          !isAccessEdit
            ? null
            : handleToggleAddForm(ENTITIES.ENTITY_GENERAL, { globalType })
        }
      >
        <span className="asset__add-item--icon">
          <IconContainer width={32} height={32} color={color}>
            {!Icon ? (
              <Icons.IconOther width={24} height={24} color="ui" />
            ) : (
              <Icon width={24} height={24} color={color} />
            )}
          </IconContainer>
        </span>

        <span className="asset__add-item--title">{titleToShow}</span>
      </button>
    );
  }

  const contentEntities =
    ENTITIES_GROUPS &&
    ENTITIES &&
    Object.keys(ENTITIES_GROUPS).map((entityGroupKey, indexGroup) => {
      const localEntities = ENTITIES_GROUPS[entityGroupKey].ENTITIES;
      const title = ENTITIES_GROUPS[entityGroupKey].title;

      const formFields = Object.keys(localEntities).map(entityKey => {
        const entity = localEntities[entityKey];

        if (entity === ENTITIES.ENTITY_GENERAL) {
          return null;
        }

        let titleToShow = designScheme[entity].title || title;
        const titleI18n = designScheme[entity].titleI18n;
        if (titleI18n) {
          titleToShow = (
            <FormattedMessage id={titleI18n} defaultMessage={title} />
          );
        }

        const Icon = Icons[designScheme[entity].icon];
        const color = isAccessEdit
          ? designScheme[entity].color
          : "textTetrieary";

        return (
          <button
            key={entityKey}
            className="asset__add-item"
            onClick={
              !isAccessEdit ? null : handleToggleAddForm(entity, { globalType })
            }
          >
            <span className="asset__add-item--icon">
              <IconContainer width={32} height={32} color={color}>
                {!Icon ? (
                  <Icons.IconOther width={24} height={24} color="ui" />
                ) : (
                  <Icon width={24} height={24} color={color} />
                )}
              </IconContainer>
            </span>

            <span className="asset__add-item--title">{titleToShow}</span>
          </button>
        );
      });

      return formFields;
    });

  return (
    <section
      className={`asset-card asset-card--add-asset ${
        isEvents ? "asset-card--add-event" : ""
      } ${isAlwaysShow ? "asset-card--add-asset-show" : ""} ${
        isDialog ? "asset-card--add-dialog" : ""
      } ${isHorizontal ? "asset-card--add-horizontal" : ""}`}
    >
      <div className="asset-card__wrapper">
        <div className="asset-card__top" title="">
          <IconContainer color="white" opacity={0.3}>
            <Icons.IconPlusNew width={24} height={24} color="white" />
          </IconContainer>
          <div className="asset-card__title">
            <div className="asset-card__title-dsc">
              <div className="asset-card__title-dsc">
                <FormattedMessage
                  id={translations.edit.sideMenuAdd.titleI18n}
                  defaultMessage={translations.edit.sideMenuAdd.title}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="asset-card__tiles-type">
          <div className="assets-add__container">
            {contentEntities}

            {bottomItem}
          </div>

          <div className="asset-card__more " />
        </div>
      </div>
    </section>
  );
};

AddTile.propTypes = {
  ENTITIES_GROUPS: PropTypes.any,
  ENTITIES: PropTypes.any,
  isAccessEdit: PropTypes.any,
  designScheme: PropTypes.any,
  isMobile: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  isAlwaysShow: PropTypes.bool,
  isDialog: PropTypes.bool,
  translations: PropTypes.any,
  isEvents: PropTypes.bool,
  handleToggleAddForm: PropTypes.func
};

export class AssetCategoryCommon extends PureComponent {
  static propTypes = {
    isDialog: PropTypes.bool,
    isAccessEdit: PropTypes.bool,

    auth: PropTypes.object,
    profile: PropTypes.object,
    childs: PropTypes.any,
    family: PropTypes.any,
    assets: PropTypes.any,
    summaryData: PropTypes.any,

    ENTITIES: PropTypes.object,
    ENTITIES_ADDITIONAL: PropTypes.object,
    ENTITIES_GROUPS: PropTypes.object,
    formScheme: PropTypes.object,
    designScheme: PropTypes.object,
    formsValidationScheme: PropTypes.object,
    formsInitialState: PropTypes.object,
    GLOBAL_TYPE_USE_ASSETS: PropTypes.string,
    GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU: PropTypes.any,
    GLOBAL_TYPE_COLLECTION: PropTypes.any,
    GLOBAL_COLLECTION: PropTypes.any,
    translations: PropTypes.object,
    additionalAssets: PropTypes.object,
    isExpiredCalculated: PropTypes.object,
    isHideSort: PropTypes.bool,
    additionalSaveGlobalType: PropTypes.object,

    customOptionsValues: PropTypes.object,
    ////
    isGoals: PropTypes.bool,
    isPositionsUpdating: PropTypes.bool,
    handleOnDragEnd: PropTypes.func,
    onSortEnd: PropTypes.func,
    ///
    isEvents: PropTypes.bool,
    isDashboard: PropTypes.bool,
    activeYear: PropTypes.any,
    activeYears: PropTypes.any,
    isCombineDashboard: PropTypes.bool
  };

  state = {
    openRightDrawer: false,
    formOpened: null,
    formEditOpened: null,
    isDebtOpened: null,
    debtOpened: [],
    mobileAddAsset: null,
    selectedAsset: null,
    assetUid: "",
    duplicate: "",

    cardsWidth: null,
    //// from parent
    ...this.props.formsInitialState,
    validationScheme: Object.assign({}, this.props.formsValidationScheme),

    dialogWhyNeedItOpen: false,
    isDialogFinishWizardOpen: false,
    isWizardFinishing: false,
    endingSoonIds: [],
    // custom
    optionsOwner: optionsOwner,
    optionsOwnerAdults: optionsOwner,
    optionsAsset: optionsAsset,
    // events custom
    sliderHeight: 0,
    currentEventSlide: 0,
    ////
    assets: [],
    items: [],
    scrollWidth: 0
  };

  cardsRef = React.createRef();
  containerRef = React.createRef();
  mainContentRef = React.createRef();

  componentDidMount() {
    const {
      profile,
      family,
      isDialog,
      isEvents,
      isCombineDashboard
    } = this.props;

    if (profile && profile.isLoaded && family) {
      this.handleUpdateOwnersOptions();
    }

    if (this.props.additionalAssets) {
      this.handleUpdateAdditionalAssetsOptions();
    }

    this.setState({
      assets: this.props.assets
    });

    if (!isDialog && (!isEvents && !isCombineDashboard)) {
      this.props.setUI({
        mobileMenuHandler: () => {
          this.setState({
            openRightDrawer: true,
            formOpened: null,
            formEditOpened: null
          });
        }
      });
    }

    this.setState({ scrollWidth: this.calcScrollBarWidth() });

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { profile, additionalAssets, family, childs } = this.props;

    if (
      profile &&
      profile.displayName &&
      prevProps.profile.displayName !== profile.displayName
    ) {
      this.handleUpdateOwnersOptions();
    }

    if (
      (!prevProps.family && family) ||
      (!prevProps.childs && childs) ||
      (family &&
        family.displayName &&
        prevProps.family.displayName !== family.displayName)
    ) {
      this.handleUpdateOwnersOptions();
    }

    if (additionalAssets !== prevProps.additionalAssets) {
      this.handleUpdateAdditionalAssetsOptions();
    }

    if (!this.state.cardsWidth) {
      if (this.cardsRef && this.cardsRef.current) {
        this.setState({
          cardsWidth: this.cardsRef.current.offsetWidth
        });
      }
    }

    //goals
    // eslint-disable-next-line
    if (this.props.assets != prevProps.assets) {
      this.setState({
        assets: this.props.assets
      });
    }
  }

  componentWillUnmount() {
    this.props.setUI({ mobileMenuHandler: false });
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  calcScrollBarWidth = () => {
    let scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";

    const container = document.querySelector(
      ".main-container.main-container--dialog"
    );

    if (container) {
      container.appendChild(scrollDiv);
      // Get the scrollbar width
      let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      // Delete the DIV
      container.removeChild(scrollDiv);
      return scrollbarWidth;
    }

    return 0;
  };

  handleEventSliderHeight = sliderHeight => {
    if (this.state.sliderHeight !== sliderHeight) {
      this.setState({ sliderHeight });
    }
  };
  handleEventSliderCurrentSlide = currentEventSlide => {
    if (this.state.currentEventSlide !== currentEventSlide) {
      this.setState({ currentEventSlide });
    }
  };

  handleClickOutside = ev => {
    const { target } = ev;

    const isCard = !!target.closest(".asset-card");
    const isCardSideEdit = !!target.closest(".main__side-menu.side-menu");

    if (
      !isCard &&
      !isCardSideEdit &&
      this.containerRef &&
      this.containerRef.current.contains(target)
    ) {
      this.setState({
        formEditOpened: null
      });
    }
  };

  handleOpenDialogWhyNeedIt = () => {
    this.setState({ dialogWhyNeedItOpen: true });
  };

  handleCloseDialogs = () => {
    this.setState({ dialogWhyNeedItOpen: false });
  };

  updateCardsDimensions = width => {
    this.setState({
      cardsWidth: width
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  handleFinishWizard = () => {
    const {
      firestore,
      assetsAll,
      transactionsAll,
      profileProvider
    } = this.props;

    const batch = firestore.batch();
    this.setState({ isWizardFinishing: true });

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("userAssets").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    if (transactionsAll) {
      Object.keys(transactionsAll).forEach(assetKey => {
        const asset = transactionsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("transactions").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    // family
    if (profileProvider) {
      const docRefUser = firestore.collection("users").doc(profileProvider);
      batch.update(docRefUser, {
        isWizardFinished: true
      });
    }

    batch.commit().then(() => {
      if (this.state.funcIfAccept) {
        this.state.funcIfAccept();
      }
      this.setState({ isWizardFinishing: false });
      this.handleDialogFinishWizardClose();
    });
  };

  handleUpdateOwnersOptions = () => {
    const { childs, family } = this.props;

    const currentProfile = family;
    const template = [];
    let templateAll = [];

    if (currentProfile) {
      const birthdayGeneral = currentProfile.birthday;

      const ageGeneral =
        birthdayGeneral && birthdayGeneral.seconds
          ? diffYears(Date.now(), birthdayGeneral.seconds * 1000)
          : 0;

      template[0] = {
        name: currentProfile.displayName,
        value: "You",
        age: ageGeneral
      };
    }

    if (
      currentProfile &&
      currentProfile.spouse &&
      currentProfile.spouse.spouseName &&
      (currentProfile.relationshipStatus === "married" ||
        currentProfile.relationshipStatus === "established")
    ) {
      let age = 0;
      if (
        currentProfile.spouse.birthday &&
        currentProfile.spouse.birthday.seconds
      ) {
        age = diffYears(
          Date.now(),
          currentProfile.spouse.birthday.seconds * 1000
        );
      }
      template[1] = {
        name: currentProfile.spouse.spouseName,
        value: "Spouse",
        age
      };
    }

    templateAll = [...template];

    if (childs) {
      childs.forEach(child => {
        const fullYears =
          child.birthday && child.birthday.seconds
            ? diffYears(Date.now(), child.birthday.seconds * 1000)
            : 0;

        const childOption = {
          name: child.childName,
          value: child.id,
          age: fullYears
        };

        templateAll.push(childOption);

        if (fullYears > 16) {
          template.push(childOption);
        }
      });
    }

    this.setState({ optionsOwnerAdults: template, optionsOwner: templateAll });
  };

  handleUpdateAdditionalAssetsOptions = () => {
    const { additionalAssets } = this.props;

    const template = {};

    Object.keys(additionalAssets).forEach(entityKey => {
      const entities = additionalAssets[entityKey];

      if (entities) {
        Object.keys(entities).forEach(assetKey => {
          const asset = entities[assetKey];

          const entityId = Number.isInteger(+assetKey) ? asset.id : assetKey;

          if (!asset) {
            return;
          }

          if (!template[entityKey]) {
            template[entityKey] = [];
          }

          template[entityKey].push({
            name: `${
              asset.type ? `${asset.type.toUpperCase()} ` : ""
            }${trimString(asset.description, 45)}`,
            value: entityId
          });
        });
      }
    });

    this.setState({ optionsAsset: template });
  };

  /// Dialog finish wizard
  handleDialogFinishWizardAgree = () => {
    this.handleFinishWizard();
  };

  handleDialogFinishWizardOpen = funcIfAccept => {
    this.setState({
      isDialogFinishWizardOpen: true,
      funcIfAccept: funcIfAccept
    });
  };

  handleDialogFinishWizardClose = () => {
    this.setState({
      isDialogFinishWizardOpen: false,
      funcIfAccept: null
    });
  };

  /// Dialog Remove asset
  handleDialogRemoveAgree = () => {
    this.handleDeleteAsset(this.state.assetUid)(); // new

    this.handleDialogRemoveClose();
  };

  handleDialogRemoveOpen = assetUid => () => {
    const { family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleDialogRemoveOpen(assetUid)
      );
    }

    this.setState({
      assetUid
    });
  };

  handleDialogRemoveClose = () => {
    this.setState({
      assetUid: ""
    });
  };
  ////////////////////////////////////

  handleToggleAddForm = form => ev => {
    ev.stopPropagation();

    if (this.state.formOpened) {
      this.handleCleanForm(form);
    }

    this.setState(prevState => ({
      formOpened: prevState.formOpened === form ? null : form,
      isDebtOpened: prevState.formOpened === form
    }));
  };

  handleCloseRightDrawer = () => {
    this.setState({
      openRightDrawer: false,
      formEditOpened: null
    });
  };

  handleCleanForm = entity => {
    if (!entity) {
      return;
    }
    const { ENTITIES_ADDITIONAL } = this.props;

    const cleanAdditional = this.helperGetAdditionalFieldsInitialState(entity);

    const additionalFields = {};

    if (ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(additKey => {
        if (!additionalFields[`${entity}_${additKey}`]) {
          additionalFields[`${entity}_${additKey}`] = {
            ...this.props.formsValidationScheme[`${entity}_${additKey}`]
          };
        }
      });
    }
    const newStateValidation = this.state.validationScheme[entity];
    const initialValidation = { ...this.props.formsValidationScheme[entity] };

    Object.keys(newStateValidation).forEach(field => {
      if (newStateValidation[field] && initialValidation[field]) {
        newStateValidation[field].isError = false;
        newStateValidation[field].isTouched = false;
        newStateValidation[field].isRequired =
          initialValidation[field].isRequired;
        newStateValidation[field].min = initialValidation[field].min;
        newStateValidation[field].max = initialValidation[field].max;
      }
    });

    this.setState(prevState => ({
      ...cleanAdditional,
      [entity]: this.props.formsInitialState[entity],
      duplicate: "",

      validationScheme: {
        ...prevState.validationScheme,
        [entity]: { ...newStateValidation },
        ...additionalFields
      }
    }));
  };

  handleCleanFormAndCloseEdit = entity => isChanged => {
    this.handleCleanForm(entity);

    this.handleCloseForms(isChanged);
  };

  handleCloseForms = isChanged => {
    this.setState({
      formOpened: null,
      formEditOpened: null,
      isDebtOpened: false,
      openRightDrawer: false,
      isExpiredCalculated: !isChanged
    });
  };

  handleToggleDebt = (formKey, state) => {
    let newArray = [...this.state.debtOpened];
    if (state) {
      newArray.push(formKey);
    } else {
      const indexToRemove = this.state.debtOpened.findIndex(
        el => el === formKey
      );
      newArray.splice(indexToRemove, 1);
    }

    this.setState(prevState => ({
      isDebtOpened: !prevState.isDebtOpened,
      debtOpened: newArray
    }));
  };

  helperGetAdditionalFieldsState = entity => {
    const { ENTITIES_ADDITIONAL } = this.props;
    const additionalFields = {};

    if (ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(addFormKey => {
        additionalFields[addFormKey] = this.state[`${entity}_${addFormKey}`];
      });
    }

    return additionalFields;
  };

  helperOptionsHomeIsPrimary = ({ isEdit }) => {
    const { assets } = this.props;

    // check is edit home with type "primary"  or is home with Primary type already added
    const isPrimaryExists =
      !(isEdit && this.state.home.type === "primary") &&
      assets &&
      Object.keys(assets).length &&
      Object.keys(assets).find(
        key =>
          assets[key] &&
          assets[key].entity === "home" &&
          assets[key].type === "primary"
      );

    return isPrimaryExists;
  };

  helperOptionsEvent = type => {
    const options = [];

    if (this.state[type].type) {
      const currentType = this.state[type] && this.state[type].type;

      if (
        !currentType ||
        (currentType !== valuesMoneyInType.ANNUITY &&
          currentType !== valuesMoneyInType.RENT &&
          currentType !== valuesMoneyInType.SALE)
      ) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.assetsAll
        ) {
          Object.keys(this.props.additionalAssets.assetsAll).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.assetsAll[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.ANNUITY) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.annuities
        ) {
          Object.keys(this.props.additionalAssets.annuities).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.annuities[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.RENT) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.homesRent
        ) {
          Object.keys(this.props.additionalAssets.homesRent).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.homesRent[entityKey];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }

      if (currentType === valuesMoneyInType.SALE) {
        if (
          this.props.additionalAssets &&
          this.props.additionalAssets.useAssetsAll
        ) {
          Object.keys(this.props.additionalAssets.useAssetsAll).forEach(
            entityKey => {
              const entity = this.props.additionalAssets.useAssetsAll[
                entityKey
              ];

              options.push({
                value: entity.id,
                name: trimString(entity.description, 45)
              });
            }
          );
        }
      }
    }

    return options;
  };

  helperOptionsHomeDebt = field => {
    const options = this.state.optionsAsset[field.optionsCustomAsset];
    const { additionalAssets } = this.props;

    if (this.state.debt.asset && this.state.debt.asset !== "general") {
      const assetId = this.state.debt.asset;
      const asset = additionalAssets.useAssets[assetId];

      if (asset && asset.entity === useEntities.ENTITY_HOME) {
        const customOptions = this.state.optionsAsset[
          field.optionsCustomAsset
        ].filter(el => el.value === asset.debtId);
        const customValue = asset.debtId;

        return {
          options: customOptions,
          value: customValue
        };
      }
    }

    if (this.state.debt.asset === "general") {
      const customOptions = this.state.optionsAsset[
        field.optionsCustomAsset
      ].filter(el => {
        const debt = additionalAssets.debts.find(debt => debt.id === el.value);

        return (
          !debt || (!debt.assetId || debt.entity !== debtEntities.ENTITY_HOME)
        );
      });

      return {
        options: customOptions
      };
    }

    return { options };
  };

  helperGetAdditionalFieldsInitialState = entity => {
    const { ENTITIES_ADDITIONAL } = this.props;
    const additionalFields = {};

    if (ENTITIES_ADDITIONAL[entity]) {
      Object.keys(ENTITIES_ADDITIONAL[entity]).forEach(addFormKey => {
        additionalFields[
          `${entity}_${addFormKey}`
        ] = this.props.formsInitialState[`${entity}_${addFormKey}`];
      });
    }

    return additionalFields;
  };

  handleValidateData = (entity, { isEdit, isAdditionFields } = {}) => {
    const { formScheme } = this.props;
    let isValid = true;
    let newState = { ...this.state.validationScheme };

    const formValidation = { ...this.state.validationScheme[entity] };

    Object.keys(formValidation).forEach(keyField => {
      const form = this.state[entity];

      const InitialValidFieldScheme = this.props.formsValidationScheme[entity][
        keyField
      ];
      const scheme = formValidation[keyField];

      if (scheme.isRequired) {
        let isConditionAccept = true;
        const formEntityScheme = formScheme[entity][keyField];

        if (formEntityScheme.condition) {
          const formInner = formEntityScheme.condition.inner
            ? this.state[`${entity}_${formEntityScheme.condition.inner}`]
            : null;

          isConditionAccept = this.checkConditions(
            formEntityScheme.condition,
            form,
            { entity, formInner: formInner }
          );
        }

        if (!form[keyField] && isConditionAccept) {
          if (formScheme[entity][keyField].isAmount && form[keyField] === 0) {
            newState[entity][keyField].isError = null;
          } else {
            isValid = false;

            newState[entity][keyField].isError = "error";
          }
        } else {
          newState[entity][keyField].isError = null;
        }

        newState[entity][keyField].isTouched = this.state.validationScheme[
          entity
        ][keyField].isTouched;

        newState[entity][keyField].isTouched = false;
        newState[entity][keyField].isRequired = true;
      }

      if (form[keyField] !== "" && typeof scheme.min !== "undefined") {
        let min = scheme.min;

        if (InitialValidFieldScheme.minFromCustom) {
          min = this.getCustomMinValidation(InitialValidFieldScheme, entity, {
            defaultMin: min
          });
        }

        if (form[keyField] < min) {
          isValid = false;
          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "min";
          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="APP.FORM.VALIDATION.MIN"
              defaultMessage={`Enter number greater or equal {value}`}
              values={{ value: min }}
            />
          );
        }
      }

      if (form[keyField] !== "" && typeof scheme.max !== "undefined") {
        if (form[keyField] > scheme.max) {
          isValid = false;
          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "max";
          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="APP.FORM.VALIDATION.MAX"
              defaultMessage={`Enter number less or equal {value}`}
              values={{ value: scheme.max }}
            />
          );
        }
      }
    });

    this.setState({ validationScheme: newState });
    return isValid;
  };

  checkConditions = (conditions, form, { keyField, entity, formInner }) => {
    let isConditionAccept = true;

    conditions.forEach(condition => {
      if (condition) {
        let assetField;

        if (condition.fieldDebt || condition.inner) {
          assetField = this.state[entity][
            condition.fieldDebt || condition.field
          ];
        } else {
          assetField = form[condition.field];
        }

        if (condition.isFilled) {
          isConditionAccept = !!assetField;
        }

        if (condition.isNotFilled) {
          isConditionAccept = !assetField;
        }

        if (condition.value) {
          // eslint-disable-next-line
          isConditionAccept = assetField == condition.value;
        }

        if (condition.valueIn) {
          // eslint-disable-next-line
          isConditionAccept = condition.valueIn.indexOf(assetField) !== -1;
        }
      }
    });

    return isConditionAccept;
  };

  handleSubmit = ({ entity, assetDebt, isEdit }) => ev => {
    const { translations, ENTITIES_ADDITIONAL, family } = this.props;

    if (ev) {
      ev.preventDefault();
    }

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleSubmit({ entity, assetDebt, isEdit })
      );
    }

    const additional = ENTITIES_ADDITIONAL[entity];

    const isValid = this.handleValidateData(entity, { isEdit });

    let isValidAdd = true;

    if (additional && Object.keys(additional)) {
      Object.keys(additional).forEach(additKey => {
        const isOpened = this.state.debtOpened.find(
          el => el === `${entity}_${additKey}`
        );

        isValidAdd =
          isValidAdd &&
          (!isOpened ||
            this.handleValidateData(`${entity}_${additKey}`, {
              isEdit,
              isAdditionFields: true
            }));
      });
    }

    if (!(isValid && isValidAdd)) {
      toast(
        <FormattedMessage
          id={translations.form.fillAllRequired.titleI18n}
          defaultMessage={translations.form.fillAllRequired.title}
        />,
        { type: "error" }
      );

      return;
    }

    const additionalFields = this.helperGetAdditionalFieldsState(entity);

    if (!isEdit) {
      return this.handleSubmitAddAsset(
        {
          entity: entity,
          ...this.state[entity],
          ...additionalFields
        },
        assetDebt
      );
    }

    return this.handleSubmitEditAsset({ entity, additionalFields });
  };

  handleInputChangeDate = (asset, field) => date => {
    this.setState(prevState => ({
      [asset]: { ...prevState[asset], [field]: date },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [field]: {
            isTouched: true,
            isError: false
          }
        }
      }
    }));
  };

  handleInputChange = asset => ev => {
    const { name, value } = ev.target;

    const isRequired =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isRequired;

    const min =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      typeof this.state.validationScheme[asset][name].min !== "undefined" &&
      this.state.validationScheme[asset][name].min;

    const max =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      typeof this.state.validationScheme[asset][name].max !== "undefined" &&
      this.state.validationScheme[asset][name].max;

    const additional = {};
    if (typeof min === "number") {
      additional.min = min;
    }
    if (typeof max === "number") {
      additional.max = max;
    }

    this.setState(prevState => ({
      [asset]: { ...prevState[asset], [name]: value },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [name]: {
            isTouched: true,
            isError: false,
            isRequired: isRequired,
            ...additional
          }
        }
      }
    }));
  };

  handleSubmitEditAsset = async ({ entity, additionalFields }) => {
    const {
      firestore,
      translations,
      GLOBAL_COLLECTION,
      GLOBAL_TYPE_COLLECTION,
      additionalSaveGlobalType,
      assets,
      updateFieldGlobalType
    } = this.props;

    const entityId = this.state.formEditOpened.uidAsset;
    const asset = assets.find(el => el.id === entityId);

    const collection = GLOBAL_COLLECTION || "userAssets";

    if (GLOBAL_TYPE_COLLECTION) {
      additionalFields.entityType = GLOBAL_TYPE_COLLECTION;
    }

    // custom for debt <-> use assets
    let additionsSaves = this.helperSaveAdditions({
      additionalFields,
      entity,
      entityId,
      additionId: asset.debtId || null
    });

    if (additionalSaveGlobalType) {
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        delete additionalFields[additKey];
      });
    }

    let debtData =
      !asset.debtId && additionsSaves ? await additionsSaves : null;
    debtData = debtData ? { debtId: debtData.id } : {};

    const assetObject = this.state[entity];
    delete assetObject.isWhatIf;
    delete assetObject.isWizard;
    delete assetObject.isDisabled;

    return firestore
      .update(`${collection}/${entityId}`, {
        ...assetObject,
        ...additionalFields,
        ...debtData,
        updated: new Date(),
        globalType: this.props.GLOBAL_TYPE_USE_ASSETS
      })
      .then(res => {
        if (updateFieldGlobalType && updateFieldGlobalType.debtToUse) {
          const debtToUseId = this.state[entity][
            updateFieldGlobalType.debtToUse.fieldId
          ];

          if (debtToUseId) {
            this.helperUpdateExternalFields(entityId, debtToUseId);
          }
        }

        toast(
          <FormattedMessage
            id={translations.toast.assetSaved.titleI18n}
            defaultMessage={translations.toast.assetSaved.title}
          />
        );

        const additionalFieldsInitial = this.helperGetAdditionalFieldsInitialState(
          entity
        );

        this.setState({
          openRightDrawer: false,
          formOpened: null,
          formEditOpened: null,
          isExpiredCalculated: false,
          [entity]: this.props.formsInitialState[entity],
          ...additionalFieldsInitial
          // [assetDebt]: this.props.formsInitialState[assetDebt]
        });
      });
  };

  helperUpdateExternalFields = (id, externalId) => {
    const { updateFieldGlobalType, firestore } = this.props;

    if (updateFieldGlobalType) {
      if (updateFieldGlobalType.debtToUse) {
        return firestore.update(
          `${updateFieldGlobalType.debtToUse.collection}/${externalId}`,
          {
            updated: new Date(),
            [updateFieldGlobalType.debtToUse.externalField]: id
          }
        );
      }

      if (updateFieldGlobalType.useToDebt) {
        return firestore.update(
          `${updateFieldGlobalType.useToDebt.collection}/${externalId}`,
          {
            updated: new Date(),
            [updateFieldGlobalType.useToDebt.fieldId]: id
            // debt ID update
          }
        );
      }
    }
  };

  helperSaveAdditions = ({
    additionalFields,
    entity,
    entityId,
    additionId
  }) => {
    const {
      additionalSaveGlobalType,
      firestore,
      auth,
      profileProvider
    } = this.props;
    const { uid } = auth;
    const userUid = profileProvider || uid;
    const userRef = firestore.doc(`/users/${userUid}`);

    let additionsSaves = null;
    let promise = null;

    // custom for debt <-> use assets
    if (additionalSaveGlobalType) {
      additionsSaves = {};
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        // if Additional form closed -> return
        const isOpened = !!this.state.debtOpened.find(
          debt => debt === `${entity}_${additKey}`
        );

        if (!isOpened) {
          return;
        }
        additionsSaves[additKey] = { ...additionalFields[additKey] };

        additionsSaves[additKey].entity = entity; // USE ASSETS HOME VEHICLE OTHER
        additionsSaves[additKey][
          additionalSaveGlobalType[additKey].externalField
        ] = entityId;
        additionsSaves[additKey].globalType =
          additionalSaveGlobalType[additKey].globalType; // USE ASSETS HOME VEHICLE OTHER

        delete additionalFields[additKey];

        if (additionId) {
          promise = firestore.update(
            `/${additionalSaveGlobalType[additKey].collection}/${additionId}`,
            {
              updated: new Date(),
              ...additionsSaves[additKey]
            }
          );
        } else {
          // const additionsSave = additionsSaves[additKey];

          promise = firestore.add(
            `/${additionalSaveGlobalType[additKey].collection}`,
            {
              created: new Date(),
              user: userUid,
              userRef,
              ...additionsSaves[additKey]
            }
          );
        }
      });
    }

    return promise;
  };

  handleSubmitAddAsset = async (assetObject, assetDebt) => {
    const {
      firestore,
      auth,
      translations,
      GLOBAL_COLLECTION,
      GLOBAL_TYPE_COLLECTION,
      additionalSaveGlobalType,
      updateFieldGlobalType,
      profileProvider,
      assets
    } = this.props;

    const collection = GLOBAL_COLLECTION || "userAssets";

    const additionalFields = {};
    if (GLOBAL_TYPE_COLLECTION) {
      additionalFields.entityType = GLOBAL_TYPE_COLLECTION;
    }

    const { uid } = auth;
    const userUid = profileProvider || uid;

    const userRef = firestore.doc(`/users/${userUid}`);

    let additionalFieldsSaved = {};

    if (additionalSaveGlobalType) {
      Object.keys(additionalSaveGlobalType).forEach(additKey => {
        additionalFieldsSaved[additKey] = { ...assetObject[additKey] };

        delete assetObject[additKey];
      });
    }

    delete assetObject.id;

    // goals
    if (this.props.isGoals) {
      assetObject.position = assets && assets.length ? assets.length : 0;
    }

    firestore
      .add(`/${collection}`, {
        ...assetObject,
        created: new Date(),
        user: userUid,
        userRef,
        globalType: this.props.GLOBAL_TYPE_USE_ASSETS
      })
      .then(async docAsset => {
        // TODO ADDED EMPTY CONNECTED DEBT
        let additionsSaves = this.helperSaveAdditions({
          additionalFields: additionalFieldsSaved,
          entity: assetObject.entity,
          entityId: docAsset.id,
          additionId: null
        });

        if (updateFieldGlobalType && updateFieldGlobalType.debtToUse) {
          const debtToUseId =
            assetObject[updateFieldGlobalType.debtToUse.fieldId];

          if (debtToUseId) {
            this.helperUpdateExternalFields(docAsset.id, debtToUseId, {
              userRef,
              userUid
            });
          }
        }

        let debtData = {};
        try {
          debtData = additionsSaves ? await additionsSaves : null;
          // custom for use asset debtID
          debtData = debtData ? { debtId: debtData.id } : {};
        } catch (e) {}

        try {
          await firestore.update(`/${collection}/${docAsset.id}`, {
            ...debtData
          });
        } catch (e) {}

        this.setState({ isExpiredCalculated: false });
        this.handleCleanForm(assetObject.entity);

        toast(
          <FormattedMessage
            id={translations.toast.assetAdded.titleI18n}
            defaultMessage={translations.toast.assetAdded.title}
          />
        );
      })
      .catch(err => {
        console.error("-catch(err--", err);
        toast(
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage="Error has occurred"
          />,
          { type: "error" }
        );
      });
  };

  handleDeleteAsset = uidAsset => () => {
    const {
      firestore,
      translations,
      GLOBAL_COLLECTION,
      updateFieldGlobalType,
      assets
    } = this.props;

    const collection = GLOBAL_COLLECTION || "userAssets";

    if (updateFieldGlobalType) {
      if (updateFieldGlobalType.debtToUse) {
        const asset = assets.find(el => el.debtId === uidAsset);

        if (asset) {
          this.helperUpdateExternalFields(null, asset.id);
        }
      }
    }

    firestore
      .delete(`${collection}/${uidAsset}`)
      .then(res => {
        // remove connected asset
        if (updateFieldGlobalType && updateFieldGlobalType.useToDebt) {
          const asset = assets.find(el => el.id === uidAsset);
          const externalId =
            asset[updateFieldGlobalType.useToDebt.externalField];

          if (externalId) {
            firestore.delete(
              `${updateFieldGlobalType.useToDebt.collection}/${externalId}`
            );
          }
        }

        toast(
          <FormattedMessage
            id={translations.toast.assetRemoved.titleI18n}
            defaultMessage={translations.toast.assetRemoved.title}
          />
        );
      })
      .catch(err => {
        toast(
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage="Error has occurred"
          />,
          { type: "error" }
        );
        console.log("---", err);
      });
  };

  handleExpiredCalculate = () => {
    const {
      assets,
      formScheme,
      firestore,
      ENTITIES_ADDITIONAL,
      GLOBAL_COLLECTION
    } = this.props;

    const batch = firestore.batch();
    const endingSoonIds = [];

    const checkExpiredCalcField = (
      asset,
      { expiredCalc, endingSoonCalc, field, keyField }
    ) => {
      const expired = expiredCalculate(asset, {
        expiredCalc,
        endingSoonCalc,
        field,
        keyField
      });

      const collection = GLOBAL_COLLECTION || "userAssets";
      const docRef = firestore.collection(collection).doc(asset.id);

      if (expired && expired.isExpired && !asset.isExpired) {
        batch.update(docRef, {
          isExpired: true,
          updated: new Date()
        });
      }

      if ((!expired || !expired.isExpired) && asset.isExpired) {
        batch.update(docRef, { isExpired: false, updated: new Date() });
      }

      if (expired && expired.isEndingSoon) {
        endingSoonIds.push(asset.id);
      }
    };

    assets.forEach(asset => {
      const scheme = formScheme[asset.entity];
      const additional = ENTITIES_ADDITIONAL[asset.entity];

      if (scheme) {
        Object.keys(scheme).forEach(keyField => {
          if (scheme[keyField].expiredCalculate) {
            const expiredCalc = scheme[keyField].expiredCalculate;
            const endingSoonCalc = scheme[keyField].endingSoonCalculate;

            if (asset[keyField]) {
              const field = asset[keyField];
              checkExpiredCalcField(asset, {
                field,
                expiredCalc,
                endingSoonCalc,
                keyField
              });
            }
          }
        });
      }

      if (additional) {
        Object.keys(additional).forEach(keyGroup => {
          const addScheme = additional[keyGroup];

          if (addScheme) {
            Object.keys(addScheme).forEach(keyField => {
              if (addScheme[keyField].expiredCalculate) {
                const expiredCalc = addScheme[keyField].expiredCalculate;

                if (asset[keyGroup] && asset[keyGroup][keyField]) {
                  const field = asset[keyGroup][keyField];
                  checkExpiredCalcField(asset, {
                    field,
                    expiredCalc,
                    keyField
                  });
                }
              }
            });
          }
        });
      }
    });

    batch.commit();

    this.setState({ isExpiredCalculated: true, endingSoonIds });
  };

  handleDuplicateAsset = uidAsset => () => {
    const { assets } = this.props;

    const assetIndex = assets.findIndex(el => el.id === uidAsset);

    if (assetIndex === -1) {
      return;
    }

    const asset = assets[assetIndex];
    const { created, id, debt, ...newAsset } = asset;

    const additionalFields = this.helperGetAdditionalFieldsState(asset.entity);

    const newState = {
      [newAsset.entity]: { ...newAsset },
      formOpened: asset.entity,
      formEditOpened: null,
      duplicate: uidAsset,
      openRightDrawer: true,
      ...additionalFields
    };

    this.setState(newState);
  };

  // deprecated
  handleSelectAsset = uidAsset => ev => {
    this.setState({ selectedAsset: uidAsset });
  };

  _renderButtonSaveOrAdd = ({ entity, isEdit }) => {
    const { translations, isAccessEdit } = this.props;

    if (!isAccessEdit) {
      return null;
    }

    return (
      <ButtonAddAsset
        block
        type="submit"
        onClick={this.handleSubmit({
          entity,
          isEdit
        })}
      >
        {isEdit ? (
          <FormattedMessage
            id={translations.edit.buttonSave.titleI18n}
            defaultMessage={translations.edit.buttonSave.title}
          />
        ) : (
          <FormattedMessage
            id={translations.edit.buttonAdd.titleI18n}
            defaultMessage={translations.edit.buttonAdd.title}
          />
        )}
      </ButtonAddAsset>
    );
  };

  handleOpenRightBarAddForm = () => {
    this.setState({
      openRightDrawer: true
    });
  };

  handleCloseRightBarAddForm = () => {
    this.setState({
      openRightDrawer: false
    });
  };

  handleToggleWhatIfAsset = uidAsset => () => {
    const { firestore, GLOBAL_COLLECTION, assets, family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleToggleWhatIfAsset(uidAsset)
      );
    }

    const asset = assets.find(asset => asset.id === uidAsset);

    firestore.update(`${GLOBAL_COLLECTION || "userAssets"}/${uidAsset}`, {
      isWhatIf: !asset.isWhatIf
    });
  };

  handleToggleDisableAsset = uidAsset => () => {
    const { firestore, GLOBAL_COLLECTION, assets, family } = this.props;

    if (family && !family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(
        this.handleToggleWhatIfAsset(uidAsset)
      );
    }

    const asset = assets.find(asset => asset.id === uidAsset);

    firestore.update(`${GLOBAL_COLLECTION || "userAssets"}/${uidAsset}`, {
      isDisabled: !asset.isDisabled
    });
  };

  handleEditAsset = uidAsset => () => {
    const { assets, isMobile, ENTITIES_ADDITIONAL } = this.props;

    const asset = assets.find(asset => asset.id === uidAsset);

    if (asset && asset.entity) {
      this.handleCleanForm(asset.entity);

      const { debt, ...onlyAsset } = asset;

      const additionalFields = {};
      if (ENTITIES_ADDITIONAL[asset.entity]) {
        Object.keys(ENTITIES_ADDITIONAL[asset.entity]).forEach(addFormKey => {
          additionalFields[`${asset.entity}_${addFormKey}`] =
            asset[addFormKey] || {};
        });
      }

      this.setState({
        formEditOpened: {
          uidAsset,
          entity: asset.entity
        },
        [asset.entity]: onlyAsset,
        ...additionalFields,
        openRightDrawer: isMobile
      });
    }
  };

  _renderFormWoDebt = (
    entityType,
    isEdit,
    {
      title = "Other",
      titleI18n = "",
      caption = "",
      captionI18n = "",
      assetDebt = null,
      bgSecondary = false
    } = {}
  ) => {
    const {
      isMobile,
      designScheme,
      classes,
      ENTITIES_ADDITIONAL,
      isAccessEdit
    } = this.props;

    const additionalForms = ENTITIES_ADDITIONAL[entityType]
      ? Object.keys(ENTITIES_ADDITIONAL[entityType]).map(addFormKey => {
          return this._renderDebtForAsset(`${entityType}_${addFormKey}`, {
            isEdit,
            typeCheckEntity: entityType,
            addFormKey
          });
        })
      : null;

    const Scheme = this.props.formScheme[entityType];

    const isSchemeExists =
      Scheme && Object.keys(Scheme) && Object.keys(Scheme).length;

    const formFields = isSchemeExists
      ? Object.keys(Scheme).map((fieldKey, i) => {
          const field = Scheme[fieldKey];

          return this._renderFormFields(field, fieldKey, entityType, {
            isEdit,
            isFirst: i === 0
          });
        })
      : null;

    return (
      <SideGroupItem
        key={entityType}
        Icon={Icons[designScheme[entityType].icon]}
        color={
          isAccessEdit || isEdit
            ? designScheme[entityType].color
            : "textTetrieary"
        }
        caption={caption}
        captionI18n={captionI18n}
        title={designScheme[entityType].title || title}
        titleI18n={designScheme[entityType].titleI18n || titleI18n}
        handleClickToggle={
          !isAccessEdit
            ? null
            : isEdit
            ? null
            : this.handleToggleAddForm(entityType)
        }
        isOpen={isMobile && (isEdit || this.state.formOpened === entityType)}
        isMobile={isMobile}
        isEdit={isEdit}
        bgSecondary={bgSecondary}
      >
        <form
          className={classNames(
            isMobile ? classes.sideFormOld : classes.sideForm
          )}
          noValidate
          onSubmit={this.handleSubmit({
            entity: entityType,
            isEdit
          })}
        >
          <section>{formFields}</section>

          {additionalForms}

          {this._renderButtonSaveOrAdd({
            entity: entityType,
            isEdit
          })}
        </form>
      </SideGroupItem>
    );
  };

  _renderDebtForAsset = (
    entityType,
    { typeCheckEntity, isEdit, addFormKey }
  ) => {
    const { GLOBAL_TYPE_USE_ASSETS } = this.props;

    const Scheme = this.props.formScheme[entityType];

    const isSchemeExists =
      Scheme && Object.keys(Scheme) && Object.keys(Scheme).length;

    const formFields = isSchemeExists
      ? Object.keys(Scheme).map(fieldKey => {
          const field = Scheme[fieldKey];

          return this._renderFormFields(field, fieldKey, entityType, {
            typeCheckEntity,
            isEdit
          });
        })
      : null;

    const isFieldsNotEmpty = formFields ? formFields.find(el => !!el) : null;

    if (!formFields || !isFieldsNotEmpty) {
      return null;
    }

    const formColor = addFormKey === "configuration" ? "yellow" : "brand";

    const showButtonText =
      addFormKey === "configuration" ? (
        <FormattedMessage
          id={`ADDITIONAL_FORMS_LABELS.CONFIGURATION`}
          defaultMessage={"Show Configuration"}
        />
      ) : (
        <FormattedMessage
          id={`ADDITIONAL_FORMS_LABELS.${GLOBAL_TYPE_USE_ASSETS}.${entityType}`}
          defaultMessage={"Show More"}
        />
      );

    return (
      <AdditionalForm
        key={entityType}
        formKey={entityType}
        mainColor={formColor}
        handleIsOpen={this.handleToggleDebt}
        title={showButtonText}
        // actionComponent={<Icons.IconArrowDown color={formColor} />}
        // actionFullTop={this.handleToggleDebt}
      >
        {formFields}
      </AdditionalForm>
    );
  };

  _renderEditDesktop = () => {
    const { formEditOpened = {}, formOpened } = this.state;
    const {
      isMobile,
      designScheme,
      ENTITIES_ADDITIONAL,
      GLOBAL_TYPE_USE_ASSETS,
      GLOBAL_COLLECTION,
      isAccessEdit,
      assets,
      additionalSaveGlobalType,
      updateFieldGlobalType,
      firestore,
      auth,
      profile,
      profileProvider,
      family,
      childs,
      additionalAssets,
      transactionsAll,
      assetsAll
    } = this.props;

    const entityType =
      formEditOpened && formEditOpened.entity
        ? formEditOpened.entity
        : formOpened;
    const scheme = this.props.formScheme[entityType];

    if (!this.state.formOpened && !this.state.formEditOpened) {
      return null;
    }

    return (
      <AssetForm
        closeForm={this.handleCleanFormAndCloseEdit(entityType)}
        onClose={this.handleCleanFormAndCloseEdit(entityType)}
        open={!!entityType}
        assets={assets}
        //
        isAccessEdit={isAccessEdit}
        //
        assetType={entityType}
        formsInitialState={this.props.formsInitialState}
        scheme={scheme}
        formScheme={this.props.formScheme}
        formsValidationScheme={this.props.formsValidationScheme}
        validationScheme={this.props.formsValidationScheme}
        designScheme={designScheme[entityType]}
        translations={this.props.translations}
        //
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        formEditOpened={this.state.formEditOpened}
        formOpened={this.state.formOpened}
        //
        additionalSaveGlobalType={additionalSaveGlobalType}
        updateFieldGlobalType={updateFieldGlobalType}
        //
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={GLOBAL_COLLECTION}
        //
        duplicate={this.state.duplicate}
        //
        additionalAssets={additionalAssets}
        handleFinishWizard={this.handleFinishWizard}
        assetsAll={assetsAll}
        transactionsAll={transactionsAll}
        //
        profileProvider={profileProvider}
        family={family}
        childs={childs}
        //
        firestore={firestore}
        auth={auth}
        profile={profile}
        isMobile={isMobile}
      />
    );
  };

  _renderEditMenu = () => {
    const { isMobile } = this.props;
    const { formEditOpened = {} } = this.state;

    return (
      <div>
        <SideMenu
          // titleI18n={translations.edit.sideMenu.titleI18n}
          // title={translations.edit.sideMenu.title}
          handleCloseEdit={this.handleCleanFormAndCloseEdit(
            formEditOpened.entity
          )}
          handleCloseRightDrawer={this.handleCloseRightDrawer}
          isOpen={isMobile}
          isMobile={isMobile}
          // WideDialog={true}
          bgSecondary={formEditOpened && formEditOpened.entity === "general"}
          isEdit={true}
        >
          {!!formEditOpened &&
            this._renderFormWoDebt(formEditOpened.entity, true, {
              isEdit: true
            })}
        </SideMenu>
      </div>
    );
  };

  getCustomMinValidation = (field, formEntity, { defaultMin = 0 } = {}) => {
    let age = defaultMin;
    if (field.minFromCustom.isGeneralMemberAge) {
      const minAgeOwner = this.state.optionsOwner.find(
        el => el.value === "You"
      );

      age = (minAgeOwner && minAgeOwner.age) || 0;
    }

    if (field.minFromCustom.isOwner) {
      const fieldOwner = field.minFromCustom.field;
      const form = this.state[formEntity];
      const owners = this.state.optionsOwner;

      optionsOwner.find(el => el.value === "You");
      const minAgeOwner = owners.find(el => el.value === form[fieldOwner]);

      age = (minAgeOwner && minAgeOwner.age) || 0;
    }

    return age;
  };

  _renderFormFields = (
    field,
    fieldKey,
    type,
    { typeCheckEntity, isEdit, isFirst = false } = {}
  ) => {
    const { isAccessEdit } = this.props;
    if (field.isOnlyShow) {
      return null;
    }

    if (field.condition) {
      let isPassedConditions = true;
      const form = this.state[typeCheckEntity || type];

      isPassedConditions = this.checkConditions(field.condition, form, {
        entity: type
      });

      if (!isPassedConditions) {
        return null;
      }
    }

    const isError =
      this.state.validationScheme[type] &&
      this.state.validationScheme[type][fieldKey] &&
      !!this.state.validationScheme[type][fieldKey].isError &&
      !this.state.validationScheme[type][fieldKey].isTouched;

    if (field.isSelect) {
      let valueCustom;
      const { GLOBAL_TYPE_USE_ASSETS } = this.props;

      let options = field.options;

      // move to other func
      if (field.optionsCustom === "custom-members-all") {
        options = [...this.state.optionsOwner];
      }

      if (field.optionsCustom === "custom-members-adult") {
        options = [...this.state.optionsOwnerAdults];
      }

      if (field.optionsCustom === "custom-assets-external") {
        options = [...this.state.optionsAsset];
      }

      if (field.optionsCustom === "custom-event-moneyIn") {
        options = this.helperOptionsEvent(type);
      }

      if (field.optionsCustomAsset) {
        if (this.state.optionsAsset[field.optionsCustomAsset]) {
          // Custom money out -> assets -> debt
          if (field.assignedHomeDebt) {
            const customOptions = this.helperOptionsHomeDebt(field);
            options = customOptions.options;
            valueCustom = customOptions.value;
          } else {
            options = [...this.state.optionsAsset[field.optionsCustomAsset]];
          }
        } else {
          options = [];
        }
      }

      // Custom use assets -> home
      if (
        GLOBAL_TYPE_USE_ASSETS === "use-assets" &&
        fieldKey === "type" &&
        type === "home"
      ) {
        const isPrimaryExists = this.helperOptionsHomeIsPrimary({ isEdit });
        if (isPrimaryExists) {
          options = field.optionsHomeWoPrimary;
        }
      }

      if (
        field.optionsCustomAsset &&
        !options.length &&
        field.isEmptyHelperI18n
      ) {
        return (
          <FormattedMessage
            key={fieldKey}
            id={field.isEmptyHelperI18n}
            defaultMessage={field.isEmptyHelper}
          />
        );
      }

      let valueShow = this.state[type][fieldKey] || field.default || "";
      if (field.optionsCustom) {
        const key = this.state[type][fieldKey];
        const found = options.find(el => el.value === key);
        if (!found) {
          valueShow = field.default || "";
        }
      }

      if (valueCustom) {
        valueShow = valueCustom;
      }

      return (
        <FormControlSelect
          required={field.isRequired}
          disabled={!isAccessEdit}
          error={isError}
          key={fieldKey}
          fieldName={fieldKey}
          label={field.label}
          labelI18n={field.labelI18n}
          handleChange={this.handleInputChange(type)}
          type="select"
          options={options}
          value={valueShow}
          bgSecondary={field.bgSecondary}
          autoFocus={isFirst}
          hideNoneOption={!field.isShowNoneOption}
          isShowNoneLabel={field.isShowNoneOption}
          noneOptionLabel={field.isShowNoneLabel || field.isShowNoneOption}
          showNoneName={field.isShowNoneOption}
        />
      );
    }

    if (field.isDate) {
      return (
        <FormDatePicker
          required={field.isRequired}
          disabled={!isAccessEdit}
          error={isError}
          key={fieldKey}
          fieldName={fieldKey}
          label={field.label}
          labelI18n={field.labelI18n}
          handleChange={this.handleInputChangeDate(type, fieldKey)}
          type="date"
          isFuture={field.isFuture}
          isPast={field.isPast}
          value={
            this.state[type][fieldKey] && this.state[type][fieldKey].seconds
              ? this.state[type][fieldKey].seconds * 1000
              : this.state[type][fieldKey]
              ? this.state[type][fieldKey]
              : null
          }
          isShowYear={true}
          bgSecondary={field.bgSecondary}
          autoFocus={isFirst}
        />
      );
    }

    const additionalProps = {};
    let currentValue = this.state[type][fieldKey];

    if (field.isAmount) {
      additionalProps.InputProps = {
        startAdornment: <InputAdornment position="start">$</InputAdornment>
      };

      additionalProps.step = "0.05";
      additionalProps.min = "0";
    }

    if (field.prefix) {
      additionalProps.InputProps = {
        startAdornment: (
          <InputAdornment position="start">{field.prefix}</InputAdornment>
        )
      };
    }

    if (field.isNumber) {
      additionalProps.type = "number";
      if (!additionalProps.inputProps) {
        additionalProps.inputProps = {};
      }

      if (typeof field.min !== "undefined") {
        additionalProps.inputProps.min = field.min;
      }

      if (typeof field.max !== "undefined") {
        additionalProps.inputProps.max = field.max;
      }

      // TODO Chrome bug with comma
      if (field.isInt) {
        additionalProps.type = "text";
      }

      if (field.minFromCustom) {
        const customMin = this.getCustomMinValidation(
          field,
          typeCheckEntity || type
        );

        additionalProps.inputProps.min = customMin || 0;
      }
    }

    if (field.after) {
      additionalProps.InputProps = {
        endAdornment: (
          <InputAdornment position="end">{field.after}</InputAdornment>
        )
      };
    }

    if (!currentValue && !field.isAmount) {
      currentValue = "";
    }

    return (
      <FormControlInput
        key={fieldKey}
        disabled={!isAccessEdit}
        error={isError}
        helperText={
          isError &&
          this.state.validationScheme[type][fieldKey].errorMessageI18n
            ? this.state.validationScheme[type][fieldKey].errorMessageI18n
            : null
        }
        required={field.isRequired}
        fieldName={fieldKey}
        label={field.label}
        labelI18n={field.labelI18n}
        handleChange={this.handleInputChange(type)}
        value={currentValue}
        bgSecondary={field.bgSecondary}
        {...additionalProps}
        autoFocus={isFirst}
        isAmount={field.isAmount}
        fieldScheme={field}
        formWidth={field.formWidth}
      />
    );
  };

  _renderAddMenu = () => {
    const { ENTITIES, ENTITIES_GROUPS, translations, isMobile } = this.props;

    let bottomItem = null;
    if (ENTITIES.ENTITY_GENERAL) {
      bottomItem = this._renderFormWoDebt(ENTITIES.ENTITY_GENERAL, false, {
        bgSecondary: true,
        titleI18n: translations.edit.generalAsset.titleI18n,
        caption: translations.edit.generalAsset.caption,
        captionI18n: translations.edit.generalAsset.captionI18n
      });
    }

    const contentEntities = Object.keys(ENTITIES_GROUPS).map(
      (entityGroupKey, indexGroup) => {
        const localEntities = ENTITIES_GROUPS[entityGroupKey].ENTITIES;
        const title = ENTITIES_GROUPS[entityGroupKey].title;

        const formFields = Object.keys(localEntities).map(entityKey => {
          const entity = localEntities[entityKey];

          if (entity === ENTITIES.ENTITY_GENERAL) {
            return null;
          }

          return this._renderFormWoDebt(localEntities[entityKey], false, {
            key: entity
          });
        });

        return (
          <SideGroup
            titleI18n={entityGroupKey}
            title={title}
            key={entityGroupKey}
            notFirst={indexGroup !== 0}
          >
            {formFields}
          </SideGroup>
        );
      }
    );

    const sideMenu = (
      <SideMenu
        titleI18n={translations.edit.sideMenuAdd.titleI18n}
        title={translations.edit.sideMenuAdd.title}
        bottom={bottomItem}
        handleCloseRightDrawer={this.handleCloseRightDrawer}
        isOpen={isMobile}
        isMobile={isMobile}
        OpenDialogWhyNeedIt={this.handleOpenDialogWhyNeedIt}
      >
        {contentEntities}
      </SideMenu>
    );

    if (!isMobile) {
      return sideMenu;
    }

    if (!this.state.formOpened) {
      return sideMenu;
    }

    return this._renderFormWoDebt(this.state.formOpened, false);
  };

  calculateSummary = () => {
    const { assets, designScheme } = this.props;

    if (!assets) {
      return null;
    }

    const summary = { total: 0 };

    Object.keys(designScheme).forEach(key => {
      summary[key] = 0;
    });

    assets.forEach(el => {
      if (el && !el.isDisabled && !el.isExpired) {
        summary[el.entity] += +el.value;
        summary.total += +el.value;
      }
    });

    return summary;
  };

  _renderAddTile = () => {
    const {
      ENTITIES_GROUPS,
      ENTITIES,
      isAccessEdit,
      designScheme,
      isMobile,
      translations,
      isEvents,
      isGoals
    } = this.props;

    const AddTileLocal = (
      <AddTile
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES={ENTITIES}
        isAccessEdit={isAccessEdit}
        designScheme={designScheme}
        isMobile={isMobile}
        translations={translations}
        handleToggleAddForm={this.handleToggleAddForm}
        isHorizontal={isGoals}
      />
    );

    if (isEvents) {
      return (
        <div style={{ margin: 16, outline: "none" }}>
          <h3
            className={`events__age events__age--add ${
              !this.state.currentEventSlide ? "events__age--add-first" : ""
            }`}
          >
            <span>$nbsp;</span>
          </h3>

          <div
            className="events__card-container"
            style={{ paddingBottom: this.state.sliderHeight - 250 }}
          >
            {AddTileLocal}
          </div>
        </div>
      );
    }

    return AddTileLocal;
  };

  _renderContent = () => {
    const {
      assets,
      isMobile,
      isDialog,
      GLOBAL_TYPE_USE_ASSETS,
      ENTITIES_ADDITIONAL,
      additionalAssets,
      isAccessEdit,
      family,
      translations
    } = this.props;

    if ((!assets || !assets.length) && isMobile) {
      // TODO EMPTY
      return (
        <EmptyPanel
          titleI18n={translations.mainContent.emptyContent.titleI18n}
          title={translations.mainContent.emptyContent.title}
        />
      );
    }

    if (assets && !this.state.isExpiredCalculated) {
      this.handleExpiredCalculate();
    }

    return (
      <div className="cards" ref={this.cardsRef}>
        <ReactResizeDetector
          handleWidth
          onResize={this.updateCardsDimensions}
        />

        {/*{(!isMobile || isDialog) && this._renderAddTile()}*/}
        {!isMobile && this._renderAddTile()}

        {assets &&
          assets.map(assetOriginal => {
            const asset = { ...assetOriginal };

            const { id } = asset;
            const designSchemeAsset = this.props.designScheme[asset.entity];
            const formSchemeAsset = this.props.formScheme[asset.entity];

            if (!designSchemeAsset || !formSchemeAsset) {
              return null;
            }

            const formSchemeAdditional = ENTITIES_ADDITIONAL[asset.entity];

            if (asset.owner || asset.employeeName) {
              const owner = this.state.optionsOwner.find(
                el =>
                  el.value === asset.owner || el.value === asset.employeeName
              );

              if (asset.owner) {
                asset.owner = owner ? owner.name : "";
              }

              if (asset.employeeName) {
                asset.employeeName = owner ? owner.name : "";
              }
            }

            asset.isEndingSoon =
              this.state.endingSoonIds && ~this.state.endingSoonIds.indexOf(id);

            const isPrimaryResidence =
              GLOBAL_TYPE_USE_ASSETS === "use-assets" &&
              asset.entity === "home" &&
              asset.type === "primary";

            return (
              <AssetCard
                isAccessEdit={isAccessEdit}
                isWizardFinished={family && family.isWizardFinished}
                key={id}
                asset={asset}
                handleEditAsset={this.handleEditAsset(id)}
                handleToggleWhatIfAsset={this.handleToggleWhatIfAsset(id)}
                handleToggleDisableAsset={this.handleToggleDisableAsset(id)}
                handleDeleteAsset={this.handleDialogRemoveOpen(id)}
                handleDuplicateAsset={
                  isPrimaryResidence ? null : this.handleDuplicateAsset(id)
                }
                handleSelectAsset={this.handleSelectAsset(id)}
                isSelected={this.state.selectedAsset === id}
                designScheme={designSchemeAsset}
                formScheme={formSchemeAsset}
                formSchemeAdditional={formSchemeAdditional}
                isMobile={isMobile}
                containerWidth={this.state.cardsWidth}
                additionalAssets={additionalAssets}
              />
            );
          })}
      </div>
    );
  };

  renderEventsContent = () => {
    const {
      assets,
      isMobile,
      translations,
      GLOBAL_TYPE_USE_ASSETS,
      ENTITIES_ADDITIONAL,
      additionalAssets,
      eventsYears,
      eventsYearsFull,
      isAccessEdit,
      family,
      isCombineDashboard,
      activeYear,
      activeYears
    } = this.props;

    // if (!assets || !assets.length) {
    //   // TODO EMPTY
    //   return (
    //     <EmptyPanel
    //       titleI18n={translations.mainContent.emptyContent.titleI18n}
    //       title={translations.mainContent.emptyContent.title}
    //     />
    //   );
    // }

    if (assets && !this.state.isExpiredCalculated) {
      this.handleExpiredCalculate();
    }

    const currentAge =
      family && family.birthday && family.birthday.seconds
        ? diffYears(Date.now(), family.birthday.seconds * 1000)
        : MIN_CALC_AGE;

    const addTile = this._renderAddTile();

    const activeSlide = activeYear
      ? eventsYears
        ? eventsYears.indexOf(+activeYear)
        : null
      : null;

    const isNotShowAddTile =
      (eventsYears.length && isCombineDashboard) ||
      (isCombineDashboard && eventsYears.length && isMobile);

    return (
      <div>
        <ReactResizeDetector
          handleWidth
          onResize={this.updateCardsDimensions}
        />
        <div className="events__caption">
          <span className="events__caption-age">Age</span>
        </div>
        <EventsSlider
          containerWidth={this.state.cardsWidth}
          isMobile={isMobile}
          ref={this.slider}
          totalSlides={eventsYears.length}
          addTile={isNotShowAddTile ? null : addTile}
          handleEventSliderHeight={this.handleEventSliderHeight}
          handleSliderCurrentSlide={this.handleEventSliderCurrentSlide}
          activeSlide={activeSlide}
        >
          {eventsYears &&
            eventsYears.map(el => {
              // should we show previous years?
              // if (el < currentAge) {
              //   return null;
              // }

              return (
                <div key={el} style={{ margin: 16, outline: "none" }}>
                  <h3 className="events__age">{el}</h3>

                  <div className="events__card-container">
                    {eventsYearsFull[el].map(assetId => {
                      const assetIndex = assets.findIndex(
                        asset => asset.id === assetId
                      );
                      const asset = assets[assetIndex];

                      if (!asset) {
                        return null;
                      }

                      const { id } = asset;
                      const designSchemeAsset = this.props.designScheme[
                        asset.entity
                      ];
                      const formSchemeAsset = this.props.formScheme[
                        asset.entity
                      ];

                      if (!designSchemeAsset || !formSchemeAsset) {
                        return null;
                      }

                      // TODO additional schemas for AssetCARD
                      const formSchemeAssetDebt = this.props.formScheme[
                        `${asset.entity}Debt`
                      ];

                      const formSchemeAdditional =
                        ENTITIES_ADDITIONAL[asset.entity];

                      if (asset.owner || asset.employeeName) {
                        const owner = this.state.optionsOwner.find(
                          el =>
                            el.value === asset.owner ||
                            el.value === asset.employeeName
                        );

                        if (asset.owner && owner) {
                          asset.owner = owner.name;
                        }

                        if (asset.employeeName && owner) {
                          asset.employeeName = owner.name;
                        }
                      }

                      const isPrimaryResidence =
                        GLOBAL_TYPE_USE_ASSETS === "use-assets" &&
                        asset.entity === "home" &&
                        asset.type === "primary";

                      return (
                        <AssetCard
                          key={id}
                          isAccessEdit={isAccessEdit}
                          asset={asset}
                          handleEditAsset={this.handleEditAsset(id)}
                          handleToggleWhatIfAsset={this.handleToggleWhatIfAsset(
                            id
                          )}
                          handleToggleDisableAsset={this.handleToggleDisableAsset(
                            id
                          )}
                          handleDeleteAsset={this.handleDialogRemoveOpen(id)}
                          handleDuplicateAsset={
                            isPrimaryResidence
                              ? null
                              : this.handleDuplicateAsset(id)
                          }
                          handleSelectAsset={this.handleSelectAsset(id)}
                          isSelected={this.state.selectedAsset === id}
                          designScheme={designSchemeAsset}
                          formScheme={formSchemeAsset}
                          formSchemeDebt={formSchemeAssetDebt}
                          formSchemeAdditional={formSchemeAdditional}
                          isMobile={isMobile}
                          isEvent={true}
                          containerWidth={this.state.cardsWidth}
                          additionalAssets={additionalAssets}
                          currentAge={currentAge}
                          calcAge={el}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </EventsSlider>
      </div>
    );
  };

  handleOnDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  _renderGoalsContent = () => {
    const {
      assets,
      isMobile,
      translations,
      ENTITIES_ADDITIONAL,
      additionalAssets,
      isAccessEdit,
      family
    } = this.props;

    if (!assets || !assets.length) {
      // TODO EMPTY
      return (
        <EmptyPanel
          titleI18n={translations.mainContent.emptyContent.titleI18n}
          title={translations.mainContent.emptyContent.title}
        />
      );
    }

    if (!this.state.isExpiredCalculated) {
      this.handleExpiredCalculate();
    }

    const SortableGoals = SortableContainer(({ items }) => {
      return (
        <div className="ac-draggable__list">
          {items.map((assetOriginal, index) => {
            const asset = { ...assetOriginal };

            const { id } = asset;
            const designSchemeAsset = this.props.designScheme[asset.entity];
            const formSchemeAsset = this.props.formScheme[asset.entity];

            if (!designSchemeAsset || !formSchemeAsset) {
              return null;
            }

            const formSchemeAdditional = ENTITIES_ADDITIONAL[asset.entity];

            if (asset.personAttending || asset.personInsured) {
              const owner = this.state.optionsOwner.find(
                el =>
                  el.value === asset.personAttending ||
                  el.value === asset.personInsured
              );

              if (asset.personAttending) {
                asset.personAttending = owner ? owner.name : "";
              }

              if (asset.personInsured) {
                asset.personInsured = owner ? owner.name : "";
              }
            }

            const SortableItem = SortableElement(({ assetEl }) => {
              return <div key={`item-${index}`}>{assetEl}</div>;
            });

            const DragHandle = SortableHandle(() => (
              <div className="ac-draggable__drag">
                <Icons.IconMenu width={24} height={24} />
              </div>
            ));

            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                assetEl={
                  <GoalsDraggableHoc
                    key={id}
                    isAccessEdit={isAccessEdit && family.isWizardFinished}
                    isWizardFinished={family.isWizardFinished}
                    index={index}
                    DragHandle={DragHandle}
                    asset={asset}
                    handleEditAsset={this.handleEditAsset(id)}
                    handleDeleteAsset={this.handleDialogRemoveOpen(id)}
                    handleDuplicateAsset={this.handleDuplicateAsset(id)}
                    handleSelectAsset={this.handleSelectAsset(id)}
                    isSelected={this.state.selectedAsset === id}
                    designScheme={designSchemeAsset}
                    formScheme={formSchemeAsset}
                    formSchemeAdditional={formSchemeAdditional}
                    isMobile={isMobile}
                    containerWidth={this.state.cardsWidth}
                    additionalAssets={additionalAssets}
                  />
                }
              />
            );
          })}
        </div>
      );
    });

    return (
      <div className="cards cards--goals" ref={this.cardsRef}>
        <div className="goals-labels">
          <div className="goals-labels__type">
            <FormattedMessage
              id="PLANS.GOALS.TITLES.TYPE.LABEL"
              defaultMessage="Type"
            />
          </div>
          <div className="goals-labels__amount">
            <FormattedMessage
              id="PLANS.GOALS.TITLES.AMOUNT.LABEL"
              defaultMessage="Amount"
            />
          </div>
          <div className="goals-labels__description">
            <FormattedMessage
              id="PLANS.GOALS.TITLES.DESCRIPTION.LABEL"
              defaultMessage="Description"
            />
          </div>
        </div>

        <SortableGoals
          useDragHandle
          items={assets}
          onSortEnd={this.props.onSortEnd}
          onSortStart={this.handleOnDragStart}
        />
      </div>
    );
  };

  render() {
    const {
      isMobile,
      assets,
      profile,
      firestoreStatus,
      translations,
      designScheme,
      isHideSort,
      isEvents,
      isGoals,
      headerWithoutPadding,
      GLOBAL_TYPE_USE_ASSETS,
      summaryData,
      isCombineDashboard,
      isDialog
    } = this.props;

    if (!profile.isLoaded || firestoreStatus.requesting.assets) {
      return <IsLoading />;
    }

    const isEventsDashboard = isEvents && isCombineDashboard;

    const isShowSummary =
      GLOBAL_TYPE_USE_ASSETS !== "events" &&
      !!assets &&
      !!assets.length &&
      summaryData;

    const SummaryComp = isShowSummary ? (
      <Summary
        calcScrollBarWidth={this.calcScrollBarWidth()}
        isDialog={isDialog}
        mainContentRef={this.mainContentRef}
        summary={summaryData}
        isMobile={isMobile}
        containerWidth={this.state.cardsWidth}
        designScheme={designScheme}
      />
    ) : null;

    const mainContent = (
      <div
        className={`main-container ${isDialog ? "main-container--dialog" : ""}`}
        ref={this.containerRef}
      >
        <div
          className={`main-content ${isDialog ? "main-content--dialog" : ""}`}
          ref={this.mainContentRef}
        >
          {!isEventsDashboard && !isDialog && (
            <>
              <ContentHeader
                isContentEmpty={!(assets && assets.length)}
                headerWithoutPadding={headerWithoutPadding}
                header={
                  <FormattedMessage
                    id={translations.mainContent.header.titleI18n}
                    defaultMessage={translations.mainContent.header.title}
                  />
                }
                caption={
                  <FormattedMessage
                    id={translations.mainContent.caption.titleI18n}
                    defaultMessage={translations.mainContent.caption.title}
                  />
                }
              />

              <div className="main-divider" />

              <MainToolbar
                titleI18n={translations.mainContent.toolbarHeader.titleI18n}
                title={translations.mainContent.toolbarHeader.title}
                rightPanel={true}
                isHideSort={isHideSort}
              />

              {!isMobile && isGoals && this._renderAddTile()}
            </>
          )}

          <ContentMain isEvents={isEvents}>
            {!isEvents && !isGoals && this._renderContent()}
            {isEvents && this.renderEventsContent()}
            {isGoals && this._renderGoalsContent()}
          </ContentMain>
        </div>

        {// !isMobile &&
        ((this.state.formEditOpened && this.state.formEditOpened.entity) ||
          this.state.formOpened) &&
          this._renderEditDesktop()}

        {/*{isEvents && !isMobile && this._renderAddMenu()}*/}

        {isMobile && (
          <SideRight
            open={this.state.openRightDrawer}
            handleClose={this.handleCloseRightDrawer}
          >
            {this.state.formEditOpened && this.state.formEditOpened.entity
              ? this._renderEditMenu()
              : this._renderAddMenu()}
          </SideRight>
        )}

        {/*{isMobile && !isDialog && (*/}
        {isMobile && (!isEvents && !isCombineDashboard) && (
          <div className="app-bar__button-container">
            <ButtonSaveChanges
              handleClick={this.handleOpenRightBarAddForm}
              round
            >
              <Icons.IconPlus width={20} height={20} color={"white"} />
            </ButtonSaveChanges>
          </div>
        )}

        {translations.whyNeedIt &&
          translations.whyNeedIt.textI18n &&
          translations.whyNeedIt.titleI18n && (
            <DialogWhyNeedIt
              fullScreen={isMobile}
              open={this.state.dialogWhyNeedItOpen}
              onClose={this.handleCloseDialogs}
              titleI18n={translations.whyNeedIt.titleI18n}
              textI18n={translations.whyNeedIt.textI18n}
            />
          )}
        <DialogRemoveAsset
          isOpen={!!this.state.assetUid}
          handleAgreeAndClose={this.handleDialogRemoveAgree}
          handleClose={this.handleDialogRemoveClose}
        />
        <DialogFinishWizard
          isOpen={
            this.state.isDialogFinishWizardOpen || this.state.isWizardFinishing
          }
          handleAgreeAndClose={this.handleDialogFinishWizardAgree}
          handleClose={this.handleDialogFinishWizardClose}
          isLoading={this.state.isWizardFinishing}
        />
        {!isMobile && !isDialog && SummaryComp}
      </div>
    );

    return (
      <>
        {mainContent}
        {!isMobile && isDialog && SummaryComp}
      </>
    );
  }
}

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { isMobile, profileProvider }
}) {
  const family = ordered && ordered.family && ordered.family[0];
  const userUid = profileProvider || auth.uid;
  const childUser = data && data.users && data.users[userUid];
  const childs =
    childUser && childUser.childs
      ? Object.keys(childUser.childs).map(childKey => ({
          id: childKey,
          ...childUser.childs[childKey]
        }))
      : null;

  return {
    isMobile,
    auth,
    profile,
    profileProvider: userUid,
    family: family,
    childs: childs,
    // assetsWhole: ordered && ordered.assetsWhole,
    assetsAll: getAssetsAllData(ordered),
    transactionsAll: getTransactionsAllData(ordered),
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    { setUI }
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: "users",
        doc: profileUid,
        subcollections: [
          {
            collection: "childs",
            orderBy: ["birthday", "asc"],
            storeAs: "childs"
          }
        ]
      },
      {
        collection: "users",
        doc: profileUid,
        storeAs: "family"
      }
    ];
    if (props.family && !props.family.isWizardFinished) {
      queries.push({
        collection: "userAssets",
        where: [["user", "==", profileUid]],
        storeAs: "assetsAll"
      });

      queries.push({
        collection: "transactions",
        where: [["user", "==", profileUid]],
        storeAs: "transactionsAll"
      });
    }
    return queries;
  })
)(withFirebase(withStyles(styles)(AssetCategoryCommon)));
