import { colors } from "constants/styles";

export const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  logoTop: {
    marginTop: "30px",
    textAlign: "center"
  },

  heading: {
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "0.5px",
    color: "#232735"
  },
  subheading: {
    marginBottom: "8px",
    fontSize: "16px",
    lineHeight: "40px",
    letterSpacing: "0.5px",
    color: "#505565"
  },
  text: {
    marginBottom: "8px",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.5px",
    color: "#505565"
  },
  tipsButton: {
    marginBottom: "25px"
  },
  quizContainer: {
    marginBottom: "40px"
  },

  button: {
    backgroundColor: colors.uiYellow,
    paddingLeft: 32,
    paddingRight: 32,
    marginTop: "40px",
    color: colors.white,
    borderRadius: 4,

    "&:hover": {
      backgroundColor: colors.uiYellow,
      opacity: 0.8
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: `${
      theme.spacing.unit * 2 // Fix IE 11 issue.
    }px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      boxSizing: "border-box",
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  card: {
    width: "100%",
    marginBottom: "10px",
    padding: "16px 20px"
  },
  cardContent: {
    padding: "0 !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  radioGroup: {
    flexDirection: "row"
  }
}); // Fix IE 11 issue.
