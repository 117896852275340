import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconMoneyOwned = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6667 11.3333H30.5714L18.6667 3L2 14.6667H5.33333V31.3333H15.3333H20.3333V18C20.3333 16.6739 20.8601 15.4021 21.7978 14.4645C22.3301 13.9321 22.9702 13.5322 23.6667 13.286V11.3333ZM31.6667 33.3333H28.3333V31.6667H25V28.3333H31.6667V26.6667H26.6667C26.2246 26.6667 25.8007 26.4911 25.4882 26.1785C25.1756 25.8659 25 25.442 25 25V20C25 19.558 25.1756 19.134 25.4882 18.8215C25.8007 18.5089 26.2246 18.3333 26.6667 18.3333H28.3333V16.6667H31.6667V18.3333H35V21.6667H28.3333V23.3333H33.3333C33.7754 23.3333 34.1993 23.5089 34.5118 23.8215C34.8244 24.134 35 24.558 35 25V30C35 30.442 34.8244 30.8659 34.5118 31.1785C34.1993 31.4911 33.7754 31.6667 33.3333 31.6667H31.6667V33.3333Z"
        fill={fill}
      />
    </svg>
  );
};
IconMoneyOwned.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};
export default IconMoneyOwned;
