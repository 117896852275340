import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconClock = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 2C6.46484 2 2 6.46484 2 12C2 17.5352 6.46484 22 12 22C17.5352 22 22 17.5352 22 12C22 6.46484 17.5352 2 12 2ZM12 4C16.4648 4 20 7.53516 20 12C20 16.4648 16.4648 20 12 20C7.53516 20 4 16.4648 4 12C4 7.53516 7.53516 4 12 4ZM11 7V12.4062L11.2812 12.7188L14.2812 15.7188L15.7188 14.2812L13 11.5625V7H11Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconClock.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconClock;
