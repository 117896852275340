import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconVehicleMulti = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M11.3074 3C10.5375 3 9.85195 3.48867 9.60234 4.21641L8.75508 7.5H10.65L11.3074 4.8H18.0926L19.0312 7.5H13.35C14.1659 7.63021 14.5527 7.84637 14.8301 8.4L16.2188 12C16.2188 12 16.5 12.9 16.5 13.8H18.3V14.7C18.3 15.1957 18.7043 15.6 19.2 15.6H20.1C20.5957 15.6 21 15.1957 21 14.7V7.725L19.7941 4.21641C19.548 3.48867 18.8625 3 18.0926 3H11.3074ZM5.90391 8.4C5.1375 8.4 4.45195 8.88867 4.20234 9.61641L3 13.125V20.1C3 20.5957 3.4043 21 3.9 21H4.8C5.2957 21 5.7 20.5957 5.7 20.1V19.2H12.9V20.1C12.9 20.5957 13.3043 21 13.8 21H14.7C15.1957 21 15.6 20.5957 15.6 20.1V13.125L14.3941 9.61641C14.148 8.88867 13.4625 8.4 12.6926 8.4H5.90391ZM18.3 9.3C18.7957 9.3 19.2 9.7043 19.2 10.2C19.2 10.6957 18.7957 11.1 18.3 11.1C17.8043 11.1 17.4 10.6957 17.4 10.2C17.4 9.7043 17.8043 9.3 18.3 9.3ZM5.90391 10.2H12.6926L13.6313 12.9H4.96875L5.90391 10.2ZM5.7 14.7C6.1957 14.7 6.6 15.1043 6.6 15.6C6.6 16.0957 6.1957 16.5 5.7 16.5C5.2043 16.5 4.8 16.0957 4.8 15.6C4.8 15.1043 5.2043 14.7 5.7 14.7ZM12.9 14.7C13.3957 14.7 13.8 15.1043 13.8 15.6C13.8 16.0957 13.3957 16.5 12.9 16.5C12.4043 16.5 12 16.0957 12 15.6C12 15.1043 12.4043 14.7 12.9 14.7Z"
        fill={fill}
      />
    </svg>
  );
};

IconVehicleMulti.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconVehicleMulti;
