// export const WIZARD_DEFAULTS_NEW_SET = {...}

export const WIZARD_DEFAULTS_TEST = {
  nameDB: "testSet", // Using for translations file ->   "WIZARD.DEFAULT_SET.testSet.TITLE": "Test Set" & "WIZARD.DEFAULT_SET.testSet.DESCRIPTION": "Test set description",
  meta: {
    // default title & description if there no translations
    title: "Test Set",
    description: "Test data set"
  },
  family: {
    AGE: 33,
    DEPENDENTS: 1
  },
  money: {
    SALARY_DEFAULT_SINGLE: 50005,
    SALARY_DEFAULT_MARRIED: 75005,
    RENTS_DEFAULT: 12005,
    PROPERTY_TAX_DEFAULT: 5000,
    HOUSE_VALUE_DEFAULT: 400005,
    MONTHLY_RENT_DEFAULT: 3005,
    HOA_DEFAULT: 505,
    VACATION_DEFAULT: 30005
  },
  approximation: {
    GROCERIES_DEFAULT: 335,
    DINING_DEFAULT: 445,
    DEPENDENT_DEFAULT_MULT: 995,
    MEDICAL_DEFAULT_MULT: 115,
    TRANSPORTATION_DEFAULT: 885,
    INSURANCE_DEFAULT: 0,
    OTHER_DEFAULT: 225
  },
  assets: {
    HOMES_VEHICLES_DEFAULT: {
      // 0 to 25 age = $0;
      // 25 to 36 = $50,000;
      // 36 to 41 = $150,000; etc
      amount: [0, 50005, 150005, 300005, 500005],
      age: [0, 25, 36, 41, 60]
    },
    RETIREMENT_DEFAULT: {
      amount: [10005, 33005, 144005, 344005, 444005],
      age: [0, 30, 36, 41, 60]
    },
    SAVED_DEFAULT: {
      amount: [0, 15005, 90005, 190005, 205005],
      age: [0, 30, 36, 41, 60]
    },
    DEATH_DEFAULT: {
      amount: [0, 50005, 250005, 500005, 500005],
      age: [0, 30, 36, 41, 60]
    },
    INVESTED_DEFAULT: {
      amount: [10005, 50005, 150005, 300005, 100005],
      age: [0, 30, 36, 41, 60]
    },
    CHECKING_SAVING_DEFAULT: {
      amount: [10005, 50005, 85005, 125005, 80005],
      age: [0, 30, 36, 41, 60]
    }
  },
  assetsDebt: {
    DEBT_REMAINS_DEFAULT: {
      amount: [0, 150005, 250005, 300005, 100005],
      age: [0, 30, 36, 41, 60]
    },
    INTEREST_DEBT_DEFAULT: 5005,
    INTEREST_DEBT_INTEREST_DEFAULT: "12",
    OTHER_DEBT_DEFAULT: 5000,
    OTHER_DEBT_INTEREST_DEFAULT: "6"
  }
};

export const WIZARD_DEFAULTS_TEST2 = {
  nameDB: "testSet2",
  // Using for translations file ->   "WIZARD.DEFAULT_SET.testSet2.TITLE": "Test Set" & "WIZARD.DEFAULT_SET.testSet2.DESCRIPTION": "Test set description",
  meta: {
    // default title & description if there no translations
    title: "Test Set2",
    description: "Test data set2"
  },
  family: {
    AGE: 33,
    DEPENDENTS: 1
  },
  money: {
    SALARY_DEFAULT_SINGLE: 50005,
    SALARY_DEFAULT_MARRIED: 75005,
    RENTS_DEFAULT: 12005,
    PROPERTY_TAX_DEFAULT: 2555,
    HOUSE_VALUE_DEFAULT: 400005,
    MONTHLY_RENT_DEFAULT: 3005,
    HOA_DEFAULT: 505,
    VACATION_DEFAULT: 30005
  },
  approximation: {
    GROCERIES_DEFAULT: 335,
    DINING_DEFAULT: 445,
    DEPENDENT_DEFAULT_MULT: 995,
    MEDICAL_DEFAULT_MULT: 115,
    TRANSPORTATION_DEFAULT: 885,
    INSURANCE_DEFAULT: 0,
    OTHER_DEFAULT: 225
  },
  assets: {
    HOMES_VEHICLES_DEFAULT: {
      // 0 to 25 age = $0;
      // 25 to 36 = $50,000;
      // 36 to 41 = $150,000; etc
      amount: [0, 50005, 150005, 300005, 500005],
      age: [0, 25, 36, 41, 60]
    },
    RETIREMENT_DEFAULT: {
      amount: [10005, 33005, 144005, 344005, 444005],
      age: [0, 30, 36, 41, 60]
    },
    SAVED_DEFAULT: {
      amount: [0, 15005, 90005, 190005, 205005],
      age: [0, 30, 36, 41, 60]
    },
    DEATH_DEFAULT: {
      amount: [0, 50005, 250005, 500005, 500005],
      age: [0, 30, 36, 41, 60]
    },
    INVESTED_DEFAULT: {
      amount: [10005, 50005, 150005, 300005, 100005],
      age: [0, 30, 36, 41, 60]
    },
    CHECKING_SAVING_DEFAULT: {
      amount: [10005, 50005, 85005, 125005, 80005],
      age: [0, 30, 36, 41, 60]
    }
  },
  assetsDebt: {
    DEBT_REMAINS_DEFAULT: {
      amount: [0, 150005, 250005, 300005, 100005],
      age: [0, 30, 36, 41, 60]
    },
    INTEREST_DEBT_DEFAULT: 5005,
    INTEREST_DEBT_INTEREST_DEFAULT: "12",
    OTHER_DEBT_DEFAULT: 2050,
    OTHER_DEBT_INTEREST_DEFAULT: "6"
  }
};

export const WIZARD_DEFAULTS_NEW = {
  nameDB: "new",
  // Using for translations file ->   "WIZARD.DEFAULT_SET.testSet2.TITLE": "Test Set" & "WIZARD.DEFAULT_SET.testSet2.DESCRIPTION": "Test set description",
  meta: {
    // default title & description if there no translations
    title: "New",
    description: "Clean template"
  },
  family: {
    AGE: 18,
    DEPENDENTS: 0
  },
  money: {
    SALARY_DEFAULT_SINGLE: 0,
    SALARY_DEFAULT_MARRIED: 0,
    RENTS_DEFAULT: 0,
    PROPERTY_TAX_DEFAULT: 0,
    HOUSE_VALUE_DEFAULT: 0,
    MONTHLY_RENT_DEFAULT: 0,
    HOA_DEFAULT: 0,
    UTILITIES_DEFAULT: 0,
    VACATION_DEFAULT: 0
  },
  approximation: {
    GROCERIES_DEFAULT: 0,
    DINING_DEFAULT: 0,
    DEPENDENT_DEFAULT_MULT: 0,
    MEDICAL_DEFAULT_MULT: 0,
    TRANSPORTATION_DEFAULT: 0,
    INSURANCE_DEFAULT: 0,
    OTHER_DEFAULT: 0
  },
  assets: {
    HOMES_VEHICLES_DEFAULT: {
      // 0 to 25 age = $0;
      // 25 to 36 = $50,000;
      // 36 to 41 = $150,000; etc
      amount: [0],
      age: [0]
    },
    RETIREMENT_DEFAULT: {
      amount: [0],
      age: [0]
    },
    SAVED_DEFAULT: {
      amount: [0],
      age: [0]
    },
    DEATH_DEFAULT: {
      amount: [0],
      age: [0]
    },
    INVESTED_DEFAULT: {
      amount: [0],
      age: [0]
    },
    CHECKING_SAVING_DEFAULT: {
      amount: [0],
      age: [0]
    }
  },
  assetsDebt: {
    DEBT_REMAINS_DEFAULT: {
      amount: [0],
      age: [0]
    },
    INTEREST_DEBT_DEFAULT: 0,
    INTEREST_DEBT_INTEREST_DEFAULT: "12",
    OTHER_DEBT_DEFAULT: 0,
    OTHER_DEBT_INTEREST_DEFAULT: "6"
  }
};
