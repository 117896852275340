import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAuthIsEmptyState,
  getAuthIsLoadedState,
  getAuthExpireInState,
  getProfileIsAdminApprovedState
} from "../store/selectors/firebase";

import IsLoadingAuth from "./IsLoadingAuth";
import { withFirebase } from "react-redux-firebase";

import { DASHBOARD } from "constants/routes";

function PrivateRoute({
  component: Component,
  authFallback: AuthFallback,
  isAdvisorRoute,
  ...rest
}) {
  const {
    isLoaded,
    isEmpty,
    isAdminApproved,
    profile,
    firebase,
    ...restProps
  } = rest;

  if (!isLoaded || !profile.isLoaded) {
    return <IsLoadingAuth />;
  }

  const emailVerified =
    firebase &&
    firebase.auth().currentUser &&
    firebase.auth().currentUser.emailVerified;

  const isLoggedIn = isLoaded && !isEmpty && emailVerified && isAdminApproved;

  const isAdvisorRestrict = isAdvisorRoute && !profile.isAdvisor;
  // TODO
  // const isAdminRestrict = isAminRoute && !profile.isAdmin;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          !isAdvisorRestrict ? (
            <Component {...props} {...restProps} />
          ) : (
            <Redirect
              to={{
                pathname: DASHBOARD,
                state: { from: props.location }
              }}
            />
          )
        ) : AuthFallback ? (
          <AuthFallback {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    isLoaded: getAuthIsLoadedState(state),
    isEmpty: getAuthIsEmptyState(state),
    expires: getAuthExpireInState(state),
    isAdminApproved: getProfileIsAdminApprovedState(state),
    profile: state.firebase.profile
  };
}

export default connect(
  mapStateToProps,
  {}
)(withFirebase(PrivateRoute));
