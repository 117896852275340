import React, { Component } from "react";

import PropTypes from "prop-types";
import HouseholdCard from "../../HouseholdMembers/HouseholdCard";
import IconContainer from "../../_common/IconContainer";
import * as Icons from "../../_common/icons";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import Button from "../../_common/Button/ButtonCommon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage, injectIntl } from "react-intl-v3";
import toast from "../../_common/toast";
import {
  formsInitialState,
  formsValidationScheme
} from "../../Profile/formScheme";
import { calcPasswordStrength, emailValid } from "../../LogIn/helper";
import { REQUIRED_PASSWORD_STRENGTH } from "../../../constants";
import { functionsURL } from "../../../config/config";
import ButtonSideItem from "../../_common/Button/ButtonSideItem";
import { CLOUD_FUNC_ENDPOINT } from "../../../constants/endpoints";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "../../../constants/styles";

class ModalInviteClient extends Component {
  static propTypes = {
    clientName: PropTypes.any,
    clientEmail: PropTypes.any,
    profile: PropTypes.any,
    isMobile: PropTypes.any,
    classes: PropTypes.any
  };

  state = {
    ...formsInitialState,
    validationScheme: { ...formsValidationScheme },

    notes: ""
  };

  componentDidMount() {
    const { intl } = this.props;

    const notes = intl.formatMessage({
      id: "MANAGE_CLIENT.MODAL.DEFAULT_MESSAGE",
      defaultMessage:
        "I am excited to invite you to XCurve where we can work on your case in collaboration. XCurve will help us visualize your financials today and the impact of different decisions on the future. XCurve is a hosted and secured software, so you simply need to click the link below, register and you'll be up and running in no time. \n\nOnce in the platform, you can share your case with me if and when you choose by going to the menu under your name, selecting advisor permissions, clicking my name and setting advisor access to enable. You can then choose what you want to share with me and what you don't.\n\nLooking forward to co-planning with you.\n\nSincerely"
    });

    const additionalState = {};
    const { clientName, clientEmail } = this.props;

    if (clientName || clientEmail) {
      // additionalState
      additionalState.ADVISOR_INVITE = { clientName, clientEmail };
    }
    this.setState({ notes, ...additionalState });
  }

  handleValidateData = entity => {
    let isValid = true;
    let newState = { ...this.state.validationScheme };

    const formValidation = { ...this.state.validationScheme[entity] };
    const form = this.state[entity];

    Object.keys(formValidation).forEach(keyField => {
      const scheme = formValidation[keyField];

      if (scheme.isRequired) {
        let isConditionAccept = true;
        const formEntityScheme = formsValidationScheme[entity][keyField];

        if (formEntityScheme.condition) {
          isConditionAccept = this.checkConditions(
            formEntityScheme.condition,
            form
          );
        }

        if (!form[keyField] && isConditionAccept) {
          if (
            formsValidationScheme[entity][keyField].isAmount &&
            form[keyField] === 0
          ) {
            newState[entity][keyField].isError = null;
          } else {
            isValid = false;

            newState[entity][keyField].isError = "error";
          }
        } else {
          newState[entity][keyField].isError = null;
        }

        newState[entity][keyField].isTouched = this.state.validationScheme[
          entity
        ][keyField].isTouched;

        newState[entity][keyField].isTouched = false;
        newState[entity][keyField].isRequired = true;
      }

      if (scheme.isEmail) {
        const isEmailValid = emailValid(form[keyField]);

        if (!isEmailValid) {
          isValid = false;

          newState[entity][keyField].isTouched = false;
          newState[entity][keyField].isError = "error";

          newState[entity][keyField].errorMessageI18n = (
            <FormattedMessage
              id="SIGNUP.ERROR.INVALID_EMAIL"
              defaultMessage="You must enter valid email address"
            />
          );
        }
        newState[entity][keyField].isEmail = true;
      }
    });

    if (entity === "PASSWORD") {
      const isPasswordEnoughStrength =
        calcPasswordStrength(form.newPassword) > REQUIRED_PASSWORD_STRENGTH;

      if (!isPasswordEnoughStrength) {
        isValid = false;

        newState[entity].newPassword.isError = true;
        newState[entity].newPassword.errorMessageI18n = (
          <FormattedMessage
            id="SIGNUP.ERROR.INVALID_PASSWORD_STRENGTH"
            defaultMessage="You must enter strong password"
          />
        );
      }

      const isConfirmPasswordValid =
        !form.newPassword || form.newPassword === form.confirmPassword;

      if (!isConfirmPasswordValid) {
        isValid = false;
        newState[entity].confirmPassword.isError = true;
        newState[entity].confirmPassword.errorMessageI18n = (
          <FormattedMessage
            id="SIGNUP.ERROR.INVALID_CONFIRM_PASSWORD"
            defaultMessage="You must enter the same password"
          />
        );
      }
    }

    this.setState({ validationScheme: newState });
    return isValid;
  };

  handleInputChangeSimple = ev => {
    const { name, value } = ev.target;

    this.setState({ [name]: value });
  };

  handleInputChange = asset => ev => {
    const { name, value } = ev.target;

    const isRequired =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isRequired;
    const isEmail =
      this.state.validationScheme[asset] &&
      this.state.validationScheme[asset][name] &&
      this.state.validationScheme[asset][name].isEmail;

    this.setState(prevState => ({
      [asset]: { ...prevState[asset], [name]: value },
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [name]: {
            isTouched: true,
            isError: false,
            isRequired: isRequired,
            isEmail: isEmail
          }
        }
      }
    }));
  };

  handleInviteClient = async ev => {
    ev.preventDefault();

    const { firebase, firestore, profile, auth } = this.props;
    const { intl } = this.props;
    const currentUser = firebase.auth().currentUser;

    const KEY = "ADVISOR_INVITE";
    const formState = this.state[KEY];
    const notes = this.state.notes;

    const isValid = this.handleValidateData(KEY);

    const hello = intl.formatMessage(
      {
        id: "PROFILE.INVITE.EMAIL.HELLO",
        defaultMessage: "Hello, {name}.\n\n"
      },
      { name: formState.clientName }
    );

    const message = intl.formatMessage(
      {
        id: "PROFILE.INVITE.EMAIL.MESSAGE",
        defaultMessage:
          "Hello, {name}. Please click to the link, to proceed to XCurve."
      },
      { name: formState.clientName }
    );

    const subject = intl.formatMessage({
      id: "PROFILE.INVITE.EMAIL.SUBJECT",
      defaultMessage: "XCurve"
    });
    const signUpLink = intl.formatMessage({
      id: "PROFILE.INVITE.EMAIL.SIGN_UP_LINK",
      defaultMessage: "signUpLink"
    });

    if (!isValid) {
      toast(
        <FormattedMessage
          id="APP.FORM.VALIDATION.FIX_ERRORS"
          defaultMessage="Please, fix all errors"
        />,
        { type: "error" }
      );

      return;
    }

    const query = await firestore
      .collection("advisor")
      .where("client", "==", formState.clientEmail)
      .where("advisor", "==", auth.email)
      .get();

    let dataAdvisor;
    query.forEach(doc => {
      dataAdvisor = { ...doc.data(), id: doc.id };
    });

    if (
      !dataAdvisor ||
      (dataAdvisor && !dataAdvisor.clientId) ||
      (dataAdvisor.register && !dataAdvisor.lastLogin)
    ) {
      this.setState({
        isSendingInvite: true
      });

      currentUser
        .getIdToken(true)
        .then(idToken => {
          const data = {
            advisorEmail: auth.email,
            clientEmail: formState.clientEmail,
            uid: currentUser.uid,
            name: profile.displayName,
            idToken,
            message: message,
            signUpLink: signUpLink,
            subject: subject,
            advisorEntityId: dataAdvisor && dataAdvisor.id,
            clientName: formState.clientName,
            hello,
            notes
          };

          fetch(`${functionsURL}/${CLOUD_FUNC_ENDPOINT.sendMailInviteClient}`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
          })
            .then(res => {
              this.setState({
                isSendingInvite: false
              });

              toast(
                <FormattedMessage
                  id="PROFILE.INVITE.SENT"
                  defaultMessage="Email successfully sent"
                />
              );

              this.props.handleClose();
            })
            .catch(err => {
              this.setState({
                isSendingInvite: false
              });
            });
        })
        .catch(error => {});
    } else {
      if (dataAdvisor && dataAdvisor.clientId) {
        toast(
          <FormattedMessage
            id="PROFILE.INVITE.ALREADY_CLIENT"
            defaultMessage="This Email already your client"
          />,
          { type: "error" }
        );
      } else {
        toast(
          <FormattedMessage
            id="PROFILE.INVITE.ALREADY_SENT"
            defaultMessage="Email already sent to this client"
          />,
          { type: "error" }
        );
      }
    }
  };

  render() {
    const { profile, isMobile, classes } = this.props;
    const KEY = "ADVISOR_INVITE";

    if (!(profile && profile.isAdvisor)) {
      return null;
    }

    return (
      <form onSubmit={this.handleInviteClient}>
        <HouseholdCard
          iconComp={
            <IconContainer color="brand" height={32} width={32}>
              <Icons.IconSendMail color="brand" height={24} width={24} />
            </IconContainer>
          }
          title="Invite a client"
          titleI18n="MANAGE_CLIENT.MODAL.TITLE"
          bodyWithoutPadding
          fullWidth
          style={{ margin: 0 }}
          actionTop={
            <ButtonSideItem
              type="button"
              isOpen
              handleClick={this.props.handleClose}
            />
          }
        >
          <div className={classes.content}>
            <div className={classes.contentItem}>
              <div className={classes.InputName}>
                <FormControlInput
                  required
                  error={
                    !!this.state.validationScheme[KEY].clientName.isError &&
                    !this.state.validationScheme[KEY].clientName.isTouched
                  }
                  fieldName="clientName"
                  label="Client Name"
                  labelI18n="PROFILE.CARD.ADVISOR_INVITE.NAME"
                  handleChange={this.handleInputChange(KEY)}
                  value={this.state[KEY].clientName}
                />
              </div>

              <div className={classes.InputEmail}>
                <FormControlInput
                  required
                  fieldName="clientEmail"
                  label="Client Email"
                  labelI18n="PROFILE.CARD.ADVISOR_INVITE.EMAIL"
                  handleChange={this.handleInputChange(KEY)}
                  value={this.state[KEY].clientEmail}
                  error={
                    !!this.state.validationScheme[KEY].clientEmail.isError &&
                    !this.state.validationScheme[KEY].clientEmail.isTouched
                  }
                  helperText={
                    !!this.state.validationScheme[KEY].clientEmail.isError &&
                    !this.state.validationScheme[KEY].clientEmail.isTouched &&
                    this.state.validationScheme[KEY].clientEmail
                      .errorMessageI18n
                      ? this.state.validationScheme[KEY].clientEmail
                          .errorMessageI18n
                      : null
                  }
                />
              </div>
            </div>

            <div className={classes.contentItem}>
              <FormControlInput
                InputLabelProps={{ shrink: true }}
                required
                // rows={multilineRows}
                multiline
                fieldName="notes"
                label="Notes"
                labelI18n="MANAGE_CLIENT.MODAL.FORM.NOTES"
                handleChange={this.handleInputChangeSimple}
                value={this.state.notes}
              />
            </div>

            <button type="submit" style={{ display: "none" }} />

            <div className={classes.contentButton}>
              <Button
                handleClick={this.handleInviteClient}
                yellow
                style={{ marginTop: 16 }}
                disabled={this.state.isSendingInvite}
              >
                {this.state.isSendingInvite ? (
                  <CircularProgress size={20} color={colors.white} />
                ) : (
                  <FormattedMessage
                    id="MANAGE_CLIENT.MODAL.FORM.SEND"
                    defaultMessage="Send"
                  />
                )}
              </Button>
            </div>
          </div>
        </HouseholdCard>
      </form>
    );
  }
}

const styles = theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    paddingBottom: 32,

    backgroundColor: colors.backgroundDisabled
  },
  contentItem: {
    display: "flex"
  },

  InputName: {
    width: 240,
    marginRight: 64
  },
  InputEmail: {
    width: 240
  },
  contentButton: {
    margin: "0 auto",
    width: 160
  }
});

export default withStyles(styles)(injectIntl(ModalInviteClient));
