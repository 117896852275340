import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import "./SideGroup.scss";

const SideGroup = ({
  children,
  title,
  titleI18n,
  bgSecondary,
  notFirst,
  open
}) => {
  if (!children) {
    return null;
  }

  return (
    <section
      className={`side-group ${bgSecondary ? "side-group--secondary" : ""} ${
        notFirst ? "side-group--not-first" : ""
      }`}
    >
      <div className="side-group__title">
        <FormattedMessage id={titleI18n} defaultMessage={title} />
      </div>
      {children}
    </section>
  );
};
SideGroup.propTypes = {
  title: PropTypes.string,
  titleI18n: PropTypes.string,
  bgSecondary: PropTypes.bool,
  notFirst: PropTypes.bool
};

export default SideGroup;
