import React from "react";
import PropTypes from "prop-types";

import "./HouseholdCard.scss";
import { FormattedMessage } from "react-intl-v3";

const HouseholdCard = props => {
  const {
    icon,
    titleEl,
    title,
    titleI18n,
    iconComp,
    subtitle,
    subtitleI18n,
    actionTop,
    fullWidth,
    tooltip,
    classNames,
    bodyWithoutPadding,
    style = {}
  } = props;

  const isTitle = (titleI18n && title) || titleEl;
  return (
    <section
      className={`hhcard ${
        fullWidth ? "hhcard--full-width" : ""
      } ${classNames || ""}`}
      style={style}
    >
      {isTitle && (
        <div className="hhcard__title">
          {iconComp ? (
            iconComp
          ) : icon ? (
            <div className="hhcard__title-icon">
              <img src={icon} alt="" />
            </div>
          ) : null}

          <div className="hhcard__title-text">
            {subtitle && subtitleI18n && (
              <div className="hhcard__title-subtitle">
                <FormattedMessage id={subtitleI18n} defaultMessage={subtitle} />
              </div>
            )}
            {titleEl ? (
              titleEl
            ) : (
              <FormattedMessage id={titleI18n} defaultMessage={title} />
            )}

            {tooltip}
          </div>

          {actionTop && <div className="hhcard__top-action">{actionTop}</div>}
        </div>
      )}

      <div
        className={`hhcard__body ${
          bodyWithoutPadding ? "hhcard__body--no-padding" : ""
        }`}
      >
        {props.children}
      </div>
    </section>
  );
};

HouseholdCard.propTypes = {
  fullWidth: PropTypes.bool,
  classNames: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  titleEl: PropTypes.any,
  title: PropTypes.string,
  titleI18n: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleI18n: PropTypes.string,
  iconComp: PropTypes.element,
  actionTop: PropTypes.any
};

export default HouseholdCard;
