import React from "react";

import { colors } from "constants/styles";

const IconDashboard = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6.57143C3 5.13436 4.18112 4 5.6 4H18.4C19.8189 4 21 5.13436 21 6.57143V14.4286C21 15.8656 19.8189 17 18.4 17H13V19H16C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21H8C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19H11V17H5.6C4.18112 17 3 15.8656 3 14.4286V6.57143ZM6 7H9V9H6V7ZM6 10H13V14H6V10ZM14 10H18V14H14V10ZM10 7H13V9H10V7ZM14 7H18V9H14V7ZM4 6.5C4 5.67157 4.67157 5 5.5 5H18.5C19.3284 5 20 5.67157 20 6.5V14.5C20 15.3284 19.3284 16 18.5 16H5.5C4.67157 16 4 15.3284 4 14.5V6.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconDashboard;
