import { toast } from "react-toastify";
import { css } from "glamor";
import { colors } from "constants/styles";

export default {
  // Keep the signature of the original toast object
  // Doing so you can pass additionnal options
  default(msg, options = {}) {
    return toast(msg, {
      ...options,
      closeButton: css({
        color: "#F0F",
        position: "relative",
        top: "8px",
        right: "8px",
        fontSize: "20px"
      }),
      className: css({
        background:
          "linear-gradient(0deg, rgba(76, 196, 97, 0.1), rgba(76, 196, 97, 0.1)), #FFFFFF",
        boxShadow: "0px 4px 16px rgba(30, 50, 90, 0.2)",
        borderRadius: "4px",

        fontFamily: "PT Root UI",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.5px"
      })
    });
  },
  info(msg, options = {}) {
    return toast.success(msg, {
      // Merge additionals options
      ...options,
      className: css({
        color: "#31708f",
        borderRadius: "4px",
        background: "#d9edf7"
      })
    });
  },
  success(msg, options = {}) {
    return toast.success(msg, {
      // Merge additionals options
      ...options,
      closeButton: css({
        // color: colors.textTetrieary,
        color: "#F0F",
        position: "relative",
        top: "8px",
        right: "8px",
        fontSize: "20px"
      }),
      bodyClassName: css({
        paddingLeft: "32px",
        paddingRight: "32px"
      }),
      className: css({
        color: "#4CC461",
        backgroundColor: "#dff0d8",
        backgroundImage:
          "url('/assets/img/toast/success.png'), linear-gradient(0deg, rgba(76, 196, 97, 0.1), rgba(76, 196, 97, 0.1))",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "10px center, center center",
        backgroundSize: "20px 20px, 100% 100%",

        boxShadow: "0px 4px 16px rgba(30, 50, 90, 0.2)",
        borderRadius: "4px",

        fontFamily: "PT Root UI",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.5px",
        fontWeight: "500"
      })
    });
  },
  warning(msg, options = {}) {
    return toast.success(msg, {
      // Merge additionals options
      ...options,
      className: css({
        color: "#8a6d3b",
        borderRadius: "4px",
        background: "#fcf8e3"
      })
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      closeButton: css({
        color: colors.textTetrieary,
        position: "relative",
        top: "8px",
        right: "8px",
        fontSize: "20px"
      }),
      bodyClassName: css({
        paddingLeft: "32px",
        paddingRight: "32px"
      }),
      className: css({
        color: "#FF3B30",
        backgroundColor: "#f2dede",
        backgroundImage:
          "linear-gradient(0deg, rgba(255, 59, 48, 0.1), rgba(255, 59, 48, 0.1)), url('/assets/img/toast/fail.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center, 10px center",
        backgroundSize: "100% 100%, 20px 20px",
        boxShadow: "0px 4px 16px rgba(30, 50, 90, 0.2)",
        borderRadius: "4px",

        fontFamily: "PT Root UI",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.5px",
        fontWeight: "500"
      })
    });
  }
};
