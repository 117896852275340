import React from "react";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { Redirect } from "react-router";
import { setUI } from "AC/ui";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "components/LogIn/styles";
import { FormattedMessage } from "react-intl-v3";

import AppBarLogIn from "components/ContainerApp/AppBarLogIn";

import * as ROUTES from "constants/routes";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { compose } from "redux";
import { connect } from "react-redux";

const modesParam = {
  resetPassword: {
    route: ROUTES.CHANGE_PASSWORD,
    pullCode: true,
    method: "verifyPasswordResetCode"
  },
  verifyEmail: {
    route: ROUTES.HOME,
    method: "applyActionCode"
  }
};

class EmailVerification extends React.PureComponent {
  state = {
    isVerified: false,
    mode: "",
    error: "",
    errorMessage: "",
    oobCode: "",
    isVerifying: false
  };

  intervalHandler = null;
  isVerifying = false;

  componentDidMount() {
    const { firebase, location } = this.props;

    if (!location && !location.search) {
      return this.setState({ error: "missing-credentials" });
    }

    if (firebase.auth()) {
      this.handleVerifyingCode();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { firebase } = this.props;

    const currentUser = firebase.auth().currentUser;

    if (currentUser && currentUser.emailVerified && !this.state.isVerified) {
      this.setState({
        isVerified: true,
        mode: this.modeObject,
        oobCode: this.oobCode
      });
    }

    const { isVerifying, isVerified, error } = this.state;

    if (!isVerifying && !isVerified && !error && !this.isVerifying) {
      this.handleVerifyingCode();
    }
  }

  componentWillUnmount() {
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
    }
  }

  checkVerifying = async () => {
    const { firebase, location } = this.props;

    // firebase.auth().onAuthStateChanged(res => {});

    if (firebase && firebase.auth() && firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload();
    }

    const emailVerified =
      firebase &&
      firebase.auth().currentUser &&
      firebase.auth().currentUser.emailVerified;

    if (emailVerified) {
      const urlParams = new URLSearchParams(location.search);
      const oobCode = urlParams.get("oobCode");
      const mode = urlParams.get("mode");
      const modeRoute = modesParam[mode];

      this.props.setUI({});
      if (this.intervalHandler) {
        clearInterval(this.intervalHandler);
      }

      this.setState({
        isVerified: true,
        mode: modeRoute,
        oobCode
      });
    }
  };

  handleVerifyingCode = () => {
    const { firebase, location } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const oobCode = urlParams.get("oobCode");
    const mode = urlParams.get("mode");
    const modeRoute = modesParam[mode];
    this.modeObject = modesParam[mode];

    if (!oobCode) {
      return this.setState({ error: "missing-credentials" });
    }

    if (!modeRoute) {
      return this.setState({ error: "missing-credentials" });
    }

    if (modeRoute.route === ROUTES.HOME && !this.isVerifying) {
      this.isVerifying = true;

      firebase
        .auth()
        .applyActionCode(oobCode)
        .then(res => {
          firebase.auth().onIdTokenChanged(user => {
            const currentUser = firebase.auth().currentUser;

            if (
              (user && user.emailVerified) ||
              (currentUser && currentUser.emailVerified)
            ) {
              this.setState({
                isVerified: true,
                mode: modeRoute,
                oobCode
              });
            } else {
              this.intervalHandler = setInterval(this.checkVerifying, 500);
            }
          });
        })
        .catch(err => {
          console.log("err---", err);
          this.setState({ error: err.code, errorMessage: err.message });
        });
    }

    if (modeRoute.route === ROUTES.CHANGE_PASSWORD && !this.isVerifying) {
      this.isVerifying = true;

      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(() => {
          this.setState({
            isVerified: true,
            mode: modeRoute,
            oobCode
          });
        })
        .catch(err => {
          this.setState({ error: err.code, errorMessage: err.message });
        });
    }
  };

  handleSendVerificationEmail = () => {
    const { firebase } = this.props;

    const currentUser = firebase.auth().currentUser;

    if (currentUser && !currentUser.emailVerified) {
      currentUser.sendEmailVerification().then(() => {
        this.setState({ isEmailSent: true });
      });
    }
  };

  _renderError = () => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.paperLeft}>
          <div className={classes.titleWrap}>
            <Typography component="h1" variant="h5" className={classes.title}>
              <FormattedMessage
                id="EMAIL_VERIFICATION.TITLE"
                defaultMessage="Verify email"
              />
            </Typography>
          </div>
          <div className={classes.imgCont}>
            <img src="/assets/img/bg-sign.svg" alt="" />
          </div>
          <img
            className={classes.logoBottom}
            src="/assets/img/logo-orange-white.svg"
            alt=""
          />
        </div>
        <div className={classes.paperRight}>
          <div>
            <h1>
              <FormattedMessage
                id="APP.ERROR_HAS_OCCURRED"
                defaultMessage="Error has occurred"
              />
            </h1>

            <Typography className={classes.typographyVerification}>
              <FormattedMessage
                id="EMAIL_VERIFICATION.ERROR.CODE"
                defaultMessage="The action code is invalid. This can happen if the code is malformed, expired, or has already been used"
              />
            </Typography>

            <Grid container spacing={24} className={classes.sendAgainContainer}>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*<Typography>*/}
              {/*<FormattedMessage*/}
              {/*id="EMAIL_VERIFICATION.SEND_EMAIL_AGAIN"*/}
              {/*defaultMessage="Or push button to send email again"*/}
              {/*/>*/}
              {/*</Typography>*/}
              {/*</Grid>*/}
              {/*<Grid*/}
              {/*item*/}
              {/*xs={12}*/}
              {/*sm={6}*/}
              {/*className={classes.sendAgainButtonContainer}*/}
              {/*>*/}
              {/*<Button*/}
              {/*variant="contained"*/}
              {/*color="primary"*/}
              {/*onClick={this.handleSendVerificationEmail}*/}
              {/*>*/}
              {/*<FormattedMessage*/}
              {/*id="EMAIL_VERIFICATION.SEND_EMAIL_AGAIN_BUTTON"*/}
              {/*defaultMessage="Send email"*/}
              {/*/>*/}
              {/*</Button>*/}
              {/*</Grid>*/}
            </Grid>
          </div>
        </div>
      </>
    );
  };

  _renderVerifyingData = () => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.paperLeft}>
          <div className={classes.titleWrap} />
          <div className={classes.imgCont}>
            <img src="/assets/img/bg-sign.svg" alt="" />
          </div>
          <img
            className={classes.logoBottom}
            src="/assets/img/logo-orange-white.svg"
            alt=""
          />
        </div>
        <div className={classes.paperRight}>
          <div className={classes.paperCenter}>
            <h1>
              <FormattedMessage
                id="EMAIL_VERIFICATION.IS_VERIFYING"
                defaultMessage="Verifying data"
              />
            </h1>

            <CircularProgress />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    const { isVerified, mode, oobCode } = this.state;

    if (isVerified && mode && mode.route) {
      const to = { pathname: mode.route };

      if (mode.pullCode) {
        to.state = { code: oobCode };
        to.search = `?code=${oobCode}`;
      }

      return <Redirect to={to} />;
    }

    const content = !this.state.error
      ? this._renderVerifyingData()
      : this._renderError();

    return (
      <main className={classes.main}>
        <CssBaseline />
        <AppBarLogIn
          rightButtonLink={"/login"}
          rightButtonText={
            <FormattedMessage id="APP.LOG_IN" defaultMessage="Log In" />
          }
        />
        <Paper className={classes.paper}>{content}</Paper>
      </main>
    );
  }
}

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { profileProvider }
}) {
  return {
    auth,
    profile,
    assets: ordered && ordered.goals,
    profileProvider: profileProvider || auth.uid,
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    { setUI }
  ),

  withFirebase
)(withStyles(styles)(EmailVerification));
