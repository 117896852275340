import { DRAWER_WIDTH, colors } from "../../constants/styles";

export default theme => ({
  root: {
    display: "flex"
  },
  header: {
    height: 64,
    background: colors.white,
    boxShadow: "0px 0px 12px rgba(46, 63, 82, 0.25)",
    zIndex: 1260,

    [theme.breakpoints.down(960)]: {
      zIndex: 1000,
      top: 0,
      width: "100vw",
      height: 56,
      background: "linear-gradient(180deg, #363658 0%, #432f70 100%)"
    }
  },
  topProfileMenu: {},
  logo: {
    width: "89px",
    height: "16px",
    marginRight: "20px"
  },
  link: {
    color: colors.white,
    fontSize: 14,
    lineHeight: "21px",
    textDecoration: "none"
  },

  clientSelect: {
    marginTop: 8,
    marginLeft: 16,
    marginRight: 16,
    width: 240,

    [theme.breakpoints.down(1200)]: {
      marginLeft: 0,
      marginRight: 16
    }
  },
  clientInviteContainer: {
    paddingBottom: 0
  },
  clientInvite: {
    backgroundColor: colors.backgroundSecondary,
    marginBottom: -8,
    padding: 16
  },

  toolbar: {
    paddingLeft: 0,
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  toolbarLogInWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid rgba(255, 255, 255, 0.3)`
    }
  },

  toolbarLogIn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  backButtonIcon: {
    marginLeft: -40,
    marginRight: 8
  },
  appBar: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarBreadCrumbs: {
    [theme.breakpoints.down("lg")]: {
      display: "none"
    },
    [theme.breakpoints.up("lg")]: {
      display: "block"
    },
    [theme.breakpoints.up("lg")]: {
      display: "block"
    },
    // width: "538px",
    width: "450px",
    display: "block"
  },
  profileButton: {
    display: "flex",
    marginRight: "16px"
  },
  profileAva: {
    marginLeft: 4,
    marginRight: 10,
    width: 24,
    height: 24
  },
  profileName: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    wordWrap: "break-word"
  },
  menuButton: {
    marginLeft: 20,
    marginRight: 20
  },
  menuButtonHidden: {
    display: "none"
  },
  navLinks: {
    display: "flex",
    flexGrow: 1,
    // [theme.breakpoints.up(1366)]: {
    //   marginLeft: 0
    // },
    "&.navLinksAdvisor": {
      [theme.breakpoints.down(1200)]: {
        marginLeft: "50px"
      },
      [theme.breakpoints.down(1050)]: {
        marginLeft: "16px"
      }
    },

    [theme.breakpoints.down("lg")]: {
      marginLeft: "150px"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto"
    }
  },

  //DRAWER
  drawerPaper: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      backgroundColor: "#503493",
      backgroundImage: "linear-gradient(180deg, #363658 0%, #564DD0 100%)"
    },

    [theme.breakpoints.up("md")]: {
      position: "relative",
      top: "64px",
      maxHeight: "calc(100vh - 64px)",
      whiteSpace: "nowrap",
      width: DRAWER_WIDTH,
      flexShrink: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      background: "linear-gradient(180deg, #363658 0%, #564DD0 100%)"
    }
  },
  drawerRootOpen: {
    zIndex: 1250
  },
  drawerRootClose: {
    width: 1
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9
    }
  },
  appBarSpacer: theme.mixins.toolbar,

  content: {
    marginTop: "65px",
    padding: "0",
    flexGrow: 1,
    height: "calc(100vh - 65px)",
    overflow: "auto",

    [theme.breakpoints.down("md")]: {
      marginTop: "56px",
      paddingBottom: "56px"
    }
  },
  textTetrieary: {
    color: colors.textTetrieary
  },

  clientInviteDialogContent: {
    "&:not(.important)": {
      padding: 0
    }
  },
  langContainer: {
    padding: "6px 8px",
    borderRadius: 4
  },
  langShow: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    color: colors.textPrimary
  }
});
