import React, { Component } from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { formatMoneyIntWithSign } from "../../lib/helpers";

// const logMap = {
//   use: globalTypeUse,
//   savings: globalTypeSavings,
//   investment: globalTypeInvestments,
//   accounts: globalTypeAccount,
//   debt: globalTypeDebt,
//   moneyIn: globalTypeMoneyIn,
//   moneyOut: "custom",
//   events: globalTypeEvents
// };
// const logSubMap = {
//   asset: globalTypeMoneyOutAssets,
//   family: globalTypeMoneyOutPersonal,
//   rentLease: globalTypeMoneyOutOther
// };

class DashboardLog extends Component {
  static propTypes = {
    dashboardChartTerm: PropTypes.any,
    dashboardIfChecked: PropTypes.bool,
    propsState: PropTypes.any,
    transactionsAll: PropTypes.any,
    transactionsAllObj: PropTypes.any,
    assetsAll: PropTypes.any,
    assetsAllObj: PropTypes.any,
    goalsAll: PropTypes.any,
    eventsALl: PropTypes.any,
    profile: PropTypes.object,
    rights: PropTypes.any,
    family: PropTypes.any,
    fullCalc: PropTypes.any,
    uiSettings: PropTypes.any
  };

  render() {
    const { assetsAllObj, transactionsAllObj, propsState } = this.props;

    const { fullCalc } = propsState;

    if (!fullCalc) {
      return null;
    }

    const assetsLog = {};
    const assetsLogOverdraft = {};

    const calcLog = (assets, logs, age) => {
      Object.keys(assets).forEach(globalCat => {
        const category = assets[globalCat];

        if (category) {
          Object.keys(category).forEach(subType => {
            const type = category[subType];

            if (type) {
              Object.keys(type).forEach(entityId => {
                if (!logs[globalCat]) {
                  logs[globalCat] = {};
                }

                if (!logs[globalCat][subType]) {
                  logs[globalCat][subType] = {};
                }

                if (!logs[globalCat][subType][entityId]) {
                  logs[globalCat][subType][entityId] = {};
                }

                const entity = type[entityId];
                logs[globalCat][subType][entityId][age] = entity;
              });
            }
          });
        }
      });
    };

    Object.keys(fullCalc).forEach(age => {
      const assetsAllC = fullCalc[age].assetsAll;
      const assetsAllOverdraft = fullCalc[age].overdraft.assetsAll;

      if (!assetsAllC) {
        return null;
      }

      calcLog(assetsAllC, assetsLog, age);
      calcLog(assetsAllOverdraft, assetsLogOverdraft, age);
    });

    let fullLog = null;
    if (assetsLog) {
      fullLog = Object.keys(assetsLog).map(globalCat => {
        const category = assetsLog[globalCat];

        const categoriesEl = Object.keys(category).map(subType => {
          const type = category[subType];

          const typeEl = Object.keys(type).map(entityId => {
            const entity = type[entityId];

            const asset =
              (assetsAllObj && assetsAllObj[entityId]) ||
              (transactionsAllObj && transactionsAllObj[entityId]) ||
              null;

            const entityRows = entity => {
              return Object.keys(entity).map(age => {
                let value = entity[age].value;
                let cover = entity[age].cover;

                if (globalCat === "accounts") {
                  value = entity[age].balance;
                }

                if (subType === "salary") {
                  value = entity[age].valueEarning;
                }
                if (subType === "lifeInsurance") {
                  value = entity[age].cashValue;
                }

                const rows = [
                  <TableCell>{age}</TableCell>,
                  <TableCell>
                    {!!+cover
                      ? formatMoneyIntWithSign(+value + +cover)
                      : formatMoneyIntWithSign(value)}
                  </TableCell>,
                  <TableCell>{formatMoneyIntWithSign(value)}</TableCell>,
                  <TableCell>{formatMoneyIntWithSign(cover)}</TableCell>
                ];

                if ("savings" === globalCat) {
                  const taxAdvantage = entity[age].taxAdvantage;
                  const taxDeferred = entity[age].taxDeferred;

                  rows.push(
                    <TableCell>
                      {formatMoneyIntWithSign(taxAdvantage)}
                    </TableCell>
                  );
                  rows.push(
                    <TableCell>{formatMoneyIntWithSign(taxDeferred)}</TableCell>
                  );
                }

                if (subType === "lifeInsurance") {
                  const deathBenefit = entity[age].deathBenefit;

                  rows.push(
                    <TableCell>
                      {formatMoneyIntWithSign(deathBenefit)}
                    </TableCell>
                  );
                }

                return <TableRow key={entityId + age}>{rows}</TableRow>;
              });
            };

            const entityTable = entityRows(entity);

            const entityOverdraft =
              assetsLogOverdraft[globalCat][subType][entityId];

            const entityTableOverdraft = entityRows(entityOverdraft);

            const isHideAssetsWithdrawal =
              globalCat === "debt" ||
              globalCat === "use" ||
              globalCat === "events" ||
              globalCat === "moneyIn" ||
              globalCat === "moneyOut";

            const headRow = [
              <TableCell>Age</TableCell>,
              <TableCell>before cover</TableCell>,
              <TableCell>Value</TableCell>,
              <TableCell>Cover Overdraft</TableCell>
            ];

            if ("savings" == globalCat) {
              headRow.push(<TableCell>Tax Advantage</TableCell>);
              headRow.push(<TableCell>Tax Deferred</TableCell>);
            }

            if (subType == "lifeInsurance") {
              headRow.push(<TableCell>Death Benefit</TableCell>);
            }

            return (
              <div key={entityId}>
                <details style={{ marginBottom: 16 }}>
                  <summary className="cursor-pointer">
                    {(asset && asset.description) || entityId}
                  </summary>
                  <div className="tabales" style={{ display: "flex" }}>
                    <Table style={{ marginRight: 50 }}>
                      <caption>Overdraft</caption>
                      <TableHead>
                        <TableRow>{headRow}</TableRow>
                      </TableHead>
                      <TableBody>{entityTable}</TableBody>
                    </Table>

                    {!isHideAssetsWithdrawal && (
                      <Table>
                        <caption>AssetsWithdrawal</caption>
                        <TableHead>
                          <TableRow>{headRow}</TableRow>
                        </TableHead>
                        <TableBody>{entityTableOverdraft}</TableBody>
                      </Table>
                    )}
                  </div>
                </details>
              </div>
            );
          });

          return (
            <div key={subType} style={{ marginBottom: 24 }}>
              <h3>{subType}</h3>
              {typeEl}
            </div>
          );
        });

        return (
          <div key={globalCat}>
            <h2>{globalCat}</h2>
            {categoriesEl}
            <hr />
          </div>
        );
      });
    }

    return (
      <div style={{ padding: 40, paddingTop: 16 }}>
        <h1>Calculation log</h1>
        {fullLog}
      </div>
    );
  }
}

export default DashboardLog;
