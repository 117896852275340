import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/_common/Button/ButtonCommon";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl-v3";

const styles = {
  container: {
    padding: "44px 60px 56px 60px",
    textAlign: "center",

    "& p": {
      textAlign: "left"
    }
  },
  welcomeImage: {},
  subheading: {
    marginBottom: "16px",
    color: "#232735"
  },
  desc: {
    marginBottom: "16px",
    color: "#232735"
  },
  button: {
    marginTop: "30px"
  }
};

class DialogWelcome extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func
  };

  render() {
    const { classes, onClose, open } = this.props;

    // TODO i18n
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <div className={classes.container}>
          <h2 className="main-title">
            <FormattedMessage
              id="APP.WHY_NEED_IT"
              defaultMessage="Why do we need this information?"
            />
          </h2>

          <FormattedHTMLMessage
            id="HOUSEHOLD.MEMBERS.WHY.ALL_TEXT"
            defaultMessage=" "
          />

          <div className={classes.button}>
            <Button variant="contained" color="primary" onClick={onClose}>
              <FormattedMessage
                id="HOUSEHOLD.MEMBERS.WHY.BUTTON.OK"
                defaultMessage="Ok, I understand"
              />
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWelcome);
