import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconSettings = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M8.06059 0C7.79876 0 7.57573 0.196815 7.54664 0.449859L7.30422 2.47423C6.29572 2.81162 5.39389 3.31771 4.60843 3.97376L2.66902 3.16776C2.42659 3.06467 2.14538 3.15839 2.00962 3.38332L0.0702045 6.62606C-0.0655531 6.84161 0.00232385 7.13215 0.215661 7.2821L1.88356 8.48173C1.78658 8.96908 1.7284 9.48454 1.7284 10C1.7284 10.5155 1.78658 11.0309 1.88356 11.5183L0.215661 12.7179C0.00232385 12.8679 -0.0558567 13.1584 0.0702045 13.3739L2.00962 16.6167C2.14538 16.8416 2.42659 16.9353 2.66902 16.8322L4.60843 16.0262C5.39389 16.6823 6.29572 17.1884 7.30422 17.5258L7.54664 19.5501C7.57573 19.8032 7.79876 20 8.06059 20H11.9394C12.2012 20 12.4243 19.8032 12.4534 19.5501L12.6958 17.5258C13.7043 17.1884 14.6061 16.6823 15.3916 16.0262L17.331 16.8229C17.5734 16.926 17.8546 16.8416 17.9904 16.6167L19.9298 13.3739C20.0656 13.149 19.9977 12.8679 19.7843 12.7179L18.1164 11.5183C18.2134 11.0216 18.2716 10.5155 18.2716 10C18.2716 9.48454 18.2134 8.96908 18.1164 8.48173L19.7843 7.2821C19.9977 7.13215 20.0559 6.84161 19.9298 6.62606L17.9904 3.38332C17.8546 3.15839 17.5734 3.06467 17.331 3.16776L15.3916 3.97376C14.6061 3.31771 13.7043 2.81162 12.6958 2.47423L12.4534 0.449859C12.4243 0.196815 12.2012 0 11.9394 0H8.06059ZM10 5.99813C12.2885 5.99813 14.1406 7.78819 14.1406 10C14.1406 12.2118 12.2885 14.0019 10 14.0019C7.71149 14.0019 5.85935 12.2118 5.85935 10C5.85935 7.78819 7.71149 5.99813 10 5.99813Z"
        fill={fill}
      />
    </svg>
  );
};

IconSettings.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSettings;
