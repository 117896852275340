import {
  addAdditionalConfiguration,
  commonPrefixAfter,
  prepareState
} from "../AssetsCommon/_formScheme";
import { repeatableCommon } from "components/HouseholdAssetsSavings/formScheme";
import {
  RIGHT_HOUSEHOLD_ASSETS,
  RIGHT_HOUSEHOLD_ASSETS_INVESTED
} from "../../constants/rights";
import { MAX_AGE } from "../../constants";

export const GLOBAL_TYPE_USE_ASSETS = "use-invested";
export const GLOBAL_COLLECTION_USE_ASSETS = "userAssets";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_ASSETS;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_ASSETS_INVESTED;

const ENTITY_RSU = "RSU";
const ENTITY_ESPP = "ESPP";
const ENTITY_MUTUAL_FUNDS = "mutualFunds";
const ENTITY_STOCKS = "stocks";
const ENTITY_TREASURIES = "treasuries";
const ENTITY_BUSINESS_INTEREST = "businessInterest";
const ENTITY_OTHER = "other";
const ENTITY_GENERAL = "general";

export const ENTITIES = {
  ENTITY_RSU,
  ENTITY_ESPP,
  ENTITY_MUTUAL_FUNDS,
  ENTITY_STOCKS,
  ENTITY_TREASURIES,
  ENTITY_BUSINESS_INTEREST,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

export const ENTITIES_DEBT = {};
export const ENTITIES_ADDITIONAL = {};

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.ASSETS.INVESTED.FORM_GROUP.TITLE": {
    title: "Asset Type",
    ENTITIES: {
      ENTITY_RSU,
      ENTITY_ESPP,
      ENTITY_MUTUAL_FUNDS,
      ENTITY_STOCKS,
      ENTITY_TREASURIES,
      ENTITY_BUSINESS_INTEREST,
      ENTITY_OTHER
    }
  }
};

const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];
// const optionsInterestType = [
//   {
//     name: "Simple",
//     nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.INTEREST.TYPES.SIMPLE",
//     value: "simple"
//   },
//   {
//     name: "Compound",
//     nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.INTEREST.TYPES.COMPOUND",
//     value: "compound"
//   }
// ];

const optionsLookBackPeriods = [
  {
    name: "None",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.NONE.LABEL",
    value: "none"
  },
  {
    name: "6 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.6.LABEL",
    value: "6"
  },
  {
    name: "12 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.12.LABEL",
    value: "12"
  },
  {
    name: "18 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.18.LABEL",
    value: "18"
  },
  {
    name: "24 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.24.LABEL",
    value: "24"
  },
  {
    name: "30 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.30.LABEL",
    value: "30"
  },
  {
    name: "36 Months",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.36.LABEL",
    value: "36"
  }
];

const repeatableSchemeGeneral = {
  description: {
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.DESCRIPTION.LABEL"
  },
  owner: {
    isAdditional: true,
    isFullWidth: true,

    isRequired: true,
    isSelect: true,
    options: optionsOwner,
    optionsCustom: "custom-members-all",
    label: "Owner",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.OWNER.LABEL"
  },
  value: {
    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Value",
    labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.VALUE.LABEL"
  },
  stockTicker: {
    label: "Stock Ticker",
    labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.STOCK_TICKER.LABEL"
  },

  currentValue: {
    isMain: true,
    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Current Value",
    labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.CURRENT_VALUE.LABEL"
  },
  purchaseValue: {
    isMain: false,
    isAmount: true,
    isNumber: true,

    isRequired: false,

    condition: [{ field: "value", isFilled: true }],
    label: "Purchase Value",
    labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PURCHASE_VALUE.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_RSU]: {
    icon: "IconCase",
    color: "green",
    title: "RSU",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.RSU"
  },

  [ENTITIES.ENTITY_ESPP]: {
    icon: "IconCase",
    color: "green",
    title: "ESPP",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.ESPP"
  },

  [ENTITIES.ENTITY_MUTUAL_FUNDS]: {
    icon: "IconAccount",
    color: "green",
    title: "Mutual Funds",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.MUTUAL_FUNDS"
  },

  [ENTITIES.ENTITY_STOCKS]: {
    icon: "IconAccount",
    color: "green",
    title: "Stocks",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.STOCKS"
  },

  [ENTITIES.ENTITY_TREASURIES]: {
    icon: "IconAccount",
    color: "green",
    title: "Treasuries",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.TREASURIES"
  },

  [ENTITIES.ENTITY_BUSINESS_INTEREST]: {
    icon: "IconDollar",
    color: "green",
    title: "Interest",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.INTEREST",
    titleCard: "Business Interest",
    titleI18nCard: "HOUSEHOLD.ASSETS.INVESTED.TILE.BUSINESS_INTEREST"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "Group of Assets",
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TILE.GROUP_OF_ASSETS"
  }
};

export const formScheme = {
  [ENTITIES.ENTITY_RSU]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.RSU.DESCRIPTION.LABEL"
    },
    owner: { ...repeatableSchemeGeneral.owner },
    numberOfUnvestedUnits: {
      isMain: true,
      calculate: { isUnvestedEstimate: true },
      isRequired: true,
      isNumber: true,
      isInt: true,
      labelI18nCard:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.NUMBER_UNVESTED_UNITS.CARD.LABEL",
      labelCard: "Unvested Estimate",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.NUMBER_AWARDED_UNITS.LABEL",
      label: "Number of awarded units"
    },
    awardDate: {
      isAdditional: true,
      calculate: { isAwardDate: true, dateField: "awardDate" },
      isRequired: true,
      isDate: true,
      // isFuture: true,
      label: "Award Date",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.AWARD_DATE.LABEL",
      labelCard: "Days to Next Vest",
      labelI18nCard:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.NEXT_VESTING_DATE.LABEL.CARD"
    },
    remainingVestingPeriod: {
      isAdditional: true,
      isRequired: true,
      isNumber: true,
      isInt: true,
      calculate: { isRemainingVestingPeriod: true },

      expiredCalculate: {
        isRemainingVestingPeriod: true
      }, // TODO
      min: 0,
      max: MAX_AGE,
      after: commonPrefixAfter.YRS,
      label: "Vesting Periods",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.VESTING_PERIOD.LABEL",
      labelCard: "Remaining Periods",
      labelI18nCard: "HOUSEHOLD.ASSETS.INVESTED.FORM.VESTING_PERIOD.LABEL.CARD"
    },
    stockPrice: {
      isRequired: true,
      isAmount: true,
      isNumber: true,
      label: "Stock Price",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.STOCK_PRICE.LABEL"
    },
    stockTicker: { ...repeatableSchemeGeneral.stockTicker }
  },
  [ENTITIES.ENTITY_ESPP]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.ESPP.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    stockTicker: { ...repeatableSchemeGeneral.stockTicker, isRequired: true },
    purchaseAmount: {
      ...repeatableSchemeGeneral.value,
      isMain: true,
      calculate: { isPurchaseAmount: true, field: "purchaseAmount" },
      labelCard: "Discounted Purchase",
      labelI18nCard:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.DISCOUNTED_PURCHASE.CARD.LABEL",
      label: "Purchase Amount",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PURCHASE_AMOUNT.LABEL"
    },
    offeringDate: {
      isAdditional: true, // calculate: { isDaysLeft: true, dateField: "offeringDate" }, // TODO calc offering date+6 months
      calculate: { isDaysLeft: true, dateField: "purchaseDate" },
      isRequired: true,
      isDate: true,
      labelCard: "Days To End of Offering",
      labelI18nCard:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.DAYS_TO_OFFERING_DATE.LABEL",
      label: "Offering Date",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.OFFERING_DATE.LABEL"
    },
    offeringStockPrice: {
      ...repeatableSchemeGeneral.value,
      label: "Offering Stock Price",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.OFFERING_STOCK_PRICE.LABEL"
    },
    purchaseDate: {
      // isAdditional: true, // TODO default offering date+6 months

      // calculate: { isInvestedOfferingDate: true, dateField: "offeringDate" },
      isRequired: true,
      isDate: true,
      label: "Purchase Date",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PURCHASE_DATE.LABEL",
      labelCard: "Days To End of Offering",
      labelI18nCard: "HOUSEHOLD.ASSETS.INVESTED.FORM.PURCHASE_DATE.LABEL.CARD"
    },
    discountPercent: {
      isRequired: true,
      default: "15",
      prefix: "%",
      isNumber: true,
      min: 0,
      isInt: true,
      label: "Discount Percent",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.DISCOUNT_PERCENT.LABEL"
    },
    lookBackPeriod: {
      isSelect: true,
      options: optionsLookBackPeriods,
      default: "6",
      label: "Look Back Period",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.LOOK_BACK_PERIOD.LABEL"
    }
  },
  [ENTITIES.ENTITY_MUTUAL_FUNDS]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.MUTUAL_FUNDS.DESCRIPTION.LABEL"
    },
    value: {
      ...repeatableSchemeGeneral.currentValue
    },
    purchaseValue: {
      ...repeatableSchemeGeneral.purchaseValue
    }
  },
  [ENTITIES.ENTITY_STOCKS]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.STOCKS.DESCRIPTION.LABEL"
    },
    value: {
      ...repeatableSchemeGeneral.currentValue
    },
    purchaseValue: {
      ...repeatableSchemeGeneral.purchaseValue
    }
  },
  [ENTITIES.ENTITY_TREASURIES]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.TREASURIES.DESCRIPTION.LABEL"
    },
    value: {
      ...repeatableSchemeGeneral.currentValue
    },
    purchaseValue: {
      ...repeatableSchemeGeneral.purchaseValue
    }
  },
  [ENTITIES.ENTITY_BUSINESS_INTEREST]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.BUSINESS_INTEREST.DESCRIPTION.LABEL"
    },
    investmentValue: {
      ...repeatableSchemeGeneral.value,
      isMain: true,
      label: "Investment Value",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.INVESTMENT_VALUE.LABEL"
    },
    annualInterestEarned: {
      prefix: "%",
      isRequired: true,
      isNumber: true,
      label: "Annual Interest Earned",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.ANNUAL_INTEREST_EARNED.LABEL"
    },
    annualPayment: {
      isOnlyShow: true,
      isAmount: true,
      isAdditional: true,
      calculate: { isAnnualInterestEarned: true },
      labelCard: "Annual Payment",
      labelI18nCard:
        "HOUSEHOLD.ASSETS.INVESTED.FORM.AMOUNT_EARNED.ANNUAL.LABEL.CARD"
    },
    taxCategory: { ...repeatableCommon.taxCategory, isAdditional: false }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.OTHER.DESCRIPTION.LABEL"
    },
    value: {
      ...repeatableSchemeGeneral.currentValue
    },
    purchaseValue: {
      ...repeatableSchemeGeneral.purchaseValue
    }
  },
  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.GENERAL.DESCRIPTION.LABEL"
    },
    value: { ...repeatableSchemeGeneral.value, isMain: true },
    taxCategory: { ...repeatableCommon.taxCategory }
  }
};

addAdditionalConfiguration(ENTITIES, ENTITIES_ADDITIONAL, GLOBAL_TYPE_USE_ASSETS);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED",
        title: "Investments"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.TITLE",
      title: "All of your saving, protection and retirement assets in one"
    },
    caption: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.CAPTION",
      title: "Add or edit use assets you have"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.EMPTY",
      title: "You have no assets, \nplease add your first asset"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.H2",
      title: "Saving & Retirement"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "APP.ASSET.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "APP.ASSET.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "APP.SUCCESSFULLY_SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.SIDE_MENU_TITLE",
      title: "Add Assets"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.SIDE_MENU_TITLE.EDIT",
      title: "Edit Asset"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM_GROUP.TITLE",
      title: "Asset Type"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.ASSET_TYPES.GENERAL_GROUP",
      title: "Group Of Assets",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Asset”",
      captionI18n: "HOUSEHOLD.ASSETS.INVESTED.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.VALUE.BUTTON.SAVE",
      title: "Save Asset"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.VALUE.BUTTON",
      title: "+ Add Asset"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.ASSETS.INVESTED.WHY.TITLE",
    textI18n: "HOUSEHOLD.ASSETS.INVESTED.WHY.ALL_TEXT"
  }
};
