import React from "react";

import { colors } from "constants/styles";

const IconQuizSpouseWork = ({
  width = 40,
  height = 86,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 86"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <circle
        cx="20"
        cy="14"
        r="12"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M20 27H14C7.37258 27 2 32.3726 2 39V55C2 58.3137 4.68629 61 8 61H11V78C11 81.3137 13.6863 84 17 84H23C26.3137 84 29 81.3137 29 78V61H32C35.3137 61 38 58.3137 38 55V39C38 32.3726 32.6274 27 26 27H20Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <circle
        cx="20"
        cy="14"
        r="9"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 53V45C4 39.3995 4 36.5992 5.08993 34.4601C6.04867 32.5785 7.57847 31.0487 9.46009 30.0899C11.5992 29 14.3995 29 20 29C25.6005 29 28.4008 29 30.5399 30.0899C32.4215 31.0487 33.9513 32.5785 34.9101 34.4601C36 36.5992 36 39.3995 36 45V53C36 54.8638 36 55.7956 35.6955 56.5307C35.2895 57.5108 34.5108 58.2895 33.5307 58.6955C32.7956 59 31.8638 59 30 59C29.0681 59 28.6022 59 28.2346 59.1522C27.7446 59.3552 27.3552 59.7446 27.1522 60.2346C27 60.6022 27 61.0681 27 62V75C27 77.8089 27 79.2134 26.3259 80.2223C26.034 80.659 25.659 81.034 25.2223 81.3259C24.2134 82 22.8089 82 20 82C17.1911 82 15.7866 82 14.7777 81.3259C14.341 81.034 13.966 80.659 13.6741 80.2223C13 79.2134 13 77.8089 13 75V62C13 61.0681 13 60.6022 12.8478 60.2346C12.6448 59.7446 12.2554 59.3552 11.7654 59.1522C11.3978 59 10.9319 59 10 59C8.13623 59 7.20435 59 6.46927 58.6955C5.48915 58.2895 4.71046 57.5108 4.30448 56.5307C4 55.7956 4 54.8638 4 53Z"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconQuizSpouseWork;
