import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl-v3";

import "./AppBarLink.scss";

const AppBarLink = props => {
  const { to, i18nStringId, defaultMessage } = props;

  return (
    <NavLink to={to} className="app-bar-link">
      <span className="app-bar-link__title">
        <FormattedMessage id={i18nStringId} defaultMessage={defaultMessage} />
      </span>

      <span className="mark" />
    </NavLink>
  );
};

AppBarLink.propTypes = {
  classes: PropTypes.object,
  to: PropTypes.string,
  i18nStringId: PropTypes.string,
  defaultMessage: PropTypes.string
};

export default AppBarLink;
