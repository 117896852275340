import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import IconContainer from "components/_common/IconContainer";
import * as Icons from "components/_common/icons";

import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl-v3";

import differenceInYears from "date-fns/differenceInCalendarYears";
import differenceInMonths from "date-fns/differenceInCalendarMonths";
import differenceInDays from "date-fns/differenceInCalendarDays";
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";

import formatDate from "lib/formatWLocale";
import {
  formatMoney,
  formatMoneyInt,
  formatMoneyIntWithSign,
  trimString
} from "lib/helpers";

import { colors } from "constants/styles";
import "./AssetCard.scss";
import { NavLink } from "react-router-dom";
import { optionsOccursEvery } from "../../HouseholdMoneyIn/formScheme";
import { optionsRepaymentValues } from "../../HouseholdDebt/formScheme";
import { optionsValuesMaturity } from "../../HouseholdAccounts/formScheme";
import {
  defaultHomeTaxRate,
  optionsValuesHome,
  optionsValuesHomeTaxType
} from "../../HouseholdAssetsUse/formScheme";
import { taxPropertyPurchaseMult } from "../../../constants/taxesAndDashboard";

function isFloat(n) {
  return n % 1 !== 0;
}

const styles = theme => ({
  button: {
    padding: "6px",
    borderRadius: "4px",

    "root:hover": {
      padding: "6px",
      borderRadius: "4px"
    },
    root: {
      padding: "6px",
      borderRadius: "4px"
    }
  }
});

class AssetCard extends PureComponent {
  static propTypes = {
    asset: PropTypes.object,
    handleEditAsset: PropTypes.func,
    handleDeleteAsset: PropTypes.func,
    handleToggleWhatIfAsset: PropTypes.func,
    handleToggleDisableAsset: PropTypes.func,
    handleDuplicateAsset: PropTypes.func,
    handleSelectAsset: PropTypes.func,
    designScheme: PropTypes.object,
    formScheme: PropTypes.object,
    designSchemeAssetDebt: PropTypes.object,
    formSchemeAdditional: PropTypes.object,
    formSchemeAssetDebt: PropTypes.object,
    isMobile: PropTypes.bool,
    isEvent: PropTypes.bool,
    isAccessEdit: PropTypes.bool,
    isAllWhatIfMode: PropTypes.bool,
    isWizardFinished: PropTypes.bool,

    additionalInfo: PropTypes.object,
    additionalAssets: PropTypes.object,
    currentAge: PropTypes.any,
    calcAge: PropTypes.any,
    isSelected: PropTypes.bool,
    containerWidth: PropTypes.number
  };

  state = {
    anchorEl: null,
    isExpanded: false,
    height: null,
    width: null,
    heightDefault: null,
    heightDefaultC: null,
    widthDefault: null
  };

  componentDidMount() {
    if (this.cardRef && this.cardRef.current) {
      const widthDefault = parseFloat(
        getComputedStyle(this.cardRef.current).width
      );
      const heightDefaultC = parseFloat(
        getComputedStyle(this.cardRef.current).height
      );

      this.setState({
        widthDefault,
        heightDefaultC
      });
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (this.props.containerWidth !== prevProps.containerWidth) {
      const widthDefault = parseFloat(
        getComputedStyle(this.cardRef.current).width
      );
      const heightDefaultC = parseFloat(
        getComputedStyle(this.cardRef.current).height
      );

      const newState = {
        widthDefault,
        heightDefaultC
      };

      this.setState(newState);
    }

    if (!prevState.isExpanded && this.state.isExpanded) {
      const currentTarget = this.cardRef.current;

      const wrapperEl = currentTarget.querySelector(".asset-card__wrapper");
      const wrapperElWidth = parseFloat(getComputedStyle(wrapperEl).width);
      const wrapperElHeight = parseFloat(getComputedStyle(wrapperEl).height);

      const topEl = currentTarget.querySelector(".asset-card__top");
      const topElHeight = parseFloat(getComputedStyle(topEl).height);
      const mainEl = currentTarget.querySelector(".asset-card__fields--main");
      const mainElHeight = parseFloat(getComputedStyle(mainEl).height);

      const moreEl = currentTarget.querySelector(".asset-card__more");
      const moreElWidth = parseFloat(getComputedStyle(moreEl).width);
      const moreElHeight = parseFloat(getComputedStyle(moreEl).height);

      const wrapperDimension = {
        width: wrapperElWidth ? wrapperElWidth : 0,
        height: wrapperElHeight ? wrapperElHeight : 0
      };

      const moreDimension = {
        width: moreElWidth ? moreElWidth : 0,
        height: moreElHeight ? moreElHeight : 0
      };

      // this.height = wrapperDimension.height + moreDimension.height;
      this.height = topElHeight + moreDimension.height + mainElHeight + 32;

      this.setState({
        height: this.height,
        width: wrapperDimension.width + 20,
        isExpanded: true
      });
    }
  }

  cardRef = React.createRef();
  containerRef = React.createRef();
  moreRef = React.createRef();
  dropdownRef = React.createRef();

  waitingHover = false;
  height = null;

  handleClickDropDown = ev => {
    ev.stopPropagation();

    this.setState({ anchorEl: ev.currentTarget });
  };

  handleCloseDropDown = handle => ev => {
    ev.stopPropagation();

    this.setState({
      anchorEl: null,
      height: null,
      width: null,
      heightDefault: null,
      isExpanded: false
    });
    if (handle) {
      handle();
    }
  };

  handleOnHoverDeb = ev => {
    const { currentTarget } = ev;
    this.waitingHover = true;
    setTimeout(this.handleOnHover, 150, { currentTarget });
  };

  handleOnHover = ev => {
    const { isMobile } = this.props;

    if (isMobile) {
      return;
    }

    if (this.state.height && this.state.width) {
      return;
    }

    const { currentTarget } = ev;

    const wrapperEl = currentTarget.querySelector(".asset-card__wrapper");

    const wrapperDimension = {
      width: wrapperEl ? wrapperEl.offsetWidth : 0,
      height: wrapperEl ? wrapperEl.offsetHeight : 0
    };

    this.height = wrapperDimension.height + 20;

    this.setState({
      height: this.height,
      width: wrapperDimension.width + 20,
      isExpanded: true
    });
  };

  handleOnHoverOut = ev => {
    const { isMobile, asset } = this.props;
    if (isMobile) {
      return;
    }

    const { relatedTarget } = ev;

    const dropdownMenuEl = document.getElementById(`simple-menu-${asset.id}`);

    const isInMenuDropDown =
      dropdownMenuEl && dropdownMenuEl.contains(relatedTarget);
    const isInCard = this.cardRef.current.contains(relatedTarget);

    if (!isInCard && !isInMenuDropDown) {
      this.setState({
        height: null,
        width: null,
        heightDefault: null,
        isExpanded: false
      });
    }
  };

  handleToggleExpand = () => {
    if (this.props.isMobile) {
      this.setState(prevState => ({
        isExpanded: !prevState.isExpanded
      }));
    } else {
      this.props.handleEditAsset();
    }
  };

  _renderDropDown() {
    const { anchorEl } = this.state;

    const {
      asset,
      classes,
      handleEditAsset,
      handleDeleteAsset,
      handleDuplicateAsset,
      handleToggleWhatIfAsset,
      handleToggleDisableAsset,
      isAccessEdit,
      isWizardFinished
    } = this.props;

    return (
      <div ref={this.dropdownRef}>
        <IconButton
          className={classes.button}
          aria-label="More"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClickDropDown}
        >
          <Icons.IconDotsHorizontal opacity={0.6} />
        </IconButton>
        <Menu
          id={`simple-menu-${asset.id}`}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          aria-haspopup={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseDropDown()}
          disableAutoFocusItem
        >
          <MenuItem onClick={this.handleCloseDropDown(handleEditAsset)}>
            {isAccessEdit || !isWizardFinished ? (
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.EDIT"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.VIEW"
                defaultMessage="View"
              />
            )}
          </MenuItem>
          {isAccessEdit && !!handleToggleWhatIfAsset && (
            <MenuItem
              onClick={this.handleCloseDropDown(handleToggleWhatIfAsset)}
              focusVisibleClassName={""}
            >
              {!!asset.isWhatIf ? (
                <FormattedMessage
                  id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.WHAT_IF.DISABLE"
                  defaultMessage="Standard"
                />
              ) : (
                <FormattedMessage
                  id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.WHAT_IF.ENABLE"
                  defaultMessage="What-If"
                />
              )}
            </MenuItem>
          )}

          {isAccessEdit && !!handleToggleDisableAsset && (
            <MenuItem
              onClick={this.handleCloseDropDown(handleToggleDisableAsset)}
              focusVisibleClassName={""}
            >
              {!!asset.isDisabled ? (
                <FormattedMessage
                  id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.ENABLE"
                  defaultMessage="Enable"
                />
              ) : (
                <FormattedMessage
                  id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.DISABLE"
                  defaultMessage="Disable"
                />
              )}
            </MenuItem>
          )}

          {isAccessEdit && handleDuplicateAsset && (
            <MenuItem onClick={this.handleCloseDropDown(handleDuplicateAsset)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.DUPLICATE"
                defaultMessage="Duplicate"
              />
            </MenuItem>
          )}
          {isAccessEdit && (
            <MenuItem onClick={this.handleCloseDropDown(handleDeleteAsset)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.ERASE"
                defaultMessage="Erase"
              />
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }

  // deprecated
  getStyleContainer = () => {
    const { isMobile } = this.props;

    return !isMobile && this.state.heightDefaultC && this.state.isExpanded
      ? {
          height: this.state.heightDefaultC + 16,
          width: this.state.widthDefault
          // flexGrow: "unset"
        }
      : {};
  };

  getStyleWrapper = () => {
    const { isMobile, isEvent } = this.props;

    if (!isMobile && this.state.height && this.state.isExpanded) {
      return {
        height: this.state.height,
        // width: this.state.widthDefault - 16
        width: isEvent ? 270 : this.state.widthDefault
      };
    }

    return {};
  };

  getIconContainer = attributeTitle => {
    const { asset, designScheme, isAllWhatIfMode } = this.props;

    const Icon =
      (asset.isDisabled && Icons.IconDisabled) ||
      (!isAllWhatIfMode && asset.isWhatIf && Icons.IconHelpCircle) ||
      // custom for use assets multiple home or vehicle
      (designScheme &&
        designScheme.iconMulti &&
        asset.type === "multiple" &&
        Icons[designScheme.iconMulti]) ||
      (designScheme && designScheme.icon && Icons[designScheme.icon]) ||
      Icons.IconOther;

    const iconColor =
      (isAllWhatIfMode && "ui") ||
      (asset.isDisabled && "textTetrieary") ||
      (asset.isWhatIf && "yellow") ||
      (asset.isWizard && "brand") ||
      (designScheme && designScheme.color ? designScheme.color : "ui");

    return (
      <IconContainer color={iconColor}>
        <Icon
          width={24}
          height={24}
          color={iconColor}
          iconTitle={attributeTitle}
        />
      </IconContainer>
    );
  };

  getAttributeTitle = asset => {
    const { intl } = this.props;

    let attributeTitle = "";
    if (asset.isWhatIf) {
      attributeTitle = intl.formatMessage({
        id: "APP.WHAT_IF",
        defaultMessage: "What-If"
      });
    }

    if (asset.isDisabled) {
      attributeTitle = intl.formatMessage({
        id: "APP.DISABLED",
        defaultMessage: "Disabled"
      });
    }

    if (asset.isDisabled) {
      attributeTitle = intl.formatMessage({
        id: "APP.WIZARD",
        defaultMessage: "Wizard"
      });
    }

    return attributeTitle;
  };

  getTitle = () => {
    const { intl } = this.props;

    const {
      asset,
      designScheme,
      formScheme,
      additionalAssets,
      isAllWhatIfMode
    } = this.props;

    let labelIntl = intl.formatMessage({
      id: designScheme.titleI18nCard || designScheme.titleI18n,
      defaultMessage: designScheme.titleCard || designScheme.title
    });

    // TODO temp
    const expired = asset.isExpired ? (
      <span style={{ color: colors.red }}>Exp</span>
    ) : null;

    let titleEl = "";
    let titleElSubTitle = "";
    Object.keys(formScheme).forEach(schemeKey => {
      if (formScheme[schemeKey].isSubTitle) {
        const isAdditionalAsset = formScheme[schemeKey].isAdditionalAsset;
        const additionalAssetField = formScheme[schemeKey].additionalAssetField;

        const dsc =
          isAdditionalAsset &&
          additionalAssets[isAdditionalAsset] &&
          additionalAssets[isAdditionalAsset][asset[schemeKey]] &&
          additionalAssets[isAdditionalAsset][asset[schemeKey]][
            additionalAssetField
          ]
            ? additionalAssets[isAdditionalAsset][asset[schemeKey]][
                additionalAssetField
              ]
            : formScheme[schemeKey].additionalAssetField
            ? formScheme[schemeKey].default || ""
            : asset[schemeKey] || formScheme[schemeKey].default;

        titleElSubTitle = (
          <div className="asset-card__title-dsc">
            {expired} {dsc}
          </div>
        );
      }

      if (
        formScheme[schemeKey].isTitle ||
        formScheme[schemeKey].isPrefixTitle
      ) {
        let tempTitle = "";
        if (formScheme[schemeKey].isSelect) {
          const indexOpt = formScheme[schemeKey].options.findIndex(
            el => el.value === asset[schemeKey]
          );

          if (indexOpt !== -1) {
            const labelName =
              formScheme[schemeKey].options[indexOpt].title ||
              formScheme[schemeKey].options[indexOpt].name;
            const labelNameI18n =
              formScheme[schemeKey].options[indexOpt].titleI18n ||
              formScheme[schemeKey].options[indexOpt].nameI18n;

            tempTitle = (
              <FormattedMessage id={labelNameI18n} defaultMessage={labelName} />
            );

            if (formScheme[schemeKey].isPrefixTitle) {
              tempTitle = (
                <>
                  {tempTitle} {labelIntl}
                </>
              );
            }
          }
        } else {
          tempTitle = asset[schemeKey];
        }

        titleEl = tempTitle;
      }
    });

    if (!titleEl) {
      titleEl = labelIntl;
    }

    if (titleElSubTitle) {
      titleEl = (
        <>
          <div className="asset-card__sub-title">
            {titleEl}{" "}
            {!isAllWhatIfMode && asset.isWhatIf && (
              <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-If" />
            )}
          </div>
          <div className="asset-card__title-dsc">{titleElSubTitle}</div>
        </>
      );
    } else if (asset.isWhatIf) {
      titleEl = (
        <span>
          {titleEl}
          {` `}
          <FormattedMessage id="APP.WHAT_IF" defaultMessage="What-If" />
        </span>
      );
    }

    return titleEl;
  };

  render() {
    const {
      asset,
      designScheme,
      formScheme,
      formSchemeDebt,
      formSchemeAdditional,
      additionalAssets,
      isMobile,
      currentAge,
      calcAge,
      isAllWhatIfMode
    } = this.props;

    if (!asset) {
      return null;
    }

    const titleEl = this.getTitle();
    const attributeTitle = this.getAttributeTitle(asset);

    return (
      <section
        ref={this.cardRef}
        className={`asset-card ${
          this.state.isExpanded && !isMobile ? "asset-card--selected" : ""
        } ${
          this.state.isExpanded && isMobile ? "asset-card--selected-mobile" : ""
        } ${asset.isDisabled ? "asset-card--disabled" : ""} ${
          !isAllWhatIfMode && asset.isWhatIf ? "asset-card--what-if" : ""
        } `}
        onMouseOver={this.handleOnHover}
        onMouseOut={this.handleOnHoverOut}
        style={
          !isMobile && this.state.isExpanded
            ? { height: this.state.heightDefaultC }
            : {}
        }
      >
        {asset.isWizard && (
          <div className="asset-card__top-label">
            <Icons.IconClockArrow width={16} height={18} color="white" />
            <FormattedMessage id="APP.WIZARD" defaultMessage="Wizard" />
          </div>
        )}

        {!!asset.isEndingSoon && !asset.isExpired && (
          <div className="asset-card__top-label asset-card__top-label--warn">
            <Icons.IconAlertTile width={16} height={18} color="white" />

            {asset.maturity &&
            asset.maturity === optionsValuesMaturity.RENEW ? (
              <FormattedMessage
                id="APP.RENEWING_SOON"
                defaultMessage="Renewing soon"
              />
            ) : (
              <FormattedMessage
                id="APP.ENDING_SOON"
                defaultMessage="Ending soon"
              />
            )}
          </div>
        )}
        <div
          className="asset-card__wrapper"
          ref={this.containerRef}
          style={this.getStyleWrapper()}
          onClick={this.handleToggleExpand}
        >
          <div className="asset-card__top" title={attributeTitle}>
            {asset.isExpired && (
              <div className="asset-card__top-alert">
                <Icons.IconAlertTile width={18} height={18} color="yellow" />
              </div>
            )}

            {this.getIconContainer(attributeTitle)}

            <div className="asset-card__title">{titleEl}</div>

            <div className="asset-card__dropdown">{this._renderDropDown()}</div>
          </div>

          <div className="asset-card__content">
            <AssetContentUniversal
              asset={asset}
              additionalAssets={additionalAssets}
              formScheme={formScheme}
              designScheme={designScheme}
              formSchemeDebt={formSchemeDebt}
              formSchemeAdditional={formSchemeAdditional}
              moreRef={this.moreRef}
              currentAge={currentAge}
              calcAge={calcAge}
            />
          </div>
        </div>
      </section>
    );
  }
}

const AssetContentUniversal = ({
  asset,
  formScheme,
  designScheme,
  formSchemeDebt,
  formSchemeAdditional,
  moreRef,
  currentAge,
  calcAge,
  additionalAssets
}) => {
  const makeFields = (
    isMain,
    valueDef,
    fieldKey,
    { fieldScheme = {}, isOptional = false, additionalKey = "" } = {}
  ) => {
    let value = valueDef;

    let label = fieldScheme.label;

    if (fieldScheme.labelI18nCard || fieldScheme.labelI18n) {
      label = (
        <FormattedMessage
          id={fieldScheme.labelI18nCard || fieldScheme.labelI18n}
          defaultMessage={fieldScheme.labelCard || fieldScheme.label}
        />
      );
    }

    if (isMain && !(fieldScheme.isMain || fieldScheme.isMainCondition)) {
      return null;
    }

    if (
      !isMain &&
      !(fieldScheme.isAdditional || fieldScheme.isAdditionalCondition)
    ) {
      return null;
    }

    if (
      !value &&
      !fieldScheme.isOnlyShow &&
      !(fieldScheme.isAmount && !isOptional)
    ) {
      return null;
    }

    const isConditionAccept = checkAllCondition(asset, fieldScheme, isMain);

    if (fieldScheme.isHidden || !isConditionAccept) {
      return null;
    }

    // calculateIf deprecated
    if (fieldScheme.calculate || fieldScheme.calculateIf) {
      let calculatedValue;

      if (fieldScheme.calculate.isUseHomeEstimatedTax) {
        calculatedValue = calculateUseHomeEstimatedTax(
          asset,
          fieldScheme.calculate
        );
      }

      if (fieldScheme.calculate.isMonthsLeft) {
        calculatedValue = calculateMonthsLeft(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isMOutOtherAnnual) {
        calculatedValue = calculateMOutOther(
          asset,
          fieldScheme.calculate,
          fieldKey
        );
      }

      if (fieldScheme.calculate.isMonthsLeftPeriod) {
        calculatedValue = calculateMonthsLeftPeriod(
          asset,
          fieldScheme.calculate,
          fieldKey
        );
      }

      if (fieldScheme.calculate.isYearsLeftPeriod) {
        calculatedValue = calculateYearsLeftPeriod(
          asset,
          fieldScheme.calculate,
          fieldKey,
          {
            endingSoonCalculate: fieldScheme.endingSoonCalculate
          }
        );
      }

      if (
        fieldScheme.endingSoonCalculate &&
        fieldScheme.endingSoonCalculate.MoneyOutYears
      ) {
        calculatedValue = calcYearsLeftPeriodEndingSoonOut(
          asset,
          fieldScheme.calculate,
          fieldKey,
          {
            endingSoonCalculate: fieldScheme.endingSoonCalculate
          }
        );
      }

      if (fieldScheme.calculate.isDaysLeft) {
        calculatedValue = calculateDaysLeft(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isAnnuitySum) {
        calculatedValue = calculateAnnuitySum(asset);
      }

      if (fieldScheme.calculate.isPurchaseAmount) {
        calculatedValue = calculateInvestedPurchAmount(
          asset,
          fieldScheme.calculate
        );
      }

      if (fieldScheme.calculate.isInvestedOfferingDate) {
        calculatedValue = calculateInvestedOfferingDate(
          asset,
          fieldScheme.calculate
        );
      }

      if (fieldScheme.calculate.isFromAgeToAge) {
        calculatedValue = calculateFromAgeToAge(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isAmountEarned) {
        calculatedValue = calculateInvestedAmountEarned(asset);
      }

      if (fieldScheme.calculate.isAnnualInterestEarned) {
        calculatedValue = calculateAnnualInterestEarned(asset);
      }

      if (fieldScheme.calculate.isAwardDate) {
        calculatedValue = calculateAwardDate(asset);
      }

      if (fieldScheme.calculate.isRemainingVestingPeriod) {
        calculatedValue = calculateRemainingVestingPeriod(asset);
      }

      if (fieldScheme.calculate.isUnvestedEstimate) {
        calculatedValue = calculateUnvestedEstimate(asset);
      }

      if (fieldScheme.calculate.isDebtRemainingFixed) {
        calculatedValue = calculateDebtRemainingFixed(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtRemainingMonthly) {
        calculatedValue = calculateDebtRemainingMonthly(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtAnnualPayMonthly) {
        calculatedValue = calculateDebtRemainingFixed(
          asset,
          fieldScheme.calculate.inner
        );
      }
      if (fieldScheme.calculate.isDebtAnnualAccrual) {
        calculatedValue = calculateDebtAnnualAccrual(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtRemainingAmortizationInterest) {
        calculatedValue = calculateDebtRemainingAmortizationInterest(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtRemainingAmortization) {
        calculatedValue = calculateDebtRemainingAmortization(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtAnnualPayFixed) {
        calculatedValue = calculateDebtAnnualPayFixed(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtAnnualPayMonthly) {
        // TODO
        calculatedValue = calculateDebtAnnualPayFixed(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isDebtAnnualPayAmortization) {
        calculatedValue = calculateDebtAnnualPayAmortization(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isAnnualPaymentDebt) {
        calculatedValue = calculateDebtAnnualPay(
          asset,
          fieldScheme.calculate.inner
        );
      }

      if (fieldScheme.calculate.isEarningsIn) {
        calculatedValue = calculateMoneyInEarning(asset);
      }

      if (fieldScheme.calculate.isAnnualRate) {
        calculatedValue = calculateAnnualRent(asset);
      }

      if (fieldScheme.calculate.isAnnualMoneyInOther) {
        calculatedValue = calculateAnnualMoneyInOther(asset);
      }

      if (fieldScheme.calculate.isEventVariable) {
        let relatedAsset;

        if (asset.assetId) {
          relatedAsset =
            additionalAssets && additionalAssets.assetsAll
              ? additionalAssets.assetsAll.find(el => el.id === asset.assetId)
              : null;
        }

        calculatedValue = calculateEventVariable(asset, {
          relatedAsset,
          currentAge,
          calcAge
        });
      }

      if (fieldScheme.calculate.isEventAmount) {
        calculatedValue = calculateEventAmount(asset, {
          currentAge,
          calcAge
        });
      }

      value = typeof calculatedValue !== "undefined" ? calculatedValue : value;
    }

    if (fieldScheme.isSelect && !fieldScheme.isAdditionalAsset) {
      const found = fieldScheme.options.find(item => item.value === value);

      if (found && found.nameI18n) {
        value = (
          <FormattedMessage id={found.nameI18n} defaultMessage={found.name} />
        );
      }
    }

    if (fieldScheme.isShowDate) {
      if (value && value.seconds) {
        value = formatDate(value.seconds * 1000, fieldScheme.isShowDate);
      }
    }

    if (fieldScheme.isAdditionalAsset) {
      const isAdditionalAsset = fieldScheme.isAdditionalAsset;
      const additionalAssetField = fieldScheme.additionalAssetField;

      value =
        isAdditionalAsset &&
        additionalAssets &&
        additionalAssets[isAdditionalAsset] &&
        additionalAssets[isAdditionalAsset][
          asset[fieldScheme.fieldCustom || "asset"]
        ] &&
        additionalAssets[isAdditionalAsset][
          asset[fieldScheme.fieldCustom || "asset"]
        ][additionalAssetField]
          ? trimString(
              additionalAssets[isAdditionalAsset][
                asset[fieldScheme.fieldCustom || "asset"]
              ][additionalAssetField],
              100
            )
          : ""; //asset[fieldKey];

      // TODO waiting
      if (!value) {
        return null;
      }
    }

    let valueShow =
      fieldScheme.isAmount && !fieldScheme.calculate
        ? isFloat(value)
          ? formatMoney(value, 2)
          : formatMoney(value, 0)
        : value;

    valueShow = fieldScheme.prefix
      ? `${fieldScheme.prefix}${valueShow}`
      : valueShow;

    if (fieldScheme.isGroupLabel) {
      if (fieldKey === "group401K" && (!!asset.self || !!asset.employer)) {
        return null;
      }

      return (
        <div
          className={
            "asset-card__field asset-card__field--full-width asset-card__field--group-label"
          }
          key={additionalKey + fieldKey}
        >
          {label}
        </div>
      );
    }

    if (fieldScheme.isShowDate && value && value.seconds) {
      valueShow = formatDate(value.seconds * 1000, fieldScheme.isShowDate);
    }

    let formattedValue = (
      <span>
        {fieldScheme.isAmount && <span>$</span>}
        {valueShow}
        {` `}
        {fieldScheme.after}
      </span>
    );

    if (fieldScheme.isCardLink) {
      formattedValue = (
        <NavLink to={fieldScheme.isCardLink}>{formattedValue}</NavLink>
      );
    }

    // TODO formattedMessage label
    return (
      <div
        className={`asset-card__field 
        ${fieldScheme.isFullWidth ? "asset-card__field--full-width" : ""} 
        ${fieldScheme.isThirdWidth ? "asset-card__field--third-width" : ""}
        `}
        key={additionalKey + fieldKey}
      >
        <div className="asset-card__label">{label}</div>

        <div
          className={`asset-card__value ${
            fieldScheme.isSecondary ? "asset-card__value--secondary" : ""
          }`}
        >
          {formattedValue}
        </div>
      </div>
    );
  };

  const mainFields = Object.keys(formScheme).map(fieldKey => {
    const value = asset[fieldKey];

    return makeFields(true, value, fieldKey, {
      fieldScheme: formScheme[fieldKey]
    });
  });

  let mainFieldsDebt = [];

  const additionalFields = Object.keys(formScheme).map(fieldKey => {
    const value = asset[fieldKey];

    return makeFields(false, value, fieldKey, {
      fieldScheme: formScheme[fieldKey]
    });
  });

  let additionalFieldsDebt = [];
  if (formSchemeAdditional) {
    Object.keys(formSchemeAdditional).forEach(key => {
      Object.keys(formSchemeAdditional[key]).forEach(fieldKey => {
        const fieldScheme = formSchemeAdditional[key][fieldKey];

        const value = asset[key] ? asset[key][fieldKey] : "";

        additionalFieldsDebt.push(
          makeFields(false, value, fieldKey, {
            fieldScheme,
            isOptional: true,
            additionalKey: key
          })
        );

        mainFieldsDebt.push(makeFields(true, value, fieldKey, { fieldScheme }));
      });
    });
  }

  return (
    <>
      <div className="asset-card__fields asset-card__fields--main">
        {[...mainFields, ...mainFieldsDebt]}
      </div>

      <div className="asset-card__more " ref={moreRef}>
        <div className="asset-card__fields">
          {[...additionalFields, ...additionalFieldsDebt]}
        </div>
      </div>
    </>
  );
};

function checkAllCondition(asset, fieldScheme, isMain) {
  let isConditionAccept = true;

  if (fieldScheme.condition) {
    fieldScheme.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  if (
    fieldScheme.isMainCondition &&
    fieldScheme.isMainCondition.condition &&
    isMain
  ) {
    fieldScheme.isMainCondition.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  if (
    fieldScheme.isAdditionalCondition &&
    fieldScheme.isAdditionalCondition.condition &&
    !isMain
  ) {
    fieldScheme.isAdditionalCondition.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  return isConditionAccept;
}

function checkCondition(asset, condition) {
  let isConditionAccept = true;

  let assetField;
  if ((condition.fieldDebt || condition.inner) && asset[condition.inner]) {
    assetField = asset[condition.inner][condition.fieldDebt || condition.field];
  } else {
    assetField = asset[condition.field];
  }

  if (condition.isFilled) {
    isConditionAccept = !!assetField;
  }

  if (condition.isNotFilled) {
    isConditionAccept = !assetField;
  }

  if (condition.value) {
    // eslint-disable-next-line
    isConditionAccept = assetField == condition.value;
  }

  if (condition.valueIn) {
    // eslint-disable-next-line
    isConditionAccept = condition.valueIn.indexOf(assetField) !== -1;
  }

  return isConditionAccept;
}

export function calculateMonthsLeftPeriod(
  asset,
  calculate,
  fieldKey,
  { calcDate = Date.now() } = {}
) {
  const fieldDate = asset[calculate.fieldDate];

  if (fieldDate && fieldDate.seconds && asset[fieldKey]) {
    const monthsDiff = differenceInMonths(calcDate, fieldDate.toDate());
    const monthsLeft = asset[fieldKey] - monthsDiff;

    const endingDate = addMonths(fieldDate.toDate(), asset[fieldKey]);
    const daysDiff = differenceInDays(endingDate, calcDate);

    if (daysDiff > 0 && daysDiff < 30) {
      return "<1";
    }

    if (monthsLeft <= 0) {
      return "0";
    }

    return monthsLeft;
  }
}

function calculateYearsLeftPeriod(
  asset,
  calculate,
  fieldKey,
  { calcDate = Date.now(), endingSoonCalculate } = {}
) {
  const fieldDate = asset[calculate.fieldDate];

  if (fieldDate && fieldDate.seconds && asset[fieldKey]) {
    const yearsDiff = differenceInYears(calcDate, fieldDate.toDate());

    const yearsLeft = asset[fieldKey] - yearsDiff;

    if (yearsLeft <= 0) {
      return "-";
    }

    return yearsLeft;
  }
}
function calcYearsLeftPeriodEndingSoonOut(
  asset,
  calculate,
  fieldKey,
  { calcDate = Date.now(), endingSoonCalculate } = {}
) {
  const fieldDate = asset[endingSoonCalculate.fieldDate];

  if (fieldDate && fieldDate.seconds && asset[fieldKey]) {
    const endingDate = addYears(fieldDate.toDate(), asset.durationYears);

    // const yearsLeft = asset[fieldKey] - yearsDiff;
    const monthsDiff = differenceInMonths(endingDate, calcDate);

    if (monthsDiff <= 0) {
      return "-";
    }
  }
}

function calculateMOutOther(asset, calculate) {
  if (
    asset.isExpired &&
    asset.maturity === optionsValuesMaturity.RENEW &&
    asset.contractStartDate &&
    asset.contractStartDate.seconds
  ) {
    // If duration remaining=0 and maturity=”renew”
    // then annual cost*(1+annual change/100)^(floor(month since start date/12))

    const monthsPassed = differenceInMonths(
      Date.now(),
      asset.contractStartDate.toDate()
    );

    const annualChange =
      asset && asset.configuration && asset.configuration.annualChange
        ? 1 + asset.configuration.annualChange / 100
        : 1;

    const annualCost =
      asset.annualCost * Math.pow(annualChange, Math.floor(monthsPassed / 12));

    return formatMoneyInt(annualCost);
  }

  return formatMoneyInt(asset.annualCost);
}

//isUseHomeEstimatedTax
export function _helperCalculateUseHomeEstimatedTax(
  asset,
  calculate,
  { calcDate = Date.now() } = {}
) {
  let taxRate = 0;

  if (asset.configuration) {
    taxRate = +asset.configuration.taxRate || 0;

    if (!asset.configuration.hasOwnProperty("taxType")) {
      taxRate = defaultHomeTaxRate;
    }

    // Estimated tax: Tax amount if exist,
    if (asset.configuration.taxType === optionsValuesHomeTaxType.AMOUNT) {
      return +asset.configuration.taxAmount || 0;
    }

    //  otherwise assessed value*tax rate/100,
    const countyAssessedValue = +asset.countyAssessedValue;
    if (countyAssessedValue && taxRate) {
      return countyAssessedValue * (taxRate / 100);
    }
  }

  // otherwise if assessed value doesn’t exist then
  //    MIN(purchase price*(1.02)^(years passed since purchase date), current value)*tax rate/100
  const currentValue = +asset.value;
  const purchasePrice = +asset.purchasePrice;
  const yearsDiff =
    asset.purchaseDate && asset.purchaseDate.seconds
      ? differenceInYears(calcDate, asset.purchaseDate.toDate())
      : 0;

  // In the case of multiple homes without purchase date, use original purchase price*tax.
  const purchasePriceMult =
    optionsValuesHome.MULTIPLE === asset.type
      ? purchasePrice
      : purchasePrice * Math.pow(taxPropertyPurchaseMult, yearsDiff);
  const res = Math.min(purchasePriceMult, currentValue) * (taxRate / 100);

  return res;
}

function calculateUseHomeEstimatedTax(asset, calculate) {
  //Estimated tax: Tax amount if exist

  const result = +_helperCalculateUseHomeEstimatedTax(asset, calculate);

  return result.toFixed(0);
}

function calculateMonthsLeft(asset, calculate) {
  let value;
  let field;
  if (calculate.dateFieldDebt) {
    field = asset[calculate.inner][calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const monthsLeft = differenceInMonths(field.seconds * 1000, Date.now());

  if (monthsLeft < 0) {
    return (
      <FormattedMessage
        id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.PAYMENTS_ENDED"
        defaultMessage="Payments Ended"
      />
    );
  }

  if (monthsLeft < 1) {
    return (
      <FormattedMessage
        id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.CONTRACT_DATE.LESS_MONTH"
        defaultMessage="Less Than Month"
      />
    );
  }

  value = monthsLeft;

  return value;
}

function calculateAnnuitySum(asset) {
  const value = +asset.value || 0;
  const interestValue = +asset.interestValue || 0;

  return formatMoneyIntWithSign(interestValue + value, {
    isNotShowCurrency: true
  });
}

function calculateDaysLeft(asset, calculate) {
  let value;
  let field;

  if (calculate.dateFieldDebt) {
    field = asset[calculate.inner][calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const daysLeft = differenceInDays(field.seconds * 1000, Date.now());

  if (daysLeft < 1) {
    return <span>-</span>;
  }

  value = (
    <>
      {daysLeft}
      {` `}
      {daysLeft === 1 ? (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAY_LEFT"
          defaultMessage="Day Left"
        />
      ) : (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAYS_LEFT"
          defaultMessage="Days Left"
        />
      )}
    </>
  );

  return value;
}

export function calculateInvestedPurchAmountClean(asset, calculate) {
  const FACTOR = (100 + +asset.discountPercent) / 100;
  let field;

  if (calculate && calculate.fieldDebt) {
    field = asset[calculate.inner][calculate.fieldDebt];
  } else {
    field =
      asset[calculate && calculate.field ? calculate.field : "purchaseAmount"];
  }

  const value = field * FACTOR;

  return value;
}

function calculateInvestedPurchAmount(asset, calculate) {
  const value = calculateInvestedPurchAmountClean(asset, calculate);

  return formatMoney(value, 0);
}

function calculateInvestedOfferingDate(asset, calculate) {
  const MONTHS = 6;
  let value;
  let field;

  if (calculate.dateFieldDebt) {
    field = asset[calculate.inner][calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const date = addMonths(field.seconds * 1000, MONTHS);

  const daysLeft = differenceInDays(date, Date.now());

  if (daysLeft < 1) {
    return <span>-</span>;
  }

  value = (
    <>
      {daysLeft}
      {` `}
      {daysLeft === 1 ? (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAY_LEFT"
          defaultMessage="Day Left"
        />
      ) : (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAYS_LEFT"
          defaultMessage="Days Left"
        />
      )}
    </>
  );

  return value;
}

function calculateFromAgeToAge(asset, calculate) {
  const fieldFrom =
    calculate.inner && asset[calculate.inner]
      ? asset[calculate.inner].fromAge
      : asset.fromAge;

  const fieldTo =
    calculate.inner && asset[calculate.inner]
      ? asset[calculate.inner].toAge
      : asset.toAge;

  if (fieldFrom && fieldTo) {
    return `${fieldFrom} - ${fieldTo}`;
  }

  if (!fieldFrom && fieldTo) {
    return `Till ${fieldTo}`;
  }

  if (fieldFrom && !fieldTo) {
    return `From ${fieldFrom}`;
  }

  return null;
}

export function calculateInvestedAmountEarnedClean(asset) {
  return (
    asset.investmentValue *
    (asset.interestEarned / 100) *
    asset.interestPaidFrequency
  );
}

function calculateInvestedAmountEarned(asset) {
  if (
    !asset.investmentValue ||
    !asset.interestEarned ||
    !asset.interestPaidFrequency
  ) {
    return 0;
  }

  return formatMoney(calculateInvestedAmountEarnedClean(asset), 0);
}
function calculateAnnualInterestEarned(asset) {
  if (!asset.investmentValue || !asset.annualInterestEarned) {
    return 0;
  }
  return formatMoney(
    asset.investmentValue * (1 + +asset.annualInterestEarned / 100),
    0
  );
}
export function calculateUnvestedEstimateClean(
  asset,
  { calcDate = Date.now() } = {}
) {
  if (
    !asset.numberOfUnvestedUnits ||
    !asset.stockPrice ||
    !(asset.awardDate && asset.awardDate.seconds)
  ) {
    return 0;
  }

  // vesting periods-floor(Days(today-award date)/365)

  const remainingPeriods =
    asset.remainingVestingPeriod -
    Math.floor(
      differenceInDays(calcDate, asset.awardDate.seconds * 1000) / 365
    );

  if (remainingPeriods < 0) {
    return 0;
  }

  // remaining periods*awarded units/vesting period
  const unvestedUnits =
    (remainingPeriods * asset.numberOfUnvestedUnits) /
    asset.remainingVestingPeriod;

  // Unvested Estimate: $ calc number of unvested units*stock price
  return unvestedUnits * asset.stockPrice;
}

function calculateAwardDate(asset, { calcDate = Date.now() } = {}) {
  if (asset.awardDate && asset.awardDate.seconds) {
    let awardDate = new Date(asset.awardDate.seconds * 1000);

    let remainingPeriods = asset.remainingVestingPeriod;

    while (awardDate < calcDate && remainingPeriods >= 0) {
      awardDate = addYears(awardDate, 1);
      remainingPeriods--;
    }

    if (awardDate < calcDate) {
      return 0;
    }

    const daysLeft = differenceInDays(awardDate, calcDate);

    const value = daysLeft % 365;

    return value;
  }

  return 0;
}

function calculateRemainingVestingPeriod(
  asset,
  { calcDate = Date.now() } = {}
) {
  if (asset.awardDate && asset.awardDate.seconds) {
    const remainingPeriods =
      asset.remainingVestingPeriod -
      Math.floor(
        differenceInDays(calcDate, asset.awardDate.seconds * 1000) / 365
      );

    if (remainingPeriods < 0) {
      return 0;
    }

    return remainingPeriods;
  }

  return 0;
}

function calculateUnvestedEstimate(asset) {
  if (!asset.numberOfUnvestedUnits || !asset.stockPrice) {
    return 0;
  }

  const value = formatMoneyIntWithSign(calculateUnvestedEstimateClean(asset));

  return value;
}

// export function _helperCalcDebtRemainingAmortizationOld(asset) {
//   const interest = asset.interestRate / 100;
//   /////////////////////////////////////////////////////////////////////////////////
//   // N - Number of months - 10 months
//   // n - Number of months passed since loan date
//   // L - Loans amount - $1000
//   // IR - interest rate - 10%
//   // D - Discount factor = {[(1 + IR) ^N] - 1} / [IR*(1 + IR)^N]
//   // Debt per Month = L/D
//   // Discount factor = {[(1 + 0.1) ^10] - 1} / [0.1*(1 + 0.1)^10]=6.144
//   // Debt per Month = 1000/6.144=162.76
//   // Remaining Debt = (N-n)*162.76
//
//   const common = Math.pow(1 + interest, asset.term);
//   const discountFactor = (common - 1) / (interest * common);
//
//   const debtPerMonth = asset.loanAmount / discountFactor;
//
//   if (asset.loanDate && asset.loanDate.seconds) {
//     const monthsPassed = differenceInMonths(
//       Date.now(),
//       asset.loanDate.seconds * 1000
//     );
//
//     const value = (asset.term - monthsPassed) * debtPerMonth;
//
//     if (value < 0) {
//       return 0;
//     }
//
//     return value;
//   }
//
//   return 0;
// }
export function _helperCalcDebtRemainingAmortization(
  asset,
  { calcDate = Date.now() } = {}
) {
  // Amortization: M*periods remaining

  // M - Monthly payment
  // asset.loanAmount - Loan Amount
  // interestRate - Annual Interest Rate
  // interest - Monthly Interest Rate
  // asset.term - number of months
  //
  // r=interestRate/12
  // M=L*(interest*(1+interest)^asset.term) /
  //  { [ (1+interest)^asset.term] -1 }

  if (
    asset.loanDate &&
    asset.loanDate.seconds &&
    asset.term &&
    asset.loanAmount &&
    asset.interestRate
  ) {
    const monthsPassed = differenceInMonths(
      calcDate,
      asset.loanDate.seconds * 1000
    );

    const monthsLeft = asset.term - monthsPassed;

    const interest = asset.interestRate / 12 / 100;
    const common = Math.pow(1 + interest, asset.term);

    const discountFactor = (interest * common) / (common - 1);
    const debtPerMonth = asset.loanAmount * discountFactor;

    const value = monthsLeft * debtPerMonth;

    if (value < 0) {
      return 0;
    }

    return value;
  }

  return 0;
}

export function _helperCalcDebtRemainingInterest(
  asset,
  { calcDate = Date.now() } = {}
) {
  // periods remaining*loan amount*(interest rate/12)

  if (asset.loanDate && asset.loanDate.seconds && asset.loanAmount) {
    const interest = asset.interestRate / 100 + 1;

    const monthsDiff = differenceInMonths(
      calcDate,
      asset.loanDate.seconds * 1000
    );

    const periodsRemaining = asset.term - monthsDiff;

    if (periodsRemaining <= 0) {
      return 0;
    }

    const value = periodsRemaining * asset.loanAmount * (interest / 12);

    if (value < 0) {
      return 0;
    }

    return value;
  }

  return 0;
}

function calculateDebtRemainingAmortization(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;
  const value = _helperCalcDebtRemainingAmortization(calcAsset);

  return formatMoney(value, 0);
}

export function calculateDebtRemainingAmortizationInterest(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;
  let value = 0;

  if (calcAsset.loanType === optionsRepaymentValues.AMORTIZATION) {
    value = _helperCalcDebtRemainingAmortization(calcAsset);
  }

  if (calcAsset.loanType === optionsRepaymentValues.INTEREST_ONLY) {
    value = _helperCalcDebtRemainingInterest(calcAsset);
  }

  return `$${formatMoney(value, 0)}`;
}

export function _helperCalculateDebtAnnualPay(
  asset,
  inner,
  { calcDate = Date.now() } = {}
) {
  // If Amortization: calc 12*remaining debt/periods remaining
  // If monthly amount: monthly payment*12
  // If interest only:loan amount*interest rate
  let value = 0;
  const calcAsset = inner ? asset[inner] || {} : asset;

  if (calcAsset.loanType === optionsRepaymentValues.AMORTIZATION) {
    value = calculateDebtAnnualPayAmortization(calcAsset, { calcDate });
  }

  if (calcAsset.loanType === optionsRepaymentValues.INTEREST_ONLY) {
    value = calculateDebtAnnualPayInterest(calcAsset);
  }

  if (calcAsset.loanType === optionsRepaymentValues.MONTHLY_AMOUNT) {
    value = calculateDebtAnnualPayMonthly(calcAsset);
  }

  return value;
}

function calculateDebtAnnualPay(asset, inner) {
  const value = _helperCalculateDebtAnnualPay(asset, inner);

  return `$${formatMoney(value, 0)}`;
}

function calculateDebtAnnualPayInterest(asset, { calcDate = Date.now() } = {}) {
  // If interest only:loan amount*interest rate
  const interest = (+asset.interestRate + 100) / 100;

  return interest * asset.loanAmount;
}

function calculateDebtAnnualPayMonthly(asset, inner) {
  // monthly amount: monthly payment*12

  return asset.monthlyPayment ? asset.monthlyPayment * 12 : 0;
}

function calculateDebtAnnualPayAmortization(
  asset,
  { calcDate = Date.now() } = {}
) {
  // 12*Remaining debt/number of months

  const monthsPassed = differenceInMonths(
    calcDate,
    asset.loanDate.seconds * 1000
  );

  const monthsLeft = asset.term - monthsPassed;

  const debtRemaining = _helperCalcDebtRemainingAmortization(asset, {
    calcDate
  });

  return (12 * debtRemaining) / monthsLeft;
}

// Debt remaining type - monthly
export function _helperCalcDebtRemainingMonthly(
  asset,
  { calcDate = Date.now() } = {}
) {
  //Remaining Debt: $ monthly payment*periods remaining
  if (asset.loanDate && asset.loanDate.seconds) {
    const monthsDiff = differenceInMonths(
      calcDate,
      asset.loanDate.seconds * 1000
    );

    const term = asset.loanAmount / asset.monthlyPayment;

    const monthLeft = term - +monthsDiff;

    if (monthLeft <= 0) {
      return 0;
    }

    return monthLeft * asset.monthlyPayment;
  }

  return 0;
}

function calculateDebtAnnualAccrual(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;

  // (calc interest rate*remaining debt)
  // (calc interestRate*loanAmount)
  const value = calcAsset.loanAmount * (calcAsset.interestRate / 100);

  return `$${formatMoney(value, 0)}`;
}

function calculateDebtRemainingMonthly(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;
  const value = _helperCalcDebtRemainingMonthly(calcAsset);

  return `$${formatMoney(value, 0)}`;
}

export function _helperCalcDebtRemainingFixed(asset) {
  //Remaining Debt: $ periods remaining*periodic payment amount
  return +asset.periodsRemaining * +asset.periodicPayment;
}

function calculateDebtRemainingFixed(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;
  const value = _helperCalcDebtRemainingFixed(calcAsset);

  return `$${formatMoney(value, 0)}`;
}

function calculateDebtAnnualPayFixed(asset, inner) {
  const calcAsset = inner ? asset[inner] || {} : asset;

  // Annual payment: calc remaining debt/(periods remaining*12 if monthly or 4 of quarterly, 1 otherwise),
  const remainingDebt = _helperCalcDebtRemainingFixed(calcAsset);

  // TODO waiting client
  const value =
    remainingDebt / (calcAsset.periodsRemaining / calcAsset.frequency);

  return `$${formatMoney(value, 0)}`;
}

function calculateMoneyInEarning(asset) {
  const value = +asset.baseSalary + +asset.variable;

  return `$${formatMoney(value, 0)}`;
}

function calculateAnnualRent(asset) {
  const value =
    asset.monthlyRent * 12 -
    (asset.averageVacancy * asset.monthlyRent * 12) / 365;

  return `$${formatMoney(value, 0)}`;
}

function calculateAnnualMoneyInOther(asset) {
  if (asset.amount && asset.every) {
    const optionIndex = optionsOccursEvery.findIndex(
      el => el.value === asset.every
    );

    if (typeof optionIndex === "number") {
      const multiplier = optionsOccursEvery[optionIndex].multiplier;
      const value = asset.amount * multiplier;

      return `$${formatMoney(value, 0)}`;
    }
  }

  return null;
}

function calculateEventVariable(
  asset,
  { relatedAsset, currentAge, calcAge } = {}
) {
  let valueVariable = asset.variable;
  const tRelated = calcAge - currentAge;

  if (asset.annualChange) {
    const t = calcAge - asset.startAge;

    valueVariable = valueVariable * Math.pow(1 + asset.annualChange / 100, t);
  }

  let annualChangeRelated;
  if (
    relatedAsset &&
    relatedAsset.configuration &&
    relatedAsset.configuration.annualChange
  ) {
    annualChangeRelated = relatedAsset.configuration.annualChange;
  }

  const valueRelated = relatedAsset
    ? relatedAsset.value * Math.pow(1 + annualChangeRelated / 100, tRelated)
    : 0;

  return formatMoneyIntWithSign(valueRelated * (valueVariable / 100));
}

function calculateEventAmount(asset, { currentAge, calcAge } = {}) {
  let valueAmount = asset.amount;

  if (asset.annualChange) {
    const t = calcAge - asset.startAge;

    valueAmount = valueAmount * Math.pow(1 + asset.annualChange / 100, t);
  }

  return formatMoneyInt(valueAmount);
}

export default withStyles(styles)(injectIntl(AssetCard));
