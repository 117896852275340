import IconMenu from "./IconMenu";
import IconAccount from "./IconAccount";
import IconCarHome from "./IconCarHome";
import IconHome from "./IconHome";
import IconHousehold from "./IconHousehold";
import IconInvestment from "./IconInvestment";
import IconMoneyIn from "./IconMoneyIn";
import IconMoneyOwned from "./IconMoneyOwned";
import IconPlusCircle from "./IconPlusCircle";
import IconUmbrella from "./IconUmbrella";
import IconUmbrellaIndependence from "./IconUmbrellaIndependence";
import IconVehicle from "./IconVehicle";
import IconPlus from "./IconPlus";
import IconPlusNew from "./IconPlusNew";
import IconClose from "./IconClose";
import IconLogoMobile from "./IconLogoMobile";
import IconAvatar from "./IconAvatar";
import IconAvatarClean from "./IconAvatarClean";
import IconDashboard from "./IconDashboard";
import IconPlans from "./IconPlans";
import IconOperation from "./IconOperation";
import IconArrowDownOld from "./IconArrowDownOld";
import IconArrowUp from "./IconArrowUp";
import IconArrowUpOld from "./IconArrowUpOld";
import IconArrowDown from "./IconArrowDown";
import IconArrowRight from "./IconArrowRight";
import IconArrowLeft from "./IconArrowLeft";
import IconDoubleChevronLeft from "./IconDoubleChevronLeft";
import IconOther from "./IconOther";
import IconDotsHorizontal from "./IconDotsHorizontal";
import IconGeneralSavings from "./IconGeneralSavings";
import IconRetirement from "./IconRetirement";
import IconStudent from "./IconStudent";
import IconDeferredComp from "./IconDeferredComp";
import IconCase from "./IconCase";
import IconDollar from "./IconDollar";
import IconDollarCrossed from "./IconDollarCrossed";
import IconCreditCard from "./IconCreditCard";
import IconMedical from "./IconMedical";
import IconDocEdit from "./IconDocEdit";
import IconCertificate from "./IconCertificate";
import IconSaving from "./IconSaving";
import IconInterests from "./IconInterests";
import IconGoal from "./IconGoal";
import IconEvent from "./IconEvent";
import IconBalances from "./IconBalances";
import IconOperating from "./IconOperating";
import IconSalaries from "./IconSalaries";
import IconCart from "./IconCart";
import IconAirplane from "./IconAirplane";
import IconGlasses from "./IconGlasses";
import IconLogOut from "./IconLogOut";
import IconHammer from "./IconHammer";
import IconMoneyBag from "./IconMoneyBag";
import IconPaycheque from "./IconPaycheque";
import IconProtect from "./IconProtect";
import IconSupport from "./IconSupport";
import IconClock from "./IconClock";
import IconClockArrow from "./IconClockArrow";
import IconElectrical from "./IconElectrical";
import IconMoneyOut from "./IconMoneyOut";
import IconPassword from "./IconPassword";
import IconLanguage from "./IconLanguage";
import IconProfile from "./IconProfile";
import IconAlert from "./IconAlert";
import IconAlertTile from "./IconAlertTile";
import IconHelpCircle from "./IconHelpCircle";
import IconHomeMulti from "./IconHomeMulti";
import IconVehicleMulti from "./IconVehicleMulti";
import IconDisabled from "./IconDisabled";
import IconTrophy from "./IconTrophy";
import IconKey from "./IconKey";
import IconChild from "./IconChild";
import IconSettings from "./IconSettings";
import IconFormatList from "./IconFormatList";
import IconClockRetry from "./IconClockRetry";
import IconSunLounger from "./IconSunLounger";
import IconWhatIf from "./IconWhatIf";
import IconStar from "./IconStar";
import IconSearch from "./IconSearch";
import IconFilter from "./IconFilter";
import IconSendMail from "./IconSendMail";
import IconBigAccepted from "./IconBigAccepted";
import IconBigBank from "./IconBigBank";
import IconBigEducation from "./IconBigEducation";
import IconBigInsurance from "./IconBigInsurance";
import IconBigHome from "./IconBigHome";
import IconBigLoan from "./IconBigLoan";
import IconBigRetirement from "./IconBigRetirement";
import IconBigStrategy from "./IconBigStrategy";
import IconBigEmployee from "./IconBigEmployee";
import IconBigMoneyBag from "./IconBigMoneyBag";
import IconBigProfit from "./IconBigProfit";
import IconBigPlanning from "./IconBigPlanning";
import IconEye from "./IconEye";
import IconQuizChild from "./IconQuizChild";
import IconQuizManWork from "./IconQuizManWork";
import IconQuizSpouseWork from "./IconQuizSpouseWork";
import IconCheck from "./IconCheck";

export {
  IconMenu,
  IconHome,
  IconUmbrella,
  IconUmbrellaIndependence,
  IconVehicle,
  IconPlusCircle,
  IconMoneyOwned,
  IconMoneyIn,
  IconInvestment,
  IconHousehold,
  IconCarHome,
  IconAccount,
  IconPlus,
  IconClose,
  IconLogoMobile,
  IconAvatar,
  IconOperation,
  IconPlans,
  IconDashboard,
  IconArrowDownOld,
  IconArrowDown,
  IconArrowUp,
  IconArrowUpOld,
  IconArrowRight,
  IconArrowLeft,
  IconOther,
  IconDotsHorizontal,
  IconGeneralSavings,
  IconStudent,
  IconRetirement,
  IconDeferredComp,
  IconCase,
  IconDollar,
  IconDollarCrossed,
  IconCreditCard,
  IconMedical,
  IconSaving,
  IconCertificate,
  IconDocEdit,
  IconGoal,
  IconEvent,
  IconInterests,
  IconBalances,
  IconOperating,
  IconSalaries,
  IconCart,
  IconAirplane,
  IconLogOut,
  IconGlasses,
  IconHammer,
  IconMoneyBag,
  IconPaycheque,
  IconProtect,
  IconElectrical,
  IconClock,
  IconClockArrow,
  IconSupport,
  IconMoneyOut,
  IconLanguage,
  IconPassword,
  IconProfile,
  IconAlert,
  IconAlertTile,
  IconHelpCircle,
  IconVehicleMulti,
  IconHomeMulti,
  IconDisabled,
  IconTrophy,
  IconPlusNew,
  IconKey,
  IconAvatarClean,
  IconChild,
  IconDoubleChevronLeft,
  IconSettings,
  IconFormatList,
  IconClockRetry,
  IconSunLounger,
  IconWhatIf,
  IconStar,
  IconSearch,
  IconFilter,
  IconSendMail,
  IconBigRetirement,
  IconBigLoan,
  IconBigInsurance,
  IconBigHome,
  IconBigEducation,
  IconBigBank,
  IconBigAccepted,
  IconBigPlanning,
  IconBigProfit,
  IconBigMoneyBag,
  IconBigEmployee,
  IconBigStrategy,
  IconEye,
  IconQuizManWork,
  IconQuizSpouseWork,
  IconQuizChild,
  IconCheck
};
