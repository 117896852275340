import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconBigAccepted = ({
  height = 60,
  width = 60,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 60"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M30.0069 60H30.0008C21.9839 59.9985 14.4482 56.8785 8.78339 51.2122C3.11853 45.546 -0.00154173 38.0105 5.71512e-07 30C-0.00308402 13.4608 13.4535 0.00462677 29.9977 0H30.0008C38.0145 0 45.5456 3.11999 51.2135 8.78778C56.883 14.4556 60.0031 21.9895 60 29.9985C60.0031 38.0089 56.883 45.5413 51.2166 51.2091C45.5502 56.8785 38.0176 60 30.0069 60ZM30.0008 3.08452H29.9977C15.1546 3.08914 3.08151 15.1619 3.0846 30C3.08305 37.19 5.88078 43.9482 10.9642 49.0315C16.0476 54.1147 22.809 56.9139 30.0008 56.9155L30.0069 58.4577V56.9155C37.1925 56.9155 43.9524 54.1163 49.0358 49.0299C54.1192 43.9451 56.9185 37.1869 56.9154 30C56.9185 22.8131 54.1192 16.0549 49.0327 10.9701C43.9478 5.88371 37.1894 3.08452 30.0008 3.08452Z"
        fill={fill}
      />
      <path
        d="M41.703 21.0011L26.8207 35.8935C26.7923 35.9278 26.762 35.9611 26.7299 35.9932C26.4541 36.269 26.0921 36.4076 25.73 36.4076C25.368 36.4076 25.006 36.269 24.7302 35.9932L18.6897 29.9485C18.6494 29.9163 18.6104 29.8815 18.5729 29.8443C18.1558 29.4285 17.6042 29.2008 17.0188 29.2008H17.0173C16.4305 29.2008 15.879 29.4299 15.4646 29.8457C14.609 30.7027 14.609 32.0942 15.4632 32.9512C15.5147 33.0027 15.5613 33.0571 15.6032 33.114L24.1965 41.7074C24.2198 41.7279 24.2427 41.7493 24.2649 41.7715C24.6793 42.1859 25.2294 42.415 25.8149 42.415H25.8163C26.4031 42.4122 26.9547 42.1845 27.3704 41.7687C27.3961 41.7381 27.4229 41.7088 27.4508 41.6807C27.4769 41.6493 27.5046 41.6188 27.5339 41.5892L44.9083 24.0447C44.9289 24.024 44.95 24.0042 44.9715 23.985C44.9846 23.9708 44.9981 23.9567 45.012 23.9429C45.4263 23.5271 45.654 22.9742 45.654 22.3887C45.6512 21.8018 45.4235 21.2517 45.0049 20.8359C44.1479 19.9803 42.7536 19.9817 41.9022 20.8345C41.8398 20.8971 41.773 20.9526 41.703 21.0011Z"
        fill={fill}
      />
    </svg>
  );
};

IconBigAccepted.propTypes = {
  // strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconBigAccepted;
