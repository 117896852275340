import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconDeferredComp = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H15V12V16V17H16V2H5V17H10.3333L10.1667 16H6V3Z"
        fill={fill}
      />
      <path
        d="M15.5186 10C16.2319 10 18.2701 13.1177 18.4984 13.2901C19.0587 13.715 19.0338 14.4364 18.9633 14.8293C18.9467 14.9495 18.9052 15.0617 18.8554 15.174C18.5441 15.8714 18.0128 20.4608 17.8758 21.6954C17.8551 21.8677 17.7057 22 17.523 22H13.6838C13.4845 22 13.3268 21.8437 13.3268 21.6553V21.3026C12.148 20.1121 11.7205 18.3926 11.7205 18.3926L11.3387 15.2221C10.4837 12.3682 11.5172 12.284 11.5172 12.284C11.5545 12.28 11.5877 12.28 11.6251 12.28C12.9906 12.28 13.2313 14.8814 13.2313 14.8814C13.2313 14.8814 13.7211 15.8594 14.1611 16.172L14.3889 16L15.5186 10Z"
        fill={fill}
      />
      <circle cx="6.5" cy="3.5" r="1.5" fill={fill} />
      <circle cx="10.5" cy="9.5" r="2.5" fill={fill} />
      <circle cx="14.5" cy="3.5" r="1.5" fill={fill} />
      <circle cx="6.5" cy="15.5" r="1.5" fill={fill} />
    </svg>
  );
};

IconDeferredComp.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconDeferredComp;
