import React, { Component } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { formatMoney } from "lib/helpers";

import "./FormControl.scss";
import { injectIntl } from "react-intl-v3";

function isFloat(n) {
  return n % 1 !== 0;
}

class FormControlInput extends Component {
  static defaultProps = {
    type: "text"
  };

  static propTypes = {
    type: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    labelI18n: PropTypes.string,
    helperText: PropTypes.any,
    value: PropTypes.any,
    options: PropTypes.array,
    handleChange: PropTypes.func,
    bgSecondary: PropTypes.bool,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    isAmount: PropTypes.bool,
    isInt: PropTypes.bool,
    multiline: PropTypes.bool,
    multilineRows: PropTypes.any,
    formWidth: PropTypes.string,
    fieldScheme: PropTypes.object,
    classNameExtra: PropTypes.any,
    classNameContainer: PropTypes.any,
    defaultValue: PropTypes.any,
    InputLabelProps: PropTypes.any
  };

  state = {
    isFocused: false
  };

  startAdornment = null;

  handleFocus = () => {
    this.setState({
      isFocused: true
    });
  };

  handleBlur = () => {
    this.setState({
      isFocused: false
    });
  };

  handleChangeInt = ev => {
    const { name, value } = ev.target;

    const { handleChange } = this.props;

    const newValue = value.replace(/[^0-9-]/g, "");

    handleChange({
      target: { value: newValue, name: name }
    });
  };

  handleChangeAmount = ev => {
    const { name, value } = ev.target;

    const { handleChange } = this.props;

    const newAmount = value.replace(/,/g, "");

    handleChange({ target: { value: newAmount, name: name } });
  };

  render() {
    const {
      classes,
      fieldName,
      label,
      labelI18n,
      value,
      handleChange,
      type,
      bgSecondary,
      error,
      autoFocus,
      isAmount,
      formWidth,
      additionalProps = {},
      fieldScheme,
      disabled,
      helperText,
      multiline,
      multilineRows,
      classNameExtra,
            classNameContainer,
      defaultValue,
      isInt,
      placeholder,
      InputLabelProps,
      ...rest
    } = this.props;

    const { intl } = this.props;

    const labelIntl =
      labelI18n || label
        ? intl.formatMessage({
            id: labelI18n || " ",
            defaultMessage: label
          })
        : "";

    const restProps = { ...rest };

    if (!additionalProps.inputProps) {
      additionalProps.inputProps = {};
    }

    let valueShow = value;
    let typeShow = type;

    if (isAmount) {
      const isDecimal =
        isFloat(valueShow) ||
        (+valueShow[valueShow.length - 1] === 0 &&
          valueShow[valueShow.length - 2] === ".");

      let isLastDot = false;
      let isDecimalCount = 0;

      if (valueShow && valueShow[valueShow.length - 1] === ".") {
        isLastDot = true;
      }

      if (isDecimal && valueShow) {
        isDecimalCount = valueShow.length - `${valueShow}`.indexOf(".") - 1;
        isDecimalCount = isDecimalCount > 2 ? 2 : isDecimalCount;
      }

      valueShow = formatMoney(valueShow, isDecimalCount, ".", ",");

      if (isLastDot) {
        valueShow += ".";
      }

      typeShow = "text";

      if (!additionalProps.inputProps) {
        additionalProps.inputProps = {};
      }

      additionalProps.inputProps.inputMode = "decimal";
    }

    if (
      !isAmount &&
      restProps.InputProps &&
      restProps.InputProps.startAdornment
    ) {
      if (!this.startAdornment) {
        this.startAdornment = restProps.InputProps.startAdornment;
      }

      if (!(value || this.state.isFocused)) {
        delete restProps.InputProps.startAdornment;
      }
    }

    if (!isAmount && this.startAdornment && (value || this.state.isFocused)) {
      restProps.InputProps.startAdornment = this.startAdornment;
    }

    if (isAmount && !value && this.state.isFocused) {
      valueShow = "";
    }

    let handleChangeNew = handleChange;

    if ((fieldScheme && fieldScheme.isInt) || isInt) {
      additionalProps.inputProps.inputMode = "numeric";

      handleChangeNew = this.handleChangeInt;
    }

    if (isAmount) {
      handleChangeNew = this.handleChangeAmount;
    }

    return (
      <div
        className={`form-control__wrapper ${
          formWidth ? formWidthObj[formWidth] : ""
        } ${bgSecondary ? "form-control__wrapper--secondary" : ""} ${
          classNameContainer ? classNameContainer : ""
        }`}
      >
        <TextField
          InputLabelProps={InputLabelProps}
          placeholder={placeholder}
          rows={multilineRows}
          defaultValue={defaultValue}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          error={error}
          helperText={helperText || null}
          id={fieldName}
          label={labelIntl}
          name={fieldName}
          className={`${classes.textField ? classes.textField : ""} ${
            classNameExtra ? classNameExtra : ""
          }`}
          value={valueShow}
          onChange={handleChangeNew}
          margin="none"
          type={typeShow}
          fullWidth
          multiline={multiline}
          autoFocus={autoFocus}
          disabled={disabled}
          {...additionalProps}
          {...restProps}
        />
      </div>
    );
  }
}

const formWidthObj = {
  33: "form-control__wrapper--third",
  50: "form-control__wrapper--half"
};

const styles = theme => ({});

export default withStyles(styles)(injectIntl(FormControlInput));
