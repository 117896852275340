export const DRAWER_WIDTH = 220;

export const COLOR_BRAND = "#6a68fb";
export const COLOR_BRAND_08 = "#7877fb";
export const COLOR_BRAND_STROKE = "#7248EB";
export const COLOR_LIGHT_BLUE_STROKE = "#B8D5FF";
export const COLOR_UI = "#6885ee";
export const COLOR_UI_08 = "#7791ef";
export const COLOR_UI_LIGHT = "#e8edfc";
export const COLOR_UI_YELLOW = "#ff9d0a";
export const COLOR_UI_YELLOW_08 = "#ffa622";
export const COLOR_UI_YELLOW_LIGHT = "#ffcf3c";
export const COLOR_UI_YELLOW_DARK = "#E7D42E";
export const COLOR_UI_BLUE = "#bad3ff";
export const COLOR_TEXT_PRIMARY = "#2c2a3f";
export const COLOR_TEXT_SECONDARY = "#5c6178";
export const COLOR_TEXT_TETRIARY = "#85859a";
export const COLOR_TEXT_FIELD_COLOR = "rgba(48, 65, 84, 0.6)";
export const COLOR_TEXT_GREY = "#c4c4c4";
export const COLOR_TEXT_GREEN = "#4cc461";
export const COLOR_TEXT_RED = "#ff3b30";
export const COLOR_WHITE = "#FFFFFF";

export const COLOR_BACKGROUND = "#F9F9F9";
export const COLOR_BACKGROUND_SECONDARY = "#F0F3FD";
export const COLOR_BACKGROUND_DISABLED = "#f6f6f8";
export const COLOR_BACKGROUND_DISABLED_ASSET = "#EDEDF1";
export const COLOR_BACKGROUND_ACTIVE_UI = "#E1E7FC";
export const COLOR_DIVIDER = "#E8EAEC";
export const COLOR_DIVIDER2 = "#e7e7eb";
export const COLOR_UNDERLINE_INPUT = "#c5cacf";

export const BREAKPOINT_MD = 960;
export const BREAKPOINT_MD_MAX = 959.9;
export const BREAKPOINT_SM = 580;

export const BOX_SHADOW_1 = "0 2px 4px rgba(30, 50, 90, 0.15)";
export const BOX_SHADOW_2 = "0 4px 16px rgba(30, 50, 90, 0.2)";
export const BOX_SHADOW_3 = "0 8px 18px rgba(30, 50, 90, 0.2)";

export const colors = {
  white: COLOR_WHITE,
  ui: COLOR_UI,
  ui08: COLOR_UI_08,
  uiLight: COLOR_UI_LIGHT,
  brand: COLOR_BRAND,
  brand08: COLOR_BRAND_08,
  brandStroke: COLOR_BRAND_STROKE,
  blue: COLOR_UI_BLUE,
  lightBlueStroke: COLOR_LIGHT_BLUE_STROKE,
  uiYellow: COLOR_UI_YELLOW,
  uiYellow08: COLOR_UI_YELLOW_08,
  yellow: COLOR_UI_YELLOW,
  yellowLight: COLOR_UI_YELLOW_LIGHT,
  yellowDark: COLOR_UI_YELLOW_DARK,
  yellow08: COLOR_UI_YELLOW_08,
  textPrimary: COLOR_TEXT_PRIMARY,
  textSecondary: COLOR_TEXT_SECONDARY,
  textTetrieary: COLOR_TEXT_TETRIARY,
  textFieldColor: COLOR_TEXT_FIELD_COLOR,
  green: COLOR_TEXT_GREEN,
  red: COLOR_TEXT_RED,
  background: COLOR_BACKGROUND,
  backgroundSecondary: COLOR_BACKGROUND_SECONDARY,
  backgroundDisabled: COLOR_BACKGROUND_DISABLED,
  backgroundDisabledAsset: COLOR_BACKGROUND_DISABLED_ASSET,
  backgroundActiveUi: COLOR_BACKGROUND_ACTIVE_UI,
  divider: COLOR_DIVIDER,
  divider2: COLOR_DIVIDER2,
  inputUnderline: COLOR_UNDERLINE_INPUT,
  grey: COLOR_TEXT_GREY
};

export const styleMix = {
  boxShadow1: {
    boxShadow: "0px 2px 4px rgba(30, 50, 90, 0.15)"
  },
  ResetUl: {
    margin: 0,
    padding: 0,

    listStyle: "none"
  },
  ResetButton: {
    border: "none",
    margin: 0,
    padding: 0,
    width: "auto",
    overflow: "visible",

    background: "transparent",

    color: "inherit",
    font: "inherit",
    cursor: "pointer"
  },
  buttonLink: {
    fontSize: 14,
    lineHeight: "16px",
    /* identical to box height, or 114% */
    letterSpacing: "0.5px",
    textDecorationLine: "underline",

    color: colors.ui,

    "&:hover": {
      opacity: 0.7
    },
    "&:active": {
      opacity: 0.5
    }
  },
  textSubtitle: {
    fontSize: 18,
    lineHeight: "30px",
    letterSpacing: "0.5px",

    color: colors.textPrimary
  },
  uiText: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.5px",

    color: colors.textPrimary
  },
  bodyBold: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.5px",
    color: colors.textPrimary
  },
  uiTextBold: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.5px",

    color: colors.textPrimary
  },

  title: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "40px",

    letterSpacing: "0.5px",

    color: colors.textPrimary
  },
  h2: {
    fontSize: 20,
    lineHeight: "26px",
    textAlign: "center",
    letterSpacing: "0.5px",

    color: colors.textPrimary
  },
  h3: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.5px",

    color: colors.textPrimary
  },
  headCard: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",

    color: colors.textSecondary
  },
  labelCard: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",

    color: colors.textSecondary
  }
};
