import { colors } from "../../constants/styles";

export const chartsMeta = {
  income: {
    savings: {
      colors: {
        id: "colorGreen",
        stroke: colors.green,
        start: "#82ca9d",
        stop: "#2EA643",

      }
    },
    expenses: {
      colors: {
        id: "colorUi",
        stroke: colors.ui,
        start: "#86A3FF",
        stop: "#4A67D0"
      }
    },
    "pre-tax_deductions": {
      colors: {
        id: "colorBrand",
        stroke: colors.brandStroke,
        start: "#9066FF",
        stop: "#542ACD"
      }
    },
    taxesIncome: {
      colors: {
        id: "colorYellow",
        stroke: colors.yellow,
        start: "#FFCF3C",
        stop: "#FF9D0A"
      }
    }
  },
  wealth: {
    // netWealth: {
    //   colors: {
    //     id: "colorLightBlue",
    //     stroke: colors.lightBlueStroke,
    //     start: "#D6F3FF",
    //     stop: "#9AB7E1"
    //   }
    // },
    homesAndVehicles: {
      colors: {
        id: "colorUi",
        stroke: colors.lightBlueStroke,
        start: "#D6F3FF",
        stop: "#9AB7E1"
      }
    },
    monetaryAssets: {
      colors: {
        id: "colorLightBlue",
        stroke: "#6885EE",
        start: "#86A3FF",
        stop: "#4A67D0"
      }
    },

    liabilities: {
      colors: {
        id: "colorRed",
        stroke: colors.red,
        start: "#FF594E",
        stop: "#E11D12"
      }
    },
    taxes: {
      colors: {
        id: "colorYellow",
        stroke: colors.yellow,
        start: "#FFCF3C",
        stop: "#FF9D0A"
      }
    }
  }
};
