import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconPlus = ({
  height = 14,
  width = 14,
  color = "textTetrieary",
  iconTitle = "",
  stroke,
  strokeWidth = 0
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
        fill={fill}
        fillOpacity="0.6"
        strokeWidth={strokeWidth}
        stroke={stroke}
      />
    </svg>
  );
};

IconPlus.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconPlus;
