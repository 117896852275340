import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconHammer = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M11.425 3.00913C10.9564 2.97712 10.512 3.03402 10.1295 3.12293C12.5413 5.61229 13.9333 8.18701 13.2442 8.89825L15.7525 11.4872L16.3589 10.8328L16.9929 11.4872L16.3589 12.1415L17.6269 13.4218C17.9714 13.7774 18.5227 13.7774 18.8672 13.4218L20.7416 11.4872C21.0861 11.1316 21.0861 10.5626 20.7416 10.2069L19.4737 8.89825L18.8672 9.5526L18.2333 8.89825L18.8672 8.27236L16.3589 5.68342C14.5501 3.81639 12.8308 3.10159 11.425 3.00913ZM12.1141 10.0647L4.25841 18.1729C3.91386 18.5285 3.91386 19.0975 4.25841 19.4532L5.52635 20.7334C5.8709 21.089 6.42217 21.089 6.76672 20.7334L14.6224 12.6252L12.1141 10.0647Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconHammer.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconHammer;
