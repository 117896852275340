import React, { Component } from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";
import FormControlSelect from "../../_common/FormControl/FormControlSelect";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import TooltipTitle from "../../_common/TooltipTitle";

import { optionsEmployedStatus } from "components/HouseholdMembers/HouseholdMembers";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { optionsWizardSets, WIZARD_DEFAULTS } from "../../../constants/wizard";
import ButtonMUI from "@material-ui/core/Button/Button";

export const optionsFillingStatusValues = {
  married: {
    nameI18n: "WIZARD.FAMILY.FILLING.MARRIED",
    name: "Married filing jointly"
  },
  single: {
    nameI18n: "WIZARD.FAMILY.FILLING.SINGLE",
    name: "Single"
  },
  other: {
    nameI18n: "WIZARD.FAMILY.FILLING.OTHER",
    name: "Other"
  }
};

const optionsFillingStatus = [
  {
    value: "married",
    ...optionsFillingStatusValues.married
  },
  {
    value: "single",
    ...optionsFillingStatusValues.single
  },
  {
    value: "other",
    ...optionsFillingStatusValues.other
  }
];

const optionsAge = [];
for (let i = 18; i < 101; i++) {
  optionsAge.push({
    value: i,
    name: i
  });
}
const optionsDependents = [];
for (let i = 0; i < 11; i++) {
  optionsDependents.push({
    value: "" + i,
    name: "" + i
  });
}

class WizardFamily extends Component {
  state = {
    isValid: true
  };

  zipCodeInput = React.createRef();
  body = React.createRef();

  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderContent = () => {
    const {
      classes,
      formState,
      handleInputChange,
      handleDefaultTemplateChange,
      wizardTemplate
    } = this.props;

    const isZipCodeError = !this.state.isValid;

    const descriptionDefault =
      WIZARD_DEFAULTS[wizardTemplate] &&
      WIZARD_DEFAULTS[wizardTemplate].meta &&
      WIZARD_DEFAULTS[wizardTemplate].meta.description;
    const wizardTemplateDescription = (
      <FormattedMessage
        id={`WIZARD.DEFAULT_SET.${wizardTemplate}.DESCRIPTION`}
        defaultMessage={descriptionDefault}
      />
    );

    return (
      <div className={classes.wizardBodyForm} ref={this.body}>
        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.DEFAULT_TEMPLATE"
              defaultMessage="Select a default template?"
            />
          </div>

          <div className={classes.wizardFormControl}>
            <FormControlSelect
              fieldName="wizardTemplate"
              helperText={wizardTemplateDescription}
              label={null}
              labelI18n={null}
              handleChange={handleDefaultTemplateChange}
              value={wizardTemplate || "default"}
              type="select"
              options={optionsWizardSets}
              autoFocus
              cleanGap
              hideNoneOption
            />
          </div>

          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            className={classes.toleranceTooltip}
            title={
              <TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.DEFAULT_TEMPLATE" />
            }
            placement="bottom-end"
          >
            <span style={{ userSelect: "none" }}>?</span>
          </Tooltip>
        </div>

        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.AGE"
              defaultMessage="How old are you?"
            />
          </div>

          <div className={classes.wizardFormControl}>
            <FormControlSelect
              fieldName="age"
              label={null}
              labelI18n={null}
              handleChange={handleInputChange("family")}
              value={formState.age || 18}
              type="select"
              options={optionsAge}
              autoFocus
              cleanGap
              hideNoneOption
            />
          </div>

          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            className={classes.toleranceTooltip}
            title={<TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.AGE" />}
            placement="bottom-end"
          >
            <span style={{ userSelect: "none" }}>?</span>
          </Tooltip>
        </div>

        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.CHILD"
              defaultMessage="How many dependents do you claim on your tax returns?"
            />
          </div>

          <div className={classes.wizardFormControl}>
            <FormControlSelect
              fieldName="dependents"
              label={null}
              labelI18n={null}
              handleChange={handleInputChange("family")}
              value={formState.dependents || "0"}
              type="select"
              options={optionsDependents}
              cleanGap
              hideNoneOption
            />
          </div>

          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            className={classes.toleranceTooltip}
            title={<TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.CHILDREN" />}
            placement="bottom-end"
          >
            <span style={{ userSelect: "none" }}>?</span>
          </Tooltip>
        </div>

        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.IS_MARRIED"
              defaultMessage="What status do you use when you file your taxes?"
            />
          </div>

          <div className={classes.wizardFormControlLarge}>
            <FormControlSelect
              fieldName="taxesStatus"
              label={null}
              labelI18n={null}
              handleChange={handleInputChange("family")}
              value={formState.taxesStatus}
              type="select"
              options={optionsFillingStatus}
              cleanGap
              hideNoneOption
            />
          </div>

          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            className={classes.toleranceTooltip}
            title={
              <TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.TAXES_STATUS" />
            }
            placement="bottom-end"
          >
            <span style={{ userSelect: "none" }}>?</span>
          </Tooltip>
        </div>

        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.EMPLOYMENT"
              defaultMessage="What is your employment status?"
            />
          </div>

          <div className={classes.wizardFormControlLarge}>
            <FormControlSelect
              fieldName="employmentStatus"
              label={null}
              labelI18n={null}
              handleChange={handleInputChange("family")}
              value={formState.employmentStatus}
              type="select"
              options={optionsEmployedStatus}
              cleanGap
              hideNoneOption
            />
          </div>

          <Tooltip
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
            className={classes.toleranceTooltip}
            title={
              <TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.GENERAL_EMPL_STATUS" />
            }
            placement="bottom-end"
          >
            <span style={{ userSelect: "none" }}>?</span>
          </Tooltip>
        </div>

        {formState.taxesStatus === "married" && (
          <div className={classes.wizardFormItem}>
            <div className={classes.wizardFormLabel}>
              <FormattedMessage
                id="WIZARD.FAMILY.FORM.SPOUSE_EMPLOYMENT"
                defaultMessage="What is your spouse employment status?"
              />
            </div>

            <div className={classes.wizardFormControlLarge}>
              <FormControlSelect
                fieldName="spouseStatus"
                label={null}
                labelI18n={null}
                handleChange={handleInputChange("family")}
                value={formState.spouseStatus}
                type="select"
                options={optionsEmployedStatus}
                cleanGap
                hideNoneOption
              />
            </div>

            <Tooltip
              disableFocusListener
              enterTouchDelay={50}
              leaveTouchDelay={5000}
              className={classes.toleranceTooltip}
              title={
                <TooltipTitle labelI18n="WIZARD.FAMILY.TOOLTIP.SPOUSE_EMPL_STATUS" />
              }
              placement="bottom-end"
            >
              <span style={{ userSelect: "none" }}>?</span>
            </Tooltip>
          </div>
        )}

        <div className={classes.wizardFormItem}>
          <div className={classes.wizardFormLabel}>
            <FormattedMessage
              id="WIZARD.FAMILY.FORM.ZIP_CODE"
              defaultMessage="What is your primary residence zipcode?"
            />
          </div>

          <div className={classes.wizardFormControl}>
            <FormControlInput
              ref={this.zipCodeInput}
              autoFocus={isZipCodeError}
              error={isZipCodeError}
              helperText={
                isZipCodeError ? (
                  <FormattedMessage
                    id="WIZARD.FAMILY.FORM.ZIP_CODE.ERROR.REQUIRED"
                    defaultMessage="Fill this field, please"
                  />
                ) : null
              }
              fieldName="zipCode"
              label={null}
              labelI18n={null}
              handleChange={handleInputChange("family")}
              value={formState.zipCode}
              inputProps={{
                minLength: 5,
                maxLength: 5
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  validateAndChange = () => {
    const { nextStep, handleChangeStep, formState } = this.props;

    let isValid = true;

    if (!formState.zipCode) {
      isValid = false;
      this.setState({ isValid: false });
    } else {
      this.setState({ isValid: true });
    }

    if (isValid) {
      handleChangeStep(nextStep)();
    }
  };

  render() {
    const {
      classes,
      isMobile,
      prevStep,
      handleChangeStep,
      handleFinishAndSaveAssets,
      formState
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.FAMILY.LABEL"
                defaultMessage="Family"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={2} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.FAMILY.HEADING2"
              defaultMessage="Using this basic information we can forecast your spending needs and tax liabilities for the years to come"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            disabled={!formState.zipCode}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={this.validateAndChange}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardFamily.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardFamily;
