import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconSendMail = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M4 4C2.97656 4 2.14062 4.77344 2.02344 5.76562L12 12L21.9766 5.76562C21.8594 4.77344 21.0234 4 20 4H4ZM2 7.75V18C2 19.1055 2.89453 20 4 20H12.5781C13.6445 17.5391 15.9844 16.3633 18 16.0742V14C18 13.4492 18.4492 13 19 13H20.4141C20.6797 13 20.9336 13.1055 21.1211 13.293L22 14.1719V7.75L12 14L2 7.75ZM20 15V18H19C19 18 17.793 17.9844 16.5508 18.6055C15.3125 19.2266 14 20.668 14 23V24H16V23C16 21.332 16.6875 20.7734 17.4492 20.3945C18.207 20.0156 19 20 19 20H20V23L24 19L20 15Z"
        fill={fill}
      />
    </svg>
  );
};

IconSendMail.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSendMail;
