import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import * as Icons from "components/_common/icons";
import { colors, BOX_SHADOW_1 } from "../../constants/styles";
import { FormattedMessage } from "react-intl-v3";

const actionButtonsZIndexes = {};
const actionButtonsBottomGoal = {};

const EVENT_HEIGHT = -13;
const START_Z_INDEX = 100;

for (let i = 0; i <= 50; i++) {
  actionButtonsZIndexes[`actionButtonIndex${START_Z_INDEX - i}`] = {
    zIndex: START_Z_INDEX - i
  };
}
for (let i = 0; i <= 50; i++) {
  actionButtonsBottomGoal[`actionButtonsBottomGoal${i}`] = {
    bottom: EVENT_HEIGHT * i
  };
}

const styles = theme => ({
  speedDial: {
    height: 320,
    marginTop: -305,
    position: "relative",

    "&:hover": {
      zIndex: 1260
    }
  },

  speedDialDot: {
    // width: 7,
    width: 9,
    marginLeft: 2,
    marginRight: 2,
    marginTop: -315
  },

  buttonChartDot: {
    display: "block",
    width: 7,
    height: 7,
    minHeight: 4,

    backgroundColor: colors.brand,
    cursor: "pointer",

    borderRadius: "50%"
  },
  buttonChartDotNegative: {
    "&:not(.important):not(.important)": {
      backgroundColor: colors.red
    }
  },
  buttonChart: {
    marginTop: -4,
    width: 26,
    height: 26,
    minHeight: 26,

    padding: "5px 6px",
    borderRadius: 6,
    boxShadow: BOX_SHADOW_1,
    fontSize: 11,
    fontWeight: 700,
    lineHeight: "16px",

    background: colors.white,

    "&:not(.important)": {
      backgroundColor: colors.white,
      color: colors.textTetrieary,
      opacity: 1
    },

    "&:hover": {
      backgroundColor: colors.brand,
      color: colors.white
    }
  },

  buttonChartActive: {
    "&:not(.important)": {
      backgroundColor: colors.brand,
      color: colors.white
    }
  },

  buttonChartNegative: {
    "&:not(.important):not(.important)": {
      backgroundColor: colors.white,
      color: colors.red
    },
    "&:hover:not(.important):not(.important)": {
      backgroundColor: colors.red,
      color: colors.white
    }
  },
  buttonChartNegativeActive: {
    "&:not(.important):not(.important)": {
      backgroundColor: colors.red,
      color: colors.white
    }
  },

  actionButtonClosedFirst: {
    "&:not(.important):not(.important):not(.important)": {
      transitionProperty: "margin",

      opacity: 1,
      transform: "scale(1)"
    }
  },

  ...actionButtonsZIndexes,
  ...actionButtonsBottomGoal,

  actionButtonClosed: {
    "&:not(.important):not(.important):not(.important)": {
      pointerEvents: "auto",

      "&:not(:first-child)": {
        marginBottom: -25
      }
    }
  },

  actionButtonClosedGoals: {
    "&:not(.important):not(.important):not(.important)": {
      "&:first-child": {
        pointerEvents: "auto"
      }
    }
  },

  actionButton: {
    "&:not(.important)": {
      width: 26,
      margin: 2,
      height: 35,
      // height: 26,
      minHeight: 26,
      padding: "0 4px",
      marginBottom: -12,

      borderRadius: 6,
      // boxShadow: "0px 2px 4px rgba(30, 50, 90, 0.15)",
      filter: "drop-shadow(0px 2px 4px rgba(30, 50, 90, 0.15))",

      boxShadow: "none",

      fontSize: 14,
      lineHeight: "21px",
      color: colors.brand,

      backgroundColor: "transparent",
      backgroundImage: "url(/assets/img/dashboard/drop.png)",
      backgroundSize: "26px 35px",
      backgroundRepeat: "no-repeat",

      "&:not(:first-child)": {
        marginBottom: -12
      },

      "& svg": {
        margin: 1,
        marginTop: -7
      }
    }
  },

  actionMonetary: {
    "&:not(.important)": {
      backgroundImage: "url(/assets/img/dashboard/dropMonetary.png)"
    }
  },
  actionIndependence: {
    "&:not(.important)": {
      backgroundImage: "url(/assets/img/dashboard/dropIndependence.png)"
    }
  },
  actionIndependenceYellow: {
    "&:not(.important)": {
      backgroundImage: "url(/assets/img/dashboard/dropIndependenceYellow.png)"
    }
  },
  actionSpecial: {
    "&:not(.important)": {
      backgroundImage: "url(/assets/img/dashboard/dropSpecial.png)"
    }
  },

  actionButtonDot: {
    "&:not(.important)": {
      marginLeft: -10
    }
  },

  actionButtonYear: {
    "&:not(.important)": {
      width: 36,
      minWidth: 36
    }
  },

  actionsDot: {
    "&:not(.important)": {
      marginBottom: -27
    }
  },

  eventsCounter: {
    position: "absolute",
    top: -12,
    right: -14,
    width: 15,
    height: 15,
    padding: 2,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    fontSize: 11,
    fontWeight: 700,
    lineHeight: 1, //"13px",

    borderRadius: "50%",
    border: `1px solid ${colors.white}`,

    backgroundColor: colors.brand,
    color: colors.white
  },
  eventsCounterActive: {
    border: `1px solid ${colors.white}`
  },
  eventsCounterYear: {
    right: -12
  }
});

const EventIcon = <Icons.IconEvent width={20} height={20} color={"brand"} />;
const EventIconNotActive = (
  <Icons.IconEvent width={20} height={20} color={"grey"} />
);
const GoalIcon = <Icons.IconTrophy width={20} height={20} color={"brand"} />;

const MonetaryIcon = (
  <img
    src={require("assets/img/icon/exclamation.png")}
    alt=""
    style={{
      marginTop: -8,
      marginLeft: -1,
      width: 5,
      height: 16
    }}
  />
);

class ChartFab extends PureComponent {
  state = {
    open: false,
    hidden: false
  };

  handleClick = (id, { isEvent = false, asset } = {}) => ev => {
    if (this.props.handleClickProp && ev) {
      this.props.handleClickProp({ clientX: ev.clientX });
    }

    if (id && this.props.handleEditEvent) {
      this.props.handleEditEvent(id, { isEvent, asset })();
    }

    this.setState(state => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  _renderNegativeMonetary = () => {
    const { classes, isDot, isWhatIfShow, mark } = this.props;

    const tooltipTitle = (
      <FormattedMessage
        id="DASHBOARD.CHART.LABEL.TOOLTIP.MONETARY_NEGATIVE"
        defaultMessage="Monetary Assets is Negative"
      />
    );

    return (
      <SpeedDialAction
        classes={{
          button: `${classes.actionButton} ${classes.actionMonetary} ${
            isDot ? classes.actionButtonDot : ""
          }  ${classes[`actionButtonIndex${START_Z_INDEX}`]}`,
          buttonClosed: `${classes.actionButtonClosed} ${classes.actionButtonClosedFirst}`
        }}
        icon={<Icons.IconDollarCrossed />}
        tooltipTitle={tooltipTitle}
        onMouseEnter={this.handleOpen}
      />
    );
  };

  _renderIndependenceAgeFab = () => {
    const {
      classes,
      isDot,
      isPrimaryHomeSold,
      isWhatIfShow,
      mark
    } = this.props;

    const tooltipTitle = isPrimaryHomeSold ? (
      <FormattedMessage
        id="DASHBOARD.CHART.LABEL.TOOLTIP.FINANCIAL_INDEPENDENCE.SOLD_RESIDENCE"
        defaultMessage="Financial independence age (Primary residence sold)"
      />
    ) : (
      <FormattedMessage
        id="DASHBOARD.CHART.LABEL.TOOLTIP.FINANCIAL_INDEPENDENCE"
        defaultMessage="Financial independence age."
      />
    );

    return (
      <SpeedDialAction
        classes={{
          button: `${classes.actionButton} ${
            isPrimaryHomeSold
              ? classes.actionIndependenceYellow
              : classes.actionIndependence
          } ${isDot ? classes.actionButtonDot : ""}  ${
            classes[`actionButtonIndex${START_Z_INDEX}`]
          }`,
          buttonClosed: `${classes.actionButtonClosed} ${classes.actionButtonClosedFirst}`
        }}
        icon={<Icons.IconUmbrellaIndependence />}
        tooltipTitle={tooltipTitle}
        onMouseEnter={this.handleOpen}
      />
    );
  };

  _renderGoals = () => {
    const {
      classes,
      isDot,
      isWhatIfShow,
      mark,
      age,
      isShowMonetaryNegativeFab,
      isShowIndependenceAgeFab,
      isShowRepeatableEvents
    } = this.props;
    const { open } = this.state;

    let eventsLength = 0;
    let events = [];

    if (mark && mark.event) {
      if (isShowRepeatableEvents) {
        events = mark.event;
      } else {
        events = mark.event.filter(event => event.startAge === age);
      }

      if (isWhatIfShow) {
        eventsLength = events.filter(el => !el.isWhatIf).length;
      } else {
        eventsLength = events.length;
      }
    }

    // ? mark.event.length
    // : mark.event.filter(el => !el.isWhatIf).length
    // : 0;

    const monetaryLength =
      isShowMonetaryNegativeFab || isShowIndependenceAgeFab ? 1 : 0;
    const specialLength =
      mark && mark.specialEvents ? mark.specialEvents.length : 0;

    return mark && mark.goal
      ? mark.goal.map((goal, i) => {
          if (!isWhatIfShow && goal.isWhatIf) {
            return null;
          }

          return (
            <SpeedDialAction
              key={goal.id}
              classes={{
                button: `${classes.actionButton} ${
                  isDot ? classes.actionButtonDot : ""
                } ${
                  classes[
                    `actionButtonIndex${START_Z_INDEX -
                      eventsLength -
                      i -
                      monetaryLength -
                      specialLength}`
                  ]
                } ${
                  !i && !open && eventsLength > 1
                    ? classes[`actionButtonsBottomGoal${eventsLength - 1}`]
                    : ""
                }`,
                buttonClosed: `${classes.actionButtonClosed} ${
                  !i ? classes.actionButtonClosedFirst : ""
                }`
              }}
              icon={GoalIcon}
              tooltipTitle={goal.description}
              onMouseEnter={this.handleOpen}
              onClick={this.handleClick(goal.id, {
                asset: goal,
                isEvent: false
              })}
            />
          );
        })
      : null;
  };

  _renderSpecialEvents = () => {
    const {
      classes,
      isDot,
      isWhatIfShow,
      mark,
      isMobile,
      age,
      isShowMonetaryNegativeFab,
      isShowIndependenceAgeFab
    } = this.props;
    const { open } = this.state;

    const monetaryLength =
      isShowMonetaryNegativeFab || isShowIndependenceAgeFab ? 1 : 0;

    return mark && mark.specialEvents
      ? mark.specialEvents.map((event, i) => {
          return (
            <SpeedDialAction
              key={event.id}
              classes={{
                button: `${classes.actionButton} ${classes.actionSpecial} ${
                  isDot ? classes.actionButtonDot : ""
                } ${
                  classes[
                    `actionButtonIndex${START_Z_INDEX - monetaryLength - i}`
                  ]
                }
                ${
                  !open && monetaryLength
                    ? classes[`actionButtonsBottomGoal${1 - monetaryLength}`]
                    : ""
                }
                `,
                buttonClosed: `${classes.actionButtonClosed} ${classes.actionButtonClosedFirst}`
              }}
              icon={null}
              tooltipTitle={event.message}
              // disableHoverListener={isMobile}
              onMouseEnter={this.handleOpen}
              // onClick={this.handleClick(event.id, {
              //   asset: event,
              //   isEvent: true
              // })}
            />
          );
        })
      : null;
  };

  _renderEvents = () => {
    const {
      classes,
      isDot,
      isWhatIfShow,
      mark,
      isMobile,
      age,
      isShowMonetaryNegativeFab,
      isShowIndependenceAgeFab,
      isShowRepeatableEvents
    } = this.props;
    const { open } = this.state;

    const monetaryLength =
      isShowMonetaryNegativeFab || isShowIndependenceAgeFab ? 1 : 0;

    const specialLength =
      mark && mark.specialEvents ? mark.specialEvents.length : 0;
    // let isShowed = false;

    let iShowed = -1;

    const events =
      mark && mark.event
        ? isShowRepeatableEvents
          ? mark.event
          : mark.event.filter(event => event.startAge === age)
        : null;

    return events
      ? events.map((event, i) => {
          if (!isWhatIfShow && event.isWhatIf) {
            return null;
          }

          iShowed++;

          // const closedClass =
          //   !isShowed && event.startAge === age
          //     ? classes.actionButtonClosedFirst
          //     : "";
          // isShowed = !!closedClass;

          return (
            <SpeedDialAction
              key={event.id}
              classes={{
                button: `${classes.actionButton} ${
                  isDot ? classes.actionButtonDot : ""
                } ${
                  classes[
                    `actionButtonIndex${START_Z_INDEX -
                      iShowed -
                      monetaryLength -
                      specialLength}`
                  ]
                }
                ${
                  !iShowed && !open && monetaryLength
                    ? classes[
                        `actionButtonsBottomGoal${1 -
                          monetaryLength -
                          specialLength}`
                      ]
                    : ""
                }
                `,
                buttonClosed: `${classes.actionButtonClosed} ${
                  !iShowed ? classes.actionButtonClosedFirst : ""
                }`
              }}
              icon={event.isNotActive ? EventIconNotActive : EventIcon}
              tooltipTitle={event.description}
              // disableHoverListener={isMobile}
              onMouseEnter={this.handleOpen}
              onClick={this.handleClick(event.id, {
                asset: event,
                isEvent: true
              })}
            />
          );
        })
      : null;
  };

  render() {
    const {
      classes,
      active,
      isDot,
      isShowMonetaryNegativeFab,
      isShowIndependenceAgeFab,
      isYear,
      buttonChartNegative,
      buttonChartNegativeActive,
      title,
      markCount,
      styleOffset
    } = this.props;
    const { hidden, open } = this.state;

    // const fabCounter =
    //   !isDot && markCount ? (
    //     <div
    //       className={`${classes.eventsCounter} ${
    //         active ? classes.eventsCounterActive : ""
    //       } ${isYear ? classes.eventsCounterYear : ""}  `}
    //     >
    //       {markCount}
    //     </div>
    //   ) : null;
    const fabCounter = null;

    const fabContent = !isDot ? (
      <span style={{ position: "relative" }}>
        {title}
        {fabCounter}
      </span>
    ) : (
      <span />
    );

    return (
      <SpeedDial
        ariaLabel=" "
        className={`${classes.speedDial} ${isDot ? classes.speedDialDot : ""}`}
        classes={{
          fab: `${isDot ? classes.buttonChartDot : classes.buttonChart} ${
            active ? classes.buttonChartActive : ""
          } ${
            buttonChartNegative
              ? isDot
                ? classes.buttonChartDotNegative
                : classes.buttonChartNegative
              : ""
          } ${
            buttonChartNegativeActive ? classes.buttonChartNegativeActive : ""
          } ${isYear ? classes.actionButtonYear : ""}`,
          // actions: isDot ? classes.actionsDot : "classes.buttonChart"
          actions: isDot ? classes.actionsDot : ""
        }}
        hidden={hidden}
        icon={fabContent}
        onBlur={this.handleClose}
        onClick={this.handleClick()}
        onClose={this.handleClose}
        onFocus={this.handleOpen}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        open={open}
        style={styleOffset}
      >
        {isShowMonetaryNegativeFab && this._renderNegativeMonetary()}
        {isShowIndependenceAgeFab && this._renderIndependenceAgeFab()}
        {this._renderSpecialEvents()}
        {this._renderEvents()}
        {this._renderGoals()}
      </SpeedDial>
    );
  }
}

ChartFab.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickProp: PropTypes.func,
  handleEditEvent: PropTypes.func,
  markCount: PropTypes.any,
  age: PropTypes.any,
  mark: PropTypes.any,
  title: PropTypes.any,
  isWhatIfShow: PropTypes.bool,
  isShowRepeatableEvents: PropTypes.bool,
  isShowMonetaryNegativeFab: PropTypes.bool,
  isShowIndependenceAgeFab: PropTypes.bool,
  isYear: PropTypes.bool,
  isDot: PropTypes.bool,
  buttonChartNegative: PropTypes.bool,
  buttonChartNegativeActive: PropTypes.bool,
  active: PropTypes.bool,
  empty: PropTypes.bool
};

export default withStyles(styles)(ChartFab);
