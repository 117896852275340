import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import { compose } from "redux";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AssetCategoryCommon from "components/AssetsCommon/AssetCategoryCommon";

import {
  GLOBAL_TYPE_USE_ASSETS,
  GLOBAL_COLLECTION,
  GLOBAL_TYPE_COLLECTION,
  ENTITIES,
  ENTITIES_GROUPS,
  ENTITIES_ADDITIONAL,
  formScheme,
  designScheme,
  formsValidationScheme,
  formsInitialState,
  RIGHT_LEVEL,
  RIGHT_SHARE,
  translations
} from "./formScheme";
import { itemsSorted } from "../../store/selectors/firebase";
import AccessForbidden from "../../routeHandlers/AccessForbidden";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";

const GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU = (
  <FormattedMessage
    id="DEBT.FORM.TOP.SHOW_CONTRIBUTION"
    defaultMessage="Show Contribution"
  />
);

export class MoneyOutOther extends Component {
  calculateSummary = () => {
    const { assets } = this.props;

    if (!assets) {
      return null;
    }

    const summary = { total: 0 };

    Object.keys(designScheme).forEach(key => {
      summary[key] = 0;
    });

    assets.forEach(el => {
      if (el && !el.isDisabled && !el.isExpired && !el.isWhatIf && !el.isWhatIfTest) {
        const value = +el.annualCost || 0;

        summary[el.entity] += value;
        summary.total += value;
      }
    });

    return summary;
  };

  helperAssetFromArrayToObject = assets => {
    if (!assets) {
      return [];
    }

    const temp = {};

    assets.forEach(asset => {
      temp[asset.id] = asset;
    });

    return temp;
  };

  render() {
    const {
      auth,
      assets,
      profile,
      firestoreStatus,
      childs,
      profileProvider
    } = this.props;

    if (!profile.isLoaded || firestoreStatus.requesting.assets) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareHousehold ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const isAccessEdit =
      isOwnProfile ||
      (this.props.rights && this.props.rights[RIGHT_LEVEL] === "1");

    return (
      <AssetCategoryCommon
        isDialog={this.props.isDialog}
        isAccessEdit={isAccessEdit}
        profile={profile}
        assets={assets}
        summaryData={this.calculateSummary()}
        childs={childs}
        formScheme={formScheme}
        auth={auth}
        ENTITIES={ENTITIES}
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        designScheme={designScheme}
        formsInitialState={formsInitialState}
        formsValidationScheme={formsValidationScheme}
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={GLOBAL_COLLECTION}
        GLOBAL_TYPE_COLLECTION={GLOBAL_TYPE_COLLECTION}
        GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU={
          GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU
        }
        translations={translations}
      />
    );
  }
}

MoneyOutOther.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  assets: PropTypes.any,
  rights: PropTypes.any,
  profileProvider: PropTypes.any
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { profileProvider }
}) {
  const rights = ordered && ordered.rights;

  return {
    auth,
    profile,
    assets: ordered && itemsSorted(ordered.assetsMoneyOutOther),
    childs: ordered && ordered.childs,
    profileProvider: profileProvider || auth.uid,
    rights: rights && rights[0],
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: GLOBAL_COLLECTION || "userAssets",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_USE_ASSETS]
        ],
        storeAs: "assetsMoneyOutOther"
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(MoneyOutOther));
