import { SET_UI, SET_BOOT_DATA } from "../constants";
import { valuesStatTypes } from "../components/Dashboard/CalcContainer.scheme";

const defaultState = {
  isMobile: false,
  mobileMenuHandler: null,
  dashboardHideChart: false,
  dashboardCompareTo: false,
  dashboardIfChecked: false,
  dashboardIfCheckedSecondary: false,
  dashboardWealthIncome: false,
  dashboardWealthIncomeSecondary: false,
  dashboardNoOverdraft: true,
  dashboardAgeYear: false,
  // dashboardAgeYearSecondary: false,
  profileProvider: null,
  isDrawerOpen: false,
  dashboardChartTerm: "20",
  dashboardShowRepeatableEvents: false,
  // dashboardChartTermSecondary: "20",
  advisorSummaryType: valuesStatTypes.SUMMARY
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_UI:
      return { ...state, ...payload };

    case SET_BOOT_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
};
