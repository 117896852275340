import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { FormattedMessage } from "react-intl-v3";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";

const styles = {
  container: {
    padding: "44px 60px 56px 60px",
    textAlign: "center"
  },
  welcomeImage: {},
  subheading: {
    marginBottom: "16px",
    color: "#232735"
  },
  desc: {
    marginBottom: "16px",
    color: "#232735"
  },
  button: {
    marginTop: "30px"
  }
};

class DialogWelcome extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    name: PropTypes.string
  };

  state = {
    step: null
  };

  render() {
    const { classes, onClose, open, name } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <div className={classes.container}>
          <div className={classes.welcomeImage}>
            <img src="/assets/img/quiz/welcome.png" alt="" />
          </div>

          <DialogTitle id="simple-dialog-title">
            <FormattedMessage
              id="QUIZ.WELCOME.HEADING"
              defaultMessage="Welcome to XCurve"
            />
          </DialogTitle>

          <Typography
            className={classes.subheading}
            component="p"
            variant="subheading"
          >
            {!!name ? (
              <FormattedMessage
                id="QUIZ.WELCOME.GREET"
                defaultMessage="Welcome, {name}. Looks like this is the first time you are using XCurve."
                values={{ name }}
              />
            ) : (
              <FormattedMessage
                id="QUIZ.WELCOME.GREET_WO_NAME"
                defaultMessage="Welcome. Looks like this is the first time you are using XCurve."
              />
            )}
          </Typography>

          <Typography className={classes.desc} component="p" variant="body1">
            <FormattedMessage
              id="QUIZ.WELCOME.DESC1"
              defaultMessage="XCurve makes it very easy for you to see and plan your financial future. It can also help you save money, protect your wealth and secure your financial future. While the information is yours and yours alone, you are free to share it with other people such as a financial advisor or family member."
            />
          </Typography>

          <Typography className={classes.desc} component="p" variant="body1">
            <FormattedMessage
              id="QUIZ.WELCOME.DESC2"
              defaultMessage="XCurve will now guide you though several steps to set up your household profile, but don’t worry, you can come back and change it anytime."
            />
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onClose}
          >
            <FormattedMessage id="QUIZ.BUTTON.CONTINUE" />
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWelcome);
