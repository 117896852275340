import {
  addAdditionalConfiguration,
  prepareState,
  repeatableFields as repeatableCommon
} from "../AssetsCommon/_formScheme";
import {
  RIGHT_HOUSEHOLD_MONEY_OUT,
  RIGHT_HOUSEHOLD_MONEY_OUT_PERSONAL
} from "constants/rights";

export const GLOBAL_COLLECTION = "transactions";
export const GLOBAL_TYPE_COLLECTION = "out";
export const GLOBAL_TYPE_USE_ASSETS = "money-out-personal";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_MONEY_OUT;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_MONEY_OUT_PERSONAL;

const ENTITY_INSURANCE = "insurance";
const ENTITY_MEDICAL = "medical";
const ENTITY_EDUCATION = "education";
const ENTITY_NECESSITIES = "necessities";
const ENTITY_LEISURE = "leisure";
const ENTITY_DEBT = "debt";
const ENTITY_OTHER = "other";

const ENTITY_GENERAL = "general";

export const ENTITIES = {
  ENTITY_INSURANCE,
  ENTITY_MEDICAL,
  ENTITY_EDUCATION,
  ENTITY_NECESSITIES,
  ENTITY_LEISURE,
  ENTITY_DEBT,
  ENTITY_OTHER,
  ENTITY_GENERAL
};

// const ENTITY_ADD_CONTRIBUTION = "contribution";
export const MONEY_OUT_ENDING_SOON = 3;

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.MONEY_OUT.FORM_GROUP.TITLE": {
    title: "Personal money out type",
    ENTITIES: {
      ENTITY_INSURANCE,
      ENTITY_MEDICAL,
      ENTITY_EDUCATION,
      ENTITY_NECESSITIES,
      ENTITY_LEISURE,
      ENTITY_DEBT,
      ENTITY_OTHER
    }
  }
};

const optionsOwnerAll = [
  // {
  //   name: "General",
  //   nameI18n: "HOUSEHOLD.COMMON.FORM.TYPE.GENERAL.LABEL",
  //   value: "general"
  // },
  {
    name: "You",
    value: "You"
  }
];

const repeatableSchemeCommon = {
  description: {
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.DESCRIPTION.LABEL"
  }
};

const repeatableSchemeGeneral = {
  annualCost: {
    isMain: true,

    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Annual Cost",
    labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.ANNUAL_COST.TITLE"
  },
  startDate: {
    isDate: true,
    isClearable: true,
    label: "Start Date",
    labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.START_DATE.TITLE"
  },
  durationYears: {
    ...repeatableCommon.durationYears,

    // calculate: { noop: true },
    expiredCalculate: { isYearsLeftPeriod: true, fieldDate: "startDate" },
    endingSoonCalculate: {
      monthsLeftPeriod: MONEY_OUT_ENDING_SOON,
      fieldDate: "startDate",
      MoneyOutYears: true
    },

    condition: [
      {
        field: "startDate",
        isFilled: true
      }
    ]
  },

  // fromAge: { ...repeatableCommon.fromAge },
  // toAge: { ...repeatableCommon.toAge },
  owner: {
    isSubTitle: true,
    isAdditional: true,
    isFullWidth: true,

    isSelect: true,
    options: optionsOwnerAll,
    optionsCustom: "custom-members-all",
    default: "general",
    isShowNoneOption: "general",
    isShowNoneLabel: "General",
    isShowNoneLabelI18n: "General", // TODO move all none props to object
    label: "Owner",
    labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.OWNER.TITLE"
  }
};

// const repeatableSchemeGeneralWoOwner = {
//   description: repeatableSchemeGeneral.description,
//   value: repeatableSchemeGeneral.value
// };

export const designScheme = {
  [ENTITIES.ENTITY_INSURANCE]: {
    icon: "IconUmbrella",
    color: "green",
    title: "Insurance",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.INSURANCE"
  },
  [ENTITIES.ENTITY_MEDICAL]: {
    icon: "IconMedical",
    color: "green",
    title: "Medical",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.MEDICAL"
  },

  [ENTITIES.ENTITY_EDUCATION]: {
    icon: "IconStudent",
    color: "green",
    title: "Education",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.EDUCATION"
  },

  [ENTITIES.ENTITY_NECESSITIES]: {
    icon: "IconCart",
    color: "green",
    title: "Necessities",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.NECESSITIES"
  },

  [ENTITIES.ENTITY_LEISURE]: {
    icon: "IconAirplane",
    color: "green",
    title: "Leisure",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.LEISURE"
  },

  [ENTITIES.ENTITY_DEBT]: {
    icon: "IconAccount",
    color: "green",
    title: "Debt Repayment",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.DEBT"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "General Assets",
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TILE.GENERAL"
  }
};

export const ENTITIES_ADDITIONAL = {};

export const formScheme = {
  [ENTITIES.ENTITY_INSURANCE]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.INSURANCE.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_MEDICAL]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.MEDICAL.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_EDUCATION]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.EDUCATION.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_NECESSITIES]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n:
        "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.NECESSITIES.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_LEISURE]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.LEISURE.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },
  [ENTITIES.ENTITY_DEBT]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.DEBT.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral,
    debtId: {
      isAdditional: true,
      isFullWidth: true,

      isAdditionalAsset: "debts",
      additionalAssetField: "description",
      optionsCustomAsset: "debts",
      fieldCustom: "debtId",

      isSelect: true,
      default: "general",
      isShowNoneOption: "general",
      isShowNoneLabel: "General",

      label: "Debt",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.DEBT.LABEL"
    }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.OTHER.DESCRIPTION.LABEL"
    },
    ...repeatableSchemeGeneral
  },

  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeCommon.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.GENERAL.DESCRIPTION.LABEL"
    },
    annualCost: { ...repeatableSchemeGeneral.annualCost }
  }
};

addAdditionalConfiguration(ENTITIES, ENTITIES_ADDITIONAL, GLOBAL_TYPE_USE_ASSETS);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL",
        title: "Living Expenses"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TITLE",
      title: "Ok. Now let’s dig into your regular personal spending"
    },
    caption: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.CAPTION",
      title:
        "We’ll help make sure you always have enough to cover your family’s needs"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.EMPTY",
      title:
        "You have no personal money out,\n please add your first personal money out"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.H2",
      title: "Money Out"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TOAST.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TOAST.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.TOAST.SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.SIDE_MENU_TITLE",
      title: "Add Personal Expenses"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.SIDE_MENU_TITLE.EDIT",
      title: "Edit Personal Expenses"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM_GROUP.TITLE",
      title: "Personal Money Out Type"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.GENERAL_GROUP",
      title: "General Personal Expenses",
      caption:
        "Sure, you can enter the total value of all your personal expanses, but we will not be able to predict their future value. To continue anyway, enter a value and select “General Personal Expanses”",
      captionI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.VALUE.BUTTON.SAVE",
      title: "Save"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.FORM.VALUE.BUTTON",
      title: "+ Add"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.WHY.TITLE",
    textI18n: "HOUSEHOLD.MONEY_OUT.PERSONAL.WHY.ALL_TEXT"
  }
};
