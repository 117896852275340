import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { withRouter } from "react-router-dom";

import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer/Drawer";

import styles from "./styles";
import "./drawer-menu.scss";

import { drawMenu } from "constants/menu";
import AppDrawerMenu from "./AppDrawerMenu";

class AppDrawer extends Component {
  static propTypes = {
    open: PropTypes.bool,
    isMobile: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
  };

  state = {
    currentMenu: [],
    currentPath: null,
    currentItem: null
  };

  componentDidMount() {
    this.handleUpdateMenu();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { pathname } = this.props.location;

    const currentPath = pathname.split("/")[1];

    if (currentPath && currentPath !== this.state.currentPath) {
      this.handleUpdateMenu();
    }
  }

  handleUpdateMenu = () => {
    const { pathname } = this.props.location;

    const currentPath = pathname.split("/")[1];

    const currentMenu = drawMenu[currentPath] || [];

    this.setState({
      currentMenu,
      currentPath,
      fullPath: pathname
    });
  };

  handleSelectItem = currentItem => () => {
    this.setState({ currentItem });
  };

  render() {
    const { classes, open, handleDrawerToggle, isMobile, isAdvisor } = this.props;

    return (
      <Drawer
        variant="persistent"
        classes={{
          root: classNames(!open ? classes.drawerRootClose : classes.drawerRootOpen),
          paper: classNames(
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          )
        }}
        open={open}
      >
        <AppDrawerMenu
          menu={this.state.currentMenu}
          handleSelectItem={this.handleSelectItem}
          handleDrawerToggle={handleDrawerToggle}
          currentItem={this.state.currentItem}
          fullPath={this.state.fullPath}
          isMobile={isMobile}
          isAdvisor={isAdvisor}
        />
      </Drawer>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter
)(AppDrawer);
