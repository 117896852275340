import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconOther = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M4 4V8H8V4H4ZM10 4V8H14V4H10ZM16 4V8H20V4H16ZM4 10V14H8V10H4ZM10 10V14H14V10H10ZM16 10V14H20V10H16ZM4 16V20H8V16H4ZM10 16V20H14V16H10ZM16 16V20H20V16H16Z"
        fill={fill}
        fillOpacity={opacity}
      />
    </svg>
  );
};

IconOther.propTypes = {
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconOther;
