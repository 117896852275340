import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Slide from "@material-ui/core/Slide";
import { FormattedMessage } from "react-intl-v3";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FormDialog extends React.Component {
  static propTypes = {
    text: PropTypes.any,
    title: PropTypes.any,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAgreeAndClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.any,
    disableActions: PropTypes.bool,
    disableTitle: PropTypes.bool,
    okButton: PropTypes.any,
    contentClass: PropTypes.any
  };

  render() {
    const {
      isOpen,
      handleAgreeAndClose,
      handleClose,
      fullScreen,
      fullWidth,
      maxWidth,
      text,
      title,
      okButton,
      content,
      children,
      disableTitle,
      disableActions,
      contentClass
    } = this.props;

    return (
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        {isOpen && (
          <>
            {!disableTitle && (
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            )}
            <DialogContent className={contentClass || ""}>
              <DialogContentText>
                {!children && !!text && text}
              </DialogContentText>
              {!!children && children}
            </DialogContent>
            {!disableActions && (
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
                </Button>
                <Button onClick={handleAgreeAndClose} color="secondary">
                  {okButton ? (
                    okButton
                  ) : (
                    <FormattedMessage id="APP.OK" defaultMessage="Ok" />
                  )}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    );
  }
}

export default FormDialog;
