import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import Grid from "@material-ui/core/Grid";
import HouseholdCard from "../HouseholdMembers/HouseholdCard";
import { compose } from "redux";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";

import "./DasboardAdvisor.scss";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import ButtonAddAsset from "../_common/Button/ButtonAddAsset";
import ButtonCommon from "../_common/Button/ButtonCommon";
import diffYears from "date-fns/differenceInCalendarYears";

import {
  GLOBAL_TYPE_USE_ASSETS as typeSavings,
  ENTITIES as entitiesSavings
} from "components/HouseholdAssetsSavings/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as typeGoal,
  ENTITIES as entitiesGoals
} from "components/PlansGoals/formScheme";

import "../Dashboard/Dashboard.scss";
import * as Icons from "../_common/icons";
import FormControlInput from "../_common/FormControl/FormControlInput";

import {
  getAssetsAllData,
  getTransactionsAllData
} from "../../store/selectors/firebase";
import { withStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { cloneObject, formatMoneyIntWithSign } from "../../lib/helpers";
import FormControlSelect from "../_common/FormControl/FormControlSelect";
import {
  INFLATION,
  INFLATION_PERCENT
} from "../../constants/taxesAndDashboard";
import toast from "../_common/toast";
import { optionsValuesHome } from "../HouseholdAssetsUse/formScheme";
import { DataWithBar } from "components/Dashboard/Statistic";
import { DEFAULT_EXPECTED_RETIREMENT_AGE, MAX_AGE } from "../../constants";
const optionsYesNoMaybe = [
  {
    name: "Yes",
    nameI18n: "MY_ADVISOR.STAT.YES.LABEL",
    value: "yes"
  },
  {
    name: "No",
    nameI18n: "MY_ADVISOR.STAT.NO.LABEL",
    value: "no"
  },
  {
    name: "Maybe",
    nameI18n: "MY_ADVISOR.STAT.MAYBE.LABEL",
    value: "maybe"
  }
];

const styles = () => ({
  inputAdvisor: {
    marginBottom: 0,

    "& input": {
      fontSize: 14,
      padding: "1px 0 2px"
    }
  }
});

export const defaultDataAdvisor = {
  products: {
    You: {
      IRA: 0,
      "401K": 0,
      lifeInsurance: 0,
      annuity: 0,
      all: 0
    },
    Spouse: {
      IRA: 0,
      "401K": 0,
      lifeInsurance: 0,
      annuity: 0,
      all: 0
    },
    other: {
      "529college": 0,
      other: 0,
      all: 0
    }
  },

  assets: {
    lifeInsurance: {
      cash: 0
    },
    cash: {
      checkingAndSavings: 0,
      moneyMarketAndCD: 0
    },

    "529college": {},

    educationWithUntilAttend: [],
    educationTotal: 0,
    debt: {
      primary: 0,
      vacation: 0,
      investments: 0,
      all: 0
    },
    home: {
      primary: 0,
      vacation: 0,
      investments: 0,
      all: 0
    }
  },

  lifeInsurance: {
    userMortgage: 0,
    userOtherDebt: 0,
    userHighEducation: 0,
    userAnnualIncome: 0,
    userAnnualInflation: INFLATION_PERCENT,
    userRecommendedAmount: 0,
    userExistingInsurance: 0,
    userOpp: "",

    spouseMortgage: 0,
    spouseOtherDebt: 0,
    spouseHighEducation: 0,
    spouseAnnualIncome: 0,
    spouseAnnualInflation: INFLATION_PERCENT,
    spouseRecommendedAmount: 0,
    spouseExistingInsurance: 0,
    spouseOpp: ""
  },
  cashAndDebt: {
    checkingAmount: 0,
    moneyMarketAmount: 0,
    investmentsAmount: 0,
    Retirement: 0
  },
  emergencyFund: {
    notes: "",
    monthlyIncome: 0,
    emergencyMultiple: 6,
    recommendedAmount: 0,
    existingFund: 0
  },
  majorIncome: {},
  financialIndependence: {
    lifeExpectancy: MAX_AGE,

    annualInflation: INFLATION_PERCENT,
    estimatedLifetime: 0,
    estimatedLifetimeNet: 0,
    expectedRetirementAge: 0,

    gapToIndependence: 0,
    gapToIndependenceWhatIf: 0,
    opportunity: "",

    ageFullDepletion: 0,
    stopWorking: "",
    //
    passiveIncome: 0
  },
  temp: {
    goals: {
      education: 0,
      retirement: 0,
      home: 0,
      legacy: 0,
      major: 0,
      other: 0,
      general: 0
    }
  }
};

class DashboardAdvisor extends Component {
  static propTypes = {};

  state = {
    forms: {
      lifeInsurance: {
        notes: "",
        userMortgage: "",
        userOtherDebt: "",
        userHighEducation: "",
        userAnnualIncome: "",
        userAnnualInflation: INFLATION_PERCENT,
        userRecommendedAmount: "",
        userOpp: "",

        spouseMortgage: "",
        spouseOtherDebt: "",
        spouseHighEducation: "",
        spouseAnnualIncome: "",
        spouseAnnualInflation: INFLATION_PERCENT,
        spouseRecommendedAmount: "",
        spouseOpp: "",

        isSaved: false,
        isDone: false
      },
      cashDebt: {
        notes: "",
        cashOpp: "",
        debtOpp: "",

        isSaved: false,
        isDone: false
      },
      realEstate: {
        notes: "",
        ownedOpp: "",
        rentedOpp: "",

        isSaved: false,
        isDone: false
      },
      higheducationNeeds: {
        notes: "",
        advisor: "no",

        isSaved: false,
        isDone: false
      },
      emergencyFund: {
        notes: "",
        monthlyIncome: "",
        emergencyMultiple: "",
        opp: "",
        existingFund: 0,
        recommendedAmount: 0,

        isSaved: false,
        isDone: false
      },
      taxAsset: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      investmentDiversification: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      majorIncome: {
        notes: "",
        opp: "",

        isSaved: false,
        isDone: false
      },
      financialIndependence: {
        notes: "",
        lifeExpectancy: "",
        annualInflation: "",
        estimatedLifetime: 0,
        estimatedLifetimeNet: 0,
        gapToIndependence: 0,
        expectedRetirementAge: 0,

        ageFullDepletion: MAX_AGE,
        opportunity: "",

        isSaved: false,
        isDone: false
      },
      temp: {
        goals: {
          education: 0,
          retirement: 0,
          home: 0,
          legacy: 0,
          major: 0,
          other: 0,
          general: 0
        }
      }
    },

    members: {},
    isSaving: false,
    expanded: "panel1",
    data: defaultDataAdvisor
  };

  componentDidMount() {
    this.updateStateAdvisor();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      (!prevProps.advisorDashboard && !!this.props.advisorDashboard) ||
      (prevProps.advisorDashboard &&
        this.props.advisorDashboard &&
        prevProps.advisorDashboard.id !== this.props.advisorDashboard.id)
    ) {
      this.updateStateAdvisor();
    }
  }

  updateProducts = (firstYear, members) => {
    const defaultProducts = cloneObject(defaultDataAdvisor.products);
    const collegeFund = {};

    const debt = { primary: 0, vacation: 0, investment: 0, other: 0, all: 0 };
    const home = { primary: 0, vacation: 0, investment: 0, other: 0, all: 0 };

    if (firstYear) {
      Object.keys(firstYear.assetsAll.savings).forEach(elKey => {
        const entities = firstYear.assetsAll.savings[elKey];

        if (
          elKey === "otherUnknown" ||
          elKey === "otherOrdinary" ||
          elKey === "otherCapital" ||
          elKey === "otherNoTax" ||
          elKey === "529college"
        ) {
          Object.keys(entities).forEach(entityKey => {
            const entity = entities[entityKey];

            if (elKey === "529college") {
              defaultProducts.other["529college"] += entity.value;

              collegeFund[entity.owner] =
                (collegeFund[entity.owner] || 0) + entity.value;
            } else {
              defaultProducts.other.other += entity.value;
            }
            defaultProducts.other.all += entity.value;
          });
        } else {
          if (elKey === "IRA") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.IRA += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse.IRA += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }

          if (elKey === "401k") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You["401K"] += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse["401K"] += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }
          if (elKey === "lifeInsurance") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.lifeInsurance += entity.deathBenefit;
                defaultProducts.You.all += entity.deathBenefit;
              } else {
                defaultProducts.Spouse.lifeInsurance += entity.deathBenefit;
                defaultProducts.Spouse.all += entity.deathBenefit;
              }
            });
          }
          if (elKey === "annuityUnqualified" || elKey === "annuityQualified") {
            Object.keys(entities).forEach(entityKey => {
              const entity = entities[entityKey];

              if (
                members.You.isMarried &&
                (!entity.owner || entity.owner === "You")
              ) {
                defaultProducts.You.annuity += entity.value;
                defaultProducts.You.all += entity.value;
              } else {
                defaultProducts.Spouse.annuity += entity.value;
                defaultProducts.Spouse.all += entity.value;
              }
            });
          }
        }
      });

      Object.keys(firstYear.assetsAll.debt.home).forEach(entityKey => {
        const entity = firstYear.assetsAll.debt.home[entityKey];

        debt[
          entity.assetType === optionsValuesHome.MULTIPLE
            ? "other"
            : entity.assetType
        ] += entity.value;
        debt.all += entity.value;
      });
      Object.keys(firstYear.assetsAll.use.home).forEach(entityKey => {
        const entity = firstYear.assetsAll.use.home[entityKey];

        home[
          entity.type === optionsValuesHome.MULTIPLE ? "other" : entity.type
        ] += entity.value;
        home.all += entity.value;
      });
    }

    return { defaultProducts, collegeFund, debt, home };
  };

  getEventsFor10Yrs = () => {
    const { propsState } = this.props;

    const { fullCalc } = propsState ? propsState : null;

    const events = [];

    if (fullCalc) {
      const tempValues = Object.values(fullCalc);

      for (let i = 0; i < 10; i++) {
        const tempYear = tempValues[i];

        if (tempYear) {
          Object.keys(tempYear.assetsAll.events.moneyIn).forEach(eventKey => {
            const event = tempYear.assetsAll.events.moneyIn[eventKey];

            if (!event.isWhatIf) {
              events.push({
                id: eventKey,
                year: tempYear.year,
                value: event.value,
                description: event.description,
                yearsUntilOccurs: i
              });
            }
          });
        }
      }
    }

    return events;
  };

  updateStateAdvisor = () => {
    const { advisorDashboard, childs, propsState, family } = this.props;

    const { fullCalc } = propsState ? propsState : null;

    ///////// CALC DATA //////////////////////////////////////////////////////////
    // collect family data
    const members = {
      You: {
        age: 18,
        name: family ? family.displayName : "",
        isMarried: false
      }
    };

    if (family && family.birthday && family.birthday.seconds) {
      members.You.age = diffYears(Date.now(), family.birthday.toDate());
      members.You.isMarried =
        family.relationshipStatus === "married" ||
        family.relationshipStatus === "established";
    }

    if (
      family &&
      family.relationshipStatus &&
      members.You.isMarried &&
      family.spouse &&
      family.spouse.birthday &&
      family.spouse.birthday.seconds
    ) {
      const age = diffYears(Date.now(), family.spouse.birthday.toDate());

      members.Spouse = { age, name: family.spouse.spouseName };
    }

    if (childs && childs.length) {
      childs.forEach(child => {
        const age = diffYears(Date.now(), child.birthday.toDate());

        members[child.id] = {
          ...child,
          age
        };
      });
    }
    // collect family data end

    const newStateData = cloneObject(defaultDataAdvisor);
    const educationInflation = {
      you: 0,
      spouse: 0
    };
    const firstYear = Object.values(fullCalc)[0];

    const { defaultProducts, collegeFund, debt, home } = this.updateProducts(
      firstYear,
      members
    );
    newStateData.products = defaultProducts;
    newStateData.assets["529college"] = collegeFund;
    newStateData.assets.debt = debt;
    newStateData.assets.home = home;
    newStateData.assets.educationWithUntilAttend = [];

    const events = this.getEventsFor10Yrs();

    if (this.props.assetsAll && firstYear) {
      let isEducationGoalExist = false;

      this.props.assetsAll.forEach(el => {
        if (el.isDisabled || el.isWhatIf) {
          return;
        }

        if (el.globalType === typeGoal) {
          if (el.entity === entitiesGoals.ENTITY_GENERAL) {
            newStateData.temp.goals.general += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_OTHER) {
            newStateData.temp.goals.other += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_MAJOR_EXPANSE) {
            newStateData.temp.goals.major += +el.amount || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_LEGACY) {
            newStateData.temp.goals.legacy += +el.amountToLeave || 0;
          }
          if (el.entity === entitiesGoals.ENTITY_HOME_PURCHASE) {
            newStateData.temp.goals.home += +el.cost || 0;
          }

          /// TOP TILES DATA
          if (el.entity === entitiesGoals.ENTITY_RETIREMENT) {
            newStateData.financialIndependence.lifeExpectancy =
              el.lifeExpectancy || MAX_AGE;

            if (el.atAge && el.starts === "atAge") {
              newStateData.financialIndependence.expectedRetirementAge =
                el.atAge;
            }

            if (el.annualAmount) {
              newStateData.temp.goals.retirement += +el.annualAmount || 0;
            }
          }

          if (el.entity === entitiesGoals.ENTITY_EDUCATION) {
            isEducationGoalExist = true;

            const untilAttend =
              el.ageAttending && members && members[el.personAttending]
                ? el.ageAttending - members[el.personAttending].age
                : 0;

            if (untilAttend >= 0) {
              const educationAmount = el.howManyYears * el.amountPerYear;
              newStateData.temp.goals.education += educationAmount;

              const withInflation =
                Math.pow(1 + INFLATION, untilAttend) * educationAmount;

              newStateData.assets.educationTotal += educationAmount;
              newStateData.assets.educationWithUntilAttend.push({
                untilAttend,
                educationAmount,
                personAttending: el.personAttending
              });

              if (el.personAttending === "You") {
                newStateData.lifeInsurance.userHighEducation += educationAmount;

                educationInflation.you += withInflation;
              }

              if (el.personAttending === "Spouse") {
                newStateData.lifeInsurance.spouseHighEducation += educationAmount;

                educationInflation.spouse += withInflation;
              }

              if (
                el.personAttending !== "You" &&
                el.personAttending !== "Spouse"
              ) {
                newStateData.lifeInsurance.spouseHighEducation += educationAmount;
                newStateData.lifeInsurance.userHighEducation += educationAmount;

                educationInflation.spouse += withInflation;
                educationInflation.you += withInflation;
              }
            }
          }
        }

        if (
          el.entity === entitiesSavings.ENTITY_LIFE_INSURANCE &&
          el.globalType === typeSavings
        ) {
          const deathBenefit = +el.deathBenefit || 0;
          newStateData.assets.lifeInsurance.cash += +el.cashValue || 0;

          if (el.owner === "You") {
            newStateData.lifeInsurance.userExistingInsurance += deathBenefit;
          }

          if (el.owner === "Spouse") {
            newStateData.lifeInsurance.spouseExistingInsurance += deathBenefit;
          }
        }
      });

      if (!isEducationGoalExist && childs && childs.length) {
        const educationDefault = childs.length * 4 * 35000;
        newStateData.lifeInsurance.spouseHighEducation = educationDefault;
        newStateData.lifeInsurance.userHighEducation = educationDefault;
      }

      newStateData.lifeInsurance.userMortgage =
        firstYear && firstYear.Liabilities ? firstYear.Liabilities.mortgage : 0;
      newStateData.lifeInsurance.userOtherDebt =
        firstYear && firstYear.Liabilities ? firstYear.Liabilities.loans : 0;
      newStateData.lifeInsurance.spouseMortgage =
        newStateData.lifeInsurance.userMortgage;
      newStateData.lifeInsurance.spouseOtherDebt =
        newStateData.lifeInsurance.userOtherDebt;

      newStateData.lifeInsurance.userAnnualIncome =
        firstYear && firstYear.TempValues
          ? firstYear.TempValues.MoneyIn.salary.you
          : 0;

      newStateData.lifeInsurance.spouseAnnualIncome =
        firstYear && firstYear.TempValues
          ? firstYear.TempValues.MoneyIn.salary.spouse
          : 0;

      newStateData.lifeInsurance.userRecommendedAmount =
        newStateData.lifeInsurance.userMortgage +
        newStateData.lifeInsurance.userOtherDebt +
        educationInflation.you +
        10 * newStateData.lifeInsurance.userAnnualIncome;
      newStateData.lifeInsurance.spouseRecommendedAmount =
        newStateData.lifeInsurance.spouseMortgage +
        newStateData.lifeInsurance.spouseOtherDebt +
        educationInflation.spouse +
        10 * newStateData.lifeInsurance.spouseAnnualIncome;

      newStateData.lifeInsurance.userOpp =
        newStateData.lifeInsurance.userExistingInsurance /
        newStateData.lifeInsurance.userRecommendedAmount;
      newStateData.lifeInsurance.spouseOpp =
        newStateData.lifeInsurance.spouseExistingInsurance /
        newStateData.lifeInsurance.spouseRecommendedAmount;

      //// existing insurance/recommended amount

      newStateData.emergencyFund.existingFund = firstYear
        ? firstYear.TempValues.accounts.savings +
          firstYear.TempValues.accounts.checking
        : 0;
      newStateData.emergencyFund.monthlyIncome = firstYear
        ? firstYear.MoneyIn.all / 12
        : 0;
      newStateData.emergencyFund.recommendedAmount = firstYear
        ? newStateData.emergencyFund.emergencyMultiple *
          newStateData.emergencyFund.monthlyIncome
        : 0;

      ////////////////////////////////////

      this.calcFinancialIndependence(
        newStateData.financialIndependence,
        fullCalc,
        firstYear
      );
    }
    ///////////////////////////////////////////////////////////////////

    const newFormsState = this.state.forms;
    Object.keys(this.state.forms).forEach(formId => {
      const form = this.state.forms[formId];

      const isSaved = advisorDashboard && advisorDashboard[`${formId}-isSaved`];
      if (isSaved) {
        Object.keys(form).forEach(fieldKey => {
          newFormsState[formId][fieldKey] = advisorDashboard
            ? advisorDashboard[`${formId}-${fieldKey}`]
            : "";
        });

        if (formId === "lifeInsurance") {
          const {
            educationWithInflationUser,
            educationWithInflationSpouse
          } = this.calcEducationWithInflation(
            newFormsState.lifeInsurance,
            newStateData.assets.educationTotal,
            newStateData.assets.educationWithUntilAttend
          );

          newFormsState.lifeInsurance.userRecommendedAmount =
            +newFormsState.lifeInsurance.userMortgage +
            +newFormsState.lifeInsurance.userOtherDebt +
            educationWithInflationUser +
            10 * newFormsState.lifeInsurance.userAnnualIncome;

          newFormsState.lifeInsurance.spouseRecommendedAmount =
            +newFormsState.lifeInsurance.spouseMortgage +
            +newFormsState.lifeInsurance.spouseOtherDebt +
            educationWithInflationSpouse +
            10 * newFormsState.lifeInsurance.spouseAnnualIncome;
        }

        if (formId === "emergencyFund") {
          newFormsState.emergencyFund.recommendedAmount = firstYear
            ? newFormsState.emergencyFund.emergencyMultiple *
              newFormsState.emergencyFund.monthlyIncome
            : 0;
        }

        if (formId === "financialIndependence") {
          this.calcFinancialIndependence(
            newFormsState.financialIndependence,
            fullCalc,
            firstYear
          );
        }
      } else {
        Object.keys(form).forEach(fieldKey => {
          newFormsState[formId][fieldKey] = newStateData[formId]
            ? newStateData[formId][fieldKey]
            : "";
        });
      }
    });

    newStateData.events = events;

    this.setState(prevState => ({
      ...prevState,
      firstYear,

      members: members,
      forms: newFormsState,
      data: newStateData
    }));
  };

  calcFinancialIndependence = (newStateData, fullCalc, firstYear) => {
    if (fullCalc) {
      const firstYear = Object.values(fullCalc)[0];

      if (!newStateData.expectedRetirementAge) {
        newStateData.expectedRetirementAge = DEFAULT_EXPECTED_RETIREMENT_AGE;
      }

      newStateData.estimatedLifetime =
        fullCalc && fullCalc[newStateData.lifeExpectancy]
          ? fullCalc[newStateData.lifeExpectancy].TempValues.MoneyOut.cumulative
          : 0;

      newStateData.estimatedLifetimeNet =
        fullCalc && fullCalc[newStateData.expectedRetirementAge]
          ? fullCalc[newStateData.expectedRetirementAge].chartData
              .MonetaryAssets
          : 0;

      const currentMonetaryAssets = firstYear
        ? firstYear.chartData.MonetaryAssets
        : 0;

      const ageDiff =
        newStateData.lifeExpectancy - this.props.propsState.currentAge;

      newStateData.gapToIndependence =
        currentMonetaryAssets -
        newStateData.estimatedLifetime /
          Math.pow(1 + +newStateData.annualInflation, ageDiff);

      let isFound = false;

      Object.keys(fullCalc).forEach(ageKey => {
        const tempYear = fullCalc[ageKey];

        if (
          !isFound &&
          (tempYear.chartData.MonetaryAssets <= 0 ||
            tempYear.MoneyIn.salary === 0)
        ) {
          isFound = true;

          newStateData.ageFullDepletion = ageKey;
        }
      });
    }
  };

  calcEducationWithInflation = (
    lifeInsuranceState,
    educationTotal,
    educationWithUntilAttend
  ) => {
    const educationDiff =
      (lifeInsuranceState.userHighEducation - educationTotal) /
      educationWithUntilAttend.length;
    const educationDiffSpouse =
      (lifeInsuranceState.spouseHighEducation - educationTotal) /
      educationWithUntilAttend.length;
    let educationWithInflationUser = 0;
    let educationWithInflationSpouse = 0;

    educationWithUntilAttend.forEach(
      ({ untilAttend, educationAmount, personAttending }) => {
        const inflationFactor = Math.pow(
          1 + lifeInsuranceState.userAnnualInflation / 100,
          untilAttend
        );

        const newAmountWithDiff = educationAmount + educationDiff;
        const newAmountWithDiffSpouse = educationAmount + educationDiffSpouse;
        const withInflation =
          inflationFactor * (newAmountWithDiff > 0 ? newAmountWithDiff : 0);
        const withInflationSpouse =
          inflationFactor *
          (newAmountWithDiffSpouse > 0 ? newAmountWithDiffSpouse : 0);

        if (personAttending === "You") {
          return (educationWithInflationUser += withInflation);
        }

        if (personAttending === "Spouse") {
          return (educationWithInflationSpouse += withInflationSpouse);
        }

        if (personAttending !== "You" && personAttending !== "Spouse") {
          educationWithInflationSpouse += withInflationSpouse;
          educationWithInflationUser += withInflation;
        }
      }
    );

    return { educationWithInflationUser, educationWithInflationSpouse };
  };

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleSaveSection = formId => () => {
    const { firestore, advisorDashboard, profileProvider, auth } = this.props;

    const saveObject = {};

    if (this.state.forms[formId]) {
      Object.keys(this.state.forms[formId]).forEach(key => {
        const dbKey = `${formId}-${key}`;
        const value = this.state.forms[formId][key];

        saveObject[dbKey] = typeof value !== "undefined" ? value : "";
      });
    }

    saveObject[`${formId}-isSaved`] = true;
    saveObject[`${formId}-isDone`] = false;

    const newDate = new Date();
    if (advisorDashboard && advisorDashboard.id) {
      firestore
        .update(`advisorDashboard/${advisorDashboard.id}`, {
          updated: new Date(),
          ...saveObject
        })
        .then(() => {
          toast(
            <FormattedMessage
              id="APP.SUCCESSFULLY_SAVED"
              defaultMessage="Saved successfully"
            />
          );
        });
    } else {
      firestore
        .add(
          { collection: "advisorDashboard" },
          {
            created: newDate,
            updated: newDate,

            advisorId: auth.uid,
            clientId: profileProvider,

            ...saveObject
          }
        )
        .then(() => {
          toast(
            <FormattedMessage
              id="APP.SUCCESSFULLY_SAVED"
              defaultMessage="Saved successfully"
            />
          );
        });
    }
  };

  expandPanel = panel => () => {
    this.setState({
      expanded: panel
    });
  };

  handleInputChangePanel = (formId, category) => ev => {
    const { name, value } = ev.target;

    const prevState = this.state;

    let additions = {};
    additions = {
      ...prevState.forms[formId],
      [name]: value
    };

    if (formId === "lifeInsurance") {
      const {
        educationWithInflationUser,
        educationWithInflationSpouse
      } = this.calcEducationWithInflation(
        additions,
        this.state.data.assets.educationTotal,
        this.state.data.assets.educationWithUntilAttend
      );
      ////////////////////////////////////////////////////////////////////

      additions.userRecommendedAmount =
        +additions.userMortgage +
        +additions.userOtherDebt +
        // +additions.userHighEducation +
        educationWithInflationUser +
        10 * additions.userAnnualIncome;

      additions.spouseRecommendedAmount =
        +additions.spouseMortgage +
        +additions.spouseOtherDebt +
        // +additions.spouseHighEducation +
        educationWithInflationSpouse +
        10 * additions.spouseAnnualIncome;
    }

    if (formId === "financialIndependence") {
      additions.gapToIndependence =
        +additions.estimatedLifetimeNet - +additions.estimatedLifetime;
    }
    if (formId === "emergencyFund") {
      additions.recommendedAmount =
        +additions.emergencyMultiple * +additions.monthlyIncome;
    }

    if (formId === "financialIndependence") {
      const { propsState } = this.props;
      const { fullCalc } = propsState;
      const firstYear = Object.values(fullCalc)[0];

      this.calcFinancialIndependence(additions, fullCalc, firstYear);
    }

    this.setState(prevState => {
      prevState.forms[formId] = {
        ...prevState.forms[formId],
        ...additions,
        [name]: value
      };

      return prevState;
    });
  };

  _renderOverallStat = () => {
    const { firstYear, data } = this.state;
    let kids = null;
    if (this.state.members) {
      let kidsAges = [];
      let kidsCount = 0;

      Object.keys(this.state.members).forEach(memberKey => {
        if (memberKey !== "You" && memberKey !== "Spouse") {
          kidsCount++;

          kidsAges.push(this.state.members[memberKey].age);
        }
      });

      kids = kidsCount ? (
        <span>
          {kidsCount}{" "}
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.KIDS"
            defaultMessage="kids"
          />
          : {kidsAges.join(", ")}
        </span>
      ) : (
        <FormattedMessage
          id="MY_ADVISOR.STAT.LABEL.NO_KIDS"
          defaultMessage="No kids"
        />
      );
    }

    const productsUser = data.products.You.all ? (
      <>
        {!!data.products.You.IRA && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.IRA"
                defaultMessage="IRA"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.IRA)}
            </div>
          </div>
        )}
        {!!data.products.You["401K"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.401K"
                defaultMessage="401K"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You["401K"])}
            </div>
          </div>
        )}
        {!!data.products.You.lifeInsurance && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE"
                defaultMessage="Life Insurance"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.lifeInsurance)}
            </div>
          </div>
        )}

        {!!data.products.You.annuity && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.ANNUITY"
                defaultMessage="Annuity"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.annuity)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    const productsSpouse = data.products.Spouse.all ? (
      <>
        {!!data.products.Spouse.IRA && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.IRA"
                defaultMessage="IRA"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.IRA)}
            </div>
          </div>
        )}
        {!!data.products.Spouse["401K"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.401K"
                defaultMessage="401K"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse["401K"])}
            </div>
          </div>
        )}
        {!!data.products.Spouse.lifeInsurance && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE"
                defaultMessage="Life Insurance"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.lifeInsurance)}
            </div>
          </div>
        )}

        {!!data.products.Spouse.annuity && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.ANNUITY"
                defaultMessage="Annuity"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.annuity)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    const productsOther = data.products.other.all ? (
      <>
        {!!data.products.other["529college"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.529_FUND"
                defaultMessage="529 Fund"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.other["529college"])}
            </div>
          </div>
        )}
        {!!data.products.other.other && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.OTHER"
                defaultMessage="Other"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.other.other)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    return (
      <HouseholdCard fullWidth>
        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRIMARY_PROVIDER"
                defaultMessage="Primary provider"
              />
            </div>
            <div className="my-advisor__item-value">
              {this.state.members && this.state.members.You && (
                <span>
                  {this.state.members.You.name}: {this.state.members.You.age}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.SECONDARY_PROVIDER"
                defaultMessage="Secondary provider"
              />
            </div>
            <div className="my-advisor__item-value">
              {this.state.members && this.state.members.Spouse && (
                <span>
                  {this.state.members.Spouse.name}:{" "}
                  {this.state.members.Spouse.age}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.FAMILY"
                defaultMessage="Family"
              />
            </div>
            <div className="my-advisor__item-value">{kids}</div>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.GROSS_INCOME"
                defaultMessage="Gross income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(
                this.state.data.lifeInsurance.userAnnualIncome
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.GROSS_INCOME"
                defaultMessage="Gross income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(
                this.state.data.lifeInsurance.spouseAnnualIncome
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PASSIVE_INCOME"
                defaultMessage="Passive Income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(
                firstYear ? firstYear.MoneyIn.all - firstYear.MoneyIn.salary : 0
              )}
            </div>
          </Grid>
        </Grid>

        <div className="my-advisor__item-content-half-divider" />

        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>
            {productsUser}
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>

            {productsSpouse}
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>

            {productsOther}
          </Grid>
        </Grid>
      </HouseholdCard>
    );
  };

  _renderMajorOther = () => {
    const { firstYear } = this.state;

    const monthlyIncome = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_INCOME"
            defaultMessage="Monthly Income"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.all : 0)}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.JOBS"
              defaultMessage="Jobs"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.salary : 0)}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.RENTS"
              defaultMessage="Rents"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.realEstate : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.OTHER"
              defaultMessage="Other"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.other : 0)}
          </div>
        </div>
      </>
    );

    const monthlyExpenses = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_EXPENSES"
            defaultMessage="Monthly Expenses"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.MoneyOut.other +
                    firstYear.MoneyOut.asset +
                    firstYear.MoneyOut.personal
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LIVING"
              defaultMessage="Living"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyOut.personal : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.ASSETS"
              defaultMessage="Assets"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyOut.asset : 0)}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.OTHER"
              defaultMessage="Other"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyOut.other : 0)}
          </div>
        </div>
      </>
    );

    const monthlyNetIncome = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_NET_INCOME"
            defaultMessage="Monthly Net Income"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.all - firstYear.MoneyOut.all : 0
            )}
          </span>
        </div>
      </>
    );

    return (
      <HouseholdCard fullWidth>
        {monthlyIncome}

        <div className="my-advisor__item-content-divider" />

        {monthlyExpenses}

        <div className="my-advisor__item-content-divider" />

        {monthlyNetIncome}
      </HouseholdCard>
    );
  };

  _renderMajorAssets = () => {
    const { firstYear } = this.state;

    const liquidAssets = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.LIQUID_ASSETS"
            defaultMessage="Liquid Assets"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.AssetsDetail.cash.lifeInsurance +
                    firstYear.AssetsDetail.cash.accounts +
                    firstYear.Assets.investments
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.CASH"
              defaultMessage="Cash"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.AssetsDetail.cash.accounts : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.BROKERAGE"
              defaultMessage="Brokerage"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Assets.investments : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE_CASH"
              defaultMessage="Life Insurance Cash"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.AssetsDetail.cash.lifeInsurance : 0
            )}
          </div>
        </div>
      </>
    );

    const illiquidAssets = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.ILLIQUID_ASSETS"
            defaultMessage="Illiquid Assets"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.MoneyIn.realEstate + firstYear.Assets.retirement
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.REAL_ESTATE"
              defaultMessage="Real Estate"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.realEstate : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.RETIREMENT_SAVINGS"
              defaultMessage="Retirement & Savings"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Assets.retirement : 0
            )}
          </div>
        </div>

        {/*<div className="my-advisor__item-content">*/}
        {/*  <div className="my-advisor__item-label">*/}
        {/*    <FormattedMessage*/}
        {/*      id="MY_ADVISOR.STAT.LABEL.BUSINESS_INTEREST"*/}
        {/*      defaultMessage="Business Interest"*/}
        {/*    />*/}
        {/*    {`: `}*/}
        {/*  </div>*/}
        {/*  <div className="my-advisor__item-value">$250K</div>*/}
        {/*</div>*/}
      </>
    );

    const majorLiabilities = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MAJOR_LIABILITIES"
            defaultMessage="Major Liabilities"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.Liabilities.loans + firstYear.Liabilities.mortgage
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.MORTGAGES"
              defaultMessage="Mortgages"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Liabilities.mortgage : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LOANS"
              defaultMessage="Loans"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Liabilities.loans : 0
            )}
          </div>
        </div>
      </>
    );

    return (
      <HouseholdCard fullWidth>
        {liquidAssets}

        <div className="my-advisor__item-content-divider" />

        {illiquidAssets}

        <div className="my-advisor__item-content-divider" />

        {majorLiabilities}
      </HouseholdCard>
    );
  };

  _renderCheckList = () => {
    return (
      <div className="recommendations">
        <h2 className="recommendations__title">
          <FormattedMessage
            id="MY_ADVISOR.CHECK_LIST.TITLE"
            defaultMessage="Checklist"
          />
        </h2>
        <div className="recommendations__wrap">
          <div className="recommendations__collapse">
            {/*  <div className="recommendations__wrap recommendations__wrap--advisor">*/}
            {/*<div className="recommendations__collapse recommendations__collapse--advisor">*/}
            {this._renderItemLifeInsurance()}

            {this._renderItemCashDebt()}
            {this._renderItemRealEstate()}
            {this._renderItemHigheducationNeeds()}
            {this._renderItemEmergencyFund()}
            {this._renderItemTaxAsset()}
            {this._renderItemInvestmentDiversification()}
            {this._renderItemMajorIncome()}
            {this._renderFinancialIndependence()}
          </div>
        </div>
      </div>
    );
  };

  _renderItemLifeInsurance = () => {
    const { classes } = this.props;

    const formState = this.state.forms.lifeInsurance;
    const formData = this.state.data.lifeInsurance;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel1"}
        onChange={this.handleChangePanel("panel1")}
        style={{ marginTop: 24 }}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.TITLE"
              defaultMessage="Life Insurance"
            />
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.CAPTION_1"
                  defaultMessage="Life insurance can protect families against an unexpected turn of events. To ensure proper coverage, you need to factor in significant debt, annual income and their different savings needs."
                />
              </p>
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.CAPTION_2"
                  defaultMessage="Death benefit = Mortgage + Other debt + 10yrs*Annual income + High education*#kids*Inflation until thev attend"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("lifeInsurance")}
                  value={formState.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel2")}
                style={{ paddingLeft: 24, paddingRigh: 24 }}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("lifeInsurance")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.USER_PROP"
                        defaultMessage="User Parameters"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                        defaultMessage="System"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                        defaultMessage="Advisor"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.MORTGAGE"
                        defaultMessage="Mortgage"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userMortgage)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userMortgage"
                        value={formState.userMortgage}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.OTHER_DEBT"
                        defaultMessage="Other debt"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userOtherDebt)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userOtherDebt"
                        value={formState.userOtherDebt}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.HIGH_EDUCATION"
                        defaultMessage="High education needs"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userHighEducation)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userHighEducation"
                        value={formState.userHighEducation}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INCOME"
                        defaultMessage="Annual income"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userAnnualIncome)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userAnnualIncome"
                        value={formState.userAnnualIncome}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %&nbsp;
                      {formData.userAnnualInflation}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userAnnualInflation"
                        value={formState.userAnnualInflation}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.userRecommendedAmount)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formState.userRecommendedAmount)}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.EXISTING_INSURANCE"
                        defaultMessage="Existing insurance"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.userExistingInsurance)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="userOpp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.lifeInsurance.userOpp || ""}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SPOUSE_PROP"
                        defaultMessage="Spouse Parameters"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                        defaultMessage="System"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                        defaultMessage="Advisor"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.MORTGAGE"
                        defaultMessage="Mortgage"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseMortgage)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseMortgage"
                        value={formState.spouseMortgage}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.OTHER_DEBT"
                        defaultMessage="Other debt"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseOtherDebt)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseOtherDebt"
                        value={formState.spouseOtherDebt}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.HIGH_EDUCATION"
                        defaultMessage="High education needs"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseHighEducation)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseHighEducation"
                        value={formState.spouseHighEducation}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INCOME"
                        defaultMessage="Annual income"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseAnnualIncome)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseAnnualIncome"
                        value={formState.spouseAnnualIncome}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %&nbsp;
                      {formData.spouseAnnualInflation}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseAnnualInflation"
                        value={formState.spouseAnnualInflation}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.spouseRecommendedAmount)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(
                        formState.spouseRecommendedAmount
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.EXISTING_INSURANCE"
                        defaultMessage="Existing insurance"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.spouseExistingInsurance)}
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="spouseOpp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.lifeInsurance.spouseOpp || ""}
                        handleChange={this.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemCashDebt = () => {
    const { classes } = this.props;

    const { firstYear, data } = this.state;

    const totalCash =
      data && firstYear
        ? data.products.other.all +
          firstYear.Assets.cash +
          firstYear.AssetsDetail.retirement.IRA +
          firstYear.AssetsDetail.retirement["401K"] +
          firstYear.AssetsDetail.retirement.deferredComp +
          firstYear.AssetsDetail.retirement.annuity +
          firstYear.TempValues.investments.businessInterest +
          firstYear.AssetsDetail.investments.treasuries +
          firstYear.AssetsDetail.investments.mutualFunds +
          firstYear.AssetsDetail.investments.stocks
        : 0;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel2"}
        onChange={this.handleChangePanel("panel2")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TITLE"
              defaultMessage="Cash and debt distribution"
            />
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              lg={4}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("cashDebt")}
                  value={this.state.forms.cashDebt.notes}
                />
              </div>

              <ButtonCommon
                buttonSmallAdvisor
                white
                onClick={this.expandPanel("panel1")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>
              <ButtonCommon
                buttonSmallAdvisor
                white
                onClick={this.expandPanel("panel3")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon yellow onClick={this.handleSaveSection("cashDebt")}>
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={4}
              spacing={0}
              className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
            >
              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CASH_DISTRIBUTION"
                      defaultMessage="Cash distribution"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.AMOUNT"
                      defaultMessage="Amount"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CHECKING_SAVINGS"
                      defaultMessage="Checking/Savings"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.accounts.checking +
                            firstYear.TempValues.accounts.savings
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MONEY_MARKET_CD"
                      defaultMessage="Money market/CD"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.accounts.moneyMarket +
                            firstYear.TempValues.accounts.CD
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.INVESTMENTS"
                      defaultMessage="Investments"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.investments.businessInterest +
                            firstYear.AssetsDetail.investments.treasuries +
                            firstYear.AssetsDetail.investments.mutualFunds +
                            firstYear.AssetsDetail.investments.stocks
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.RETIREMENT"
                      defaultMessage="Retirement"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.AssetsDetail.retirement.IRA +
                            firstYear.AssetsDetail.retirement["401K"] +
                            firstYear.AssetsDetail.retirement.deferredComp +
                            firstYear.AssetsDetail.retirement.annuity
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.PROTECTION"
                      defaultMessage="Protection"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.AssetsDetail.cash.lifeInsurance : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.OTHER"
                      defaultMessage="Other"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(data ? data.products.other.all : 0)}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TOTAL_CASH"
                      defaultMessage="Total cash"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(totalCash)}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                      defaultMessage="Opportunity"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={2}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormControlSelect
                      bgSecondary
                      options={optionsYesNoMaybe}
                      fieldName="cashOpp"
                      hideNoneOption
                      classNameExtra={classes.inputAdvisor}
                      value={this.state.forms.cashDebt.cashOpp || ""}
                      handleChange={this.handleInputChangePanel("cashDebt")}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={4}
              spacing={0}
              className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li recommendations__item-right--right"
            >
              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.DEBT_DISTRIBUTION"
                      defaultMessage="Debt distribution"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.AMOUNT"
                      defaultMessage="Amount"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MORTGAGE"
                      defaultMessage="Mortgage"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.home : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.STUDENT_LOANS"
                      defaultMessage="Student loans"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.student : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CREDITCARD"
                      defaultMessage="Creditcard"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.creditCard : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MEDICAL"
                      defaultMessage="Medical"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.medical : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.OTHER"
                      defaultMessage="Other"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.other : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TOTAL_DEBT"
                      defaultMessage="Total debt"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.Liabilities.mortgage +
                            firstYear.Liabilities.loans
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                      defaultMessage="Opportunity"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={2}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormControlSelect
                      bgSecondary
                      options={optionsYesNoMaybe}
                      fieldName="debtOpp"
                      hideNoneOption
                      classNameExtra={classes.inputAdvisor}
                      value={this.state.forms.cashDebt.debtOpp || ""}
                      handleChange={this.handleInputChangePanel("cashDebt")}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemRealEstate = () => {
    const { classes } = this.props;

    const { firstYear, data } = this.state;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel3"}
        onChange={this.handleChangePanel("panel3")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TITLE"
              defaultMessage="Real estate ownership"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={5}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("realEstate")}
                  value={this.state.forms.realEstate.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel2")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel4")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("realEstate")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={7}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.OWNED_ASSETS"
                        defaultMessage="Owned assets"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                        defaultMessage="Value"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                        defaultMessage="Debt"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                        defaultMessage="Income/YR"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.PRIMARY_RESIDENCE"
                        defaultMessage="Primary residence"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.primary)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.primary)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VACATION"
                        defaultMessage="Vacation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.vacation)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.vacation)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INVESTMENTS"
                        defaultMessage="Investments"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.investment)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.investment)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.OTHER"
                        defaultMessage="Other"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.other)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.other)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.all)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.all)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                  <Grid container item xs={12} lg={12} alignItems="center">
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-item recommendations__form-item--advisor">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                          defaultMessage="Opportunity"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} lg={2}>
                      <div className="recommendations__form-item recommendations__form-item--advisor">
                        <FormControlSelect
                          bgSecondary
                          options={optionsYesNoMaybe}
                          fieldName="ownedOpp"
                          hideNoneOption
                          classNameExtra={classes.inputAdvisor}
                          value={this.state.forms.realEstate.ownedOpp || ""}
                          handleChange={this.handleInputChangePanel(
                            "realEstate"
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={6}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.RENTED_ASSETS"
                        defaultMessage="Rented assets"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"
                        defaultMessage="Annual rent"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={6}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"
                        defaultMessage="Annual rent"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="rentedOpp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.realEstate.rentedOpp || ""}
                        handleChange={this.handleInputChangePanel("realEstate")}
                      />
                    </div>
                  </Grid>
                </Grid>

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"*/}
                {/*        defaultMessage="Annual rent"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormControlSelect*/}
                {/*        bgSecondary*/}
                {/*        options={optionsYesNoMaybe}*/}
                {/*        fieldName="rentedVacation"*/}
                {/*        hideNoneOption*/}
                {/*        value={*/}
                {/*          this.state.forms.realEstate.rentedVacation || "no"*/}
                {/*        }*/}
                {/*        handleChange={this.handleInputChangePanel("realEstate")}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INVESTMENTS"*/}
                {/*        defaultMessage="Investments"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormControlSelect*/}
                {/*        bgSecondary*/}
                {/*        options={optionsYesNoMaybe}*/}
                {/*        fieldName="rentedInvestments"*/}
                {/*        hideNoneOption*/}
                {/*        value={*/}
                {/*          this.state.forms.realEstate.rentedInvestments || "yes"*/}
                {/*        }*/}
                {/*        handleChange={this.handleInputChangePanel("realEstate")}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TOTAL"*/}
                {/*        defaultMessage="Total"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemHigheducationNeeds = () => {
    const { classes } = this.props;

    const { data, members } = this.state;

    let total = 0;

    const rows = Object.keys(members).map(memberKey => {
      const member = members[memberKey];

      if (memberKey === "Spouse" || memberKey === "You" || member.age > 18) {
        return null;
      }

      total += data.assets["529college"][memberKey] || 0;

      return (
        <Grid
          container
          item
          xs={12}
          lg={12}
          key={memberKey}
          alignItems="center"
        >
          <Grid item xs={12} lg={4}>
            <div className="recommendations__form-item recommendations__form-item--primary">
              {member.childName}
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className="recommendations__form-item  recommendations__form-item--advisor">
              {member.age}
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className="recommendations__form-item  recommendations__form-item--advisor">
              {formatMoneyIntWithSign(
                data.assets["529college"][memberKey] || 0
              )}
            </div>
            {}
          </Grid>
        </Grid>
      );
    });

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel4"}
        onChange={this.handleChangePanel("panel4")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.TITLE"
              defaultMessage="High Education needs"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={6}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel(
                    "higheducationNeeds"
                  )}
                  value={this.state.forms.higheducationNeeds.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel3")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel5")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("higheducationNeeds")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.MEMBER"
                        defaultMessage="Member"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.AGE"
                        defaultMessage="Age"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.COLLEGE_SAVINGS"
                        defaultMessage="College savings"
                      />
                    </div>
                  </Grid>
                </Grid>

                {rows}

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} />
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      {formatMoneyIntWithSign(total)}
                    </div>
                  </Grid>
                  {/*<div className="recommendations__form-item">*/}
                  {/*  <FormControlSelect*/}
                  {/*    bgSecondary*/}
                  {/*    options={optionsYesNoMaybe}*/}
                  {/*    fieldName="advisor"*/}
                  {/*    hideNoneOption*/}
                  {/*    value={*/}
                  {/*      this.state.forms.higheducationNeeds.advisor || "no"*/}
                  {/*    }*/}
                  {/*    handleChange={this.handleInputChangePanel(*/}
                  {/*      "higheducationNeeds"*/}
                  {/*    )}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="advisor"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.state.forms.higheducationNeeds.advisor || ""
                        }
                        handleChange={this.handleInputChangePanel(
                          "higheducationNeeds"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemEmergencyFund = () => {
    const { classes } = this.props;

    const { data, firstYear } = this.state;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel5"}
        onChange={this.handleChangePanel("panel5")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.TITLE"
              defaultMessage="Emergency fund"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("emergencyFund")}
                  value={this.state.forms.emergencyFund.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel4")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel6")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("emergencyFund")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.PROP"
                        defaultMessage="Parameter"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                        defaultMessage="System"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.ADVISOR"
                        defaultMessage="Advisor"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.MONTHLY_INCOME"
                        defaultMessage="Monthly income"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.all / 12 : 0
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="monthlyIncome"
                        value={this.state.forms.emergencyFund.monthlyIncome}
                        handleChange={this.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EMERGENCY_MULTIPLE"
                        defaultMessage="Emergency multiple"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item">
                      {data.emergencyFund.emergencyMultiple}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isInt
                        classNameExtra={classes.inputAdvisor}
                        fieldName="emergencyMultiple"
                        value={this.state.forms.emergencyFund.emergencyMultiple}
                        handleChange={this.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={12}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EMERGENCY_FUND_MONTHLY"
                        defaultMessage="Emergency fund = Monthly income*Emergency multiple"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(
                        data.emergencyFund.recommendedAmount
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="recommendedAmount"
                        value={this.state.forms.emergencyFund.recommendedAmount}
                        handleChange={this.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EXISTING_FUND"
                        defaultMessage="Possible existing fund"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(data.emergencyFund.existingFund)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} />

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.emergencyFund.opp || ""}
                        handleChange={this.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemTaxAsset = () => {
    const { classes } = this.props;

    const { firstYear } = this.state;

    const { all, ...restData } = firstYear ? firstYear.TaxCategories : {};

    const transKey = "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.";

    const taxablePercent =
      restData && restData.taxable
        ? parseInt((restData.taxable / all) * 100)
        : 0;
    const taxable = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxable"
            percent={taxablePercent}
          />
        </Grid>
      </Grid>
    );

    const deferredPercent =
      restData && restData.taxDeferred
        ? parseInt((restData.taxDeferred / all) * 100)
        : 0;
    const deferred = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxDeferred"
            percent={deferredPercent}
          />
        </Grid>
      </Grid>
    );

    const advantagePercent =
      restData && restData.taxAdvantage
        ? parseInt((restData.taxAdvantage / all) * 100)
        : 0;
    const advantage = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxAdvantage"
            percent={advantagePercent}
          />
        </Grid>
      </Grid>
    );

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel6"}
        onChange={this.handleChangePanel("panel6")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.TITLE"
              defaultMessage="Tax asset allocation"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("taxAsset")}
                  value={this.state.forms.taxAsset.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel5")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel7")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon yellow onClick={this.handleSaveSection("taxAsset")}>
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={12}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.ASSET_CATEGORY_DISTRIBUTION"
                        defaultMessage="Asset category distribution"
                      />
                    </div>
                  </Grid>
                </Grid>

                {taxable}

                {deferred}

                {advantage}

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.taxAsset.opp || ""}
                        handleChange={this.handleInputChangePanel("taxAsset")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemInvestmentDiversification = () => {
    const { classes } = this.props;

    const { firstYear } = this.state;

    const transKey = "DASHBOARD.STATISTIC.LABEL.ASSETS_USE.";

    const all = firstYear ? firstYear.AssetsDetail.use.all : 0;
    const homesValue = firstYear ? firstYear.AssetsDetail.use.home : 0;
    const otherValue = firstYear
      ? firstYear.AssetsDetail.use.all - homesValue
      : 0;

    const homesPercent = all ? parseInt((homesValue / all) * 100) : 0;
    const otherPercent = all ? parseInt((otherValue / all) * 100) : 0;

    const dataAll = { homeUse: homesValue, otherUse: otherValue };

    const homeEl = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            transKey={transKey}
            data={dataAll}
            labelI18n="DASHBOARD.STATISTIC.LABEL.ASSETS_USE.home"
            entityKey="homeUse"
            value={homesValue}
            percent={homesPercent}
          />
        </Grid>
      </Grid>
    );

    const otherEl = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            transKey={transKey}
            labelI18n="DASHBOARD.STATISTIC.LABEL.ASSETS_USE.other"
            data={dataAll}
            entityKey="otherUse"
            value={otherValue}
            percent={otherPercent}
          />
        </Grid>
      </Grid>
    );

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel7"}
        onChange={this.handleChangePanel("panel7")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.TITLE"
              defaultMessage="Investment diversification"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel(
                    "investmentDiversification"
                  )}
                  value={this.state.forms.investmentDiversification.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel6")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel8")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("investmentDiversification")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={7}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.REAL_ESTATE_STOCK"
                        defaultMessage="Real estate; stock market ratios"
                      />
                    </div>
                  </Grid>
                </Grid>

                {homeEl}

                {otherEl}

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.state.forms.investmentDiversification.opp || ""
                        }
                        handleChange={this.handleInputChangePanel(
                          "investmentDiversification"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemMajorIncome = () => {
    const { classes } = this.props;

    const { data } = this.state;

    let total = 0;

    const eventsRows =
      data && data.events
        ? data.events.map(event => {
            if (!event.value) {
              return null;
            }

            total += event.value;

            return (
              <Grid
                container
                item
                xs={12}
                lg={12}
                key={`${event.id}-${event.year}`}
                alignItems="center"
              >
                <Grid item xs={12} lg={4}>
                  <div className="recommendations__form-item">
                    {event.description}
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <div className="recommendations__form-item">
                    {formatMoneyIntWithSign(event.value)}
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <div className="recommendations__form-item">
                    {event.yearsUntilOccurs}
                  </div>
                </Grid>
              </Grid>
            );
          })
        : null;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel8"}
        onChange={this.handleChangePanel("panel8")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.TITLE"
              defaultMessage="Major income events in the near future"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={6}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel("majorIncome")}
                  value={this.state.forms.majorIncome.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel7")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel9")}
              >
                <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                <Icons.IconArrowRight
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("majorIncome")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.EVENT"
                        defaultMessage="Event"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.AMOUNT"
                        defaultMessage="Amount"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.YEARS"
                        defaultMessage="Years"
                      />
                    </div>
                  </Grid>
                </Grid>

                {eventsRows}

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      {formatMoneyIntWithSign(total)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} />
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={this.state.forms.majorIncome.opp || ""}
                        handleChange={this.handleInputChangePanel(
                          "majorIncome"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderFinancialIndependence = () => {
    const { classes } = this.props;

    const formState = this.state.forms.financialIndependence;
    const formData = this.state.data.financialIndependence;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel9"}
        onChange={this.handleChangePanel("panel9")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.TITLE"
              defaultMessage="Financial independence planning"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={6}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.CAPTION_1"
                  defaultMessage="It is recomended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations __notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.handleInputChangePanel(
                    "financialIndependence"
                  )}
                  value={this.state.forms.financialIndependence.notes}
                />
              </div>

              <ButtonCommon
                white
                buttonSmallAdvisor
                onClick={this.expandPanel("panel8")}
              >
                <Icons.IconArrowLeft
                  color="textTetrieary"
                  width={18}
                  height={18}
                />
                <FormattedMessage id="APP.BACK" defaultMessage="Back" />
              </ButtonCommon>

              <ButtonCommon
                yellow
                onClick={this.handleSaveSection("financialIndependence")}
              >
                <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
              </ButtonCommon>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.PROP"
                        defaultMessage="Parameter"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                        defaultMessage="System"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                        defaultMessage="Advisor"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.LIFE_EXPECTACY"
                        defaultMessage="Life expectacy"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.lifeExpectancy}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isInt
                        fieldName="lifeExpectancy"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.state.forms.financialIndependence.lifeExpectancy
                        }
                        handleChange={this.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation %"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %{` `}
                      {formData.annualInflation}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="annualInflation"
                        value={
                          this.state.forms.financialIndependence.annualInflation
                        }
                        handleChange={this.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ESTIMATED_LIFETIME_NEEDS"
                        defaultMessage="Estimated lifetime needs (PV)"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.estimatedLifetime)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(formState.estimatedLifetime)}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ESTIMATED_LIFETIME_NET_LIQUIDITY"
                        defaultMessage="Estimated lifetime net liquidity"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.estimatedLifetimeNet)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(formState.estimatedLifetimeNet)}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.EXPECTED_RET_AGE"
                        defaultMessage="Expected Retirement Age"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.expectedRetirementAge}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isInt
                        fieldName="expectedRetirementAge"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.state.forms.financialIndependence
                            .expectedRetirementAge
                        }
                        handleChange={this.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.GAP_FROM_INDEPENDENCE"
                        defaultMessage="Gap from independence"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.gapToIndependence)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        this.state.forms.financialIndependence.gapToIndependence
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.FULL_DEPLETION_IF_RETIRED_TODAY_BY"
                        defaultMessage="Full depletion if retired today by"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.ageFullDepletion}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item">
                      {formState.ageFullDepletion}

                      {/*<FormControlInput*/}
                      {/*  bgSecondary*/}
                      {/*  isInt*/}
                      {/*  fieldName="ageFullDepletion"*/}
                      {/*  classNameExtra={classes.inputAdvisor}*/}
                      {/*  value={*/}
                      {/*    this.state.forms.financialIndependence*/}
                      {/*      .ageFullDepletion*/}
                      {/*  }*/}
                      {/*  handleChange={this.handleInputChangePanel(*/}
                      {/*    "financialIndependence"*/}
                      {/*  )}*/}
                      {/*/>*/}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={3} />

                  <Grid item xs={12} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        bgSecondary
                        options={optionsYesNoMaybe}
                        fieldName="opportunity"
                        hideNoneOption
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.state.forms.financialIndependence.opportunity ||
                          "no"
                        }
                        handleChange={this.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderMainInfo = () => {
    return (
      <>
        <Grid item container xs={12} lg={6} className="statistic__grid-item">
          {this._renderOverallStat()}
        </Grid>

        <Grid item container xs={12} lg={3} className="statistic__grid-item">
          {this._renderMajorAssets()}
        </Grid>

        <Grid item container xs={12} lg={3} className="statistic__grid-item">
          {this._renderMajorOther()}
        </Grid>
      </>
    );
  };

  render() {
    return (
      <main className="main-container main-container--dashboard">
        <div className="main-content">
          <div className="content-header content-header--dashboard">
            <h1 className="content-header__title">
              <FormattedMessage
                id="MY_ADVISOR.MAIN.TITLE"
                defaultMessage="Dashboard"
              />
            </h1>
          </div>

          <div className="content-main">
            <Grid className="graphics" container spacing={8}>
              {this._renderMainInfo()}
            </Grid>

            {this._renderCheckList()}
          </div>
        </div>
      </main>
    );
  }
}

DashboardAdvisor.propTypes = {
  isMobile: PropTypes.bool,
  profileProvider: PropTypes.any,
  profile: PropTypes.any,
  advisorDashboard: PropTypes.any,
  childs: PropTypes.any,
  rights: PropTypes.any,
  firestoreStatus: PropTypes.any
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { isMobile, profileProvider }
}) {
  const family = ordered && ordered.family && ordered.family[0];
  const rights = ordered && ordered.rights && ordered.rights[0];
  const advisorDashboard =
    ordered && ordered.advisorDashboard && ordered.advisorDashboard[0];

  const userUid = profileProvider || auth.uid;

  const childUser = data && data.users && data.users[userUid];
  const childs =
    childUser && childUser.childs
      ? Object.keys(childUser.childs).map(childKey => ({
          id: childKey,
          ...childUser.childs[childKey]
        }))
      : null;

  return {
    isMobile,
    auth,
    profile,
    profileProvider: profileProvider || auth.uid,
    childs,
    rights,
    family,
    advisorDashboard,
    firestoreStatus: status,

    assetsAll: getAssetsAllData(ordered),
    transactionsAll: getTransactionsAllData(ordered)
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: "users",
        doc: getProfileUidOrClientUidData(props),
        subcollections: [
          {
            collection: "childs",
            orderBy: ["birthday", "asc"],
            storeAs: "childs"
          }
        ]
      },
      {
        collection: "advisorDashboard",
        where: [
          ["clientId", "==", profileUid],
          ["advisorId", "==", props.auth.uid]
        ],
        storeAs: "advisorDashboard"
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  }),
  withFirebase
)(withStyles(styles)(DashboardAdvisor));
