import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

const TooltipTitle = ({label, labelI18n, id}) => (
  <span className="tooltip-span">
    <FormattedMessage id={labelI18n || id} defaultMessage={label} />
  </span>
);

TooltipTitle.propTypes = {
  label: PropTypes.string,
  labelI18n: PropTypes.string,
  id: PropTypes.string,
};

export default TooltipTitle;
