import React, { Component } from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";
import FormControlSelect from "../../_common/FormControl/FormControlSelect";
import TooltipTitle from "../../_common/TooltipTitle";
import Slider from "@material-ui/lab/Slider";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { formatMoneyIntWithSign } from "../../../lib/helpers";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { WIZARD_MONEY } from "constants/wizardSetDefault";
import ButtonMUI from "@material-ui/core/Button/Button";

const optionsResidenceOwnership = [
  {
    value: "own",
    nameI18n: "WIZARD.MONEY.OPTIONS.RESIDENCE.OWN",
    name: "Own"
  },
  {
    value: "rent",
    nameI18n: "WIZARD.MONEY.OPTIONS.RESIDENCE.RENT",
    name: "Rent"
  }
];

class WizardMoney extends Component {
  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderContent = () => {
    const { classes, formState, handleInputChange } = this.props;

    const totalIncome =
      (+formState.totalSalary || 0) + (+formState.collectingRents || 0);

    const totalHomeExpenses =
      (formState.primaryResidence === "own"
        ? +formState.propertyTax || 0
        : +formState.monthlyRent || 0) + (+formState.maintenance || 0);
    return (
      <div className={classes.wizardBodyForm}>
        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.TOTAL_SALARY"
                  defaultMessage="How much total annual salary is earned?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="totalSalary"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("money")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.totalSalary}
                />
                <Slider
                  min={WIZARD_MONEY.SALARY_MIN}
                  max={
                    formState.totalSalary > WIZARD_MONEY.SALARY_MAX
                      ? +formState.totalSalary
                      : WIZARD_MONEY.SALARY_MAX
                  }
                  step={1000}
                  onChange={handleInputChange("money", "totalSalary")}
                  value={+formState.totalSalary}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={<TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.SALARY" />}
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.COLLECTING_RENTS"
                  defaultMessage="How much do you make annually from collecting rents?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="collectingRents"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("money")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.collectingRents}
                />
                <Slider
                  min={WIZARD_MONEY.RENTS_MIN}
                  max={
                    formState.collectingRents > WIZARD_MONEY.RENTS_MAX
                      ? +formState.collectingRents
                      : WIZARD_MONEY.RENTS_MAX
                  }
                  step={1000}
                  onChange={handleInputChange("money", "collectingRents")}
                  value={+formState.collectingRents}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={<TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.RENTS" />}
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.TOTAL_INCOME"
                  defaultMessage="Total income"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(totalIncome)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.PRIMARY_RESIDENCE"
                  defaultMessage="Is your primary residence owned or rented?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlSelect
                  fieldName="primaryResidence"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("money")}
                  value={formState.primaryResidence}
                  cleanGap
                  type="select"
                  options={optionsResidenceOwnership}
                  hideNoneOption
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.PRIMARY_RESIDENCE" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            {formState.primaryResidence === "own" && (
              <div className={classes.wizardFormItem}>
                <div className={classes.wizardFormLabel}>
                  <FormattedMessage
                    id="WIZARD.MONEY.FORM.PROPERTY_TAX"
                    defaultMessage="What is your primary residence annual tax payment?"
                  />
                </div>

                <div className={classes.wizardFormControl}>
                  <FormControlInput
                    fieldName="propertyTax"
                    label={null}
                    labelI18n={null}
                    handleChange={handleInputChange("money")}
                    isAmount
                    classNameExtra={classes.inputWithRange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    value={formState.propertyTax}
                  />

                  <Slider
                    min={WIZARD_MONEY.PROPERTY_TAX_MIN}
                    max={
                      formState.propertyTax > WIZARD_MONEY.PROPERTY_TAX_MAX
                        ? +formState.propertyTax
                        : WIZARD_MONEY.PROPERTY_TAX_MAX
                    }
                    step={WIZARD_MONEY.PROPERTY_TAX_STEP}
                    onChange={handleInputChange("money", "propertyTax")}
                    value={+formState.propertyTax}
                  />
                </div>

                <Tooltip
                  disableFocusListener
                  enterTouchDelay={50}
                  leaveTouchDelay={5000}
                  className={classes.toleranceTooltip}
                  title={
                    <TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.HOUSE_VALUE" />
                  }
                  placement="bottom-end"
                >
                  <span style={{ userSelect: "none" }}>?</span>
                </Tooltip>
              </div>
            )}
            {formState.primaryResidence === "rent" && (
              <div className={classes.wizardFormItem}>
                <div className={classes.wizardFormLabel}>
                  <FormattedMessage
                    id="WIZARD.MONEY.FORM.MONTHLY_RENT"
                    defaultMessage="How much do you spend monthly on rent?"
                  />
                </div>

                <div className={classes.wizardFormControl}>
                  <FormControlInput
                    fieldName="monthlyRent"
                    label={null}
                    labelI18n={null}
                    handleChange={handleInputChange("money")}
                    isAmount
                    classNameExtra={classes.inputWithRange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    value={formState.monthlyRent}
                  />
                  <Slider
                    min={WIZARD_MONEY.MONTHLY_RENT_MIN}
                    max={
                      formState.monthlyRent > WIZARD_MONEY.MONTHLY_RENT_MAX
                        ? +formState.monthlyRent
                        : WIZARD_MONEY.MONTHLY_RENT_MAX
                    }
                    step={100}
                    onChange={handleInputChange("money", "monthlyRent")}
                    value={+formState.monthlyRent}
                  />
                </div>

                <Tooltip
                  disableFocusListener
                  enterTouchDelay={50}
                  leaveTouchDelay={5000}
                  className={classes.toleranceTooltip}
                  title={
                    <TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.MONTHLY_RENT" />
                  }
                  placement="bottom-end"
                >
                  <span style={{ userSelect: "none" }}>?</span>
                </Tooltip>
              </div>
            )}

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.MAINTENANCE"
                  defaultMessage="What are your annual HOA, maintenance, insurance and utilities charges?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="maintenance"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("money")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.maintenance}
                />
                <Slider
                  min={WIZARD_MONEY.HOA_MIN}
                  max={
                    formState.maintenance > WIZARD_MONEY.HOA_MAX
                      ? +formState.maintenance
                      : WIZARD_MONEY.HOA_MAX
                  }
                  step={100}
                  onChange={handleInputChange("money", "maintenance")}
                  value={+formState.maintenance}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.MAINTENANCE" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.TOTAL_HOME"
                  defaultMessage="Total home expenses"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(totalHomeExpenses)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.VACATION"
                  defaultMessage="What is your annual vacation spend?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="vacation"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("money")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.vacation}
                />
                <Slider
                  min={WIZARD_MONEY.VACATION_MIN}
                  max={
                    formState.vacation > WIZARD_MONEY.VACATION_MAX
                      ? +formState.vacation
                      : WIZARD_MONEY.VACATION_MAX
                  }
                  step={50}
                  onChange={handleInputChange("money", "vacation")}
                  value={+formState.vacation}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.MONEY.TOOLTIP.VACATION" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <div className={classes.wizardBodySummaryItem}>
                <FormattedMessage
                  id="WIZARD.MONEY.FORM.TOTAL_VACATION"
                  defaultMessage="Total vacation expenses"
                />
                <span className={classes.wizardBodySummaryCount}>
                  {formatMoneyIntWithSign(+formState.vacation || 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      prevStep,
      nextStep,
      handleChangeStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.MONEY.LABEL"
                defaultMessage="Money making and spending"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={3} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.MONEY.HEADING2"
              defaultMessage="How are you making money and what you are spending it on determines trending of your financials over time"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardMoney.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func,
  handleSaveStep: PropTypes.func
};

export default WizardMoney;
