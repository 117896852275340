import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import {
  getAssetsAllData,
  getAssetsAllDataTest,
  getAssetsAllEventsData,
  getAssetsAllEventsDataTest,
  getAssetsAllGoalsData,
  getAssetsAllGoalsDataTest,
  getMyAdvisorsState,
  getTransactionsAllData,
  getTransactionsAllDataTest
} from "store/selectors/firebase";
import { compose } from "redux";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";
import { setUI } from "AC/ui";
import { getProfileUidOrClientUidData } from "store/selectors/profileProvider";
import diffYears from "date-fns/differenceInCalendarYears";
import setMonth from "date-fns/setMonth";
import setDate from "date-fns/setDate";

import { MAX_AGE, MIN_CALC_AGE } from "../../constants";
import { INFLATION, SAndP } from "../../constants/inflation";
import {
  cloneObject,
  getIsAccessEditView,
  getSorting,
  stableSort
} from "../../lib/helpers";

import {
  ENTITIES as entitiesEvents,
  GLOBAL_TYPE_USE_ASSETS as globalTypeEvent,
  optionsAmountTypeValues,
  optionsLifeEventTypeValues,
  valuesMoneyInType
} from "../PlansEvents/formScheme";
import {
  ENTITIES as entitiesGoals,
  GLOBAL_TYPE_USE_ASSETS as typeGoal
} from "../PlansGoals/formScheme";
import {
  ENTITIES as entitiesMoneyIn,
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyIn,
  optionsOccurrenceValuesOnceEvery,
  optionsOccursEveryValueMult
} from "../HouseholdMoneyIn/formScheme";
import {
  capitalGainTable,
  incomeTaxTable,
  stateTable,
  taxFICAValue,
  taxPrimaryHomePrice,
  taxPropertyPurchaseMult,
  taxPropertyValue
} from "../../constants/taxesAndDashboard";
import {
  ENTITIES as entitiesInvested,
  GLOBAL_TYPE_USE_ASSETS as globalTypeInvested
} from "../HouseholdAssetsInvested/formScheme";
import {
  _helperCalcDebtRemainingAmortization,
  _helperCalcDebtRemainingInterest,
  _helperCalcDebtRemainingMonthly,
  _helperCalculateDebtAnnualPay,
  _helperCalculateUseHomeEstimatedTax,
  calculateInvestedPurchAmountClean,
  calculateMonthsLeftPeriod,
  calculateUnvestedEstimateClean
} from "../_common/AssetCard";
import {
  GLOBAL_TYPE_USE_ASSETS as globalTypeAccounts,
  ENTITIES as entitiesAccounts,
  optionsValuesMaturity
} from "../HouseholdAccounts/formScheme";
import {
  ENTITIES as entitiesSavings,
  GLOBAL_TYPE_USE_ASSETS as globalTypeSavings,
  optionsInsuranceTypeValue,
  TypeAnnuitValues,
  TypeIRAValues
} from "../HouseholdAssetsSavings/formScheme";
import {
  ENTITIES as useEntities,
  GLOBAL_TYPE_USE_ASSETS as globalTypeUse,
  optionsValuesHome
} from "../HouseholdAssetsUse/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as globalTypeDebt,
  optionsRepaymentValues,
  ENTITIES as entitiesDebt
} from "../HouseholdDebt/formScheme";

import {
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutPersonal,
  ENTITIES as entitiesMoneyOutPersonal
} from "../MoneyOutPersonal/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutAssets,
  ENTITIES as entitiesMoneyOutAssets
} from "../MoneyOutAssets/formScheme";
import { GLOBAL_TYPE_USE_ASSETS as globalTypeMoneyOutOther } from "../MoneyOutOther/formScheme";
import Operations from "../Operations/Operations";
import DashboardAdvisor from "../DashboardAdvisor/DashboardAdvisor";
import DashboardLog from "../Dashboard/DashboardLog";
import IsLoading from "../../routeHandlers/IsLoadingAuth";
import {
  DASHBOARD,
  OPERATIONS_CASHFLOW,
  OPERATIONS_DASBOARD,
  ADMIN_CALC_LOG
} from "constants/routes";
import Dashboard from "./Dashboard";

import addYears from "date-fns/addYears";
import {
  helperIsDebtMonthlyExp,
  helperIsMonthsLeftPeriod,
  helperIsRemainingVestingPeriod
  // isDebtMonthlyExp
} from "../AssetsCommon/helperExpiredCalculate";
import differenceInDays from "date-fns/differenceInCalendarDays";
import differenceInYears from "date-fns/differenceInCalendarYears";
import { optionsTaxCategoryValues } from "../AssetsCommon/_formScheme";

import {
  initialYearAssetsState,
  optionsOtherTypeCalc,
  overdraftPriority,
  overdraftPriorityDefault,
  overdraftPriorityScheme
} from "./CalcContainer.scheme";
import { entitiesScheme } from "../AssetsCommon/formSchemeFull";
import {
  getAnnualChange,
  getObjects,
  isNotExpiredMonths,
  valueFuncRSTByPrevContrib,
  valueFuncRSTESPP,
  valueFuncTotalAnnualContrib,
  valueFuncAnnualContrib,
  isMoneyOutNotExpiredYears,
  commonRST,
  commonRSTMultiplier,
  getAnnualChangeDefault
} from "./Calc.helpers";
import { FormattedMessage, injectIntl } from "react-intl-v3";

const cloneDeep = require("lodash.clonedeep");

const initialCalcState = {
  chartData: {
    //NetWealth
    NetWealth: 0,
    HomesAndVehicles: 0,
    MonetaryAssets: 0,
    Taxes: 0,

    // Income
    Savings: 0,
    PreTaxDeductions: 0,
    Expenses: 0,
    TaxesIncome: 0
  },
  chartDataWhatIf: {
    //NetWealth
    NetWealth: 0,
    Liabilities: 0,
    HomesAndVehicles: 0,
    MonetaryAssets: 0,
    Taxes: 0,

    // Income
    Savings: 0,
    PreTaxDeductions: 0,
    Expenses: 0,
    TaxesIncome: 0
  },
  MoneyInDetail: {
    other: {
      other: 0,
      businessInterest: 0,
      all: 0
    }
  },
  MoneyInDetailWhatIf: {
    other: {
      other: 0,
      businessInterest: 0,
      all: 0
    }
  },
  MoneyIn: {
    salary: 0,
    realEstate: 0,
    bank: 0,
    other: 0,
    events: 0,

    all: 0
  },
  MoneyInWhatIf: {
    salary: 0,
    realEstate: 0,
    bank: 0,
    other: 0,
    events: 0,

    all: 0
  },

  MoneyOut: {
    personal: 0,
    asset: 0,
    savings: 0,
    debtRepayment: 0,
    other: 0,
    events: 0,
    taxes: 0,

    all: 0
  },
  MoneyOutWhatIf: {
    personal: 0,
    asset: 0,
    savings: 0,
    debtRepayment: 0,
    other: 0,
    events: 0,
    taxes: 0,

    all: 0
  },

  MoneyOutDetail: {
    taxes: {
      income: 0,
      capital: 0,
      state: 0,
      FICA: 0,
      property: 0,

      all: 0
    },
    savings: {
      IRA: 0,
      annuity: 0,
      "529college": 0,
      lifeInsurance: 0,
      other: 0,

      all: 0
    }
  },
  MoneyOutDetailWhatIf: {
    taxes: {
      income: 0,
      capital: 0,
      state: 0,
      FICA: 0,
      property: 0,

      all: 0
    },
    savings: {
      IRA: 0,
      annuity: 0,
      "529college": 0,
      lifeInsurance: 0,
      other: 0,
      all: 0
    }
  },

  Assets: {
    savings: 0,
    cash: 0,
    investments: 0,
    retirement: 0,
    use: 0,

    all: 0
  },
  AssetsWhatIf: {
    savings: 0,
    cash: 0,
    investments: 0,
    retirement: 0,
    use: 0,

    all: 0
  },

  AssetsDetail: {
    cash: {
      accounts: 0,
      // lifeInsurance: 0,
      all: 0
    },
    investments: {
      mutualFunds: 0,
      stocks: 0,
      treasuries: 0,
      unvestedRSU: 0,
      general: 0,
      // ESPP: 0,
      all: 0
    },
    retirement: {
      "401K": 0,
      IRA: 0,
      deferredComp: 0,
      annuity: 0,
      lifeInsurance: 0,
      "529college": 0,
      other: 0,
      all: 0
    },
    taxable: {
      stocks: 0,
      mutualAndTreasuries: 0,
      EsppAndRsu: 0,
      cash: 0,
      savings: 0,

      all: 0
    },
    taxDeferred: {
      "401K": 0,
      IRA: 0,
      annuity: 0,
      deferredComp: 0,
      lifeInsurance: 0,
      // estateInvestments: 0,
      // estatePrimaryRes: 0,
      other: 0,
      houses: 0,
      all: 0
    },
    taxAdvantage: {
      IRA: 0,
      // annuity: 0,
      lifeInsurance: 0,
      "529college": 0,
      other: 0,

      all: 0
    },
    use: {
      home: 0,
      vehicle: 0,
      other: 0
    }
  },
  AssetsDetailWhatIf: {
    cash: {
      accounts: 0,
      // lifeInsurance: 0,
      all: 0
    },
    investments: {
      mutualFunds: 0,
      stocks: 0,
      treasuries: 0,
      unvestedRSU: 0,
      general: 0,

      // ESPP: 0,
      all: 0
    },
    retirement: {
      "401K": 0,
      IRA: 0,
      deferredComp: 0,
      annuity: 0,
      lifeInsurance: 0,
      "529college": 0,
      other: 0,
      all: 0
    },
    taxable: {
      stocks: 0,
      mutualAndTreasuries: 0,
      EsppAndRsu: 0,
      cash: 0,
      savings: 0,

      all: 0
    },
    taxDeferred: {
      "401K": 0,
      IRA: 0,
      annuity: 0,
      deferredComp: 0,
      lifeInsurance: 0,
      // estateInvestments: 0,
      // estatePrimaryRes: 0,
      houses: 0,
      other: 0,

      all: 0
    },
    taxAdvantage: {
      IRA: 0,
      // annuity: 0,
      lifeInsurance: 0,
      "529college": 0,
      other: 0,

      all: 0
    },
    use: {
      home: 0,
      vehicle: 0,
      other: 0
    }
  },

  AssetsTax: {
    deferred: 0,
    advantage: 0
  },
  AssetsTaxWhatIf: {
    deferred: 0,
    advantage: 0
  },

  Liabilities: {
    mortgage: 0,
    loans: 0,
    capitalTax: 0,
    deferredIncomeTax: 0,

    all: 0
  },
  LiabilitiesWhatIf: {
    mortgage: 0,
    loans: 0,
    capitalTax: 0,
    deferredIncomeTax: 0,

    all: 0
  },

  TaxCategories: {
    taxable: 0,
    taxDeferred: 0,
    taxAdvantage: 0,

    all: 0
  },
  TaxCategoriesWhatIf: {
    taxable: 0,
    taxDeferred: 0,
    taxAdvantage: 0,

    all: 0
  },

  TempValues: {
    investments: {
      RST_ESPP: 0,
      businessInterest: 0
    },
    savings: {
      currYear: 0,
      retirementOtherCapital: 0
    },
    // Only standard
    MoneyIn: {
      salary: {
        you: 0,
        spouse: 0
      }
    },
    MoneyOut: {
      cumulative: 0,
      cumulativeByStart: {},
      estimatedTaxes: 0
    },
    Liabilities: {
      capitalTaxBefore: 0,
      homes: 0
    },
    accounts: {
      checking: 0,
      savings: 0,
      moneyMarket: 0,
      cd: 0
    },
    debt: {
      student: 0,
      home: 0,
      creditCard: 0,
      medical: 0,
      other: 0,
      general: 0
    },
    use: {
      homePurchase: 0,
      value: 0
    }
  },
  TempValuesWhatIf: {
    investments: {
      RST_ESPP: 0
    },
    savings: {
      currYear: 0,
      retirementOtherCapital: 0
    },
    MoneyOut: {
      cumulative: 0,
      cumulativeByStart: {},
      estimatedTaxes: 0
    },
    Liabilities: {
      capitalTaxBefore: 0,
      homes: 0
    },
    use: {
      homePurchase: 0,
      value: 0
    }
  }
};

class CalcContainer extends PureComponent {
  static propTypes = {
    transactionsAll: PropTypes.any,
    assetsAll: PropTypes.any,
    goalsAll: PropTypes.any,
    eventsALl: PropTypes.any,
    profile: PropTypes.object,
    configurationDepletion: PropTypes.any,
    rights: PropTypes.any,
    family: PropTypes.any
  };

  state = {
    // ...initialCalcState,
    // stat: null,

    whatIfChecked: true,
    moneyOutStatState: null,
    assetsStatState: null,
    chartTerm: "20",
    // activeYear: null,
    // containerWidth: 0,
    fullCalc: {},
    assetsLog: {},
    isCalculating: false,
    currentAge: MIN_CALC_AGE,
    calcTimestamp: null
  };

  componentDidMount() {
    const {
      assetsAll,
      transactionsAll,
      profileProvider,
      family,
      rights,
      firestoreStatus,
      auth
    } = this.props;

    const isOwnProfile = profileProvider === auth.uid;
    const isRightsReady =
      (isOwnProfile && firestoreStatus && !firestoreStatus.requesting.rights) ||
      !isOwnProfile;

    const isConfigReady =
      firestoreStatus &&
      !firestoreStatus.requesting.configuration &&
      firestoreStatus.requested.configuration;

    const isAssetsReady =
      firestoreStatus &&
      !firestoreStatus.requesting.assetsAll &&
      !firestoreStatus.requesting.transactionsAll &&
      firestoreStatus.requested.assetsAll &&
      firestoreStatus.requested.transactionsAll;

    if (
      isConfigReady &&
      isRightsReady &&
      isAssetsReady &&
      profileProvider &&
      assetsAll &&
      transactionsAll &&
      family &&
      rights
    ) {
      this.calcFullStat();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      assetsAll,
      transactionsAll,
      eventsAll,
      profileProvider,
      family,
      rights,
      auth,
      firestoreStatus
    } = this.props;

    const assetsAllPrev = prevProps.assetsAll;
    const transactionsAllPrev = prevProps.transactionsAll;
    const eventsAllPrev = prevProps.eventsAll;

    const isRightsChanged =
      prevProps.profileProvider !== profileProvider ||
      prevProps.rights !== rights;

    const isOwnProfile = profileProvider === auth.uid;
    const isRightsReady =
      (isOwnProfile && firestoreStatus && !firestoreStatus.requesting.rights) ||
      !isOwnProfile;

    const isConfigReady =
      firestoreStatus &&
      !firestoreStatus.requesting.configuration &&
      firestoreStatus.requested.configuration;
    const isConfigChanged =
      !firestoreStatus.requesting.configuration &&
      prevProps.firestoreStatus.requesting.configuration;

    const isAssetsReady =
      firestoreStatus &&
      !firestoreStatus.requesting.assetsAll &&
      !firestoreStatus.requesting.transactionsAll &&
      firestoreStatus.requested.assetsAll &&
      firestoreStatus.requested.transactionsAll;

    if (
      // family &&
      isConfigReady &&
      isRightsReady &&
      isAssetsReady &&
      (!this.state.fullCalc ||
        !Object.keys(this.state.fullCalc).length ||
        isRightsChanged ||
        isConfigChanged ||
        profileProvider !== prevProps.profileProvider ||
        ((assetsAll !== assetsAllPrev ||
          transactionsAll !== transactionsAllPrev) &&
          assetsAll &&
          transactionsAll) ||
        eventsAll !== eventsAllPrev ||
        (family && !prevProps.family) ||
        family !== prevProps.family ||
        (family &&
          prevProps.family &&
          (family.id !== prevProps.family.id ||
            family.displayName !== prevProps.family.displayName)))
    ) {
      this.calcFullStat();
    }
  }

  applyTax = (isMarried, amount, { scheme }) => {
    const localScheme = isMarried ? scheme.married : scheme.single;
    let result = 0;

    for (let key = 0; key < localScheme.sum.length; key++) {
      const sum = localScheme.sum[key];

      if (amount < sum) {
        break;
      }

      const nextSum = localScheme.sum[key + 1] || 0;
      const rate = localScheme.rates[key];

      let calcAmount = Math.min(
        amount > nextSum && nextSum ? nextSum - sum : amount - sum,
        nextSum ? amount - sum : Infinity
      );

      const calcRate = rate / 100 || 0;
      result += calcAmount * calcRate;
    }

    return result;
  };

  applyTaxSimple = (isMarried, amount, { scheme, customAmountCheck }) => {
    const localScheme = isMarried ? scheme.married : scheme.single;
    let calcRate = 0;

    for (let key = 0; key < localScheme.sum.length; key++) {
      const sum = localScheme.sum[key];

      if (customAmountCheck < sum) {
        break;
      }

      calcRate = localScheme.rates[key] / 100 || 0;
    }

    return amount * calcRate;
  };

  calcTaxesAndAll = (
    temp,
    isMarried,
    {
      prevYearSavings = 0,
      prevYearSavingsWhatIf = 0,
      yearKey,
      overdraftRepaid,
      overdraftRepaidWhatIf,
      prevYear,
      lifeEvents,
      lifeEventsWhatIf
    } = {}
  ) => {
    const { family } = this.props;
    let isOverdraft = false;
    let isOverdraftWhatIf = false;

    let isMarriedLocal = isMarried;
    let isMarriedLocalWhatIf = isMarried;

    let zipCodeLocal = family && +family.zipCode;
    let zipCodeLocalWhatIf = family && +family.zipCode;

    if (lifeEvents) {
      if (lifeEvents.death) {
        const ageDeathSpouse = +lifeEvents.death["Spouse"];
        const ageDeathYou = +lifeEvents.death["You"];

        if (
          (ageDeathSpouse && ageDeathSpouse <= +yearKey) ||
          (ageDeathYou && +ageDeathYou <= +yearKey)
        ) {
          isMarriedLocal = false;
        }
      }

      if (lifeEvents.relocation && lifeEvents.relocation.age) {
        const ageRelocation = +lifeEvents.relocation.age;

        if (ageRelocation && ageRelocation <= +yearKey) {
          zipCodeLocal = lifeEvents.relocation.zipCode;
        }
      }
    }
    if (lifeEventsWhatIf) {
      if (lifeEventsWhatIf.death) {
        const ageDeathSpouse = +lifeEventsWhatIf.death["Spouse"];
        const ageDeathYou = +lifeEventsWhatIf.death["You"];

        if (
          (ageDeathSpouse && ageDeathSpouse <= +yearKey) ||
          (ageDeathYou && ageDeathYou <= +yearKey)
        ) {
          isMarriedLocalWhatIf = false;
        }
      }

      if (lifeEventsWhatIf.relocation && lifeEventsWhatIf.relocation.age) {
        const ageRelocation = +lifeEventsWhatIf.relocation.age;

        if (ageRelocation && ageRelocation <= +yearKey) {
          zipCodeLocalWhatIf = lifeEventsWhatIf.relocation.zipCode;
        }
      }
    }

    temp.MoneyIn.all = this.helperSumAllProperties(temp.MoneyIn);
    temp.MoneyInWhatIf.all = this.helperSumAllProperties(temp.MoneyInWhatIf);

    temp.MoneyOutDetail.taxes.income =
      temp.MoneyOutDetail.taxes.income +
      temp.MoneyIn.salary +
      temp.MoneyIn.bank +
      temp.MoneyIn.realEstate;

    temp.MoneyOutDetailWhatIf.taxes.income =
      temp.MoneyOutDetailWhatIf.taxes.income +
      temp.MoneyInWhatIf.salary +
      temp.MoneyInWhatIf.bank +
      temp.MoneyInWhatIf.realEstate;

    if (zipCodeLocal && zipCodeLocal > 90001 && zipCodeLocal < 96162) {
      temp.MoneyOutDetail.taxes.state = this.applyTax(
        isMarriedLocal,
        temp.MoneyOutDetail.taxes.income,
        {
          scheme: stateTable
        }
      );
    }

    // if what-If event relocation
    if (
      zipCodeLocalWhatIf &&
      zipCodeLocalWhatIf > 90001 &&
      zipCodeLocalWhatIf < 96162
    ) {
      temp.MoneyOutDetailWhatIf.taxes.state = this.applyTax(
        isMarriedLocalWhatIf,
        temp.MoneyOutDetail.taxes.income,
        {
          scheme: stateTable
        }
      );
    }

    temp.MoneyOutDetail.taxes.income = this.applyTax(
      isMarriedLocal,
      // temp.MoneyOutDetail.taxes.income + temp.MoneyOutDetail.taxes.capital,
      temp.MoneyOutDetail.taxes.income,
      {
        scheme: incomeTaxTable
      }
    );
    temp.MoneyOutDetailWhatIf.taxes.income = this.applyTax(
      isMarriedLocalWhatIf,
      // temp.MoneyOutDetailWhatIf.taxes.income + temp.MoneyOutDetailWhatIf.taxes.capital,
      temp.MoneyOutDetailWhatIf.taxes.income,
      {
        scheme: incomeTaxTable
      }
    );

    temp.MoneyOutDetail.taxes.capital = this.applyTaxSimple(
      isMarriedLocal,
      temp.MoneyOutDetail.taxes.capital,
      {
        scheme: capitalGainTable,
        customAmountCheck: temp.MoneyIn.all
      }
    );
    temp.MoneyOutDetailWhatIf.taxes.capital = this.applyTaxSimple(
      isMarriedLocalWhatIf,
      temp.MoneyOutDetailWhatIf.taxes.capital,
      {
        scheme: capitalGainTable,
        customAmountCheck: temp.MoneyInWhatIf.all
      }
    );

    Object.keys(temp.MoneyOutDetail).forEach(key => {
      temp.MoneyOutDetail[key].all = this.helperSumAllProperties(
        temp.MoneyOutDetail[key]
      );

      temp.MoneyOutDetailWhatIf[key].all = this.helperSumAllProperties(
        temp.MoneyOutDetailWhatIf[key]
      );
    });

    temp.MoneyOut.taxes += temp.MoneyOutDetail.taxes.all;
    temp.MoneyOutWhatIf.taxes += temp.MoneyOutDetailWhatIf.taxes.all;

    temp.MoneyOut.all = this.helperSumAllProperties(temp.MoneyOut);
    temp.MoneyOutWhatIf.all = this.helperSumAllProperties(temp.MoneyOutWhatIf);

    //// ASSETS
    temp.AssetsDetail.investments.stocks +=
      temp.TempValues.investments.RST_ESPP;
    temp.AssetsDetailWhatIf.investments.stocks +=
      temp.TempValuesWhatIf.investments.RST_ESPP;
    temp.Assets.investments += temp.TempValues.investments.RST_ESPP;
    temp.AssetsWhatIf.investments += temp.TempValuesWhatIf.investments.RST_ESPP;

    temp.Assets.retirement = this.helperSumAllProperties(
      temp.AssetsDetail.retirement
    );
    temp.AssetsWhatIf.retirement = this.helperSumAllProperties(
      temp.AssetsDetailWhatIf.retirement
    );

    //  SUM ALL PREV YEARS
    if (overdraftRepaid || overdraftRepaid === 0) {
      temp.Assets.savings = overdraftRepaid;
      temp.AssetsWhatIf.savings = overdraftRepaid;
    } else {
      temp.Assets.savings =
        temp.MoneyIn.all - temp.MoneyOut.all + prevYearSavings;
      temp.AssetsWhatIf.savings =
        temp.MoneyInWhatIf.all -
        temp.MoneyOutWhatIf.all +
        prevYearSavingsWhatIf;
    }

    temp.TempValues.MoneyOut.cumulative +=
      temp.MoneyOut.all +
      (prevYear ? prevYear.TempValues.MoneyOut.cumulative : 0);

    temp.TempValuesWhatIf.MoneyOut.cumulative +=
      temp.MoneyOutWhatIf.all +
      (prevYear ? prevYear.TempValuesWhatIf.MoneyOut.cumulative : 0);

    temp.TempValues.MoneyOut.cumulativeByStart[yearKey] =
      temp.TempValues.MoneyOut.cumulative;

    temp.TempValues.MoneyOut.estimatedTaxes +=
      temp.MoneyOut.taxes +
      (prevYear ? prevYear.TempValues.MoneyOut.estimatedTaxes : 0);

    if (temp.Assets.savings < 0) {
      isOverdraft = true;
    }
    if (temp.AssetsWhatIf.savings < 0) {
      isOverdraftWhatIf = true;
    }

    temp.TempValues.savings.currYear = temp.MoneyIn.all - temp.MoneyOut.all;
    temp.TempValuesWhatIf.savings.currYear =
      temp.MoneyInWhatIf.all - temp.MoneyOutWhatIf.all;

    temp.Assets.all = this.helperSumAllProperties(temp.Assets);
    temp.AssetsWhatIf.all = this.helperSumAllProperties(temp.AssetsWhatIf);

    // CALC Assets detail
    temp.AssetsDetail.taxable.stocks = temp.AssetsDetail.investments.stocks;
    temp.AssetsDetail.taxable.mutualAndTreasuries =
      temp.AssetsDetail.investments.mutualFunds +
      temp.AssetsDetail.investments.treasuries;
    temp.AssetsDetail.taxable.EsppAndRsu =
      (temp.AssetsDetail.investments.ESPP || 0) +
      temp.AssetsDetail.investments.unvestedRSU;

    temp.AssetsDetailWhatIf.taxable.stocks =
      temp.AssetsDetailWhatIf.investments.stocks;
    temp.AssetsDetailWhatIf.taxable.mutualAndTreasuries =
      temp.AssetsDetailWhatIf.investments.mutualFunds +
      temp.AssetsDetailWhatIf.investments.treasuries;
    temp.AssetsDetailWhatIf.taxable.EsppAndRsu =
      (temp.AssetsDetailWhatIf.investments.ESPP || 0) +
      temp.AssetsDetailWhatIf.investments.unvestedRSU;
    /////////////////////////////////////////////////////

    ///// LIABILITIES
    temp.Liabilities.capitalTax += temp.Assets.investments;
    temp.LiabilitiesWhatIf.capitalTax += temp.AssetsWhatIf.investments;

    temp.TempValues.Liabilities.capitalTaxBefore = temp.Liabilities.capitalTax;
    //apply tax
    temp.Liabilities.capitalTax = this.applyTax(
      isMarriedLocal,
      temp.Liabilities.capitalTax,
      {
        scheme: capitalGainTable
      }
    );

    temp.TempValuesWhatIf.Liabilities.capitalTaxBefore =
      temp.LiabilitiesWhatIf.capitalTax;
    temp.LiabilitiesWhatIf.capitalTax = this.applyTax(
      isMarriedLocalWhatIf,
      temp.LiabilitiesWhatIf.capitalTax,
      { scheme: capitalGainTable }
    );

    temp.Liabilities.deferredIncomeTax = this.applyTax(
      isMarriedLocal,
      temp.TaxCategories.taxDeferred,
      { scheme: incomeTaxTable }
    );
    temp.LiabilitiesWhatIf.deferredIncomeTax = this.applyTax(
      isMarriedLocalWhatIf,
      temp.TaxCategoriesWhatIf.taxDeferred,
      { scheme: incomeTaxTable }
    );

    temp.Liabilities.all = this.helperSumAllProperties(temp.Liabilities);
    temp.LiabilitiesWhatIf.all = this.helperSumAllProperties(
      temp.LiabilitiesWhatIf
    );

    // TAXES
    temp.TaxCategories.taxable = temp.Assets.investments;
    temp.TaxCategoriesWhatIf.taxable = temp.AssetsWhatIf.investments;

    temp.AssetsDetail.taxable.cash += temp.AssetsDetail.cash.accounts;
    temp.AssetsDetailWhatIf.taxable.cash +=
      temp.AssetsDetailWhatIf.cash.accounts;

    const savingsCurYear =
      temp.TempValues.savings.currYear > 0
        ? temp.TempValues.savings.currYear
        : 0;
    temp.TaxCategories.taxable += savingsCurYear;

    const savingsCurYearWhatIf =
      temp.TempValuesWhatIf.savings.currYear > 0
        ? temp.TempValuesWhatIf.savings.currYear
        : 0;
    temp.TaxCategoriesWhatIf.taxable += savingsCurYearWhatIf;

    temp.AssetsDetail.taxable.savings = savingsCurYear;
    temp.AssetsDetailWhatIf.taxable.savings = savingsCurYearWhatIf;

    // TAX DEFERRED
    // temp.TaxCategories.taxDeferred +=
    //   temp.AssetsDetail.taxDeferred.estateInvestments +
    //   temp.AssetsDetail.taxDeferred.estatePrimaryRes;
    // temp.TaxCategoriesWhatIf.taxDeferred +=
    //   temp.AssetsDetailWhatIf.taxDeferred.estateInvestments +
    //   temp.AssetsDetailWhatIf.taxDeferred.estatePrimaryRes;

    temp.AssetsDetail.taxDeferred.houses += temp.TempValues.use.value;
    temp.TaxCategories.taxDeferred += temp.TempValues.use.value;

    temp.AssetsDetailWhatIf.taxDeferred.houses +=
      temp.TempValuesWhatIf.use.value;
    temp.TaxCategoriesWhatIf.taxDeferred += temp.TempValuesWhatIf.use.value;

    // TAX ADVANTAGE
    // TAX ALL
    temp.TaxCategories.all = this.helperSumAllProperties(temp.TaxCategories);
    temp.TaxCategoriesWhatIf.all = this.helperSumAllProperties(
      temp.TaxCategoriesWhatIf
    );

    Object.keys(temp.AssetsDetail).forEach(key => {
      temp.AssetsDetail[key].all = this.helperSumAllProperties(
        temp.AssetsDetail[key]
      );

      temp.AssetsDetailWhatIf[key].all = this.helperSumAllProperties(
        temp.AssetsDetailWhatIf[key]
      );
    });

    temp.TaxCategories.taxAdvantage = temp.AssetsDetail.taxAdvantage.all;
    temp.TaxCategoriesWhatIf.taxAdvantage =
      temp.AssetsDetailWhatIf.taxAdvantage.all;

    return { isOverdraft, isOverdraftWhatIf };
  };

  calcChartDataByYear = (yearData, { isOverdraft = false } = {}) => {
    // WEALTH
    yearData.chartData.NetWealth =
      yearData.Assets.all - yearData.Liabilities.all;
    yearData.chartDataWhatIf.NetWealth =
      yearData.AssetsWhatIf.all - yearData.LiabilitiesWhatIf.all;

    // Homes & Vehicles=3.e-4.a-((4.c.i.3+4.c.i.4)/4.c.i)*4.c
    // 1. Take total value of homes & vehicles assets and deduct from them their respective tax and liabilities (4.a).
    // 2. Calculate respective tax by tkaing the home&vehicle assets out of totals assets and multiply by total tax paid
    ///// 4.c.i.3 + 4.c.i.4 -> TempValues.Liabilities.homes
    const temp4c = yearData.Liabilities.capitalTax;
    const temp4cWhatIf = yearData.LiabilitiesWhatIf.capitalTax;

    yearData.chartData.HomesAndVehicles =
      yearData.Assets.use - // 3.e
      yearData.Liabilities.mortgage - // 4.a
      ((yearData.TempValues.Liabilities.homes / //((4.c.i.3+4.c.i.4)/4.c.i)*4.c
        yearData.TempValues.Liabilities.capitalTaxBefore) *
        temp4c || 0);
    yearData.chartDataWhatIf.HomesAndVehicles =
      yearData.AssetsWhatIf.use - // 3.e
      yearData.LiabilitiesWhatIf.mortgage - // 4.a
      ((yearData.TempValuesWhatIf.Liabilities.homes /
        yearData.TempValuesWhatIf.Liabilities.capitalTaxBefore) *
        temp4cWhatIf || 0);

    // Change. Logic is as follows:
    // 1. Take total value of all assets and remove homes&vehicle. This leaves all monetary assets, BUT they are pre-tax, so now need to remove only the tax for the monetary assets as well as the liabilities for these assets
    // 2. Deduct any loans that are specific to monetary assets (they will be back via the liabilities data (4.a+4.b)
    // 3. Deduct the tax on the monetary assets by calculating the ratio of monetary assets out of total assets and multiplying by total tax paid
    // 4. Deduct the deferred tax from retirement & savings
    // Monetary Assets=TOTAL Assets (item 3)-Homes&Vehicles(3.e)-Non home loans (4.b)-((4.c.i.1+4.c.i.2)/4.c.i)*4.c-4.d+Accrued Savings
    ///////////////////////////////////////////////////////////////
    // 4.c.i.1 (TempValues.savings.retirementOtherCapital)
    // 4.c.i.2 === 3.c (Assets.investments)
    yearData.chartData.MonetaryAssets =
      yearData.Assets.all - //3
      yearData.Assets.use - //3.e
      yearData.Liabilities.loans - //4.b
      // ((4.c.i.1 + 4.c.i.2) / 4.c.i) * 4.c
      (((yearData.TempValues.savings.retirementOtherCapital +
        yearData.Assets.investments) /
        yearData.TempValues.Liabilities.capitalTaxBefore) *
        temp4c || 0) -
      yearData.Liabilities.deferredIncomeTax + // 4.d
        yearData.Assets.savings || 0; // Accrued Savings

    yearData.chartDataWhatIf.MonetaryAssets =
      yearData.AssetsWhatIf.all - //3
      yearData.AssetsWhatIf.use - //3.e
      yearData.LiabilitiesWhatIf.loans - //4.b
      // ((4.c.i.1 + 4.c.i.2) / 4.c.i) * 4.c
      (((yearData.TempValuesWhatIf.savings.retirementOtherCapital +
        yearData.AssetsWhatIf.investments) /
        yearData.TempValuesWhatIf.Liabilities.capitalTaxBefore) *
        temp4cWhatIf || 0) -
      yearData.LiabilitiesWhatIf.deferredIncomeTax + // 4.d
        yearData.AssetsWhatIf.savings || 0; // Accrued Savings

    yearData.chartData.MonetaryAssetsShow =
      yearData.chartData.MonetaryAssets < 0
        ? 0
        : yearData.chartData.MonetaryAssets;

    yearData.chartDataWhatIf.MonetaryAssetsShow =
      yearData.chartDataWhatIf.MonetaryAssets < 0
        ? 0
        : yearData.chartDataWhatIf.MonetaryAssets;

    ///////////////////////////////////////////////////////////////
    // yearData.Liabilities.all;
    ///////////////////////////////////////////////////////////////
    // yearData.chartDataWhatIf.MonetaryAssets =
    //   yearData.AssetsWhatIf.all -
    //   yearData.AssetsWhatIf.use -
    //   yearData.LiabilitiesWhatIf.all;

    yearData.chartData.Liabilities =
      yearData.Liabilities.mortgage + yearData.Liabilities.loans;
    yearData.chartDataWhatIf.Liabilities =
      yearData.LiabilitiesWhatIf.mortgage + yearData.LiabilitiesWhatIf.loans;

    yearData.chartData.Taxes =
      yearData.Liabilities.capitalTax + yearData.Liabilities.deferredIncomeTax;
    yearData.chartDataWhatIf.Taxes =
      yearData.LiabilitiesWhatIf.capitalTax +
      yearData.LiabilitiesWhatIf.deferredIncomeTax;

    // INCOME
    yearData.chartData.Savings = yearData.TempValues.savings.currYear;
    yearData.chartDataWhatIf.Savings =
      yearData.TempValuesWhatIf.savings.currYear;

    const moneyOut = yearData.MoneyOut;
    const moneyOutWhatIf = yearData.MoneyOutWhatIf;
    yearData.chartData.Expenses =
      moneyOut.personal +
      moneyOut.asset +
      moneyOut.savings +
      moneyOut.debtRepayment +
      moneyOut.other;
    yearData.chartDataWhatIf.Expenses =
      moneyOutWhatIf.personal +
      moneyOutWhatIf.asset +
      moneyOutWhatIf.savings +
      moneyOutWhatIf.debtRepayment +
      moneyOutWhatIf.other;

    yearData.chartData.TaxesIncome = moneyOut.taxes;
    yearData.chartDataWhatIf.TaxesIncome = moneyOutWhatIf.taxes;
  };

  calcChartData = (tempFull, currentAge, { isOverdraft = false } = {}) => {
    const currentYear = new Date().getFullYear();

    Object.keys(tempFull).forEach(keyYear => {
      if (isOverdraft && !tempFull[keyYear].overdraft) {
        tempFull[keyYear].overdraft = tempFull[keyYear];
      }

      const yearData = isOverdraft
        ? tempFull[keyYear].overdraft
        : tempFull[keyYear];

      yearData.year = currentYear + (keyYear - currentAge);

      this.calcChartDataByYear(yearData, { isOverdraft });
    });
  };

  calcSalaries = (
    entity,
    tempYear,
    { calcAge, t, isOverdraft, lifeEvents, lifeEventsWhatIf }
  ) => {
    const isWhatIf = entity.isWhatIf;
    const { assetsAll, family } = this.props;

    let lifeEventDeath;
    let lifeEventJobLoss;
    if (lifeEvents && entity.employeeName) {
      if (lifeEvents.death) {
        lifeEventDeath = lifeEvents.death[entity.employeeName];
      }
      if (lifeEvents.jobLoss) {
        lifeEventJobLoss = lifeEvents.jobLoss[entity.employeeName];
      }
    }

    let lifeEventJobLossWhatIf;
    let lifeEventDeathWhatIf;
    if (lifeEventsWhatIf && entity.employeeName) {
      if (lifeEventsWhatIf.death) {
        lifeEventDeathWhatIf = lifeEventsWhatIf.death[entity.employeeName];
      }
      if (lifeEvents.jobLoss) {
        lifeEventJobLossWhatIf = lifeEventsWhatIf.jobLoss[entity.employeeName];
      }
    }

    const isExpiredAnticipatedAge =
      entity && entity.anticipatedEndAge < calcAge;

    if (isExpiredAnticipatedAge) {
      return;
    }

    const values = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf,
      valueEarning: 0,
      spendingAccount: 0,
      value401k: 0,
      value401kSelf: 0,
      valueSpendingPlus401K: 0,
      valueEarningWoSpending: 0,
      valueEarningWoSpending401K: 0,
      valueRST: 0,
      taxMultiplier: 0,
      employeeName: entity.employeeName
    };

    const isSelfEmployed =
      (entity.employeeName === "Spouse" &&
        family &&
        family.spouse &&
        family.spouse.employedStatus === "self-employed") ||
      (entity.employeeName === "You" &&
        family &&
        family.employedStatus === "self-employed");

    values.taxMultiplier = isSelfEmployed
      ? taxFICAValue + taxFICAValue
      : taxFICAValue;

    values.spendingAccount = entity.spendingAccount
      ? (+entity.spendingAccount.dca || 0) +
        (+entity.spendingAccount.fsa || 0) +
        (+entity.spendingAccount.hsa || 0)
      : 0;

    const account401KId = entity["401k"] && entity["401k"]["401kAccount"];
    const account401KEntity = account401KId
      ? assetsAll.find(el => el.id === account401KId)
      : null;

    values.value401k = account401KId
      ? +entity["401k"].employer + +entity["401k"].self
      : 0;
    values.value401kSelf = account401KId ? +entity["401k"].self : 0;
    values.valueSpendingPlus401K = values.value401k + values.spendingAccount;

    /////////////// HELPERS
    const valueFuncRST = (entity, { t, value, PMT }) => {
      const P = value;
      const annualChange = getAnnualChange(entity, { t });
      const r = annualChange && t !== 0 ? +annualChange / 100 : 0;

      const RST = P * (1 + r) + PMT;

      return RST;
    };

    const valueFuncSalary = (entity, { t, calcAge }) => {
      const annualChange = getAnnualChange(entity, { t });

      const baseAnnualChange =
        annualChange && t !== 0
          ? entity.baseSalary * Math.pow(1 + annualChange / 100, t)
          : +entity.baseSalary;

      const earning =
        baseAnnualChange + +entity.variable + +entity.otherCompensation;

      return earning;
    };
    /////////////// HELPERS END
    values.valueEarning = valueFuncSalary(entity, { t });

    values.valueEarningWoSpending =
      values.valueEarning - values.spendingAccount;
    values.valueEarningWoSpending401K =
      values.valueEarningWoSpending - values.value401k;

    if (isOverdraft) {
      if (
        !(lifeEventDeathWhatIf && lifeEventDeathWhatIf <= calcAge) &&
        !(lifeEventJobLossWhatIf && lifeEventJobLossWhatIf <= calcAge)
      ) {
        tempYear.assetsAllWhatIf.moneyIn.salary[entity.id] = values;
      }
    }
    if (
      !(lifeEventDeath && lifeEventDeath <= calcAge) &&
      !(lifeEventJobLoss && lifeEventJobLoss <= calcAge)
    ) {
      tempYear.assetsAll.moneyIn.salary[entity.id] = values;
    }
  };

  calcIncomeRent = (
    entity,
    tempYear,
    { calcAge, t, isOverdraft, lifeEvents, lifeEventsWhatIf }
  ) => {
    const { assetsAll } = this.props;
    const isWhatIf = entity.isWhatIf;

    const assetEntity = entity.asset
      ? assetsAll.find(el => el.id === entity.asset)
      : null;

    const annualChange = getAnnualChange(entity);

    const value =
      (+entity.monthlyRent * 12 -
        (+entity.averageVacancy * +entity.monthlyRent * 12) / 365) *
      Math.pow(1 + annualChange / 100, t);

    const values = {
      entityType: entity.entity,
      isWhatIf,
      value,
      assetEntity: assetEntity ? assetEntity.id : null
    };

    tempYear.assetsAll.moneyIn.rent[entity.id] = values;

    if (isOverdraft) {
      tempYear.assetsAllWhatIf.moneyIn.rent[entity.id] = values;
    }
  };

  calcBusinessInterest = (entity, tempYear, { t, prevYear, isOverdraft }) => {
    const isWhatIf = entity.isWhatIf;

    const prevValues = isOverdraft
      ? prevYear &&
        prevYear.assetsAllWhatIf.investments.businessInterest &&
        prevYear.assetsAllWhatIf.investments.businessInterest[entity.id]
        ? prevYear.assetsAllWhatIf.investments.businessInterest[entity.id]
        : null
      : prevYear &&
        prevYear.assetsAll.investments.businessInterest &&
        prevYear.assetsAll.investments.businessInterest[entity.id]
      ? prevYear.assetsAll.investments.businessInterest[entity.id]
      : null;

    const values = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf,
      amount: prevValues ? prevValues.amount : +entity.investmentValue,
      earn: +prevValues ? +prevValues.earn : 0,
      interestEarned: prevValues
        ? prevValues.interestEarned
        : +entity.interestEarned,
      type: entity.taxCategory
    };

    const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;

    if (annualChange) {
      values.interestEarned =
        values.interestEarned * Math.pow(1 + +annualChange / 100, t);
    }

    values.earn = values.amount * (values.interestEarned / 100) || 0;

    if (entity.taxCategory === optionsTaxCategoryValues.ORDINARY_INCOME) {
      values.taxIncome = values.earn;
    }

    if (entity.taxCategory === optionsTaxCategoryValues.CAPITAL_GAIN) {
      values.taxCapital = values.earn;
    }

    if (isOverdraft) {
      tempYear.assetsAllWhatIf.investments.businessInterest[entity.id] = values;
    } else {
      tempYear.assetsAll.investments.businessInterest[entity.id] = values;
    }
  };

  calcMoneyInOther = (entity, tempYear, { t, calcDate, isOverdraft }) => {
    const isWhatIf = entity.isWhatIf;

    const values = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf,
      value: 0,
      type: entity.taxCategory
    };

    const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;

    if (entity.occurs === optionsOccurrenceValuesOnceEvery.EVERY) {
      const amount = annualChange
        ? +entity.amount * Math.pow(1 + annualChange / 100, t)
        : +entity.amount;

      values.value = optionsOccursEveryValueMult[entity.every] * amount;
    }

    if (
      entity.occurs === optionsOccurrenceValuesOnceEvery.ONCE &&
      entity.onDate &&
      entity.onDate.seconds
    ) {
      const value = +entity.amount;

      const onDate = new Date(entity.onDate.seconds * 1000);

      const isInThisYear = diffYears(onDate, calcDate) === 0;

      if (isInThisYear) {
        values.value = value;
      }
    }

    if (entity.taxCategory === optionsTaxCategoryValues.ORDINARY_INCOME) {
      values.taxIncome = values.value;
    }

    if (entity.taxCategory === optionsTaxCategoryValues.CAPITAL_GAIN) {
      values.taxCapital = values.value;
    }

    if (values.value) {
      if (isOverdraft) {
        tempYear.assetsAllWhatIf.moneyIn.other[entity.id] = values;
      }
      tempYear.assetsAll.moneyIn.other[entity.id] = values;
    }
    //////////////////////////////////////////
  };

  calcAccounts = (entity, tempYear, { t, prevYear, isOverdraft, calcDate }) => {
    const isWhatIf = entity.isWhatIf;
    const entityType = entity.entity;

    const prevValues = isOverdraft
      ? prevYear &&
        prevYear.assetsAllWhatIf.accounts[entityType] &&
        prevYear.assetsAllWhatIf.accounts[entityType][entity.id]
        ? prevYear.assetsAllWhatIf.accounts[entityType][entity.id]
        : null
      : prevYear &&
        prevYear.assetsAll.accounts[entityType] &&
        prevYear.assetsAll.accounts[entityType][entity.id]
      ? prevYear.assetsAll.accounts[entityType][entity.id]
      : null;

    const prevValueBalance = prevValues ? prevValues.balance : +entity.balance;

    const values = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf,
      balance: prevValueBalance,
      interestRateDefault: getAnnualChangeDefault(entity, {
        customField: "interestRate",
        customFieldType: "annualChangeType",
        t
      }),
      interestValue: 0
    };

    const isAnnualChanges =
      entity.entity === entitiesAccounts.ENTITY_SAVINGS ||
      entity.entity === entitiesAccounts.ENTITY_CD ||
      entity.entity === entitiesAccounts.ENTITY_MONEY_MARKET;

    let isExpired = false;
    if (entity.entity === entitiesAccounts.ENTITY_CD) {
      if (
        entity.maturity === optionsValuesMaturity.WITHDRAW ||
        !entity.maturity
      ) {
        let isExpiredLocal = helperIsMonthsLeftPeriod(entity, {
          calcDate,
          expiredCalc: { fieldDate: "depositDate" },
          keyField: "term"
        });

        const monthsLeftPeriodCalc = calculateMonthsLeftPeriod(
          entity,
          { fieldDate: "depositDate" },
          "term",
          { calcDate }
        );

        values.monthsLeft = monthsLeftPeriodCalc;
        if (isExpiredLocal && isExpiredLocal.isExpired) {
          isExpired = true;
        }
      }
    }

    const annualChange = getAnnualChange(entity, {
      customField: "interestRate",
      customFieldType: "annualChangeType",
      t
    });

    if (isAnnualChanges && !isExpired) {
      values.interestRate = t < 1 ? 0 : annualChange;
      // values.interestRate = annualChange;

      const annualChangeMult = 1;
      const interestRateMult = values.interestRate
        ? 1 + values.interestRate / 100
        : 1;

      values.balance = values.balance * interestRateMult * annualChangeMult;
      values.interestValue = values.balance - prevValueBalance;
    }

    if (isOverdraft) {
      tempYear.assetsAllWhatIf.accounts[entityType][entity.id] = values;
    } else {
      tempYear.assetsAll.accounts[entityType][entity.id] = values;
    }
  };

  calcInvestments = (
    entity,
    tempYear,
    { t, prevYear, calcDate, isOverdraft }
  ) => {
    const isWhatIf = entity.isWhatIf;
    const whatIfByOverdraft = isOverdraft ? "WhatIf" : "";
    const entityType = entity.entity;

    const prevValues = isOverdraft
      ? prevYear &&
        prevYear.assetsAllWhatIf.investments[entityType] &&
        prevYear.assetsAllWhatIf.investments[entityType][entity.id]
        ? prevYear.assetsAllWhatIf.investments[entityType][entity.id]
        : null
      : prevYear &&
        prevYear.assetsAll.investments[entityType] &&
        prevYear.assetsAll.investments[entityType][entity.id]
      ? prevYear.assetsAll.investments[entityType][entity.id]
      : null;

    if (
      entity.entity === entitiesInvested.ENTITY_MUTUAL_FUNDS ||
      entity.entity === entitiesInvested.ENTITY_STOCKS ||
      entity.entity === entitiesInvested.ENTITY_TREASURIES ||
      entity.entity === entitiesInvested.ENTITY_GENERAL
    ) {
      const values = {
        id: entity.id,
        entityType: entityType,
        isWhatIf,
        value: prevValues ? prevValues.value : +entity.value
      };

      const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;

      const annualChangeMult = annualChange ? 1 + annualChange / 100 : 1;

      values.value = values.value * annualChangeMult;

      tempYear[`assetsAll${whatIfByOverdraft}`].investments[entityType][
        entity.id
      ] = values;
    }

    if (entity.entity === entitiesInvested.ENTITY_RSU) {
      const isExpired = helperIsRemainingVestingPeriod(entity, { calcDate });
      if (isExpired) {
        return;
      }

      const value = calculateUnvestedEstimateClean(entity);

      tempYear[`assetsAll${whatIfByOverdraft}`].investments[entityType][
        entity.id
      ] = {
        entityType: entity.entity,
        isWhatIf,
        value
      };
    }

    if (entity.entity === entitiesInvested.ENTITY_ESPP) {
      const value = calculateInvestedPurchAmountClean(entity);

      const valueRST = valueFuncRSTESPP(entity, { t, value });

      const values = {
        entityType: entity.entity,
        isWhatIf,
        value: valueRST,
        valueRST
      };

      tempYear[`assetsAll${whatIfByOverdraft}`].investments[entityType][
        entity.id
      ] = {
        ...values
      };
    }
  };

  calcSavings = (
    entity,
    tempYear,
    {
      t,
      prevYear,
      calcDate,
      calcAge,
      currentAge,
      isOverdraft,
      lifeEvents,
      lifeEventsWhatIf
    }
  ) => {
    const prevYearAssets = prevYear
      ? isOverdraft
        ? prevYear.assetsAllWhatIf
        : prevYear.assetsAll
      : null;

    const isWhatIf = entity.isWhatIf;
    const whatIfByOverdraft = isOverdraft ? "WhatIf" : "";
    const defaultValue = +entity.value;

    const annualContribution = valueFuncAnnualContrib(entity, { calcAge });

    const valuesCommon = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf,
      type: entity.taxCategory,
      annualContribution,
      owner: entity.owner
    };

    if (entity.entity === entitiesSavings.ENTITY_DEFERRED_COMPENSATION) {
      const values = {
        entityType: entity.entity,
        value: 0
      };

      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings.deferredComp &&
        prevYearAssets.savings.deferredComp[entity.id]
          ? prevYearAssets.savings.deferredComp[entity.id]
          : null;

      values.value = prevValues ? prevValues.value : +entity.value;

      const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;
      if (annualChange) {
        values.value = values.value * (1 + annualChange / 100);
      }

      values.taxDeferred = +values.value;
      tempYear[`assetsAll${whatIfByOverdraft}`].savings.deferredComp[
        entity.id
      ] = values;
    }

    if (
      entity.entity === entitiesSavings.ENTITY_OTHER ||
      entity.entity === entitiesSavings.ENTITY_GENERAL
    ) {
      const otherType = optionsOtherTypeCalc[entity.taxCategory || ""];

      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings[otherType] &&
        prevYearAssets.savings[otherType][entity.id]
          ? prevYearAssets.savings[otherType][entity.id]
          : null;

      const currentValue = prevValues ? prevValues.value : +entity.value;

      const values = {
        ...valuesCommon,
        entityType: entity.entity,
        value: currentValue
      };

      const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;
      if (annualChange) {
        values.value = values.value * (1 + annualChange / 100);
      }

      if (otherType === "otherCapital") {
        values.otherCapital = values.value;
      }

      if (entity.taxCategory === optionsTaxCategoryValues.NO_TAX) {
        values.taxAdvantage = values.value;
      } else {
        values.taxDeferred = values.value;
      }

      tempYear[`assetsAll${whatIfByOverdraft}`].savings[otherType][
        entity.id
      ] = values;
    }

    if (entity.entity === entitiesSavings.ENTITY_529_COLLEGE_SAVINGS) {
      const entityKey = "529college";

      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings[entityKey] &&
        prevYearAssets.savings[entityKey][entity.id]
          ? prevYearAssets.savings[entityKey][entity.id]
          : null;

      const currentValue = prevValues ? +prevValues.value : defaultValue;

      const valueRST = valueFuncRSTByPrevContrib(entity, {
        t,
        calcAge,
        value: currentValue
      });

      let values = {
        ...valuesCommon,
        value: valueRST,
        valueRSTBase: currentValue,
        owner: entity.owner
      };

      values.taxAdvantage = +values.value;
      values.taxDeferred = 0;

      tempYear[`assetsAll${whatIfByOverdraft}`].savings[entityKey][
        entity.id
      ] = values;
    }

    if (entity.entity === entitiesSavings.ENTITY_IRA) {
      const { values, entityKey } = this.calcIRA(entity, tempYear, {
        t,
        prevYear,
        calcDate,
        calcAge,
        isOverdraft,
        prevYearAssets
      });

      tempYear[`assetsAll${whatIfByOverdraft}`].savings[entityKey][
        entity.id
      ] = values;
    }

    if (entity.entity === entitiesSavings.ENTITY_ANNUITY) {
      const { values, entityKey } = this.calcAnnuity(entity, tempYear, {
        t,
        prevYear,
        calcDate,
        calcAge,
        isOverdraft,
        prevYearAssets
      });

      if (entityKey) {
        tempYear[`assetsAll${whatIfByOverdraft}`].savings[entityKey][
          entity.id
        ] = values;
      }
    }

    if (entity.entity === entitiesSavings.ENTITY_401K) {
      const { values } = this.calc401K(entity, tempYear, {
        t,
        prevYear,
        calcDate,
        calcAge,
        isOverdraft,
        prevYearAssets
      });

      tempYear[`assetsAll${whatIfByOverdraft}`].savings["401k"][
        entity.id
      ] = values;
    }

    if (entity.entity === entitiesSavings.ENTITY_LIFE_INSURANCE) {
      let yearsPassed;
      if (entity.insuranceDate && entity.insuranceDate.seconds) {
        yearsPassed = differenceInYears(
          calcDate,
          new Date(entity.insuranceDate.seconds * 1000)
        );
      }

      let lifeEventDeath;
      if (lifeEvents && entity.employeeName) {
        if (lifeEvents.death) {
          lifeEventDeath = lifeEvents.death[entity.employeeName];
        }
      }

      let lifeEventDeathWhatIf;
      if (lifeEventsWhatIf && entity.employeeName) {
        if (lifeEventsWhatIf.death) {
          lifeEventDeathWhatIf = lifeEventsWhatIf.death[entity.employeeName];
        }
      }

      const annualChange = getAnnualChange(entity, { t });

      const expiredFuncIUL = (entity, { cashValue, deathBenefit }) => {
        return false;
      };

      const expiredFuncTerm = (entity, { calcDate, t }) => {
        const insuranceDate = entity.insuranceDate;

        if (insuranceDate && insuranceDate.seconds && entity.term) {
          const endedDate = addYears(insuranceDate.seconds * 1000, entity.term);
          const daysDiff = differenceInDays(endedDate, calcDate);

          if (daysDiff <= 0) {
            return true;
          }
        }
      };

      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings.lifeInsurance &&
        prevYearAssets.savings.lifeInsurance[entity.id]
          ? prevYearAssets.savings.lifeInsurance[entity.id]
          : null;

      const totalAnnualContribution =
        prevValues && prevValues.totalAnnualContribution
          ? prevValues.totalAnnualContribution
          : valueFuncTotalAnnualContrib(entity, {
              calcAge,
              currentAge
            });

      const isFreeze = prevValues && prevValues.isFreeze;
      const defaultCashValue = +entity.cashValue || 0;

      const deathBenefit =
        prevValues && typeof prevValues.deathBenefit === "number"
          ? prevValues.deathBenefit
          : +entity.deathBenefit;

      const currentCashValue = prevValues
        ? prevValues.cashValue
        : defaultCashValue;

      let calculatedCashValue =
        !isFreeze && t !== 0 && currentCashValue
          ? currentCashValue * (1 + annualChange / 100)
          : currentCashValue;

      const subCashBenefit =
        calculatedCashValue <= deathBenefit
          ? deathBenefit - calculatedCashValue
          : 0;

      const annualContribution = valueFuncAnnualContrib(entity, {
        currentAge,
        calcAge,
        isNotCheckAge: entity.type !== optionsInsuranceTypeValue.IUL
      });

      const annualContributionCumulative =
        ((prevValues && prevValues.annualContributionCumulative) || 0) +
        annualContribution;

      let expiredFunc;
      if (entity.type === optionsInsuranceTypeValue.TERM) {
        expiredFunc = expiredFuncTerm;
      }

      if (entity.type === optionsInsuranceTypeValue.IUL) {
        expiredFunc = expiredFuncIUL;
      }
      if (entity.type === optionsInsuranceTypeValue.ELI) {
        expiredFunc = () => {};
      }

      const isExpired = expiredFunc(entity, {
        calcDate,
        t,
        cashValue: calculatedCashValue,
        cashValueCalced: calculatedCashValue,
        deathBenefit
      });

      const isOverdrafted =
        prevValues &&
        (prevValues.isOverdrafted ||
          (prevValues.cashValue === 0 && prevValues.cover > 0));

      if (isExpired && !isFreeze) {
        return;
      }

      let cashIulValue;

      if (entity.type === optionsInsuranceTypeValue.IUL) {
        if (!isOverdrafted && !isFreeze) {
          const toAge =
            (entity.contribution && +entity.contribution.toAge) || MAX_AGE;
          const isNotFinished = currentAge <= toAge;

          // TODO IS default Cash value or from previous year?
          if (
            isNotFinished &&
            !!totalAnnualContribution &&
            totalAnnualContribution > defaultCashValue &&
            yearsPassed < 8
          ) {
            //If current year-policy issue year<8 then
            // [sum of all annual contributions before current year+Cash Amount-
            // (if current age=to age+1 then one annual contribution amount otherwise 0)
            // ]*Inflation avg rate. If 0 then annual contribution*0.1

            if (calcAge === toAge + 1) {
              cashIulValue = annualContribution;
            } else {
              const prevValuesAnnualContrib =
                (prevValues && prevValues.annualContributionCumulative) || 0;

              cashIulValue =
                (((prevValues && prevValues.cashValue) || defaultCashValue) +
                  prevValuesAnnualContrib +
                  annualContribution) *
                (1 + INFLATION / 100);
              // cashIulValue =
              //   (defaultCashValue +
              //     prevValuesAnnualContrib +
              //     annualContribution) *
              //   (1 + INFLATION / 100);
            }

            if (
              !cashIulValue &&
              entity.contribution &&
              entity.contribution.toAge
            ) {
              cashIulValue = 0.1 * annualContribution;
            }
          }

          if (
            isNotFinished &&
            !!totalAnnualContribution &&
            totalAnnualContribution > defaultCashValue &&
            yearsPassed == 8
          ) {
            //If current year-policy issue year=8 then sum total annual contributions
            cashIulValue = totalAnnualContribution;
          }

          if (
            isNotFinished &&
            !!totalAnnualContribution &&
            totalAnnualContribution > defaultCashValue &&
            yearsPassed > 8
          ) {
            //If current year-policy issue year>8 then sum total annual contributions*(1+[Annual Interest]/100)^(current year-policy issue year-8)

            const factor = yearsPassed - 8;
            cashIulValue =
              totalAnnualContribution *
              Math.pow(1 + annualChange / 100, factor);
          }

          //If (current age>To age) or (no annual contributions) then
          if (!isNotFinished || !totalAnnualContribution) {
            cashIulValue = calculatedCashValue;
          }

          if (!cashIulValue) {
            cashIulValue = defaultCashValue;
          }

          calculatedCashValue = cashIulValue || 0;
        } else {
          if (isOverdrafted) {
            calculatedCashValue = 0;
            cashIulValue = 0;
          }

          if (isFreeze) {
            calculatedCashValue = (prevValues && prevValues.cashValue) || 0;
            cashIulValue = calculatedCashValue;
          }
        }
      }

      const insuranceDate =
        entity.insuranceDate && entity.insuranceDate.seconds
          ? entity.insuranceDate.seconds
          : null;

      const values = {
        insuranceDate,
        entityType: entity.entity,
        isWhatIf,
        cashValue: calculatedCashValue,
        subCashBenefit,
        deathBenefit,
        type: entity.type,
        owner: entity.owner,
        beneficiary: entity.beneficiary,
        annualContributionCumulative,
        annualContribution,
        totalAnnualContribution,
        cashIulValue,
        isOverdrafted,
        isFreeze
      };

      if (entity.type === optionsInsuranceTypeValue.IUL) {
        // values.taxAdvantage = values.subCashBenefit;
        values.taxAdvantage = values.cashValue;
      }

      const valuesWhatIf = cloneObject(values);

      if (
        entity.type === optionsInsuranceTypeValue.TERM ||
        entity.type === optionsInsuranceTypeValue.ELI
      ) {
        // If it is term or employer life insurance and death is while policy is not expired,
        // the entire death benefit becomes cash and death benefit of that insurance policy=0 from that point on

        if (
          lifeEvents.death[entity.owner] &&
          lifeEvents.death[entity.owner] == calcAge
        ) {
          values.cashValue = values.deathBenefit;
          values.isFreeze = true;
          values.deathBenefit = 0;
        }

        if (
          lifeEventsWhatIf.death[entity.owner] &&
          lifeEventsWhatIf.death[entity.owner] == calcAge
        ) {
          valuesWhatIf.cashValue = valuesWhatIf.deathBenefit;
          valuesWhatIf.isFreeze = true;
          valuesWhatIf.deathBenefit = 0;
        }
      }

      if (entity.type === optionsInsuranceTypeValue.IUL) {
        // If it is IUL,
        // if death benefit>cash value, then entire death benefit becomes cash value (instead of the value that was there) and death benefit=0.
        // If cash value>= death benefit, then just set death benefit to 0

        if (
          lifeEvents.death[entity.owner] &&
          lifeEvents.death[entity.owner] == calcAge
        ) {
          if (values.deathBenefit > values.cashValue) {
            values.cashValue = values.deathBenefit - values.cashValue;
            values.isFreeze = true;
            values.taxAdvantage = values.cashValue;
          }
          // else {
          //   values.deathBenefit = 0;
          // }
          values.deathBenefit = 0;
        }

        if (
          lifeEventsWhatIf.death[entity.owner] &&
          lifeEventsWhatIf.death[entity.owner] == calcAge
        ) {
          if (valuesWhatIf.deathBenefit > valuesWhatIf.cashValue) {
            valuesWhatIf.cashValue =
              valuesWhatIf.deathBenefit - valuesWhatIf.cashValue;
            valuesWhatIf.isFreeze = true;
            valuesWhatIf.taxAdvantage = valuesWhatIf.cashValue;
          }
          // else {
          //   values.deathBenefit = 0;
          // }
          values.deathBenefit = 0;
        }
      }

      tempYear[`assetsAll${whatIfByOverdraft}`].savings.lifeInsurance[
        entity.id
      ] = whatIfByOverdraft ? valuesWhatIf : values;
    }
  };

  calcIRA = (
    entity,
    tempYear,
    { t, prevYear, calcDate, calcAge, isOverdraft, prevYearAssets, deductValue }
  ) => {
    const defaultValue = +entity.value;

    const annualContribution = valueFuncAnnualContrib(entity, { calcAge });

    const valuesCommon = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf: entity.isWhatIf,
      type: entity.taxCategory,
      annualContribution,
      owner: entity.owner
    };

    const entityKey = entitiesSavings.ENTITY_IRA;
    const prevValues =
      prevYearAssets &&
      prevYearAssets.savings[entityKey] &&
      prevYearAssets.savings[entityKey][entity.id]
        ? prevYearAssets.savings[entityKey][entity.id]
        : null;

    const currentValue = prevValues ? +prevValues.value : defaultValue;
    valuesCommon.isEventDeduction =
      !!deductValue || (prevValues && prevValues.isEventDeduction);

    let values = {
      ...valuesCommon,
      value: currentValue,
      valueRSTBase: currentValue,
      type: entity.type
    };

    let deductActual = deductValue || 0;
    if (deductValue) {
      if (deductValue > values.value) {
        deductActual = values.value;
        values.value = 0;
      } else {
        values.value -= deductValue;
      }
    }

    values.value = valueFuncRSTByPrevContrib(entity, {
      t,
      calcAge,
      value: values.value
    });

    if (entity.type === TypeIRAValues.ROTH) {
      values.taxAdvantage = +values.value;
    } else {
      values.taxDeferred = +values.value;
    }

    return { values, entityKey, deductActual };
  };

  calc401K = (
    entity,
    tempYear,
    { t, calcAge, prevYearAssets, deductValue }
  ) => {
    const { assetsAll } = this.props;

    const defaultValue = +entity.value;

    const annualContribution = valueFuncAnnualContrib(entity, { calcAge });

    const valuesCommon = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf: entity.isWhatIf,
      type: entity.taxCategory,
      annualContribution,
      owner: entity.owner
    };

    const entityKey = "401k";
    const prevValues =
      prevYearAssets &&
      prevYearAssets.savings[entityKey] &&
      prevYearAssets.savings[entityKey][entity.id]
        ? prevYearAssets.savings[entityKey][entity.id]
        : null;

    const currentValue = prevValues ? +prevValues.value : defaultValue;
    valuesCommon.isEventDeduction =
      !!deductValue || (prevValues && prevValues.isOverdrafted);

    const values = {
      ...valuesCommon,
      value: currentValue,
      owner: entity.owner
    };

    const account401kSalary = assetsAll.find(
      el => el["401k"] && el["401k"]["401kAccount"] === entity.id
    );

    values.value401kSelf = account401kSalary
      ? +account401kSalary["401k"].self
      : 0;
    values.value401kEmployer = account401kSalary
      ? +account401kSalary["401k"].employer
      : 0;

    const valueFuncRST = (entity, { t, value, PMT }) => {
      const P = value;
      const annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;

      const r = annualChange && t !== 0 ? annualChange / 100 : 0;

      const RST = P * (1 + r) + PMT;

      return RST;
    };

    let deductActual = deductValue || 0;
    if (deductValue) {
      if (deductValue > values.value) {
        deductActual = values.value;
        values.value = 0;
      } else {
        values.value -= deductValue;
      }
    }

    values.value = valueFuncRST(entity, {
      value: values.value,
      PMT: values.value401kSelf + values.value401kEmployer,
      t
    });

    values.valueRSTBase = values.value;
    // values.taxDeferred = values.value;
    values.taxDeferred = values.value401kSelf + values.value401kEmployer;

    return { values, entityKey, deductActual };
  };

  calcAnnuity = (
    entity,
    tempYear,
    {
      t,
      prevYear,
      calcDate,
      calcAge,
      isOverdraft,
      prevYearAssets,
      deductValue,
      deductWhatIf
    }
  ) => {
    const annualContribution = valueFuncAnnualContrib(entity, { calcAge });

    const valuesCommon = {
      id: entity.id,
      entityType: entity.entity,
      isWhatIf: entity.isWhatIf,
      type: entity.taxCategory,
      annualContribution,
      //
      isEventDeduction: !!deductValue,
      owner: entity.owner
    };

    const values = {
      ...valuesCommon
    };

    const defaultInterestValue = +entity.interestValue;
    const defaultPrincipalValue = +entity.value;

    let entityKey = "";
    let value = 0;
    let taxAdvantage = 0;
    let taxDeferred = 0;
    let baseValue = 0;

    // TODO WAITING FOR LOGIC
    if (entity.type === TypeAnnuitValues.ROTH) {
      return;
    }

    if (entity.type === TypeAnnuitValues.QUALIFIED) {
      entityKey = "annuityQualified";

      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings[entityKey] &&
        prevYearAssets.savings[entityKey][entity.id]
          ? prevYearAssets.savings[entityKey][entity.id]
          : null;

      valuesCommon.isEventDeduction =
        !!deductValue || (prevValues && prevValues.isEventDeduction);
      //
      const defaultValue = defaultInterestValue + defaultPrincipalValue;

      values.isOverdrafted = prevValues ? prevValues.isOverdrafted : false;
      values.isOverdraftWas = prevValues ? prevValues.isOverdraftWas : false;

      value = prevValues ? prevValues.value : defaultValue;

      ////////////////////////
      // const prevDeductValue = prevValues ? prevValues.prevDeductValue : 0;
      ////////////////////////

      baseValue = prevValues ? prevValues.baseValue : defaultPrincipalValue;

      if (deductValue) {
        value = value > deductValue ? value - deductValue : 0;
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      // if (prevDeductValue) {
      //   value = value > prevDeductValue ? value - prevDeductValue : 0;
      // }
      ////////////////////////////////////////////////////////////////////////////////////////////////

      value = valueFuncRSTByPrevContrib(entity, { value, calcAge, t });

      taxDeferred = value;
    }

    if (entity.type === TypeAnnuitValues.UNQUALIFIED) {
      entityKey = "annuityUnqualified";
      const prevValues =
        prevYearAssets &&
        prevYearAssets.savings[entityKey] &&
        prevYearAssets.savings[entityKey][entity.id]
          ? prevYearAssets.savings[entityKey][entity.id]
          : null;

      valuesCommon.isEventDeduction =
        !!deductValue || prevValues ? prevValues.isEventDeduction : false;
      //
      values.isOverdrafted = prevValues ? prevValues.isOverdrafted : false;
      values.isOverdraftWas = prevValues ? prevValues.isOverdraftWas : false;

      value = prevValues
        ? prevValues.value
        : defaultPrincipalValue + defaultInterestValue;

      ////////////////////////
      // const prevDeductValue = prevValues ? prevValues.prevDeductValue : 0;
      ////////////////////////

      baseValue = prevValues ? prevValues.baseValue : defaultPrincipalValue;

      if (deductValue) {
        value = value > deductValue ? value - deductValue : 0;
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      // if (prevDeductValue) {
      //   value = value > prevDeductValue ? value - prevDeductValue : 0;
      // }
      ////////////////////////////////////////////////////////////////////////////////////////////////

      value = valueFuncRSTByPrevContrib(entity, { value, t, calcAge });

      // taxAdvantage = defaultPrincipalValue;
      // INTEREST AMOUNT
      // taxDeferred = value - defaultPrincipalValue;

      taxDeferred = value;
    }

    values.value = value;
    values.baseValue = baseValue;
    values.taxAdvantage = taxAdvantage > 0 ? taxAdvantage : 0;
    values.taxDeferred = taxDeferred > 0 ? taxDeferred : 0;

    return { values, entityKey };
  };

  calcUses = (
    entity,
    tempYear,
    { t, prevYear, calcDate, calcAge, isOverdraft }
  ) => {
    const isWhatIf = entity.isWhatIf;
    const defaultValue = +entity.value;

    const isHome = entity.entity === useEntities.ENTITY_HOME;
    const isHomePrimary = isHome && entity.type === optionsValuesHome.PRIMARY;

    const prevValues = isOverdraft
      ? prevYear &&
        prevYear.assetsAllWhatIf.use[entity.entity] &&
        prevYear.assetsAllWhatIf.use[entity.entity][entity.id]
        ? prevYear.assetsAllWhatIf.use[entity.entity][entity.id]
        : null
      : prevYear &&
        prevYear.assetsAll.use[entity.entity] &&
        prevYear.assetsAll.use[entity.entity][entity.id]
      ? prevYear.assetsAll.use[entity.entity][entity.id]
      : null;

    let annualChange = t !== 0 ? getAnnualChange(entity, { t }) : 0;

    const values = {
      id: entity.id,
      type: entity.type,
      entityType: entity.entity,
      isWhatIf,
      isHomePrimary,
      value: prevValues
        ? prevValues.value * (1 + annualChange / 100)
        : defaultValue
    };

    if (isHome && entity.debtId) {
      values.debtId = entity.debtId;
    }

    // propertyTax only if home doesn't sold
    if (isHome && values.value !== 0) {
      const propertyTax = _helperCalculateUseHomeEstimatedTax(entity, null, {
        calcDate
      });

      values.propertyTax = propertyTax
        ? propertyTax * Math.pow(1 + annualChange / 100, t)
        : 0;
    }

    if (isHome && entity.purchasePrice) {
      values.purchasePrice = +entity.purchasePrice;

      const { family } = this.props;
      const isMarried = family && family.relationshipStatus === "married";

      if (isHomePrimary && values.value !== 0) {
        values.isHomePrimary = true;
        const valueFunc = (entity, { calcDate = Date.now(), t } = {}) => {
          if (
            entity.purchaseDate &&
            entity.purchaseDate.seconds &&
            entity.purchasePrice
          ) {
            const increaseValue = values.value - entity.purchasePrice;

            const isDateMore =
              Math.abs(
                differenceInDays(entity.purchaseDate.seconds * 1000, calcDate)
              ) >
              365 * 2;

            const isValueMore = isMarried
              ? increaseValue > taxPrimaryHomePrice.married
              : increaseValue > taxPrimaryHomePrice.single;

            if (isDateMore && isValueMore) {
              return increaseValue;
            }

            return 0;
          }
        };

        values.increaseValue = valueFunc(entity, { t });
      }

      if (!isHomePrimary && entity.purchasePrice && values.value !== 0) {
        const valueFunc = (entity, { calcDate = Date.now(), t } = {}) => {
          const increaseValue = values.value - entity.purchasePrice;

          // TODO is this right
          if (increaseValue < 0) {
            return 0;
          }

          // FOR MULTIPLE ASSUME IS TRUE
          if (entity.type === optionsValuesHome.MULTIPLE) {
            return increaseValue;
          }

          if (entity.purchaseDate && entity.purchaseDate.seconds) {
            const isDateMore =
              Math.abs(
                differenceInDays(entity.purchaseDate.seconds * 1000, calcDate)
              ) > 365;

            if (isDateMore) {
              return increaseValue;
            }
          }

          return 0;
        };

        values.increaseValue = valueFunc(entity, { t });
      }
    }

    if (isOverdraft) {
      tempYear.assetsAllWhatIf.use[entity.entity][entity.id] = values;
    } else {
      tempYear.assetsAll.use[entity.entity][entity.id] = values;
    }
  };

  calcDebt = (
    entity,
    tempYear,
    { t, prevYear, calcDate, calcAge, isOverdraft }
  ) => {
    const { assetsAll } = this.props;
    const isWhatIf = entity.isWhatIf;

    const prevValues = isOverdraft
      ? prevYear &&
        prevYear.assetsAllWhatIf.debt[entity.entity] &&
        prevYear.assetsAllWhatIf.debt[entity.entity][entity.id]
        ? prevYear.assetsAllWhatIf.debt[entity.entity][entity.id]
        : null
      : prevYear &&
        prevYear.assetsAll.debt[entity.entity] &&
        prevYear.assetsAll.debt[entity.entity][entity.id]
      ? prevYear.assetsAll.debt[entity.entity][entity.id]
      : null;

    const assetEntity = entity.assetId
      ? assetsAll.find(el => el.id === entity.assetId)
      : null;

    const isHome =
      assetEntity && assetEntity.entity === useEntities.ENTITY_HOME;
    const isHomePrimary =
      isHome && assetEntity.type === optionsValuesHome.PRIMARY;
    const isHomeInvestment =
      isHome && assetEntity.type === optionsValuesHome.INVESTMENT;
    const isHomeVacation =
      isHome && assetEntity.type === optionsValuesHome.VACATION;
    const isHomeOther =
      isHome &&
      (assetEntity.type === optionsValuesHome.OTHER ||
        assetEntity.type === optionsValuesHome.MULTIPLE);

    const valueAnnualPayment = _helperCalculateDebtAnnualPay(entity, null);

    const curriedHelperIsMonthsLeftPeriod = (entity, { calcDate }) => {
      const isExpiredObj = helperIsMonthsLeftPeriod(entity, {
        calcDate,
        expiredCalc: { fieldDate: "loanDate" },
        keyField: "term"
      });

      if (isExpiredObj && isExpiredObj.isExpired) {
        return true;
      }

      return false;
    };

    const expiredFunc =
      entity.loanType === optionsRepaymentValues.MONTHLY_AMOUNT
        ? helperIsDebtMonthlyExp
        : curriedHelperIsMonthsLeftPeriod;

    const isExpired = expiredFunc(entity, { calcDate });

    if (
      (isExpired && entity.entity !== entitiesDebt.ENTITY_GENERAL) ||
      (prevValues && prevValues.value === 0) ||
      (!prevValues && t > 0)
    ) {
      return;
    }

    let remainingDebt = 0;

    if (entity.loanType === optionsRepaymentValues.AMORTIZATION) {
      remainingDebt = +_helperCalcDebtRemainingAmortization(entity, {
        calcDate
      });
    }
    if (entity.loanType === optionsRepaymentValues.MONTHLY_AMOUNT) {
      remainingDebt = +_helperCalcDebtRemainingMonthly(entity, { calcDate });
    }
    if (entity.loanType === optionsRepaymentValues.INTEREST_ONLY) {
      remainingDebt = +_helperCalcDebtRemainingInterest(entity, { calcDate });
    }

    // TODO
    if (entity.entity === entitiesDebt.ENTITY_GENERAL) {
      // TODO how calc remaining for general
      // const annualChange = +entity.interestRate
      //   ? 1 + +entity.interestRate / 100
      //   : 1;
      // remainingDebt = +entity.loanAmount * Math.pow(annualChange, t);
      remainingDebt = +entity.loanAmount;
    }

    const annualPay = _helperCalculateDebtAnnualPay(entity, null, { calcDate });

    const values = {
      id: entity.id,
      entityType: entity.entity,
      assetEntity: assetEntity ? assetEntity.id : null,
      isWhatIf,
      isHome,
      value: remainingDebt,
      annualPay
    };

    if (isHome) {
      values.assetType = assetEntity
        ? assetEntity.type
        : optionsValuesHome.OTHER;
    }

    if (isOverdraft) {
      tempYear.assetsAllWhatIf.debt[entity.entity][entity.id] = values;
    }

    tempYear.assetsAll.debt[entity.entity][entity.id] = values;
  };

  calcEventInAnnuity = (
    entity,
    tempYear,
    {
      t,
      prevYear,
      calcDate,
      calcAge,
      isOverdraft,
      prevYearAssets,
      values,
      relatedEntity
    }
  ) => {
    let annuityTypeData = "";
    if (relatedEntity && relatedEntity.type === TypeAnnuitValues.QUALIFIED) {
      annuityTypeData = "annuityQualified";
    }

    if (relatedEntity && relatedEntity.type === TypeAnnuitValues.UNQUALIFIED) {
      annuityTypeData = "annuityUnqualified";
    }

    ////////////////
    if (isOverdraft && annuityTypeData) {
      const annuityData =
        tempYear.assetsAllWhatIf.savings[annuityTypeData][relatedEntity.id];

      if (
        annuityData &&
        !annuityData.isOverdrafted
        // !annuityData.isOverdraftWas
      ) {
        const { values: valuesNewAnnuity } = this.calcAnnuity(
          relatedEntity,
          tempYear,
          {
            t,
            prevYear,
            calcDate,
            calcAge,
            isOverdraft,
            prevYearAssets,
            deductValue: values.value
            // prevDeductValue: values.value,
            // deductWhatIf: relatedEntity.isWhatIf
          }
        );

        // new logic
        // if (isOverdraft) {
        //   tempYear.assetsAllWhatIf.savings[annuityTypeData][
        //     relatedEntity.id
        //   ] = {
        //     ...tempYear.assetsAllWhatIf.savings[annuityTypeData][
        //       relatedEntity.id
        //     ],
        //     prevDeductValue: values.value,
        //     deductWhatIf: relatedEntity.isWhatIf
        //   };
        // } else {
        //   tempYear.assetsAll.savings[annuityTypeData][relatedEntity.id] = {
        //     ...tempYear.assetsAll.savings[annuityTypeData][relatedEntity.id],
        //     prevDeductValue: values.value,
        //     deductWhatIf: relatedEntity.isWhatIf
        //   };
        // }

        if (isOverdraft) {
          tempYear.assetsAllWhatIf.savings[annuityTypeData][
            relatedEntity.id
          ] = valuesNewAnnuity;
        } else {
          tempYear.assetsAll.savings[annuityTypeData][
            relatedEntity.id
          ] = valuesNewAnnuity;
        }
      } else {
        values.value = 0;
      }
    }

    const annuityData =
      tempYear.assetsAll.savings[annuityTypeData][relatedEntity.id];

    if (
      annuityData &&
      !annuityData.isOverdrafted
      // !annuityData.isOverdraftWas
    ) {
      const { values: valuesNewAnnuity } = this.calcAnnuity(
        relatedEntity,
        tempYear,
        {
          t,
          prevYear,
          calcDate,
          calcAge,
          isOverdraft,
          prevYearAssets,
          deductValue: values.value
          // prevDeductValue: values.value,
          // deductWhatIf: relatedEntity.isWhatIf
        }
      );

      // new logic
      // tempYear.assetsAll.savings[annuityTypeData][relatedEntity.id] = {
      //   ...tempYear.assetsAll.savings[annuityTypeData][relatedEntity.id],
      //   prevDeductValue: values.value,
      //   deductWhatIf: relatedEntity.isWhatIf
      // };

      tempYear.assetsAll.savings[annuityTypeData][
        relatedEntity.id
      ] = valuesNewAnnuity;
    } else {
      values.value = 0;
    }
  };

  calcEventInIRA = (
    entity,
    tempYear,
    {
      t,
      prevYear,
      calcDate,
      calcAge,
      isOverdraft,
      prevYearAssets,
      values,
      relatedEntity
    }
  ) => {
    const calcNewIRA = isWhatIf => {
      const relatedData =
        tempYear[isWhatIf ? "assetsAllWhatIf" : "assetsAll"].savings.IRA[
          relatedEntity.id
        ];

      if (relatedData && relatedData.value > 0) {
        const { values: valuesNew, deductActual } = this.calcIRA(
          relatedEntity,
          tempYear,
          {
            t,
            prevYear,
            calcDate,
            calcAge,
            isOverdraft,
            prevYearAssets,
            deductValue: values.value
          }
        );

        values.deductActual = deductActual;

        tempYear[isWhatIf ? "assetsAllWhatIf" : "assetsAll"].savings.IRA[
          relatedEntity.id
        ] = valuesNew;
      } else {
        values.value = 0;
      }
    };

    if (isOverdraft) {
      calcNewIRA(true);
    }

    calcNewIRA();
  };

  calcEventIn401K = (
    entity,
    tempYear,
    {
      t,
      prevYear,
      calcDate,
      calcAge,
      isOverdraft,
      prevYearAssets,
      values,
      relatedEntity
    }
  ) => {
    const calcNew401K = isWhatIf => {
      const relatedData =
        tempYear[isWhatIf ? "assetsAllWhatIf" : "assetsAll"].savings["401k"][
          relatedEntity.id
        ];

      if (relatedData && relatedData.value > 0) {
        const { values: valuesNew, deductActual } = this.calc401K(
          relatedEntity,
          tempYear,
          {
            t,
            prevYear,
            calcDate,
            calcAge,
            isOverdraft,
            prevYearAssets,
            deductValue: values.value
          }
        );

        values.deductActual = deductActual;

        tempYear[isWhatIf ? "assetsAllWhatIf" : "assetsAll"].savings["401k"][
          relatedEntity.id
        ] = valuesNew;
      } else {
        values.value = 0;
      }
    };

    if (isOverdraft) {
      calcNew401K(true);
    }

    calcNew401K();
  };

  calcEventIn = (
    entity,
    tempYear,
    { t, prevYear, calcDate, calcAge, isOverdraft }
  ) => {
    const { assetsAll } = this.props;
    const prevYearAssets = prevYear
      ? isOverdraft
        ? prevYear.assetsAllWhatIf
        : prevYear.assetsAll
      : null;

    const relatedEntity =
      assetsAll.find(el => el.id === entity.assetId) || null;

    let relatedAssetCurrentYear;
    if (relatedEntity) {
      relatedAssetCurrentYear = getObjects(
        prevYearAssets,
        "id",
        entity.assetId
      );

      relatedAssetCurrentYear = relatedAssetCurrentYear[0];
    }

    const values = {
      entityType: entity.entity,
      isWhatIf: entity.isWhatIf,
      description: entity.description
    };

    const annualChange = entity.annualChange ? +entity.annualChange / 100 : 0;

    const isInThisYear = calcAge === +entity.startAge;
    const pastsTo =
      entity.occurs === "annual" &&
      +entity.years + +entity.startAge > calcAge &&
      calcAge >= +entity.startAge;

    if (isInThisYear || pastsTo) {
      const tEvent = calcAge - entity.startAge;
      if (entity.amountType === optionsAmountTypeValues.FIXED) {
        values.value = annualChange
          ? entity.amount * Math.pow(1 + annualChange, tEvent)
          : +entity.amount;
      }

      if (entity.amountType === optionsAmountTypeValues.VARIABLE) {
        values.variable = annualChange
          ? entity.variable * Math.pow(1 + annualChange, tEvent)
          : +entity.variable;

        values.value =
          relatedAssetCurrentYear && relatedAssetCurrentYear.value
            ? relatedAssetCurrentYear.value * (values.variable / 100)
            : 0;
      }

      ///////////////////////////////// ANNUITY, IRA, etc START
      if (
        entity.type === valuesMoneyInType.ANNUITY &&
        entity.assetId &&
        relatedEntity
      ) {
        this.calcEventInAnnuity(entity, tempYear, {
          t,
          prevYear,
          calcDate,
          calcAge,
          isOverdraft,
          prevYearAssets,
          values,
          relatedEntity
        });
      }

      if (
        entity.assetId &&
        relatedEntity &&
        relatedEntity.entity === entitiesSavings.ENTITY_IRA
      ) {
        this.calcEventInIRA(entity, tempYear, {
          t,
          prevYear,
          calcDate,
          calcAge,
          isOverdraft,
          prevYearAssets,
          values,
          relatedEntity
        });

        values.value = values.deductActual;
      }

      if (
        entity.assetId &&
        relatedEntity &&
        relatedEntity.entity === entitiesSavings.ENTITY_401K
      ) {
        this.calcEventIn401K(entity, tempYear, {
          t,
          prevYear,
          calcDate,
          calcAge,
          isOverdraft,
          prevYearAssets,
          values,
          relatedEntity
        });

        values.value = values.deductActual;
      }
      ///////////////////////////////// ANNUITY, IRA, etc END

      if (
        entity.tax === optionsTaxCategoryValues.ORDINARY_INCOME ||
        entity.tax === optionsTaxCategoryValues.UNKNOWN ||
        !entity.tax
      ) {
        values.taxIncome = values.value;
      }

      if (entity.tax === optionsTaxCategoryValues.CAPITAL_GAIN) {
        values.taxCapital = values.value;
      }

      if (isOverdraft) {
        tempYear.assetsAllWhatIf.events[entity.entity][entity.id] = values;
      }

      tempYear.assetsAll.events[entity.entity][entity.id] = values;
    }
  };

  calcEventOut = (
    entity,
    tempYear,
    { t, prevYear, calcDate, calcAge, isOverdraft }
  ) => {
    const isWhatIf = entity.isWhatIf;
    const annualChange = entity.annualChange ? +entity.annualChange / 100 : 0;

    const value = annualChange
      ? entity.amount * Math.pow(1 + annualChange, t)
      : +entity.amount;

    const startAge = +entity.startAge;
    const years = +entity.years;

    const isInThisYear = calcAge === startAge;
    const pastsTo =
      entity.occurs === "annual" &&
      years + startAge > calcAge &&
      calcAge >= startAge;

    if (isInThisYear || pastsTo) {
      const values = {
        entityType: entity.entity,
        isWhatIf,
        value: value
      };

      if (isOverdraft) {
        tempYear.assetsAllWhatIf.events[entity.entity][entity.id] = values;
      }

      tempYear.assetsAll.events[entity.entity][entity.id] = values;
    }
  };

  calcAddSalary = (tempYear, { isOverdraft } = {}) => {
    const salaries = tempYear.assetsAll.moneyIn.salary;

    const salariesWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.moneyIn.salary
      : null;

    const fillAssets = (useKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      if (!whatIf) {
        if (data.employeeName === "You") {
          tempYear.TempValues.MoneyIn.salary.you += data.valueEarning;
        } else {
          tempYear.TempValues.MoneyIn.salary.spouse += data.valueEarning;
        }
      }

      tempYear[`AssetsDetail${whatIf}`].retirement["401K"] +=
        data.valueRST || 0;
      tempYear[`AssetsDetail${whatIf}`].taxDeferred["401K"] +=
        data.valueRST || 0;
      tempYear[`AssetsTax${whatIf}`].deferred += data.valueRST || 0;

      tempYear[`MoneyOut${whatIf}`].personal += data.spendingAccount || 0;
      tempYear[`chartData${whatIf}`].PreTaxDeductions +=
        data.spendingAccount + data.value401k || 0;

      tempYear[`MoneyOutDetail${whatIf}`].taxes.FICA +=
        data.valueEarningWoSpending401K * data.taxMultiplier || 0;

      tempYear[`MoneyIn${whatIf}`].salary +=
        data.valueEarningWoSpending401K || 0;
    };

    if (salaries) {
      Object.values(salaries).forEach(data => {
        // employeeName
        // spendingAccount
        // taxMultiplier
        // value401k
        // value401kSelf
        // valueEarning
        // valueEarningWoSpending
        // valueEarningWoSpending401K
        // valueRST
        // valueSpendingPlus401K

        if (!data.isWhatIf) {
          fillAssets(null, data);
        }

        if (!isOverdraft) {
          fillAssets(null, data, { isWhatIf: true });
        }
      });
    }

    if (salariesWhatIf) {
      Object.values(salariesWhatIf).forEach(data => {
        fillAssets(null, data, { isWhatIf: true });
      });
    }
  };

  calcAddRent = (tempYear, { isOverdraft } = {}) => {
    const rents = tempYear.assetsAll.moneyIn.rent;
    const homes = tempYear.assetsAll.use.home;
    const rentsWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.moneyIn.rent
      : null;
    const homesWhatIf = tempYear.assetsAllWhatIf.use.home;

    const fillAssets = (useKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      tempYear[`MoneyIn${whatIf}`].realEstate += data.value || 0;
    };

    if (rents) {
      Object.values(rents).forEach(data => {
        let isHomeNotSold = true;
        let isHomeNotSoldWhatIf = true;

        if (data.assetEntity && homes) {
          const homeAsset = homes[data.assetEntity];
          const homeAssetWhatIf = homesWhatIf && homesWhatIf[data.assetEntity];

          if (homeAsset && homeAsset.value === 0) {
            isHomeNotSold = false;
          }
          if (homeAssetWhatIf && homeAssetWhatIf.value === 0) {
            isHomeNotSoldWhatIf = false;
          }
        }

        if (!data.isWhatIf && isHomeNotSold) {
          fillAssets(null, data);
        }

        if (!isOverdraft && (isHomeNotSold && isHomeNotSoldWhatIf)) {
          fillAssets(null, data, { isWhatIf: true });
        }
      });
    }

    if (rentsWhatIf) {
      Object.values(rentsWhatIf).forEach(data => {
        let isHomeNotSoldWhatIf = true;

        if (data.assetEntity && homesWhatIf) {
          const homeAssetWhatIf = homesWhatIf && homesWhatIf[data.assetEntity];

          if (homeAssetWhatIf && homeAssetWhatIf.value === 0) {
            isHomeNotSoldWhatIf = false;
          }
        }

        if (isHomeNotSoldWhatIf) {
          fillAssets(null, data, { isWhatIf: true });
        }
      });
    }
  };

  calcAddBusinessInterest = (tempYear, { isOverdraft } = {}) => {
    const businessInterests = tempYear.assetsAll.investments.businessInterest;

    const businessInterestsWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.investments.businessInterest
      : null;

    const fillAssets = (useKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      tempYear[`MoneyIn${whatIf}`].other += data.earn || 0;

      tempYear[`MoneyOutDetail${whatIf}`].taxes.income += data.taxIncome || 0;
      tempYear[`MoneyOutDetail${whatIf}`].taxes.capital += data.taxCapital || 0;
    };

    if (businessInterests) {
      Object.values(businessInterests).forEach(data => {
        //       amount
        //       earn
        //       interestEarned
        //       type

        if (!data.isWhatIf) {
          fillAssets(null, data);
        }

        if (!isOverdraft) {
          fillAssets(null, data, { isWhatIf: true });
        }
      });
    }

    if (businessInterestsWhatIf) {
      Object.values(businessInterestsWhatIf).forEach(data => {
        fillAssets(null, data, { isWhatIf: true });
      });
    }
  };

  calcAddMoneyInOther = (tempYear, { isOverdraft } = {}) => {
    const others = tempYear.assetsAll.moneyIn.other;
    const othersWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.moneyIn.other
      : null;

    const fillAssets = (useKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      tempYear[`MoneyIn${whatIf}`].other += data.value || 0;

      tempYear[`MoneyOutDetail${whatIf}`].taxes.income += data.taxIncome || 0;
      tempYear[`MoneyOutDetail${whatIf}`].taxes.capital += data.taxCapital || 0;
    };

    if (others) {
      Object.values(others).forEach(data => {
        //       value
        //       taxIncome
        //       taxCapital

        if (!data.isWhatIf) {
          fillAssets(null, data);
        }

        if (!isOverdraft) {
          fillAssets(null, data, { isWhatIf: true });
        }
      });
    }

    if (othersWhatIf) {
      Object.values(othersWhatIf).forEach(data => {
        fillAssets(null, data, { isWhatIf: true });
      });
    }
  };

  calcAddAccounts = (tempYear, { calcAge, isOverdraft, isWhatIf }) => {
    const accounts = tempYear.assetsAll.accounts;

    const accountsWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.accounts
      : null;

    const fillAssets = (accountKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";
      tempYear[`Assets${whatIf}`].cash += data.balance || 0;
      tempYear[`AssetsDetail${whatIf}`].cash.accounts += data.balance || 0;

      tempYear[`MoneyIn${whatIf}`].bank += data.interestValue || 0;

      if (!isWhatIf) {
        tempYear.TempValues.accounts[accountKey] += +data.balance || 0;
      }
    };

    if (accounts) {
      Object.keys(accounts).forEach(accountKey => {
        Object.values(accounts[accountKey]).forEach(data => {
          if (!data.isWhatIf) {
            fillAssets(accountKey, data);
          }

          if (!isOverdraft) {
            fillAssets(accountKey, data, { isWhatIf: true });
          }
        });
      });
    }

    if (accountsWhatIf) {
      Object.keys(accountsWhatIf).forEach(accountKey => {
        Object.values(accountsWhatIf[accountKey]).forEach(data => {
          fillAssets(accountKey, data, { isWhatIf: true });
        });
      });
    }
  };

  calcAddInvestments = (tempYear, { isOverdraft } = {}) => {
    const investments = tempYear.assetsAll.investments;

    const investmentsWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.investments
      : null;

    const fillAssets = (investmentKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      if (
        investmentKey === entitiesInvested.ENTITY_MUTUAL_FUNDS ||
        investmentKey === entitiesInvested.ENTITY_STOCKS ||
        investmentKey === entitiesInvested.ENTITY_TREASURIES ||
        investmentKey === entitiesInvested.ENTITY_GENERAL
      ) {
        tempYear[`Assets${whatIf}`].investments += data.value || 0;
        tempYear[`AssetsDetail${whatIf}`].investments[investmentKey] +=
          data.value || 0;
      }

      if (investmentKey === entitiesInvested.ENTITY_RSU) {
        tempYear[`Assets${whatIf}`].investments += data.value || 0;
        tempYear[`AssetsDetail${whatIf}`].investments.unvestedRSU +=
          data.value || 0;
      }

      if (investmentKey === entitiesInvested.ENTITY_ESPP) {
        tempYear[`TempValues${whatIf}`].investments.RST_ESPP += data.value || 0;
      }

      if (
        investmentKey === entitiesInvested.ENTITY_BUSINESS_INTEREST &&
        whatIf
      ) {
        tempYear.TempValues.investments.businessInterest = data.amount || 0;
      }
    };

    if (investments) {
      Object.keys(investments).forEach(investmentKey => {
        Object.values(investments[investmentKey]).forEach(data => {
          if (!data.isWhatIf) {
            fillAssets(investmentKey, data);
          }

          if (!isOverdraft) {
            fillAssets(investmentKey, data, { isWhatIf: true });
          }
        });
      });
    }

    if (investmentsWhatIf) {
      Object.keys(investmentsWhatIf).forEach(investmentKey => {
        Object.values(investmentsWhatIf[investmentKey]).forEach(data => {
          fillAssets(investmentKey, data, { isWhatIf: true });
        });
      });
    }
  };

  calcAddSavings = (tempYear, { isOverdraft } = {}) => {
    const savings = tempYear.assetsAll.savings;
    const savingsWhatIf = isOverdraft ? tempYear.assetsAllWhatIf.savings : null;

    const fillAssets = (savingKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      const subCategoryObj = {
        IRA: "IRA",
        "401k": "401K",
        annuityQualified: "annuity",
        annuityUnqualified: "annuity",
        "529college": "529college",
        lifeInsurance: "lifeInsurance",
        deferredComp: "deferredComp",
        otherNoTax: "other",
        otherCapital: "other",
        otherOrdinary: "other",
        otherUnknown: "other"
      };

      if (data.annualContribution) {
        if (subCategoryObj[savingKey]) {
          tempYear[`MoneyOut${whatIf}`].savings += data.annualContribution || 0;
          tempYear[`MoneyOutDetail${whatIf}`].savings[
            subCategoryObj[savingKey]
          ] += data.annualContribution || 0;
        }
      }

      if (savingKey === "otherCapital") {
        tempYear[`Liabilities${whatIf}`].capitalTax += data.value || 0;
        tempYear[`TempValues${whatIf}`].savings.retirementOtherCapital +=
          data.value || 0;
      }

      if (
        savingKey === "otherNoTax" ||
        savingKey === "otherCapital" ||
        savingKey === "otherOrdinary" ||
        savingKey === "otherUnknown"
      ) {
        tempYear[`AssetsDetail${whatIf}`].retirement.other += data.value || 0;
      }

      if (savingKey === "529college") {
        tempYear[`AssetsDetail${whatIf}`].retirement["529college"] +=
          data.value || 0;
      }

      if (savingKey === entitiesSavings.ENTITY_IRA) {
        tempYear[`AssetsDetail${whatIf}`].retirement.IRA += data.value || 0;
      }

      if (savingKey === "401k") {
        tempYear[`AssetsDetail${whatIf}`].retirement["401K"] += data.value || 0;
      }

      if (savingKey === "deferredComp") {
        tempYear[`AssetsDetail${whatIf}`].retirement.deferredComp +=
          data.value || 0;
      }

      if (subCategoryObj[savingKey] === "annuity") {
        tempYear[`AssetsDetail${whatIf}`].retirement.annuity += data.value || 0;
      }

      ///// TAX
      const taxDeferred = data.taxDeferred || 0;

      /////// Deferred
      if (taxDeferred) {
        tempYear[`AssetsTax${whatIf}`].deferred += taxDeferred || 0;

        tempYear[`TaxCategories${whatIf}`].taxDeferred += taxDeferred || 0;
      }

      /////// Advantage
      const taxAdvantage = data.taxAdvantage;

      if (taxAdvantage) {
        tempYear[`AssetsTax${whatIf}`].advantage += taxAdvantage || 0;
        tempYear[`TaxCategories${whatIf}`].taxAdvantage += taxAdvantage || 0;
      }

      if (subCategoryObj[savingKey] && taxDeferred) {
        tempYear[`AssetsDetail${whatIf}`].taxDeferred[
          subCategoryObj[savingKey]
        ] += taxDeferred || 0;
      }

      if (subCategoryObj[savingKey] && taxAdvantage) {
        tempYear[`AssetsDetail${whatIf}`].taxAdvantage[
          subCategoryObj[savingKey]
        ] += taxAdvantage || 0;
      }

      if (savingKey === "lifeInsurance") {
        // cashValue deathBenefit isWhatIf subCashBenefit

        tempYear[`AssetsDetail${whatIf}`].retirement.lifeInsurance +=
          data.cashValue || 0;

        tempYear[`AssetsTax${whatIf}`].advantage += data.taxAdvantage || 0;
        tempYear[`TaxCategories${whatIf}`].taxAdvantage +=
          data.taxAdvantage || 0;
      }
    };

    if (savings) {
      Object.keys(savings).forEach(savingKey => {
        Object.values(savings[savingKey]).forEach(data => {
          if (!data.isWhatIf) {
            fillAssets(savingKey, data);
          }

          if (!isOverdraft) {
            fillAssets(savingKey, data, { isWhatIf: true });
          }
        });
      });
    }

    if (savingsWhatIf) {
      Object.keys(savingsWhatIf).forEach(savingKey => {
        Object.values(savingsWhatIf[savingKey]).forEach(data => {
          fillAssets(savingKey, data, { isWhatIf: true });
        });
      });
    }
  };

  calcAddUse = (tempYear, { isOverdraft } = {}) => {
    const uses = tempYear.assetsAll.use;
    const usesWhatIf = isOverdraft ? tempYear.assetsAllWhatIf.use : null;
    //increaseValue isWhatIf purchasePrice propertyTax isHomePrimary value

    const fillAssets = (useKey, data, { isWhatIf = false } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      ///use
      tempYear[`TempValues${whatIf}`].use.homePurchase +=
        // data.purchasePrice || 0; ///use
        data.value || 0; ///use
      tempYear[`TempValues${whatIf}`].use.value += data.value || 0;

      tempYear[`Assets${whatIf}`].use += data.value || 0;

      if (typeof tempYear[`AssetsDetail${whatIf}`].use[useKey] === "number") {
        tempYear[`AssetsDetail${whatIf}`].use[useKey] += data.value || 0;
      }

      tempYear[`MoneyOutDetail${whatIf}`].taxes.property +=
        data.propertyTax || 0;

      tempYear[`Liabilities${whatIf}`].capitalTax += data.increaseValue || 0;
      tempYear[`TempValues${whatIf}`].Liabilities.homes +=
        data.increaseValue || 0;
    };

    if (uses) {
      Object.keys(uses).forEach(useKey => {
        Object.values(uses[useKey]).forEach(data => {
          if (!data.isWhatIf) {
            fillAssets(useKey, data);
          }

          if (!isOverdraft) {
            fillAssets(useKey, data, { isWhatIf: true });
          }
        });
      });
    }

    if (usesWhatIf) {
      Object.keys(usesWhatIf).forEach(useKey => {
        Object.values(usesWhatIf[useKey]).forEach(data => {
          fillAssets(useKey, data, { isWhatIf: true });
        });
      });
    }
  };

  calcAddDebt = (tempYear, { isOverdraft } = {}) => {
    const debt = tempYear.assetsAll.debt;
    const debtWhatIf = isOverdraft ? tempYear.assetsAllWhatIf.debt : null;

    const fillAssets = (useKey, data, { isWhatIf = false, debtKey } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      if (!whatIf) {
        tempYear.TempValues.debt[debtKey] += data.value || 0;
      }

      tempYear[`MoneyOut${whatIf}`].debtRepayment += data.annualPay || 0;
      if (data.isHome) {
        tempYear[`Liabilities${whatIf}`].mortgage += data.value || 0;
      } else {
        tempYear[`Liabilities${whatIf}`].loans += data.value || 0;
      }
    };

    if (debt) {
      Object.keys(debt).forEach(debtKey => {
        Object.values(debt[debtKey]).forEach(data => {
          //isWhatIf, isHome, value: remainingDebt, annualPay

          if (!data.isWhatIf) {
            fillAssets(debtKey, data, { debtKey });
          }

          if (!isOverdraft) {
            fillAssets(debtKey, data, { isWhatIf: true, debtKey });
          }
        });
      });
    }
    if (debtWhatIf) {
      Object.keys(debtWhatIf).forEach(debtKey => {
        Object.values(debtWhatIf[debtKey]).forEach(data => {
          fillAssets(debtKey, data, { isWhatIf: true, debtKey });
        });
      });
    }
  };

  calcAddMoneyOut = (tempYear, { isOverdraft } = {}) => {
    //   family: {}, // personal
    //   asset: {},
    //   rentLease: {}
    const MoneyOut = tempYear.assetsAll.moneyOut;
    const MoneyOutWhatIf = isOverdraft
      ? tempYear.assetsAllWhatIf.moneyOut
      : null;

    const fillAssets = (useKey, data, { isWhatIf = false, entityKey } = {}) => {
      const whatIf = isWhatIf ? "WhatIf" : "";

      if (entityKey === "family") {
        tempYear[`MoneyOut${whatIf}`].personal += data.value || 0;
      }
      if (entityKey === "asset") {
        tempYear[`MoneyOut${whatIf}`].asset += data.value || 0;
      }
      if (entityKey === "rentLease") {
        tempYear[`MoneyOut${whatIf}`].other += data.value || 0;
      }
    };

    if (MoneyOut) {
      Object.keys(MoneyOut).forEach(moneyOutKey => {
        Object.values(MoneyOut[moneyOutKey]).forEach(data => {
          if (!data.isWhatIf) {
            fillAssets(moneyOutKey, data, { entityKey: moneyOutKey });
          }

          if (!isOverdraft) {
            fillAssets(moneyOutKey, data, {
              isWhatIf: true,
              entityKey: moneyOutKey
            });
          }
        });
      });
    }

    if (MoneyOutWhatIf) {
      Object.keys(MoneyOutWhatIf).forEach(moneyOutKey => {
        Object.values(MoneyOutWhatIf[moneyOutKey]).forEach(data => {
          fillAssets(moneyOutKey, data, {
            isWhatIf: true,
            entityKey: moneyOutKey
          });
        });
      });
    }
  };

  calcAddEvents = (tempYear, { isOverdraft } = {}) => {
    const events = isOverdraft
      ? tempYear.assetsAll.events
      : tempYear.assetsAll.events;
    //      : tempYear.overdraft.assetsAll.events;

    if (events) {
      Object.keys(events).forEach(eventKey => {
        Object.values(events[eventKey]).forEach(data => {
          if (eventKey === "moneyIn") {
            if (!data.isWhatIf) {
              tempYear.MoneyIn.events += data.value || 0;

              tempYear.MoneyOutDetail.taxes.income += data.taxIncome || 0;
              tempYear.MoneyOutDetail.taxes.capital += data.taxCapital || 0;
            }

            tempYear.MoneyInWhatIf.events += data.value || 0;

            tempYear.MoneyOutDetailWhatIf.taxes.income += data.taxIncome || 0;
            tempYear.MoneyOutDetailWhatIf.taxes.capital += data.taxCapital || 0;
          }

          if (eventKey === "moneyOut") {
            if (!data.isWhatIf) {
              tempYear.MoneyOut.events += data.value || 0;
            }

            tempYear.MoneyOutWhatIf.events += data.value || 0;
          }
        });
      });
    }
  };

  calcAddAllYearData = (tempYear, { calcAge, isCalcOverdraft, isWhatIf }) => {
    const isOverdraft = isCalcOverdraft;

    this.calcAddSalary(tempYear, { isOverdraft, isWhatIf });
    this.calcAddRent(tempYear, { isOverdraft, isWhatIf });
    this.calcAddBusinessInterest(tempYear, { isOverdraft, isWhatIf });

    this.calcAddMoneyInOther(tempYear, { isOverdraft, isWhatIf });
    this.calcAddAccounts(tempYear, { isOverdraft, calcAge });
    this.calcAddInvestments(tempYear, { isOverdraft, isWhatIf });
    this.calcAddSavings(tempYear, { isOverdraft, isWhatIf });
    this.calcAddUse(tempYear, { isOverdraft, isWhatIf });
    this.calcAddDebt(tempYear, { isOverdraft, isWhatIf });

    this.calcAddMoneyOut(tempYear, { isOverdraft, isWhatIf });
    this.calcAddEvents(tempYear, { isOverdraft, isWhatIf });
  };

  calcAllTilesByYear = (
    tempYear,
    prevYear,
    {
      t,
      currentAge,
      currentDate,
      calcDate,
      isOverdraft,
      lifeEvents,
      lifeEventsWhatIf
    }
  ) => {
    const {
      assetsAll,
      transactionsAll,
      auth,
      rights,
      profileProvider
    } = this.props;

    const calcAge = currentAge + t;

    const getIsAccessViewByType = globalType => {
      return getIsAccessEditView(globalType, entitiesScheme, rights, {
        auth,
        profileProvider,
        isEdit: false
      });
    };

    assetsAll &&
      assetsAll.forEach(entity => {
        if (
          entity &&
          (profileProvider === entity.user || auth.uid === entity.user) &&
          !entity.isDisabled &&
          (!entity.isExpired || entity.globalType === globalTypeAccounts)
        ) {
          const isWhatIf = entity.isWhatIf;

          if (
            entity.globalType === globalTypeMoneyIn &&
            getIsAccessViewByType(globalTypeMoneyIn)
          ) {
            if (entity.entity === entitiesMoneyIn.ENTITY_SALARIES) {
              this.calcSalaries(entity, tempYear, {
                calcAge,
                t,
                lifeEvents,
                lifeEventsWhatIf
              });

              if (isOverdraft) {
                this.calcSalaries(entity, tempYear, {
                  calcAge,
                  t,
                  isOverdraft,
                  lifeEvents,
                  lifeEventsWhatIf
                });
              }
            }

            if (entity.entity === entitiesMoneyIn.ENTITY_RENT) {
              this.calcIncomeRent(entity, tempYear, {
                calcAge,
                t,
                lifeEvents,
                lifeEventsWhatIf
              });

              if (isOverdraft) {
                this.calcIncomeRent(entity, tempYear, {
                  calcAge,
                  t,
                  isOverdraft,
                  lifeEvents,
                  lifeEventsWhatIf
                });
              }
            }

            if (
              entity.globalType === globalTypeMoneyIn &&
              entity.entity === entitiesMoneyIn.ENTITY_OTHER
            ) {
              // no prev values
              this.calcMoneyInOther(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                isOverdraft
              });
            }
          }

          if (
            getIsAccessViewByType(globalTypeAccounts) &&
            entity.globalType === globalTypeAccounts
          ) {
            this.calcAccounts(entity, tempYear, {
              t,
              prevYear,
              calcDate,
              calcAge
            });

            if (isOverdraft) {
              this.calcAccounts(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                calcAge,
                isOverdraft
              });
            }
          }

          if (
            getIsAccessViewByType(globalTypeInvested) &&
            entity.globalType === globalTypeInvested
          ) {
            if (entity.entity === entitiesInvested.ENTITY_BUSINESS_INTEREST) {
              this.calcBusinessInterest(entity, tempYear, {
                t,
                prevYear
              });

              if (isOverdraft) {
                this.calcBusinessInterest(entity, tempYear, {
                  t,
                  prevYear,
                  isOverdraft
                });
              }
            } else {
              this.calcInvestments(entity, tempYear, {
                t,
                prevYear,
                calcDate
              });

              if (isOverdraft) {
                this.calcInvestments(entity, tempYear, {
                  t,
                  prevYear,
                  calcDate,
                  isOverdraft
                });
              }
            }
          }

          if (
            getIsAccessViewByType(globalTypeSavings) &&
            entity.globalType === globalTypeSavings
          ) {
            this.calcSavings(entity, tempYear, {
              t,
              prevYear,
              calcDate,
              calcAge,
              currentAge,
              lifeEvents,
              lifeEventsWhatIf
            });

            if (isOverdraft) {
              this.calcSavings(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                currentAge,
                calcAge,
                isOverdraft,
                lifeEvents,
                lifeEventsWhatIf
              });
            }
          }

          if (
            getIsAccessViewByType(globalTypeUse) &&
            entity.globalType === globalTypeUse
          ) {
            this.calcUses(entity, tempYear, {
              t,
              prevYear,
              calcDate,
              calcAge
            });

            if (isOverdraft) {
              this.calcUses(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                calcAge,
                isOverdraft
              });
            }
          }

          if (
            getIsAccessViewByType(globalTypeDebt) &&
            entity.globalType === globalTypeDebt
          ) {
            this.calcDebt(entity, tempYear, {
              t,
              prevYear,
              calcDate,
              calcAge,
              isOverdraft
            });
          }
        }
      });

    transactionsAll &&
      transactionsAll.forEach(entity => {
        if (
          entity &&
          !entity.isDisabled &&
          !entity.isExpired &&
          (profileProvider === entity.user || auth.uid === entity.user)
        ) {
          const isWhatIf = entity.isWhatIf;

          if (
            getIsAccessViewByType(globalTypeEvent) &&
            entity.globalType === globalTypeEvent
          ) {
            if (entity.entity === entitiesEvents.ENTITY_MONEY_IN) {
              this.calcEventIn(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                calcAge,
                isOverdraft
              });
            }

            if (entity.entity === entitiesEvents.ENTITY_MONEY_OUT) {
              this.calcEventOut(entity, tempYear, {
                t,
                prevYear,
                calcDate,
                calcAge,
                isOverdraft
              });
            }
          }

          let annualChange = getAnnualChange(entity, { t });

          if (
            getIsAccessViewByType(globalTypeMoneyOutAssets) &&
            entity.globalType === globalTypeMoneyOutAssets
          ) {
            const value = +entity.annualCost;

            const isNotExpired = isMoneyOutNotExpiredYears(entity, {
              calcDate
            });

            if (isNotExpired) {
              const valueCalced = value * Math.pow(1 + annualChange / 100, t);

              tempYear.assetsAll.moneyOut.asset[entity.id] = {
                isWhatIf,
                value: valueCalced
              };

              if (isOverdraft) {
                tempYear.assetsAllWhatIf.moneyOut.asset[entity.id] = {
                  isWhatIf,
                  value: valueCalced
                };
              }
            }
          }

          if (
            getIsAccessViewByType(globalTypeMoneyOutPersonal) &&
            entity.globalType === globalTypeMoneyOutPersonal
          ) {
            const isNotExpired = isMoneyOutNotExpiredYears(entity, {
              calcDate
            });

            if (isNotExpired) {
              const value = annualChange
                ? +entity.annualCost * Math.pow(1 + annualChange / 100, t)
                : +entity.annualCost;

              tempYear.assetsAll.moneyOut.family[entity.id] = {
                isWhatIf,
                value
              };

              if (isOverdraft) {
                tempYear.assetsAllWhatIf.moneyOut.family[entity.id] = {
                  isWhatIf,
                  value
                };
              }
            }
          }

          if (
            getIsAccessViewByType(globalTypeMoneyOutOther) &&
            entity.globalType === globalTypeMoneyOutOther
          ) {
            const isNotExpired = isNotExpiredMonths(entity, { calcDate });

            if (isNotExpired) {
              const value = annualChange
                ? +entity.annualCost * Math.pow(1 + annualChange / 100, t)
                : +entity.annualCost;

              tempYear.assetsAll.moneyOut.rentLease[entity.id] = {
                isWhatIf,
                value
              };

              if (isOverdraft) {
                tempYear.assetsAllWhatIf.moneyOut.rentLease[entity.id] = {
                  isWhatIf,
                  value
                };
              }
            }
          }
        }
      });
  };

  prepareMembers = () => {
    const { family, childs } = this.props;

    // collect family data
    const members = {
      You: {
        age: 18,
        name: family ? family.displayName : "",
        isMarried: false
      }
    };

    if (family && family.birthday && family.birthday.seconds) {
      members.You.age = diffYears(Date.now(), family.birthday.seconds * 1000);
      members.You.isMarried =
        family.relationshipStatus === "married" ||
        family.relationshipStatus === "established";
    }

    if (
      family &&
      family.relationshipStatus &&
      members.You.isMarried &&
      family.spouse &&
      family.spouse.birthday &&
      family.spouse.birthday.seconds
    ) {
      const age = diffYears(Date.now(), family.spouse.birthday.toDate());

      members.Spouse = { age, name: family.spouse.spouseName };
    }

    if (childs) {
      Object.keys(childs).forEach(childKey => {
        let child = childs[childKey];

        const age = diffYears(Date.now(), child.birthday.toDate());

        members[childKey] = {
          ...child,
          id: childKey,
          age
        };
      });
    }

    return members;
  };

  getLifeEvents = (members, currentAge) => {
    const { transactionsAll, profileProvider, auth, intl } = this.props;

    const lifeEvents = { death: {}, relocation: {}, jobLoss: {}, special: {} };
    const lifeEventsWhatIf = {
      death: {},
      relocation: {},
      jobLoss: {},
      special: {}
    };

    transactionsAll &&
      transactionsAll.forEach(entity => {
        if (
          entity &&
          !entity.isDisabled &&
          (profileProvider === entity.user || auth.uid === entity.user)
        ) {
          if (
            entity.globalType === globalTypeEvent &&
            entity.entity === entitiesEvents.ENTITY_LIFE_EVENT
          ) {
            if (entity.type === optionsLifeEventTypeValues.DEATH) {
              if (!entity.isWhatIf) {
                lifeEvents.death[entity.member] = entity.startAge;
              }

              lifeEventsWhatIf.death[entity.member] = entity.startAge;
            }

            if (entity.type === optionsLifeEventTypeValues.JOB_LOSS) {
              if (!entity.isWhatIf) {
                lifeEvents.jobLoss[entity.member] = entity.startAge;
              }

              lifeEventsWhatIf.jobLoss[entity.member] = entity.startAge;
            }

            if (entity.type === optionsLifeEventTypeValues.RELOCATION) {
              if (!entity.isWhatIf) {
                lifeEvents.relocation = {
                  age: entity.startAge,
                  zipCode: entity.zipCode
                };
              }

              lifeEventsWhatIf.relocation = {
                age: entity.startAge,
                zipCode: entity.zipCode
              };
            }
          }
        }
      });

    const { Spouse, You, ...rest } = members;

    if (You.age <= 55) {
      if (!lifeEvents.special[55]) {
        lifeEvents.special[55] = [];
      }

      lifeEvents.special[55].push({
        id: `you-55`,
        age: 55,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.55.LABEL",
            defaultMessage:
              "If retired and have 401K, {name} may withdraw without penalty"
          },
          { name: members.You.name }
        )
      });
    }
    if (You.age <= 59) {
      if (!lifeEvents.special[59]) {
        lifeEvents.special[59] = [];
      }

      lifeEvents.special[59].push({
        id: `you-59`,
        age: 59,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.59.LABEL",
            defaultMessage:
              "{name} can withdraw tax-deferred assets without penalty at 59 ½"
          },
          { name: members.You.name }
        )
      });
    }

    if (You.age <= 62) {
      if (!lifeEvents.special[62]) {
        lifeEvents.special[62] = [];
      }

      lifeEvents.special[62].push({
        id: `you-62`,
        age: 62,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.62.LABEL",
            defaultMessage:
              "Earliest age {name} can begin Social Security benefits at reduced amounts"
          },
          { name: members.You.name }
        )
      });
    }
    if (You.age <= 66) {
      if (!lifeEvents.special[66]) {
        lifeEvents.special[66] = [];
      }

      lifeEvents.special[66].push({
        id: `you-66`,
        age: 66,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.66.LABEL",
            defaultMessage:
              "{name} is eligible to full Social Security benefits"
          },
          { name: members.You.name }
        )
      });
    }

    if (You.age <= 70) {
      if (!lifeEvents.special[70]) {
        lifeEvents.special[70] = [];
      }

      lifeEvents.special[70].push({
        id: `you-70`,
        age: 70,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.70.LABEL",
            defaultMessage:
              "{name} is eligible to maximum Social Security benefits"
          },
          { name: members.You.name }
        )
      });

      lifeEvents.special[70].push({
        id: `you-70_5`,
        age: 70,
        message: intl.formatMessage(
          {
            id: "DASHBOARD.MAIN.SPECIAL_EVENT.70_5.LABEL",
            defaultMessage:
              '{name} must take Required Minimum Distributions at 70 ½"'
          },
          { name: members.You.name }
        )
      });
    }

    if (Spouse) {
      if (Spouse.age <= 55) {
        const eventAge = currentAge + (55 - Spouse.age);

        if (eventAge <= MAX_AGE) {
          if (!lifeEvents.special[eventAge]) {
            lifeEvents.special[eventAge] = [];
          }

          lifeEvents.special[eventAge].push({
            id: `spouse-55`,
            age: eventAge,
            message: intl.formatMessage(
              {
                id: "DASHBOARD.MAIN.SPECIAL_EVENT.55.LABEL",
                defaultMessage:
                  "If retired and have 401K, {name} may withdraw without penalty"
              },
              { name: members.Spouse.name }
            )
          });
        }
      }

      if (Spouse.age <= 59) {
        const eventAge = currentAge + (59 - Spouse.age);

        if (eventAge <= MAX_AGE) {
          if (!lifeEvents.special[eventAge]) {
            lifeEvents.special[eventAge] = [];
          }

          lifeEvents.special[eventAge].push({
            id: `spouse-59`,
            age: eventAge,
            message: intl.formatMessage(
              {
                id: "DASHBOARD.MAIN.SPECIAL_EVENT.59.LABEL",
                defaultMessage:
                  "{name} can withdraw tax-deferred assets without penalty at 59 ½"
              },
              { name: members.Spouse.name }
            )
          });
        }

        if (Spouse.age <= 62) {
          const eventAge = currentAge + (62 - Spouse.age);

          if (eventAge <= MAX_AGE) {
            if (!lifeEvents.special[eventAge]) {
              lifeEvents.special[eventAge] = [];
            }

            lifeEvents.special[eventAge].push({
              id: `spouse-62`,
              age: eventAge,
              message: intl.formatMessage(
                {
                  id: "DASHBOARD.MAIN.SPECIAL_EVENT.62.LABEL",
                  defaultMessage:
                    "Earliest age {name} can begin Social Security benefits at reduced amounts"
                },
                { name: members.Spouse.name }
              )
            });
          }
        }

        if (Spouse.age <= 66) {
          const eventAge = currentAge + (66 - Spouse.age);

          if (eventAge <= MAX_AGE) {
            if (!lifeEvents.special[eventAge]) {
              lifeEvents.special[eventAge] = [];
            }

            lifeEvents.special[eventAge].push({
              id: `spouse-66`,
              age: eventAge,
              message: intl.formatMessage(
                {
                  id: "DASHBOARD.MAIN.SPECIAL_EVENT.66.LABEL",
                  defaultMessage:
                    "{name} is eligible to full Social Security benefits"
                },
                { name: members.Spouse.name }
              )
            });
          }
        }

        if (Spouse.age <= 70) {
          const eventAge = currentAge + (70 - Spouse.age);

          if (eventAge <= MAX_AGE) {
            if (!lifeEvents.special[eventAge]) {
              lifeEvents.special[eventAge] = [];
            }

            lifeEvents.special[eventAge].push({
              id: `spouse-70`,
              age: eventAge,
              message: intl.formatMessage(
                {
                  id: "DASHBOARD.MAIN.SPECIAL_EVENT.70.LABEL",
                  defaultMessage:
                    "{name} is eligible to maximum Social Security benefits"
                },
                { name: members.Spouse.name }
              )
            });

            lifeEvents.special[eventAge].push({
              id: `spouse-70_5`,
              age: eventAge,
              message: intl.formatMessage(
                {
                  id: "DASHBOARD.MAIN.SPECIAL_EVENT.70_5.LABEL",
                  defaultMessage:
                    "{name} must take Required Minimum Distributions at 70 ½"
                },
                { name: members.Spouse.name }
              )
            });
          }
        }
      }
    }

    if (rest) {
      Object.keys(rest).map(keyChild => {
        const child = rest[keyChild];

        if (child.age <= 18) {
          const eventAge = currentAge + (18 - child.age);

          if (eventAge <= MAX_AGE) {
            if (!lifeEvents.special[eventAge]) {
              lifeEvents.special[eventAge] = [];
            }

            lifeEvents.special[eventAge].push({
              id: `${keyChild}-${eventAge}`,
              age: eventAge,
              message: intl.formatMessage(
                {
                  id: "DASHBOARD.MAIN.SPECIAL_EVENT.18.LABEL",
                  defaultMessage: "{name} will start college around this time"
                },
                { name: child.childName }
              )
            });
          }
        }
      });
    }

    return { lifeEvents, lifeEventsWhatIf };
  };

  calcFullStat = () => {
    this.setState({ isCalculating: true });

    const { family } = this.props;

    const members = this.prepareMembers();

    const assetsLog = {};

    const currentAge =
      family && family.birthday && family.birthday.seconds
        ? diffYears(Date.now(), family.birthday.seconds * 1000)
        : MIN_CALC_AGE;

    const { lifeEvents, lifeEventsWhatIf } = this.getLifeEvents(
      members,
      currentAge
    );

    // let calcAge = currentAge;
    let maxCalcAge = MAX_AGE;

    // redundant
    let isOverdraftGlobal = true;
    const tempFull = {};

    const isMarried = family && family.relationshipStatus === "married";
    const currentDate = Date.now();

    for (let t = 0; currentAge + t <= MAX_AGE; t++) {
      const calcAge = currentAge + t;

      if (!tempFull[calcAge]) {
        tempFull[calcAge] = cloneObject(initialCalcState);
      }

      const prevYear = currentAge < calcAge ? tempFull[calcAge - 1] : null;
      const tempYear = tempFull[calcAge];
      tempYear.assetsAll = cloneObject(initialYearAssetsState);
      tempYear.assetsAllWhatIf = cloneObject(initialYearAssetsState);

      const calcDate =
        calcAge && t !== 0
          ? setDate(setMonth(addYears(currentDate, t), 0), 1)
          : currentDate;

      this.calcAllTilesByYear(tempYear, prevYear, {
        t,
        currentAge,
        currentDate,
        calcDate,
        lifeEvents,
        lifeEventsWhatIf
      });
    }

    for (let t = 0; currentAge + t <= MAX_AGE; t++) {
      const calcAge = currentAge + t;

      if (!tempFull[calcAge]) {
        tempFull[calcAge] = cloneObject(initialCalcState);
      }

      // const prevYear = currentAge < calcAge ? tempFull[calcAge - 1] : null;
      const tempYear = tempFull[calcAge];

      this.calcAddAllYearData(tempYear, { calcAge });
    }

    Object.keys(tempFull).forEach(yearKey => {
      const calcAge = yearKey;

      if (yearKey < currentAge) {
        return null;
      }
      const t = yearKey - currentAge;
      const calcDate =
        t !== 0 && currentDate
          ? setDate(setMonth(addYears(currentDate, t), 0), 1)
          : currentDate;

      const tempYear = tempFull[yearKey];
      const prevYear = tempFull[yearKey - 1];

      let prevYearSavings =
        prevYear && prevYear.Assets && prevYear.Assets.savings
          ? prevYear.Assets.savings
          : 0;

      let prevYearSavingsWhatIf =
        prevYear && prevYear.AssetsWhatIf && prevYear.AssetsWhatIf.savings
          ? prevYear.AssetsWhatIf.savings
          : 0;

      if (isOverdraftGlobal) {
        //  CALC ALL TILES Overdraft
        if (tempFull[calcAge] && !tempFull[calcAge].overdraft) {
          tempFull[calcAge].overdraft = cloneDeep(initialCalcState);
        }

        const prevYearOverdraft =
          currentAge < calcAge ? tempFull[calcAge - 1].overdraft : null;

        let tempYearOverdraft = tempFull[calcAge].overdraft;

        tempYearOverdraft.assetsAll = cloneDeep(initialYearAssetsState);
        tempYearOverdraft.assetsAllWhatIf = cloneDeep(initialYearAssetsState);

        let prevYearSavingsOverdraft =
          prevYearOverdraft &&
          prevYearOverdraft.Assets &&
          prevYearOverdraft.Assets.savings
            ? prevYearOverdraft.Assets.savings
            : 0;

        let prevYearSavingsOverdraftWhatIf =
          prevYearOverdraft &&
          prevYearOverdraft.AssetsWhatIf &&
          prevYearOverdraft.AssetsWhatIf.savings
            ? prevYearOverdraft.AssetsWhatIf.savings
            : 0;

        const calcDate =
          calcAge && t !== 0
            ? setDate(setMonth(addYears(currentDate, t), 0), 1)
            : currentDate;

        this.calcAllTilesByYear(tempYearOverdraft, prevYearOverdraft, {
          t,
          currentAge,
          currentDate,
          calcDate,
          isOverdraft: true,
          lifeEvents,
          lifeEventsWhatIf
        });

        // CALC ALL DATA Overdraft
        const calcOverdraftYear = true;
        this.calcAddAllYearData(tempYearOverdraft, {
          calcAge,
          isCalcOverdraft: calcOverdraftYear
        });

        //  CALC SUM & TAXES Overdraft
        const { isOverdraft, isOverdraftWhatIf } = this.calcTaxesAndAll(
          tempYearOverdraft,
          isMarried,
          {
            prevYearSavings: prevYearSavingsOverdraft,
            prevYearSavingsWhatIf: prevYearSavingsOverdraftWhatIf,
            yearKey,
            prevYear: prevYearOverdraft,
            lifeEvents,
            lifeEventsWhatIf
          }
        );

        if (isOverdraft || isOverdraftWhatIf) {
          const savingsYear = tempYearOverdraft.Assets.savings;
          const savingsYearWhatIf = tempYearOverdraft.AssetsWhatIf.savings;

          const {
            overdraftCalcSavings,
            overdraftCalcSavingsWhatIf
          } = this.calcOverdraft(tempYearOverdraft, {
            t,
            calcDate,
            savingsYear,
            savingsYearWhatIf,
            calcAge,
            overdraft: { isOverdraftWhatIf, isOverdraft }
          });

          if (isOverdraft) {
            tempYearOverdraft.Assets.savings = overdraftCalcSavings;
          }
          if (isOverdraftWhatIf) {
            tempYearOverdraft.AssetsWhatIf.savings = overdraftCalcSavingsWhatIf;
          }
        }
      }

      const {
        isOverdraft: isOverdraftFirst,
        isOverdraftWhatIf: isOverdraftFirstWhatIf
      } = this.calcTaxesAndAll(tempYear, isMarried, {
        prevYearSavings,
        prevYearSavingsWhatIf,
        yearKey,
        prevYear,
        lifeEvents,
        lifeEventsWhatIf
      });

      // first overdraft
      if ((isOverdraftFirst || isOverdraftFirstWhatIf) && !isOverdraftGlobal) {
        const savingsYear = tempYear.Assets.savings;
        const savingsYearWhatIf = tempYear.AssetsWhatIf.savings;
        isOverdraftGlobal = true;

        const tempYearOverdraft = cloneObject(tempYear);
        tempYearOverdraft.assetsAllWhatIf = cloneObject(
          tempYearOverdraft.assetsAll
        );

        tempYear.overdraft = tempYearOverdraft;

        const {
          overdraftCalcSavings,
          overdraftCalcSavingsWhatIf
        } = this.calcOverdraft(tempYearOverdraft, {
          t,
          calcDate,
          savingsYear,
          savingsYearWhatIf,
          calcAge,
          overdraft: {
            isOverdraftWhatIf: isOverdraftFirstWhatIf,
            isOverdraft: isOverdraftFirst
          }
        });

        tempYearOverdraft.Assets.savings = overdraftCalcSavings;
        tempYearOverdraft.AssetsWhatIf.savings = overdraftCalcSavingsWhatIf;
      }
    });

    this.calcChartData(tempFull, currentAge);
    this.calcChartData(tempFull, currentAge, { isOverdraft: true });

    // TODO
    // this.calcMonetaryIndependence(tempFull);

    const calcTimestamp = +new Date();

    this.setState({
      isCalculating: false,
      fullCalc: tempFull,
      currentAge,
      assetsLog,
      members,
      calcTimestamp,
      lifeEvents,
      lifeEventsWhatIf
    });
  };

  // calcMonetaryIndependence = fullCalc => {
  //   const currentOverdraftPriority = this.getOverdraftPriority();
  //
  //   console.log('---currentOverdraftPriority', currentOverdraftPriority);
  //   console.log("fullCalc---", fullCalc);
  // };

  checkOverdraftCondition = (entity, item, { calcDate, calcAge } = {}) => {
    if (!item.condition) {
      return true;
    }

    let conditionAccepted = true;

    if (item.condition === "LifeMore7" && entity.insuranceDate) {
      const insuranceDate = new Date(entity.insuranceDate * 1000);
      const yearsPassed = diffYears(calcDate, insuranceDate);

      if (yearsPassed <= 7) {
        conditionAccepted = false;
      }
    }

    if (item.condition === "LifeLess7" && entity.insuranceDate) {
      const insuranceDate = new Date(entity.insuranceDate * 1000);
      const yearsPassed = diffYears(calcDate, insuranceDate);

      if (yearsPassed > 7) {
        conditionAccepted = false;
      }
    }

    if (item.condition === "eventDontStart") {
      conditionAccepted = !entity.isEventDeduction;
    }

    if (item.condition === "iraTypeRoth") {
      conditionAccepted = entity.type === TypeAnnuitValues.ROTH;
    }

    if (item.condition === "taxNoTax") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.NO_TAX &&
        entity.entityType === entitiesSavings.ENTITY_OTHER;
    }

    if (item.condition === "taxCapital") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.CAPITAL_GAIN &&
        entity.entityType === entitiesSavings.ENTITY_OTHER;
    }
    if (item.condition === "taxOrdinary") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.ORDINARY_INCOME &&
        entity.entityType === entitiesSavings.ENTITY_OTHER;
    }

    if (item.condition === "taxNoTaxGeneral") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.NO_TAX &&
        entity.entityType === entitiesSavings.ENTITY_GENERAL;
    }

    if (item.condition === "taxCapitalGeneral") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.CAPITAL_GAIN &&
        entity.entityType === entitiesSavings.ENTITY_GENERAL;
    }
    if (item.condition === "taxOrdinaryGeneral") {
      conditionAccepted =
        entity.type === optionsTaxCategoryValues.ORDINARY_INCOME &&
        entity.entityType === entitiesSavings.ENTITY_GENERAL;
    }

    if (item.condition === "ageMore59.5") {
      conditionAccepted = +calcAge > 59.5;
    }

    if (item.condition === "ageLess59.5") {
      conditionAccepted = +calcAge < 59.5;
    }

    if (item.condition === "homeTypeVacation") {
      conditionAccepted = entity.type === optionsValuesHome.VACATION;
    }
    if (item.condition === "homeTypeOther") {
      conditionAccepted = entity.type === optionsValuesHome.OTHER;
    }
    if (item.condition === "homeTypeInvestment") {
      conditionAccepted = entity.type === optionsValuesHome.INVESTMENT;
    }
    if (item.condition === "homeTypeMultiple") {
      conditionAccepted = entity.type === optionsValuesHome.MULTIPLE;
    }
    if (item.condition === "homeTypePrimary") {
      conditionAccepted = entity.type === optionsValuesHome.PRIMARY;
    }

    return conditionAccepted;
  };

  getOverdraftPriority = () => {
    const { configurationDepletion } = this.props;

    let currentConfigurationDepletion = configurationDepletion;
    if (!configurationDepletion) {
      currentConfigurationDepletion = overdraftPriorityDefault;
    }

    const customPriority = currentConfigurationDepletion.map(el => {
      const globalType = el.globalType;
      const entity = el.entity;
      const entityCustom = el.entityCustom;

      const scheme =
        overdraftPriorityScheme[`${globalType}-${entityCustom || entity}`];

      return { ...scheme, ...el };
    });

    return customPriority;
  };

  getSortedEntitiesOverdraft = (entities, item) => {
    const orderBy = item.orderBy;
    const order = item.order || "desc";

    let entitiesSorted =
      entities && stableSort(entities, getSorting(order, orderBy));

    return entitiesSorted;
  };

  deductOverdraft = (
    tempYear,
    tempSavings,
    { calcAge, calcDate, isWhatIf, currentOverdraftPriority }
  ) => {
    const deductFromAllCategories = (
      tempYear,
      pathArray,
      value,
      { isWhatIf }
    ) => {
      pathArray.forEach(path => {
        const tempPath = [...path];

        if (isWhatIf) {
          tempPath[0] = tempPath[0] + "WhatIf";
        }

        const oldValue = get(tempPath, tempYear);
        const resultValue = oldValue - value;

        setToValue(tempYear, resultValue, tempPath);
      });
    };

    currentOverdraftPriority.forEach(item => {
      if (!(item && item.path)) {
        return;
      }

      const entities = isWhatIf
        ? get(item.path, tempYear.assetsAllWhatIf)
        : get(item.path, tempYear.assetsAll);

      const assetsAllTemp = isWhatIf
        ? tempYear.assetsAllWhatIf
        : tempYear.assetsAll;

      if (entities && item.isEnabled) {
        let entitiesArray = Object.keys(entities).map(entityKey => ({
          ...entities[entityKey],
          entityKey
        }));

        if (item.orderBy) {
          /// custom orders
          if (
            item.orderBy === "remainingDebt" ||
            item.orderByCustom === "incomeRent"
          ) {
            let entitiesDebtAll = assetsAllTemp.debt.home;

            entitiesArray = entitiesArray.map(home => {
              let remainingDebt = 0;
              let annualPay = 0;

              if (
                home.debtId &&
                entitiesDebtAll &&
                entitiesDebtAll[home.debtId]
              ) {
                remainingDebt = +entitiesDebtAll[home.debtId].value || 0;
                annualPay = +entitiesDebtAll[home.debtId].annualPay || 0;
              }

              return { ...home, remainingDebt, annualPay };
            });
          }

          if (item.orderByCustom === "incomeRent") {
            let entitiesRentAll = assetsAllTemp.moneyIn.rent;

            entitiesArray = entitiesArray.map(home => {
              home.incomeRent = 0;

              if (entitiesRentAll && home.value) {
                Object.keys(entitiesRentAll).forEach(key => {
                  const entityRent = entitiesRentAll[key];

                  const monthlyDebt = home.annualPay ? home.annualPay / 12 : 0;

                  if (entityRent.assetEntity === home.id) {
                    home.incomeRent = entityRent.value - monthlyDebt;
                  }
                });
              }

              return home;
            });
          }

          entitiesArray = this.getSortedEntitiesOverdraft(entitiesArray, item);
        }

        entitiesArray.forEach(entity => {
          const entityKey = entity.entityKey;

          if ((!entity.isWhatIf || isWhatIf) && tempSavings < 0) {
            const field = entity[item.key];
            let conditionAccepted = this.checkOverdraftCondition(entity, item, {
              calcDate,
              calcAge
            });

            conditionAccepted = !entity.isEventDeduction && conditionAccepted;

            if (conditionAccepted && field && field > 0) {
              if (Math.abs(tempSavings) >= field || item.isFull) {
                let debtDeduct = 0;

                if (item.isFull) {
                  if (
                    entity.debtId &&
                    assetsAllTemp.debt.home &&
                    assetsAllTemp.debt.home[entity.debtId]
                  ) {
                    const debtEntity = assetsAllTemp.debt.home[entity.debtId];

                    debtDeduct = debtEntity.value;
                  }
                }

                const sumDeduct = field;
                if (item.deduct) {
                  deductFromAllCategories(tempYear, item.deduct, sumDeduct, {
                    isWhatIf
                  });
                }
                if (item.deductDebt) {
                  deductFromAllCategories(
                    tempYear,
                    item.deductDebt,
                    debtDeduct,
                    {
                      isWhatIf
                    }
                  );
                }

                if (item.isOverdrafted) {
                  setToValue(assetsAllTemp, true, [
                    ...item.path,
                    entityKey,
                    "isOverdrafted"
                  ]);
                }
                if (item.isOverdraftWas) {
                  setToValue(assetsAllTemp, true, [
                    ...item.path,
                    entityKey,
                    "isOverdraftWas"
                  ]);
                }

                tempSavings += field - debtDeduct;

                setToValue(assetsAllTemp, 0, [
                  ...item.path,
                  entityKey,
                  item.key
                ]);

                if (
                  debtDeduct &&
                  item.keyDebt &&
                  item.pathDebt &&
                  entity.debtId
                ) {
                  setToValue(assetsAllTemp, 0, [
                    ...item.pathDebt,
                    entity.debtId,
                    item.keyDebt
                  ]);
                }

                if (item.key2) {
                  setToValue(assetsAllTemp, 0, [
                    ...item.path,
                    entityKey,
                    item.key2
                  ]);
                }

                setToValue(assetsAllTemp, sumDeduct, [
                  ...item.path,
                  entityKey,
                  "cover"
                ]);
              } else {
                const sumDeduct = Math.abs(tempSavings);

                if (item.deduct) {
                  deductFromAllCategories(tempYear, item.deduct, sumDeduct, {
                    isWhatIf
                  });
                }

                if (item.isOverdraftWas) {
                  setToValue(assetsAllTemp, true, [
                    ...item.path,
                    entityKey,
                    "isOverdraftWas"
                  ]);
                }
                setToValue(assetsAllTemp, tempSavings + entity[item.key], [
                  ...item.path,
                  entityKey,
                  item.key
                ]);

                if (item.key2) {
                  setToValue(assetsAllTemp, tempSavings + entity[item.key], [
                    ...item.path,
                    entityKey,
                    item.key2
                  ]);
                }

                setToValue(assetsAllTemp, sumDeduct, [
                  ...item.path,
                  entityKey,
                  "cover"
                ]);

                tempSavings = 0;
              }
            }
          }
        });
      }
    });

    return tempSavings;
  };

  calcOverdraft = (
    tempYear,
    { t, calcDate, calcAge, savingsYear, savingsYearWhatIf, overdraft } = {}
  ) => {
    let tempSavings = savingsYear;
    let tempSavingsWhatIf = savingsYearWhatIf;
    const currentOverdraftPriority = this.getOverdraftPriority();

    const { isOverdraft, isOverdraftWhatIf } = overdraft;

    if (isOverdraft) {
      tempSavings = this.deductOverdraft(tempYear, savingsYear, {
        calcDate,
        calcAge,
        currentOverdraftPriority
      });
    }
    if (isOverdraftWhatIf) {
      tempSavingsWhatIf = this.deductOverdraft(tempYear, savingsYearWhatIf, {
        calcAge,
        calcDate,
        isWhatIf: true,
        currentOverdraftPriority
      });
    }

    return {
      overdraftCalcSavings: tempSavings,
      overdraftCalcSavingsWhatIf: tempSavingsWhatIf
    };
  };

  helperSumAllProperties = object => {
    let sum = 0;

    Object.keys(object).forEach(key => {
      if (key === "all") {
        return;
      }

      sum += object[key];
    });

    return sum;
  };

  render() {
    const {
      firestoreStatus,
      transactionsAll,
      assetsAll,
      configurationDepletion
    } = this.props;

    if (
      firestoreStatus.requesting.family ||
      firestoreStatus.requesting.assetsAll ||
      firestoreStatus.requesting.transactionsAll ||
      this.state.isCalculating
    ) {
      return <IsLoading />;
    }

    const isEmpty =
      (!assetsAll || assetsAll.length === 0) &&
      (!transactionsAll || transactionsAll.length === 0);

    const assetsAllObj = {};
    assetsAll &&
      assetsAll.forEach(entity => {
        assetsAllObj[entity.id] = entity;
      });
    const transactionsAllObj = {};
    transactionsAll &&
      transactionsAll.forEach(entity => {
        transactionsAllObj[entity.id] = entity;
      });

    if (this.props.location.pathname === OPERATIONS_CASHFLOW) {
      return (
        <Operations {...this.props} propsState={this.state} isEmpty={isEmpty} />
      );
    }

    if (this.props.location.pathname === DASHBOARD) {
      return (
        <Dashboard
          {...this.props}
          propsState={this.state}
          assetsAllObj={assetsAllObj}
          transactionsAllObj={transactionsAllObj}
          isEmpty={isEmpty}
          members={this.state.members}
        />
      );
    }

    if (this.props.location.pathname === OPERATIONS_DASBOARD) {
      return (
        <DashboardAdvisor
          {...this.props}
          propsState={this.state}
          isEmpty={isEmpty}
        />
      );
    }

    if (this.props.location.pathname === ADMIN_CALC_LOG) {
      return (
        <DashboardLog
          {...this.props}
          propsState={this.state}
          assetsAllObj={assetsAllObj}
          transactionsAllObj={transactionsAllObj}
          isEmpty={isEmpty}
        />
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  const {
    ui,
    firebase: { auth, profile },
    firestore: { data, ordered, status }
  } = state;

  const rights = ordered && ordered.rights;

  const userUid = ui.profileProvider || auth.uid;

  const childs =
    data && data.users && data.users[userUid] && data.users[userUid].childs;

  const childUser = data && data.users && data.users[userUid];
  const childsArr =
    childUser && childUser.childs
      ? Object.keys(childUser.childs).map(childKey => ({
          id: childKey,
          ...childUser.childs[childKey]
        }))
      : null;

  const advisorDashboard =
    ordered && ordered.advisorDashboard && ordered.advisorDashboard[0];

  const configuration =
    ordered && ordered.configuration && ordered.configuration[0];

  const configurationDepletion =
    ordered &&
    ordered.configuration &&
    ordered.configuration[0] &&
    ordered.configuration[0].assetsDepletion;

  return {
    dashboardIfChecked: ui.dashboardIfChecked,
    dashboardChartTerm: ui.dashboardChartTerm,

    dashboardNoOverdraft: ui.dashboardNoOverdraft,
    dashboardWealthIncome: ui.dashboardWealthIncome,
    dashboardAgeYear: ui.dashboardAgeYear,

    dashboardWealthIncomeSecondary: ui.dashboardWealthIncomeSecondary,
    dashboardAgeYearWIf: ui.dashboardAgeYearWIf,
    uiSettings: ui,

    profileProvider: ui.profileProvider,
    // profileProvider,
    isMobile: ui.isMobile,
    auth,
    profile,
    advisorDashboard,
    myAdvisors: getMyAdvisorsState(state),
    assetsAll: getAssetsAllData(ordered),
    eventsAll: getAssetsAllEventsData(ordered),
    goalsAll: getAssetsAllGoalsData(ordered),
    transactionsAll: getTransactionsAllData(ordered),

    configurationDepletion,
    configuration,
    childs: childs,
    childsArr: childsArr,
    rights: rights && rights[0],
    family: ordered && ordered.family && ordered.family[0],
    firestoreStatus: status
  };
}
export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    { setUI }
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props) || props.auth.uid;

    const queries = [
      {
        collection: "users",
        doc: profileUid,
        subcollections: [
          {
            collection: "childs",
            orderBy: ["birthday", "asc"],
            storeAs: "childs"
          }
        ]
      },
      {
        collection: "userAssets",
        where: [["user", "==", profileUid]],
        storeAs: "assetsAll"
      },
      {
        collection: "transactions",
        where: [["user", "==", profileUid]],
        storeAs: "transactionsAll"
      },
      { collection: "users", doc: profileUid, storeAs: "family" },
      {
        collection: "configuration",
        where: [["clientId", "==", profileUid]],
        storeAs: "configuration"
      },
      {
        collection: "advisor",
        where: [["clientId", "==", props.auth.uid]],
        storeAs: "myAdvisors"
      }
    ];

    if (props.auth.uid !== profileUid) {
      queries.push({
        collection: "advisorDashboard",
        where: [
          ["clientId", "==", profileUid],
          ["advisorId", "==", props.auth.uid]
        ],
        storeAs: "advisorDashboard"
      });

      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  }),
  withRouter,
  injectIntl
)(withFirebase(CalcContainer));

function setToValue(obj, value, path) {
  let i = 0;
  // path = path.split('.');
  for (i = 0; i < path.length - 1; i++) {
    obj = obj[path[i]];
  }

  obj[path[i]] = value;
}

function get(path, object) {
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);
}
