import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconClockArrow = ({
  height = 16,
  width = 20,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M8.00016 1.33301C4.32568 1.33301 1.3335 4.3252 1.3335 7.99967C1.3335 11.6742 4.32568 14.6663 8.00016 14.6663C9.49756 14.6663 10.8856 14.1689 12.0002 13.333L13.3335 14.6663V11.333H10.0002L11.0418 12.3747C10.1799 12.9762 9.13558 13.333 8.00016 13.333C5.04704 13.333 2.66683 10.9528 2.66683 7.99967C2.66683 5.04655 5.04704 2.66634 8.00016 2.66634C10.9533 2.66634 13.3335 5.04655 13.3335 7.99967H14.6668C14.6668 4.3252 11.6746 1.33301 8.00016 1.33301ZM7.3335 3.99967V8.66634H10.6668V7.33301H8.66683V3.99967H7.3335Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconClockArrow.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconClockArrow;
