import React from "react";

const About = () => {
  return (
    <section>
      <h1>About page</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet
        architecto asperiores aspernatur blanditiis, deserunt dolores, hic in
        itaque nihil non obcaecati odio officia qui sed sint tempora veritatis
        voluptatum.
      </p>
    </section>
  );
};

export default About;
