import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconDisabled = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12 2C6.46875 2 2 6.46875 2 12C2 17.5312 6.46875 22 12 22C17.5312 22 22 17.5312 22 12C22 6.46875 17.5312 2 12 2ZM17 15.5898L15.5898 17L12 13.4102L8.41016 17L7 15.5898L10.5898 12L7 8.41016L8.41016 7L12 10.5898L15.5898 7L17 8.41016L13.4102 12L17 15.5898Z"
        fill={fill}
      />
    </svg>
  );
};

IconDisabled.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconDisabled;
