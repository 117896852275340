import React from "react";
import PropTypes from "prop-types";

import "./EmptyPanel.scss";
import { FormattedMessage } from "react-intl-v3";

const EmptyPanel = ({ title, titleI18n }) => {
  return (
    <section className="empty-panel">
      <div className="empty-panel__title">
        <FormattedMessage id={titleI18n} defautlMessage={title} />
      </div>
    </section>
  );
};

EmptyPanel.propTypes = {
  title: PropTypes.string,
  titleI18n: PropTypes.string
};

export default EmptyPanel;
