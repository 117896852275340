import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconDoubleChevronLeft = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M12.4102 7.42L7.83016 12L12.4102 16.59L11.0002 18L5.00016 12L11.0002 6L12.4102 7.42Z"
        fill={fill}
      />
      <path
        d="M18 7.42L13.42 12L18 16.59L16.59 18L10.59 12L16.59 6L18 7.42Z"
        fill={fill}
      />
    </svg>
  );
};

IconDoubleChevronLeft.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconDoubleChevronLeft;
