import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const UmbrellaIndependence = ({
  height = 17,
  width = 20,
  color = "white",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 17"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M10 0L6.25 7.93333H8.88889V17H10.8333L11.1111 16.8229V7.93333H13.75L10 0ZM8.22917 1.20417L0 5.66667L4.93056 7.93333L8.22917 1.20417ZM11.7708 1.20417L15.0694 7.93333L20 5.66667L11.7708 1.20417Z"
        fill={fill}
      />
    </svg>
  );
};

UmbrellaIndependence.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default UmbrellaIndependence;
