import format from "date-fns/format";

const locales = {
  en: require("date-fns/locale/en-US"),
  ru: require("date-fns/locale/ru"),
  zh: require("date-fns/locale/zh-CN")
};

export default function(date, formatStr = "MMMM do, yyyy") {
  return format(date, formatStr, {
    locale: locales[window.__localeId__] // or global.__localeId__
  });
}
