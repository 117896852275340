import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";

import { withStyles } from "@material-ui/core";
import styles from "./styles";
// import Button from "@material-ui/core/Button/Button";
import Button from "components/_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";

// import AppBarLink from "./AppBarLink";
import { compose } from "redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl-v3";
import { BREAKPOINT_MD } from "constants/styles";
import IconButton from "@material-ui/core/IconButton";

// import * as ROUTES from "constants/routes";

class AppBarLogIn extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  static propTypes = {
    classes: PropTypes.object,
    isMobile: PropTypes.bool,
    rightButtonLink: PropTypes.string,
    rightButtonText: PropTypes.any
  };

  state = {
    anchorEl: null,
    isProfileMenuOpen: false
  };

  isMobile = window.innerWidth <= BREAKPOINT_MD;

  handleProfileMenuOpen = ev => {
    const { currentTarget } = ev;
    this.setState({ anchorEl: currentTarget, isProfileMenuOpen: true });
  };

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null, isProfileMenuOpen: false });
  };
  handleGoBack = () => {
    if (this.props.history) {
      this.props.history.goBack();
    }
  };

  render() {
    const { classes, rightButtonLink, rightButtonText } = this.props;

    return (
      <>
        <AppBar position="fixed" color="default" className={classes.header}>
          <Toolbar className={classes.toolbarLogIn}>
            <div className={classes.toolbarLogInWrapper}>
              {this.isMobile ? (
                <IconButton onClick={this.handleGoBack}>
                  <Icons.IconArrowLeft color="white" width={24} height={24} />
                </IconButton>
              ) : (
                <Button
                  color="inherit"
                  aria-label="Go back"
                  onClick={this.handleGoBack}
                  white
                >
                  <Icons.IconArrowLeft
                    className={classes.backButtonIcon}
                    color="textTetrieary"
                    width={24}
                    height={24}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </Button>
              )}

              {this.isMobile ? (
                <img
                  className={classes.logo}
                  src="/assets/img/logo-orange-white.svg"
                  alt="XCurve"
                  width={89}
                  height={16}
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/assets/img/logo.png"
                  alt="XCurve"
                  width={89}
                  height={16}
                />
              )}

              {!!rightButtonLink && !!rightButtonText ? (
                this.isMobile ? (
                  <IconButton>
                    <NavLink className={classes.link} to={rightButtonLink}>
                      {rightButtonText}
                    </NavLink>
                  </IconButton>
                ) : (
                  <NavLink
                    to={rightButtonLink}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      className={classes.profileButton}
                      onClick={this.handleProfileMenuOpen}
                      ui
                    >
                      {rightButtonText}
                    </Button>
                  </NavLink>
                )
              ) : (
                <div />
              )}
            </div>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter
)(AppBarLogIn);
