import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconAlert = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1,
  stroke = "#FFFFFF",
  strokeWidth = 0,
  strokeLinecap = "round"
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M13 14H11V9.99997H13V14ZM13 18H11V16H13V18ZM2.73804 17.9979C1.96611 19.3312 2.92823 21 4.4689 21H19.5311C21.0718 21 22.0339 19.3312 21.262 17.9979L13.7309 4.98962C12.9605 3.65905 11.0395 3.65906 10.2692 4.98963L2.73804 17.9979Z"
        fill={fill}
        opacity={opacity}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        shapeRendering="crispEdges"
      />
    </svg>
  );
};

IconAlert.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconAlert;
