import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Slide from "@material-ui/core/Slide";
import { FormattedMessage } from "react-intl-v3";
import CircularProgress from "@material-ui/core/CircularProgress";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogFinishWizard extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAgreeAndClose: PropTypes.func,
    fullScreen: PropTypes.bool
  };

  render() {
    const {
      isOpen,
      handleAgreeAndClose,
      handleClose,
      fullScreen,
      isLoading
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        {isOpen && (
          <>
            <DialogTitle id="form-dialog-title">Warning!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage
                  id="WIZARD.COMMON.WARNING.ASSET"
                  defaultMessage='If you change any data manually (without wizard), \n You will not be able to use "Wizard" again!'
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={isLoading}
              >
                <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
              </Button>
              <Button
                onClick={handleAgreeAndClose}
                color="secondary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <FormattedMessage
                    id="WIZARD.COMMON.CHANGE_ANYWAY"
                    defaultMessage="Change anyway"
                  />
                )}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default DialogFinishWizard;
