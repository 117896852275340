let config;
switch (process.env.REACT_APP_RUNTIME_CONFIG) {
  case "dev7":
    config = require("../config/dev7.js");
    break;
  case "release":
    config = require("../config/release.js");
    break;
  case "dev":
    config = require("../config/dev.js");
    break;
  case "life":
    config = require("../config/life.js");
    break;
  default:
    config = require("../config/dev.js");
}

export const functionsURL = config.functionsURL;
export const CONFIG = config.default;
