export default {
  apiKey: "AIzaSyDkn4qw2xHRh7QTmFYxLZKoY-glg44adDY",
  authDomain: "xcurve-life.firebaseapp.com",
  databaseURL: "https://xcurve-life.firebaseio.com",
  projectId: "xcurve-life",
  storageBucket: "xcurve-life.appspot.com",
  messagingSenderId: "382032582038",
  appId: "1:382032582038:web:ddce63fa6523a3e5"
};

export const functionsURL = "https://us-central1-xcurve-life.cloudfunctions.net";