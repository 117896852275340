import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { breadcrumbNameMap } from "constants/menu";
import * as Icons from "components/_common/icons";
import "./AppBarTopMobile.scss";
import { FormattedMessage } from "react-intl-v3";

class ContainerAppBarTopMobile extends Component {
  static propTypes = {
    handleDrawerToggle: PropTypes.func
  };

  state = {};

  render() {
    const { handleDrawerToggle, location } = this.props;

    const { pathname } = location;

    const titleObject = breadcrumbNameMap[pathname] || "";

    return (
      <>
        <div className="top-mobile">
          <div className="top-mobile__wrapper">
            <button
              className="top-mobile__drawer-button"
              onClick={handleDrawerToggle}
            >
              <Icons.IconMenu
                width={24}
                height={24}
                color="white"
                opacity={1}
              />
            </button>
            <div className="top-mobile__title">
              {!!titleObject && (
                <FormattedMessage
                  id={titleObject.nameI18n}
                  defaultMessage={titleObject.name}
                />
              )}
            </div>
            <div className="top-mobile__actions" />
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ContainerAppBarTopMobile);
