import {
  addAdditionalConfiguration,
  optionsTaxCategory,
  commonPrefixAfter,
  prepareState
} from "../AssetsCommon/_formScheme";
import {
  RIGHT_HOUSEHOLD_ASSETS,
  RIGHT_HOUSEHOLD_ASSETS_SAVING
} from "../../constants/rights";

export const GLOBAL_TYPE_USE_ASSETS = "savings-retirement";
export const GLOBAL_COLLECTION_USE_ASSETS = "userAssets";
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_ASSETS;
export const RIGHT_LEVEL = RIGHT_HOUSEHOLD_ASSETS_SAVING;

const ENTITY_401K = "401K";
const ENTITY_IRA = "IRA";
const ENTITY_ANNUITY = "Annuity";
const ENTITY_DEFERRED_COMPENSATION = "DeferredCompensation";
const ENTITY_529_COLLEGE_SAVINGS = "529CollegeSavings";

const ENTITY_LIFE_INSURANCE = "LifeInsurance";
const ENTITY_OTHER = "Other";
const ENTITY_GENERAL = "general";

const ENTITY_DEBT_IRA = "IRADebt";
const ENTITY_DEBT_ANNUITY = "AnnuityDebt";
const ENTITY_DEBT_529_COLLEGE_SAVINGS = "529CollegeSavingsDebt";
const ENTITY_DEBT_LIFE_INSURANCE = "LifeInsuranceDebt";
const ENTITY_DEBT_OTHER = "OtherDebt";
const ENTITY_DEBT_GENERAL = "GeneralDebt";

export const ENTITIES = {
  ENTITY_401K,
  ENTITY_IRA,
  ENTITY_ANNUITY,
  ENTITY_DEFERRED_COMPENSATION,
  ENTITY_529_COLLEGE_SAVINGS,
  ENTITY_LIFE_INSURANCE,
  ENTITY_OTHER,

  ENTITY_GENERAL
};

const ENTITY_ADD_CONTRIBUTION = "contribution";

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.ASSETS.SAVINGS.FORM.TYPES.SAVINGS_RETIREMENTS.LABEL": {
    title: "Savings & Retirement",
    ENTITIES: {
      ENTITY_401K,
      ENTITY_IRA,
      ENTITY_ANNUITY,
      ENTITY_DEFERRED_COMPENSATION,
      ENTITY_529_COLLEGE_SAVINGS,
      ENTITY_LIFE_INSURANCE,
      ENTITY_OTHER
    }
  }
  // "HOUSEHOLD.ASSETS.SAVINGS.FORM.TYPES.DELAYED.LABEL": {
  //   title: "Delayed tax (deferred)",
  //   ENTITIES: {
  //     ENTITY_401K,
  //     ENTITY_IRA,
  //     ENTITY_QUALIFIED_ANNUITY,
  //     ENTITY_DEFERRED_COMPENSATION,
  //     ENTITY_OTHER
  //   }
  // },
  // "HOUSEHOLD.ASSETS.SAVINGS.FORM.TYPES.FREE.LABEL": {
  //   title: "Tax free (advantage)",
  //   ENTITIES: {
  //     ENTITY_529_COLLEGE_SAVINGS,
  //     ENTITY_ROTH_IRA,
  //     ENTITY_UNQUALIFIED_ANNUITY,
  //     ENTITY_LIFE_INSURANCE
  //   }
  // }
};

export const ENTITIES_DEBT = {
  ENTITY_DEBT_IRA,
  ENTITY_DEBT_ANNUITY,
  ENTITY_DEBT_529_COLLEGE_SAVINGS,
  ENTITY_DEBT_LIFE_INSURANCE,
  ENTITY_DEBT_OTHER,
  ENTITY_DEBT_GENERAL
};

export const optionsInsuranceTypeValue = {
  TERM: "term",
  IUL: "indexUniversalLife",
  ELI: "employerLifeInsurance"
};

const optionsInsuranceType = [
  {
    name: "Employer Life Insurance",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.INSURANCE.TYPES.ELI.LABEL",
    value: optionsInsuranceTypeValue.ELI
  },
  {
    name: "Term",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.INSURANCE.TYPES.TERM.LABEL",
    value: optionsInsuranceTypeValue.TERM
  },
  {
    name: "Index Universal Life",
    nameI18n:
      "HOUSEHOLD.ASSETS.SAVINGS.FORM.INSURANCE.TYPES.INDEX_UNIVERSAL_LIFE.LABEL",
    value: optionsInsuranceTypeValue.IUL
  }
];

export const TypeAnnuitValues = {
  QUALIFIED: "qualified",
  UNQUALIFIED: "unqualified",
  ROTH: "ROTH"
};

const optionsTypeAnnuity = [
  {
    name: "Qualified Annuity",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUITY.TYPES.QUAL",
    // title: "Qualified Annuity",
    // titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUITY.TYPES.QUAL",
    value: TypeAnnuitValues.QUALIFIED
  },
  {
    name: "Unqualified Annuity",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUITY.TYPES.UNQUAL",
    // title: "Unqualified Annuity",
    // titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUITY.TYPES.UNQUAL",
    value: TypeAnnuitValues.UNQUALIFIED
  },
  {
    name: "ROTH",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUITY.TYPES.ROTH",
    value: TypeAnnuitValues.ROTH
  }
];

export const TypeIRAValues = {
  STANDARD: "standard",
  SEP: "sep",
  ROTH: "roth",
  SELF_DIRECTED: "selfDirected"
};

const optionsTypeIra = [
  {
    name: "Standard",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.IRA.TYPES.STANDARD",
    value: TypeIRAValues.STANDARD
  },
  {
    name: "ROTH",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.IRA.TYPES.ROTH",
    value: TypeIRAValues.ROTH
  },
  {
    name: "SEP",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.IRA.TYPES.SEP",
    value: TypeIRAValues.SEP
  },
  {
    name: "Self-Directed",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.IRA.TYPES.SELF_DIRECTED",
    value: TypeIRAValues.SELF_DIRECTED
  }
];

const optionsOwnerOptional = [
  {
    name: "You",
    value: "You"
  }
];

const repeatableSchemeGeneral = {
  description: {
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.DESCRIPTION.LABEL"
  },
  owner: {
    isAdditional: true,
    isFullWidth: true,

    isSelect: true,
    options: optionsOwnerOptional,
    optionsCustom: "custom-members-all",
    isShowNoneOption: true,
    label: "Owner",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.OWNER.LABEL"
  },
  value: {
    isMain: true,

    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Value",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.VALUE.LABEL"
  }
};

const repeatableSchemeDebt = {
  annualContribution: {
    isAdditional: true,

    isAmount: true,
    isNumber: true,
    label: "Annual Contribution",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ANNUAL_CONTRIB.LABEL"
  },
  fromAge: {
    formWidth: "50",
    isAdditional: true,
    calculate: { isFromAgeToAge: true, inner: "contribution" },
    labelI18nCard: "HOUSEHOLD.ASSETS.SAVINGS.FORM.FROM_AGE_TO_AGE.LABEL",
    labelCard: "From Age To Age",

    isNumber: true,
    min: 0,
    max: 120,
    label: "From Age",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.FROM_AGE.LABEL"
  },
  toAge: {
    formWidth: "50",
    isNumber: true,
    min: 0,
    max: 120,
    minFromValue: {
      field: "fromAge"
    },
    label: "To Age",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TO_AGE.LABEL"
  }
};

export const repeatableCommon = {
  taxCategory: {
    isAdditional: true,

    isSelect: true,
    options: optionsTaxCategory,
    label: "Tax Category",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_401K]: {
    icon: "IconRetirement",
    color: "green",
    title: "401K",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.401K"
  },
  [ENTITIES.ENTITY_IRA]: {
    icon: "IconRetirement",
    color: "green",
    title: "IRA",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.IRA"
  },

  [ENTITIES.ENTITY_ANNUITY]: {
    icon: "IconDeferredComp",
    color: "green",
    title: "Annuity",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.ANNUITY"
  },

  [ENTITIES.ENTITY_DEFERRED_COMPENSATION]: {
    icon: "IconSalaries",
    color: "green",
    title: "Deferred Compensation",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.DEFERRED_COMPENSATION",
    titleCard: "Deferred Comp",
    titleI18nCard: "HOUSEHOLD.ASSETS.SAVINGS.TILE.DEFERRED_COMP"
  },

  [ENTITIES.ENTITY_529_COLLEGE_SAVINGS]: {
    icon: "IconStudent",
    color: "green",
    title: "529 College Fund",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.529_COLLEGE"
  },

  [ENTITIES.ENTITY_LIFE_INSURANCE]: {
    icon: "IconUmbrella",
    color: "green",
    title: "Life Insurance",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.LIFE_INSURANCE"
  },

  [ENTITIES.ENTITY_OTHER]: {
    icon: "IconOther",
    color: "ui",
    title: "Other",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.OTHER"
  },

  [ENTITIES.ENTITY_GENERAL]: {
    icon: "IconGeneralSavings",
    color: "red",
    title: "Group of Assets",
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TILE.GROUP_OF_ASSETS"
  }
};

export const ENTITIES_ADDITIONAL = {
  [ENTITY_IRA]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      ...repeatableSchemeDebt
    }
  },
  [ENTITY_ANNUITY]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      ...repeatableSchemeDebt
    }
  },

  [ENTITY_529_COLLEGE_SAVINGS]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      ...repeatableSchemeDebt
    }
  },
  [ENTITY_LIFE_INSURANCE]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      annualContribution: {
        ...repeatableSchemeDebt.annualContribution
        // condition: [
        //   {
        //     field: "type",
        //     isFilled: true
        //   }
        // ]
      },
      fromAge: {
        ...repeatableSchemeDebt.fromAge,
        formWidth: "50",
        calculate: { isFromAgeToAge: true, inner: ENTITY_ADD_CONTRIBUTION },
        condition: [
          {
            field: "type",
            value: "indexUniversalLife"
            // isFilled: true
          }
        ]
      },
      toAge: {
        ...repeatableSchemeDebt.toAge,
        formWidth: "50",
        condition: [
          {
            field: "type",
            value: "indexUniversalLife"
            // isFilled: true
          }
        ]
      }
    }
  },
  [ENTITY_OTHER]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      ...repeatableSchemeDebt
    }
  },

  [ENTITY_GENERAL]: {
    [ENTITY_ADD_CONTRIBUTION]: {
      ...repeatableSchemeDebt
    }
  }
};

export const formScheme = {
  [ENTITIES.ENTITY_401K]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.401K.DESCRIPTION.LABEL"
    },
    owner: {
      ...repeatableSchemeGeneral.owner,
      optionsCustom: "custom-members-adult"
    },
    value: repeatableSchemeGeneral.value
  },
  [ENTITIES.ENTITY_IRA]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.IRA.DESCRIPTION.LABEL"
    },
    type: {
      isPrefixTitle: true,
      isFullWidth: true,

      isRequired: true,
      isSelect: true,
      options: optionsTypeIra,
      isShowNoneOption: true,
      label: "Type",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TYPE_IRA.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    value: repeatableSchemeGeneral.value
  },
  [ENTITIES.ENTITY_ANNUITY]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n:
        "HOUSEHOLD.ASSETS.SAVINGS.FORM.QUALIFIED_ANNUITY.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    //optionsTypeAnnuity
    type: {
      isTitle: true,
      isFullWidth: true,

      isRequired: true,
      isSelect: true,
      options: optionsTypeAnnuity,
      isShowNoneOption: true,
      label: "Type",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TYPE_ANNUITY.LABEL"
    },
    value: {
      ...repeatableSchemeGeneral.value,
      calculate: { isAnnuitySum: true },
      labelCard: "Value",
      labelI18nCard: "HOUSEHOLD.ASSETS.SAVINGS.FORM.VALUE.LABEL",

      label: "Principal Value",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.PRINCIPAL_VALUE.LABEL"
    },
    interestValue: {
      ...repeatableSchemeGeneral.value,
      isMain: false,

      condition: [
        {
          field: "value",
          isFilled: true
        }
      ],
      label: "Interest Value",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.INTEREST_VALUE.LABEL"
    }
  },
  [ENTITIES.ENTITY_DEFERRED_COMPENSATION]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n:
        "HOUSEHOLD.ASSETS.SAVINGS.FORM.DEFERRED_COMPENSATION.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    value: repeatableSchemeGeneral.value
  },

  [ENTITIES.ENTITY_529_COLLEGE_SAVINGS]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.529_COLLEGE.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    value: repeatableSchemeGeneral.value
  },

  [ENTITIES.ENTITY_LIFE_INSURANCE]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n:
        "HOUSEHOLD.ASSETS.SAVINGS.FORM.LIFE_INSURANCE.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    beneficiary: {
      isFullWidth: true,

      isSelect: true,
      options: optionsOwnerOptional,
      optionsCustom: "custom-members-all",
      label: "Beneficiary",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.BENEFICIARY.LABEL"
    },

    insuranceDate: {
      isRequired: true,
      isDate: true,
      label: "Issuance Date",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.ISSUANCE_DATE.LABEL"
    },

    type: {
      isMain: true,

      isRequired: true,
      isSelect: true,
      options: optionsInsuranceType,
      label: "Type",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.LIFE_INS.TYPE.LABEL"
    },
    deathBenefit: {
      isMain: true,

      condition: [
        {
          field: "type",
          isFilled: true
        }
      ],

      isRequired: true,
      isNumber: true,
      isAmount: true,
      label: "Death Benefit",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.DEATH_BENEFIT.LABEL"
    },
    term: {
      // calculate: { isYearsLeftPeriod: true, fieldDate: "startDate" },
      expiredCalculate: { isYearsLeftPeriod: true, fieldDate: "insuranceDate" },

      condition: [
        {
          field: "type",
          value: "term"
        }
      ],

      isRequired: true,
      isNumber: true,
      isInt: true,
      min: 0,
      after: commonPrefixAfter.YRS,

      label: "Term",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.LIFE_INS.TERM.LABEL"
    },
    cashValue: {
      isAdditionalCondition: {
        condition: [
          {
            field: "type",
            value: "indexUniversalLife"
          }
        ]
      },
      isRequired: true,
      isNumber: true,
      isAmount: true,
      condition: [
        {
          field: "type",
          value: "indexUniversalLife"
        }
      ],
      label: "Cash Value",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.LIFE_INS.CASH_VALUE.LABEL"
    }
  },
  [ENTITIES.ENTITY_OTHER]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.OTHER.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    value: repeatableSchemeGeneral.value,
    taxCategory: repeatableCommon.taxCategory
  },
  [ENTITIES.ENTITY_GENERAL]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.GENERAL.DESCRIPTION.LABEL"
    },
    owner: repeatableSchemeGeneral.owner,
    value: repeatableSchemeGeneral.value,
    taxCategory: repeatableCommon.taxCategory
  }
};

addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  GLOBAL_TYPE_USE_ASSETS
);
const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING",
        title: "Savings & Retirement"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.TITLE",
      title: "All of your saving, protection and retirement assets in one place"
    },
    caption: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.CAPTION",
      title: "Add or edit use assets you have"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.EMPTY",
      title: "You have no assets, \nplease add your first asset"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.H2",
      title: "Saving & Retirement"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "APP.ASSET.ADDED",
      title: "Asset Added"
    },
    assetRemoved: {
      titleI18n: "APP.ASSET.REMOVED",
      title: "Asset Removed"
    },
    assetSaved: {
      titleI18n: "APP.SUCCESSFULLY_SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.SIDE_MENU_TITLE",
      title: "Add Assets"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.SIDE_MENU_TITLE.EDIT",
      title: "Edit Asset"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM_GROUP.TITLE",
      title: "Asset Type"
    },
    generalAsset: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.ASSET_TYPES.GENERAL_GROUP",
      title: "Group Of Assets",
      caption:
        "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Asset”",
      captionI18n: "HOUSEHOLD.ASSETS.SAVINGS.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.VALUE.BUTTON.SAVE",
      title: "Save Asset"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.VALUE.BUTTON",
      title: "+ Add Asset"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.ASSETS.SAVINGS.WHY.TITLE",
    textI18n: "HOUSEHOLD.ASSETS.SAVINGS.WHY.ALL_TEXT"
  }
};
