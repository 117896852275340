import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl-v3";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import FormControlInput from "../_common/FormControl/FormControlInput";
import ButtonCommon from "../_common/Button/ButtonCommon";
import * as Icons from "../_common/icons";
import { formatMoneyInt, formatMoneyIntWithSign } from "../../lib/helpers";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlSelect from "../_common/FormControl/FormControlSelect";
import { DataWithBar } from "../Dashboard/Statistic";
import { withStyles } from "@material-ui/core";
import TooltipMUI from "@material-ui/core/Tooltip/Tooltip";
import Hidden from "@material-ui/core/Hidden";

const styles = () => ({
  inputAdvisor: {
    marginBottom: 0,

    "& input": {
      fontSize: 14,
      padding: "1px 0 2px"
    }
  }
});
const optionsYesNoMaybe = [
  {
    name: "Yes",
    nameI18n: "MY_ADVISOR.STAT.YES.LABEL",
    value: "yes"
  },
  {
    name: "No",
    nameI18n: "MY_ADVISOR.STAT.NO.LABEL",
    value: "no"
  },
  {
    name: "Maybe",
    nameI18n: "MY_ADVISOR.STAT.MAYBE.LABEL",
    value: "maybe"
  }
];

class DashboardAdvisorCheckList extends Component {
  state = {
    expanded: "panel1"
  };

  expandPanel = panel => () => {
    this.setState({
      expanded: panel
    });
  };

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleClickChangePanel = panel => (event) => {
    this.setState({
      expanded: panel ? panel : false
    });
  };

  _renderItemLifeInsurance = () => {
    const { classes } = this.props;

    const formState = this.props.forms.lifeInsurance;
    const formData = this.props.data.lifeInsurance;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel1"}
        onChange={this.handleChangePanel("panel1")}
        style={{ marginTop: 24 }}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.TITLE"
              defaultMessage="Life Insurance"
            />
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.CAPTION_1"
                  defaultMessage="Life insurance can protect families against an unexpected turn of events. To ensure proper coverage, you need to factor in significant debt, annual income and their different savings needs."
                />
              </p>
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.CAPTION_2"
                  defaultMessage="Death benefit = Mortgage + Other debt + 10yrs*Annual income + High education*#kids*Inflation until thev attend"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "lifeInsurance"
                  )}
                  value={formState.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel2")}
                  style={{ paddingLeft: 24, paddingRigh: 24 }}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("lifeInsurance")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={5}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.USER_PROP"
                        defaultMessage="User Parameters"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={4} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                          defaultMessage="System"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={4} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                          defaultMessage="Advisor"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.MORTGAGE"
                        defaultMessage="Mortgage"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userMortgage)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userMortgage"
                        value={formState.userMortgage}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.OTHER_DEBT"
                        defaultMessage="Other debt"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userOtherDebt)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userOtherDebt"
                        value={formState.userOtherDebt}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.HIGH_EDUCATION"
                        defaultMessage="High education needs"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userHighEducation)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userHighEducation"
                        value={formState.userHighEducation}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INCOME"
                        defaultMessage="Annual income"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.userAnnualIncome)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userAnnualIncome"
                        value={formState.userAnnualIncome}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %&nbsp;
                      {formData.userAnnualInflation}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="userAnnualInflation"
                        value={formState.userAnnualInflation}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.userRecommendedAmount)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formState.userRecommendedAmount)}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.EXISTING_INSURANCE"
                        defaultMessage="Existing insurance"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.userExistingInsurance)}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="userOpp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.lifeInsurance.userOpp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SPOUSE_PROP"
                        defaultMessage="Spouse Parameters"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={4} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                          defaultMessage="System"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={4} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                          defaultMessage="Advisor"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.MORTGAGE"
                        defaultMessage="Mortgage"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseMortgage)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseMortgage"
                        value={formState.spouseMortgage}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.OTHER_DEBT"
                        defaultMessage="Other debt"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseOtherDebt)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseOtherDebt"
                        value={formState.spouseOtherDebt}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.HIGH_EDUCATION"
                        defaultMessage="High education needs"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseHighEducation)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseHighEducation"
                        value={formState.spouseHighEducation}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INCOME"
                        defaultMessage="Annual income"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.spouseAnnualIncome)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseAnnualIncome"
                        value={formState.spouseAnnualIncome}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %&nbsp;
                      {formData.spouseAnnualInflation}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="spouseAnnualInflation"
                        value={formState.spouseAnnualInflation}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.spouseRecommendedAmount)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(
                        formState.spouseRecommendedAmount
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.EXISTING_INSURANCE"
                        defaultMessage="Existing insurance"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                      {formatMoneyIntWithSign(formData.spouseExistingInsurance)}
                    </div>
                  </Grid>
                </Grid>

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="spouseOpp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.lifeInsurance.spouseOpp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "lifeInsurance"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel2")}
                  style={{ paddingLeft: 24, paddingRigh: 24 }}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("lifeInsurance")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemCashDebt = () => {
    const { classes, firstYear, data } = this.props;

    const totalCash =
      data && firstYear
        ? data.products.other.all +
          firstYear.Assets.cash +
          firstYear.AssetsDetail.retirement.IRA +
          firstYear.AssetsDetail.retirement["401K"] +
          firstYear.AssetsDetail.retirement.deferredComp +
          firstYear.AssetsDetail.retirement.annuity +
          firstYear.TempValues.investments.businessInterest +
          firstYear.AssetsDetail.investments.treasuries +
          firstYear.AssetsDetail.investments.mutualFunds +
          firstYear.AssetsDetail.investments.stocks
        : 0;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel2"}
        onChange={this.handleChangePanel("panel2")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TITLE"
              defaultMessage="Cash and debt distribution"
            />
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              lg={4}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel("cashDebt")}
                  value={this.props.forms.cashDebt.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  buttonSmallAdvisor
                  white
                  onClick={this.expandPanel("panel1")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  buttonSmallAdvisor
                  white
                  onClick={this.expandPanel("panel3")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("cashDebt")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={4}
              spacing={0}
              className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li recommendations__item-right-wrap"
            >
              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CASH_DISTRIBUTION"
                      defaultMessage="Cash distribution"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.AMOUNT"
                      defaultMessage="Amount"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CHECKING_SAVINGS"
                      defaultMessage="Checking/Savings"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.accounts.checking +
                            firstYear.TempValues.accounts.savings
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MONEY_MARKET_CD"
                      defaultMessage="Money market/CD"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.accounts.moneyMarket +
                            firstYear.TempValues.accounts.cd
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.INVESTMENTS"
                      defaultMessage="Investments"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.TempValues.investments.businessInterest +
                            firstYear.AssetsDetail.investments.treasuries +
                            firstYear.AssetsDetail.investments.mutualFunds +
                            firstYear.AssetsDetail.investments.stocks
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.RETIREMENT"
                      defaultMessage="Retirement"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.AssetsDetail.retirement.IRA +
                            firstYear.AssetsDetail.retirement["401K"] +
                            firstYear.AssetsDetail.retirement.deferredComp +
                            firstYear.AssetsDetail.retirement.annuity
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.PROTECTION"
                      defaultMessage="Protection"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.AssetsDetail.cash.lifeInsurance : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.OTHER"
                      defaultMessage="Other"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(data ? data.products.other.all : 0)}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TOTAL_CASH"
                      defaultMessage="Total cash"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(totalCash)}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems="center"
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                      defaultMessage="Opportunity"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={2}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormControlSelect
                      options={optionsYesNoMaybe}
                      fieldName="cashOpp"
                      classNameExtra={classes.inputAdvisor}
                      value={this.props.forms.cashDebt.cashOpp || ""}
                      handleChange={this.props.handleInputChangePanel(
                        "cashDebt"
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={4}
              spacing={0}
              className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li recommendations__item-right--right recommendations__item-right-wrap"
            >
              <Grid container item xs={12} lg={12} alignItems={"center"}>
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.DEBT_DISTRIBUTION"
                      defaultMessage="Debt distribution"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-title text-upper">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.AMOUNT"
                      defaultMessage="Amount"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MORTGAGE"
                      defaultMessage="Mortgage"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.home : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.STUDENT_LOANS"
                      defaultMessage="Student loans"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.student : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.CREDITCARD"
                      defaultMessage="Creditcard"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.creditCard : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.MEDICAL"
                      defaultMessage="Medical"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.medical : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.OTHER"
                      defaultMessage="Other"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear ? firstYear.TempValues.debt.other : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                className="recommendations__form-item-wrap"
                container
                item
                xs={12}
                lg={12}
                alignItems={"center"}
              >
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TOTAL_DEBT"
                      defaultMessage="Total debt"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {formatMoneyIntWithSign(
                      firstYear
                        ? firstYear.Liabilities.mortgage +
                            firstYear.Liabilities.loans
                        : 0
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={12} alignItems="center">
                <Grid item xs={6} lg={6}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                      defaultMessage="Opportunity"
                    />
                  </div>
                </Grid>

                <Grid item xs={6} lg={2}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    <FormControlSelect
                      options={optionsYesNoMaybe}
                      fieldName="debtOpp"
                      classNameExtra={classes.inputAdvisor}
                      value={this.props.forms.cashDebt.debtOpp || ""}
                      handleChange={this.props.handleInputChangePanel(
                        "cashDebt"
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  buttonSmallAdvisor
                  white
                  onClick={this.expandPanel("panel1")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  buttonSmallAdvisor
                  white
                  onClick={this.expandPanel("panel3")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("cashDebt")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemRealEstate = () => {
    const { classes, firstYear, data } = this.props;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel3"}
        onChange={this.handleChangePanel("panel3")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TITLE"
              defaultMessage="Real estate ownership"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={5}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel("realEstate")}
                  value={this.props.forms.realEstate.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel2")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel4")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("realEstate")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={7}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.OWNED_ASSETS"
                        defaultMessage="Owned assets"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                          defaultMessage="Value"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                          defaultMessage="Debt"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                          defaultMessage="Income/YR"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.PRIMARY_RESIDENCE"
                        defaultMessage="Primary residence"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                            defaultMessage="Value"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.primary)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                            defaultMessage="Debt"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.primary)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                            defaultMessage="Income/YR"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VACATION"
                        defaultMessage="Vacation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                            defaultMessage="Value"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.vacation)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                            defaultMessage="Debt"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.vacation)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                            defaultMessage="Income/YR"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INVESTMENTS"
                        defaultMessage="Investments"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                            defaultMessage="Value"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.investment)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                            defaultMessage="Debt"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.investment)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                            defaultMessage="Income/YR"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.OTHER"
                        defaultMessage="Other"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                            defaultMessage="Value"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.other)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                            defaultMessage="Debt"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.other)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                            defaultMessage="Income/YR"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.VALUE"
                            defaultMessage="Value"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.home.all)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.DEBT"
                            defaultMessage="Debt"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(data.assets.debt.all)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INCOME_YR"
                            defaultMessage="Income/YR"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="ownedOpp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.realEstate.ownedOpp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "realEstate"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--top recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={6} lg={6}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.RENTED_ASSETS"
                        defaultMessage="Rented assets"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"
                        defaultMessage="Annual rent"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems={"center"}
                >
                  <Grid item xs={6} lg={6}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"
                        defaultMessage="Annual rent"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <div className="recommendations__form-item recommendations__form-item--primary recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.realEstate : 0
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={6}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="rentedOpp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.realEstate.rentedOpp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "realEstate"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.ANNUAL_RENT"*/}
                {/*        defaultMessage="Annual rent"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormControlSelect*/}
                {/*        bgSecondary*/}
                {/*        options={optionsYesNoMaybe}*/}
                {/*        fieldName="rentedVacation"*/}
                {/*        hideNoneOption*/}
                {/*        value={*/}
                {/*          this.props.forms.realEstate.rentedVacation || "no"*/}
                {/*        }*/}
                {/*        handleChange={this.props.handleInputChangePanel("realEstate")}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.INVESTMENTS"*/}
                {/*        defaultMessage="Investments"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor">*/}
                {/*      <FormControlSelect*/}
                {/*        bgSecondary*/}
                {/*        options={optionsYesNoMaybe}*/}
                {/*        fieldName="rentedInvestments"*/}
                {/*        hideNoneOption*/}
                {/*        value={*/}
                {/*          this.props.forms.realEstate.rentedInvestments || "yes"*/}
                {/*        }*/}
                {/*        handleChange={this.props.handleInputChangePanel("realEstate")}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/*<Grid container item xs={12} lg={12}>*/}
                {/*  <Grid item xs={12} lg={4}>*/}
                {/*    <div className="recommendations__form-item recommendations__form-item--advisor recommendations__form-item--primary">*/}
                {/*      <FormattedMessage*/}
                {/*        id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TOTAL"*/}
                {/*        defaultMessage="Total"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </Grid>*/}

                {/*  <Grid item xs={12} lg={4} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}

                {/*  <Grid item xs={12} lg={2} />*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel2")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel4")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("realEstate")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemHigheducationNeeds = () => {
    const { classes, data, members } = this.props;

    let total = 0;

    const rows = Object.keys(members).map(memberKey => {
      const member = members[memberKey];

      if (memberKey === "Spouse" || memberKey === "You" || member.age > 18) {
        return null;
      }

      const inState1 = 4 * 10000 * Math.pow(1.08, 18 - member.age);
      const inState2 = 4 * 22000 * Math.pow(1.08, 18 - member.age);
      const inState3 = 4 * 35000 * Math.pow(1.08, 18 - member.age);

      const titleInState = (
        <FormattedMessage
          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.IN_STATE"
          defaultMessage="In-State"
        />
      );
      const titleOutOfState = (
        <FormattedMessage
          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.OUT_OF_STATE"
          defaultMessage="Out-of-State"
        />
      );
      const titlePrivateTuition = (
        <FormattedMessage
          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.PRIVATE_TUITION"
          defaultMessage="Private Tuition"
        />
      );

      total += data.assets["529college"][memberKey] || 0;

      return (
        <Grid
          container
          item
          xs={12}
          lg={12}
          key={memberKey}
          alignItems="center"
          className="recommendations__form-item-wrap"
        >
          <Grid item xs={12} lg={4}>
            <div className="recommendations__form-item recommendations__form-item--advisor">
              {member.childName}
            </div>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Hidden mdUp>
              <Grid item xs={12}>
                <div className="recommendations__form-title recommendations__form-title-subtitle">
                  <FormattedMessage
                    id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.AGE"
                    defaultMessage="Age"
                  />
                </div>
              </Grid>
            </Hidden>

            <div className="recommendations__form-item  recommendations__form-item--advisor">
              {member.age}
            </div>
          </Grid>{" "}
          <Grid item xs={12} lg={3}>
            <Hidden mdUp>
              <Grid item xs={12}>
                <div className="recommendations__form-title recommendations__form-title-subtitle">
                  <FormattedMessage
                    id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.IN_STATE_ETC"
                    defaultMessage="In-State/Out-of-State/Private Tuition"
                  />
                </div>
              </Grid>
            </Hidden>

            <div className="recommendations__form-item  recommendations__form-item--advisor">
              <TooltipMUI
                title={titleInState}
                placement="top"
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
              >
                <span>{formatMoneyIntWithSign(inState1)}</span>
              </TooltipMUI>{" "}
              /{" "}
              <TooltipMUI
                title={titleOutOfState}
                placement="top"
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
              >
                <span>{formatMoneyIntWithSign(inState2)}</span>
              </TooltipMUI>{" "}
              /{" "}
              <TooltipMUI
                title={titlePrivateTuition}
                placement="top"
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
              >
                <span>{formatMoneyIntWithSign(inState3)}</span>
              </TooltipMUI>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Hidden mdUp>
              <Grid item xs={12}>
                <div className="recommendations__form-title recommendations__form-title-subtitle">
                  <FormattedMessage
                    id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.COLLEGE_SAVINGS"
                    defaultMessage="College savings"
                  />
                </div>
              </Grid>
            </Hidden>

            <div className="recommendations__form-item  recommendations__form-item--advisor">
              {formatMoneyIntWithSign(
                data.assets["529college"][memberKey] || 0
              )}
            </div>
          </Grid>
        </Grid>
      );
    });

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel4"}
        onChange={this.handleChangePanel("panel4")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.TITLE"
              defaultMessage="High Education needs"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={5}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "higheducationNeeds"
                  )}
                  value={this.props.forms.higheducationNeeds.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel3")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel5")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("higheducationNeeds")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={7}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.MEMBER"
                        defaultMessage="Member"
                      />
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item xs={12} lg={2}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.AGE"
                          defaultMessage="Age"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.IN_STATE_ETC"
                          defaultMessage="In-State/Out-of-State/Private Tuition"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.COLLEGE_SAVINGS"
                          defaultMessage="College savings"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                {rows}

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={6} />

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.COLLEGE_SAVINGS"
                            defaultMessage="College savings"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary">
                      {formatMoneyIntWithSign(total)}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="advisor"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.props.forms.higheducationNeeds.advisor || ""
                        }
                        handleChange={this.props.handleInputChangePanel(
                          "higheducationNeeds"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel3")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel5")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("higheducationNeeds")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemEmergencyFund = () => {
    const { classes, data, firstYear } = this.props;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel5"}
        onChange={this.handleChangePanel("panel5")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.TITLE"
              defaultMessage="Emergency fund"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "emergencyFund"
                  )}
                  value={this.props.forms.emergencyFund.notes}
                />
              </div>

              <Hidden mdDown>
                <Grid
                  item
                  xs={12}
                  className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
                >
                  <ButtonCommon
                    white
                    buttonSmallAdvisor
                    onClick={this.expandPanel("panel4")}
                  >
                    <Icons.IconArrowLeft
                      color="textTetrieary"
                      width={18}
                      height={18}
                    />
                    <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                  </ButtonCommon>

                  <ButtonCommon
                    white
                    buttonSmallAdvisor
                    onClick={this.expandPanel("panel6")}
                  >
                    <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                    <Icons.IconArrowRight
                      color="textTetrieary"
                      width={18}
                      height={18}
                    />
                  </ButtonCommon>

                  <ButtonCommon
                    yellow
                    onClick={this.props.handleSaveSection("emergencyFund")}
                  >
                    <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                  </ButtonCommon>
                </Grid>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={5}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.PROP"
                        defaultMessage="Parameter"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                          defaultMessage="System"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.ADVISOR"
                          defaultMessage="Advisor"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.MONTHLY_INCOME"
                        defaultMessage="Monthly income"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(
                        firstYear ? firstYear.MoneyIn.all / 12 : 0
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="monthlyIncome"
                        value={this.props.forms.emergencyFund.monthlyIncome}
                        handleChange={this.props.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EMERGENCY_MULTIPLE"
                        defaultMessage="Emergency multiple"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {data.emergencyFund.emergencyMultiple}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlInput
                        bgSecondary
                        isInt
                        classNameExtra={classes.inputAdvisor}
                        fieldName="emergencyMultiple"
                        value={this.props.forms.emergencyFund.emergencyMultiple}
                        handleChange={this.props.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={12}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EMERGENCY_FUND_MONTHLY"
                        defaultMessage="Emergency fund = Monthly income*Emergency multiple"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.RECOMMENDED_AMOUNT"
                        defaultMessage="Recommended amount"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary">
                      {formatMoneyIntWithSign(
                        data.emergencyFund.recommendedAmount
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div
                      className="recommendations__form-item recommendations__form-item--advisor"
                      style={{ marginBottom: 16 }}
                    >
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="recommendedAmount"
                        value={this.props.forms.emergencyFund.recommendedAmount}
                        handleChange={this.props.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.EXISTING_FUND"
                        defaultMessage="Possible existing fund"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(data.emergencyFund.existingFund)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4} />
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3} />
                  </Hidden>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.emergencyFund.opp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "emergencyFund"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel4")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel6")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("emergencyFund")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemTaxAsset = () => {
    const { classes, firstYear } = this.props;

    const { all, ...restData } = firstYear ? firstYear.TaxCategories : {};

    const transKey = "DASHBOARD.STATISTIC.LABEL.ASSETS_TAX_CATEGORIES.";

    const taxablePercent =
      restData && restData.taxable
        ? parseInt((restData.taxable / all) * 100)
        : 0;
    const taxable = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxable"
            percent={taxablePercent}
          />
        </Grid>
      </Grid>
    );

    const deferredPercent =
      restData && restData.taxDeferred
        ? parseInt((restData.taxDeferred / all) * 100)
        : 0;
    const deferred = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxDeferred"
            percent={deferredPercent}
          />
        </Grid>
      </Grid>
    );

    const advantagePercent =
      restData && restData.taxAdvantage
        ? parseInt((restData.taxAdvantage / all) * 100)
        : 0;
    const advantage = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            data={restData}
            transKey={transKey}
            entityKey="taxAdvantage"
            percent={advantagePercent}
          />
        </Grid>
      </Grid>
    );

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel6"}
        onChange={this.handleChangePanel("panel6")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.TITLE"
              defaultMessage="Tax asset allocation"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel("taxAsset")}
                  value={this.props.forms.taxAsset.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel5")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel7")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("taxAsset")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li recommendations__item-right-wrap"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={12}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.ASSET_CATEGORY_DISTRIBUTION"
                        defaultMessage="Asset category distribution"
                      />
                    </div>
                  </Grid>
                </Grid>

                {taxable}

                {deferred}

                {advantage}

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={6} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.taxAsset.opp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "taxAsset"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel5")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>

                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel7")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>

                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("taxAsset")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemInvestmentDiversification = () => {
    const { classes, firstYear } = this.props;

    const transKey = "DASHBOARD.STATISTIC.LABEL.ASSETS_USE.";

    const all = firstYear ? firstYear.AssetsDetail.use.all : 0;
    const homesValue = firstYear ? firstYear.AssetsDetail.use.home : 0;

    // investments ?
    const otherValue = firstYear
      ? firstYear.AssetsDetail.use.all -
        homesValue +
        firstYear.AssetsDetail.investments.all
      : 0;

    const homesPercent = all ? parseInt((homesValue / all) * 100) : 0;
    const otherPercent = all ? parseInt((otherValue / all) * 100) : 0;

    const dataAll = { homeUse: homesValue, otherUse: otherValue };

    const homeEl = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            transKey={transKey}
            data={dataAll}
            labelI18n="DASHBOARD.STATISTIC.LABEL.ASSETS_USE.home"
            entityKey="homeUse"
            value={homesValue}
            percent={homesPercent}
          />
        </Grid>
      </Grid>
    );

    const otherEl = (
      <Grid
        container
        item
        xs={12}
        lg={12}
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={12} lg={12}>
          <DataWithBar
            transKey={transKey}
            labelI18n="DASHBOARD.STATISTIC.LABEL.ASSETS_USE.other"
            data={dataAll}
            entityKey="otherUse"
            value={otherValue}
            percent={otherPercent}
          />
        </Grid>
      </Grid>
    );

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel7"}
        onChange={this.handleChangePanel("panel7")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.TITLE"
              defaultMessage="Investment diversification"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={7}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "investmentDiversification"
                  )}
                  value={this.props.forms.investmentDiversification.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel6")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel8")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection(
                    "investmentDiversification"
                  )}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid container item xs={12} lg={5}>
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li recommendations__item-right-wrap"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={7}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.REAL_ESTATE_STOCK"
                        defaultMessage="Real estate; stock market ratios"
                      />
                    </div>
                  </Grid>
                </Grid>

                {homeEl}

                {otherEl}

                <Grid container item xs={12} lg={12} alignItems="center">
                  <Grid item xs={6} lg={3}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.props.forms.investmentDiversification.opp || ""
                        }
                        handleChange={this.props.handleInputChangePanel(
                          "investmentDiversification"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel6")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel8")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection(
                    "investmentDiversification"
                  )}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderItemMajorIncome = () => {
    const { classes, data } = this.props;

    let total = 0;

    const eventsRows =
      data && data.events
        ? data.events.map(event => {
            if (!event.value) {
              return null;
            }

            total += event.value;

            return (
              <Grid
                container
                item
                xs={12}
                lg={12}
                key={`${event.id}-${event.year}`}
                alignItems="center"
                className="recommendations__form-item-wrap"
              >
                <Grid item xs={12} lg={4}>
                  <div className="recommendations__form-item recommendations__form-item--advisor">
                    {event.description}
                  </div>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Hidden mdUp>
                    <Grid item xs={12}>
                      <div className="recommendations__form-title recommendations__form-title-subtitle">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.AMOUNT"
                          defaultMessage="Amount"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <div className="recommendations__form-item">
                    {formatMoneyIntWithSign(event.value)}
                  </div>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Hidden mdUp>
                    <Grid item xs={12}>
                      <div className="recommendations__form-title recommendations__form-title-subtitle">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.YEARS"
                          defaultMessage="Years"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <div className="recommendations__form-item">
                    {event.yearsUntilOccurs}
                  </div>
                </Grid>
              </Grid>
            );
          })
        : null;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel8"}
        onChange={this.handleChangePanel("panel8")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.TITLE"
              defaultMessage="Major income events in the near future"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={6}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations__notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  InputLabelProps={{ shrink: true }}
                  label={"Notes"}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "majorIncome"
                  )}
                  value={this.props.forms.majorIncome.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel7")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel9")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("majorIncome")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={6}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.EVENT"
                        defaultMessage="Event"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.AMOUNT"
                          defaultMessage="Amount"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.YEARS"
                          defaultMessage="Years"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                {eventsRows}

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--primary">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.TOTAL"
                        defaultMessage="Total"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.AMOUNT"
                            defaultMessage="Amount"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--primary">
                      {formatMoneyIntWithSign(total)}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} />
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={4}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="opp"
                        classNameExtra={classes.inputAdvisor}
                        value={this.props.forms.majorIncome.opp || ""}
                        handleChange={this.props.handleInputChangePanel(
                          "majorIncome"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel7")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel9")}
                >
                  <FormattedMessage id="APP.NEXT" defaultMessage="Next" />
                  <Icons.IconArrowRight
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection("majorIncome")}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  _renderFinancialIndependence = () => {
    const { classes } = this.props;

    const formState = this.props.forms.financialIndependence;
    const formData = this.props.data.financialIndependence;

    return (
      <ExpansionPanel
        className="recommendations__item recommendations__item--advisor"
        square
        expanded={this.state.expanded === "panel9"}
        onChange={this.handleChangePanel("panel9")}
      >
        <ExpansionPanelSummary className="recommendations__item-title">
          <Typography>
            <FormattedMessage
              id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.TITLE"
              defaultMessage="Financial independence planning"
            />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={40}>
            <Grid
              item
              xs={12}
              lg={6}
              className="recommendations__item-left recommendations__item-left--li"
            >
              <p>
                <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.CAPTION_1"
                  defaultMessage="It is recommended that families keep life insurance policies against an unexpected turn of events"
                />
              </p>

              <div className="recommendations __notes">
                <FormControlInput
                  fieldName="notes"
                  multiline
                  multilineRows={6}
                  label={"Notes"}
                  InputLabelProps={{
                    shrink: !!formState.notes
                  }}
                  labelI18n={"MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.NOTES"}
                  handleChange={this.props.handleInputChangePanel(
                    "financialIndependence"
                  )}
                  value={formState.notes}
                />
              </div>

              <Hidden mdDown>
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel8")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection(
                    "financialIndependence"
                  )}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Hidden>
            </Grid>

            <Grid
              container
              item
              xs={12}
              lg={6}
              className="recommendations__item-right-wrap"
            >
              <Grid
                item
                lg={12}
                container
                spacing={0}
                className="recommendations__item-right recommendations__item-right--advisor recommendations__item-right--li"
              >
                <Grid container item xs={12} lg={12}>
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-title text-upper">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.PROP"
                        defaultMessage="Parameter"
                      />
                    </div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={3}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                          defaultMessage="System"
                        />
                      </div>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item xs={12} lg={4}>
                      <div className="recommendations__form-title text-upper">
                        <FormattedMessage
                          id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                          defaultMessage="Advisor"
                        />
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.LIFE_EXPECTACY"
                        defaultMessage="Life expectacy"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.lifeExpectancy}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isInt
                        fieldName="lifeExpectancy"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.props.forms.financialIndependence.lifeExpectancy
                        }
                        handleChange={this.props.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ANNUAL_INFLATION"
                        defaultMessage="Annual inflation %"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      %{` `}
                      {formData.annualInflation}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item">
                      <FormControlInput
                        bgSecondary
                        isAmount
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          )
                        }}
                        classNameExtra={classes.inputAdvisor}
                        fieldName="annualInflation"
                        value={
                          this.props.forms.financialIndependence.annualInflation
                        }
                        handleChange={this.props.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ESTIMATED_LIFETIME_NEEDS"
                        defaultMessage="Estimated lifetime needs (PV)"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.estimatedLifetime)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(formState.estimatedLifetime)}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.CURRENT_MONETARY"
                        defaultMessage="Current monetary assets"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.estimatedLifetimeNet)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item">
                      {formatMoneyIntWithSign(formState.estimatedLifetimeNet)}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.GAP_FROM_INDEPENDENCE"
                        defaultMessage="Gap from independence"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formData.gapToIndependence)}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formatMoneyIntWithSign(formState.gapToIndependence)}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.CALC_IND_AGE"
                        defaultMessage="Calculated independence age"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.calculatedIndependenceAge || (
                        <FormattedMessage
                          id="DASHBOARD.MAIN_TILES.RETIREMENT.NEVER"
                          defaultMessage="Never"
                        />
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.DESIRED_IND_AGE"
                        defaultMessage="Desired independence age"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.desiredIndependenceAge}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                >
                  <Grid item xs={12} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.AGE_OF_FULL_DEPLETION"
                        defaultMessage="Age of full depletion"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.ADVISOR"
                            defaultMessage="Advisor"
                          />
                        </div>
                      </Grid>
                    </Hidden>

                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      {formData.ageFullDepletion || (
                        <FormattedMessage
                          id="DASHBOARD.MAIN_TILES.RETIREMENT.NEVER"
                          defaultMessage="Never"
                        />
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={6} lg={4}>
                    <Hidden mdUp>
                      <Grid item xs={12}>
                        <div className="recommendations__form-title recommendations__form-title-subtitle">
                          <FormattedMessage
                            id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.SYSTEM"
                            defaultMessage="System"
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  className="recommendations__form-item-wrap"
                  container
                  item
                  xs={12}
                  lg={12}
                  alignItems="center"
                >
                  <Grid item xs={6} lg={5}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormattedMessage
                        id="MY_ADVISOR.CHECK_LIST.OPPORTUNITY"
                        defaultMessage="Opportunity"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={false} lg={3} />

                  <Grid item xs={6} lg={2}>
                    <div className="recommendations__form-item recommendations__form-item--advisor">
                      <FormControlSelect
                        options={optionsYesNoMaybe}
                        fieldName="opportunity"
                        classNameExtra={classes.inputAdvisor}
                        value={
                          this.props.forms.financialIndependence.opportunity ||
                          "no"
                        }
                        handleChange={this.props.handleInputChangePanel(
                          "financialIndependence"
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="recommendations__item-left recommendations__item-left--li recommendations__item-buttons"
              >
                <ButtonCommon
                  white
                  buttonSmallAdvisor
                  onClick={this.expandPanel("panel8")}
                >
                  <Icons.IconArrowLeft
                    color="textTetrieary"
                    width={18}
                    height={18}
                  />
                  <FormattedMessage id="APP.BACK" defaultMessage="Back" />
                </ButtonCommon>
                <ButtonCommon
                  yellow
                  onClick={this.props.handleSaveSection(
                    "financialIndependence"
                  )}
                >
                  <FormattedMessage id="APP.SAVE" defaultMessage="Save" />
                </ButtonCommon>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <div className="recommendations recommendations-checklist">
        <div className="recommendations__wrap">
          <div className="recommendations-head">
            <h2 className="recommendations__title">
              <FormattedMessage
                  id="MY_ADVISOR.CHECK_LIST.TITLE2"
                  defaultMessage="Advisor Checklist"
              />
            </h2>

            <div className="recommendations-toggle-list">
              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel1'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel('panel1')}>
                <FormattedMessage
                    id="MY_ADVISOR.CHECK_LIST.LIFE_INSURANCE.TITLE"
                    defaultMessage="Life Insurance"
                />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel2'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel2")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.CASH_DEBT.TITLE"
                      defaultMessage="Cash and debt distribution"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel3'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel3")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.REAL_ESTATE.TITLE"
                      defaultMessage="Real estate ownership"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel4'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel4")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.HIGHEDUCATION_NEEDS.TITLE"
                      defaultMessage="High Education needs"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel5'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel5")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.EMERGENCY_FUND.TITLE"
                      defaultMessage="Emergency fund"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel6'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel6")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.TAX_ASSET.TITLE"
                      defaultMessage="Tax asset allocation"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel7'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel7")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.INVESTMENT_DIVERSIFICATION.TITLE2"
                      defaultMessage="Investment diversification"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel8'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel8")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.MAJOR_INCOME.TITLE2"
                      defaultMessage="Major future income events"
                  />
              </div>

              <div className={`recommendations-toggle-list__item ${
                  this.state.expanded === 'panel9'
                      ? 'recommendations-toggle-list__item--active'
                      : ''}
                `}
                   onClick={this.handleClickChangePanel("panel9")}>
                  <FormattedMessage
                      id="MY_ADVISOR.CHECK_LIST.FINANCIAL_INDEPENDENCE.TITLE2"
                      defaultMessage="Financial independence"
                  />
              </div>
            </div>
          </div>

          <div className="recommendations__collapse">
            {this._renderItemLifeInsurance()}
            {this._renderItemCashDebt()}
            {this._renderItemRealEstate()}
            {this._renderItemHigheducationNeeds()}
            {this._renderItemEmergencyFund()}
            {this._renderItemTaxAsset()}
            {this._renderItemInvestmentDiversification()}
            {this._renderItemMajorIncome()}
            {this._renderFinancialIndependence()}
          </div>
        </div>
      </div>
    );
  }
}

DashboardAdvisorCheckList.propTypes = {
  forms: PropTypes.any,
  data: PropTypes.any,
  firstYear: PropTypes.any,
  members: PropTypes.any,
  handleSaveSection: PropTypes.any,
  handleInputChangePanel: PropTypes.any
};

export default withStyles(styles)(DashboardAdvisorCheckList);
