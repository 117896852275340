import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconPlusNew = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1,
  strokeWidth = 0,
  stroke = "#FFF"
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
        fill={fill}
        opacity={1}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeOpacity={1}
      />
    </svg>
  );
};

IconPlusNew.propTypes = {
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconPlusNew;
