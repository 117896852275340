import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconArrowUp = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 6L5 2L1 6" stroke={fill} strokeWidth="2" />
    </svg>
  );
};

IconArrowUp.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconArrowUp;
