import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";

const ButtonStyled = ({
  classes,
  style,
  block,
  disabled,
  fullWidth,
  handleClick,
  onClick,
  children,
  type = "button"
}) => {
  return (
    <Button
      className={`${classes.button} ${classes.buttonAdd}`}
      style={style || {}}
      disabled={disabled}
      onClick={handleClick || onClick}
      fullWidth={block || fullWidth}
      type={type}
    >
      {children}
    </Button>
  );
};

ButtonStyled.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.string
};

export default withStyles(styles)(ButtonStyled);
