import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import * as Icons from "components/_common/icons";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine
} from "recharts";
import { FormattedMessage } from "react-intl-v3";
import Button from "../_common/Button/ButtonCommon";
import { chartsMeta } from "./chartMeta";
import { formatMoneyIntWithSign } from "../../lib/helpers";

import { highlightedYearsInitial } from "constants/taxesAndDashboard";
import "./Chart.scss";
import { MIN_CALC_AGE } from "../../constants";

import ChartFab from "./ChartFab";
import TooltipMUI from "@material-ui/core/Tooltip";
import IconContainer from "../_common/IconContainer";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/lab/Slider";

import { entitiesScheme } from "components/AssetsCommon/formSchemeFull";
import { withStyles } from "@material-ui/core";
import {
  optionHighlights,
  optionHighlightsArr,
  optionHighlightsValues
} from "./CalcContainer.scheme";
import { colors, styleMix } from "../../constants/styles";

const styles = theme => ({
  slider: {
    marginLeft: 4,
    width: "95%"
  },
  buttonLink: {
    ...styleMix.ResetButton,
    ...styleMix.buttonLink,
    fontWeight: 500
  },
  buttonChartTerm: {
    ...styleMix.ResetButton,
    ...styleMix.uiTextBold,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 8,
    color: colors.textSecondary
  },
  buttonChartTermActive: {
    background: colors.backgroundActiveUi,
    borderRadius: 5,
    color: colors.ui
  }
});

class Charts extends PureComponent {
  static propTypes = {
    data: PropTypes.any,
    dataOverdraft: PropTypes.any,
    dataOverdraftSecondary: PropTypes.any,
    dataSecondary: PropTypes.any,
    isWhatIfShow: PropTypes.bool,

    isMobile: PropTypes.bool,
    isIncome: PropTypes.bool,
    dashboardAgeYear: PropTypes.bool,

    chartMarks: PropTypes.object,
    lifeEvents: PropTypes.object,
    activeYear: PropTypes.any,
    isHighlight: PropTypes.bool,
    containerWidth: PropTypes.number,
    dashboardChartTerm: PropTypes.any,
    currentAge: PropTypes.any,
    TimeLineTermElement: PropTypes.any,
    setUI: PropTypes.func,
    uiSettings: PropTypes.any,
    profile: PropTypes.any,
    independenceAgeObject: PropTypes.any,
    family: PropTypes.any,
    handleOpenForm: PropTypes.func,
    handleAddTileGoalDialogOpen: PropTypes.func,
    handleSetAddTileDialogType: PropTypes.func
  };

  state = {
    firsChartAnimationStart: false,
    disabled: [],
    disabledSecondary: [],
    isTooltipActive: false,
    isFixed: false,
    activeIndex: null,
    clientX: null,
    isLeftPosition: false,
    chartTooltipPayload: null,
    data: [],
    dataWhatIf: [],
    dataOverdraft: [],
    dataOverdraftWhatIf: [],
    dataSecondary: [],
    dataSecondaryWhatIf: [],
    dataOverdraftSecondary: [],
    dataOverdraftWhatIfSecondary: [],

    highlightedYears: [
      this.props.currentAge || this.props.activeYear || MIN_CALC_AGE,
      ...highlightedYearsInitial.filter(
        el =>
          (this.props.currentAge || this.props.activeYear || MIN_CALC_AGE) < el
      )
    ]
  };

  componentDidMount() {
    this.updateStateData();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { uiSettings } = this.props;
    if (this.props.dataOverdraft !== prevProps.dataOverdraft) {
      this.updateStateData();
    }

    if (this.props.containerWidth !== prevProps.containerWidth) {
      this.setState({ isFixed: false });
    }

    if (
      uiSettings.fixDashboardAgeChart &&
      (this.state.dataOverdraft !== prevState.dataOverdraft ||
        this.props.dataOverdraft !== prevProps.dataOverdraft ||
        uiSettings.fixDashboardAgeChart !==
          prevProps.uiSettings.fixDashboardAgeChart ||
        uiSettings.dashboardChartTerm !==
          prevProps.uiSettings.dashboardChartTerm)
    ) {
      const dataPrimary = this.state.dataOverdraft;
      const agesArr = dataPrimary.map(el => el.age);

      const index = agesArr.findIndex(
        el => el == uiSettings.fixDashboardAgeChart
      );

      let clientXCenter =
        (this.props.containerWidth / this.state.dataOverdraft.length) * index +
        32;

      const isLeftPosition = clientXCenter > document.body.clientWidth / 2;

      if (!clientXCenter || clientXCenter < 0) {
        clientXCenter = document.body.clientWidth / 2;
      }

      this.handleButtonSetActiveYear(uiSettings.fixDashboardAgeChart, index)({
        clientX: clientXCenter,
        isLeftPosition
      });
    }
  }

  updateStateData = () => {
    const {
      data,
      dataOverdraft,
      dataSecondary,
      dataOverdraftSecondary,
      dashboardChartTerm
    } = this.props;
    const temp = [];
    const tempWhatIf = [];
    const tempOverdraft = [];
    const tempOverdraftWhatIf = [];
    const tempSecondary = [];
    const tempSecondaryWhatIf = [];
    const tempOverdraftSecondary = [];
    const tempOverdraftSecondaryWhatIf = [];

    if (!dataOverdraft) {
      return null;
    }

    const highlightedYears = [
      this.props.currentAge || this.props.activeYear || MIN_CALC_AGE,
      ...highlightedYearsInitial.filter(
        el =>
          (this.props.currentAge || this.props.activeYear || MIN_CALC_AGE) < el
      )
    ];

    if (dataOverdraft) {
      Object.keys(dataOverdraft).forEach(keyYear => {
        if (
          (dashboardChartTerm !== "highlights" ||
            highlightedYears.indexOf(+keyYear) !== -1) &&
          dataOverdraft[keyYear]
        ) {
          const item = {
            age: keyYear,
            year: dataOverdraft[keyYear].year,
            //Income
            savings: dataOverdraft[keyYear].chartData.Savings,
            expenses: dataOverdraft[keyYear].chartData.Expenses,
            taxesIncome: dataOverdraft[keyYear].chartData.TaxesIncome,
            "pre-tax_deductions":
              dataOverdraft[keyYear].chartData.PreTaxDeductions,
            //Wealth
            taxes: dataOverdraft[keyYear].chartData.Taxes,
            liabilities: dataOverdraft[keyYear].chartData.Liabilities,
            // netWealth: dataOverdraft[keyYear].chartData.NetWealth,
            monetaryAssets: dataOverdraft[keyYear].chartData.MonetaryAssetsShow,
            homesAndVehicles: dataOverdraft[keyYear].chartData.HomesAndVehicles
          };

          const itemWhatIf = {
            age: keyYear,
            //Income
            savings: dataOverdraft[keyYear].chartDataWhatIf.Savings,
            expenses: dataOverdraft[keyYear].chartDataWhatIf.Expenses,
            taxesIncome: dataOverdraft[keyYear].chartDataWhatIf.TaxesIncome,
            "pre-tax_deductions":
              dataOverdraft[keyYear].chartDataWhatIf.PreTaxDeductions,
            //Wealth
            taxes: dataOverdraft[keyYear].chartDataWhatIf.Taxes,
            liabilities: dataOverdraft[keyYear].chartDataWhatIf.Liabilities,
            // netWealth: dataOverdraft[keyYear].chartDataWhatIf.NetWealth
            monetaryAssets:
              dataOverdraft[keyYear].chartDataWhatIf.MonetaryAssetsShow,
            homesAndVehicles:
              dataOverdraft[keyYear].chartDataWhatIf.HomesAndVehicles
          };

          tempOverdraft.push(item);
          tempOverdraftWhatIf.push(itemWhatIf);
        }
      });
    }

    if (dataOverdraftSecondary) {
      Object.keys(dataOverdraftSecondary).forEach(keyYear => {
        if (
          (dashboardChartTerm !== "highlights" ||
            highlightedYears.indexOf(+keyYear) !== -1) &&
          dataOverdraftSecondary[keyYear]
        ) {
          const item = {
            age: keyYear,
            year: dataOverdraftSecondary[keyYear].year,
            //Income
            savings: dataOverdraftSecondary[keyYear].chartData.Savings,
            expenses: dataOverdraftSecondary[keyYear].chartData.Expenses,
            taxesIncome: dataOverdraftSecondary[keyYear].chartData.TaxesIncome,
            "pre-tax_deductions":
              dataOverdraftSecondary[keyYear].chartData.PreTaxDeductions,
            //Wealth
            taxes: dataOverdraftSecondary[keyYear].chartData.Taxes,
            liabilities: dataOverdraftSecondary[keyYear].chartData.Liabilities,
            // netWealth: dataOverdraftSecondary[keyYear].chartData.NetWealth,
            monetaryAssets:
              dataOverdraftSecondary[keyYear].chartData.MonetaryAssetsShow,
            homesAndVehicles:
              dataOverdraftSecondary[keyYear].chartData.HomesAndVehicles
          };

          const itemWhatIf = {
            age: keyYear,
            //Income
            savings: dataOverdraftSecondary[keyYear].chartDataWhatIf.Savings,
            expenses: dataOverdraftSecondary[keyYear].chartDataWhatIf.Expenses,
            taxesIncome:
              dataOverdraftSecondary[keyYear].chartDataWhatIf.TaxesIncome,
            "pre-tax_deductions":
              dataOverdraftSecondary[keyYear].chartDataWhatIf.PreTaxDeductions,
            //Wealth
            taxes: dataOverdraftSecondary[keyYear].chartDataWhatIf.Taxes,
            liabilities:
              dataOverdraftSecondary[keyYear].chartDataWhatIf.Liabilities,
            // netWealth: dataOverdraftSecondary[keyYear].chartDataWhatIf.NetWealth
            monetaryAssets:
              dataOverdraftSecondary[keyYear].chartDataWhatIf
                .MonetaryAssetsShow,
            homesAndVehicles:
              dataOverdraftSecondary[keyYear].chartDataWhatIf.HomesAndVehicles
          };

          tempOverdraftSecondary.push(item);
          tempOverdraftSecondaryWhatIf.push(itemWhatIf);
        }
      });
    }

    this.setState({
      // data: temp,
      // dataWhatIf: tempWhatIf,
      dataOverdraft: tempOverdraft,
      dataOverdraftWhatIf: tempOverdraftWhatIf,

      // dataSecondary: tempSecondary,
      // dataSecondaryWhatIf: tempSecondaryWhatIf,
      dataOverdraftSecondary: tempOverdraftSecondary,
      dataOverdraftWhatIfSecondary: tempOverdraftSecondaryWhatIf,
      highlightedYears
    });
  };

  firstChartRenderEnd = () => {
    if (!this.state.firsChartAnimationStart) {
      this.setState({
        firsChartAnimationStart: true
      });
    }
  };

  handleButtonSetActiveYear = (age, index) => ev => {
    const { isMobile, setUI } = this.props;

    if (ev.clientX) {
      setUI({ fixDashboardAgeChart: null });
    }

    const isDrawerOpened = document.querySelector(".drawer-opened");
    const isLeftPosition = ev.clientX > document.body.clientWidth / 2;

    const subtract = !isMobile ? 220 : 0;

    let clientX = isDrawerOpened
      ? ev.clientX - subtract - 220 - 24
      : ev.clientX - subtract - 24;

    if (!ev) {
      clientX = 222;
    }

    if (isLeftPosition) {
      clientX -= 270;
    }

    this.props.handleSetActiveYear(age);

    this.setState({
      isFixed: true,
      activeIndex: index,
      clientX,
      isLeftPosition
    });
  };

  handleMouseMoveOnChart = data => {
    if (!data || this.state.isFixed) {
      return null;
    }

    if (
      this.state.isTooltipActive !== data.isTooltipActive ||
      data.activeLabel !== this.props.activeYear
    ) {
      this.props.handleSetActiveYear(data.activeLabel);

      this.setState(prevState => ({
        isTooltipActive: data.isTooltipActive,
        activeIndex: data.activeTooltipIndex,
        clientX: null
      }));
    }
  };

  handleClickOnChart = (data, ev) => {
    if (!data) {
      return;
    }

    const { isMobile, setUI } = this.props;

    if (ev && ev.clientX) {
      setUI({ fixDashboardAgeChart: null });
    }

    const isDrawerOpened = document.querySelector(".drawer-opened");
    const isLeftPosition = ev.clientX > document.body.clientWidth / 2;
    const subtract = !isMobile ? 220 : 0;

    let clientX = isDrawerOpened
      ? ev.clientX - subtract - 220 - 24
      : ev.clientX - subtract - 24;

    if (isLeftPosition) {
      clientX -= 270;
    }

    if (this.state.isFixed) {
      return this.setState({
        chartTooltipPayload: null,
        isFixed: false,
        activeIndex: null,
        clientX: null
      });
    }

    const chartTooltipPayload = data;
    const isFixed = true;
    this.setState({
      chartTooltipPayload,
      isFixed,
      activeIndex: data.activeTooltipIndex,
      isLeftPosition,
      clientX
    });
  };

  handleToggleChart = (dataKey, isMain) => () => {
    const disabledKey = isMain ? "disabled" : "disabledSecondary";

    if (this.state[disabledKey].includes(dataKey)) {
      this.setState({
        [disabledKey]: this.state[disabledKey].filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ [disabledKey]: this.state[disabledKey].concat(dataKey) });
    }
  };

  handleEditEvent = (id, { isEvent = false, asset } = {}) => ev => {
    if (ev) {
      ev.stopPropagation();
    }

    const conf = isEvent
      ? {
          ...entitiesScheme.events,
          assetType: asset.entity
        }
      : {
          ...entitiesScheme.goals,
          assetType: asset.entity
        };

    this.props.handleOpenForm(id, conf);
  };

  handleChangeCompareTo = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardCompareTo: checked
      },
      { email: this.props.profile.email }
    );
  };

  handleChangeRepeatableEvents = ev => {
    const { checked } = ev.target;

    this.props.setUI(
      {
        dashboardShowRepeatableEvents: !checked
      },
      { email: this.props.profile.email }
    );
  };
  handleChangeShowHideGraph = ev => {
    const { uiSettings } = this.props;

    this.props.setUI(
      {
        dashboardHideChart: !uiSettings.dashboardHideChart
      },
      { email: this.props.profile.email }
    );
  };

  handleChangeSwitch = (name, value) => ev => {
    this.props.setUI(
      {
        [name]: value
      },
      { email: this.props.profile.email }
    );
  };

  _renderTooltip = (
    isMain,
    { chartKeys, chartMeta, chartsDisabled, isFixed } = {}
  ) => props => {
    const { chartMarks, activeYear, uiSettings } = this.props;
    let total = 0;

    const mark = chartMarks ? chartMarks[activeYear] : null;

    const isShowWhatIf = isMain
      ? !!uiSettings.dashboardIfChecked
      : !!uiSettings.dashboardIfCheckedSecondary;

    let contentEvents = null;
    if (mark && mark.event) {
      contentEvents = mark.event.map(event => {
        if (event.isWhatIf && !isShowWhatIf) {
          return null;
        }

        return (
          <TooltipMUI
            key={event.id}
            title={event.description}
            placement="top"
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
          >
            <span
              onClick={this.handleEditEvent(event.id, {
                isEvent: true,
                asset: event
              })}
              className="cursor-pointer"
            >
              <IconContainer color={"hoverNoToBrand"}>
                <Icons.IconEvent color={"brand"} />
              </IconContainer>
            </span>
          </TooltipMUI>
        );
      });
    }

    let contentGoals = null;
    if (mark && mark.goal) {
      contentGoals = mark.goal.map(goal => {
        if (isMain && goal.isWhatIf) {
          return null;
        }

        return (
          <TooltipMUI
            key={goal.id}
            title={goal.description}
            placement="top"
            disableFocusListener
            enterTouchDelay={50}
            leaveTouchDelay={5000}
          >
            <span
              onClick={this.handleEditEvent(goal.id, {
                isEvent: false,
                asset: goal
              })}
              className="cursor-pointer"
            >
              <IconContainer color={"hoverNoToBrand"}>
                <Icons.IconTrophy color={"brand"} />
              </IconContainer>
            </span>
          </TooltipMUI>
        );
      });
    }

    let calcData;
    if (isMain) {
      calcData = this.state.dataOverdraft;
    } else {
      calcData = this.state.dataOverdraftSecondary;
    }

    const calcDataWhatIf = isMain
      ? this.state.dataOverdraftWhatIf
      : this.state.dataOverdraftWhatIfSecondary;

    const dataPrimary = !uiSettings.dashboardIfChecked
      ? calcData
      : calcDataWhatIf;
    const dataSecondary = !uiSettings.dashboardIfCheckedSecondary
      ? calcData
      : calcDataWhatIf;

    const dataYear = isMain
      ? dataPrimary[this.state.activeIndex]
      : dataSecondary[this.state.activeIndex];

    let isMonetaryNegative;

    const tooltipContent =
      (this.state.activeIndex || this.state.activeIndex === 0) &&
      this.state.dataOverdraft &&
      chartMeta &&
      chartKeys &&
      chartKeys.map((itemKey, i) => {
        const color = chartMeta[itemKey].colors.stroke;

        if (!dataYear || (!dataYear[itemKey] && +dataYear[itemKey] !== 0)) {
          return null;
        }

        const value = Number(dataYear[itemKey]);
        isMonetaryNegative = dataYear.monetaryAssets <= 0;

        total += value;

        return (
          <li
            key={`${itemKey}-tooltip`}
            className="recharts-tooltip-item recharts-tooltip-item--custom"
          >
            <span className="recharts-tooltip-item-name">
              <FormattedMessage
                id={`DASHBOARD.CHART.LABEL.TYPES.${itemKey}`}
                defaultMessage={itemKey}
              />
              : {` `}
            </span>
            <span
              className="recharts-tooltip-item-value"
              style={{ color: color }}
            >
              {formatMoneyIntWithSign(value)}
            </span>
          </li>
        );
      });

    if (!tooltipContent) {
      return null;
    }

    if (!this.state.dataOverdraft[this.state.activeIndex]) {
      return null;
    }

    const addEvent = (
      <span
        onClick={this.props.handleSetAddTileDialogType(
          entitiesScheme.events.assetGlobalType,
          { customValues: { startAge: activeYear }, returnToTypes: true }
        )}
        className="cursor-pointer recharts-tooltip__add-item"
      >
        <IconContainer width={48} height={32} color={"yellow"}>
          <Icons.IconPlusNew color={"yellow"} />
          <Icons.IconEvent color={"yellow"} />
        </IconContainer>
      </span>
    );

    const addGoal = (
      <span
        onClick={this.props.handleSetAddTileDialogType(
          entitiesScheme.goals.assetGlobalType,
          { customValues: { startAge: activeYear } }
        )}
        className="cursor-pointer recharts-tooltip__add-item"
      >
        <IconContainer width={48} height={32} color={"yellow"}>
          <Icons.IconPlusNew color={"yellow"} />
          <Icons.IconTrophy color={"yellow"} />
        </IconContainer>
      </span>
    );

    const tooltip = (
      <section className="recharts-default-tooltip recharts-default-tooltip--custom">
        <header className="recharts-tooltip-label recharts-tooltip-label--custom">
          <span>
            <FormattedMessage
              id="DASHBOARD.CHART.LABEL.TYPES.AGE"
              defaultMessage="Age"
            />
            :{` `}
            {this.props.activeYear}
          </span>

          <span className="recharts-tooltip-label__year">
            <FormattedMessage
              id="DASHBOARD.CHART.LABEL.TYPES.YEAR"
              defaultMessage="Year"
            />
            : {this.state.dataOverdraft[this.state.activeIndex].year}
          </span>
        </header>

        <ul className="recharts-tooltip-item-list recharts-tooltip-item-list--custom">
          {tooltipContent}
        </ul>

        <div className="recharts-tooltip-item-events">
          {contentGoals}
          {contentEvents}
          {this.state.isFixed && addEvent}
          {this.state.isFixed && addGoal}
        </div>

        <footer
          className={`recharts-tooltip-item recharts-tooltip-item--total ${
            isMonetaryNegative ? "recharts-tooltip-item--total-negative" : ""
          }`}
        >
          <span className="recharts-tooltip-item-name">
            <FormattedMessage
              id={`DASHBOARD.CHART.LABEL.TYPES.TOTAL`}
              defaultMessage="item.dataKey"
            />
            : {` `}
          </span>
          <span className="recharts-tooltip-item-value">
            {formatMoneyIntWithSign(total)}
          </span>
        </footer>
      </section>
    );

    return tooltip;
  };

  _renderLegend = (isMain, { chartKeys, chartMeta, chartsDisabled }) => () => {
    return (
      <ul
        className={`recharts-legend-list
      ${isMain ? "recharts-legend-list--main" : ""}`}
      >
        {chartKeys.map((chartKey, i) => {
          const color = chartMeta[chartKey].colors.stroke;

          const active = !chartsDisabled[i];
          const tooltipText = (
            <FormattedMessage
              id={`DASHBOARD.CHART.LABEL.TOOLTIP.${chartKey}`}
            />
          );

          return (
            <li
              className={`recharts-legend-item ${
                active ? "recharts-legend-item--active" : ""
              }`}
              key={`item-${chartKey}`}
              onClick={this.handleToggleChart(chartKey, isMain)}
            >
              <span
                className="dashboard__legend-square"
                style={{ backgroundColor: color }}
              />
              <TooltipMUI title={tooltipText} placement="right-start">
                <span className="recharts-legend-item-text">
                  <FormattedMessage
                    id={`DASHBOARD.CHART.LABEL.TYPES.${chartKey}`}
                    defaultMessage={chartKey}
                  />
                </span>
              </TooltipMUI>
            </li>
          );
        })}
      </ul>
    );
  };

  _renderHeader = isMain => {
    const { uiSettings, classes } = this.props;

    const chartTerm = uiSettings.dashboardChartTerm;
    const dashboardChartTermValue = optionHighlightsArr.indexOf(chartTerm);

    return (
      <h2 className="dashboard__chart-title">
        {!uiSettings.dashboardHideChart && (
          <div className="dashboard__chart-title--left">
            {/*{isMain && this._renderChartSettings()}*/}

            {isMain ? (
              <FormattedMessage
                id="DASHBOARD.CHART.LABEL.TITLE.STANDARD"
                defaultMessage="Primary View"
              />
            ) : (
              <FormattedMessage
                id="DASHBOARD.CHART.LABEL.TITLE.COMPARE"
                defaultMessage="Compare View"
              />
            )}
          </div>
        )}

        <div className="dashboard__chart-title--right">
          <button
            onClick={this.handleChangeTimeLineLabel(null, "10")}
            className={`${classes.buttonChartTerm} ${
              +uiSettings.dashboardChartTerm === 10
                ? classes.buttonChartTermActive
                : ""
            }`}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.SELECT.10Y"
              defaultMessage="10Y"
            />
          </button>

          <button
            onClick={this.handleChangeTimeLineLabel(null, "20")}
            className={`${classes.buttonChartTerm} ${
              +uiSettings.dashboardChartTerm === 20
                ? classes.buttonChartTermActive
                : ""
            }`}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.SELECT.20Y"
              defaultMessage="20Y"
            />
          </button>

          <button
            onClick={this.handleChangeTimeLineLabel(null, "50")}
            className={`${classes.buttonChartTerm} ${
              +uiSettings.dashboardChartTerm === 50
                ? classes.buttonChartTermActive
                : ""
            }`}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.SELECT.50Y"
              defaultMessage="50Y"
            />
          </button>

          <button
            onClick={this.handleChangeTimeLineLabel(null, "to100")}
            className={`${classes.buttonChartTerm} ${
              uiSettings.dashboardChartTerm === "to100"
                ? classes.buttonChartTermActive
                : ""
            }`}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.SELECT.ALL"
              defaultMessage="All"
            />
          </button>
        </div>
      </h2>
    );
  };

  _renderChart = (
    data,
    type = "income",
    { isMain = false, isWhatIf = false, independenceAge, isPrimaryHomeSold } = {}
  ) => {
    const { isMobile, uiSettings } = this.props;

    const chartMeta = chartsMeta[type] || chartsMeta.income;
    const whatIfKey = !isMain ? "Secondary" : "";

    const disabledKey = isMain ? "disabled" : "disabledSecondary";

    const chartKeys = Object.keys(chartMeta);
    const chartsDisabled = chartKeys.map(chartKey =>
      this.state[disabledKey].includes(chartKey) ? 1 : 0
    );

    const yTickFormatter = val => {
      const digits = Math.abs(val) > 1000000 ? 1 : 0;
      return nFormatter(val, digits);
    };

    let calcWidth = this.props.containerWidth - (!isMobile ? 220 : 0);
    const chartWidth = calcWidth;

    const monetaryNegativeYearObject = data.find(el => el.monetaryAssets <= 0);
    let monetaryNegativeYear = null;
    if (monetaryNegativeYearObject) {
      monetaryNegativeYear = monetaryNegativeYearObject.age;
    }

    return (
      <div className="chart-container">
        {this._renderToolbar(isMain)}

        <div
          className={`chart-content ${
            uiSettings.dashboardHideChart ? "chart-content--only-x-axis" : ""
          }`}
        >
          {this._renderHeader(isMain)}

          {!uiSettings.dashboardHideChart && (
            <AreaChart
              data={data}
              margin={{ top: 0, right: 0, left: -30, bottom: 0 }}
              syncId="MainChart"
              width={chartWidth - 12}
              height={300}
              onMouseMove={this.handleMouseMoveOnChart}
              onClick={this.handleClickOnChart}
            >
              <defs>
                {chartKeys.map(chartKey => (
                  <linearGradient
                    key={`${chartKey}-linearGradient`}
                    id={chartMeta[chartKey].colors.id + isMain}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="0%"
                      stopColor={chartMeta[chartKey].colors.start}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={chartMeta[chartKey].colors.stop}
                      stopOpacity={1}
                    />
                  </linearGradient>
                ))}
              </defs>

              <XAxis dataKey="age" tickLine={false} tick={false} />

              <YAxis
                padding={{ top: 32 }}
                domain={[
                  dataMin => 0 - Math.abs(dataMin),
                  dataMax => dataMax * 1.2
                ]}
                width={75}
                tickLine={false}
                tickFormatter={yTickFormatter}
              />

              <CartesianGrid strokeDasharray="0" strokeOpacity={0} />

              {chartKeys.map(
                (chartKey, i) =>
                  !chartsDisabled[i] && (
                    <Area
                      key={`${chartKey}-Area-${isMain}`}
                      stackId="1"
                      type="monotone"
                      dataKey={chartKey}
                      activeDot={this.CustomizedDot(
                        chartMeta[chartKey].colors.stroke,
                        chartKey,
                        { id: isWhatIf, isShow: !chartsDisabled[i] }
                      )}
                      opacity={chartsDisabled[i] ? 0 : 1}
                      stroke={chartMeta[chartKey].colors.stroke}
                      strokeWidth={2}
                      fillOpacity={this.state.isFixed ? 0.6 : 1}
                      fill={`url(#${chartMeta[chartKey].colors.id}${isMain})`}
                      onAnimationStart={
                        i === 0 && !this.state.firsChartAnimationStart
                          ? this.firstChartRenderEnd
                          : null
                      }
                    />
                  )
              )}

              <Tooltip
                animationDuration={300}
                activeActive={this.state.isFixed}
                position={
                  this.state.isFixed
                    ? {
                        x:
                          this.state.clientX ||
                          (this.state.chartTooltipPayload &&
                            this.state.chartTooltipPayload.activeCoordinate.x),
                        y: 0
                      }
                    : null
                }
                activePayload={
                  this.state.isFixed
                    ? (this.state.chartTooltipPayload &&
                        this.state.chartTooltipPayload &&
                        this.state.chartTooltipPayload.activePayload) || [
                        { value: 1 }
                      ]
                    : null
                }
                cursor={{
                  stroke: "#FFFFFF",
                  strokeWidth: 2,
                  strokeOpacity: 0.7
                }}
                content={this._renderTooltip(isMain, {
                  chartKeys,
                  chartMeta,
                  chartsDisabled
                })}
              />

              {monetaryNegativeYear &&
                +independenceAge !== +monetaryNegativeYear && (
                  <ReferenceLine
                    x={+monetaryNegativeYear}
                    strokeWidth={2}
                    stroke={colors.red}
                  />
                )}

              {independenceAge &&
                +independenceAge !== +monetaryNegativeYear && (
                  <ReferenceLine
                    x={+independenceAge}
                    strokeWidth={2}
                    stroke={isPrimaryHomeSold ? colors.yellowDark : colors.green}
                  />
                )}

              {chartKeys.map(
                (chartKey, i) =>
                  !chartsDisabled[chartKey] && (
                    <use
                      key={`${chartKey}-use`}
                      xlinkHref={`#${chartKey}-${isWhatIf}`}
                      opacity={chartsDisabled[i] ? 0 : 1}
                    />
                  )
              )}
            </AreaChart>
          )}

          {this._renderXAxis(isMain, { independenceAge, calcWidth, isPrimaryHomeSold })}
        </div>
      </div>
    );
  };

  CustomizedDot = (color, entity, { id, isShow = true } = {}) => props => {
    const { cx, cy } = props;

    if (!isShow) {
      return null;
    }

    return (
      <circle
        id={`${entity}-${id}`}
        cx={cx}
        cy={cy}
        r="4"
        fill={color}
        strokeWidth="2"
        stroke="#FFF"
        className="recharts-dot"
      />
    );
  };

  _renderXAxis = (isMain, { independenceAge, calcWidth, isPrimaryHomeSold } = {}) => {
    // TODO GET type of graph (highlights etc)
    const {
      chartMarks,
      activeYear,
      data,
      dataOverdraft,
      dataOverdraftSecondary,
      dashboardChartTerm,
      uiSettings,
      dashboardAgeYear,
      isWhatIfShow,
      isMobile
    } = this.props;

    const isYear = dashboardAgeYear;
    const { firsChartAnimationStart } = this.state;

    if (!firsChartAnimationStart && !uiSettings.dashboardHideChart) {
      return null;
    }

    const currentData = isMain ? dataOverdraft : dataOverdraftSecondary;

    const dashboardChartTermLocal = dashboardChartTerm;

    const isShowWhatIf = isMain
      ? uiSettings.dashboardIfChecked
      : uiSettings.dashboardIfCheckedSecondary;

    if (!currentData) {
      return null;
    }

    const isMdScreen = window.innerWidth < 1650;
    const isSmScreen = window.innerWidth < 1355;

    const calcData = isMain
      ? this.state.dataOverdraft
      : this.state.dataOverdraftSecondary;

    const calcDataWhatIf = isMain
      ? this.state.dataOverdraftWhatIf
      : this.state.dataOverdraftWhatIfSecondary;

    const isShowRepeatableEvents = uiSettings.dashboardShowRepeatableEvents;

    const dataPrimary = (isMain
    ? !uiSettings.dashboardIfChecked
    : !uiSettings.dashboardIfCheckedSecondary)
      ? calcData
      : calcDataWhatIf;

    const agesArr = dataPrimary.map(el => el.age);

    const isTooLong =
      +dashboardChartTermLocal === 50 || dashboardChartTermLocal === "to100";

    const isShowEveryFourYear =
      dashboardChartTermLocal === "to100" && agesArr.length > 50;

    const isShowEveryOtherYear =
      +dashboardChartTermLocal === 50 ||
      (dashboardChartTermLocal === "to100" &&
        agesArr.length > 20 &&
        agesArr.length <= 50);

    const lastIndex = agesArr.length - 1;
    const firstLevel = 2;
    const secondLevel = 4;
    const thirdLevel = 8;

    let isFirstNegative = true;

    return (
      <div
        className={`dashboard__chart-axis ${
          isTooLong ? "dashboard__chart-axis--long" : ""
        } ${
          !isShowEveryFourYear && !isShowEveryOtherYear
            ? "dashboard__chart-axis--with-track"
            : ""
        } ${
          uiSettings.dashboardHideChart ? "dashboard__chart-axis--alone" : ""
        }`}
      >
        {agesArr.map((age, index) => {
          const isEveryOther =
            index % (isMdScreen ? secondLevel : firstLevel) === 0;
          const isEveryFour =
            index % (isMdScreen ? thirdLevel : secondLevel) === 0;

          const isShowYear =
            (isShowEveryFourYear && isEveryFour) ||
            (isShowEveryOtherYear && isEveryOther) ||
            !(isShowEveryFourYear || isShowEveryOtherYear) ||
            lastIndex === index;

          const isActive = +activeYear === +age;
          const mark = chartMarks ? chartMarks[age] : null;

          const markCount = mark
            ? (isShowWhatIf ? mark.countWhatIf : mark.count) || 0
            : 0;

          const title = isYear
            ? ` ${calcData[index] ? calcData[index].year : age} `
            : age;

          const isMonetaryAssetsNegative = dataPrimary[index]
            ? dataPrimary[index].monetaryAssets <= 0
            : false;

          let isShowMonetaryNegativeFab =
            isMonetaryAssetsNegative && isFirstNegative;

          let isShowIndependenceAgeFab = age == independenceAge;

          if (isShowMonetaryNegativeFab && isShowIndependenceAgeFab) {
            isShowMonetaryNegativeFab = false;
            isShowIndependenceAgeFab = false;
          }

          if (isMonetaryAssetsNegative) {
            isFirstNegative = false;
          }

          if (!isShowYear || (+age === 99 && agesArr.length !== index + 1)) {
            return (mark && markCount) ||
              (isShowMonetaryNegativeFab || isShowIndependenceAgeFab) ? (
              <ChartFab
                key={`${age}-key`}
                title={title}
                isDot
                isMobile
                isShowRepeatableEvents={isShowRepeatableEvents}
                buttonChartNegative={isMonetaryAssetsNegative}
                markCount={markCount}
                isWhatIfShow={isShowWhatIf}
                mark={mark}
                isShowMonetaryNegativeFab={isShowMonetaryNegativeFab}
                isShowIndependenceAgeFab={isShowIndependenceAgeFab}
                isPrimaryHomeSold={isPrimaryHomeSold}
                monetaryAssetNegative={isFirstNegative}
                active={isActive}
                handleClickProp={this.handleButtonSetActiveYear(age, index)}
                handleEditEvent={this.handleEditEvent}
                age={age}
                // styleOffset={styleOffset}
              />
            ) : (
              <Button
                key={`${age}-key`}
                buttonChart
                buttonChartDot
                buttonChartDotNegative={isMonetaryAssetsNegative}
                onClick={this.handleButtonSetActiveYear(age, index)}
                className={`dashboard__chart-axis-circle ${
                  markCount ? "dashboard__chart-axis-circle--event" : ""
                }`}
                // style={styleOffset}
              />
            );
          }

          return (
            <div key={age}>
              {(mark && markCount) ||
              (isShowMonetaryNegativeFab || isShowIndependenceAgeFab) ? (
                <ChartFab
                  key={`${age}-key`}
                  title={title}
                  isMobile
                  isShowRepeatableEvents={isShowRepeatableEvents}
                  isYear={isYear}
                  buttonChartNegative={isMonetaryAssetsNegative}
                  buttonChartNegativeActive={
                    isMonetaryAssetsNegative && isActive
                  }
                  markCount={markCount}
                  isWhatIfShow={isShowWhatIf}
                  mark={mark}
                  isShowMonetaryNegativeFab={isShowMonetaryNegativeFab}
                  isShowIndependenceAgeFab={isShowIndependenceAgeFab}
                  isPrimaryHomeSold={isPrimaryHomeSold}
                  active={isActive}
                  handleClickProp={this.handleButtonSetActiveYear(age, index)}
                  handleEditEvent={this.handleEditEvent}
                  age={age}
                  // styleOffset={styleOffset}
                />
              ) : (
                <Button
                  key={`${age}-key`}
                  buttonChart
                  buttonChartActive={isActive}
                  buttonChartYear={isYear}
                  buttonChartNegative={isMonetaryAssetsNegative}
                  buttonChartNegativeActive={
                    isMonetaryAssetsNegative && isActive
                  }
                  style={{
                    // ...styleOffset,
                    marginTop: -18
                  }}
                  onClick={this.handleButtonSetActiveYear(age, index)}
                >
                  <span key={`axis-${index}`}>{title}</span>
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  handleChangeTimeLineButton = (isMain, isPlus) => () => {
    const { uiSettings } = this.props;

    const chartTerm = uiSettings.dashboardChartTerm;

    let dashboardChartTermIndex = optionHighlightsArr.indexOf(chartTerm);

    if (typeof dashboardChartTermIndex === "number") {
      this.handleChangeTimeLine()(
        null,
        isPlus ? ++dashboardChartTermIndex : --dashboardChartTermIndex
      );
    }
  };

  handleChangeTimeLineLabel = (isMain, value) => ev => {
    this.handleChangeTimeLine()(null, value);
  };

  handleChangeTimeLine = term => (event, value) => {
    const dashboardChartTermValue =
      typeof value === "number"
        ? optionHighlightsArr[value]
        : optionHighlightsValues[value];

    if (dashboardChartTermValue) {
      this.props.setUI(
        {
          dashboardChartTerm: dashboardChartTermValue
        },
        { email: this.props.profile.email }
      );
    }
  };

  _renderChartSettings = isMain => {
    const { uiSettings, classes } = this.props;
    const whatIfKey = !isMain ? "Secondary" : "";

    const chartTerm = uiSettings.dashboardChartTerm;
    const dashboardChartTermValue = optionHighlightsArr.indexOf(chartTerm);

    const sliderEl = (
      <div className="chart-slider-container">
        <Slider
          classes={{ container: classes.slider }}
          value={dashboardChartTermValue}
          min={0}
          max={3}
          step={1}
          onChange={this.handleChangeTimeLine(isMain)}
        />

        <div className="chart-timeline__slider">
          <div
            className="chart-timeline__slider-item"
            onClick={this.handleChangeTimeLineLabel(isMain, 0)}
          >
            10
          </div>
          <div
            className="chart-timeline__slider-item"
            onClick={this.handleChangeTimeLineLabel(isMain, 1)}
          >
            20
          </div>
          <div
            className="chart-timeline__slider-item"
            onClick={this.handleChangeTimeLineLabel(isMain, 2)}
          >
            50
          </div>
          <div
            className="chart-timeline__slider-item"
            onClick={this.handleChangeTimeLineLabel(isMain, 3)}
          >
            <FormattedMessage
              id="DASHBOARD.MAIN.SELECT.ALL"
              defaultMessage="All"
            />
          </div>
        </div>
      </div>
    );

    const ageYearSwitch = (
      <div className="chart-switch-container">
        <button
          onClick={this.handleChangeSwitch(`dashboardAgeYear`, false)}
          className={`chart-switch-label chart-switch-label--icon chart-switch-label--secondary ${
            !uiSettings[`dashboardAgeYear`] ? "chart-switch-label--active" : ""
          }`}
        >
          <span className="chart-switch-label__icon">
            <Icons.IconRetirement
              color={!uiSettings[`dashboardAgeYear`] ? "white" : "brand"}
            />
          </span>

          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.AGE"
            defaultMessage="Age"
          />
        </button>
        <button
          onClick={this.handleChangeSwitch(`dashboardAgeYear`, true)}
          className={`chart-switch-label chart-switch-label--icon chart-switch-label--secondary ${
            uiSettings[`dashboardAgeYear`] ? "chart-switch-label--active" : ""
          }`}
        >
          <span className="chart-switch-label__icon">
            <Icons.IconFormatList
              color={uiSettings[`dashboardAgeYear`] ? "white" : "brand"}
            />
          </span>

          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.YEAR"
            defaultMessage="Year"
          />
        </button>
      </div>
    );

    const checkRepeatableEventSwitch = (
      <FormControlLabel
        control={
          <Checkbox
            checked={!uiSettings.dashboardShowRepeatableEvents}
            name="showIf"
            onChange={this.handleChangeRepeatableEvents}
          />
        }
        label={
          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.EVENTS.RECURRING"
            defaultMessage="Hide recurring events"
          />
        }
      />
    );

    return (
      <div className="dashboard__chart-settings chart-settings chart-settings--common">
        <div
          className={`chart-settings__action ${
            uiSettings.dashboardHideChart
              ? "chart-settings__action--only-x-axis"
              : ""
          }`}
        >
          <div className="chart-settings__action-container">
            <div className="chart-settings__icon-cont">
              <Icons.IconSettings color="brand" />
            </div>

            <FormattedMessage
              id="DASHBOARD.CHART.LABEL.GRAPH_SETTING"
              defaultMessage="Graph setting"
            />
          </div>

          <div className="chart-settings__toolbar-container">
            <div className="chart-settings__toolbar">
              {ageYearSwitch}
              {sliderEl}
              {checkRepeatableEventSwitch}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderToolbar = isMain => {
    const { uiSettings, classes } = this.props;
    const whatIfKey = !isMain ? "Secondary" : "";

    const isIncome = uiSettings[`dashboardWealthIncome${whatIfKey}`];

    const disabledKey = isMain ? "disabled" : "disabledSecondary";

    const chartType = isIncome ? "income" : "wealth";
    const chartMeta = chartsMeta[chartType] || chartsMeta.income;
    const chartKeys = Object.keys(chartMeta);
    const chartsDisabled = chartKeys.map(chartKey =>
      this.state[disabledKey].includes(chartKey) ? 1 : 0
    );

    const chartTerm = uiSettings.dashboardChartTerm;

    const dashboardChartTermValue = optionHighlightsArr.indexOf(chartTerm);

    const compareToEl = (
      <FormControlLabel
        control={
          <Checkbox
            checked={uiSettings.dashboardCompareTo}
            name="showIf"
            onChange={this.handleChangeCompareTo}
          />
        }
        label={
          <FormattedMessage
            id="DASHBOARD.MAIN.CHECKBOX.COMPARE_TO"
            defaultMessage="Compare to"
          />
        }
      />
    );
    const hideChartEl = (
      <button
        className={classes.buttonLink}
        onClick={this.handleChangeShowHideGraph}
      >
        {uiSettings.dashboardHideChart ? (
          <FormattedMessage
            id="DASHBOARD.CHART.LABEL.SHOW_GRAPH"
            defaultMessage="Show graph"
          />
        ) : (
          <FormattedMessage
            id="DASHBOARD.CHART.LABEL.HIDE_GRAPH"
            defaultMessage="Hide graph"
          />
        )}
      </button>
    );

    return (
      <div className={`chart-toolbar ${isMain ? "chart-toolbar--main" : ""}`}>
        {!uiSettings.dashboardHideChart && !isMain && (
          <div className="toolbar-chart--top">{compareToEl}</div>
        )}

        {isMain && hideChartEl}

        {isMain && this._renderChartSettings()}

        {!uiSettings.dashboardHideChart && (
          <div className="chart-switch">
            {this._renderToolbarSwitches(isMain)}
          </div>
        )}

        {!uiSettings.dashboardHideChart &&
          this._renderLegend(isMain, {
            chartKeys,
            chartMeta,
            chartsDisabled
          })()}

        {!uiSettings.dashboardHideChart &&
          !uiSettings.dashboardCompareTo &&
          isMain && <div className="toolbar-chart--bottom">{compareToEl}</div>}
      </div>
    );
  };

  _renderToolbarSwitches = isMain => {
    const { uiSettings } = this.props;
    const secondaryKey = !isMain ? "Secondary" : "";

    const incomeWealthSwitch = (
      <div className="chart-switch-container">
        <button
          onClick={this.handleChangeSwitch(
            `dashboardWealthIncome${secondaryKey}`,
            false
          )}
          className={`chart-switch-label  ${
            !uiSettings[`dashboardWealthIncome${secondaryKey}`]
              ? "chart-switch-label--active"
              : ""
          }`}
        >
          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.WEALTH"
            defaultMessage="Wealth"
          />
        </button>
        <button
          onClick={this.handleChangeSwitch(
            `dashboardWealthIncome${secondaryKey}`,
            true
          )}
          className={`chart-switch-label  ${
            uiSettings[`dashboardWealthIncome${secondaryKey}`]
              ? "chart-switch-label--active"
              : ""
          }`}
        >
          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.INCOME"
            defaultMessage="Income"
          />
        </button>
      </div>
    );

    const whatIfSwitch = (
      <div className="chart-switch-container">
        <button
          onClick={this.handleChangeSwitch(
            `dashboardIfChecked${secondaryKey}`,
            false
          )}
          className={`chart-switch-label  ${
            !uiSettings[`dashboardIfChecked${secondaryKey}`]
              ? "chart-switch-label--active"
              : ""
          }`}
        >
          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.STANDARD"
            defaultMessage="Standard"
          />
        </button>
        <button
          onClick={this.handleChangeSwitch(
            `dashboardIfChecked${secondaryKey}`,
            true
          )}
          className={`chart-switch-label ${
            uiSettings[`dashboardIfChecked${secondaryKey}`]
              ? "chart-switch-label--active"
              : ""
          }`}
        >
          <FormattedMessage
            id="DASHBOARD.MAIN.SWITCH.WHAT_IF"
            defaultMessage="What If"
          />
        </button>
      </div>
    );

    return (
      <>
        {incomeWealthSwitch}

        {whatIfSwitch}
      </>
    );
  };

  render() {
    const { uiSettings, independenceAgeObject } = this.props;

    const calcData = this.state.dataOverdraft;
    const calcDataWhatIf = this.state.dataOverdraftWhatIf;

    const calcDataSecondary = this.state.dataOverdraftSecondary;

    const calcDataSecondaryWhatIf = this.state.dataOverdraftWhatIfSecondary;

    const chartType = uiSettings.dashboardWealthIncome ? "income" : "wealth";
    const chartTypeWIf = uiSettings.dashboardWealthIncomeSecondary
      ? "income"
      : "wealth";

    const dataPrimary = !uiSettings.dashboardIfChecked
      ? calcData
      : calcDataWhatIf;
    const independenceAge = !uiSettings.dashboardIfChecked
      ? independenceAgeObject.independenceAge
      : independenceAgeObject.independenceAgeWhatIf;
    const isPrimaryHomeSold = !uiSettings.dashboardIfChecked
      ? independenceAgeObject.primaryHomeSold
      : independenceAgeObject.primaryHomeSoldWhatIf;

    const dataSecondary = !uiSettings.dashboardIfCheckedSecondary
      ? calcDataSecondary
      : calcDataSecondaryWhatIf;

    const independenceAgeSecondary = !uiSettings.dashboardIfCheckedSecondary
      ? independenceAgeObject.independenceAge
      : independenceAgeObject.independenceAgeWhatIf;

    const isPrimaryHomeSoldSecondary = !uiSettings.dashboardIfCheckedSecondary
      ? independenceAgeObject.primaryHomeSold
      : independenceAgeObject.primaryHomeSoldWhatIf;

    return (
      <>
        <div className="dashboard__chart-main-container">
          {this._renderChart(dataPrimary, chartType, {
            isMain: true,
            independenceAge,
            isPrimaryHomeSold
          })}

          {!uiSettings.dashboardHideChart && uiSettings.dashboardCompareTo && (
            <>
              {this._renderChart(dataSecondary, chartTypeWIf, {
                isWhatIf: true,
                independenceAge: independenceAgeSecondary,
                isPrimaryHomeSold: isPrimaryHomeSoldSecondary
              })}
            </>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Charts);

export function nFormatter(num, digits = 0) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" }
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }

  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
