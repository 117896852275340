import { colors, styleMix } from "../../constants/styles";

export const styles = () => ({
  TitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    textTransform: "none"
  },
  title: {
    ...styleMix.title,
    textAlign: "left",
    textTransform: "none"
  },
  subTitle: {
    fontSize: 14,
    lineHeight: "21px",
    textAlign: "left",

    display: "flex",
    alignItems: "center",
    letterSpacing: "0.5px",
    color: colors.textSecondary,

    textTransform: "none"
  },
  ActionAndGoalsBlock: {
    marginLeft: 16,
    marginRight: 0,
    width: "auto"
  },
  actions: {
    ...styleMix.ResetUl
  },
  actionItem: {
    position: "relative",
    padding: 16,
    paddingRight: 40,
    marginBottom: 8,
    background: colors.white,
    border: `1px solid #D8DADC`,

    borderRadius: 4,

    "&:hover $closeButton": {
      opacity: 1
    }
  },
  actionItemClose: {
    position: "absolute"
  },
  ActionButton: {
    ...styleMix.ResetButton,
    textDecoration: "underline",
    color: colors.ui,

    "&:hover": {
      opacity: 0.7
    },
    "&:active": {
      opacity: 0.5
    }
  },
  ActionButtonCollapse: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 14,
    lineHeight: "16px",
    letterSpacing: "0.5px",

    color: colors.ui,
    cursor: "pointer",

    "&:hover": {
      opacity: 0.7
    },
    "&:active": {
      opacity: 0.5
    }
  },
  GoalsEmptyContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginLeft: -16,

    "@media (max-width: 1763px)": {
      justifyContent: "center",
      alignItems: "flex-start"
    }
  },
  GoalsAddButton: {
    transition: "background-color 0.3s ease",

    ...styleMix.ResetButton,
    flex: 1,
    marginLeft: 16,
    marginBottom: 16,
    position: "relative",
    width: 162,
    height: 162,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",

    background: colors.white,
    border: `1px solid ${colors.divider}`,

    borderRadius: 4,

    "&:hover": {
      background: "rgba(104, 133, 238, 0.25)",
      border: `1px solid ${colors.brand}`,

      "& $GoalsAddButtonIcon": {
        opacity: 0.2
      },
      "& $GoalsAddButtonIconPlus": {
        opacity: 1
      }
    },

    "@media (max-width: 1763px)": {
      minWidth: "30%",
      maxWidth: "32%",
      flexDirection: "row",
      height: 80
    },

    "@media (max-width: 1575px)": {
      minWidth: "40%",
      maxWidth: "49%"
    },

    "@media (max-width: 1279px)": {
      minWidth: "30%",
      maxWidth: "32%"
    },

    "@media (max-width: 1175px)": {
      minWidth: "40%",
      maxWidth: "49%"
    },
    "@media (max-width: 700px)": {
      width: "100%",
      minWidth: "100%",
      maxWidth: "100%"
    }
  },

  GoalsAddButtonIconPlus: {
    transition: "opacity 0.3s ease",
    opacity: 0,

    position: "absolute",
    zIndex: 1,
    display: "flex",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 1763px)": {
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: 16
    }
  },
  GoalsAddButtonIcon: {
    transition: "opacity 0.3s ease",
    marginTop: 20,
    marginBottom: 16,

    "@media (max-width: 1763px)": {
      "& svg": {
        width: 48,
        height: 48
      },

      marginTop: 16,
      marginBottom: 16,
      marginLeft: 30,
      marginRight: 24
    }
  },
  GoalsAddButtonTitle: {
    fontSize: 14,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.5px",

    color: colors.textPrimary,

    "@media (max-width: 1763px)": {
      ...styleMix.h3
    },

    "@media (max-width: 450px)": {
      fontSize: 16
    }
  },

  closeButton: {
    transition: "opacity 0.3s ease-out",

    position: "absolute",
    right: 24,
    top: 0,
    bottom: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",

    opacity: 0
  }
});
