import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconCarHome = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M38.3454 16.0417C38.0756 16.0141 37.8028 16 37.5279 16H24.4721C21.442 16 18.6719 17.712 17.3167 20.4223L14.8446 25.3666C14.2892 26.4774 14 27.7023 14 28.9443V39C14 39.3425 14.0652 39.6769 14.1847 40H8V20H4L24 6L38.3454 16.0417Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 28.9443C18 28.3233 18.1446 27.7108 18.4223 27.1554L20.8944 22.2111C21.572 20.856 22.957 20 24.4721 20H37.5279C39.043 20 40.428 20.856 41.1056 22.2111L43.5777 27.1554C43.8554 27.7108 44 28.3233 44 28.9443V39C44 39.5523 43.5523 40 43 40H41C40.4477 40 40 39.5523 40 39V38H22V39C22 39.5523 21.5523 40 21 40H19C18.4477 40 18 39.5523 18 39V28.9443ZM22.4472 23.1056C22.786 22.428 23.4785 22 24.2361 22H37.7639C38.5215 22 39.214 22.428 39.5528 23.1056L41.2764 26.5528C41.6088 27.2177 41.1253 28 40.382 28H21.618C20.8747 28 20.3912 27.2177 20.7236 26.5528L22.4472 23.1056ZM23.3333 30C24.8061 30 26 31.1939 26 32.6667C26 33.403 25.403 34 24.6667 34H22.6667C21.1939 34 20 32.8061 20 31.3333C20 30.597 20.597 30 21.3333 30H23.3333ZM38.6667 30C37.1939 30 36 31.1939 36 32.6667C36 33.403 36.597 34 37.3333 34H39.3333C40.8061 34 42 32.8061 42 31.3333C42 30.597 41.403 30 40.6667 30H38.6667Z"
        fill={fill}
      />
    </svg>
  );
};

IconCarHome.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconCarHome;
