import React from "react";

import { colors } from "constants/styles";

const IconCarHome = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8333 6.66675H31.6666V15.5691C30.6241 15.2006 29.5021 15.0001 28.3333 15.0001C27.4701 15.0001 26.6324 15.1095 25.8333 15.3151V6.66675ZM24.1666 15.9068V13.3334H18.3333V25.0001V33.3334H22.8037C20.1092 31.5419 18.3333 28.4784 18.3333 25.0001C18.3333 20.9643 20.7241 17.4868 24.1666 15.9068ZM10.8333 20.0001H16.6666V33.3334H10.8333V20.0001ZM3.33331 26.6667H9.16665V33.3334H3.33331V26.6667Z"
        fill={fill}
      />
      <path
        d="M28.3333 16.6667C29.4277 16.6667 30.5113 16.8823 31.5224 17.3011C32.5334 17.7199 33.4521 18.3337 34.2259 19.1075C34.9997 19.8813 35.6135 20.8 36.0323 21.8111C36.4511 22.8221 36.6667 23.9057 36.6667 25.0001C36.6667 27.2102 35.7887 29.3298 34.2259 30.8926C32.6631 32.4554 30.5435 33.3334 28.3333 33.3334C27.239 33.3334 26.1554 33.1179 25.1443 32.6991C24.1333 32.2803 23.2146 31.6665 22.4408 30.8926C20.878 29.3298 20 27.2102 20 25.0001C20 22.7899 20.878 20.6703 22.4408 19.1075C24.0036 17.5447 26.1232 16.6667 28.3333 16.6667ZM28.3333 18.3334C26.5652 18.3334 24.8695 19.0358 23.6193 20.286C22.369 21.5363 21.6667 23.232 21.6667 25.0001C21.6667 26.7682 22.369 28.4639 23.6193 29.7141C24.8695 30.9644 26.5652 31.6667 28.3333 31.6667C30.1014 31.6667 31.7971 30.9644 33.0474 29.7141C34.2976 28.4639 35 26.7682 35 25.0001C35 23.232 34.2976 21.5363 33.0474 20.286C31.7971 19.0358 30.1014 18.3334 28.3333 18.3334ZM27.5 29.1667V28.3334H25.8333V26.6667H29.1667V25.8334H26.6667C26.4457 25.8334 26.2337 25.7456 26.0774 25.5893C25.9211 25.4331 25.8333 25.2211 25.8333 25.0001V22.5001C25.8333 22.2791 25.9211 22.0671 26.0774 21.9108C26.2337 21.7545 26.4457 21.6667 26.6667 21.6667H27.5V20.8334H29.1667V21.6667H30.8333V23.3334H27.5V24.1667H30C30.221 24.1667 30.433 24.2545 30.5893 24.4108C30.7455 24.5671 30.8333 24.7791 30.8333 25.0001V27.5001C30.8333 27.7211 30.7455 27.9331 30.5893 28.0893C30.433 28.2456 30.221 28.3334 30 28.3334H29.1667V29.1667H27.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCarHome;
