import {
  addAdditionalConfiguration,
  commonPrefixAfter,
  prepareState,
  repeatableSchemeConf as annualChangeScheme
} from "../AssetsCommon/_formScheme";
import { RIGHT_HOUSEHOLD_ACCOUNTS } from "../../constants/rights";
import { defaultChanges, defaultChangesType } from "../../constants/inflation";

export const GLOBAL_TYPE_USE_ASSETS = "household-accounts ";
export const GLOBAL_COLLECTION = "userAssets";
export const GLOBAL_COLLECTION_USE_ASSETS = GLOBAL_COLLECTION;
export const RIGHT_SHARE = RIGHT_HOUSEHOLD_ACCOUNTS;
export const RIGHT_LEVEL = `${RIGHT_HOUSEHOLD_ACCOUNTS}-can`;

const ENTITY_CHECKING = "checking";
const ENTITY_SAVINGS = "savings";
const ENTITY_MONEY_MARKET = "moneyMarket";
const ENTITY_CD = "cd";

export const ENTITIES = {
  ENTITY_CHECKING,
  ENTITY_SAVINGS,
  ENTITY_MONEY_MARKET,
  ENTITY_CD
};

export const ENTITIES_DEBT = {};
export const ENTITIES_ADDITIONAL = {};

export const ACCOUNT_ENDING_SOON = 1;

export const optionsValuesMaturity = {
  RENEW: "renew",
  WITHDRAW: "withdraw",
  END: "end"
};

const optionsMaturity = [
  {
    name: "Renew",
    nameI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.FORM.MATURITY.TYPES.RENEW",
    value: optionsValuesMaturity.RENEW
  },
  {
    name: "Withdraw",
    nameI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.FORM.MATURITY.TYPES.WITHDRAW",
    value: optionsValuesMaturity.WITHDRAW
  }
];

export const ENTITIES_GROUPS = {
  "HOUSEHOLD.ASSETS.ACCOUNTS.SIDE_MENU_TITLE.SUBTITLE": {
    title: "Account Type",
    ENTITIES: {
      ENTITY_CHECKING,
      ENTITY_SAVINGS,
      ENTITY_MONEY_MARKET,
      ENTITY_CD
    }
  }
};

const repeatableSchemeGeneral = {
  description: {
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "HOUSEHOLD.ASSETS.ACCOUNT.FORM.DESCRIPTION.LABEL"
  },

  balance: {
    isMain: true,

    isRequired: true,
    isAmount: true,
    isNumber: true,
    label: "Balance",
    labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.BALANCE.LABEL"
  },
  interestRate: {
    isMain: true,

    prefix: "%",
    isRequired: true,
    isNumber: true,
    min: 0,
    label: "Interest Rate",
    labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.INTEREST_RATE.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_CHECKING]: {
    icon: "IconDocEdit",
    color: "green",
    title: "Checking",
    titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TILE.CHECKING"
  },

  [ENTITIES.ENTITY_SAVINGS]: {
    icon: "IconSaving",
    color: "green",
    title: "Savings",
    titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TILE.SAVINGS"
  },

  [ENTITIES.ENTITY_MONEY_MARKET]: {
    icon: "IconSaving",
    color: "green",
    title: "Money Market",
    titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TILE.MONEY_MARKET"
  },

  [ENTITIES.ENTITY_CD]: {
    icon: "IconCertificate",
    color: "green",
    title: "CD",
    titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TILE.CD"
  }
};

export const formScheme = {
  [ENTITIES.ENTITY_CHECKING]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNT.FORM.CHECKING.DESCRIPTION.LABEL"
    },
    balance: repeatableSchemeGeneral.balance
  },
  [ENTITIES.ENTITY_SAVINGS]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNT.FORM.SAVINGS.DESCRIPTION.LABEL"
    },
    balance: repeatableSchemeGeneral.balance,
    annualChangeType: {
      ...annualChangeScheme.annualChangeType,
      default: defaultChangesType["household-accounts "].default
    },
    interestRate: {
      ...annualChangeScheme.annualChange,
      default: defaultChanges["household-accounts "].default,
      label: "Interest Rate",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.INTEREST_RATE.LABEL"
    }
  },
  [ENTITIES.ENTITY_MONEY_MARKET]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNT.FORM.MONEY_MARKET.DESCRIPTION.LABEL"
    },
    balance: repeatableSchemeGeneral.balance,
    // interestRate: repeatableSchemeGeneral.interestRate,
    annualChangeType: {
      ...annualChangeScheme.annualChangeType,
      default: defaultChangesType["household-accounts "].moneyMarket
    },
    interestRate: {
      ...annualChangeScheme.annualChange,
      default: defaultChanges["household-accounts "].moneyMarket,
      label: "Interest Rate",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.INTEREST_RATE.LABEL"
    }
  },
  [ENTITIES.ENTITY_CD]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNT.FORM.CD.DESCRIPTION.LABEL"
    },
    balance: repeatableSchemeGeneral.balance,
    // interestRate: repeatableSchemeGeneral.interestRate,
    annualChangeType: {
      ...annualChangeScheme.annualChangeType,
      default: defaultChangesType["household-accounts "].cd
    },
    interestRate: {
      ...annualChangeScheme.annualChange,
      default: defaultChanges["household-accounts "].cd,
      label: "Interest Rate",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.INTEREST_RATE.LABEL"
    },

    depositDate: {
      isRequired: true,
      isDate: true,
      isPast: true,
      label: "Deposit Date",
      labelI18n: "HOUSEHOLD.MONEY_IN.FORM.DEPOSIT_DATE.LABEL"
    },
    term: {
      isAdditional: true,
      calculate: { isMonthsLeftPeriod: true, fieldDate: "depositDate" },
      expiredCalculate: { isMonthsLeftPeriod: true, fieldDate: "depositDate" },
      endingSoonCalculate: {
        isMonthsLeftPeriod: true,
        monthsLeftPeriod: ACCOUNT_ENDING_SOON,
        fieldDate: "depositDate"
      },

      after: commonPrefixAfter.MNTH,

      isRequired: true,
      isNumber: true,
      isInt: true,
      min: 0,
      labelCard: "Months Left",
      labelI18nCard: "HOUSEHOLD.ASSETS.ACCOUNTS.MONTHS_LEFT.LABEL",
      label: "Term",
      labelI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TERM.LABEL"
    },
    maturity: {
      isSelect: true,
      options: optionsMaturity,
      label: "On Maturity",
      labelI18n: "HOUSEHOLD.ASSETS.USE.FORM.HOME.MATURITY.LABEL"
    }
  }
};

const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.HOUSEHOLD.ACCOUNTS",
        title: "Bank Accounts"
      }
    },
    header: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.TITLE",
      title: "All your bank account balances. Across all institutions"
    },
    caption: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.CAPTION",
      title:
        "The distribution of your available cash across the different accounts"
    },
    emptyContent: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.EMPTY",
      title: "You have no accounts,\n please add your first accounts"
    },
    toolbarHeader: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.H2",
      title: "Accounts"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNT.TOAST.ADDED",
      title: "Account Added"
    },
    assetRemoved: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNT.TOAST.REMOVED",
      title: "Account Removed"
    },
    assetSaved: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNT.TOAST.SAVED",
      title: "Account Saved"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.SIDE_MENU_TITLE",
      title: "Add Account"
    },
    sideMenu: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.SIDE_MENU_TITLE.EDIT",
      title: "Edit Account"
    },
    sideGroup: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.SUBTITLE",
      title: "Account Type"
    },
    // generalAsset: {
    //   titleI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL_GROUP",
    //   title: "Group Of Dept",
    //   caption:
    //     "Sure, you can enter the total value of all your use assets, but we will not be able to predict their future value. To continue anyway, enter a value and select “Add General Dept”",
    //   captionI18n: "HOUSEHOLD.ASSETS.DEBT.GENERAL.CAPTION"
    // },
    buttonSave: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.BUTTON.SAVE",
      title: "Save Account"
    },
    buttonAdd: {
      titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.BUTTON.ADD",
      title: "+ Add Account"
    }
  },
  whyNeedIt: {
    titleI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.WHY.TITLE",
    textI18n: "HOUSEHOLD.ASSETS.ACCOUNTS.WHY.ALL_TEXT"
  }
};
