import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import "./SideMenu.scss";
import ButtonSideItem from "../Button/ButtonSideItem";
import ButtonForIcon from "../Button/ButtonForIcon";
import * as Icons from "components/_common/icons";

const SideMenu = ({
  children,
  title,
  titleI18n,
  bottom,
  handleCloseEdit,
  handleCloseRightDrawer,
  isOpen,
  isMobile,
  bgSecondary,
  isEdit,
  OpenDialogWhyNeedIt,
  WideDialog
}) => {
  // TODO DIALOG WHY NEED IT (waiting design)
  return (
    <div
      className={`main__side-menu side-menu ${
        isOpen ? "side-menu--open" : ""
      }  ${bgSecondary ? "side-menu--secondary" : ""} ${
        WideDialog ? "side-menu--new" : ""
      }`}
    >
      <section
        className={`side-menu__main ${
          bgSecondary ? "side-menu__main--secondary" : ""
        } ${WideDialog ? "side-menu--dialog" : ""}`}
      >
        <div className="side-menu__main-wrapper">
          {!isEdit && !!handleCloseEdit && (
            <ButtonSideItem
              topRight={true}
              isOpen={true}
              handleClick={handleCloseEdit}
            />
          )}

          {isMobile && !!handleCloseRightDrawer && (
            <div
              className={`side-menu__back ${
                isEdit ? "side-menu__back--edit" : ""
              }`}
            >
              <ButtonForIcon
                topRight={true}
                isOpen={true}
                handleClick={handleCloseRightDrawer}
              >
                <Icons.IconArrowLeft />
              </ButtonForIcon>
            </div>
          )}

          {!!title && !!titleI18n && (
            <div
              className={`side-menu__title ${
                isMobile ? "side-menu__title--mobile" : ""
              }`}
            >
              <FormattedMessage id={titleI18n} defaultMessage={title} />
            </div>
          )}

          {children}
        </div>

        <div className="side-menu__bottom">{bottom}</div>
      </section>

      {false && !isEdit && (
        <div className="side-menu__caption">
          <button
            className="side-menu__caption-button"
            onClick={OpenDialogWhyNeedIt}
          >
            <FormattedMessage
              id="APP.WHY_NEED_IT"
              defaultMessage="Why do we need this information?"
            />
          </button>
        </div>
      )}
    </div>
  );
};
SideMenu.propTypes = {
  title: PropTypes.string,
  titleI18n: PropTypes.string,
  bottom: PropTypes.any,
  handleCloseEdit: PropTypes.func,
  bgSecondary: PropTypes.bool
};

export default SideMenu;
