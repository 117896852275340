// import { combineReducers } from 'redux-immutable';
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import uiReducer from "./ui";

export default combineReducers({
  ui: uiReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});
