import React from "react";
import { FormattedMessage } from "react-intl-v3";

import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "constants/styles";
import { BREAKPOINT_SM } from "constants/styles";
import Button from "components/_common/Button/ButtonCommon";
import { NavLink } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { setUI } from "AC/ui";
import {
  getAuthExpireInState,
  getAuthIsEmptyState,
  getAuthIsLoadedState,
  getAuthIsVerifiedState,
  getProfileIsAdminApprovedState
} from "../store/selectors/firebase";

import { DASHBOARD } from "constants/routes";

const NotFoundPage = props => {
  const { isLoaded, isEmpty, isVerified, isAdminApproved } = props;

  const isLoggedIn = isLoaded && !isEmpty && isVerified && isAdminApproved;

  const { classes } = props;

  // TODO  hide drawer and button
  const homeLinkPath = isLoggedIn ? DASHBOARD : "/";

  return (
    <div className={classes.notFoundMain}>
      <div className="main-content main-content--not-found">
        <Paper className={classes.notFoundContent}>
          <div className={classes.title}>404</div>
          <div className={classes.subtitle}>
            <FormattedMessage
              id="APP.PAGE_NOT_FOUND.TITLE"
              defaultMessage="Oops!"
            />
          </div>
          <div>
            <FormattedMessage
              id="APP.PAGE_NOT_FOUND"
              defaultMessage="Sorry we can’t find this page!"
            />
          </div>

          <NavLink to={homeLinkPath} className="link-button">
            <Button style={{ marginTop: 16 }}>
              <FormattedMessage
                id="APP.PAGE_NOT_FOUND.LINK"
                defaultMessage="Go to Homepage"
              />
            </Button>
          </NavLink>
        </Paper>
      </div>
    </div>
  );
};

const styles = theme => ({
  notFoundMain: {
    height: "100%"
  },
  notFoundContent: {
    marginTop: 96,
    textAlign: "center",
    fontSize: "16px",
    maxWidth: "290px",
    width: "100%",
    padding: "17px 32px 32px 32px",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) translateY(calc(-100% - 30px))",
    // top: "50%",
    top: 128,
    boxShadow: "0px 8px 18px rgba(30, 50, 90, 0.2)",
    [theme.breakpoints.down(BREAKPOINT_SM)]: {
      transform: "translateX(-50%) translateY(calc(-50% - 30px))"
    },

    [theme.breakpoints.down("md")]: {
      top: 96
    },

    "&:after": {
      content: "''",
      display: "block",
      width: "28px",
      height: "16px",
      background: "url('/assets/img/arrow-small-bottom.svg') no-repeat center",
      position: "absolute",
      top: "auto",
      bottom: "-13px",
      left: "50%",
      transformOrigin: "50% 50%",
      transform: "translateX(-50%)",
      backgroundSize: "cover"
    },
    "&:before": {
      content: "''",
      display: "block",
      width: "16px",
      height: "16px",
      position: "absolute",
      top: "auto",
      bottom: "-20px",
      left: "50%",
      transform: "rotate(45deg) translateX(calc(-50% - 8px)) translateY(-3px)",
      boxShadow: "10px 10px 10px 0px rgba(0,0,0,0.11);"
    }
  },
  title: {
    color: colors.textPrimary,
    fontSize: "96px",
    fontWeight: "300",
    letterSpacing: "0.5px",
    lineHeight: "1"
  },
  subtitle: {
    color: colors.textSecondary,
    fontWeight: "700",
    fontSize: "20px",
    marginBottom: "10px"
  }
});

function mapStateToProps(state) {
  return {
    isMobile: state.ui.isMobile,

    isLoaded: getAuthIsLoadedState(state),
    isEmpty: getAuthIsEmptyState(state),
    isVerified: getAuthIsVerifiedState(state),
    expires: getAuthExpireInState(state),
    isAdminApproved: getProfileIsAdminApprovedState(state)
  };
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { setUI }
  )
)(NotFoundPage);
