import { colors } from "../../constants/styles";
import { commonWizardStyles } from "components/Quiz/DialogWizard.styles";

export const styles = theme => ({
  ...commonWizardStyles,

  categoryPaperScrollPaper: {
    height: "calc(100% - 55px)",
    maxHeight: 700
  },
  categoryHead: {
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",

    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",

    color: colors.textSecondary
  },
  categoryHeadWhatIf: {
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",

    borderRadius: "4px 4px 0 0",

    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",

    color: colors.white,
    backgroundColor: colors.ui
  },
  categoryHeadTitle: {
    marginLeft: 8,
    marginRight: "auto"
  },
  categoryHeadTitleWhatIf: {
    fontSize: 20,
    lineHeight: "26px",
    fontWeight: 700,
    textTransform: "none"
  },
  categoryBody: {
    position: "relative",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.backgroundDisabled
  },
  categoryFooter: {},
  categoryFooterWhatIf: {
    margin: 0,
    padding: "16px 24px"
  },
  TopTilesTitle: {
    display: "flex",
    alignItems: "center"
  },
  toleranceTooltip: {
    width: 20,
    height: 20,
    marginLeft: 16,
    marginRight: 16,
    // marginTop: 8,
    // marginBottom: 16,
    paddingBottom: 1,
    borderRadius: "50%",
    backgroundColor: colors.textTetrieary,
    fontSize: 12,
    lineHeight: "16px",
    color: colors.white,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    [theme.breakpoints.down("md")]: { marginTop: 1 }
  },
  switchLabel: {
    fontSize: 14,
    lineHeight: "21px",
    color: colors.textSecondary
  },

  ButtonOutUi: {
    marginTop: 12,
    padding: "8px 16px",
    fontSize: 14,
    lineHeight: "21px",
    fontWeight: 500,
    borderColor: colors.ui,
    color: colors.ui,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  ButtonOutBrand: {
    marginTop: 12,
    padding: "8px 16px",
    fontSize: 14,
    lineHeight: "21px",
    fontWeight: 500,
    borderColor: colors.brand,
    color: colors.brand,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  ButtonOutYellow: {
    marginTop: 12,
    padding: "8px 16px",
    fontSize: 14,
    lineHeight: "21px",
    fontWeight: 500,
    borderColor: colors.yellow,
    color: colors.yellow,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },

  progressGreen: {},
  linearColorGreen: {
    borderRadius: 4,
    backgroundColor: colors.divider
  },
  linearBarColorGreen: {
    borderRadius: 4,
    backgroundColor: colors.green
  },
  linearColorYellow: {
    borderRadius: 4,
    backgroundColor: colors.divider
  },
  linearBarColorYellow: {
    borderRadius: 4,
    backgroundColor: colors.yellow
  },
  linearColorBrand: {
    position: "relative",
    borderRadius: 4,
    backgroundColor: colors.divider,
    overflow: "inherit",

    "&:before": {
      content: "''",
      position: "absolute",
      top: -4,
      right: -1,

      display: "block",
      width: 2,
      height: 12,
      backgroundColor: colors.textSecondary
    }
  },
  linearBarColorBrand: {
    borderRadius: 4,
    backgroundColor: colors.brand
  },
  ActionAndGoals: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: -16,
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  }
});
