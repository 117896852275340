import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconMoneyBag = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M11.1 3C10.2 3 10.1473 3.16523 9.75 3.9C9.42305 4.50469 9.10664 4.49062 8.25938 4.49062C7.53516 4.49062 7.3207 5.05664 7.66875 5.55938C7.85508 5.82656 9.0082 7.92891 9.3 8.4H13.8C13.6875 7.24687 14.7176 5.1832 14.8687 4.96875C15.1289 4.60312 15.0656 3.81562 14.2781 3.81562C14.127 3.81562 13.8 3.9 12.9 3.9C12 3.9 12 3 11.1 3ZM9.3 9.3C5.41523 10.4637 3 14.6016 3 17.4C3 20.7152 7.02891 21 12 21C16.9711 21 21 21 21 17.4C21 14.4187 18.0715 10.1367 13.8 9.3H9.3ZM11.1 10.2H12V11.1562C13.0266 11.3637 13.8 12.2637 13.8 13.35H12C12 13.1004 11.7961 12.9 11.55 12.9C11.3039 12.9 11.1 13.1004 11.1 13.35C11.1 13.4801 11.1105 13.7965 11.6062 13.8C12.4465 13.8 13.8 14.7668 13.8 16.05C13.8 17.1363 13.0266 18.0363 12 18.2437V19.2H11.1V18.2437C10.0734 18.0363 9.3 17.1363 9.3 16.05H11.1C11.1 16.2996 11.3039 16.5 11.55 16.5C11.7961 16.5 12 16.2996 12 16.05C12 15.9199 11.9895 15.6035 11.4937 15.6C10.657 15.6 9.3 14.6332 9.3 13.35C9.3 12.2637 10.0734 11.3637 11.1 11.1562V10.2Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconMoneyBag.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconMoneyBag;
