import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import { compose } from "redux";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AssetCategoryCommon from "components/AssetsCommon/AssetCategoryCommon";
import { itemsSorted } from "store/selectors/firebase";

import {
  ENTITIES,
  GLOBAL_TYPE_USE_ASSETS,
  ENTITIES_GROUPS,
  ENTITIES_ADDITIONAL,
  formScheme,
  designScheme,
  formsValidationScheme,
  formsInitialState,
  GLOBAL_TYPE_USE_ASSETS as GLOBAL_TYPE_ASSETS_USE,
  GLOBAL_COLLECTION_USE_ASSETS as COLLECTION_ASSETS_USE,
  RIGHT_SHARE,
  RIGHT_LEVEL,
  translations
} from "./formScheme";

import {
  GLOBAL_TYPE_USE_ASSETS as GLOBAL_TYPE_ADDITIONAL,
  GLOBAL_COLLECTION as GLOBAL_COLLECTION_ADDITIONAL
} from "components/HouseholdDebt/formScheme";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";
import AccessForbidden from "../../routeHandlers/AccessForbidden";

const GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU = (
  <FormattedMessage id="DEBT.FORM.TOP.SHOW_DEBT" defaultMessage="Show Debt" />
);

export class HouseholdAssetsUse extends PureComponent {
  state = {
    isExpiredCalculated: false
  };

  calculateSummary = () => {
    const { assets } = this.props;

    if (!assets) {
      return null;
    }

    const summary = { total: 0 };

    Object.keys(designScheme).forEach(key => {
      summary[key] = 0;
    });

    assets.forEach(el => {
      if (
        el &&
        !el.isDisabled &&
        !el.isExpired &&
        !el.isWhatIf &&
        !el.isWhatIfTest
      ) {
        const value = +el.value || 0;

        summary[el.entity] += value;
        summary.total += value;
      }
    });

    return summary;
  };

  render() {
    const {
      auth,
      assets,
      profile,
      firestoreStatus,
      childs,
      debts,
      profileProvider
    } = this.props;

    if (
      !profile.isLoaded ||
      firestoreStatus.requesting.assets ||
      firestoreStatus.requesting.debts
    ) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareHousehold ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const isAccessEdit =
      isOwnProfile ||
      (this.props.rights && this.props.rights[RIGHT_LEVEL] === "1");

    let assetsWithDebts = [];
    if (assets) {
      assets.forEach(asset => {
        if (asset.debtId && debts) {
          return assetsWithDebts.push({ ...asset, debt: debts[asset.debtId] });
        }

        assetsWithDebts.push(asset);
      });
    }

    return (
      <AssetCategoryCommon
        isDialog={this.props.isDialog}
        isAccessEdit={isAccessEdit}
        profile={profile}
        childs={childs}
        assets={assetsWithDebts}
        summaryData={this.calculateSummary()}
        // assets={assets}
        formScheme={formScheme}
        auth={auth}
        ENTITIES={ENTITIES}
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        designScheme={designScheme}
        formsInitialState={formsInitialState}
        formsValidationScheme={formsValidationScheme}
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={COLLECTION_ASSETS_USE}
        GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU={
          GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU
        }
        translations={translations}
        additionalAssets={{ debts }}
        additionalSaveGlobalType={{
          debt: {
            globalType: GLOBAL_TYPE_ADDITIONAL,
            collection: GLOBAL_COLLECTION_ADDITIONAL,
            fieldId: "debtId",
            externalField: "assetId"
          }
        }}
        updateFieldGlobalType={{
          useToDebt: {
            globalType: GLOBAL_TYPE_ASSETS_USE,
            collection: COLLECTION_ASSETS_USE,
            fieldId: "assetId",
            externalField: "debtId"
          }
        }}
      />
    );
  }
}

HouseholdAssetsUse.propTypes = {
  isMobile: PropTypes.bool,
  auth: PropTypes.object,
  profile: PropTypes.object,
  assets: PropTypes.any,
  debts: PropTypes.any,
  childs: PropTypes.any,
  profileProvider: PropTypes.any,
  rights: PropTypes.any,
  firestoreStatus: PropTypes.any
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { isMobile, profileProvider }
}) {
  const rights = ordered && ordered.rights;

  return {
    isMobile,
    auth,
    profileProvider: profileProvider || auth.uid,
    profile,
    assets: ordered && itemsSorted(ordered.useAssets),
    debts: data && data.debts,
    rights: rights && rights[0],
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: COLLECTION_ASSETS_USE,
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_USE_ASSETS]
        ],
        storeAs: "useAssets",
        orderBy: ["entity", "asc"]
      },
      {
        collection: GLOBAL_COLLECTION_ADDITIONAL,
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_ADDITIONAL]
        ],
        storeAs: "debts",
        orderBy: ["entity", "asc"]
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(HouseholdAssetsUse));
