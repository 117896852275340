import { colors } from "../../constants/styles";

export const styles = theme => ({
  categoryBody: {
    padding: 0,
    backgroundColor: colors.backgroundDisabled,
    // display: "flex",
    // flexDirection: "column"
  },
  categoryFooter: {
    padding: 0,
    margin: 0,
  },
  sideForm: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",

    alignItems: "flex-start",
    "& > *": { width: "100%" }
  },

  sideFormOld: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    alignItems: "flex-start",
    "& > *": { width: "100%" }
  }
});
