export const LANDING = "/";
export const ABOUT = "/about";
export const LOG_IN = "/login";
export const SIGN_UP = "/signup";
export const SIGN_UP_ADVISOR = "/signup-advisor";
export const EMAIL_VERIFICATION = "/email-verification";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHANGE_PASSWORD = "/change-password";

// Private routes
export const QUIZ = "/quiz";

export const PROFILE = "/profile/setup";
export const ADVISOR_PERMISSIONS = "/profile/advisor-permissions";
export const ADVISOR_WIZARD_SETS = "/my-advisor/wizard-sets";
export const ADVISOR_CLIENT_MANAGE = "/my-advisor/manage-clients";

export const ADMIN_CLIENT_MANAGE_FULL = "/my-advisor/manage-clients-full";

export const CHATS = "/chats";

export const HOME = "/home/dashboard";
export const DASHBOARD = "/home/dashboard";
export const MY_PLANS = "/home/my-plans";
export const MY_HOUSEHOLD = "/home/my-household";
export const MY_OPERATION = "/home/my-operation";

export const PLANS = "/plans";
export const INTERESTS = "/plans/interests";
export const GOALS = "/plans/goals";
export const EVENTS = "/plans/events";

export const HOUSEHOLD_MAIN = "/household";
export const HOUSEHOLD_MEMBERS = "/household/family";
export const ASSETS_OWNED = "/household/assets-owned";
export const ASSETS_OWNED_USE = "/household/assets-owned/use-assets";
export const ASSETS_OWNED_SAVING = "/household/assets-owned/saving-retirement";
export const ASSETS_OWNED_INVESTED = "/household/assets-owned/invested-assets";

export const DEBT = "/household/debt";
export const ACCOUNTS = "/household/accounts";
export const MONEY_IN = "/household/money-in";
export const MONEY_IN_SALARIES = "/household/money-in/salaries-wages";
export const MONEY_IN_RENTS = "/household/money-in/rents";
export const MONEY_IN_OTHER = "/household/money-in/other";

export const MONEY_OUT = "/household/money-out";
export const MONEY_OUT_PERSONAL = "/household/money-out/personal";
export const MONEY_OUT_ASSETS = "/household/money-out/assets";
export const MONEY_OUT_OTHER = "/household/money-out/rent-lease";

export const CONFIGURATION_ASSETS_DEPLETED = "/configuration/assets-depleted";

export const MY_ADVISOR = "/my-advisor";

// export const OPERATIONS = "/operations";
export const ADVISOR_MANAGE_CLIENTS = "/my-advisor/manage-clients";

export const OPERATIONS_DASBOARD = "/my-advisor/dashboard";
export const OPERATIONS_CASHFLOW = "/my-advisor/cashflow";
export const OPERATIONS_BALANCES = "/my-advisor/balances";

// ADMIN
export const ADMIN_CALC_LOG = "/my-advisor/calcLog";
export const ADMIN_ADVISOR_APPROVE = "/my-advisor/advisor-approve";
