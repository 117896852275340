import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconSunLounger = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M11 2L7.625 9H10V17H11.75L12 16.8438V9H14.375L11 2ZM9.40625 3.0625L2 7L6.4375 9L9.40625 3.0625ZM12.5938 3.0625L15.5625 9L20 7L12.5938 3.0625ZM20.5 13.125L11.75 18H2V20H4V22H6V20H18V22H20V20H22V18H20.9375L20.25 15.5625L21.5 14.875L20.5 13.125ZM18.4375 16.5625L18.875 18H15.8438L18.4375 16.5625Z"
        fill={fill}
      />
    </svg>
  );
};

IconSunLounger.propTypes = {
  opacity: PropTypes.any,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSunLounger;
