import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconStudent = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M6.27273 16.6044V13.0489L12 16.4444L17.7273 13.0489V16.6044C17.7273 16.6044 16 20 12 20C8 20 6.27273 16.6044 6.27273 16.6044Z"
        fill={fill}
      />
      <path
        d="M3 9.33333L12 4L21 9.33333L12 14.6667L3 9.33333Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconStudent.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconStudent;
