import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconCreditCard = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M4 3C2.90625 3 2 3.90625 2 5V14C2 14.9883 2.73828 15.8164 3.6875 15.9688L3.53125 16.7812C3.30859 17.8516 3.99219 18.9375 5.0625 19.1562L18.7812 21.9688C19.8516 22.1914 20.9375 21.4766 21.1562 20.4062L22.9688 11.5938C23.1914 10.5234 22.4766 9.4375 21.4062 9.21875L20 8.9375V5C20 3.90625 19.0938 3 18 3H4ZM4 5H18V7H4V5ZM4 9H18V14H4V9ZM20 11L21 11.1875L20.5938 13.1562L20 13.0312V11ZM19.7188 15L20.1875 15.0938L19.1875 20L5.46875 17.1875L5.71875 16H18C18.7305 16 19.3711 15.5977 19.7188 15Z"
        fill={fill}
      />
    </svg>
  );
};

IconCreditCard.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconCreditCard;
