import {
  commonPrefixAfter,
  optionsTaxCategory,
  prepareState
} from "../AssetsCommon/_formScheme";

import { RIGHT_PLANS_EVENTS } from "../../constants/rights";
import { MAX_AGE } from "../../constants";

export const GLOBAL_TYPE_USE_ASSETS = "events";
export const GLOBAL_COLLECTION = "transactions";
export const GLOBAL_TYPE_COLLECTION = "out";
export const RIGHT_SHARE = RIGHT_PLANS_EVENTS;
export const RIGHT_LEVEL = `${RIGHT_PLANS_EVENTS}-can`;

const ENTITY_MONEY_IN = "moneyIn";
const ENTITY_MONEY_OUT = "moneyOut";
const ENTITY_LIFE_EVENT = "lifeEvent";

export const ENTITIES = {
  ENTITY_MONEY_IN,
  ENTITY_MONEY_OUT,
  ENTITY_LIFE_EVENT
};

export const ENTITIES_GROUPS = {
  "PLANS.EVENTS.FORM_GROUP.TITLE": {
    title: "Event type",
    ENTITIES: {
      ...ENTITIES
    }
  }
};

export const optionsAmountTypeValues = {
  FIXED: "fixed",
  VARIABLE: "variable"
};

const optionsAmountType = [
  {
    name: "Fixed",
    nameI18n: "PLANS.EVENTS.FORM.AMOUNT_TYPE.FIXED.LABEL",
    value: optionsAmountTypeValues.FIXED
  },
  {
    name: "Variable",
    nameI18n: "PLANS.EVENTS.FORM.AMOUNT_TYPE.VARIABLE.LABEL",
    value: optionsAmountTypeValues.VARIABLE
  }
];

const optionsOwnerNewborn = [
  {
    name: "Newborn",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.CHILDBIRTH.VALUE",
    value: "newborn"
  }
];
export const optionsOwnerFamily = [
  {
    name: "Family",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.FAMILY.LABEL",
    value: "family"
  }
];
const optionsOwner = [
  {
    name: "You",
    value: "You"
  }
];
export const optionsOwnerEventLifeValues = {
  NEWBORN: "newborn",
  FAMILY: "family"
};

export const optionsLifeEventTypeValues = {
  CHILDBIRTH: "childbirth",
  DEATH: "death",
  RELOCATION: "relocation",
  JOB_LOSS: "jobLoss",
  OTHER: "other"
};

const optionsLifeEventType = [
  {
    name: "Childbirth",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT_TYPE.CHILDBIRTH.LABEL",
    value: optionsLifeEventTypeValues.CHILDBIRTH
  },
  {
    name: "Death",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT_TYPE.DEATH.LABEL",
    value: optionsLifeEventTypeValues.DEATH
  },
  {
    name: "Job Loss/End",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT_TYPE.JOB_LOSS.LABEL",
    value: optionsLifeEventTypeValues.JOB_LOSS
  },
  {
    name: "Relocation",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT_TYPE.RELOCATION.LABEL",
    value: optionsLifeEventTypeValues.RELOCATION
  },
  {
    name: "Other",
    nameI18n: "PLANS.EVENTS.FORM.LIFE_EVENT_TYPE.OTHER.LABEL",
    value: optionsLifeEventTypeValues.OTHER
  }
];

const optionsMoneyOutType = [
  {
    name: "Insurance",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.INSURANCE.LABEL",
    value: "insurance"
  },
  {
    name: "Healthcare",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.HEALTHCARE.LABEL",
    value: "healthcare"
  },
  {
    name: "Education",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.EDUCATION.LABEL",
    value: "education"
  },
  {
    name: "Vacation",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.VACATION.LABEL",
    value: "vacation"
  },
  {
    name: "Debt repayment",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.DEBT_REPAYMENT.LABEL",
    value: "debt"
  },
  {
    name: "Other",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.OTHER.LABEL",
    value: "other"
  }
];

export const valuesMoneyInType = {
  GIFT: "gift",
  SOCIAL_SECURITY: "socialSecurity",
  INHERITANCE: "inheritance",
  ANNUITY: "annuity",
  RENT: "rent",
  BUSINESS_INTEREST: "business interest",
  SALE: "sale",
  SEVERANCE_PAY: "refund",
  OTHER: "other"
};

const optionsMoneyInType = [
  {
    name: "Gift",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.GIFT.LABEL",
    value: valuesMoneyInType.GIFT
  },
  {
    name: "Social Security",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.SOCIAL_SECURITY.LABEL",
    value: valuesMoneyInType.SOCIAL_SECURITY
  },
  {
    name: "Inheritance",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.INHERITANCE.LABEL",
    value: valuesMoneyInType.INHERITANCE
  },
  {
    name: "Annuity",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.ANNUITY.LABEL",
    value: valuesMoneyInType.ANNUITY
  },
  {
    name: "Rent",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.RENT.LABEL",
    value: valuesMoneyInType.RENT
  },
  {
    name: "Business Interest",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.BUSINESS_INTEREST.LABEL",
    value: valuesMoneyInType.BUSINESS_INTEREST
  },
  {
    name: "Sale",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.SALE.LABEL",
    value: valuesMoneyInType.SALE
  },
  {
    name: "Severance Pay",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.SEVERANCE_PAY.LABEL",
    value: valuesMoneyInType.SEVERANCE_PAY
  },
  {
    name: "Other",
    nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TYPES.OTHER.LABEL",
    value: valuesMoneyInType.OTHER
  }
];
//
// const optionsMoneyInTax = [
//   {
//     name: "Before Taxes",
//     nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TAX_TYPE.BEFORE.LABEL",
//     value: "before"
//   },
//   {
//     name: "After Taxes",
//     nameI18n: "PLANS.EVENTS.FORM.MONEY_IN.TAX_TYPE.AFTER.LABEL",
//     value: "after"
//   }
// ];

export const optionsOccurrenceValuesEvent = {
  ONCE: "once",
  ANNUAL: "annual"
};

export const optionsOccurrenceEvent = [
  {
    name: "Once",
    nameI18n: "PLANS.EVENTS.FORM.OCCURRENCE_TYPE.ONCE.LABEL",
    value: optionsOccurrenceValuesEvent.ONCE
  },
  {
    name: "Annual",
    nameI18n: "PLANS.EVENTS.FORM.OCCURRENCE_TYPE.EVERY_YEAR.LABEL",
    value: optionsOccurrenceValuesEvent.ANNUAL
  }
];
const repeatableSchemeGeneral = {
  description: {
    // isHidden: true,
    isSubTitle: true,
    isAdditional: true,
    isSecondary: true,

    isRequired: true,
    isFullWidth: true,
    label: "Description",
    labelI18n: "PLANS.EVENTS.FORM.DESCRIPTION.LABEL"
  },
  amountType: {
    isAdditional: true,

    isRequired: true,
    isSelect: true,
    // default: optionsAmountTypeValues.FIXED,
    options: optionsAmountType,

    label: "Amount type",
    labelI18n: "PLANS.EVENTS.FORM.AMOUNT_TYPE.LABEL"
  },

  amount: {
    isMain: true,
    calculate: { isEventAmount: true, inner: "contribution" },

    isAmount: true,
    isNumber: true,
    isRequired: true,
    label: "Amount",
    labelI18n: "PLANS.EVENTS.FORM.AMOUNT.LABEL"
  },
  variable: {
    // calculate: { isEventVariable: true, inner: "contribution" },

    condition: [
      {
        field: "amountType",
        value: optionsAmountTypeValues.VARIABLE
      }
    ],
    isNumber: true,
    isRequired: true,

    prefix: "%",
    label: "Variable",
    labelI18n: "PLANS.EVENTS.FORM.AMOUNT_TYPE.VARIABLE.LABEL"
  },

  annualChange: {
    isNumber: true,

    prefix: "%",
    label: "Annual Change",
    labelI18n: "HOUSEHOLD.COMMON.FORM.ANNUAL_CHANGE.LABEL"
  },
  goal: {
    isAdditional: true,
    isFullWidth: true,

    isAdditionalAsset: "goals",
    additionalAssetField: "description",
    optionsCustomAsset: "goals",
    fieldCustom: "goal",

    isSelect: true,
    isShowNoneOption: "None",
    label: "Associated Goal",
    labelI18n: "PLANS.EVENTS.FORM.ASSOCIATED_GOAL.LABEL"
  },
  startAge: {
    isAdditional: true,

    min: 0,
    max: MAX_AGE,
    minFromCustom: { isGeneralMemberAge: true },
    isNumber: true,
    isInt: true,
    isRequired: true,
    label: "At Age",
    labelI18n: "PLANS.EVENTS.FORM.AT_AGE.LABEL"
  },
  occurs: {
    isAdditional: true,

    isRequired: true,
    isSelect: true,
    options: optionsOccurrenceEvent,

    label: "Occurrence",
    labelI18n: "PLANS.EVENTS.FORM.OCCURRENCE.LABEL"
  },
  years: {
    isAdditional: true,

    condition: [
      {
        field: "occurs",
        value: "annual"
      }
    ],
    isNumber: true,
    isInt: true,
    isRequired: true,
    min: 0,
    max: 120,
    // after: commonPrefixAfter.YRS,
    after: commonPrefixAfter.YRS,
    label: "Number Of Years",
    labelI18n: "PLANS.EVENTS.FORM.NUMBER_OF_YEARS.LABEL"
  }
};

export const designScheme = {
  [ENTITIES.ENTITY_MONEY_IN]: {
    icon: "IconMoneyIn",
    color: "green",
    title: "Money In",
    titleI18n: "PLANS.EVENTS.TILE.MONEY_IN"
  },

  [ENTITIES.ENTITY_MONEY_OUT]: {
    icon: "IconMoneyOut",
    color: "yellow",
    title: "Money Out",
    titleI18n: "PLANS.EVENTS.TILE.MONEY_OUT"
  },

  [ENTITIES.ENTITY_LIFE_EVENT]: {
    icon: "IconClockRetry",
    color: "red",
    title: "Life Event",
    titleI18n: "PLANS.EVENTS.TILE.LIFE_EVENT"
  }
};

export const ENTITIES_ADDITIONAL = {};

export const formScheme = {
  [ENTITIES.ENTITY_MONEY_IN]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.EVENTS.FORM.MONEY_IN.DESCRIPTION.LABEL"
    },
    type: {
      isTitle: true,
      isRequired: true,
      isSelect: true,
      options: optionsMoneyInType,

      label: "Type",
      labelI18n: "PLANS.EVENTS.FORM.TYPE.LABEL"
    },
    amountType: repeatableSchemeGeneral.amountType,
    amount: {
      ...repeatableSchemeGeneral.amount,
      condition: [
        {
          field: "amountType",
          value: optionsAmountTypeValues.FIXED
        }
      ]
    },
    variable: repeatableSchemeGeneral.variable,
    variableShow: {
      isMain: true,
      isOnlyShow: true,
      calculate: { isEventVariable: true, inner: "contribution" },

      condition: [
        {
          field: "amountType",
          value: optionsAmountTypeValues.VARIABLE
        }
      ],
      label: "Variable",
      labelI18n: "PLANS.EVENTS.FORM.AMOUNT_TYPE.VARIABLE.LABEL"
    },
    annualChange: repeatableSchemeGeneral.annualChange,
    goal: repeatableSchemeGeneral.goal,
    assetId: {
      isAdditional: true,

      isAdditionalAsset: "assets",
      additionalAssetField: "description",
      // optionsCustomAsset: "assets",

      isSelect: true,
      optionsCustom: "custom-event-moneyIn",
      // optionsCustomAsset: "homes",
      // optionsCustom: "custom-assets-external",
      label: "Associated Asset",
      labelI18n: "PLANS.EVENTS.FORM.ASSOCIATED_ASSET.LABEL"
    },
    tax: {
      // isRequired: true,
      isSelect: true,
      // options: optionsMoneyInTax,
      options: optionsTaxCategory,

      label: "Tax",
      labelI18n: "PLANS.EVENTS.FORM.TAX.LABEL"
    },
    occurs: repeatableSchemeGeneral.occurs,
    startAge: repeatableSchemeGeneral.startAge,
    years: repeatableSchemeGeneral.years
  },
  [ENTITIES.ENTITY_MONEY_OUT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.EVENTS.FORM.MONEY_OUT.DESCRIPTION.LABEL"
    },
    type: {
      isTitle: true,
      isRequired: true,
      isSelect: true,
      options: optionsMoneyOutType,

      label: "Type",
      labelI18n: "PLANS.EVENTS.FORM.TYPE.LABEL"
    },
    amount: repeatableSchemeGeneral.amount,
    annualChange: repeatableSchemeGeneral.annualChange,
    goal: repeatableSchemeGeneral.goal,
    debtId: {
      isAdditional: true,
      isFullWidth: true,

      isAdditionalAsset: "debts",
      additionalAssetField: "description",
      optionsCustomAsset: "debts",
      fieldCustom: "debtId",

      isSelect: true,
      isShowNoneOption: "None",
      label: "Associated Debt",
      labelI18n: "PLANS.EVENTS.FORM.ASSOCIATED_DEBT.LABEL"
    },
    occurs: repeatableSchemeGeneral.occurs,
    startAge: repeatableSchemeGeneral.startAge,
    years: repeatableSchemeGeneral.years
  },
  [ENTITIES.ENTITY_LIFE_EVENT]: {
    description: {
      ...repeatableSchemeGeneral.description,
      label: "Description",
      labelI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.DESCRIPTION.LABEL"
    },
    type: {
      isTitle: true,
      isRequired: true,
      isSelect: true,
      options: optionsLifeEventType,
      showHelperJobLoss: {
        id: "PLANS.EVENTS.FORM.LIFE_EVENT.TYPE.JOB_LOSS.HELPER",
        message: "For severance pay, please also add a Money In event"
      },

      label: "Type",
      labelI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.TYPE.LABEL"
    },
    member: {
      isFullWidth: true,
      isRequired: true,

      isSelect: true,
      options: optionsOwner,
      optionsCustomNewBorn: optionsOwnerNewborn,
      optionsCustomRelocation: optionsOwnerFamily,
      optionsCustom: "custom-members-all",
      label: "Member",
      labelI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.MEMBER.LABEL",

      conditionDefault: [
        {
          field: "type",
          value: optionsLifeEventTypeValues.CHILDBIRTH,
          default: "newborn"
        },
        {
          field: "type",
          value: optionsLifeEventTypeValues.RELOCATION,
          default: "family"
        }
      ]
    },
    zipCode: {
      isRequired: true,
      isNumber: true,
      isInt: true,
      condition: [
        {
          field: "type",
          value: optionsLifeEventTypeValues.RELOCATION
        }
      ],
      label: "Zip Code",
      labelI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.ZIP_CODE.LABEL"
    },
    startAge: {
      ...repeatableSchemeGeneral.startAge,

      isMain: true,
      isAdditional: false,

      minFromCustom: { isOwner: true, field: "member" },
      label: "Expected Age",
      labelI18n: "PLANS.EVENTS.FORM.LIFE_EVENT.AT_AGE.LABEL"
    }
  }
};

const initialState = prepareState(ENTITIES_ADDITIONAL, formScheme);

export const formsInitialState = { ...initialState.formsInitialState };
export const formsValidationScheme = { ...initialState.formsValidationScheme };

export const translations = {
  mainContent: {
    section: {
      name: {
        titleI18n: "DRAWER.PLANS.EVENTS",
        title: "Events"
      }
    },
    header: {
      titleI18n: "PLANS.EVENTS.OTHER.TITLE",
      title: "Let’s note all your future events"
    },
    caption: {
      titleI18n: "PLANS.EVENTS.OTHER.CAPTION",
      title:
        "Add or edit any known event that adds money in or will spend it outz"
    },
    emptyContent: {
      titleI18n: "PLANS.EVENTS.OTHER.EMPTY",
      title: "You have no events,\n please add your first event"
    },
    toolbarHeader: {
      titleI18n: "PLANS.EVENTS.OTHER.H2",
      title: "Events"
    }
  },
  form: {
    fillAllRequired: {
      titleI18n: "APP.FORM.VALIDATION.FILL_ALL_REQUIRED",
      title: "Fill all required fields"
    }
  },
  toast: {
    assetAdded: {
      titleI18n: "PLANS.EVENTS.ADDED",
      title: "Event Added"
    },
    assetRemoved: {
      titleI18n: "PLANS.EVENTS.REMOVED",
      title: "Event Removed"
    },
    assetSaved: {
      titleI18n: "APP.SUCCESSFULLY_SAVED",
      title: "Saved successfully"
    }
  },
  edit: {
    sideMenuAdd: {
      titleI18n: "PLANS.EVENTS.SIDE_MENU_TITLE",
      title: "Add Event"
    },
    sideMenu: {
      titleI18n: "PLANS.EVENTS.SIDE_MENU_TITLE.EDIT",
      title: "Edit Event"
    },
    sideGroup: {
      titleI18n: "PLANS.EVENTS.FORM_GROUP.TITLE",
      title: "Event Type"
    },
    generalAsset: {
      titleI18n: "PLANS.EVENTS.GENERAL_GROUP",
      title: "General Expenses",
      caption:
        "Sure, you can enter the total value of all your spend expanses, but we will not be able to predict their future value. To continue anyway, enter a value and select “General Expanses”",
      captionI18n: "PLANS.EVENTS.GENERAL.CAPTION"
    },
    buttonSave: {
      titleI18n: "PLANS.EVENTS.FORM.BUTTON.SAVE",
      title: "Save Event"
    },
    buttonAdd: {
      titleI18n: "PLANS.EVENTS.FORM.BUTTON.EDIT",
      title: "+ Add Event"
    }
  },
  whyNeedIt: {
    titleI18n: "PLANS.EVENTS.WHY.TITLE",
    textI18n: "PLANS.EVENTS.WHY.ALL_TEXT"
  }
};
