import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "../reducer";

// import api from "../middlewares/api";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV !== "production"
});

export default function configureStore() {
  const initialState = {};

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        // api,
        logger
      )
    )
  );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("../reducer").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
