import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconDollarCrossed = ({
  height = 19,
  width = 23,
  color = "white",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 23"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g clipPath="url(#clipDollarCrossed)">
        <path
          d="M4.42518 7.82278C4.35839 8.09378 4.32341 8.37359 4.32341 8.65971C4.32341 8.82512 4.33212 8.98369 4.34896 9.13574C4.56341 11.0716 6.09707 11.9531 7.78147 12.5017C8.39943 12.703 9.03768 12.8595 9.63853 13.0068L9.73501 13.0297L4.42518 7.82278Z"
          fill={fill}
        />
        <path
          d="M12.9267 16.1595C12.743 16.4073 12.4895 16.631 12.1839 16.8188C11.8447 17.0273 11.4413 17.1915 10.9979 17.2952C10.6543 17.3756 10.2868 17.4195 9.90652 17.4195C8.08642 17.4195 6.55665 16.4121 6.55665 15.2296H4.32341C4.32341 17.1896 5.96484 18.8539 8.23158 19.4124V21.7994H11.5814V19.4124C11.9906 19.3116 12.3794 19.1747 12.7418 19.0066C13.1191 18.8316 13.4679 18.6227 13.7814 18.3853C14.0516 18.1807 14.2956 17.9548 14.509 17.7112L12.9267 16.1595Z"
          fill={fill}
        />
        <path
          d="M15.4724 15.5762C15.4838 15.4618 15.4896 15.3462 15.4896 15.2296C15.4896 12.4739 13.0738 11.6206 10.8599 11.0531L15.4724 15.5762Z"
          fill={fill}
        />
        <path
          d="M7.16001 7.42498L5.58701 5.88247C6.26747 5.23063 7.18256 4.73533 8.23158 4.47689V2.08984H11.5814V4.47689C13.8482 5.03533 15.4896 6.6997 15.4896 8.65971H13.2564C13.2564 7.47713 11.7266 6.46975 9.90652 6.46975C8.78339 6.46975 7.77081 6.85334 7.16001 7.42498Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7814 18.3853C14.0516 18.1807 14.2956 17.9548 14.509 17.7112L12.9267 16.1595C12.743 16.4073 12.4895 16.631 12.1839 16.8188L13.7814 18.3853ZM7.78147 12.5017C8.39943 12.703 9.03768 12.8595 9.63853 13.0068L9.73501 13.0297L4.42518 7.82278C4.35839 8.09378 4.32341 8.37359 4.32341 8.65971C4.32341 8.82512 4.33212 8.98369 4.34896 9.13574L7.78147 12.5017Z"
          fill={fill}
        />
        <path
          d="M4.32341 8.65971C4.32341 8.37359 4.35839 8.09378 4.42518 7.82278L9.73501 13.0297L9.63853 13.0068C9.03768 12.8595 8.39943 12.703 7.78147 12.5017L12.1839 16.8188C12.4895 16.631 12.743 16.4073 12.9267 16.1595L14.509 17.7112C14.2956 17.9548 14.0516 18.1807 13.7814 18.3853L16.4862 20.9989L18.0564 19.4591L2.51556 4.29703L0.945312 5.83684L4.34896 9.13574C4.33212 8.98369 4.32341 8.82512 4.32341 8.65971Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clipDollarCrossed">
          <rect width="19" height="23" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

IconDollarCrossed.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconDollarCrossed;
