import React from "react";
import PropTypes from "prop-types";

import * as Icons from "components/_common/icons";

import "./ButtonSideItem.scss";

const ButtonSideItem = ({
  children,
  label,
  color,
  title,
  titleI18n,
  isOpen,
  notHovered,
  handleClick,
  type,
  topRight = false
}) => {
  const classes = `button-side-item ${
    notHovered ? "button-side-item--not-hovered" : ""
  } ${topRight ? "button-side-item--top-right" : ""}`;

  return (
    <button className={classes} onClick={handleClick} type={type || "button"}>
      {!!label && label}

      {isOpen ? (
        <Icons.IconClose color={color ? color : "textTetrieary"} />
      ) : (
        <Icons.IconPlus color={color ? color : "textTetrieary"} />
      )}
    </button>
  );
};
ButtonSideItem.propTypes = {
  isOpen: PropTypes.bool,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "white"
  ])
};

export default ButtonSideItem;
