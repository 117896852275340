import { calculateInvestedPurchAmountClean } from "../_common/AssetCard";
import { MAX_AGE } from "../../constants";
import addYears from "date-fns/addYears";
import addMonths from "date-fns/addMonths";
import dateIsBefore from "date-fns/isBefore";
import dateIsAfter from "date-fns/isAfter";
import { INFLATION, SAndP, valuesInflation } from "../../constants/inflation";

export function isNotExpiredMonths(entity, { calcDate, t, calcAge }) {
  if (entity.contractStartDate && entity.contractStartDate.seconds) {
    const contractStartDate = new Date(entity.contractStartDate.seconds * 1000);

    const contractEndDate = addMonths(contractStartDate, entity.durationMonths);

    const isBefore = dateIsBefore(calcDate, contractEndDate);
    const isAfter = dateIsAfter(calcDate, contractStartDate);

    if (isBefore && isAfter) {
      return true;
    }

    return false;
  }

  return true;
}

export function getObjects(obj, key, val) {
  let objects = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == "object") {
      objects = objects.concat(getObjects(obj[i], key, val));
    } else if ((i === key && obj[i] === val) || (i === key && val === "")) {
      objects.push(obj);
    } else if (obj[i] === val && key === "") {
      if (objects.lastIndexOf(obj) === -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
}

export function valueFuncRSTByPrevContrib(entity, { calcAge, value, t }) {
  const contribution = entity.contribution || {};
  const annualContribution = +contribution.annualContribution || 0;

  const annualChange = value > 0 ? getAnnualChange(entity, { t }) : 0;

  const isFromAgeOk =
    !contribution.fromAge ||
    (contribution.fromAge && contribution.fromAge <= calcAge);

  const isToAgeOk =
    !contribution.toAge ||
    (contribution.toAge && contribution.toAge >= calcAge);

  let PMT = isFromAgeOk && isToAgeOk ? annualContribution : 0;

  const RST = value * (1 + annualChange / 100) + PMT;

  return RST;
}

export function valueFuncRSTESPP(entity, { t, value }) {
  const P = value;
  const annualChange = getAnnualChange(entity);

  const r = annualChange && t !== 0 ? annualChange / 100 : 0;

  const PMT = calculateInvestedPurchAmountClean(entity) * 2;

  const RST = P * (1 + r) + PMT;

  return RST;
}

export function valueFuncAnnualContrib(
  entity,
  { calcAge, isNotCheckAge = false } = {}
) {
  if (entity.contribution && entity.contribution.annualContribution) {
    const annualContribution = +entity.contribution.annualContribution;

    const isFromAgeOk =
      isNotCheckAge ||
      !entity.contribution.fromAge ||
      (entity.contribution.fromAge && entity.contribution.fromAge <= calcAge);

    const isToAgeOk =
      isNotCheckAge ||
      !entity.contribution.toAge ||
      (entity.contribution.toAge && entity.contribution.toAge >= calcAge);

    return isFromAgeOk && isToAgeOk ? annualContribution : 0;
  }

  return 0;
}

export function valueFuncTotalAnnualContrib(entity, { currentAge }) {
  if (entity.contribution && entity.contribution.annualContribution) {
    const annualContribution = +entity.contribution.annualContribution;
    const fromAge =
      entity.contribution.fromAge && entity.contribution.fromAge > currentAge
        ? entity.contribution.fromAge
        : currentAge;

    const toAge = entity.contribution.toAge || MAX_AGE;

    const totalAnnualContrib = annualContribution * (toAge - fromAge);

    return totalAnnualContrib;
  }

  return 0;
}

export function isMoneyOutNotExpiredYears(entity, { calcDate, t, calcAge }) {
  if (entity.startDate && entity.startDate.seconds) {
    const contractStartDate = new Date(entity.startDate.seconds * 1000);
    const contractEndDate = addYears(contractStartDate, entity.durationYears);

    const isBefore = dateIsBefore(calcDate, contractEndDate);
    const isAfter = dateIsAfter(calcDate, contractStartDate);

    if (isBefore && isAfter) {
      return true;
    }

    return false;
  }

  return true;
}

export function getAnnualChange(
  asset,
  { t, customField, customFieldType } = {}
) {
  let annualChange = 0;

  if (t === 0) {
    return annualChange;
  }

  let annualChangeValue;
  let annualChangeType;

  if (asset.configuration) {
    annualChangeValue = +asset.configuration.annualChange || 0;
    annualChangeType = asset.configuration.annualChangeType;
  }

  if (customField) {
    annualChangeValue = asset[customField];
  }

  if (customFieldType) {
    annualChangeType = asset[customFieldType];
  }

  if (annualChangeValue || annualChangeType) {
    if (annualChangeType === valuesInflation.CUSTOM) {
      annualChange = annualChangeValue;
    }

    if (annualChangeType === valuesInflation.INFLATION) {
      annualChange = INFLATION;
    }

    if (annualChangeType === valuesInflation.INFLATION_BY) {
      annualChange = INFLATION + annualChangeValue;
    }

    if (annualChangeType === valuesInflation.SANDP) {
      annualChange = SAndP;
    }

    if (annualChangeType === valuesInflation.SANDP_BY) {
      annualChange = SAndP + annualChangeValue;
    }

    if (!annualChangeType && annualChangeValue) {
      annualChange = annualChangeValue;
    }
  }

  return annualChange ? annualChange : 0;
}

export function getAnnualChangeDefault(
  asset,
  { t, customField, customFieldType } = {}
) {
  let annualChange = 0;

  let annualChangeValue;
  let annualChangeType;

  if (asset.configuration) {
    annualChangeValue = +asset.configuration.annualChange || 0;
    annualChangeType = asset.configuration.annualChangeType;
  }

  if (customField) {
    annualChangeValue = asset[customField];
  }

  if (customFieldType) {
    annualChangeType = asset[customFieldType];
  }

  if (annualChangeValue || annualChangeType) {
    if (annualChangeType === valuesInflation.CUSTOM) {
      annualChange = annualChangeValue;
    }

    if (annualChangeType === valuesInflation.INFLATION) {
      annualChange = INFLATION;
    }

    if (annualChangeType === valuesInflation.INFLATION_BY) {
      annualChange = INFLATION + annualChangeValue;
    }

    if (annualChangeType === valuesInflation.SANDP) {
      annualChange = SAndP;
    }

    if (annualChangeType === valuesInflation.SANDP_BY) {
      annualChange = SAndP + annualChangeValue;
    }

    if (!annualChangeType && annualChangeValue) {
      annualChange = annualChangeValue;
    }
  }

  return annualChange ? annualChange : 0;
}

export function commonRST(P, r, t, PMT) {
  const n = 1;
  const nt = t * n;
  const ntPmt = t + 1;

  const RST =
    P * Math.pow(1 + r / n, nt) +
    PMT * ((Math.pow(1 + r / n, nt) - 1) / (r / n));

  return RST;
}

export function commonRSTMultiplier(P, r, t, PMT, mainMultiplier, PMTMulti) {
  const n = 1;
  // const nt = t * n;

  const RSTBase = P * Math.pow(1 + r / n, mainMultiplier * n);

  const RSTAdditional =
    PMT * ((Math.pow(1 + r / n, PMTMulti * n) - 1) / (r / n));

  const RST = RSTBase + (RSTAdditional || PMT || 0);

  return RST;
}
