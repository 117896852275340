import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconAlertTile = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1,
  stroke = "#FFFFFF",
  strokeWidth = 0,
  strokeLinecap = "round"
}) => {
  // eslint-disable-next-line
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 16"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="20"
        height="17"
        fill="black"
      >
        <rect fill="white" y="-1" width="20" height="17" />
        <path d="M8.39307 2.88861C9.12093 1.7038 10.8791 1.7038 11.6069 2.88861L16.7301 11.228C17.4795 12.448 16.5798 14 15.1231 14H4.87688C3.42024 14 2.52052 12.448 3.26995 11.228L8.39307 2.88861Z" />
      </mask>
      <path
        d="M8.39307 2.88861C9.12093 1.7038 10.8791 1.7038 11.6069 2.88861L16.7301 11.228C17.4795 12.448 16.5798 14 15.1231 14H4.87688C3.42024 14 2.52052 12.448 3.26995 11.228L8.39307 2.88861Z"
        fill="#FF9D0A"
      />
      <path
        d="M3.26995 11.228L4.97407 12.2749L3.26995 11.228ZM16.7301 11.228L18.4342 10.1812L16.7301 11.228ZM11.6069 2.88861L9.90281 3.9355L9.90281 3.9355L11.6069 2.88861ZM8.39307 2.88861L10.0972 3.9355L8.39307 2.88861ZM9.90281 3.9355L15.0259 12.2749L18.4342 10.1812L13.3111 1.84173L9.90281 3.9355ZM15.1231 12H4.87688V16H15.1231V12ZM4.97407 12.2749L10.0972 3.9355L6.68895 1.84173L1.56583 10.1812L4.97407 12.2749ZM4.87688 12C4.87562 12 4.89814 12.0016 4.9261 12.0182C4.94912 12.0319 4.96536 12.0494 4.97642 12.0684C4.98749 12.0875 4.99975 12.1196 5 12.165C5.00028 12.2172 4.98421 12.2584 4.97407 12.2749L1.56583 10.1812C-0.0461216 12.8051 1.94084 16 4.87688 16V12ZM15.0259 12.2749C15.0158 12.2584 14.9997 12.2172 15 12.165C15.0003 12.1196 15.0125 12.0875 15.0236 12.0684C15.0346 12.0494 15.0509 12.0319 15.0739 12.0182C15.1019 12.0016 15.1244 12 15.1231 12V16C18.0592 16 20.0461 12.8051 18.4342 10.1812L15.0259 12.2749ZM13.3111 1.84173C11.8025 -0.61391 8.19751 -0.613908 6.68895 1.84173L10.0972 3.9355C10.087 3.95211 10.0651 3.9741 10.0389 3.98807C10.0177 3.99931 10.0044 4 10 4C9.99557 4 9.9823 3.99931 9.96115 3.98807C9.93488 3.9741 9.91301 3.95211 9.90281 3.9355L13.3111 1.84173Z"
        fill="white"
        mask="url(#path-1-outside-1)"
      />
      <rect x="9" y="10" width="2" height="2" fill="white" />
      <rect x="9" y="5" width="2" height="4" fill="white" />
    </svg>
  );
};

IconAlertTile.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconAlertTile;
