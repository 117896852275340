import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import PropTypes from "prop-types";

const ButtonStyled = ({
  classes,
  className,
  style,
  block,
  fullWidth,
  handleClick,
  onClick,
  children,
  buttonChartPlusMinus,
  buttonChart,
  buttonChartActive,
  buttonChartDot,
  buttonChartNegative,
  buttonChartDotNegative,
  buttonChartNegativeActive,
  buttonChartDotNegativeActive,
  buttonChartYear,
  buttonSmallAdvisor,
  type = "button",
  white,
  brand,
  yellow,
  ui,
  uiLight,
  disabled
}) => {
  return (
    <Button
      disabled={disabled}
      className={`${classes.button} ${className ? className : ""} ${
        buttonChartPlusMinus ? classes.buttonChartPlusMinus : ""
      } ${buttonChart ? classes.buttonChart : ""} ${
        buttonChartActive ? classes.buttonChartActive : ""
      } ${buttonChartYear ? classes.buttonChartYear : ""} ${
        buttonChartDot ? classes.buttonChartDot : ""
      } ${white ? classes.buttonWhite : ""} ${
        brand ? classes.buttonBrand : ""
      } ${yellow ? classes.buttonYellow : ""} ${
        uiLight ? classes.buttonUiLight : ""
      } ${buttonChartDotNegative ? classes.buttonChartDotNegative : ""}${
        buttonChartNegative ? classes.buttonChartNegative : ""
      } ${
        buttonChartDotNegativeActive ? classes.buttonChartDotNegativeActive : ""
      }${buttonChartNegativeActive ? classes.buttonChartNegativeActive : ""} ${
        ui ? classes.buttonUi : ""
      } ${buttonSmallAdvisor ? classes.buttonSmallAdvisor : ""}`}
      style={style || {}}
      onClick={handleClick || onClick}
      fullWidth={block || fullWidth}
      type={type}
    >
      {buttonChartDot ? (
        buttonChartDotNegative ? (
          <div className={classes.buttonChartDotInnerNegative} />
        ) : (
          <div className={classes.buttonChartDotInner} />
        )
      ) : (
        children
      )}
    </Button>
  );
};

ButtonStyled.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  block: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  white: PropTypes.bool,
  ui: PropTypes.bool,
  brand: PropTypes.bool,
  yellow: PropTypes.bool,
  uiLight: PropTypes.bool,
  buttonChartPlusMinus: PropTypes.bool,
  buttonChart: PropTypes.bool,
  buttonChartDot: PropTypes.bool,
  buttonChartDotNegative: PropTypes.bool,
  buttonChartNegative: PropTypes.bool,
  buttonChartNegativeActive: PropTypes.bool,
  buttonChartDotNegativeActive: PropTypes.bool,
  buttonChartActive: PropTypes.bool,
  buttonChartYear: PropTypes.bool,
  buttonSmallAdvisor: PropTypes.bool
};

export default withStyles(styles)(ButtonStyled);
