import React from "react";

import { colors } from "constants/styles";

const IconOperation = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          d="M3 7H9V13H3V7ZM3 3H21V5H3V3ZM21 7V9H11V7H21ZM21 11V13H11V11H21ZM3 15H17V17H3V15ZM3 19H21V21H3V19Z"
          fill={fill}
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
};

export default IconOperation;
