import React from "react";

import { colors } from "constants/styles";

const IconQuizSpouseWork = ({
  width = 58,
  height = 113,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 58 113"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <circle
        opacity="0.1"
        cx="29"
        cy="12"
        r="12"
        fill="#333456"
        fillOpacity="0.6"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6567 28H33.3426C35.0833 28 36.0624 27.9999 36.9516 28.1233C41.3061 28.7272 45.0603 31.491 46.9305 35.4694C47.3124 36.2818 47.6032 37.2167 48.1203 38.8787L57.0055 67.4383C58.1729 71.1908 55.3688 75 51.4388 75C50.6806 75 49.9478 74.8534 49.2724 74.5828L49.5162 75.2863C50.1222 77.0342 50.6137 78.4521 50.8298 79.5892C51.0535 80.7659 51.0285 81.865 50.349 82.8197C49.6694 83.7745 48.6392 84.158 47.4541 84.3321C46.3089 84.5003 44.8082 84.5003 42.9582 84.5003H42V106C42 109.866 38.866 113 35 113C32.4537 113 30.2249 111.64 29 109.608C27.7751 111.64 25.5463 113 23 113C19.134 113 16 109.866 16 106V84.5003H15.0406C13.1906 84.5003 11.69 84.5003 10.5447 84.3321C9.35964 84.158 8.32941 83.7745 7.64987 82.8197C6.97034 81.865 6.94538 80.7659 7.16903 79.5892C7.38515 78.4521 7.87666 77.0342 8.4826 75.2863L8.72639 74.583C8.05114 74.8534 7.31851 75 6.56046 75C2.63049 75 -0.173683 71.1908 0.993779 67.4383L9.8789 38.8789C10.396 37.2168 10.6868 36.2818 11.0688 35.4694C12.939 31.491 16.6932 28.7272 21.0476 28.1233C21.9368 27.9999 22.916 28 24.6567 28Z"
        fill="#333456"
        fillOpacity="0.6"
      />
      <circle
        cx="29"
        cy="12"
        r="11"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M28.5 29H33.2474C35.0786 29 35.9942 29 36.8146 29.1138C40.8341 29.6713 44.2995 32.2224 46.0259 35.8948C46.3782 36.6444 46.6502 37.5186 47.1942 39.2672L56.051 67.7353C57.0182 70.8442 54.695 74 51.4392 74V74C49.3807 74 47.5484 72.6953 46.875 70.75L39 48"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17 84V106C17 109.314 19.6863 112 23 112V112C26.3137 112 29 109.314 29 106V84"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M39 48L48.5447 75.5349C49.8028 79.1644 50.4319 80.9792 49.5348 82.2396C48.6378 83.5 46.717 83.5 42.8756 83.5H17"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.5 29H24.7526C22.9214 29 22.0058 29 21.1854 29.1138C17.1659 29.6713 13.7005 32.2224 11.9741 35.8948C11.6218 36.6444 11.3498 37.5186 10.8058 39.2672L1.94901 67.7353C0.981798 70.8442 3.30497 74 6.56083 74V74C8.61934 74 10.4516 72.6953 11.125 70.75L19 48"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19 48L9.4553 75.5349C8.19716 79.1644 7.56809 80.9792 8.46517 82.2396C9.36225 83.5 11.283 83.5 15.1244 83.5H41"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M41 84V106C41 109.314 38.3137 112 35 112V112C31.6863 112 29 109.314 29 106V84"
        stroke="#2A293D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M26 34L28 31H30L32 34L30 37L32 60L29 62L26 60L28 37L26 34Z"
        fill="#2A293D"
      />
    </svg>
  );
};

export default IconQuizSpouseWork;
