import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconInterests = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          d="M12 2C6.48828 2 2 6.48828 2 12C2 17.5117 6.48828 22 12 22C17.5117 22 22 17.5117 22 12C22 6.48828 17.5117 2 12 2ZM12 4C16.4297 4 20 7.57031 20 12C20 16.4297 16.4297 20 12 20C7.57031 20 4 16.4297 4 12C4 7.57031 7.57031 4 12 4ZM15 6L12.125 9.5625L11.5625 9C11.1328 8.57031 10.4297 8.57031 10 9L9 10L11 12L7 16V17H8L12 13L14 15L15 14C15.4297 13.5703 15.4297 12.8672 15 12.4375L14.4375 11.875L18 9L15 6Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

IconInterests.propTypes = {
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconInterests;
