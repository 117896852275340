import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconRetirement = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9998 6C13.6191 6 12.4998 4.88071 12.4998 3.5C12.4998 2.11929 13.6191 1 14.9998 1C16.3805 1 17.4998 2.11929 17.4998 3.5C17.4998 4.88071 16.3805 6 14.9998 6ZM12.676 5.38242C11.7827 4.73275 10.5319 4.93025 9.88218 5.82355L5.88218 11.3236C5.61679 11.6885 5.49277 12.113 5.49979 12.5319V21.5C5.49979 22.3284 6.17137 23 6.99979 23C7.82822 23 8.49979 22.3284 8.49979 21.5V15.3534L10.4346 17.5032L10.009 21.3344C9.91748 22.1577 10.5108 22.8994 11.3341 22.9908C12.1575 23.0823 12.8991 22.489 12.9906 21.6657L13.4906 17.1657C13.5379 16.7402 13.4011 16.3148 13.1147 15.9966L9.97045 12.5029L11.8632 9.90033C12.4437 10.4626 13.2729 11.145 14.1838 11.4486C15.117 11.7597 16.0242 11.7534 16.6729 11.6813C16.789 11.6685 16.8984 11.6533 17 11.637V23H18V11.3662C18.4035 11.1339 18.6016 10.6426 18.4487 10.1837C18.2745 9.6612 17.7108 9.37816 17.188 9.54989L13.1967 8.05829C13.7484 7.17576 13.5306 6.00398 12.676 5.38242ZM17.6667 11L17.5 10.5C17.5699 10.7095 17.6242 10.8728 17.6666 11H17.6667ZM13.1446 8.35536C13.1137 8.32447 13.0834 8.29376 13.0538 8.26333L13.1171 8.17624C13.1451 8.13772 13.1716 8.09854 13.1965 8.05876L17.1857 9.55063C17.1808 9.55217 17.1701 9.55543 17.1551 9.55976C17.1251 9.56843 17.076 9.5819 17.0106 9.59747C16.8791 9.62877 16.6857 9.66762 16.4521 9.69358C15.9758 9.7465 15.383 9.74019 14.8162 9.55128C14.2991 9.37889 13.6868 8.89756 13.1446 8.35536Z"
        fill={fill}
      />
    </svg>
  );
};

IconRetirement.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconRetirement;
