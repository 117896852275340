import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { entitiesFull, globalTypes } from "../AssetsCommon/formSchemeFull";
import { optionsValuesHome } from "../HouseholdAssetsUse/formScheme";
import { FormattedMessage } from "react-intl-v3";
import HouseholdCard from "../HouseholdMembers/HouseholdCard";
import { withStyles } from "@material-ui/core";
import { styles } from "./DahboardActionAndGoals.styles";
import { IconClose } from "../_common/icons";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import DialogRemoveAsset from "../AssetsCommon/DialogRemoveAsset";

class DashboardActions extends PureComponent {
  static propTypes = {
    configuration: PropTypes.any,
    auth: PropTypes.any,
    firestore: PropTypes.any,
    family: PropTypes.any,
    members: PropTypes.any,
    assetsAll: PropTypes.any,
    transactionsAll: PropTypes.any,
    myAdvisors: PropTypes.any,
    handleOpenCategoryDialog: PropTypes.func,
    handleOpenEditGoal: PropTypes.func,
    monetaryNegativeYear: PropTypes.any,
    monetaryNegativeYearWhatIf: PropTypes.any
  };

  state = {
    accounts: false,
    income: false,
    advisors: false,
    moneyOutProperty: false,
    moneyOutPersonal: false,
    primaryHouse: false,
    rentedHouse: false,
    kidsLess18: false,
    goalEducation: false,

    assetsOnMount: false,
    transactionsOnMount: false,
    membersOnMount: false,
    advisorsOnMount: false,

    advise: null
  };

  componentDidMount() {
    if (this.props.assetsAll) {
      this.setState({ assetsOnMount: true });
      this.updateStateAssets();
    }

    if (this.props.transactionsAll) {
      this.setState({ transactionsOnMount: true });
      this.updateStateTransactions();
    }

    if (this.props.members) {
      this.setState({ membersOnMount: true });
      this.updateStateMembers();
    }
    if (this.props.advisors) {
      this.setState({ advisorsOnMount: true });
      this.updateStateAdvisors();
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { assetsAll, transactionsAll, members, myAdvisors } = this.props;
    const {
      assetsOnMount,
      transactionsOnMount,
      membersOnMount,
      advisorsOnMount
    } = this.state;

    if (assetsAll !== prevProps.assetsAll || (assetsAll && !assetsOnMount)) {
      this.updateStateAssets();
    }

    if (
      transactionsAll !== prevProps.transactionsAll ||
      (transactionsAll && !transactionsOnMount)
    ) {
      this.updateStateTransactions();
    }

    if (members !== prevProps.members || (members && !membersOnMount)) {
      this.updateStateMembers();
    }

    if (
      myAdvisors !== prevProps.myAdvisors ||
      (myAdvisors && !advisorsOnMount)
    ) {
      this.updateStateAdvisors();
    }
  }

  updateStateAssets = () => {
    const { assetsAll } = this.props;

    let newState = {
      accounts: false,
      primaryHouse: false,
      income: false,
      goalEducation: false
    };

    assetsAll.forEach(asset => {
      if (asset.isDisabled) {
        return;
      }

      if (asset.globalType === globalTypes.globalTypeAccounts) {
        newState.accounts = true;
        return;
      }

      if (
        asset.globalType === globalTypes.globalTypeUse &&
        asset.entity === entitiesFull.entitiesUse.ENTITY_HOME &&
        asset.type === optionsValuesHome.PRIMARY
      ) {
        newState.primaryHouse = true;
        return;
      }

      if (asset.globalType === globalTypes.globalTypeMoneyIn) {
        newState.income = true;
        return;
      }

      if (
        asset.globalType === globalTypes.globalTypeGoals &&
        asset.entity === entitiesFull.entitiesGoals.ENTITY_EDUCATION
      ) {
        newState.goalEducation = true;
      }
    });

    this.setState(newState);
  };

  updateStateTransactions = () => {
    const { transactionsAll } = this.props;

    let newState = {
      moneyOutProperty: false,
      moneyOutPersonal: false,
      rentedHouse: false
    };

    transactionsAll.forEach(asset => {
      if (asset.isDisabled) {
        return;
      }

      if (
        !newState.moneyOutProperty &&
        asset.globalType === globalTypes.globalTypeMoneyOutAssets
      ) {
        newState.moneyOutProperty = true;
        return;
      }

      if (
        !newState.moneyOutPersonal &&
        asset.globalType === globalTypes.globalTypeMoneyOutPersonal
      ) {
        newState.moneyOutPersonal = true;
        return;
      }

      if (
        !newState.rentedHouse &&
        asset.globalType === globalTypes.globalTypeMoneyOutOther &&
        asset.entity === entitiesFull.entitiesMoneyOutOther.ENTITY_HOUSE_RENT
      ) {
        newState.rentedHouse = true;
      }
    });

    this.setState(newState);
  };

  updateStateMembers = () => {
    const { members } = this.props;

    let newState = { kidsLess18: false };

    if (members) {
      Object.values(members).forEach(member => {
        if (member.age < 18) {
          newState.kidsLess18 = true;
        }
      });
    }

    if (this.state.kidsLess18 !== newState.kidsLess18) {
      this.setState(newState);
    }
  };

  updateStateAdvisors = () => {
    const { myAdvisors } = this.props;

    let newState = { advisors: myAdvisors && Object.keys(myAdvisors).length };

    if (this.state.advisors !== newState.advisors) {
      this.setState(newState);
    }
  };

  handleRemoveAdvise = () => {
    const { advise } = this.state;

    const { auth, profileProvider, firestore, configuration } = this.props;

    const userUid = profileProvider || auth.uid;

    let configActionsAdvise = { [advise]: true };

    if (configuration && configuration.actionsAdvise) {
      configActionsAdvise = {
        ...configuration.actionsAdvise,
        ...configActionsAdvise
      };
    }

    firestore[configuration ? "update" : "set"](`configuration/${userUid}`, {
      actionsAdvise: configActionsAdvise,
      clientId: userUid
    });

    this.setState({ advise: null });
  };

  _renderItemAccounts = () => {
    const {
      classes = {},
      handleOpenCategoryDialog,
      configuration
    } = this.props;

    if (
      this.state.accounts ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.accounts)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.ACCOUNTS"
          defaultMessage="Finish connecting your bank <link>accounts</link>"
          values={{
            anchor: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdAccounts")}
              >
                {msg}
              </button>
            )
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("accounts")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  _renderItemAdvisor = () => {
    const { classes = {}, configuration } = this.props;

    if (
      this.state.advisors ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.advisors)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.ADVISOR"
          defaultMessage="Consider <link>adding an advisor</link> to collaborate with to your account"
          values={{
            anchor: msg => (
              <NavLink
                to={ROUTES.ADVISOR_PERMISSIONS}
                className={classes.ActionButton}
              >
                {msg}
              </NavLink>
            )
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("advisors")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  handleFixChartAndScroll = monetaryNegativeYear => () => {
    const chartContainer = document.querySelector(".content-main");

    chartContainer.scrollIntoView({
      block: "start",
      inline: "start",
      behavior: "smooth"
    });

    this.props.handleFixChart(monetaryNegativeYear)();
  };

  _renderItemMoneyOut = () => {
    const {
      classes = {},
      monetaryNegativeYear,
      monetaryNegativeYearWhatIf,
      configuration
    } = this.props;

    if (
      !(monetaryNegativeYear || monetaryNegativeYearWhatIf) ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.monetaryNegative)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.MONEY_RUNS_OUT.WHAT_IF"
          values={{
            anchor: () =>
              monetaryNegativeYear ? (
                <button
                  onClick={this.handleFixChartAndScroll(monetaryNegativeYear)}
                  className={classes.ActionButton}
                >
                  {monetaryNegativeYear}
                </button>
              ) : null,
            anchor2: () =>
              monetaryNegativeYearWhatIf &&
              monetaryNegativeYearWhatIf !== monetaryNegativeYear ? (
                <button
                  onClick={this.handleFixChartAndScroll(
                    monetaryNegativeYearWhatIf
                  )}
                  className={classes.ActionButton}
                >
                  {!monetaryNegativeYear ? (
                    <>{monetaryNegativeYearWhatIf} (what-if)</>
                  ) : (
                    <>(what-if {monetaryNegativeYearWhatIf})</>
                  )}
                </button>
              ) : null
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("monetaryNegative")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  _renderItemIncome = () => {
    const {
      classes = {},
      handleOpenCategoryDialog,
      configuration
    } = this.props;

    if (
      this.state.income ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.income)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.NO_INCOME"
          defaultMessage="Define your <link>income sources</link>"
          values={{
            anchor: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdMoneyIn")}
              >
                {msg}
              </button>
            )
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("income")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  _renderItemExpenses = () => {
    const {
      classes = {},
      handleOpenCategoryDialog,
      configuration
    } = this.props;

    if (
      (this.state.moneyOutPersonal && this.state.moneyOutProperty) ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.moneyOut)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.NO_PERSONAL_OR_PROPERTY_EXPENSE"
          defaultMessage="Add missing <link>personal</link> or <link2>property</link2> expenses"
          values={{
            anchor: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdMoneyOutPersonal")}
              >
                {msg}
              </button>
            ),
            link2: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdMoneyOutAssets")}
              >
                {msg}
              </button>
            )
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("moneyOut")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };
  _renderItemPrimaryHouse = () => {
    const {
      classes = {},
      handleOpenCategoryDialog,
      configuration
    } = this.props;

    if (
      this.state.primaryHouse ||
      this.state.rentedHouse ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.primaryHouse)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.PRIMARY_HOME"
          defaultMessage="Add your <link>primary residence</link> (owned) or <link2>rented house</link2> expense"
          values={{
            anchor: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdUse")}
              >
                {msg}
              </button>
            ),
            link2: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenCategoryDialog("householdMoneyOutOther")}
              >
                {msg}
              </button>
            )
          }}
        />

        <div
          className={classes.closeButton}
          onClick={this.handleDialogRemoveOpen("primaryHouse")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  _renderItemEducation = () => {
    const { classes = {}, configuration, handleOpenEditGoal } = this.props;

    if (
      this.state.goalEducation ||
      (configuration &&
        configuration.actionsAdvise &&
        configuration.actionsAdvise.goalEducation)
    ) {
      return null;
    }

    return (
      <li className={classes.actionItem}>
        <FormattedMessage
          id="DASHBOARD.ACTIONS.KID_EDUCATION"
          defaultMessage="Consider adding an <link>education savings goal</link> for your kids higher education"
          values={{
            anchor: msg => (
              <button
                className={classes.ActionButton}
                onClick={handleOpenEditGoal(
                  null,
                  entitiesFull.entitiesGoals.ENTITY_EDUCATION
                )}
              >
                {msg}
              </button>
            )
          }}
        />

        <div
          className={classes.closeButton}
          // onClick={this.handleRemoveAdvise("goalEducation")}
          onClick={this.handleDialogRemoveOpen("goalEducation")}
        >
          <IconClose color={"textTetrieary"} iconTitle={""} />
        </div>
      </li>
    );
  };

  handleDialogRemoveAgree = () => {
    this.handleRemoveAdvise(); // new
  };

  handleDialogRemoveOpen = advise => () => {
    this.setState({
      advise
    });
  };

  handleDialogRemoveClose = () => {
    this.setState({
      advise: ""
    });
  };

  render() {
    const { classes } = this.props;

    const title = (
      <div>
        <div className={classes.title}>
          <FormattedMessage
            id="DASHBOARD.ACTIONS.TITLE"
            defaultMessage="Actions"
          />
        </div>

        <div className={classes.subTitle}>
          <FormattedMessage
            id="DASHBOARD.ACTIONS.CAPTION"
            defaultMessage="Your dream future awaits. Right after you attend to these..."
          />
        </div>
      </div>
    );

    return (
      <HouseholdCard
        titleEl={title}
        classNames={classes.ActionAndGoalsBlock}
        style={{ flex: 1 }}
      >
        <DialogRemoveAsset
          title={
            <FormattedMessage
              id="DASHBOARD.ACTIONS.WARNING_ERASE"
              defaultMessage="Are you sure you no longer want to see this tip?"
            />
          }
          isOpen={!!this.state.advise}
          handleAgreeAndClose={this.handleDialogRemoveAgree}
          handleClose={this.handleDialogRemoveClose}
        />

        <ul className={classes.actions}>
          {this._renderItemAccounts()}

          {this._renderItemAdvisor()}

          {this._renderItemMoneyOut()}

          {this._renderItemIncome()}

          {this._renderItemExpenses()}

          {this._renderItemPrimaryHouse()}

          {this._renderItemEducation()}
        </ul>
      </HouseholdCard>
    );
  }
}

export default withStyles(styles)(DashboardActions);
