import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconSalaries = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M14.5 8C16.433 8 18 6.433 18 4.5C18 2.567 16.433 1 14.5 1C12.567 1 11 2.567 11 4.5C11 6.433 12.567 8 14.5 8Z"
        fill={fill}
      />
      <path
        d="M9 8C9 9.10457 8.10457 10 7 10C5.89543 10 5 9.10457 5 8C5 6.89543 5.89543 6 7 6C8.10457 6 9 6.89543 9 8Z"
        fill={fill}
      />
      <path
        d="M3.12991 9.01499C2.73179 9.04037 2.47705 9.10873 2.29289 9.29289C2 9.58579 2 10.0572 2 11V20C2 20.9428 2 21.4142 2.29289 21.7071C2.58579 22 3.05719 22 4 22H19C19.9428 22 20.4142 22 20.7071 21.7071C21 21.4142 21 20.9428 21 20V18.9746C20.742 19 20.4172 19 20 19H16C15.0572 19 14.5858 19 14.2929 18.7071C14 18.4142 14 17.9428 14 17V14C14 13.0572 14 12.5858 14.2929 12.2929C14.5858 12 15.0572 12 16 12H20C20.4172 12 20.742 12 21 12.0254V11C21 10.0572 21 9.58579 20.7071 9.29289C20.4142 9 19.9428 9 19 9H13.7602L10.8727 9.00481C10.427 10.7276 8.86211 12 7 12C5.14156 12 3.57914 10.7326 3.12991 9.01499Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1464 13.1464C15 13.2929 15 13.5286 15 14V17C15 17.4714 15 17.7071 15.1464 17.8536C15.2929 18 15.5286 18 16 18H21C21.4714 18 21.7071 18 21.8536 17.8536C22 17.7071 22 17.4714 22 17V14C22 13.5286 22 13.2929 21.8536 13.1464C21.7071 13 21.4714 13 21 13H16C15.5286 13 15.2929 13 15.1464 13.1464ZM17.5 16.5C18.0523 16.5 18.5 16.0523 18.5 15.5C18.5 14.9477 18.0523 14.5 17.5 14.5C16.9477 14.5 16.5 14.9477 16.5 15.5C16.5 16.0523 16.9477 16.5 17.5 16.5Z"
        fill={fill}
      />
    </svg>
  );
};

IconSalaries.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSalaries;
