import React, { Component } from "react";

import ForgotPassword from "./ForgotPassword";
import { withFirebase } from "react-redux-firebase";

class ForgotPasswordConnected extends Component {
  render() {
    return <ForgotPassword {...this.props} />;
  }
}

export default withFirebase(ForgotPasswordConnected);
