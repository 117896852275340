import React, { lazy, Suspense } from "react";

import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import { Providers } from "lib";
import PrivateRoute from "./routeHandlers/PrivateRoute";
import configureStore from "store";

import ContainerToast from "components/ContainerApp/ContainerToast";

import NotFoundPage from "./routeHandlers/NotFoundPage";
import About from "./components/About";
import LandingPage from "./components/LandingPage";

import LogoutDev from "./components/LandingPage/LogoutDev";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import EmailVerification from "./components/SignUp/EmailVerification";
import AdminApproveAdvisor from "./components/admin/AdminApproveAdvisor";

import Profile from "./components/Profile/Profile";

import Quiz from "./components/Quiz";
import AppContainer from "./components/ContainerApp/AppContainer";

// import Dashboard from "./components/Dashboard";
// import DashboardAdvisor from "./components/DashboardAdvisor/DashboardAdvisor";

// Plans
// import PlansMain from "./components/PlansMain/PlansMain";
// import PlansInterests from "./components/PlansInterests/Interests";
// import PlansGoals from "./components/PlansGoals/Goals";
// import PlansEvents from "./components/PlansEvents/Events";

// Household
import HouseholdMembers from "./components/HouseholdMembers/HouseholdMembers";
// import HouseholdMain from "./components/HouseholdMain/HouseholdMain";
// import HouseholdAssets from "./components/HouseholdAssets";

// import HouseholdAssetsUse from "./components/HouseholdAssetsUse/HouseholdAssetsUse";
// import HouseholdAssetsSavings from "./components/HouseholdAssetsSavings/HouseholdAssetsSavings";
// import HouseholdAssetsInvested from "./components/HouseholdAssetsInvested/HouseholdAssetsInvested";

// import HouseholdDebt from "./components/HouseholdDebt/HouseholdDebt";
//
// import HouseholdAccounts from "./components/HouseholdAccounts/HouseholdAccounts";
// import HouseholdMoneyIn from "./components/HouseholdMoneyIn/HouseholdMoneyIn";
//
// // import HouseholdMoneyOutMain from "./components/HouseholdMoneyOutMain/MoneyOutMain";
// import MoneyOutPersonal from "./components/MoneyOutPersonal/MoneyOutPersonal";
// import MoneyOutAssets from "./components/MoneyOutAssets/MoneyOutAssets";
// import MoneyOutOther from "./components/MoneyOutOther/MoneyOutOther";

import * as ROUTES from "constants/routes";
import IsLoading from "./routeHandlers/IsLoadingAuth";
import CalcContainerNew from "./components/Dashboard/CalcContainerNew";

const PermissionsAdvisor = lazy(() =>
  import("./components/PermissionsAdvisor")
);

const Operations = lazy(() =>
  import("./components/Dashboard/CalcContainerNew")
);
const WizardSets = lazy(() =>
  import("./components/DashboardAdvisor/WizardSets")
);
const ManageClientFull = lazy(() =>
  import("./components/DashboardAdvisor/ManageClientFull")
);
const ManageClient = lazy(() =>
  import("./components/DashboardAdvisor/ManageClient")
);
const ConfigurationOverdraft = lazy(() =>
  import("./components/Configuration/ConfigurationOverdraft")
);

// const AdminCalcLogContainer = lazy(() =>
//   import("./components/Dashboard/CalcContainerNew")
// );

const DashboardCalcContainer = lazy(() =>
  import("./components/Dashboard/CalcContainerNew")
);

// const DashboardAdvisor = lazy(() =>
//   import("./components/DashboardAdvisor/DashboardAdvisor")
// );
const Chats = lazy(() => import("./components/Chats/Chats"));

const PlansInterests = lazy(() =>
  import("./components/PlansInterests/Interests")
);
const PlansGoals = lazy(() => import("./components/PlansGoals/Goals"));
const PlansEvents = lazy(() => import("./components/PlansEvents/Events"));

const HouseholdAssetsUse = lazy(() =>
  import("./components/HouseholdAssetsUse/HouseholdAssetsUse")
);
const HouseholdAssetsSavings = lazy(() =>
  import("./components/HouseholdAssetsSavings/HouseholdAssetsSavings")
);
const HouseholdAssetsInvested = lazy(() =>
  import("./components/HouseholdAssetsInvested/HouseholdAssetsInvested")
);

const MoneyOutPersonal = lazy(() =>
  import("./components/MoneyOutPersonal/MoneyOutPersonal")
);
const MoneyOutAssets = lazy(() =>
  import("./components/MoneyOutAssets/MoneyOutAssets")
);
const MoneyOutOther = lazy(() =>
  import("./components/MoneyOutOther/MoneyOutOther")
);
const HouseholdDebt = lazy(() =>
  import("./components/HouseholdDebt/HouseholdDebt")
);

const HouseholdAccounts = lazy(() =>
  import("./components/HouseholdAccounts/HouseholdAccounts")
);
const HouseholdMoneyIn = lazy(() =>
  import("./components/HouseholdMoneyIn/HouseholdMoneyIn")
);

const store = configureStore();

export default (
  <Providers store={store}>
    <ContainerToast>
      <BrowserRouter>
        <Switch>
          {/*TODO Container for public pages*/}
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route exact path={"/logout"} component={LogoutDev} />
          <Route path={ROUTES.ABOUT} component={About} />

          <Route exact path={ROUTES.LOG_IN} component={LogIn} />
          <Route
            exact
            path={ROUTES.SIGN_UP}
            render={props => <SignUp {...props} isAdvisorSignup={false} />}
          />
          <Route
            exact
            path={ROUTES.SIGN_UP_ADVISOR}
            render={props => <SignUp {...props} isAdvisorSignup={true} />}
          />
          <Route
            path={ROUTES.EMAIL_VERIFICATION}
            component={EmailVerification}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />

          <PrivateRoute path={ROUTES.QUIZ} component={Quiz} />

          <AppContainer>
            <Suspense fallback={<IsLoading />}>
              <Switch>
                <PrivateRoute path={ROUTES.PROFILE} component={Profile} />
                {/*<PrivateRoute path={ROUTES.OPERATIONS} component={Operations} />*/}
                <PrivateRoute
                  path={ROUTES.OPERATIONS_CASHFLOW}
                  component={Operations}
                  isAdvisorRoute
                />
                <PrivateRoute
                  path={ROUTES.ADVISOR_WIZARD_SETS}
                  component={WizardSets}
                  isAdvisorRoute
                />

                <PrivateRoute
                  path={ROUTES.ADVISOR_CLIENT_MANAGE}
                  component={ManageClient}
                  isAdvisorRoute
                />

                <PrivateRoute
                  path={ROUTES.ADMIN_CLIENT_MANAGE_FULL}
                  component={ManageClientFull}
                  isAdvisorRoute
                  isAdminRoute
                />

                <PrivateRoute
                  path={ROUTES.CHATS}
                  component={Chats}
                />

                <PrivateRoute
                  path={ROUTES.ADVISOR_PERMISSIONS}
                  component={PermissionsAdvisor}
                />

                <PrivateRoute
                  path={ROUTES.CONFIGURATION_ASSETS_DEPLETED}
                  component={ConfigurationOverdraft}
                />

                <Redirect
                  exact
                  path={ROUTES.MY_ADVISOR}
                  to={ROUTES.OPERATIONS_DASBOARD}
                />

                {/*<PrivateRoute*/}
                {/*  path={ROUTES.OPERATIONS_DASBOARD}*/}
                {/*  component={DashboardAdvisor}*/}
                {/*/>*/}
                <PrivateRoute
                  path={ROUTES.OPERATIONS_DASBOARD}
                  component={CalcContainerNew}
                  isAdvisorRoute
                />

                {/* ADMIN */}
                <PrivateRoute
                  path={ROUTES.ADMIN_CALC_LOG}
                  component={DashboardCalcContainer}
                  isAdvisorRoute
                />

                <PrivateRoute
                  path={ROUTES.ADMIN_ADVISOR_APPROVE}
                  component={AdminApproveAdvisor}
                  isAdvisorRoute
                />

                <PrivateRoute
                  path={ROUTES.DASHBOARD}
                  component={DashboardCalcContainer}
                />
                {/*<PrivateRoute*/}
                {/*  path={ROUTES.DASHBOARD + 2}*/}
                {/*  component={Dashboard2CalcContainer}*/}
                {/*/>*/}
                {/*<PrivateRoute path={ROUTES.DASHBOARD + 2} component={Dashboard} />*/}

                {/* PLANS */}
                {/*<PrivateRoute exact path={ROUTES.PLANS} component={PlansMain} />*/}
                <Redirect exact path={ROUTES.PLANS} to={ROUTES.INTERESTS} />

                <PrivateRoute
                  exact
                  path={ROUTES.INTERESTS}
                  component={PlansInterests}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.GOALS}
                  component={PlansGoals}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.EVENTS}
                  component={PlansEvents}
                />

                {/* Household */}

                <Redirect
                  exact
                  path={ROUTES.HOUSEHOLD_MAIN}
                  to={ROUTES.HOUSEHOLD_MEMBERS}
                />
                {/*<PrivateRoute*/}
                {/*  exact*/}
                {/*  path={ROUTES.HOUSEHOLD_MAIN}*/}
                {/*  component={HouseholdMain}*/}
                {/*/>*/}
                <PrivateRoute
                  exact
                  path={ROUTES.HOUSEHOLD_MEMBERS}
                  component={HouseholdMembers}
                />

                <Redirect
                  exact
                  path={ROUTES.ASSETS_OWNED}
                  to={ROUTES.ASSETS_OWNED_USE}
                />
                {/*<PrivateRoute*/}
                {/*  exact*/}
                {/*  path={ROUTES.ASSETS_OWNED}*/}
                {/*  component={HouseholdAssets}*/}
                {/*/>*/}
                <PrivateRoute
                  exact
                  path={ROUTES.ASSETS_OWNED_USE}
                  component={HouseholdAssetsUse}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.ASSETS_OWNED_SAVING}
                  component={HouseholdAssetsSavings}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.ASSETS_OWNED_INVESTED}
                  component={HouseholdAssetsInvested}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.DEBT}
                  component={HouseholdDebt}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.ACCOUNTS}
                  component={HouseholdAccounts}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.MONEY_IN}
                  component={HouseholdMoneyIn}
                />

                <Redirect
                  exact
                  path={ROUTES.MONEY_OUT}
                  to={ROUTES.MONEY_OUT_PERSONAL}
                />
                {/*<PrivateRoute*/}
                {/*  exact*/}
                {/*  path={ROUTES.MONEY_OUT_PERSONAL}*/}
                {/*  component={HouseholdMoneyOutMain}*/}
                {/*/>*/}

                <PrivateRoute
                  exact
                  path={ROUTES.MONEY_OUT_PERSONAL}
                  component={MoneyOutPersonal}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.MONEY_OUT_ASSETS}
                  component={MoneyOutAssets}
                />

                <PrivateRoute
                  exact
                  path={ROUTES.MONEY_OUT_OTHER}
                  component={MoneyOutOther}
                />

                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Suspense>
          </AppContainer>
        </Switch>
      </BrowserRouter>
    </ContainerToast>
  </Providers>
);
