import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";

import { compose } from "redux";
import { withFirebase, withFirestore } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

import IsLoading from "../../routeHandlers/IsLoadingAuth";

import AssetCategoryCommon from "components/AssetsCommon/AssetCategoryCommon";

import {
  ENTITIES,
  GLOBAL_TYPE_USE_ASSETS,
  GLOBAL_COLLECTION,
  // GLOBAL_TYPE_COLLECTION,
  ENTITIES_GROUPS,
  ENTITIES_ADDITIONAL,
  formScheme,
  designScheme,
  formsValidationScheme,
  formsInitialState,
  RIGHT_SHARE,
  RIGHT_LEVEL,
  optionsOccursEveryValueMult,
  translations
} from "./formScheme";

import {
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalHomes,
  ENTITIES as ENTITIES_USE
} from "../HouseholdAssetsUse/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as QueryGlobalSavings,
  ENTITIES as ENTITIES_SAVINGS
} from "../HouseholdAssetsSavings/formScheme";
import {
  getNotDisabledEntities,
  itemsSorted
} from "../../store/selectors/firebase";
import AccessForbidden from "../../routeHandlers/AccessForbidden";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";

const GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU = (
  <FormattedMessage
    id="DEBT.FORM.TOP.SHOW_CONTRIBUTION"
    defaultMessage="Show Contribution"
  />
);

export class HouseholdMoneyIn extends Component {
  calculateSummary = () => {
    const { assets } = this.props;

    if (!assets) {
      return null;
    }

    const summary = { total: 0 };

    Object.keys(designScheme).forEach(key => {
      summary[key] = 0;
    });

    assets.forEach(el => {
      if (
        el &&
        !el.isDisabled &&
        !el.isExpired &&
        !el.isWhatIf &&
        !el.isWhatIfTest
      ) {
        if (el.entity === ENTITIES.ENTITY_OTHER) {
          let value = 0;

          if (el.occurs === "every") {
            const multiplier = optionsOccursEveryValueMult[el.every] || 1;

            value = +el.amount * multiplier;
          } else {
            value = +el.amount;
          }

          summary[el.entity] += value || 0;
          summary.total += value || 0;
          return;
        }

        if (el.entity === ENTITIES.ENTITY_SALARIES) {
          let value = +el.baseSalary + +el.variable;

          summary[el.entity] += value || 0;
          summary.total += value || 0;
          return;
        }

        if (el.entity === ENTITIES.ENTITY_RENT) {
          let value =
            +el.monthlyRent * 12 -
            (el.averageVacancy * el.monthlyRent * 12) / 365;

          summary[el.entity] += value || 0;
          summary.total += value || 0;
          return;
        }

        const value = +el.value || 0;

        summary[el.entity] += value;
        summary.total += value;
      }
    });

    return summary;
  };

  render() {
    const {
      auth,
      assets,
      profile,
      childs,
      firestoreStatus,
      homes,
      savings,
      profileProvider
    } = this.props;

    if (
      !profile ||
      !profile.isLoaded ||
      firestoreStatus.requesting.assets ||
      firestoreStatus.requesting.childs
    ) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareHousehold ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const isAccessEdit =
      isOwnProfile ||
      (this.props.rights && this.props.rights[RIGHT_LEVEL] === "1");

    return (
      <AssetCategoryCommon
        isDialog={this.props.isDialog}
        isAccessEdit={isAccessEdit}
        profile={profile}
        childs={childs}
        assets={assets}
        summaryData={this.calculateSummary()}
        formScheme={formScheme}
        auth={auth}
        ENTITIES={ENTITIES}
        ENTITIES_GROUPS={ENTITIES_GROUPS}
        ENTITIES_ADDITIONAL={ENTITIES_ADDITIONAL}
        designScheme={designScheme}
        formsInitialState={formsInitialState}
        formsValidationScheme={formsValidationScheme}
        GLOBAL_TYPE_USE_ASSETS={GLOBAL_TYPE_USE_ASSETS}
        GLOBAL_COLLECTION={GLOBAL_COLLECTION}
        GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU={
          GLOBAL_TYPE_SHOW_ADDITIONAL_FORM_MENU
        }
        translations={translations}
        additionalAssets={{ homes, savings }}
      />
    );
  }
}

HouseholdMoneyIn.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  childs: PropTypes.any,
  assets: PropTypes.any,
  homes: PropTypes.any,
  savings: PropTypes.any,
  profileProvider: PropTypes.any,
  rights: PropTypes.any
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { profileProvider }
}) {
  const rights = ordered && ordered.rights;

  return {
    auth,
    profile,
    profileProvider: profileProvider || auth.uid,
    assets: ordered && itemsSorted(ordered.assetsMoneyIn),
    childs: ordered && ordered.childs,
    homes: ordered && getNotDisabledEntities(ordered.homes), // TODO is only disabled?
    savings: ordered && getNotDisabledEntities(ordered.assetSavings401k), // TODO is only disabled?
    rights: rights && rights[0],
    firestoreStatus: status
  };
}

export default compose(
  withFirestore,
  connect(
    mapStateToProps,
    {}
  ),
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      // {
      //   collection: "users",
      //   doc: profileUid,
      //   subcollections: [{ collection: "childs" }],
      //   orderBy: ["birthday", "asc"],
      //   storeAs: "childs"
      // },
      {
        collection: "userAssets",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", GLOBAL_TYPE_USE_ASSETS]
        ],
        storeAs: "assetsMoneyIn",
        orderBy: ["entity", "asc"]
      },
      {
        collection: "userAssets",
        storeAs: "homes",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", QueryGlobalHomes],
          ["entity", "==", ENTITIES_USE.ENTITY_HOME]
        ]
      },
      {
        collection: "userAssets",
        storeAs: "assetSavings401k",
        where: [
          ["user", "==", profileUid],
          ["globalType", "==", QueryGlobalSavings],
          ["entity", "==", ENTITIES_SAVINGS.ENTITY_401K]
        ]
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(HouseholdMoneyIn));
