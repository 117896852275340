import React from "react";
import PropTypes from "prop-types";

import "./SideGroupItem.scss";
import { FormattedMessage } from "react-intl-v3";

import IconContainer from "components/_common/IconContainer";
import ButtonSideItem from "components/_common/Button/ButtonSideItem";
import ButtonForIcon from "../Button/ButtonForIcon";
import * as Icons from "../icons";

const defaultFunc = () => {};

const SideGroupItem = ({
  children,
  Icon,
  color,
  caption,
  captionI18n,
  title,
  titleI18n,
  isOpen,
  isMobile,
  general,
  isDialog,
  isEdit,
  handleClickToggle,
  bgSecondary = false
}) => {
  const classNameWrapper = `side-item__wrapper ${
    !isMobile && isOpen && handleClickToggle ? "side-item__wrapper--open" : ""
  } ${bgSecondary ? "side-item__wrapper--general" : ""} ${
    isOpen ? "side-item__wrapper--full-height" : ""
  }`;

  let titleToShow = title;

  if (titleI18n) {
    titleToShow = <FormattedMessage id={titleI18n} defaultMessage={title} />;
  }

  const top = (
    <div
      className={`side-item__top ${
        !isOpen && handleClickToggle ? "side-item__top--clickable" : ""
      } ${isEdit ? "side-item__top--edit" : ""}`}
    >
      {isMobile && isOpen && !!handleClickToggle && (
        <div className="side-item__button-back">
          <ButtonForIcon // topRight={true}
            isOpen={true}
            handleClick={handleClickToggle}
          >
            <Icons.IconArrowLeft />
          </ButtonForIcon>
        </div>
      )}

      <div className="side-item__icon">
        <IconContainer width={32} height={32} color={color}>
          {!Icon ? (
            <Icons.IconOther width={24} height={24} color="ui" />
          ) : (
            <Icon width={24} height={24} color={color} />
          )}
        </IconContainer>
      </div>

      <div className="side-item__label">{titleToShow || "Asset"}</div>

      {isMobile && !isOpen && !!handleClickToggle && (
        <ButtonSideItem
          type="button"
          isOpen={isOpen}
          handleClick={handleClickToggle}
        />
      )}

      {!isMobile && !!handleClickToggle && (
        <ButtonSideItem
          type="button"
          isOpen={isOpen}
          handleClick={handleClickToggle}
        />
      )}
    </div>
  );

  if (isDialog) {
    return top;
  }

  return (
    <div className="side-item">
      <div
        className={classNameWrapper}
        onClick={!isOpen && handleClickToggle ? handleClickToggle : defaultFunc}
      >
        {top}

        {caption && captionI18n && (
          <div className="side-item__caption">
            <FormattedMessage id={captionI18n} defaultMessage={caption} />
          </div>
        )}

        {isOpen && <div className="side-item__content">{children}</div>}
      </div>
    </div>
  );
};
SideGroupItem.propTypes = {
  icon: PropTypes.element,
  color: PropTypes.oneOf([
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "white",
    "textTetrieary"
  ]),
  title: PropTypes.string,
  titleI18n: PropTypes.string,
  caption: PropTypes.string,
  captionI18n: PropTypes.string,
  general: PropTypes.bool,
  bgSecondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleClickToggle: PropTypes.func,
  handleCloseEdit: PropTypes.func
};

export default SideGroupItem;
