import React, { Component } from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import * as Icons from "components/_common/icons";
import CommonTopStep from "./CommonTopStep";
import TooltipTitle from "../../_common/TooltipTitle";
import Slider from "@material-ui/lab/Slider";
import FormControlInput from "../../_common/FormControl/FormControlInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { formatMoneyIntWithSign } from "../../../lib/helpers";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { WIZARD_ASSETS_DEBT } from "constants/wizardSetDefault";
import ButtonMUI from "@material-ui/core/Button/Button";

class WizardAssetsDebt extends Component {
  componentWillUnmount() {
    this.props.handleSaveStep(this.props.currentStep)();
  }

  _renderContent = () => {
    const { classes, formState, handleInputChange } = this.props;

    const totalDebt =
      (+formState.interestDebt || 0) +
      (+formState.remains || 0) +
      (+formState.otherDebt || 0);
    return (
      <div className={classes.wizardBodyForm}>
        <div className={classes.wizardFormContainer}>
          <div className={classes.wizardFormLeft}>
            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS_DEBT.FORM.DEBT_REMAINS"
                  defaultMessage="If you have mortgage, how much debt remains?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="remains"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assetsDebt")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.remains}
                />
                <Slider
                  min={WIZARD_ASSETS_DEBT.DEBT_REMAINS_MIN}
                  max={
                    formState.remains > WIZARD_ASSETS_DEBT.DEBT_REMAINS_MAX
                      ? formState.remains
                      : WIZARD_ASSETS_DEBT.DEBT_REMAINS_MAX
                  }
                  step={100}
                  onChange={handleInputChange("assetsDebt", "remains")}
                  value={+formState.remains}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS_DEBT.FORM.DEBT_REMAINS" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS_DEBT.FORM.INTEREST_DEBT"
                  defaultMessage="How much creditcard or other high interest debt to you have?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="interestDebt"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assetsDebt")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.interestDebt}
                />
                <Slider
                  min={WIZARD_ASSETS_DEBT.INTEREST_DEBT_MIN}
                  max={
                    formState.interestDebt >
                    WIZARD_ASSETS_DEBT.INTEREST_DEBT_MAX
                      ? formState.interestDebt
                      : WIZARD_ASSETS_DEBT.INTEREST_DEBT_MAX
                  }
                  step={50}
                  onChange={handleInputChange("assetsDebt", "interestDebt")}
                  value={+formState.interestDebt}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS_DEBT.FORM.INTEREST_DEBT" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>

            <div className={classes.wizardFormItem}>
              <div className={classes.wizardFormLabel}>
                <FormattedMessage
                  id="WIZARD.ASSETS_DEBT.FORM.OTHER_DEBT"
                  defaultMessage="How much student or other type loan do you have?"
                />
              </div>

              <div className={classes.wizardFormControl}>
                <FormControlInput
                  fieldName="otherDebt"
                  label={null}
                  labelI18n={null}
                  handleChange={handleInputChange("assetsDebt")}
                  isAmount
                  classNameExtra={classes.inputWithRange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  value={formState.otherDebt}
                />
                <Slider
                  min={WIZARD_ASSETS_DEBT.OTHER_DEBT_MIN}
                  max={
                    formState.otherDebt > WIZARD_ASSETS_DEBT.OTHER_DEBT_MAX
                      ? formState.otherDebt
                      : WIZARD_ASSETS_DEBT.OTHER_DEBT_MAX
                  }
                  step={50}
                  onChange={handleInputChange("assetsDebt", "otherDebt")}
                  value={+formState.otherDebt}
                />
              </div>

              <Tooltip
                disableFocusListener
                enterTouchDelay={50}
                leaveTouchDelay={5000}
                className={classes.toleranceTooltip}
                title={
                  <TooltipTitle labelI18n="WIZARD.ASSETS_DEBT.FORM.OTHER_DEBT" />
                }
                placement="bottom-end"
              >
                <span style={{ userSelect: "none" }}>?</span>
              </Tooltip>
            </div>
          </div>

          <div className={classes.wizardFormRight}>
            <div className={classes.wizardBodySummary}>
              <FormattedMessage
                id="WIZARD.ASSETS_DEBT.TOTAL.DEBT"
                defaultMessage="Total debt"
              />
              <span className={classes.wizardBodySummaryCount}>
                {formatMoneyIntWithSign(totalDebt)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      isMobile,
      handleChangeStep,
      prevStep,
      nextStep,
      handleFinishAndSaveAssets
    } = this.props;

    return (
      <>
        <DialogTitle className={classes.wizardHeader}>
          <div className={classes.wizardHeaderTop}>
            <div className={classes.wizardHeaderTopLabel}>
              <FormattedMessage
                id="WIZARD.ASSETS_DEBT.LABEL"
                defaultMessage="Assets and liabilities"
              />
            </div>
          </div>

          <CommonTopStep classes={classes} step={6} />

          <div
            className={classes.wizardHeaderTopClose}
            onClick={this.props.onCloseDialog}
          >
            <Icons.IconClose color={"textTetrieary"} />
          </div>
        </DialogTitle>

        <DialogContent className={classes.wizardBody}>
          <div className={classes.wizardHeader2}>
            <FormattedMessage
              id="WIZARD.ASSETS_DEBT.HEADING2"
              defaultMessage="The value of your Assets minus your debt help determine how much you can rely on what you own in the future"
            />
          </div>

          <div className={classes.wizardBodyContent}>
            {this._renderContent()}
          </div>
        </DialogContent>

        <DialogActions className={classes.wizardFooter}>
          <Button
            white
            className={classes.wizardButtonBack}
            block={isMobile}
            onClick={handleChangeStep(prevStep)}
          >
            <Icons.IconArrowLeft width={18} height={18} />

            <FormattedMessage id="WIZARD.COMMON.BACK" />
          </Button>

          <ButtonMUI
            className={classes.wizardButtonFinish}
            onClick={handleFinishAndSaveAssets}
            block={isMobile}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="WIZARD.COMMON.FINISH" />
          </ButtonMUI>

          <Button
            yellow
            className={classes.wizardButtonNext}
            block={isMobile}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />

            <Icons.IconArrowRight width={24} height={24} color={"white"} />
          </Button>
        </DialogActions>
      </>
    );
  }
}

WizardAssetsDebt.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  formState: PropTypes.any,
  handleInputChange: PropTypes.func,
  currentStep: PropTypes.any,
  prevStep: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardAssetsDebt;
