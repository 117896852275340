import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import Button from "../../_common/Button/ButtonCommon";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import * as Icons from "../../_common/icons";

const WizardWelcome = props => {
  const { isMobile, classes, handleChangeStep, nextStep } = props;

  return (
    <>
      <DialogTitle className={classes.wizardHeader}>
        <div className={classes.wizardHeaderTop}>
          <div className={classes.wizardHeaderTop}>
            <FormattedMessage
              id="WIZARD.WELCOME.HEADING"
              defaultMessage="Welcome"
            />
          </div>
        </div>

        <div className={classes.wizardHeaderTopClose} onClick={props.onCloseDialog}>
          <Icons.IconClose color={"textTetrieary"} />
        </div>
      </DialogTitle>

      <DialogContent className={classes.wizardBody}>
        <div className={classes.wizardHeader2}>
          <p className={classes.wizardBodyWelcomeTitle}>
            <FormattedMessage
              id="WIZARD.WELCOME.BODY.TITLE"
              defaultMessage="Welcome"
            />
          </p>

          <p className={classes.wizardBodyWelcomeTitle2}>
            <FormattedMessage
              id="WIZARD.WELCOME.HEADING2"
              defaultMessage="Looks like this is the first time you are using XCURVE"
            />
          </p>
        </div>
        <div className={classes.wizardBodyWelcome}>
          {/*<p>*/}
          {/*  <FormattedMessage*/}
          {/*    id="WIZARD.WELCOME.BODY.TITLE2"*/}
          {/*    defaultMessage="XCurve makes it very easy for you to see and plan your financial future. It can also help you save money, protect your wealth and secure your financial future. While the information is yours alone? you are free to share it with other people such as a financial advisor or family member."*/}
          {/*  />*/}
          {/*</p>*/}
          <p>
            <FormattedMessage
              id="WIZARD.WELCOME.BODY.1"
              defaultMessage="XCurve makes it very easy for you to see and plan your financial future. It can also help you save money, protect your wealth and secure your financial future. While the information is yours alone? you are free to share it with other people such as a financial advisor or family member."
            />
          </p>
          <p>
            <FormattedMessage
              id="WIZARD.WELCOME.BODY.2"
              defaultMessage="XCurve will now guide you though several steps to set up your household profile and will let you see your financial future."
            />
          </p>
          <p>
            <FormattedMessage
              id="WIZARD.WELCOME.BODY.3"
              defaultMessage="Don’t worry about being super accurate and elaborate. You can come back and provide more details anytime."
            />
          </p>

          <Button
            yellow
            block={isMobile}
            style={{ width: 260 }}
            className={classes.wizardButtonWelcome}
            onClick={handleChangeStep(nextStep)}
          >
            <FormattedMessage id="WIZARD.COMMON.NEXT" />
          </Button>
        </div>
      </DialogContent>
    </>
  );
};

WizardWelcome.propsTypes = {
  isMobile: PropTypes.bool,
  classes: PropTypes.any,
  onClose: PropTypes.any,
  onCloseDialog: PropTypes.any,
  nextStep: PropTypes.any,
  handleChangeStep: PropTypes.func
};

export default WizardWelcome;
