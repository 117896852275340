import React from "react";
import PropTypes from "prop-types";

import "./ContentHeader.scss";

const ContentHeader = ({
  children,
  header,
  caption,
  isContentEmpty,
  headerWithoutPadding,
  isNoGap
}) => {
  return (
    <div
      className={`content-header ${
        isContentEmpty || headerWithoutPadding
          ? "content-header--content-empty"
          : ""
      }`}
    >
      <h1 className="content-header__title">{header}</h1>

      {caption && <p className="content-header__caption">{caption}</p>}

      {children}
    </div>
  );
};

ContentHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isContentEmpty: PropTypes.bool,
  headerWithoutPadding: PropTypes.bool
};

export default ContentHeader;
