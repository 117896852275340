import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Slide from "@material-ui/core/Slide";
import { FormattedMessage } from "react-intl-v3";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogChangesNotSaved extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAgreeAndClose: PropTypes.func,
    fullScreen: PropTypes.bool
  };

  render() {
    const {
      isOpen,
      handleAgreeAndClose,
      handleClose,
      fullScreen,
      text,
      title
    } = this.props;

    const defaultMessage = (
      <FormattedMessage
        id="APP.CHANGES.NOT_SAVED.DIALOG"
        defaultMessage="You have unsaved changes. Are you sure you want to leave without saving?"
      />
    );

    // TODO  i18n
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        {isOpen && (
          <>
            <DialogTitle id="form-dialog-title">
              {title || defaultMessage}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{!!text && text}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
              </Button>
              <Button onClick={handleAgreeAndClose} color="secondary">
                <FormattedMessage id="APP.LEAVE" defaultMessage="Leave" />
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

export default DialogChangesNotSaved;
