import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconMenu = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <rect x="1" y="5" width="22" height="2" fill={fill} />
        <rect x="1" y="11" width="22" height="2" fill={fill} />
        <rect x="1" y="17" width="22" height="2" fill={fill} />
      </g>
    </svg>
  );
};

IconMenu.propTypes = {
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "white",
    "grey"
  ])
};

export default IconMenu;
