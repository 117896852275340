import * as ROUTES from "./routes";
import * as RIGHTS from "constants/rights";

export const drawMenu = {
  // "my-advisor": [
  //   {
  //     name: "Dashboard",
  //     nameI18n: "DRAWER.MY_ADVISOR.DASHBOARD",
  //     icon: require("assets/img/icon/dashboard.png"),
  //     to: ROUTES.MY_ADVISOR
  //   },
  //   {
  //     name: "Operations",
  //     nameI18n: "APP.LINKS.OPERATIONS",
  //     icon: require("assets/img/icon/cashflown.png"),
  //     iconMainLink: "IconDashboard",
  //     to: ROUTES.OPERATIONS_CASHFLOW
  //   }
  // ],
  profile: [
    {
      name: "Profile Setup",
      nameI18n: "DRAWER.PROFILE.SETUP",
      icon: require("assets/img/icon/dashboard.png"),
      to: ROUTES.PROFILE
    },
    {
      name: "Advisor Permissions",
      nameI18n: "DRAWER.PROFILE.ADVISOR_PERMISSIONS",
      to: ROUTES.ADVISOR_PERMISSIONS,
      icon: require("assets/img/icon/plans.png")
    }
  ],
  home: [
    {
      name: "Dashboard",
      nameI18n: "DRAWER.HOME.DASHBOARD",
      icon: require("assets/img/icon/dashboard.png"),
      to: ROUTES.DASHBOARD
    },
    {
      name: "My Plans",
      nameI18n: "DRAWER.HOME.MY_PLANS",
      to: ROUTES.PLANS,
      icon: require("assets/img/icon/plans.png")
    },
    {
      name: "My Household",
      nameI18n: "DRAWER.HOME.MY_HOUSEHOLD",
      icon: require("assets/img/icon/household.png"),
      to: ROUTES.HOUSEHOLD_MAIN
    }
  ],
  plans: [
    {
      id: RIGHTS.RIGHT_PLANS_INTERESTS,
      name: "Interests",
      nameI18n: "DRAWER.PLANS.INTERESTS",
      icon: require("assets/img/icon/interest.png"),
      iconMainLink: "IconInterests",
      textMain: "Information used to identify individuals in your household",
      textMainI18n: "DRAWER.PLANS.INTERESTS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.INTERESTS.CAPTION",
      to: ROUTES.INTERESTS
    },
    {
      id: RIGHTS.RIGHT_PLANS_GOALS,
      name: "Goals",
      nameI18n: "DRAWER.PLANS.GOALS",
      icon: require("assets/img/icon/goal.png"),
      iconMainLink: "IconGoal",
      textMain: "Systems that hold personal or financial records",
      textMainI18n: "DRAWER.PLANS.GOALS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.GOALS.CAPTION",
      to: ROUTES.GOALS
    },
    {
      id: RIGHTS.RIGHT_PLANS_EVENTS,
      name: "Events",
      nameI18n: "DRAWER.PLANS.EVENTS",
      icon: require("assets/img/icon/event.png"),
      iconMainLink: "IconEvent",
      textMain: "Money received though any means",
      textMainI18n: "DRAWER.PLANS.EVENTS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.EVENTS.CAPTION",
      to: ROUTES.EVENTS
    }
  ],
  household: [
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_MEMBERS,
      name: "Members",
      nameI18n: "DRAWER.HOUSEHOLD.MEMBERS",
      icon: require("assets/img/icon/household.png"),
      iconMain: "/assets/img/icon/svg/household.svg",
      iconMainLink: "IconHousehold",
      textMain: "Information used to identify individuals in your household",
      textMainI18n: "DRAWER.HOUSEHOLD.TEXT",
      to: ROUTES.HOUSEHOLD_MEMBERS
    },
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS,
      name: "Assets Owned",
      nameI18n: "DRAWER.HOUSEHOLD.ASSETS",
      icon: require("assets/img/icon/assets owned.png"),
      iconMain: "/assets/img/icon/svg/assets owned.svg",
      iconMainLink: "IconMoneyOwned",
      textMain: "Something you own that can provide financial benefit",
      textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.TEXT",
      to: ROUTES.ASSETS_OWNED,
      submenu: [
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_USE,
          name: "Use Assets",
          nameI18n: "DRAWER.HOUSEHOLD.ASSETS.USE",
          iconMain: "/assets/img/household/assetsOwned/assetsUse.svg",
          iconMainLink: "IconCarHome",
          nameMain: "Physical",
          nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.NAME_MAIN",
          textMain: "Houses, vehicles, and other tangible assets",
          textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.CAPTION",
          to: ROUTES.ASSETS_OWNED_USE
        },
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_SAVING,
          name: "Saving & Retirement",
          nameI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING",
          iconMain: "/assets/img/household/assetsOwned/savings.svg",
          iconMainLink: "IconSunLounger",
          nameMain: "Saving & protection & retirement",
          nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.NAME_MAIN",
          textMain: "College funds, 401K, IRAs, Life insurance and more",
          textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.CAPTION",
          to: ROUTES.ASSETS_OWNED_SAVING
        },
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_INVESTED,
          name: "Invested Assets",
          nameI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED",
          iconMain: "/assets/img/household/assetsOwned/investment.svg",
          iconMainLink: "IconInvestment",
          nameMain: "Investment",
          nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.NAME_MAIN",
          textMain: "Stocks, bonds, RSUs etc.",
          textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.CAPTION",
          to: ROUTES.ASSETS_OWNED_INVESTED
        }
      ]
    },
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_DEBT,
      name: "Money Owned",
      nameI18n: "DRAWER.HOUSEHOLD.DEBT",
      icon: require("assets/img/icon/money owned.png"),
      iconMain: "/assets/img/icon/svg/money owned.svg",
      iconMainLink: "IconMoneyOwned",
      textMain: "Your obligations to pay",
      textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OWNED.TEXT",
      to: ROUTES.DEBT
    },
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_ACCOUNTS,
      name: "Accounts",
      nameI18n: "DRAWER.HOUSEHOLD.ACCOUNTS",
      icon: require("assets/img/icon/accounts.png"),
      iconMain: "/assets/img/icon/svg/account.svg",
      iconMainLink: "IconAccount",
      textMain: "Systems that hold personal or financial records",
      textMainI18n: "DRAWER.HOUSEHOLD.ACCOUNTS.TEXT",
      to: ROUTES.ACCOUNTS
    },
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_IN,
      name: "Money In",
      nameI18n: "DRAWER.HOUSEHOLD.MONEY_IN",
      icon: require("assets/img/icon/money in.png"),
      iconMain: "/assets/img/icon/svg/money in.svg",
      iconMainLink: "IconMoneyIn",
      textMain: "Money received though any means",
      textMainI18n: "DRAWER.HOUSEHOLD.MONEY_IN.TEXT",
      to: ROUTES.MONEY_IN
    },
    {
      id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT,
      name: "Money Out",
      nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT",
      icon: require("assets/img/icon/moneu out.png"),
      iconMain: "/assets/img/icon/svg/moneu out.svg",
      iconMainLink: "IconMoneyOut",
      textMain: "Money expensed for any reason",
      textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.TEXT",
      to: ROUTES.MONEY_OUT,
      submenu: [
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_PERSONAL,
          name: "Personal",
          nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL",
          icon: require("assets/img/icon/account.png"),
          iconMainLink: "IconElectrical",
          textMain: "What you need for your day-to-day",
          textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL.CAPTION",
          to: ROUTES.MONEY_OUT_PERSONAL
        },
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_ASSETS,
          name: "Assets",
          nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS",
          icon: require("assets/img/icon/balances.png"),
          iconMainLink: "IconMoneyOwned",
          textMain: "What you need to operate\nand maintain your assets",
          textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS.CAPTION",
          to: ROUTES.MONEY_OUT_ASSETS
        },
        {
          id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_OTHER,
          name: "Other Expenses",
          nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER",
          icon: require("assets/img/icon/balances.png"),
          iconMainLink: "IconHome",
          textMain: "Any money set aside for your expenses",
          textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER.TEXT",
          caption: "68% have this",
          captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER.CAPTION",
          to: ROUTES.MONEY_OUT_OTHER
        }
      ]
    }
  ]
};

export const menuObjects = {
  householdMembers: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MEMBERS,
    name: "Members",
    nameI18n: "DRAWER.HOUSEHOLD.MEMBERS",
    icon: require("assets/img/icon/household.png"),
    iconMain: "/assets/img/icon/svg/household.svg",
    iconMainLink: "IconHousehold",
    textMain: "Information used to identify individuals in your household",
    textMainI18n: "DRAWER.HOUSEHOLD.TEXT",
    to: ROUTES.HOUSEHOLD_MEMBERS
  },
  householdAssetsOwned: {
    id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS,
    name: "Assets Owned",
    nameI18n: "DRAWER.HOUSEHOLD.ASSETS",
    icon: require("assets/img/icon/assets owned.png"),
    iconMain: "/assets/img/icon/svg/assets owned.svg",
    iconMainLink: "IconMoneyOwned",
    textMain: "Something you own that can provide financial benefit",
    textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.TEXT",
    to: ROUTES.ASSETS_OWNED
  },
  householdUse: {
    id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_USE,
    name: "Use Assets",
    nameI18n: "DRAWER.HOUSEHOLD.ASSETS.USE",
    iconMain: "/assets/img/household/assetsOwned/assetsUse.svg",
    iconMainLink: "IconCarHome",
    nameMain: "Physical",
    nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.NAME_MAIN",
    textMain: "Houses, vehicles, and other tangible assets",
    textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.ASSETS.USE.CAPTION",
    to: ROUTES.ASSETS_OWNED_USE
  },

  householdSavings: {
    id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_SAVING,
    name: "Saving & Retirement",
    nameI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING",
    iconMain: "/assets/img/household/assetsOwned/savings.svg",
    iconMainLink: "IconSunLounger",
    nameMain: "Saving & protection & retirement",
    nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.NAME_MAIN",
    textMain: "College funds, 401K, IRAs, Life insurance and more",
    textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.ASSETS.SAVING.CAPTION",
    to: ROUTES.ASSETS_OWNED_SAVING
  },
  householdInvested: {
    id: RIGHTS.RIGHT_HOUSEHOLD_ASSETS_INVESTED,
    name: "Invested Assets",
    nameI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED",
    iconMain: "/assets/img/household/assetsOwned/investment.svg",
    iconMainLink: "IconInvestment",
    nameMain: "Investment",
    nameMainI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.NAME_MAIN",
    textMain: "Stocks, bonds, RSUs etc.",
    textMainI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.ASSETS.INVESTED.CAPTION",
    to: ROUTES.ASSETS_OWNED_INVESTED
  },
  householdDebt: {
    id: RIGHTS.RIGHT_HOUSEHOLD_DEBT,
    name: "Money Owned",
    nameI18n: "DRAWER.HOUSEHOLD.DEBT",
    icon: require("assets/img/icon/money owned.png"),
    iconMain: "/assets/img/icon/svg/money owned.svg",
    iconMainLink: "IconDeferredComp",
    textMain: "Your obligations to pay",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OWNED.TEXT",
    to: ROUTES.DEBT
  },
  householdAccounts: {
    id: RIGHTS.RIGHT_HOUSEHOLD_ACCOUNTS,
    name: "Accounts",
    nameI18n: "DRAWER.HOUSEHOLD.ACCOUNTS",
    icon: require("assets/img/icon/accounts.png"),
    iconMain: "/assets/img/icon/svg/account.svg",
    iconMainLink: "IconAccount",
    textMain: "Systems that hold personal or financial records",
    textMainI18n: "DRAWER.HOUSEHOLD.ACCOUNTS.TEXT",
    to: ROUTES.ACCOUNTS
  },
  householdMoneyIn: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_IN,
    name: "Money In",
    nameI18n: "DRAWER.HOUSEHOLD.MONEY_IN",
    icon: require("assets/img/icon/money in.png"),
    iconMain: "/assets/img/icon/svg/money in.svg",
    iconMainLink: "IconSalaries",
    textMain: "Money received though any means",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_IN.TEXT",
    to: ROUTES.MONEY_IN
  },
  householdMoneyOutMain: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT,
    name: "Money Out",
    nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT",
    icon: require("assets/img/icon/moneu out.png"),
    iconMain: "/assets/img/icon/svg/moneu out.svg",
    iconMainLink: "IconMoneyOut",
    textMain: "Money expensed for any reason",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.TEXT",
    to: ROUTES.MONEY_OUT
  },
  householdMoneyOutPersonal: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_PERSONAL,
    name: "Personal",
    nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL",
    icon: require("assets/img/icon/account.png"),
    iconMainLink: "IconCart",
    textMain: "What you need for your day-to-day",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.PERSONAL.CAPTION",
    to: ROUTES.MONEY_OUT_PERSONAL
  },
  householdMoneyOutAssets: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_ASSETS,
    name: "Assets",
    nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS",
    icon: require("assets/img/icon/balances.png"),
    iconMainLink: "IconMoneyOwned",
    textMain: "What you need to operate\nand maintain your assets",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.ASSETS.CAPTION",
    to: ROUTES.MONEY_OUT_ASSETS
  },
  householdMoneyOutOther: {
    id: RIGHTS.RIGHT_HOUSEHOLD_MONEY_OUT_OTHER,
    name: "Other Expenses",
    nameI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER",
    icon: require("assets/img/icon/balances.png"),
    iconMainLink: "IconDollar",
    textMain: "Any money set aside for your expenses",
    textMainI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER.TEXT",
    caption: "68% have this",
    captionI18n: "DRAWER.HOUSEHOLD.MONEY_OUT.OTHER.CAPTION",
    to: ROUTES.MONEY_OUT_OTHER
  }
};

export const drawMenuNew = {
  dashboard: {
    name: "Dashboard",
    nameI18n: "DRAWER.HOME.DASHBOARD",
    icon: require("assets/img/icon/dashboard.png"),
    to: ROUTES.DASHBOARD
  },
  plans: [
    {
      id: RIGHTS.RIGHT_PLANS_INTERESTS,
      name: "Interests",
      nameI18n: "DRAWER.PLANS.INTERESTS",
      icon: require("assets/img/icon/interest.png"),
      iconMainLink: "IconInterests",
      textMain: "Information used to identify individuals in your household",
      textMainI18n: "DRAWER.PLANS.INTERESTS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.INTERESTS.CAPTION",
      to: ROUTES.INTERESTS
    },
    {
      id: RIGHTS.RIGHT_PLANS_GOALS,
      name: "Goals",
      nameI18n: "DRAWER.PLANS.GOALS",
      icon: require("assets/img/icon/goal.png"),
      iconMainLink: "IconGoal",
      textMain: "Systems that hold personal or financial records",
      textMainI18n: "DRAWER.PLANS.GOALS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.GOALS.CAPTION",
      to: ROUTES.GOALS
    },
    {
      id: RIGHTS.RIGHT_PLANS_EVENTS,
      name: "Events",
      nameI18n: "DRAWER.PLANS.EVENTS",
      icon: require("assets/img/icon/event.png"),
      iconMainLink: "IconEvent",
      textMain: "Money received though any means",
      textMainI18n: "DRAWER.PLANS.EVENTS.TEXT",
      caption: "68% have this",
      captionI18n: "DRAWER.PLANS.EVENTS.CAPTION",
      to: ROUTES.EVENTS
    }
  ],
  household: [
    menuObjects.householdMembers,
    {
      ...menuObjects.householdAssetsOwned,
      submenu: [
        menuObjects.householdUse,
        menuObjects.householdSavings,
        menuObjects.householdInvested
      ]
    },
    menuObjects.householdDebt,
    menuObjects.householdAccounts,
    menuObjects.householdMoneyIn,
    {
      ...menuObjects.householdMoneyOutMain,
      submenu: [
        menuObjects.householdMoneyOutPersonal,
        menuObjects.householdMoneyOutAssets,
        menuObjects.householdMoneyOutOther
      ]
    }
  ],
  profile: [
    {
      name: "Profile Setup",
      nameI18n: "DRAWER.PROFILE.SETUP",
      icon: require("assets/img/icon/dashboard.png"),
      to: ROUTES.PROFILE
    },
    {
      name: "Advisor Permissions",
      nameI18n: "DRAWER.PROFILE.ADVISOR_PERMISSIONS",
      to: ROUTES.ADVISOR_PERMISSIONS,
      icon: require("assets/img/icon/plans.png")
    }
  ]
};

let breadcrumbNameMapPrepared = {
  [ROUTES.HOME]: {
    name: "Home",
    nameI18n: "APP.LINKS.HOME"
  },
  [ROUTES.PLANS]: {
    name: "Plans",
    nameI18n: "APP.LINKS.PLANS"
  },
  [ROUTES.HOUSEHOLD_MAIN]: {
    name: "Household",
    nameI18n: "APP.LINKS.HOUSEHOLD"
  }
  // [ROUTES.MY_ADVISOR]: {
  //   name: "My Advisor",
  //   nameI18n: "APP.LINKS.MY_ADVISOR"
  // }
};

Object.keys(drawMenu).forEach(key => {
  drawMenu[key].forEach(el => {
    breadcrumbNameMapPrepared[el.to] = {
      name: el.name,
      nameI18n: el.nameI18n
    };

    if (el.submenu && el.submenu.length) {
      el.submenu.forEach(subEl => {
        breadcrumbNameMapPrepared[subEl.to] = {
          name: subEl.name,
          nameI18n: subEl.nameI18n
        };
      });
    }
  });
});

export const breadcrumbNameMap = breadcrumbNameMapPrepared;
