import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/_common/Button/ButtonCommon";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl-v3";

const styles = {
  container: {
    padding: "44px 60px 56px 60px",
    textAlign: "center",

    "& p": {
      textAlign: "left"
    }
  },
  welcomeImage: {},
  subheading: {
    marginBottom: "16px",
    color: "#232735"
  },
  desc: {
    marginBottom: "16px",
    color: "#232735"
  },
  button: {
    marginTop: "30px"
  }
};

class DialogWelcome extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    title: PropTypes.string,
    titleI18n: PropTypes.string,
    text: PropTypes.string,
    textI18n: PropTypes.string
  };

  render() {
    const {
      classes,
      onClose,
      fullScreen = false,
      open,
      title,
      titleI18n,
      text,
      textI18n
    } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
      >
        <div className={classes.container}>
          <h2 className="main-title">
            <FormattedMessage
              id={titleI18n}
              defaultMessage={title || "Why do we need this information?"}
            />
          </h2>

          <FormattedHTMLMessage
            id={textI18n || " "}
            defaultMessage={text || " "}
          />

          <div className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onClose}
            >
              <FormattedMessage
                id="HOUSEHOLD.MEMBERS.WHY.BUTTON.OK"
                defaultMessage="Ok, I understand"
              />
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWelcome);
