import React, { Component } from "react";
import PropTypes from "prop-types";

import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core";

class SideAssetsRight extends Component {
  static propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
  };

  render() {
    const { classes, open } = this.props;

    return (
      <div>
        <Drawer
          className={classes.MuiPaper}
          open={open}
          anchor="right"
          style={{ width: "100%" }}
          width={"100%"}
        >
          <div style={{ width: "100vw" }}>{this.props.children}</div>
        </Drawer>
      </div>
    );
  }
}

const styles = {
  MuiDrawer: {
    root: {
      MuiPaper: {
        width: "100%",

        root: {
          width: "100%"
        }
      }
    },
    paperAnchorRight: { width: "100%" },
    paper: { width: "100%" }
  },
  MuiDrawerFull: {
    width: "100%"
  },
  MuiPaper: {
    root: {
      width: "100%"
    }
  }
};

export default withStyles(styles)(SideAssetsRight);
