import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl-v3";

import IconLogoMobile from "components/_common/icons/IconLogoMobile";
import * as Icons from "components/_common/icons";
import IconButton from "@material-ui/core/IconButton";
import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import * as ROUTES from "constants/routes";
import { drawMenuNew } from "constants/menu";

const defaultFunc = () => {};

const householdIcon = require("assets/img/icon/money owned.png");
const plansIcon = require("assets/img/icon/plans.png");
// const profileIcon = require("assets/img/icon/account.png");
const myAdvisorIcon = require("assets/img/icon/operation.png");

const MiddleWithSubmenu = props => {
  const {
    middleMenu: el,
    currentMiddlePath,
    currentPath,
    name,
    nameI18n,
    isMobile,
    handleDrawerToggle
  } = props;

  const isOpened = `/${currentPath}/${currentMiddlePath}` === el.to;

  const [isOpen, toggleOpen] = useState(isOpened);
  const toggle = () => toggleOpen(!isOpen);

  return (
    <>
      <button
        className={`drawer-menu__link middle__drawer__link ${
          isOpen ? "active" : ""
        }`}
        onClick={toggle}
      >
        <span className="drawer-menu__link-text">
          <FormattedMessage id={nameI18n} defaultMessage={name} />
        </span>

        <img
          src={
            isOpen
              ? require("assets/img/icon/drawer-submenu-open.png")
              : require("assets/img/icon/drawer-submenu-close.png")
          }
          alt=""
        />
      </button>
      {isOpen && (
        <ul className="drawer-menu__submenu submenu__drawer">
          {el.submenu.map(subEl => (
            <li className="submenu__drawer__item" key={subEl.to}>
              <NavLink
                exact
                className="submenu__drawer__link"
                to={subEl.to}
                onClick={isMobile ? handleDrawerToggle : null}
              >
                <FormattedMessage
                  id={subEl.nameI18n}
                  defaultMessage={subEl.name}
                />
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const TopWithSubmenu = props => {
  const {
    topMenu: el,
    id,
    currentPath,
    currentMiddlePath,
    handleDrawerToggle,
    isMobile
  } = props;

  const isOpened = currentPath === id;
  const [isOpen, toggleOpen] = useState(isOpened);
  const toggle = () => toggleOpen(!isOpen);

  const isCurrentPath = currentPath === id;

  useEffect(() => {
    if (isCurrentPath) {
      toggleOpen(true);
    }
  }, [currentPath]);

  let customIcon;
  if (!el.icon && id === "plans") {
    customIcon = plansIcon;
  }
  if (!el.icon && id === "household") {
    customIcon = householdIcon;
  }

  if (!el.icon && id === "my-advisor") {
    customIcon = myAdvisorIcon;
  }

  return (
    <li
      className={`drawer-menu__item__2 ${isOpen ? "active" : ""} ${
        isCurrentPath ? "current" : ""
      }`}
      key={el.to}
    >
      <button
        className={`drawer-menu__link ${isOpen ? "active" : ""}`}
        onClick={toggle}
      >
        <img
          className="drawer-menu__link-icon"
          src={
            el.icon || customIcon || require("assets/img/icon/household.png")
          }
          width={24}
          height={24}
          alt=""
        />

        <span
          className="drawer-menu__link-text"
          style={{ textTransform: "uppercase" }}
        >
          <FormattedMessage id={`DRAWER.${id}`} defaultMessage={id} />
        </span>

        <img
          src={
            isOpen
              ? require("assets/img/icon/drawer-submenu-open.png")
              : require("assets/img/icon/drawer-submenu-close.png")
          }
          alt=""
        />
      </button>

      {isOpen && (
        <ul className="drawer-menu__submenu middle__drawer">
          {el.map(subEl => {
            if (subEl && subEl.submenu) {
              return (
                <MiddleWithSubmenu
                  key={subEl.nameI18n}
                  middleMenu={subEl}
                  isMobile={isMobile}
                  currentPath={currentPath}
                  currentMiddlePath={currentMiddlePath}
                  handleDrawerToggle={handleDrawerToggle}
                  name={subEl.name}
                  nameI18n={subEl.nameI18n}
                />
              );
            }

            return (
              <li className="middle__drawer__item" key={subEl.to}>
                <NavLink
                  exact
                  to={subEl.to}
                  onClick={isMobile ? handleDrawerToggle : null}
                  className="submenu__drawer__link submenu__drawer__link--middle"
                >
                  <FormattedMessage
                    id={subEl.nameI18n}
                    defaultMessage={subEl.name}
                  />
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

const AppDrawerMenu = props => {
  const {
    profile,
    menu,
    handleSelectItem,
    currentItem,
    fullPath,
    handleDrawerToggle,
    isMobile,
    location,
    isAdvisor
  } = props;

  const handleRouteWithToggle = to => () => {
    handleSelectItem(to);
    handleDrawerToggle();
  };

  const currentPath = location.pathname.split("/")[1];
  const currentMiddlePath = location.pathname.split("/")[2];

  const mainMenu = (
    <div className="drawer-menu__wrapper">
      <ul className="drawer-menu">
        {Object.keys(drawMenuNew).map(keyTop => {
          const topMenu = drawMenuNew[keyTop];

          const isTopLink = !Array.isArray(topMenu);

          if (keyTop === "profile" || (keyTop === "my-advisor" && !isAdvisor)) {
            return null;
          }

          if (isTopLink) {
            return (
              <li
                className={`drawer-menu__item__2 ${
                  location.pathname === topMenu.to ? "current" : ""
                }`}
                key={topMenu.to}
              >
                <NavLink
                  className="drawer-menu__link"
                  to={topMenu.to}
                  onClick={
                    isMobile && !topMenu.submenu
                      ? handleRouteWithToggle(topMenu.to)
                      : handleSelectItem(topMenu.to)
                  }
                >
                  <img
                    className="drawer-menu__link-icon"
                    src={
                      topMenu.icon || require("assets/img/icon/household.png")
                    }
                    width={24}
                    height={24}
                    alt=""
                  />

                  <span
                    className="drawer-menu__link-text"
                    style={{ textTransform: "uppercase" }}
                  >
                    <FormattedMessage
                      id={topMenu.nameI18n}
                      defaultMessage={topMenu.name}
                    />
                  </span>

                  {!!topMenu.submenu && (
                    <img
                      src={
                        (location.pathname || currentItem) === topMenu.to ||
                        fullPath.indexOf(topMenu.to) !== -1
                          ? require("assets/img/icon/drawer-submenu-open.png")
                          : require("assets/img/icon/drawer-submenu-close.png")
                      }
                      alt=""
                    />
                  )}
                </NavLink>
              </li>
            );
          }

          return (
            <TopWithSubmenu
              key={keyTop}
              id={keyTop}
              handleDrawerToggle={handleDrawerToggle}
              currentPath={currentPath}
              currentMiddlePath={currentMiddlePath}
              topMenu={topMenu}
              isMobile={isMobile}
            />
          );
        })}
      </ul>
    </div>
  );

  // menu depracated menu took from constants
  if (menu) {
    return (
      <nav className="drawer-nav">
        <div className="drawer__container">
          <div className="drawer__header">
            <IconButton
              className="drawer__header-close"
              onClick={handleDrawerToggle}
            >
              <Icons.IconClose color={"white"} width={16} height={16} />
            </IconButton>

            <div className="drawer__header-logo">
              <IconLogoMobile />
            </div>

            <IconButton
              className="drawer__header-logout"
              onClick={handleDrawerToggle}
            >
              <NavLink className="drawer__header-link" to={"/logout"}>
                <span className="drawer__header-logout-text">
                  <FormattedMessage
                    id="APP.LOG_OUT2"
                    defaultMessage="Log Out"
                  />
                </span>

                <Icons.IconLogOut
                  height={24}
                  width={24}
                  color="white"
                  opacity={0.6}
                />
              </NavLink>
            </IconButton>
          </div>

          {mainMenu}
        </div>

        {!!isMobile && (
          <div className="drawer__footer">
            <NavLink
              exact
              className="submenu__drawer__link drawer__footer-link"
              to={ROUTES.PROFILE}
              onClick={isMobile ? handleDrawerToggle : defaultFunc}
            >
              <div className="drawer__footer-icon">
                <Icons.IconAvatar
                  color={"white"}
                  width={24}
                  height={24}
                  opacity={0.6}
                />
              </div>
              <div className="drawer__footer-name">
                {profile.displayName || ""}
              </div>
              <img
                src={require("assets/img/icon/drawer-submenu-close.png")}
                alt=""
              />
            </NavLink>
          </div>
        )}
      </nav>
    );
  }

  return (
    <nav className="drawer-nav">
      <ul className="drawer-menu">
        <li className="drawer-menu__item">
          <NavLink className="drawer-menu__link" to="/household/household">
            <img
              className="drawer-menu__link-icon"
              src={require("assets/img/icon/household.png")}
              alt=""
            />
            <span className="drawer-menu__link-text">Household</span>
          </NavLink>
        </li>

        <li className="drawer-menu__item">
          <NavLink className="drawer-menu__link" to="/household/assets">
            <img
              className="drawer-menu__link-icon"
              src={require("assets/img/icon/assets owned.png")}
              alt=""
            />

            <span className="drawer-menu__link-text">Assets</span>
            <img
              src={require("assets/img/icon/drawer-submenu-open.png")}
              alt=""
            />
          </NavLink>

          <ul className="drawer-menu__submenu submenu__drawer">
            <li className="submenu__drawer__item">
              <NavLink
                className="submenu__drawer__link"
                to="/household/assets/use"
              >
                Use Assets
              </NavLink>
            </li>
            <li className="submenu__drawer__item">
              <NavLink
                className="submenu__drawer__link"
                to="/household/assets/savings"
              >
                Saving
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

AppDrawerMenu.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isMobile: PropTypes.bool,
  fullPath: PropTypes.string,
  currentItem: PropTypes.string
};

function mapStateToProps({ firebase: { profile } }) {
  return {
    profile
  };
}
export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  withFirebase,
  withRouter
)(AppDrawerMenu);
