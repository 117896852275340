import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";

import toast from "components/_common/toast";

import DialogWhyNeedHousehold from "./DialogWhyNeedHousehold";
import HouseholdCard from "./HouseholdCard";

import FormControlInput from "components/_common/FormControl/FormControlInput";

import ButtonSaveChanges from "components/_common/Button/ButtonSaveChanges";
import ButtonSideItem from "components/_common/Button/ButtonSideItem";
import FormDatePicker from "components/_common/FormControl/FormDatePicker";
import FormControlSelect from "components/_common/FormControl/FormControlSelect";
import AdditionalForm from "components/_common/AdditionalForm";

import ContentHeader from "components/ContainerApp/ContentHeader";
import ContentMain from "components/ContainerApp/ContentMain";
import IsLoading from "routeHandlers/IsLoadingAuth";

import "./HouseholdMembers.scss";
import * as Icons from "../_common/icons";

// import MembersForm from "./MembersForm";
// import MembersFormContainer from "./MembersForm";
import DialogRemoveAsset from "../AssetsCommon/DialogRemoveAsset";
import { getProfileUidOrClientUidData } from "../../store/selectors/profileProvider";
import IconContainer from "../_common/IconContainer";
import AccessForbidden from "../../routeHandlers/AccessForbidden";
import { RIGHT_HOUSEHOLD_MEMBERS } from "constants/rights";
import DialogFinishWizard from "../AssetsCommon/DialogFinishWizard";
import NavigationPrompt from "react-router-navigation-prompt";
import DialogChangesNotSaved from "../AssetsCommon/DialogChangesNotSaved";

const RIGHT_SHARE = RIGHT_HOUSEHOLD_MEMBERS;
const RIGHT_LEVEL = `${RIGHT_HOUSEHOLD_MEMBERS}-can`;

const optionsRelationshipStatus = [
  {
    name: "Married",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.MARRIED",
    value: "married"
  },
  {
    name: "Established Partnership",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.ESTABLISHED",
    value: "established"
  },
  {
    name: "Widowed",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.WIDOWED",
    value: "widowed"
  },
  {
    name: "Separated",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.SEPARATED",
    value: "separated"
  },
  {
    name: "Divorced",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.DIVORCED",
    value: "divorced"
  },
  {
    name: "Single",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.SINGLE",
    value: "single"
  },
  {
    name: "Other",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS.OTHER",
    value: "other"
  }
];

export const optionsEmployedStatusValues = {
  EMPLOYEE: "employee",
  WORKER: "worker",
  SELF_EMPLOYED: "self-employed",
  UNEMPLOYED: "unemployed"
};
export const optionsEmployedStatusNames = {
  [optionsEmployedStatusValues.UNEMPLOYED]: {
    name: "Unemployed",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS.UNEMPLOYED"
  },
  [optionsEmployedStatusValues.EMPLOYEE]: {
    name: "Employee",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS.EMPLOYEE"
  },
  [optionsEmployedStatusValues.SELF_EMPLOYED]: {
    name: "Self-employed",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS.SELF_EMPLOYED"
  },
  [optionsEmployedStatusValues.WORKER]: {
    name: "Worker",
    nameI18n: "HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS.WORKER"
  }
};
export const optionsEmployedStatus = [
  {
    ...optionsEmployedStatusNames[optionsEmployedStatusValues.UNEMPLOYED],
    value: optionsEmployedStatusValues.UNEMPLOYED
  },
  {
    ...optionsEmployedStatusNames[optionsEmployedStatusValues.EMPLOYEE],
    value: optionsEmployedStatusValues.EMPLOYEE
  },
  {
    ...optionsEmployedStatusNames[optionsEmployedStatusValues.SELF_EMPLOYED],
    value: optionsEmployedStatusValues.SELF_EMPLOYED
  },
  {
    ...optionsEmployedStatusNames[optionsEmployedStatusValues.WORKER],
    value: optionsEmployedStatusValues.WORKER
  }
];

const INITIAL_CHILD_STATE = {
  childName: "",
  birthday: null
};

const childValidationScheme = {
  childName: {
    isRequired: true,
    isError: null,
    isTouched: null
  },
  birthday: {
    isRequired: true,
    isError: null,
    isTouched: null
  }
};

const validationScheme = {
  myInfo: {
    displayName: {
      isRequired: true,
      isError: null,
      isTouched: null
    },
    birthday: {
      isRequired: true,
      isError: null,
      isTouched: null
    },
    zipCode: {
      isRequired: true,
      isError: null,
      isTouched: null
    }
  },
  spouse: {
    spouseName: {
      isRequired: true,
      isError: null,
      isTouched: null
    },
    birthday: {
      isRequired: true,
      isError: null,
      isTouched: null
    }
  },
  child: childValidationScheme
};

class HouseholdMembers extends Component {
  state = {
    childUidForRemove: "",
    validationScheme: validationScheme,

    dialogWhyNeedItOpen: false,
    isSpouse: false,
    isChanged: false,
    isDialogFinishWizardOpen: false,
    isWizardFinishing: false,

    myInfo: {
      displayName: "",
      birthday: null,
      zipCode: "",
      employedStatus: optionsEmployedStatusValues.UNEMPLOYED,
      relationshipStatus: ""
    },
    spouse: {
      spouseName: "",
      birthday: null,
      employedStatus: ""
    },
    childs: []
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      auth,
      childs,
      firestore,
      firestoreStatus,
      profileProvider
    } = this.props;

    if (
      (auth.uid &&
        firestore &&
        firestoreStatus &&
        firestoreStatus.requested[`users/${profileProvider}/childs`] === true &&
        prevProps.firestoreStatus.requested[
          `users/${profileProvider}/childs`
        ] === false) ||
      prevProps.profileProvider !== profileProvider ||
      prevProps.childs != childs
    ) {
      if (!childs) {
        this.setState(prevState => ({
          childs: [],
          validationScheme: {
            ...prevState.validationScheme,
            childs: []
          }
        }));

        return;
      }

      const childsPrepared = Object.keys(childs)
        .map(key =>
          childs[key]
            ? {
                ...childs[key],
                uid: key,
                birthday:
                  childs[key] &&
                  childs[key].birthday &&
                  childs[key].birthday.seconds
                    ? new Date(childs[key].birthday.seconds * 1000)
                    : null
              }
            : null
        )
        .filter(el => !!el);

      const childsValidation = Object.keys(childs).map(key => {
        return Object.assign({}, { ...childValidationScheme });
      });

      this.setState(prevState => ({
        childs: childsPrepared,
        validationScheme: {
          ...prevState.validationScheme,
          childs: childsValidation
        }
      }));
    }

    if (
      prevProps.profile.displayName !== this.props.profile.displayName ||
      prevProps.profile.birthday !== this.props.profile.birthday
    ) {
      this.updateState();
    }

    if (
      (!prevProps.family &&
        this.props.family &&
        (this.props.family.displayName || this.props.family.birthday)) ||
      (prevProps.family &&
        this.props.family &&
        !this.props.family.displayName) ||
      (prevProps &&
        prevProps.family &&
        this.props.family &&
        (prevProps.family.displayName !== this.props.family.displayName ||
          prevProps.profile.birthday !== this.props.profile.birthday ||
          prevProps.family.relationshipStatus !==
            this.props.family.relationshipStatus))
    ) {
      this.updateState();
    }
  }

  getIsAccessEdit = () => {
    const { profileProvider, rights, auth } = this.props;
    return (
      profileProvider === auth.uid || (rights && rights[RIGHT_LEVEL] === "1")
    );
  };

  updateState = () => {
    const { family } = this.props;

    const currentProfile = family;

    if (
      currentProfile &&
      (currentProfile.displayName || currentProfile.birthday)
    ) {
      this.setState({
        myInfo: {
          displayName: currentProfile.displayName,
          birthday:
            currentProfile.birthday && currentProfile.birthday.seconds
              ? new Date(currentProfile.birthday.seconds * 1000)
              : null,
          employedStatus:
            currentProfile.employedStatus ||
            optionsEmployedStatusValues.UNEMPLOYED,
          relationshipStatus: currentProfile.relationshipStatus || "",
          zipCode: currentProfile.zipCode
        },

        isSpouse:
          currentProfile.relationshipStatus === "married" ||
          currentProfile.relationshipStatus === "established"
      });

      if (currentProfile && currentProfile.spouse) {
        const birthday = currentProfile.spouse.birthday;

        this.setState({
          spouse: {
            spouseName: currentProfile.spouse.spouseName,
            birthday:
              birthday && birthday.seconds
                ? new Date(birthday.seconds * 1000)
                : null,
            employedStatus:
              currentProfile.spouse.employedStatus ||
              optionsEmployedStatusValues.UNEMPLOYED
          }
        });
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  handleDialogRemoveAgree = () => {
    this.handleDeleteChild(this.state.childUidForRemove)();
    this.handleDialogRemoveClose();
  };

  handleDialogRemoveOpen = childUidForRemove => () => {
    this.setState({
      childUidForRemove
    });
  };

  handleDialogRemoveClose = () => {
    this.setState({
      childUidForRemove: ""
    });
  };
  ////////////////////////////////////
  /// Dialog finish wizard
  handleFinishWizard = () => {
    const {
      firestore,
      assetsAll,
      transactionsAll,
      profileProvider
    } = this.props;

    const batch = firestore.batch();
    this.setState({ isWizardFinishing: true });

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("userAssets").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    if (transactionsAll) {
      Object.keys(transactionsAll).forEach(assetKey => {
        const asset = transactionsAll[assetKey];

        if (asset && asset.isWizard && asset.id) {
          const docRef = firestore.collection("transactions").doc(asset.id);
          batch.update(docRef, {
            isWizard: false
          });
        }
      });
    }

    // family
    if (profileProvider) {
      const docRefUser = firestore.collection("users").doc(profileProvider);
      batch.update(docRefUser, {
        isWizardFinished: true
      });
    }

    batch.commit().then(() => {
      if (this.state.funcIfAccept) {
        this.state.funcIfAccept();
      }

      this.setState({ isWizardFinishing: false });
      this.handleDialogFinishWizardClose();
    });
  };

  handleDialogFinishWizardAgree = () => {
    this.handleFinishWizard();
  };

  handleDialogFinishWizardOpen = funcIfAccept => {
    this.setState({
      isDialogFinishWizardOpen: true,
      funcIfAccept: funcIfAccept
    });
  };

  handleDialogFinishWizardClose = () => {
    this.setState({
      isDialogFinishWizardOpen: false,
      funcIfAccept: null
    });
  };
  ////////////////////////////////////

  handleValidateData = () => {
    let isValid = true;
    const newState = Object.assign({}, validationScheme);

    Object.keys(validationScheme).forEach(keyScheme => {
      if (keyScheme === "childs") {
        return;
      }

      if (keyScheme === "spouse" && !this.state.isSpouse) {
        return;
      }

      if (keyScheme === "child") {
        if (!this.state.childs || !this.state.childs.length) {
          return;
        }

        const scheme = { ...validationScheme[keyScheme] };

        this.state.childs.forEach((child, childKey) => {
          const form = this.state.childs[childKey];

          Object.keys(scheme).forEach(keyField => {
            if (scheme[keyField].isRequired) {
              if (!newState.childs) {
                newState.childs = [];
              }

              if (!newState.childs[childKey]) {
                newState.childs[childKey] = {};
              }

              if (!newState.childs[childKey][keyField]) {
                newState.childs[childKey][keyField] = Object.assign(
                  {},
                  scheme[keyField]
                );
              }

              if (!form[keyField]) {
                isValid = false;

                newState.childs[childKey][keyField].isError = "error";
              } else {
                newState.childs[childKey][keyField].isError = null;
              }

              newState.childs[childKey][keyField].isTouched = false;
            }
          });
        });
      } else {
        const form = this.state[keyScheme];

        const scheme = validationScheme[keyScheme];

        Object.keys(scheme).forEach(keyField => {
          if (scheme[keyField].isRequired) {
            if (!form[keyField]) {
              isValid = false;

              newState[keyScheme][keyField].isError = "error";
            } else {
              newState[keyScheme][keyField].isError = null;
            }
            newState[keyScheme][
              keyField
            ].isTouched = this.state.validationScheme[keyScheme][
              keyField
            ].isTouched;

            newState[keyScheme][keyField].isTouched = false;
          }
        });
      }
    });

    this.setState({ validationScheme: newState });

    return isValid;
  };

  handleUpdateProfile = () => {
    const { firestore, profileProvider } = this.props;

    const spouseObject = {};
    if (this.state.isSpouse) {
      spouseObject.spouse = {
        spouseName: this.state.spouse.spouseName || "",
        birthday: this.state.spouse.birthday || null,
        employedStatus: this.state.spouse.employedStatus || ""
      };
    }
    return firestore.update(
      { collection: "users", doc: profileProvider },
      {
        displayName: this.state.myInfo.displayName || "",
        birthday: this.state.myInfo.birthday || null,
        zipCode: this.state.myInfo.zipCode || "",
        employedStatus: this.state.myInfo.employedStatus || "",
        relationshipStatus: this.state.myInfo.relationshipStatus || "",
        ...spouseObject
      }
    );
  };

  handleUpdateChilds = () => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    const { firestore, profileProvider } = this.props;

    let promiseAdd;
    let promiseUpdate;

    this.state.childs
      .filter(child => !child.uid && (child.birthday || child.name))
      .forEach(child => {
        promiseAdd = firestore.add(`users/${profileProvider}/childs`, {
          childName: child.childName || "",
          birthday: child.birthday || null
        });
      });

    this.state.childs
      .filter(child => !!child.uid && (child.birthday || child.name))
      .forEach(child => {
        promiseUpdate = firestore.update(
          `users/${profileProvider}/childs/${child.uid}`,
          {
            childName: child.childName || "",
            birthday: child.birthday || null
          }
        );
      });

    return Promise.all([promiseAdd, promiseUpdate]);
  };

  handleUpdateData = ev => {
    const { family } = this.props;

    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    if (ev) {
      ev.preventDefault();
    }

    if (!family.isWizardFinished) {
      return this.handleDialogFinishWizardOpen(this.handleUpdateData);
    }

    const isValid = this.handleValidateData();
    if (!isValid) {
      const message = (
        <FormattedMessage
          id="APP.FORM.VALIDATION.FILL_ALL_REQUIRED"
          defaultMessage={"Fill all required fields"}
        />
      );

      toast(message, { type: "error" });
      return;
    }

    const promiseProfile = this.handleUpdateProfile();
    const promiseChilds = this.handleUpdateChilds();

    Promise.all([promiseChilds, promiseProfile])
      .then(res => {
        const message = (
          <FormattedMessage
            id="APP.SUCCESSFULLY_SAVED"
            defaultMessage={"Saved successfully"}
          />
        );
        toast(message);
        this.handleIsChange(false);
      })
      .catch(err => {
        const message = (
          <FormattedMessage
            id="APP.ERROR_HAS_OCCURRED"
            defaultMessage={"Error has occurred"}
          />
        );
        toast(message, { type: "error" });
      });
  };

  handleOpenDialogWhyNeedIt = () => {
    this.setState({
      dialogWhyNeedItOpen: true
    });
  };

  handleCloseDialogs = () => {
    this.setState({ dialogWhyNeedItOpen: false });
  };

  handleAddChild = () => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    this.setState(prevState => {
      return {
        childs: [
          ...prevState.childs,
          { ...INITIAL_CHILD_STATE, id: prevState.childs.length }
        ],
        validationScheme: {
          ...prevState.validationScheme,
          childs: prevState.validationScheme.childs
            ? [...prevState.validationScheme.childs, childValidationScheme]
            : [childValidationScheme]
        }
      };
    });
  };

  handleIsChange = state => {
    this.setState({
      isChanged: state || false
    });
  };

  handleDeleteChild = childKey => () => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    const { childs } = this.state;
    const { firestore, profileProvider } = this.props;

    const child = childs[childKey];

    const message = (
      <FormattedMessage
        id="HOUSEHOLD.MEMBERS.FORM.CHILD.REMOVED"
        defaultMessage="Child Removed"
      />
    );

    const changeState = childKey => {
      this.setState(prevState => {
        return {
          childs: [
            ...prevState.childs.slice(0, childKey),
            ...prevState.childs.slice(childKey + 1)
          ]
        };
      });

      toast(message);
    };

    if (child.uid) {
      firestore
        .delete(`users/${profileProvider}/childs/${child.uid}`)
        .then(res => {
          toast(message);
        });
    } else {
      changeState(childKey);
    }
  };

  handleInputChange = asset => ev => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    const { name, value } = ev.target;

    const isSpouse = {};

    if (asset === "myInfo" && name === "relationshipStatus") {
      const isNotSingle = value === "married" || value === "established";

      if (!this.state.isSpouse && isNotSingle) {
        isSpouse.isSpouse = true;
      }

      if (this.state.isSpouse && !isNotSingle) {
        isSpouse.isSpouse = false;
      }
    }

    this.setState(prevState => ({
      [asset]: { ...this.state[asset], [name]: value },
      ...isSpouse,
      validationScheme: {
        ...prevState.validationScheme,
        [asset]: {
          ...prevState.validationScheme[asset],
          [name]: {
            isTouched: true,
            isError: false
          }
        }
      }
    }));

    this.handleIsChange(true);
  };

  handleInputChildChange = childKey => ev => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    const { name, value } = ev.target;

    const childs = this.state.childs;
    const child = { ...this.state.childs[childKey], [name]: value };

    childs.splice(childKey, 1, child);

    const validationChilds = this.state.validationScheme.childs;
    validationChilds[childKey][name].isTouched = true;

    this.setState(prevState => ({
      childs,
      validationScheme: {
        ...prevState.validationScheme,
        childs: validationChilds
      }
    }));

    this.handleIsChange(true);
  };

  handleInputChildChangeDate = childKey => date => {
    const isAccessEdit = this.getIsAccessEdit();
    if (!isAccessEdit) {
      return;
    }

    const childs = this.state.childs;
    const child = { ...this.state.childs[childKey], birthday: date };

    childs.splice(childKey, 1, child);

    this.setState(prevState => ({
      childs
    }));

    this.handleIsChange(true);
  };

  handleInputChangeDate = asset => date => {
    this.setState({
      [asset]: { ...this.state[asset], birthday: date }
    });

    this.handleIsChange(true);
  };

  _renderMyInfo = () => {
    const isAccessEdit = this.getIsAccessEdit();

    return (
      <HouseholdCard
        iconComp={
          <IconContainer color="brand" height={32} width={32}>
            <Icons.IconAvatarClean color="brand" height={24} width={24} />
          </IconContainer>
        }
        title="My Info"
        titleI18n="HOUSEHOLD.MEMBERS.CARDS.MY_INFO"
      >
        <FormControlInput
          required
          disabled={!isAccessEdit}
          error={
            !!this.state.validationScheme.myInfo.displayName.isError &&
            !this.state.validationScheme.myInfo.displayName.isTouched
          }
          fieldName="displayName"
          label="Your Name"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.NAME"
          handleChange={this.handleInputChange("myInfo")}
          value={this.state.myInfo.displayName}
        />

        <FormDatePicker
          required
          disabled={!isAccessEdit}
          error={
            !!this.state.validationScheme.myInfo.birthday.isError &&
            !this.state.validationScheme.myInfo.birthday.isTouched
          }
          fieldName="birthday"
          label="Birthday"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.BIRTHDAY"
          handleChange={this.handleInputChangeDate("myInfo")}
          value={this.state.myInfo.birthday}
          isShowYear
          isBirthday
        />

        <FormControlInput
          required
          disabled={!isAccessEdit}
          error={
            !!this.state.validationScheme.myInfo.zipCode.isError &&
            !this.state.validationScheme.myInfo.zipCode.isTouched
          }
          fieldName="zipCode"
          label="Zip Code"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.ZIP_CODE"
          handleChange={this.handleInputChange("myInfo")}
          value={this.state.myInfo.zipCode}
        />

        <FormControlSelect
          disabled={!isAccessEdit}
          fieldName="employedStatus"
          label="Employed status"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS"
          handleChange={this.handleInputChange("myInfo")}
          type="select"
          hideNoneOption
          options={optionsEmployedStatus}
          value={
            this.state.myInfo.employedStatus ||
            optionsEmployedStatusValues.UNEMPLOYED
          }
        />

        <FormControlSelect
          disabled={!isAccessEdit}
          fieldName="relationshipStatus"
          label="Relationship status"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.RELATIONSHIP_STATUS"
          handleChange={this.handleInputChange("myInfo")}
          type="select"
          options={optionsRelationshipStatus}
          value={this.state.myInfo.relationshipStatus}
        />
      </HouseholdCard>
    );
  };

  _renderSpouse = () => {
    if (!this.state.isSpouse) {
      return null;
    }

    const { profileProvider, rights, auth } = this.props;
    const isAccessEdit =
      profileProvider === auth.uid || (rights && rights[RIGHT_LEVEL] === "1");

    return (
      <HouseholdCard
        iconComp={
          <IconContainer color="brand" height={32} width={32}>
            <Icons.IconAvatarClean color="brand" height={24} width={24} />
          </IconContainer>
        }
        title="Spouse"
        titleI18n="HOUSEHOLD.MEMBERS.CARDS.SPOUSE"
      >
        <FormControlInput
          required
          disabled={!isAccessEdit}
          error={
            !!this.state.validationScheme.spouse.spouseName.isError &&
            !this.state.validationScheme.spouse.spouseName.isTouched
          }
          bgSecondary
          fieldName="spouseName"
          label="spouseName"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.SPOUSE_NAME" // TODO i18n
          handleChange={this.handleInputChange("spouse")}
          value={this.state.spouse.spouseName}
        />

        <FormDatePicker
          required
          disabled={!isAccessEdit}
          error={
            !!this.state.validationScheme.spouse.birthday.isError &&
            !this.state.validationScheme.spouse.birthday.isTouched
          }
          fieldName="birthday"
          label="Birthday"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.BIRTHDAY"
          handleChange={this.handleInputChangeDate("spouse")}
          type="date"
          value={this.state.spouse.birthday}
          isShowYear
          isBirthday
        />

        <FormControlSelect
          required
          disabled={!isAccessEdit}
          fieldName="employedStatus"
          label="Employed status"
          labelI18n="HOUSEHOLD.MEMBERS.FORM.EMPLOYED_STATUS"
          handleChange={this.handleInputChange("spouse")}
          type="select"
          options={optionsEmployedStatus}
          value={this.state.spouse.employedStatus}
        />
      </HouseholdCard>
    );
  };

  _renderChild = () => {
    const isAccessEdit = this.getIsAccessEdit();

    return (
      <HouseholdCard
        iconComp={
          <IconContainer color="brand" height={32} width={32}>
            <Icons.IconChild color="brand" height={24} width={24} />
          </IconContainer>
        }
        title="Child"
        titleI18n="HOUSEHOLD.MEMBERS.CARDS.CHILD"
      >
        {this._renderChilds()}

        {isAccessEdit && (
          <AdditionalForm
            mainColor="main"
            title={
              <FormattedMessage
                id="HOUSEHOLD.MEMBERS.FORM.CHILD.ADD"
                defaultMessage="Add Child"
              />
            }
            actionComponent={<Icons.IconPlus color={"brand"} />}
            actionFullTop={this.handleAddChild}
          />
        )}
      </HouseholdCard>
    );
  };

  _renderChilds = () => {
    const { childs } = this.state;

    if (!(childs || childs.length)) {
      return null;
    }

    const isAccessEdit = this.getIsAccessEdit();

    return childs.map((child, key) => {
      return (
        <AdditionalForm
          key={child.uid || key}
          mainColor="main"
          alwaysOpen={true}
          title={
            <FormattedMessage
              id="HOUSEHOLD.MEMBERS.FORM.CHILD.ADD"
              defaultMessage="Add Child"
            />
          }
          actionComponent={
            isAccessEdit ? this._renderActionDeleteChild(key) : null
          }
        >
          <FormControlInput
            required
            disabled={!isAccessEdit}
            error={
              this.state.validationScheme.childs &&
              this.state.validationScheme.childs[key] &&
              !!this.state.validationScheme.childs[key].childName.isError &&
              !this.state.validationScheme.childs[key].childName.isTouched
            }
            bgSecondary
            fieldName="childName"
            label="Child Name"
            labelI18n="HOUSEHOLD.MEMBERS.FORM.CHILD.NAME" // TODO i18n
            handleChange={this.handleInputChildChange(key)}
            value={childs[key].childName}
          />

          <FormDatePicker
            required
            disabled={!isAccessEdit}
            error={
              this.state.validationScheme.childs &&
              this.state.validationScheme.childs[key] &&
              !!this.state.validationScheme.childs[key].birthday.isError &&
              !this.state.validationScheme.childs[key].birthday.isTouched
            }
            bgSecondary
            fieldName="birthday"
            label="Birthday"
            labelI18n="HOUSEHOLD.MEMBERS.FORM.BIRTHDAY"
            handleChange={this.handleInputChildChangeDate(key)}
            type="date"
            value={childs[key].birthday}
            isShowYear
            isBirthday
          />
        </AdditionalForm>
      );
    });
  };

  _renderActionDeleteChild = childKey => {
    return (
      <ButtonSideItem
        handleClick={this.handleDialogRemoveOpen(childKey)}
        isOpen={true}
        color="brand"
        notHovered
        label={
          <span className="household__action-text">
            <FormattedMessage id="APP.DELETE" defaultMessage="Delete" />
          </span>
        }
      />
    );
  };

  render() {
    const {
      profile,
      family,
      firestoreStatus,
      profileProvider,
      auth
    } = this.props;

    if (!profile.isLoaded || firestoreStatus.requesting.rights) {
      return <IsLoading />;
    }

    const isOwnProfile = profileProvider === auth.uid;

    if (
      !isOwnProfile &&
      this.props.rights &&
      (this.props.rights.mainAccess !== "1" ||
        !this.props.rights.shareHousehold ||
        !this.props.rights[RIGHT_SHARE])
    ) {
      return <AccessForbidden />;
    }

    const currentProfile = family || profile;

    return (
      <>
        {this.state.isChanged && (
          <NavigationPrompt
            when={(crntLocation, nextLocation) =>
            this.state.isChanged &&
            (!nextLocation || crntLocation.pathname !== nextLocation.pathname)}
          >
        {({ isActive, onCancel, onConfirm }) => (
          <DialogChangesNotSaved
            isOpen={isActive}
            handleAgreeAndClose={onConfirm}
            handleClose={onCancel}
          />
        )}
        </NavigationPrompt>)}

        <DialogWhyNeedHousehold
          open={this.state.dialogWhyNeedItOpen}
          onClose={this.handleCloseDialogs}
        />

        <DialogFinishWizard
          isOpen={
            this.state.isDialogFinishWizardOpen || this.state.isWizardFinishing
          }
          handleAgreeAndClose={this.handleDialogFinishWizardAgree}
          handleClose={this.handleDialogFinishWizardClose}
          isLoading={this.state.isWizardFinishing}
        />

        <main>
          <div className="main-content">
            <ContentHeader
              header={
                !!currentProfile.firstName ? (
                  <FormattedMessage
                    id="HOUSEHOLD.MEMBERS.WELCOME.GREET"
                    defaultMessage="Hi {name}. Let’s get your household set up"
                    values={{ name: currentProfile.firstName }}
                  />
                ) : (
                  <FormattedMessage
                    id="HOUSEHOLD.MEMBERS.WELCOME.GREET_WO_NAME"
                    defaultMessage="Hi. Let’s get your household set up"
                  />
                )
              }
              caption={
                <FormattedMessage
                  id="HOUSEHOLD.MEMBERS.H2"
                  defaultMessage="Tell us a little bit about your family"
                />
              }
            >
              <p className="household__help-text">
                <FormattedMessage
                  id="HOUSEHOLD.MEMBERS.CAPTION"
                  defaultMessage="We try to ask as little information as possible to get you going. You can always edit or add more later on."
                />
              </p>

              <button
                className="household__why-need-button"
                onClick={this.handleOpenDialogWhyNeedIt}
              >
                <FormattedMessage
                  id="APP.WHY_NEED_IT"
                  defaultMessage="Why do we need this information?"
                />
              </button>
            </ContentHeader>

            <ContentMain>
              <form onSubmit={this.handleUpdateData}>
                <div className="household__forms">
                  {this._renderMyInfo()}
                  {this._renderSpouse()}
                  {this._renderChild()}
                </div>
              </form>

              {this.state.isChanged && (
                <div className="button--members-save">
                  <ButtonSaveChanges handleClick={this.handleUpdateData}>
                    <FormattedMessage
                      id="APP.SAVE_CHANGES"
                      defaultMessage="Save Changes"
                    />
                  </ButtonSaveChanges>
                </div>
              )}
            </ContentMain>
          </div>
        </main>

        <DialogRemoveAsset
          isOpen={this.state.childUidForRemove !== ""}
          title={"Are you sure?"}
          handleAgreeAndClose={this.handleDialogRemoveAgree}
          handleClose={this.handleDialogRemoveClose}
        />
      </>
    );
  }
}

HouseholdMembers.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  family: PropTypes.object,
  profileProvider: PropTypes.any,
  rights: PropTypes.any,
  firestoreStatus: PropTypes.object
};

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, status, ordered },
  ui: { profileProvider }
}) {
  const userUid = profileProvider || auth.uid;

  const rights = ordered && ordered.rights;

  const childs =
    data && data.users && data.users[userUid] && data.users[userUid].childs;

  return {
    auth,
    profile,
    profileProvider: userUid,
    // TODO mode to ordered
    childs: childs,

    family: data && data.family,

    rights: rights && rights[0],
    firestoreStatus: status
  };
}
export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  withFirestore,
  firestoreConnect(props => {
    const profileUid = getProfileUidOrClientUidData(props);

    const queries = [
      {
        collection: "users",
        doc: profileUid,
        subcollections: [
          {
            collection: "childs",
            orderBy: ["birthday", "asc"],
            storeAs: "childs"
          }
        ]
      },
      {
        collection: "users",
        doc: profileUid,
        storeAs: "family"
      }
    ];

    if (props.auth.uid !== profileUid) {
      const rightsQuery = {
        collection: "advisor",
        where: [
          ["clientId", "==", profileUid],
          ["advisor", "==", props.auth.email]
        ],
        storeAs: "rights"
      };

      queries.push(rightsQuery);
    }

    return queries;
  })
)(withFirebase(HouseholdMembers));
