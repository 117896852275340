import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Icons from "components/_common/icons";

import "./ToolbarSearch.scss";
import { debounce } from "../../../lib/helpers";

class ToolbarSearch extends Component {
  static defaultProps = {
    type: "text"
  };

  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    handleChange: PropTypes.func,
    handleSearch: PropTypes.func
  };

  _handleKeyDown = ev => {
    const { handleSearch } = this.props;

    if (handleSearch && ev.key === "Enter") {
      handleSearch();
    }
  };

  setSearchQuery = debounce(ev => {
    const { handleSearch } = this.props;

    handleSearch();
  }, 1000);

  handleChange = ev => {
    this.props.handleChange(ev);
  };

  render() {
    const {
      handleChange,
      handleSearch,
      type,
      value,
      placeholder,
      className
    } = this.props;

    if (type === "select") {
      return this._renderSelect();
    }

    return (
      <div className="toolbar-search__container">
        <input
          id="search"
          name="search"
          className={`toolbar-search__input ${className}`}
          type="text"
          placeholder={placeholder}
          value={value} //TODO
          onChange={this.handleChange}
          onKeyDown={this._handleKeyDown}
        />
        <button className="toolbar-search__button" onClick={handleSearch}>
          <Icons.IconSearch color={"textTetrieary"} />
        </button>
      </div>
    );
  }
}

export default ToolbarSearch;
