import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconFormatList = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M3 5H5V7H3V5ZM7 5V7H21V5H7ZM3 11H5V13H3V11ZM7 11V13H21V11H7ZM3 17H5V19H3V17ZM7 17V19H21V17H7Z"
        fill={fill}
      />
    </svg>
  );
};

IconFormatList.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconFormatList;
