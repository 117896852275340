import React from "react";
import { FormattedMessage } from "react-intl-v3";

import {
  defaultChanges,
  defaultChangesType,
  valuesInflation
} from "../../constants/inflation";

export const commonPrefixAfter = {
  YRS: <FormattedMessage id={"APP.FORM.COMMON.YRS"} defaultMessage="YRS" />,
  MNTH: <FormattedMessage id={"APP.FORM.COMMON.MNTH"} defaultMessage="MNTH" />
};

export const optionsTaxCategoryValues = {
  ORDINARY_INCOME: "ordinaryIncome",
  CAPITAL_GAIN: "capitalGain",
  NO_TAX: "noTax",
  UNKNOWN: "unknown"
};

export const optionsInflation = [
  {
    name: "With avg inflation",
    nameI18n: "INFLATION.TYPES.AVG_INFLATION",
    value: valuesInflation.INFLATION
  },
  {
    name: "Change inflation by",
    nameI18n: "INFLATION.TYPES.AVG_INFLATION_BY",
    value: valuesInflation.INFLATION_BY
  },
  {
    name: "With avg S&P",
    nameI18n: "INFLATION.TYPES.AVG_SANDP",
    value: valuesInflation.SANDP
  },
  {
    name: "Change S&P by",
    nameI18n: "INFLATION.TYPES.AVG_SANDP_BY",
    value: valuesInflation.SANDP_BY
  },
  {
    name: "Custom",
    nameI18n: "INFLATION.TYPES.CUSTOM",
    value: valuesInflation.CUSTOM
  }
];

export const optionsTaxCategory = [
  {
    name: "Ordinary Income",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.ORDINARY_INCOME",
    value: optionsTaxCategoryValues.ORDINARY_INCOME
  },
  {
    name: "Capital Gain",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.CAPITAL_GAIN",
    value: optionsTaxCategoryValues.CAPITAL_GAIN
  },
  {
    name: "No Tax",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.NO_TAX",
    value: optionsTaxCategoryValues.NO_TAX
  },
  {
    name: "Unknown",
    nameI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TAX.TYPES.UNKNOWN",
    value: optionsTaxCategoryValues.UNKNOWN
  }
];

export const repeatableFields = {
  fromAge: {
    isAdditional: true,
    calculate: { isFromAgeToAge: true },
    labelI18nCard: "HOUSEHOLD.ASSETS.SAVINGS.FORM.FROM_AGE_TO_AGE.LABEL",
    labelCard: "From Age To Age",

    isNumber: true,
    label: "From Age",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.FROM_AGE.LABEL"
  },
  toAge: {
    isNumber: true,
    label: "To Age",
    labelI18n: "HOUSEHOLD.ASSETS.SAVINGS.FORM.TO_AGE.LABEL"
  },
  durationYears: {
    isAdditional: true,
    isRequired: true,
    isNumber: true,
    isInt: true,
    min: 0,
    after: commonPrefixAfter.YRS,
    label: "Duration",
    labelI18n: "HOUSEHOLD.COMMON.FORM.DURATION.LABEL"
  }
};

export const repeatableSchemeConf = {
  annualChangeType: {
    isAdditional: true,
    isFullWidth: true,

    isRequired: true,
    isSelect: true,
    options: optionsInflation,
    label: "Annual Change",
    labelI18n: "INFLATION.TYPES.LABEL"
  },
  annualChange: {
    condition: [
      {
        inner: "configuration",
        field: "annualChangeType",
        valueIn: [
          valuesInflation.CUSTOM,
          valuesInflation.SANDP_BY,
          valuesInflation.INFLATION_BY,
          ""
        ]
      }
    ],
    isAdditional: true,
    isRequired: true,
    isNumber: true,
    prefix: "%",
    label: "Annual Increase Rate",
    labelI18n: "HOUSEHOLD.COMMON.FORM.ANNUAL_CHANGE.LABEL"
  }
};

export const optionsPaidFrequencyMQY = [
  {
    name: "Monthly",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.MONTHLY",
    value: "12"
  },
  {
    name: "Quarterly",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.QUARTERLY",
    value: "4"
  },
  {
    name: "Annually",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.ANNUALLY",
    value: "1"
  }
];

export const optionsPaidFrequencyMQSY = [
  {
    name: "Monthly",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.MONTHLY",
    value: "12"
  },
  {
    name: "Quarterly",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.QUARTERLY",
    value: "4"
  },
  {
    name: "Semi-annually",
    nameI18n:
      "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.SEMI_ANNUALLY",
    value: "2"
  },
  {
    name: "Annually",
    nameI18n: "HOUSEHOLD.ASSETS.INVESTED.FORM.PAID_FREQUENCY.TYPES.ANNUALLY",
    value: "1"
  }
];

export function addAdditionalConfiguration(
  ENTITIES,
  ENTITIES_ADDITIONAL,
  globalType
) {
  Object.keys(ENTITIES).forEach(key => {
    const entityKey = ENTITIES[key];

    if (!ENTITIES_ADDITIONAL[entityKey]) {
      ENTITIES_ADDITIONAL[entityKey] = {};
    }

    let defaultAnnualChange = 0;
    if (defaultChanges[globalType]) {
      if (defaultChanges[globalType][entityKey]) {
        defaultAnnualChange = defaultChanges[globalType][entityKey];
      } else {
        defaultAnnualChange = defaultChanges[globalType].default || 0;
      }
    }

    let defaultAnnualChangeType;
    if (defaultChangesType[globalType]) {
      if (defaultChangesType[globalType][entityKey]) {
        defaultAnnualChangeType = defaultChangesType[globalType][entityKey];
      } else {
        defaultAnnualChangeType = defaultChangesType[globalType].default || 0;
      }
    }

    let configuration = {
      annualChangeType: {
        ...repeatableSchemeConf.annualChangeType,
        default: defaultAnnualChangeType
      },
      annualChange: {
        ...repeatableSchemeConf.annualChange,
        default: defaultAnnualChange,
        labelI18n: `HOUSEHOLD.FORM.ANNUAL_CHANGE.${globalType}.${entityKey}`
      }
    };

    if (
      ENTITIES_ADDITIONAL[entityKey] &&
      ENTITIES_ADDITIONAL[entityKey]["configuration"] &&
      defaultChanges[globalType]
    ) {
      configuration = {
        ...ENTITIES_ADDITIONAL[entityKey]["configuration"],
        ...configuration
      };
    }

    ENTITIES_ADDITIONAL[entityKey]["configuration"] = {
      ...configuration
    };
  });
}

export function prepareState(ENTITIES_ADDITIONAL, formScheme) {
  Object.keys(ENTITIES_ADDITIONAL).forEach(entityKey => {
    Object.keys(ENTITIES_ADDITIONAL[entityKey]).forEach(addKey => {
      formScheme[`${entityKey}_${addKey}`] =
        ENTITIES_ADDITIONAL[entityKey][addKey];
    });
  });

  const formsInitialStateInit = {};
  const formsValidationSchemeInit = {};
  Object.keys(formScheme).forEach(entityKey => {
    Object.keys(formScheme[entityKey]).forEach(field => {
      if (!formsInitialStateInit[entityKey]) {
        formsInitialStateInit[entityKey] = {};
      }
      if (!formsValidationSchemeInit[entityKey]) {
        formsValidationSchemeInit[entityKey] = {};
      }

      const fieldScheme = formScheme[entityKey][field];

      if (fieldScheme.isAmount) {
        formsInitialStateInit[entityKey][field] = 0;
      } else {
        formsInitialStateInit[entityKey][field] = fieldScheme.default || "";
      }

      const validScheme = {};

      if (fieldScheme.isRequired) {
        validScheme.isRequired = true;
      }

      if (typeof fieldScheme.min === "number") {
        validScheme.min = fieldScheme.min;
      }

      if (typeof fieldScheme.max === "number") {
        validScheme.max = fieldScheme.max;
      }

      if (fieldScheme.minFromCustom) {
        validScheme.minFromCustom = fieldScheme.minFromCustom;
      }

      formsValidationSchemeInit[entityKey][field] = {
        ...validScheme,
        isError: null,
        isTouched: null
      };
    });
  });

  return {
    formsInitialState: { ...formsInitialStateInit },
    formsValidationScheme: { ...formsValidationSchemeInit }
  };
}
