import React, { Component } from "react";
import PropTypes from "prop-types";

// import Button from "@material-ui/core/Button";
import Button from "components/_common/Button/ButtonAddAsset";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "components/LogIn/styles";
import { FormattedMessage } from "react-intl-v3";

import { emailValid } from "components/LogIn/helper";
import AppBarLogIn from "../ContainerApp/AppBarLogIn";

const INITIAL_STATE = {
  email: "",

  error: "",
  errorMessage: "",
  isEmailValid: true,
  isEmailSent: false
};

class Login extends Component {
  static propTypes = {
    firebase: PropTypes.object.isRequired
  };

  state = INITIAL_STATE;

  componentDidUpdate(prevProps, prevState, prevContext) {}

  handleValidateData = () => {
    const isEmailValid = emailValid(this.state.email);

    this.setState({ isEmailValid });

    return isEmailValid;
  };

  handleSubmit = ev => {
    ev.preventDefault();

    const { firebase } = this.props;

    const { email } = this.state;

    if (!this.handleValidateData()) {
      return;
    }

    this.setState({
      error: "",
      errorMessage: ""
    });

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(result => {
        this.setState({ isEmailSent: true });
      })
      .catch(err => {
        this.setState({
          error: err.code,
          errorMessage: err.message
        });
      });
  };

  handleInputChange = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value,
      isEmailValid: true
    });
  };

  handleResend = ev => {
    ev.preventDefault();
    this.setState({
      isEmailSent: !this.state.isEmailSent
    });
  };

  _renderEmailSent = () => {
    const { classes } = this.props;
    return (
      <div className={classes.paperRight}>
        <div>
          <h1 className={classes.forgotPasswordTitle}>
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.HEADING"
              defaultMessage="Reset your password"
            />
          </h1>
          <p>
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.TEXT1"
              defaultMessage="We have sent a reset password email to "
            />
            <span className={classes.forgotPasswordEmail}>
              {this.state.email}.{" "}
            </span>
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.TEXT2"
              defaultMessage="Please click the reset password link to set your new password."
            />
          </p>
          <p>
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.TEXT3"
              defaultMessage="Didn’t receive the email yet?"
            />
            <br />
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.TEXT4"
              defaultMessage="Please check your spam folder, or "
            />
            <a
              href="#"
              className={classes.resendLink}
              onClick={this.handleResend}
            >
              <FormattedMessage
                id="CHANGE_PASSWORD.SENT.TEXT5"
                defaultMessage="resend "
              />
            </a>
            <FormattedMessage
              id="CHANGE_PASSWORD.SENT.TEXT6"
              defaultMessage="the email."
            />
          </p>
        </div>
      </div>
    );
  };

  _renderForm = () => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.paperRight}>
          <div>
            <Typography
              className={classes.forgotPasswordTitle}
              component="h2"
              variant="h6"
            >
              <FormattedMessage
                id="FORGOT_PASSWORD.HEADING"
                defaultMessage="Forgot password?"
              />
            </Typography>
            <Typography
              component="p"
              className={classes.forgotPasswordStubtitle}
            >
              <FormattedMessage
                id="FORGOT_PASSWORD.SUBTITLE"
                defaultMessage="Don’t worry. Just tell us the email address you registered in XCurve"
              />
            </Typography>

            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  autoFocus
                  required
                  id="email"
                  name="email"
                  label={
                    <FormattedMessage
                      id="SIGNUP.FORM.EMAIL"
                      defaultMessage="Email"
                    />
                  }
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  error={!this.state.isEmailValid}
                  helperText={
                    !this.state.isEmailValid ? (
                      <FormattedMessage
                        id="SIGNUP.ERROR.INVALID_EMAIL"
                        defaultMessage="You must enter valid email address"
                      />
                    ) : null
                  }
                />
              </FormControl>

              {this.state.error &&
                this.state.errorMessage && (
                  <Typography style={{ color: "#FF3B30" }}>
                    {this.state.errorMessage}
                  </Typography>
                )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleSubmit}
              >
                <FormattedMessage
                  id="EMAIL_VERIFICATION.SEND_EMAIL_AGAIN_BUTTON"
                  defaultMessage="Reset Password"
                />
              </Button>
            </form>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />

        <AppBarLogIn
          rightButtonLink={"/login"}
          rightButtonText={
            <FormattedMessage id="APP.LOG_IN" defaultMessage="Log In" />
          }
        />

        <Paper className={classes.paper}>
          <div className={classes.paperLeft}>
            <div className={classes.imgCont}>
              <img src="/assets/img/bg-sign.svg" alt="" />
            </div>
            <img
              className={classes.logoBottom}
              src="/assets/img/logo-orange-white.svg"
              alt=""
            />
          </div>

          {!this.state.isEmailSent
            ? this._renderForm()
            : this._renderEmailSent()}
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(Login);
