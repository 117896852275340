import React from "react";

import { colors } from "constants/styles";

const IconMoneyIn = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M14.1668 16.6666L6.66683 9.99992L11.6668 9.99992V3.33325H16.6668L16.6668 9.99992L21.6668 9.99992L14.1668 16.6666Z"
        fill={fill}
      />
      <path
        d="M14.1666 21.1264L5.41571 13.3479C4.63625 13.3803 4.15823 13.4848 3.82165 13.8214C3.3335 14.3096 3.3335 15.0952 3.3335 16.6666V31.6666C3.3335 33.2379 3.3335 34.0236 3.82165 34.5118C4.30981 34.9999 5.09548 34.9999 6.66683 34.9999H31.6668C33.2382 34.9999 34.0239 34.9999 34.512 34.5118C35.0002 34.0236 35.0002 33.2379 35.0002 31.6666V29.9576C34.5702 29.9999 34.0288 29.9999 33.3335 29.9999H26.6668C25.0955 29.9999 24.3098 29.9999 23.8217 29.5118C23.3335 29.0236 23.3335 28.2379 23.3335 26.6666V21.6666C23.3335 20.0952 23.3335 19.3096 23.8217 18.8214C24.3098 18.3333 25.0955 18.3333 26.6668 18.3333H33.3335C34.0288 18.3333 34.5702 18.3333 35.0002 18.3755V16.6666C35.0002 15.0952 35.0002 14.3096 34.512 13.8214C34.0239 13.3333 33.2382 13.3333 31.6668 13.3333H22.9339L14.1666 21.1264Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0002 21.6666C25.0002 20.8809 25.0002 20.4881 25.2442 20.244C25.4883 19.9999 25.8812 19.9999 26.6668 19.9999H35.0002C35.7858 19.9999 36.1787 19.9999 36.4228 20.244C36.6668 20.4881 36.6668 20.8809 36.6668 21.6666V26.6666C36.6668 27.4523 36.6668 27.8451 36.4228 28.0892C36.1787 28.3333 35.7858 28.3333 35.0002 28.3333H26.6668C25.8812 28.3333 25.4883 28.3333 25.2442 28.0892C25.0002 27.8451 25.0002 27.4523 25.0002 26.6666V21.6666ZM30.8335 24.1666C30.8335 25.0871 30.0873 25.8333 29.1668 25.8333C28.2464 25.8333 27.5002 25.0871 27.5002 24.1666C27.5002 23.2461 28.2464 22.4999 29.1668 22.4999C30.0873 22.4999 30.8335 23.2461 30.8335 24.1666Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconMoneyIn;
