import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconBigBank = ({
  height = 76,
  width = 76,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  // const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 76 76"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M31.871 18.387a3.682 3.682 0 0 1 3.677-3.678h6.13v-2.451h-6.13a6.136 6.136 0 0 0-6.129 6.129h-1.515L0 32.339v6.887h2.452v4.903h2.451v19.613H2.452v4.903H0V76h61.29v-7.355H58.84v-4.903h-2.452V44.129h2.452v-4.903h2.451v-6.887L33.386 18.387h-1.515zM4.903 66.193h7.355v2.452H4.903v-2.452zm14.71-22.064v19.613H17.16v4.903H14.71v-4.903h-2.452V44.129h2.452v-4.903h2.451v4.903h2.452zm14.71 0v19.613H31.87v4.903h-2.452v-4.903h-2.451V44.129h2.451v-4.903h2.452v4.903h2.452zm14.71 0v19.613H46.58v4.903h-2.452v-4.903h-2.452V44.129h2.452v-4.903h2.452v4.903h2.451zm7.354 22.064v2.452h-7.355v-2.452h7.355zm-4.903-2.451V44.129h2.451v19.613h-2.451zm-9.807-22.065h-7.354v-2.451h7.354v2.451zm-2.451 2.452v19.613h-2.452V44.129h2.452zm2.451 22.064v2.452h-7.354v-2.452h7.354zm-14.71-24.516h-7.354v-2.451h7.355v2.451zm-2.45 2.452v19.613h-2.453V44.129h2.452zm2.45 22.064v2.452h-7.354v-2.452h7.355zM12.259 41.677H4.903v-2.451h7.355v2.451zM9.806 44.13v19.613H7.355V44.129h2.451zM58.84 71.097v2.451H2.452v-2.451h56.387zm-2.452-29.42h-7.355v-2.451h7.355v2.451zm2.452-4.903H2.452v-2.92l26.03-13.015h.937v8.58h2.452v-8.58h.936L58.84 33.853v2.92z"
        fill="#07051D"
        fillOpacity=".85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.548 14.71a3.682 3.682 0 0 0-3.677 3.677v11.032h-2.452V18.387c0-3.38 2.75-6.13 6.13-6.13h6.128v2.452h-6.129z"
        fill="#6A68FB"
      />
      <path
        d="M51.484 31.871H9.807v2.452h41.677V31.87zM62.517 0c-7.434 0-13.484 6.05-13.484 13.484s6.05 13.484 13.484 13.484c7.435 0 13.484-6.05 13.484-13.484C76 6.049 69.95 0 62.517 0zm0 24.516c-6.084 0-11.032-4.948-11.032-11.032S56.433 2.452 62.517 2.452 73.55 7.4 73.55 13.484c0 6.083-4.948 11.032-11.032 11.032z"
        fill="#07051D"
        fillOpacity=".85"
      />
      <path
        d="M61.29 9.807h2.452c.677 0 1.226.549 1.226 1.225h2.452a3.682 3.682 0 0 0-3.678-3.677V4.903h-2.451v2.452a3.682 3.682 0 0 0-3.678 3.677 3.682 3.682 0 0 0 3.678 3.678h2.451a1.226 1.226 0 0 1 0 2.451h-2.451c-.677 0-1.226-.549-1.226-1.225h-2.452a3.682 3.682 0 0 0 3.678 3.677v2.452h2.451v-2.452a3.682 3.682 0 0 0 3.678-3.677 3.682 3.682 0 0 0-3.678-3.678h-2.451a1.226 1.226 0 0 1 0-2.451zM46.58 12.258H44.13v2.451h2.452v-2.451zM68.645 47.806a3.682 3.682 0 0 1-3.677 3.678h-1.226v2.451h1.226c3.38 0 6.129-2.75 6.129-6.129V28.193h-2.452v19.613zM61.29 51.483h-2.452v2.452h2.451v-2.452z"
        fill="#6A68FB"
      />
    </svg>
  );
};

IconBigBank.propTypes = {
  // strokeWidth: PropTypes.number,
  // opacity: PropTypes.number,
  // color: PropTypes.oneOf([
  //   "none",
  //   "ui",
  //   "brand",
  //   "yellow",
  //   "green",
  //   "red",
  //   "yellow",
  //   "textTetrieary",
  //   "textSecondary",
  //   "grey",
  //   "white"
  // ])
};

export default IconBigBank;
