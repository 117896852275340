import React from "react";

import { colors } from "constants/styles";

const IconHousehold = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M24 12C22.4087 12 20.8826 12.6321 19.7574 13.7574C18.6321 14.8826 18 16.4087 18 18C18 19.5913 18.6321 21.1174 19.7574 22.2426C20.8826 23.3679 22.4087 24 24 24C25.5913 24 27.1174 23.3679 28.2426 22.2426C29.3679 21.1174 30 19.5913 30 18C30 16.4087 29.3679 14.8826 28.2426 13.7574C27.1174 12.6321 25.5913 12 24 12ZM12 16.34C10.6739 16.34 9.40215 16.8668 8.46447 17.8045C7.52678 18.7421 7 20.0139 7 21.34C7 21.9966 7.12933 22.6468 7.3806 23.2534C7.63188 23.86 8.00017 24.4112 8.46447 24.8755C8.92876 25.3398 9.47996 25.7081 10.0866 25.9594C10.6932 26.2107 11.3434 26.34 12 26.34C13.76 26.34 15.3 25.42 16.18 24.06C14.84 22.36 14 20.3 14 18C14 17.6 14 17.2 14.08 16.8C13.44 16.5 12.74 16.34 12 16.34ZM36 16.34C35.26 16.34 34.56 16.5 33.92 16.8C34 17.2 34 17.6 34 18C34 20.3 33.16 22.36 31.82 24.06C32.7 25.42 34.24 26.34 36 26.34C36.6566 26.34 37.3068 26.2107 37.9134 25.9594C38.52 25.7081 39.0712 25.3398 39.5355 24.8755C39.9998 24.4112 40.3681 23.86 40.6194 23.2534C40.8707 22.6468 41 21.9966 41 21.34C41 20.6834 40.8707 20.0332 40.6194 19.4266C40.3681 18.82 39.9998 18.2688 39.5355 17.8045C39.0712 17.3402 38.52 16.9719 37.9134 16.7206C37.3068 16.4693 36.6566 16.34 36 16.34ZM24 28C20 28 12 30 12 34V38H36V34C36 30 28 28 24 28ZM9.34 29.94C6 30.52 2 32.08 2 34.66V38H8V34C8 32.44 8.58 31.06 9.34 29.94ZM38.66 29.94C39.42 31.06 40 32.44 40 34V38H46V34.66C46 32.08 42 30.52 38.66 29.94Z"
        fill={fill}
        opacity={opacity}
      />
      />
    </svg>
  );
};

export default IconHousehold;
