import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconKey = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M7 14C6.46957 14 5.96086 13.7893 5.58579 13.4142C5.21071 13.0391 5 12.5304 5 12C5 11.4696 5.21071 10.9609 5.58579 10.5858C5.96086 10.2107 6.46957 10 7 10C7.53043 10 8.03914 10.2107 8.41421 10.5858C8.78929 10.9609 9 11.4696 9 12C9 12.5304 8.78929 13.0391 8.41421 13.4142C8.03914 13.7893 7.53043 14 7 14ZM12.65 10C11.83 7.67 9.61 6 7 6C5.4087 6 3.88258 6.63214 2.75736 7.75736C1.63214 8.88258 1 10.4087 1 12C1 13.5913 1.63214 15.1174 2.75736 16.2426C3.88258 17.3679 5.4087 18 7 18C9.61 18 11.83 16.33 12.65 14H17V18H21V14H23V10H12.65Z"
        fill={fill}
      />
    </svg>
  );
};

IconKey.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconKey;
