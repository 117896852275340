import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import IconContainer from "components/_common/IconContainer";
import * as Icons from "components/_common/icons";

import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl-v3";

import differenceInMonths from "date-fns/differenceInCalendarMonths";
import differenceInDays from "date-fns/differenceInCalendarDays";
import addMonths from "date-fns/addMonths";
// import { formatDate } from "date-fns";
import format from "lib/formatWLocale";
import { formatMoney, trimString } from "lib/helpers";

import "./AssetCardDraggable.scss";

const styles = theme => ({
  button: {
    padding: "6px",
    borderRadius: "4px",

    "root:hover": {
      padding: "6px",
      borderRadius: "4px"
    },
    root: {
      padding: "6px",
      borderRadius: "4px"
    }
  }
});

class AssetCardDraggable extends PureComponent {


  static propTypes = {
    asset: PropTypes.object,
    handleEditAsset: PropTypes.func,
    handleDeleteAsset: PropTypes.func,
    handleDuplicateAsset: PropTypes.func,
    handleSelectAsset: PropTypes.func,
    designScheme: PropTypes.object,
    formScheme: PropTypes.object,
    designSchemeAssetDebt: PropTypes.object,
    formSchemeAdditional: PropTypes.object,
    formSchemeAssetDebt: PropTypes.object,
    isMobile: PropTypes.bool,

    additionalInfo: PropTypes.object,
    additionalAssets: PropTypes.object,
    isSelected: PropTypes.bool,
    isAccessEdit: PropTypes.bool,
    isWizardFinished: PropTypes.bool,
    containerWidth: PropTypes.number
  };

  state = {
    anchorEl: null,
    isExpanded: false,
    height: null,
    width: null,
    heightDefault: null,
    heightDefaultC: null,
    widthDefault: null
  };

  componentDidUpdate(prevProps, prevState, prevContext) {}

  handleClickDropDown = ev => {
    ev.stopPropagation();

    this.setState({ anchorEl: ev.currentTarget });
  };

  handleCloseDropDown = handle => ev => {
    ev.stopPropagation();

    this.setState({ anchorEl: null });
    if (handle) {
      handle();
    }
  };

  handleToggleTile = ev => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };

  _renderDropDown() {
    const { anchorEl } = this.state;

    const {
      classes,
      handleEditAsset,
      handleDeleteAsset,
      handleDuplicateAsset,
      isAccessEdit,
      isWizardFinished
    } = this.props;

    return (
      <div ref={this.dropdownRef} style={{ width: "100%" }}>
        <IconButton
          className={classes.button}
          aria-label="More"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClickDropDown}
        >
          <Icons.IconDotsHorizontal opacity={0.6} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseDropDown()}
        >
          <MenuItem onClick={this.handleCloseDropDown(handleEditAsset)}>
            {isAccessEdit || !isWizardFinished ? (
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.EDIT"
                defaultMessage="Edit"
              />
            ) : (
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.VIEW"
                defaultMessage="View"
              />
            )}
          </MenuItem>
          {isAccessEdit && handleDuplicateAsset && (
            <MenuItem onClick={this.handleCloseDropDown(handleDuplicateAsset)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.DUPLICATE"
                defaultMessage="Duplicate"
              />
            </MenuItem>
          )}
          {isAccessEdit && (
            <MenuItem onClick={this.handleCloseDropDown(handleDeleteAsset)}>
              <FormattedMessage
                id="HOUSEHOLD.ASSETS.CARDS.ACTIONS.DELETE"
                defaultMessage="Delete"
              />
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }

  render() {
    const {
      asset,
      designScheme,
      formScheme,
      formSchemeDebt,
      formSchemeAdditional,
      additionalAssets,
      isMobile,
      DragHandle,
      isAccessEdit
    } = this.props;

    if (!asset) {
      return null;
    }

    const Icon =
      (designScheme && designScheme.icon && Icons[designScheme.icon]) ||
      Icons.IconOther;

    const iconColor =
      designScheme && designScheme.color ? designScheme.color : "ui";

    //////////////////
    const { intl } = this.props;
    const labelIntl = intl.formatMessage({
      id: designScheme.titleI18n,
      defaultMessage: designScheme.title
    });

    // TODO temp
    // const expired = asset.isExpired ? (
    //   <span title="This tile can be remove" style={{ color: "red" }}>
    //     Exp
    //   </span>
    // ) : null;

    return (
      <section className="ac-draggable">
        <div
          className={`ac-draggable__wrapper ${
            this.state.isExpanded ? "ac-draggable__wrapper--expanded" : ""
          }`}
          onClick={this.handleToggleTile}
        >
          <div className="ac-draggable__top">
            {isAccessEdit ? (
              <DragHandle />
            ) : (
              <div className="ac-draggable__drag-stub" />
            )}

            <div className="ac-draggable__icon-tile">
              <IconContainer color={iconColor}>
                <Icon width={24} height={24} color={iconColor} />
              </IconContainer>
            </div>

            <div className="ac-draggable__title">{labelIntl}</div>

            {!isMobile && (
              <>
                <AssetContentUniversal
                  asset={asset}
                  additionalAssets={additionalAssets}
                  formScheme={formScheme}
                  designScheme={designScheme}
                  formSchemeDebt={formSchemeDebt}
                  formSchemeAdditional={formSchemeAdditional}
                />

                <div className="ac-draggable__dsc">
                  <div className="ac-draggable__label ac-draggable__label--dsc">
                    <FormattedMessage
                      id="PLANS.GOALS.FORM.DESCRIPTION.LABEL"
                      defaultMessage="Description"
                    />
                  </div>
                  {asset.description}
                </div>
              </>
            )}

            <div className="ac-draggable__dropdown">
              {this._renderDropDown()}
            </div>
          </div>

          {!!isMobile && (
            <>
              <AssetContentUniversal
                asset={asset}
                additionalAssets={additionalAssets}
                formScheme={formScheme}
                designScheme={designScheme}
                formSchemeDebt={formSchemeDebt}
                formSchemeAdditional={formSchemeAdditional}
              />

              <div className="asset-card__field asset-card__field--full-width ac-draggable__field--dsc">
                <div className="asset-card__label">
                  <FormattedMessage
                    id="HOUSEHOLD.ASSETS.USE.FORM.DESCRIPTION.LABEL"
                    defaultMessage="Description"
                  />
                </div>
                <div className="asset-card__value asset-card__value--secondary">
                  {asset.description}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
}

const AssetContentUniversal = ({
  asset,
  formScheme,
  designScheme,
  formSchemeDebt,
  formSchemeAdditional,
  additionalAssets
}) => {
  const makeFields = (
    isMain,
    valueDef,
    fieldKey,
    { fieldScheme = {} } = {}
  ) => {
    let value = valueDef;

    let label = fieldScheme.label;
    if (fieldScheme.labelI18nCard || fieldScheme.labelI18n) {
      label = (
        <FormattedMessage
          id={fieldScheme.labelI18nCard || fieldScheme.labelI18n}
          defaultMessage={fieldScheme.label || fieldScheme.label}
        />
      );
    }

    if (isMain && !(fieldScheme.isMain || fieldScheme.isMainCondition)) {
      return null;
    }

    if (
      !isMain &&
      !(fieldScheme.isAdditional || fieldScheme.isAdditionalCondition)
    ) {
      return null;
    }

    if (!value && !fieldScheme.isOnlyShow && !fieldScheme.isAmount) {
      return null;
    }

    const isConditionAccept = checkAllCondition(asset, fieldScheme, isMain);

    if (fieldScheme.isHidden || !isConditionAccept) {
      return null;
    }

    if (fieldScheme.calculate || fieldScheme.calculateIf) {
      let calculatedValue;

      if (fieldScheme.calculate.isMonthsLeft) {
        calculatedValue = calculateMonthsLeft(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isDaysLeft) {
        calculatedValue = calculateDaysLeft(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isPurchaseAmount) {
        calculatedValue = calculateInvestedPurchAmount(
          asset,
          fieldScheme.calculate
        );
      }

      if (fieldScheme.calculate.isInvestedOfferingDate) {
        calculatedValue = calculateInvestedOfferingDate(
          asset,
          fieldScheme.calculate
        );
      }

      if (fieldScheme.calculate.isFromAgeToAge) {
        calculatedValue = calculateFromAgeToAge(asset, fieldScheme.calculate);
      }

      if (fieldScheme.calculate.isAmountEarned) {
        calculatedValue = calculateInvestedAmountEarned(asset);
      }

      if (fieldScheme.calculate.isUnvestedEstimate) {
        calculatedValue = calculateUnvestedEstimate(asset);
      }

      if (fieldScheme.calculate.isDebtRemainingFixed) {
        calculatedValue = calculateDebtRemainingFixed(asset);
      }

      if (fieldScheme.calculate.isDebtRemainingAmortization) {
        calculatedValue = calculateDebtRemainingAmortization(asset);
      }

      if (fieldScheme.calculate.isDebtAnnualPayFixed) {
        calculatedValue = calculateDebtAnnualPayFixed(asset);
      }

      if (fieldScheme.calculate.isDebtAnnualPayAmortization) {
        calculatedValue = calculateDebtAnnualPayAmortization(asset);
      }

      if (fieldScheme.calculate.isEarningsIn) {
        calculatedValue = calculateMoneyInEarning(asset);
      }

      if (fieldScheme.calculate.isAnnualRate) {
        calculatedValue = calculateAnnualRent(asset);
      }

      if (fieldScheme.calculate.isGoalsTotalAmount) {
        calculatedValue = calculateGoalTotalAmount(
          asset,
          fieldScheme.calculate
        );
      }

      value = calculatedValue ? calculatedValue : value;
    }

    if (fieldScheme.isSelect && !fieldScheme.isAdditionalAsset) {
      const found = fieldScheme.options.find(item => item.value === value);

      if (found && found.nameI18n) {
        value = (
          <FormattedMessage id={found.nameI18n} defaultMessage={found.name} />
        );
      }
    }

    if (fieldScheme.isShowDate) {
      if (value && value.seconds) {
        value = format(value.seconds * 1000, fieldScheme.isShowDate);
      }
    }

    if (fieldScheme.isAdditionalAsset) {
      const isAdditionalAsset = fieldScheme.isAdditionalAsset;
      const additionalAssetField = fieldScheme.additionalAssetField;

      value =
        isAdditionalAsset &&
        additionalAssets[isAdditionalAsset] &&
        additionalAssets[isAdditionalAsset][
          asset[fieldScheme.fieldCustom || "asset"]
        ] &&
        additionalAssets[isAdditionalAsset][
          asset[fieldScheme.fieldCustom || "asset"]
        ][additionalAssetField]
          ? trimString(
              additionalAssets[isAdditionalAsset][
                asset[fieldScheme.fieldCustom || "asset"]
              ][additionalAssetField],
              100
            )
          : ""; //asset[fieldKey];

      if (!value) {
        return null;
      }
    }

    let valueShow =
      fieldScheme.isAmount && !fieldScheme.calculate
        ? formatMoney(value, 0, "", ",")
        : value;

    valueShow = fieldScheme.prefix
      ? `${fieldScheme.prefix}${valueShow}`
      : valueShow;

    if (fieldScheme.isGroupLabel) {
      return (
        <div
          className={
            "ac-draggable__field ac-draggable__field--full-width ac-draggable__field--group-label"
          }
          key={fieldKey}
        >
          {label}
        </div>
      );
    }

    // TODO formattedMessage label
    return (
      <div
        className={`ac-draggable__field ${
          fieldScheme.isFullWidth ? "ac-draggable__field--full-width" : ""
        } ${
          fieldScheme.isThirdWidth ? "ac-draggable__field--third-width" : ""
        }`}
        key={fieldKey}
      >
        {isMain ? (
          <div className="ac-draggable__label ac-draggable__label--main">
            {label}
          </div>
        ) : (
          <div className="ac-draggable__label">{label}</div>
        )}

        <div
          className={`ac-draggable__value ${
            fieldScheme.isSecondary ? "ac-draggable__value--secondary" : ""
          }`}
        >
          {fieldScheme.isAmount && <span>$</span>}
          {valueShow}
          {` `}
          {fieldScheme.after}
        </div>
      </div>
    );
  };

  const mainFields = Object.keys(formScheme).map(fieldKey => {
    const value = asset[fieldKey];

    return makeFields(true, value, fieldKey, {
      fieldScheme: formScheme[fieldKey]
    });
  });

  let mainFieldsDebt = [];

  const additionalFields = Object.keys(formScheme).map(fieldKey => {
    const value = asset[fieldKey];

    return makeFields(false, value, fieldKey, {
      fieldScheme: formScheme[fieldKey]
    });
  });

  let additionalFieldsDebt = [];
  if (formSchemeAdditional) {
    Object.keys(formSchemeAdditional).forEach(key => {
      Object.keys(formSchemeAdditional[key]).forEach(fieldKey => {
        const fieldScheme = formSchemeAdditional[key][fieldKey];

        const value = asset[key] ? asset[key][fieldKey] : "";

        additionalFieldsDebt.push(
          makeFields(false, value, fieldKey, { fieldScheme })
        );

        mainFieldsDebt.push(makeFields(true, value, fieldKey, { fieldScheme }));
      });
    });
  }

  return (
    <div className="ac-draggable__fields-container">
      <div className="ac-draggable__fields">
        {[...mainFields, ...mainFieldsDebt]}
      </div>

      <div className="ac-draggable__more">
        <div className="ac-draggable__fields">
          {[...additionalFields, ...additionalFieldsDebt]}
        </div>
      </div>
    </div>
  );
};

function checkAllCondition(asset, fieldScheme, isMain) {
  let isConditionAccept = true;

  if (fieldScheme.condition) {
    fieldScheme.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  if (
    fieldScheme.isMainCondition &&
    fieldScheme.isMainCondition.condition &&
    isMain
  ) {
    fieldScheme.isMainCondition.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  if (
    fieldScheme.isAdditionalCondition &&
    fieldScheme.isAdditionalCondition.condition &&
    !isMain
  ) {
    fieldScheme.isAdditionalCondition.condition.forEach(condition => {
      isConditionAccept = checkCondition(asset, condition) && isConditionAccept;
    });
  }

  return isConditionAccept;
}

function checkCondition(asset, condition) {
  let isConditionAccept = true;

  let assetField;
  if (condition.fieldDebt) {
    assetField = asset.debt[condition.fieldDebt];
  } else {
    assetField = asset[condition.field];
  }

  if (condition.isFilled) {
    isConditionAccept = !!assetField;
  }

  if (condition.isNotFilled) {
    isConditionAccept = !assetField;
  }

  if (condition.value) {
    // eslint-disable-next-line
    isConditionAccept = assetField == condition.value;
  }

  return isConditionAccept;
}

function calculateMonthsLeft(asset, calculate) {
  let value;
  let field;
  if (calculate.dateFieldDebt) {
    field = asset.debt[calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const monthsLeft = differenceInMonths(field.seconds * 1000, Date.now());

  if (monthsLeft < 0) {
    return (
      <FormattedMessage
        id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.PAYMENTS_ENDED"
        defaultMessage="Payments Ended"
      />
    );
  }

  if (monthsLeft < 1) {
    return (
      <FormattedMessage
        id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.CONTRACT_DATE.LESS_MONTH"
        defaultMessage="Less Than Month"
      />
    );
  }

  value = (
    <>
      {monthsLeft}
      {` `}
      {monthsLeft === 1 ? (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.CONTRACT_DATE.MONTH_LEFT"
          defaultMessage="Months Remaining"
        />
      ) : (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.DEBT.CONTRACT_DATE.MONTHS_LEFT"
          defaultMessage="Months Remaining"
        />
      )}
    </>
  );

  return value;
}

function calculateDaysLeft(asset, calculate) {
  let value;
  let field;

  if (calculate.dateFieldDebt) {
    field = asset.debt[calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const daysLeft = differenceInDays(field.seconds * 1000, Date.now());

  if (daysLeft < 1) {
    return <span>-</span>;
  }

  value = (
    <>
      {daysLeft}
      {` `}
      {daysLeft === 1 ? (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAY_LEFT"
          defaultMessage="Day Left"
        />
      ) : (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAYS_LEFT"
          defaultMessage="Days Left"
        />
      )}
    </>
  );

  return value;
}

function calculateInvestedPurchAmount(asset, calculate) {
  const FACTOR = (100 + +asset.discountPercent) / 100;
  let field;

  if (calculate.fieldDebt) {
    field = asset.debt[calculate.fieldDebt];
  } else {
    field = asset[calculate.field];
  }

  return formatMoney(field * FACTOR, 0);
}

function calculateInvestedOfferingDate(asset, calculate) {
  const MONTHS = 6;
  let value;
  let field;

  if (calculate.dateFieldDebt) {
    field = asset.debt[calculate.dateFieldDebt];
  } else {
    field = asset[calculate.dateField];
  }

  const date = addMonths(field.seconds * 1000, MONTHS);

  const daysLeft = differenceInDays(date, Date.now());

  if (daysLeft < 1) {
    return <span>-</span>;
  }

  value = (
    <>
      {daysLeft}
      {` `}
      {daysLeft === 1 ? (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAY_LEFT"
          defaultMessage="Day Left"
        />
      ) : (
        <FormattedMessage
          id="HOUSEHOLD.ASSETS.USE.FORM.INVESTED.DAYS_LEFT"
          defaultMessage="Days Left"
        />
      )}
    </>
  );

  return value;
}

function calculateFromAgeToAge(asset, calculate) {
  if (asset.debt.fromAge && asset.debt.toAge) {
    return `${asset.debt.fromAge} - ${asset.debt.toAge}`;
  }

  if (!asset.debt.fromAge && asset.debt.toAge) {
    return `Till ${asset.debt.toAge}`;
  }

  if (asset.debt.fromAge && !asset.debt.toAge) {
    return `From ${asset.debt.fromAge}`;
  }

  return null;
}

function calculateInvestedAmountEarned(asset) {
  if (
    !asset.investmentValue ||
    !asset.interestEarned ||
    !asset.interestPaidFrequency
  ) {
    return 0;
  }

  const value =
    ((asset.investmentValue * asset.interestEarned) / 100) *
    asset.interestPaidFrequency;

  return value;
}

function calculateUnvestedEstimate(asset) {
  if (!asset.numberOfUnvestedUnits || !asset.stockPrice) {
    return 0;
  }

  const value = formatMoney(asset.numberOfUnvestedUnits * asset.stockPrice, 0);

  return `$${value}`;
}

function _helperCalcDebtRemainingAmortization(asset) {
  const interest = asset.interestRate / 100;
  /////////////////////////////////////////////////////////////////////////////////
  // N - Number of months - 10 months
  // n - Number of months passed since loan date
  // L - Loans amount - $1000
  // IR - interest rate - 10%
  // D - Discount factor = {[(1 + IR) ^N] - 1} / [IR*(1 + IR)^N]
  // Debt per Month = L/D
  // Discount factor = {[(1 + 0.1) ^10] - 1} / [0.1*(1 + 0.1)^10]=6.144
  // Debt per Month = 1000/6.144=162.76
  // Remaining Debt = (N-n)*162.76

  const common = Math.pow(1 + interest, asset.numberOfMonths);
  const discountFactor = common / (interest * common);

  const debtPerMonth = asset.loanAmount / discountFactor;

  if (asset.loanDate && asset.loanDate.seconds) {
    const monthsPassed = differenceInMonths(
      Date.now(),
      asset.loanDate.seconds * 1000
    );

    const value = (asset.numberOfMonths - monthsPassed) * debtPerMonth;

    if (value < 0) {
      return 0;
    }

    return value;
  }

  return 0;
}

function calculateDebtRemainingAmortization(asset) {
  const value = _helperCalcDebtRemainingAmortization(asset);

  return formatMoney(value, 0);
}

function calculateDebtAnnualPayAmortization(asset) {
  // 12*Remaining debt/number of months
  const debtRemaining = _helperCalcDebtRemainingAmortization(asset);
  const value = (12 * debtRemaining) / asset.numberOfMonths;

  return formatMoney(value, 0);
}

function _helperCalcDebtRemainingFixed(asset) {
  //Remaining Debt: $ periods remaining*periodic payment amount
  return +asset.periodsRemaining * +asset.periodicPayment;
}

function calculateDebtRemainingFixed(asset) {
  const value = _helperCalcDebtRemainingFixed(asset);

  return `$${formatMoney(value, 0)}`;
}

function calculateDebtAnnualPayFixed(asset) {
  // Annual payment: calc remaining debt/(periods remaining*12 if monthly or 4 of quarterly, 1 otherwise),
  const remainingDebt = _helperCalcDebtRemainingFixed(asset);

  const value = (remainingDebt / asset.periodsRemaining) * asset.frequency;

  return `$${formatMoney(value, 0)}`;
}

function calculateMoneyInEarning(asset) {
  const value = +asset.baseSalary + +asset.variable;

  return `$${formatMoney(value, 0)}`;
}

// TODO check
function calculateAnnualRent(asset) {
  const value =
    asset.monthlyRent * 12 -
    (asset.averageVacancy * asset.monthlyRent * 12) / 365;

  return `$${formatMoney(value, 0)}`;
}

function calculateGoalTotalAmount(asset, calculate) {
  const value = asset.amountPerYear * asset.howManyYears;

  return formatMoney(value, 0);
}

export default withStyles(styles)(injectIntl(AssetCardDraggable));
