import { colors, BOX_SHADOW_1 } from "constants/styles";

export const styles = theme => ({
  button: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 32,
    paddingRight: 32,
    minWidth: 150,

    borderRadius: 4,

    fontSize: 16,
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.5px",
    fontWeight: 700,

    color: colors.white,
    backgroundColor: colors.uiYellow,

    "& a": {
      color: colors.white,
      textDecoration: "none"
    },

    "&:hover": { backgroundColor: colors.uiYellow, opacity: 0.8 }
  },

  buttonAdd: {
    marginTop: 16,

    "&:disabled": {
      opacity: 0.7,
      color: colors.white,
      cursor: "not-allowed"
    }
  },

  buttonSmallAdvisor: {
    minWidth: 0,
    paddingRight: 24,
    paddingLeft: 24
  },
  buttonChartPlusMinus: {
    margin: 2,
    width: 32,
    minWidth: 0,
    height: 32,
    minHeight: 26,
    padding: "5px 6px",
    borderRadius: 6,
    fontWeight: 700,
    boxShadow: BOX_SHADOW_1,
    fontSize: 18,
    lineHeight: 1,
    //     background: #6885EE;
    // box-shadow: 0px 3px 6px rgba(104, 133, 238, 0.5);
    // border-radius: 4px;
    transitionProperty: "none",

    color: colors.white,

    "&:not(.important)": {
      backgroundColor: colors.ui,
      color: colors.white,

      opacity: 1
    },

    "&:disabled": {
      opacity: 0.4
    },
    "&:hover": {
      opacity: 0.9
    }
  },

  buttonChart: {
    margin: 2,
    width: 26,
    minWidth: 0,
    height: 26,
    minHeight: 26,
    padding: "5px 6px",
    borderRadius: 6,
    fontWeight: 700,
    boxShadow: BOX_SHADOW_1,
    fontSize: 11,
    lineHeight: "16px",

    transitionProperty: "none",

    "&:not(.important)": {
      backgroundColor: colors.white,
      // color: colors.brand,
      color: colors.textTetrieary,

      opacity: 1
    },

    "&:hover": {
      backgroundColor: colors.brand,
      color: colors.white,
      opacity: 0.9
    }
  },

  buttonChartDot: {
    "&:not(.important)": {
      // width: 7,
      width: 9,
      height: 7,
      minHeight: 0,
      padding: 0,
      marginTop: -1,
      boxShadow: "none",

      boxSizing: "border-box",

      backgroundColor: "transparent"
    }
  },
  buttonChartNegative: {
    "&:not(.important):not(.important)": {
      color: colors.red
    },
    "&:not(.important):hover": {
      backgroundColor: colors.red,
      color: colors.white,
      opacity: 0.9
    }
  },
  buttonChartDotNegative: {
    // "&:not(.important):not(.important)": {
    //   backgroundColor: colors.red
    // },
    // "&:not(.important):hover": {
    //   backgroundColor: colors.red,
    //   opacity: 0.9
    // }
  },
  buttonChartDotNegativeActive: {
    // "&:not(.important):not(.important):not(.important)": {
    //   backgroundColor: colors.red,
    //   color: colors.white
    // }
  },
  buttonChartNegativeActive: {
    "&:not(.important):not(.important):not(.important)": {
      backgroundColor: colors.red,
      color: colors.white
    }
  },
  buttonChartDotInnerNegative: {
    display: "block",
    width: 4,
    height: 1,
    borderRadius: "50%",
    backgroundColor: colors.red
  },
  buttonChartDotInner: {
    display: "block",
    width: 4,
    height: 1,
    borderRadius: "50%",
    backgroundColor: colors.textTetrieary
  },

  buttonChartActive: {
    "&:not(.important)": {
      backgroundColor: colors.brand,
      color: colors.white
    }
  },

  buttonChartYear: {
    "&:not(.important)": {
      width: 36,
      minWidth: 36
    }
  },

  buttonWhite: {
    color: colors.textTetrieary,
    backgroundColor: colors.white,
    border: `1px solid ${colors.textTetrieary}`,

    "&:hover": { backgroundColor: colors.divider },
    "&:disabled": {
      backgroundColor: colors.white,
      cursor: "not-allowed"
    }
  },

  buttonUi: {
    color: colors.white,
    backgroundColor: colors.ui,
    boxShadow: "0px 3px 6px rgba(104, 133, 238, 0.5)",

    "&:hover": { backgroundColor: colors.ui, opacity: 0.8 },
    "&:disabled": {
      color: colors.white,

      backgroundColor: colors.ui,
      opacity: 0.8,
      cursor: "not-allowed"
    }
  },
  buttonBrand: {
    color: colors.white,
    backgroundColor: colors.brand,
    boxShadow: "0px 2px 4px rgba(30, 50, 90, 0.15)",

    "&:hover": { backgroundColor: colors.brand08, opacity: 1 },
    "&:disabled": {
      backgroundColor: colors.brand08,
      opacity: 0.8,
      cursor: "not-allowed"
    }
  },
  buttonYellow: {
    color: colors.white,
    backgroundColor: colors.yellow,
    boxShadow: "0px 3px 6px rgba(245, 147, 0, 0.5)",

    "&:hover": { backgroundColor: colors.yellow08, opacity: 1 },
    "&:disabled": {
      backgroundColor: colors.yellow08,
      opacity: 0.8,
      color: colors.white,
      cursor: "not-allowed"
    }
  },

  buttonUiLight: {
    color: colors.ui,
    backgroundColor: colors.uiLight,

    "&:hover": { backgroundColor: colors.uiLight, opacity: 0.8 },
    "&:disabled": {
      backgroundColor: colors.uiLight,
      opacity: 0.8,
      cursor: "not-allowed"
    }
  }
});
