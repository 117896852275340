import React, { Component } from "react";

import Link from "@material-ui/core/Link/Link";
import Typography from "@material-ui/core/Typography/Typography";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { withStyles } from "@material-ui/core";
import NoSsr from "@material-ui/core/NoSsr";

import styles from "./styles";

import { Route } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { breadcrumbNameMap } from "constants/menu";
import { FormattedMessage } from "react-intl-v3";

class AppBarBreadcrumbs extends Component {
  render() {
    const { classes } = this.props;

    return (
      <NoSsr>
        <Route>
          {({ location }) => {
            const pathNames = location.pathname.split("/").filter(x => x);

            return (
              <Breadcrumbs
                className={classes.appBarBreadCrumbs}
                arial-label="Breadcrumb"
              >
                {!(pathNames[0] === "home" && pathNames.length === 1) &&
                  pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join("/")}`;

                    if (!breadcrumbNameMap[to]) {
                      return null;
                    }

                    return last ? (
                      <Typography
                        color="textPrimary"
                        key={to}
                        className={classes.textTetrieary}
                      >
                        <FormattedMessage
                          id={breadcrumbNameMap[to].nameI18n}
                          defaultMessage={breadcrumbNameMap[to].name}
                        />
                      </Typography>
                    ) : (
                      <Link
                        className={classes.textTetrieary}
                        component={RouterLink}
                        to={to}
                        key={to}
                      >
                        <FormattedMessage
                          id={breadcrumbNameMap[to].nameI18n}
                          defaultMessage={breadcrumbNameMap[to].name}
                        />
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            );
          }}
        </Route>
      </NoSsr>
    );
  }
}

export default withStyles(styles)(AppBarBreadcrumbs);
