import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import { styles } from "./DialogWizard.styles";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import { setUI } from "AC/ui";

import WizardWelcome from "./Wizard/WizardWelcome";
import WizardNeeds from "./Wizard/WizardNeeds";
import WizardFamily from "./Wizard/WizardFamily";
import WizardMoney from "./Wizard/WizardMoney";
import WizardApproximation from "./Wizard/WizardApproximation";
import WizardAssets from "./Wizard/WizardAssets";
import WizardAssetsDebt from "./Wizard/WizardAssetsDebt";
import WizardFinish from "./Wizard/WizardFinish";
import {
  firestoreConnect,
  withFirebase,
  withFirestore
} from "react-redux-firebase";
import { connect } from "react-redux";
import subYears from "date-fns/subYears";
import addYears from "date-fns/addYears";

import {
  ENTITIES as entitiesMoneyIn,
  GLOBAL_TYPE_USE_ASSETS as typeMoneyIn,
  GLOBAL_TYPE_COLLECTION as typeCollMoneyIn,
  GLOBAL_COLLECTION as collectionMoneyIn
} from "../HouseholdMoneyIn/formScheme";
import {
  ENTITIES as entitiesInvested,
  GLOBAL_COLLECTION_USE_ASSETS as collectionInvested,
  GLOBAL_TYPE_USE_ASSETS as typeInvested
} from "../HouseholdAssetsInvested/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as typeAccounts,
  GLOBAL_COLLECTION as collectionAccounts,
  ENTITIES as entitiesAccounts
} from "../HouseholdAccounts/formScheme";
import {
  ENTITIES as entitiesSavings,
  GLOBAL_COLLECTION_USE_ASSETS as collectionSavings,
  GLOBAL_TYPE_USE_ASSETS as typeSavings,
  optionsInsuranceTypeValue
} from "../HouseholdAssetsSavings/formScheme";
import {
  ENTITIES as entitiesOutFamily,
  GLOBAL_COLLECTION as collectionOutFamily,
  GLOBAL_TYPE_USE_ASSETS as typeOutFamily,
  GLOBAL_TYPE_COLLECTION as typeCollOutFamily
} from "../MoneyOutPersonal/formScheme";
import {
  ENTITIES as entitiesOutAssets,
  GLOBAL_TYPE_USE_ASSETS as typeOutAssets,
  GLOBAL_TYPE_COLLECTION as typeCollOutAssets
} from "../MoneyOutAssets/formScheme";
import {
  ENTITIES as entitiesOutOther,
  GLOBAL_COLLECTION as collectionOutOther,
  GLOBAL_TYPE_USE_ASSETS as typeOutOther,
  GLOBAL_TYPE_COLLECTION as typeCollOutOther
} from "../MoneyOutOther/formScheme";
import {
  defaultHomeTaxRate,
  ENTITIES as useEntities,
  GLOBAL_COLLECTION_USE_ASSETS as collectionUse,
  GLOBAL_TYPE_USE_ASSETS as typeUse,
  optionsValuesHome,
  optionsValuesHomeTaxType
} from "../HouseholdAssetsUse/formScheme";
import {
  GLOBAL_TYPE_USE_ASSETS as typeDebt,
  ENTITIES as entitiesDebt,
  optionsRepaymentValues
} from "../HouseholdDebt/formScheme";
import { optionsEmployedStatusValues } from "../HouseholdMembers/HouseholdMembers";
import {
  getAssetsAllWizardData,
  getTransactionsAllDataWizard
} from "../../store/selectors/firebase";
import { WIZARD_DEFAULTS, INVESTMENT_RENT_LIMIT } from "../../constants/wizard";

import {
  cloneObject,
  getWizardDefault,
  roundToThousand
} from "../../lib/helpers";
import DialogCommon from "../AssetsCommon/DialogCommon";
import { FormattedMessage, injectIntl } from "react-intl-v3";

import {
  defaultChanges,
  defaultChangesType,
  INFLATION,
  valuesInflation
} from "constants/inflation";
import { optionsTaxCategoryValues } from "../AssetsCommon/_formScheme";
import { taxPropertyValue } from "../../constants/taxesAndDashboard";

const mergeLodash = require("lodash.merge");

const WizardSteps = {
  "": WizardWelcome,
  needs: WizardNeeds,
  family: WizardFamily,
  money: WizardMoney,
  approximation: WizardApproximation,
  assets: WizardAssets,
  assetsDebt: WizardAssetsDebt,
  finish: WizardFinish
};

const stepArray = [
  "",
  "needs",
  "family",
  "money",
  "approximation",
  "assets",
  "assetsDebt",
  "finish"
];

const getDefaultState = (wizardTemplate = "default") => {
  let currentWizardTemplate = wizardTemplate;

  if (!WIZARD_DEFAULTS[wizardTemplate]) {
    currentWizardTemplate = "default";
  }

  return {
    wizardTemplate,
    needs: {
      needAdvice: false,
      needPlanning: false,
      planEvents: false,
      savedMoney: false,
      unexpectedEvents: false
    },
    family: {
      age: WIZARD_DEFAULTS[currentWizardTemplate].family.AGE,
      dependents: WIZARD_DEFAULTS[currentWizardTemplate].family.DEPENDENTS,
      taxesStatus: "married",
      employmentStatus: "employee",
      spouseStatus: "employee",
      zipCode: ""
    },
    money: {
      totalSalary: "",
      collectingRents:
        WIZARD_DEFAULTS[currentWizardTemplate].money.RENTS_DEFAULT,

      primaryResidence: "own",
      // houseValue:
      //   WIZARD_DEFAULTS[currentWizardTemplate].money.HOUSE_VALUE_DEFAULT,
      propertyTax:
        WIZARD_DEFAULTS[currentWizardTemplate].money.PROPERTY_TAX_DEFAULT,
      monthlyRent:
        WIZARD_DEFAULTS[currentWizardTemplate].money.MONTHLY_RENT_DEFAULT,
      maintenance: WIZARD_DEFAULTS[currentWizardTemplate].money.HOA_DEFAULT,
      // utilities: WIZARD_DEFAULTS[currentWizardTemplate].money.UTILITIES_DEFAULT,

      vacation: WIZARD_DEFAULTS[currentWizardTemplate].money.VACATION_DEFAULT
    },
    approximation: {
      groceries:
        WIZARD_DEFAULTS[currentWizardTemplate].approximation.GROCERIES_DEFAULT,
      diningOut:
        WIZARD_DEFAULTS[currentWizardTemplate].approximation.DINING_DEFAULT,
      dependentCare: "",
      medicalAndHealth: "",
      transportation:
        WIZARD_DEFAULTS[currentWizardTemplate].approximation
          .TRANSPORTATION_DEFAULT,
      insurance:
        WIZARD_DEFAULTS[currentWizardTemplate].approximation.INSURANCE_DEFAULT,
      other: WIZARD_DEFAULTS[currentWizardTemplate].approximation.OTHER_DEFAULT
    },
    assets: {
      homesVehicles: "",
      retirementFunds: "",
      purposelySaved: "",
      deathBenefit: "",
      invested: "",
      checkingOrSaving: ""
    },
    assetsDebt: {
      remains: "",
      interestDebt:
        WIZARD_DEFAULTS[currentWizardTemplate].assetsDebt.INTEREST_DEBT_DEFAULT,
      otherDebt:
        WIZARD_DEFAULTS[currentWizardTemplate].assetsDebt.OTHER_DEBT_DEFAULT
    },

    isChanged: {
      family: false,
      money: false,
      approximation: false,
      assets: false,
      assetsDebt: false
    }
  };
};

class DialogWizard extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    name: PropTypes.string,
    assetsAll: PropTypes.any,
    transactionsAll: PropTypes.any
  };

  state = {
    ...cloneObject(
      getDefaultState(this.props.wizard && this.props.wizard.wizardTemplate)
    ),

    wizardTemplate: null,
    changedFields: [],
    step: "",
    isDialogNotLastStepFinish: false,
    funcIfAccept: null
  };

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      (this.props.firestoreStatus.requested.wizard &&
        !prevProps.firestoreStatus.requested.wizard) ||
      (!this.props.firestoreStatus.requesting.wizard &&
        prevProps.firestoreStatus.requesting.wizard) ||
      (this.props.wizard && !prevProps.wizard) ||
      (!this.props.wizard && prevProps.wizard)
    ) {
      this.updateState();
    }

    if (!this.props.wizard && prevProps.wizard) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState(prevState => ({
      ...prevState,
      ...cloneObject(getDefaultState())
    }));
  };

  updateState = () => {
    const { wizard } = this.props;
    let newState = {};

    const { changedFields: changedFieldsState } = this.state;

    if (wizard) {
      newState.changedFields =
        changedFieldsState && changedFieldsState.length
          ? changedFieldsState
          : wizard.changedFields;

      if (this.state.wizardTemplate) {
        newState.wizardTemplate = wizard.wizardTemplate || "default";
      }

      if (!WIZARD_DEFAULTS[newState.wizardTemplate]) {
        newState.wizardTemplate = "default";
      }

      Object.keys(wizard).forEach(wizardKey => {
        const wizardValue = wizard[wizardKey];
        const [formKey, fieldKey] = wizardKey.split("_");

        if (formKey && fieldKey && (!!wizardValue || +wizardValue === 0)) {
          if (!newState[formKey]) {
            newState[formKey] = {};
          }

          newState[formKey][fieldKey] = wizardValue;
        }
      });
    } else {
      newState.step = "";
    }

    const calcDefaultState = this.updateDefaults(newState, { isBoot: true });

    const state = mergeLodash(
      cloneObject(getDefaultState(wizard && wizard.wizardTemplate)),
      newState,
      calcDefaultState
    );

    this.setState(prevState => ({
      ...prevState,
      ...state
    }));
  };

  updateDefaults = (newState, { isBoot, currentField } = {}) => {
    const defaultState = {};

    let currentSet =
      newState.wizardTemplate || this.state.wizardTemplate || "default";

    if (!WIZARD_DEFAULTS[currentSet]) {
      currentSet = "default";
    }

    const changedFieldsProps =
      this.props.wizard && this.props.wizard.changedFields;
    const changedFieldsState =
      this.state.changedFields && this.state.changedFields.length
        ? this.state.changedFields
        : null;

    let changedFields = changedFieldsState || changedFieldsProps || [];
    changedFields = [...changedFields, currentField];

    const fillSimpleDefault = (defaultState, section, field, defaultName) => {
      const changedFieldsLocal = changedFields || [];

      if (!~changedFields.indexOf(field)) {
        if (!defaultState[section]) {
          defaultState[section] = {};
        }

        defaultState[section][field] =
          WIZARD_DEFAULTS[currentSet][section][defaultName];
      }
    };

    if (newState) {
      if (newState.family || isBoot) {
        fillSimpleDefault(defaultState, "family", "age", "AGE");
        fillSimpleDefault(defaultState, "family", "dependents", "DEPENDENTS");
      }

      if (newState.money || isBoot) {
        if (!~changedFields.indexOf("totalSalary")) {
          if (!defaultState.money) {
            defaultState.money = {};
          }

          defaultState.money.totalSalary =
            newState.family && newState.family.taxesStatus !== "married"
              ? WIZARD_DEFAULTS[currentSet].money.SALARY_DEFAULT_SINGLE
              : WIZARD_DEFAULTS[currentSet].money.SALARY_DEFAULT_MARRIED;
        }

        fillSimpleDefault(
          defaultState,
          "money",
          "collectingRents",
          "RENTS_DEFAULT"
        );

        fillSimpleDefault(
          defaultState,
          "money",
          "propertyTax",
          "PROPERTY_TAX_DEFAULT"
        );
        fillSimpleDefault(defaultState, "money", "maintenance", "HOA_DEFAULT");

        fillSimpleDefault(
          defaultState,
          "money",
          "vacation",
          "VACATION_DEFAULT"
        );
      }

      const dependentsCount =
        (newState.family &&
          (newState.family.dependents || newState.family.dependents === 0)) ||
        WIZARD_DEFAULTS[currentSet].family.DEPENDENTS;

      const memberAge =
        (newState.family && newState.family.age) ||
        WIZARD_DEFAULTS[currentSet].family.AGE;

      if (newState.approximation || isBoot) {
        if (!~changedFields.indexOf("dependentCare")) {
          if (!defaultState.approximation) {
            defaultState.approximation = {};
          }

          defaultState.approximation.dependentCare =
            dependentsCount *
            WIZARD_DEFAULTS[currentSet].approximation.DEPENDENT_DEFAULT_MULT;
        }

        if (!~changedFields.indexOf("medicalAndHealth")) {
          if (!defaultState.approximation) {
            defaultState.approximation = {};
          }

          defaultState.approximation.medicalAndHealth =
            WIZARD_DEFAULTS[currentSet].approximation.DEPENDENT_DEFAULT_MULT;

          defaultState.approximation.medicalAndHealth += dependentsCount
            ? dependentsCount *
              WIZARD_DEFAULTS[currentSet].approximation.DEPENDENT_DEFAULT_MULT
            : 0;

          if (
            !newState.family ||
            (newState.family && newState.family.taxesStatus === "married")
          ) {
            defaultState.approximation.medicalAndHealth +=
              WIZARD_DEFAULTS[currentSet].approximation.DEPENDENT_DEFAULT_MULT;
          }
        }

        fillSimpleDefault(
          defaultState,
          "approximation",
          "groceries",
          "GROCERIES_DEFAULT"
        );
        fillSimpleDefault(
          defaultState,
          "approximation",
          "diningOut",
          "DINING_DEFAULT"
        );
        fillSimpleDefault(
          defaultState,
          "approximation",
          "transportation",
          "TRANSPORTATION_DEFAULT"
        );
        fillSimpleDefault(
          defaultState,
          "approximation",
          "insurance",
          "INSURANCE_DEFAULT"
        );
        fillSimpleDefault(
          defaultState,
          "approximation",
          "other",
          "OTHER_DEFAULT"
        );
      }

      if (newState.assets || newState.money || isBoot) {
        if (!~changedFields.indexOf("homesVehicles")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          let primaryHomeValue;
          if (this.state.money && this.state.money.propertyTax) {
            primaryHomeValue = roundToThousand(
              this.state.money.propertyTax / taxPropertyValue
            );
          }

          const defaultHomesVehicles = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.HOMES_VEHICLES_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.HOMES_VEHICLES_DEFAULT.amount
          );

          defaultState.assets.homesVehicles =
            primaryHomeValue > defaultHomesVehicles
              ? primaryHomeValue
              : defaultHomesVehicles;
        }

        if (!~changedFields.indexOf("retirementFunds")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          defaultState.assets.retirementFunds = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.RETIREMENT_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.RETIREMENT_DEFAULT.amount
          );
        }

        if (!~changedFields.indexOf("purposelySaved")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          defaultState.assets.purposelySaved = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.SAVED_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.SAVED_DEFAULT.amount
          );
        }

        if (!~changedFields.indexOf("deathBenefit")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          defaultState.assets.deathBenefit = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.DEATH_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.DEATH_DEFAULT.amount
          );
        }

        if (!~changedFields.indexOf("invested")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          defaultState.assets.invested = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.INVESTED_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.INVESTED_DEFAULT.amount
          );
        }

        if (!~changedFields.indexOf("checkingOrSaving")) {
          if (!defaultState.assets) {
            defaultState.assets = {};
          }

          defaultState.assets.checkingOrSaving = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assets.CHECKING_SAVING_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assets.CHECKING_SAVING_DEFAULT.amount
          );
        }
      }

      if (newState.assetsDebt || isBoot) {
        if (!~changedFields.indexOf("remains")) {
          if (!defaultState.assetsDebt) {
            defaultState.assetsDebt = {};
          }

          defaultState.assetsDebt.remains = getWizardDefault(
            memberAge,
            WIZARD_DEFAULTS[currentSet].assetsDebt.DEBT_REMAINS_DEFAULT.age,
            WIZARD_DEFAULTS[currentSet].assetsDebt.DEBT_REMAINS_DEFAULT.amount
          );
        }

        fillSimpleDefault(
          defaultState,
          "assetsDebt",
          "interestDebt",
          "INTEREST_DEBT_DEFAULT"
        );

        fillSimpleDefault(
          defaultState,
          "assetsDebt",
          "otherDebt",
          "OTHER_DEBT_DEFAULT"
        );
      }
    }

    return defaultState;
  };

  handleGetExistsAssets = () => {
    const { assetsAll, transactionsAll } = this.props;
    const assets = { homeInvestment: [], homeIncomeRent: [] };

    if (assetsAll) {
      Object.keys(assetsAll).forEach(assetKey => {
        const asset = assetsAll[assetKey];

        // Use assets
        if (asset.globalType === typeUse) {
          // PRIMARY RESIDENCE
          if (
            asset.entity === useEntities.ENTITY_HOME &&
            asset.type === optionsValuesHome.PRIMARY
          ) {
            assets.primaryHome = asset;
          }

          // Multiple home investments rents
          if (
            asset.entity === useEntities.ENTITY_HOME &&
            asset.type === optionsValuesHome.INVESTMENT
          ) {
            // assets.homeInvestment = asset;
            assets.homeInvestment.push(asset);
          }

          // Multiple home investments rents
          if (
            asset.entity === useEntities.ENTITY_HOME &&
            asset.type === optionsValuesHome.MULTIPLE
          ) {
            assets.useGeneral = asset;
          }
        }

        // Savings
        if (asset.globalType === typeSavings) {
          if (asset.entity === entitiesSavings.ENTITY_GENERAL) {
            if (asset.isRetirement) {
              assets.retirementGeneral = asset;
            }

            if (asset.isSavings) {
              assets.savingsGeneral = asset;
            }
          }

          if (asset.entity === entitiesSavings.ENTITY_LIFE_INSURANCE) {
            assets.lifeInsurance = asset;
          }
        }

        if (asset.globalType === typeInvested) {
          if (asset.entity === entitiesInvested.ENTITY_STOCKS) {
            assets.investments = asset;
          }
        }

        if (asset.globalType === typeAccounts) {
          if (asset.entity === entitiesAccounts.ENTITY_SAVINGS) {
            assets.accountSavings = asset;
          }
        }

        if (asset.globalType === typeDebt) {
          if (asset.entity === entitiesDebt.ENTITY_HOME) {
            assets.debtMortgage = asset;
          }

          if (asset.entity === entitiesDebt.ENTITY_GENERAL) {
            assets.debtGeneral = asset;
          }
        }

        // rents from Multiple home investments
        if (asset.globalType === typeMoneyIn) {
          if (asset.entity === entitiesMoneyIn.ENTITY_RENT) {
            // assets.homeIncomeRent = asset;
            assets.homeIncomeRent.push(asset);
          }

          if (asset.entity === entitiesMoneyIn.ENTITY_SALARIES) {
            if (!assets.salary) {
              assets.salary = {};
            }
            assets.salary[asset.employeeName] = asset;
          }
        }

        if (asset.globalType === typeDebt) {
          if (asset.entity === entitiesDebt.ENTITY_HOME) {
            assets.mortgage = asset;
          }

          if (asset.entity === entitiesDebt.ENTITY_GENERAL) {
            assets.interestDebt = asset;
          }
          if (asset.entity === entitiesDebt.ENTITY_OTHER) {
            assets.otherDebt = asset;
          }
        }
      });
    }

    if (transactionsAll) {
      Object.keys(transactionsAll).forEach(assetKey => {
        const asset = transactionsAll[assetKey];

        // rents from Primary home
        if (
          asset.globalType === typeOutOther &&
          asset.entity === entitiesOutOther.ENTITY_HOUSE_RENT
        ) {
          assets.homePrimaryRent = asset;
        }

        if (asset.globalType === typeOutAssets) {
          if (asset.entity === entitiesOutAssets.ENTITY_OTHER) {
            assets.maintenance = asset;
          }

          // if (asset.entity === entitiesOutAssets.ENTITY_UTILITIES) {
          //   assets.utilities = asset;
          // }
        }

        if (asset.globalType === typeOutFamily) {
          if (
            asset.entity === entitiesOutFamily.ENTITY_LEISURE &&
            asset.isVacation
          ) {
            assets.vacations = asset;
          }

          if (
            asset.entity === entitiesOutFamily.ENTITY_LEISURE &&
            asset.isDining
          ) {
            assets.diningOut = asset;
          }

          if (asset.entity === entitiesOutFamily.ENTITY_NECESSITIES) {
            assets.groceries = asset;
          }

          if (
            asset.entity === entitiesOutFamily.ENTITY_OTHER &&
            asset.isDependent
          ) {
            assets.dependent = asset;
          }

          if (
            asset.entity === entitiesOutFamily.ENTITY_OTHER &&
            asset.isOtherBills
          ) {
            assets.otherBills = asset;
          }

          if (
            asset.entity === entitiesOutFamily.ENTITY_OTHER &&
            asset.isTransport
          ) {
            assets.transport = asset;
          }

          if (asset.entity === entitiesOutFamily.ENTITY_MEDICAL) {
            assets.medical = asset;
          }

          if (asset.entity === entitiesOutFamily.ENTITY_INSURANCE) {
            assets.insurance = asset;
          }
        }
      });
    }

    return assets;
  };

  handleDialogFinishNotLastAgree = () => {
    if (this.state.funcIfAccept) {
      this.state.funcIfAccept();
    }

    this.setState({ isDialogNotLastStepFinish: false });
  };

  handleDialogFinishNotLast = funcIfAccept => {
    this.setState({
      isDialogNotLastStepFinish: true,
      funcIfAccept: funcIfAccept
    });
  };

  handleSetNotFirstTime = () => {
    const { firebase, profile } = this.props;

    if (profile && profile.isFirstTime && firebase) {
      firebase.updateProfile({ isFirstTime: false });
    }
  };

  handleDialogFinishNotLastClose = () => {
    this.setState({
      isDialogNotLastStepFinish: false,
      funcIfAccept: null
    });

    this.handleSetNotFirstTime();
  };

  handleFinishAndSaveAssets = ({ isAgree } = {}) => {
    const stepIndex = stepArray.indexOf(this.state.step);

    const { firestore, auth, setUI } = this.props;
    const userUid = auth.uid;

    const assets = this.handleGetExistsAssets();

    const batch = firestore.batch();

    this.handleSaveMembers(batch, userUid);

    if (stepIndex <= 4 && !isAgree) {
      return this.handleDialogFinishNotLast(() =>
        this.handleFinishAndSaveAssets({ isAgree: true })
      );
    }

    let docRefPrimaryHomeLocal;
    const { docRefPrimaryHome } = this.handleSaveMoney(batch, userUid, assets);
    docRefPrimaryHomeLocal = docRefPrimaryHome;

    this.handleSaveApproximation(batch, userUid, assets);

    this.handleSaveAssets(batch, userUid, assets);

    this.handleSaveAssetsDebt(batch, userUid, assets, {
      docRefPrimaryHome: docRefPrimaryHomeLocal
    });

    let docRefUser = firestore.collection("users").doc(userUid);

    batch.update(docRefUser, {
      isWizardFinished: false,
      isFirstTime: false,
      interests: this.state.needs
    });

    batch.commit().then(() => {
      this.setState({
        step: "family"
      });

      this.handleOnClose();

      setUI(
        {
          profileProvider: auth.uid
        },
        { email: auth.email }
      );

      this.props.history.push(ROUTES.DASHBOARD);
    });
  };

  handleSaveMembers = (batch, userUid) => {
    const { intl } = this.props;

    const { firestore, childs, family: familyProp } = this.props;

    const familyState = this.state.family;
    const wizardTemplate = this.state.wizardTemplate;

    const birthday = subYears(Date.now(), familyState.age);
    birthday.setDate(1);
    birthday.setMonth(0);
    const filling = familyState.taxesStatus;

    const spouseObject = {};
    if (filling === "married") {
      const birthdaySpouse = subYears(Date.now(), +familyState.age + 2);

      birthdaySpouse.setDate(1);
      birthdaySpouse.setMonth(0);

      spouseObject.spouse = {
        spouseName: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.SPOUSE",
          defaultMessage: "My spouse"
        }),
        birthday: birthdaySpouse || null,
        employedStatus: familyState.spouseStatus || ""
      };
    }

    const docRef = firestore.collection("users").doc(userUid);

    const displayName =
      familyProp && familyProp.lastName && familyProp.firstName
        ? `${familyProp.firstName} ${familyProp.lastName}`
        : intl.formatMessage({
            id: "WIZARD.DEFAULT_DSC.ME",
            defaultMessage: "Me"
          });

    batch.update(docRef, {
      displayName: displayName,
      birthday: birthday,
      zipCode: familyState.zipCode || "",
      employedStatus: familyState.employmentStatus,
      relationshipStatus: filling,
      wizardTemplate: wizardTemplate,
      ...spouseObject
    });

    // familyState.dependents
    if (+familyState.dependents) {
      const existsChildrenKeys = childs ? Object.keys(childs) : [];
      const isMore28 = familyState.age > 28;
      let childAge = familyState.age % 28;
      const today = new Date();

      const birthday = subYears(Date.now(), childAge);
      birthday.setDate(1);
      birthday.setMonth(0);
      let additionYears = 0;

      let i = 1;
      for (i; i <= familyState.dependents; i++) {
        let existsChild = existsChildrenKeys[i - 1];
        let docRefChild;
        if (existsChild) {
          docRefChild = firestore
            .collection("users")
            .doc(userUid)
            .collection("childs")
            .doc(existsChild);
        } else {
          docRefChild = firestore
            .collection("users")
            .doc(userUid)
            .collection("childs")
            .doc();
        }

        // if too young birthday is today
        let childBirthday = addYears(birthday, additionYears);
        if (childBirthday > today || !isMore28) {
          childBirthday = today;
        }

        batch[existsChild ? "update" : "set"](docRefChild, {
          childName: `Child ${i}`,
          birthday: childBirthday
        });

        additionYears += 2;
      }

      if (existsChildrenKeys[i - 1]) {
        for (i; i <= existsChildrenKeys.length; i++) {
          const existsChild = existsChildrenKeys[i - 1];

          const docRefChild = firestore
            .collection("users")
            .doc(userUid)
            .collection("childs")
            .doc(existsChild);

          batch.delete(docRefChild);
        }
      }
    }
  };

  handleSaveMoney = (batch, useUid, assets) => {
    const { intl } = this.props;
    const { firestore } = this.props;

    const familyState = this.state.family;
    const moneyState = this.state.money;
    let currentSet = this.state.wizardTemplate || "default";

    if (!WIZARD_DEFAULTS[currentSet]) {
      currentSet = "default";
    }
    const newDate = new Date();

    let docRefPrimaryHome;

    //////  Salary
    if (+moneyState.totalSalary) {
      let spouseSalary = 0;
      let userSalary = 0;

      const currentSalary = +moneyState.totalSalary
        ? +moneyState.totalSalary
        : familyState.taxesStatus === "married"
        ? WIZARD_DEFAULTS[currentSet].money.SALARY_DEFAULT_MARRIED
        : WIZARD_DEFAULTS[currentSet].money.SALARY_DEFAULT_SINGLE;

      if (
        familyState.taxesStatus === "married" &&
        familyState.spouseStatus !== optionsEmployedStatusValues.UNEMPLOYED
      ) {
        if (
          familyState.employmentStatus ===
          optionsEmployedStatusValues.UNEMPLOYED
        ) {
          spouseSalary = currentSalary;
          userSalary = 0;
        } else {
          if (
            (familyState.employmentStatus ===
              optionsEmployedStatusValues.SELF_EMPLOYED &&
              familyState.spouseStatus !==
                optionsEmployedStatusValues.SELF_EMPLOYED) ||
            (familyState.employmentStatus !==
              optionsEmployedStatusValues.SELF_EMPLOYED &&
              familyState.spouseStatus ===
                optionsEmployedStatusValues.SELF_EMPLOYED)
          ) {
            if (
              familyState.employmentStatus ===
              optionsEmployedStatusValues.SELF_EMPLOYED
            ) {
              spouseSalary = currentSalary * 0.67;
              userSalary = currentSalary * 0.33;
            } else {
              spouseSalary = currentSalary * 0.33;
              userSalary = currentSalary * 0.67;
            }
          } else {
            spouseSalary = currentSalary / 2;
            userSalary = currentSalary / 2;
          }
        }
      } else {
        userSalary = currentSalary;
      }

      let docRefSalary;
      let docRefSalarySpouse;

      if (assets.salary) {
        if (assets.salary && assets.salary.You) {
          docRefSalary = firestore
            .collection(collectionMoneyIn)
            .doc(assets.salary.You.id);
        } else {
          docRefSalary = firestore.collection(collectionMoneyIn).doc();
        }

        if (assets.salary && assets.salary.Spouse) {
          docRefSalarySpouse = firestore
            .collection(collectionMoneyIn)
            .doc(assets.salary.Spouse.id || null);
        } else {
          docRefSalarySpouse = firestore.collection(collectionMoneyIn).doc();
        }
      } else {
        docRefSalary = firestore.collection(collectionMoneyIn).doc();
        docRefSalarySpouse = firestore.collection(collectionMoneyIn).doc();
      }

      const configurationSalary = setDefaultAnnualChange(
        typeMoneyIn,
        entitiesMoneyIn.ENTITY_SALARIES
      );

      if (userSalary) {
        batch[assets && assets.salary && assets.salary.You ? "update" : "set"](
          docRefSalary,
          {
            entity: entitiesMoneyIn.ENTITY_SALARIES,
            globalType: typeMoneyIn,
            entityType: typeCollMoneyIn,
            user: useUid,
            isWizard: true,

            description: intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.SALARY",
              defaultMessage: "My estimated salary"
            }),
            employeeName: "You",
            employerName: intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.EMPLOYER",
              defaultMessage: "My employer"
            }),
            baseSalary: userSalary,
            otherCompensation: 0,
            variable: 0,
            anticipatedEndAge: 67,
            ...configurationSalary
          }
        );
      } else {
        if (assets.salary && assets.salary.You) {
          batch.delete(docRefSalary);
        }
      }

      if (familyState.taxesStatus === "married" && spouseSalary) {
        batch[
          assets && assets.salary && assets.salary.Spouse ? "update" : "set"
        ](docRefSalarySpouse, {
          entity: entitiesMoneyIn.ENTITY_SALARIES,
          globalType: typeMoneyIn,
          entityType: typeCollMoneyIn,
          user: useUid,
          isWizard: true,

          description: intl.formatMessage({
            id: "WIZARD.DEFAULT_DSC.SALARY_SPOUSE",
            defaultMessage: "Spouse estimated salary"
          }),
          employeeName: "Spouse",
          employerName: intl.formatMessage({
            id: "WIZARD.DEFAULT_DSC.EMPLOYER_SPOUSE",
            defaultMessage: "Spouse employer"
          }),
          baseSalary: spouseSalary,
          otherCompensation: 0,
          variable: 0,
          anticipatedEndAge: 67,
          ...configurationSalary
        });
      } else {
        if (assets.salary && assets.salary.Spouse) {
          batch.delete(docRefSalarySpouse);
        }
      }
    }
    ////// SALARY END

    //////  Rents Income
    if (true || +moneyState.collectingRents) {
      const currentCollectionRents =
        +moneyState.collectingRents ||
        WIZARD_DEFAULTS[currentSet].money.RENTS_DEFAULT;

      // count
      const count = Math.ceil(currentCollectionRents / INVESTMENT_RENT_LIMIT);
      let lastPrice = currentCollectionRents % INVESTMENT_RENT_LIMIT;
      if (lastPrice === 0) {
        lastPrice = INVESTMENT_RENT_LIMIT;
      }

      // home price
      let priceInvestmentHome = 0;
      const primaryHomeValue =
        moneyState.primaryResidence === "own"
          ? (+moneyState.propertyTax ||
              WIZARD_DEFAULTS[currentSet].money.PROPERTY_TAX_DEFAULT) /
            taxPropertyValue
          : 0;

      if (+this.state.assets.homesVehicles > primaryHomeValue) {
        priceInvestmentHome = roundToThousand(
          (this.state.assets.homesVehicles - primaryHomeValue) / count
        );
      }

      let configurationHomeMult = setDefaultAnnualChange(
        typeUse,
        useEntities.ENTITY_HOME
      );
      let configurationRentIncome = setDefaultAnnualChange(
        typeMoneyIn,
        entitiesMoneyIn.ENTITY_RENT
      );

      configurationHomeMult.taxType = optionsValuesHomeTaxType.INTEREST;
      configurationHomeMult.taxRate = defaultHomeTaxRate;
      configurationRentIncome.taxType = optionsValuesHomeTaxType.INTEREST;
      configurationRentIncome.taxRate = defaultHomeTaxRate;

      let i = 0;

      for (i; i < count; i++) {
        const existingHomeInvestment =
          assets.homeInvestment && assets.homeInvestment[i];

        let docRefHomeMultiple = existingHomeInvestment
          ? firestore.collection(collectionUse).doc(existingHomeInvestment.id)
          : firestore.collection(collectionUse).doc();

        batch[existingHomeInvestment ? "update" : "set"](docRefHomeMultiple, {
          entity: useEntities.ENTITY_HOME,
          globalType: typeUse,
          user: useUid,
          isWizard: true,

          description:
            intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.RENTED_HOME",
              defaultMessage: "My rented investment home"
            }) + ` ${i + 1}`,
          type: optionsValuesHome.INVESTMENT,
          purchasePrice: priceInvestmentHome,
          purchaseDate: newDate,
          value: priceInvestmentHome,
          ...configurationHomeMult
        });

        const existingIncomeRent =
          assets.homeIncomeRent && assets.homeIncomeRent[i];

        let docRefHomeIncomeRent = existingIncomeRent
          ? firestore.collection(collectionMoneyIn).doc(existingIncomeRent.id)
          : firestore.collection(collectionMoneyIn).doc();

        const AVERAGE_VACANCY = 30;

        batch[existingIncomeRent ? "update" : "set"](docRefHomeIncomeRent, {
          entity: entitiesMoneyIn.ENTITY_RENT,
          globalType: typeMoneyIn,
          entityType: typeCollMoneyIn,
          user: useUid,
          isWizard: true,

          description:
            intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.RENT_FROM_HOME",
              defaultMessage: "Rent from investment homes"
            }) + ` ${i + 1}`,
          asset: docRefHomeMultiple.id,
          monthlyRent:
            (i + 1 === count ? lastPrice : INVESTMENT_RENT_LIMIT) /
            (12 - (AVERAGE_VACANCY * 12) / 365),
          averageVacancy: AVERAGE_VACANCY,
          ...configurationRentIncome
        });
      }

      if (assets.homeIncomeRent[i]) {
        for (i; i <= assets.homeIncomeRent.length; i++) {
          const existsHomeInvestment = assets.homeInvestment[i];
          const existsIncomeRent = assets.homeIncomeRent[i];

          if (existsHomeInvestment && existsHomeInvestment.id) {
            const docRef = firestore
              .collection(collectionUse)
              .doc(existsHomeInvestment.id);

            batch.delete(docRef);
          }

          if (existsIncomeRent && existsIncomeRent.id) {
            const docRef = firestore
              .collection(collectionMoneyIn)
              .doc(existsIncomeRent.id);

            batch.delete(docRef);
          }
        }
      }
    }

    //////  Residence add Rent or Primary
    if (moneyState.primaryResidence === "own") {
      if (assets.homePrimaryRent) {
        const docRefPrimaryHomeRent = firestore
          .collection(collectionUse)
          .doc(assets.homePrimaryRent.id);

        batch.delete(docRefPrimaryHomeRent);
      }

      if (+moneyState.propertyTax) {
        docRefPrimaryHome = assets.primaryHome
          ? firestore.collection(collectionUse).doc(assets.primaryHome.id)
          : firestore.collection(collectionUse).doc();

        const price = roundToThousand(
          (+moneyState.propertyTax ||
            WIZARD_DEFAULTS[currentSet].money.PROPERTY_TAX_DEFAULT) /
            taxPropertyValue
        );

        const NewDate = new Date();

        const configurationPrimaryHome = setDefaultAnnualChange(
          typeUse,
          useEntities.ENTITY_HOME
        );

        batch[assets.primaryHome ? "update" : "set"](docRefPrimaryHome, {
          entity: useEntities.ENTITY_HOME,
          globalType: typeUse,
          user: useUid,
          isWizard: true,

          description: intl.formatMessage({
            id: "WIZARD.DEFAULT_DSC.PRIMARY_RESIDENCE",
            defaultMessage: "My primary residence"
          }),
          type: optionsValuesHome.PRIMARY,
          purchasePrice: price,
          value: price,
          purchaseDate: NewDate,
          ...configurationPrimaryHome
        });
      }
    } else {
      if (assets.primaryHome) {
        const docRefPrimaryHome = firestore
          .collection(collectionUse)
          .doc(assets.primaryHome.id);

        batch.delete(docRefPrimaryHome);
      }

      if (moneyState.monthlyRent) {
        let docRefHomePrimaryRent = assets.homePrimaryRent
          ? firestore
              .collection(collectionOutOther)
              .doc(assets.homePrimaryRent.id)
          : firestore.collection(collectionOutOther).doc();

        const configurationPrimaryRent = setDefaultAnnualChange(
          typeOutOther,
          entitiesOutOther.ENTITY_HOUSE_RENT
        );

        batch[assets.homePrimaryRent ? "update" : "set"](
          docRefHomePrimaryRent,
          {
            entity: entitiesOutOther.ENTITY_HOUSE_RENT,
            globalType: typeOutOther,
            entityType: typeCollOutOther,
            user: useUid,
            isWizard: true,

            description: intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.PRIMARY_RESIDENCE_RENT",
              defaultMessage: "Payment for primary residence rent"
            }),
            annualCost: moneyState.monthlyRent * 12,
            ...configurationPrimaryRent
          }
        );
      }
    }

    //////  HOA
    if (+moneyState.maintenance) {
      let docRefMaintenance = assets.maintenance
        ? firestore.collection(collectionOutOther).doc(assets.maintenance.id)
        : firestore.collection(collectionOutOther).doc();

      const configurationMaintenance = setDefaultAnnualChange(
        typeOutAssets,
        entitiesOutAssets.ENTITY_OTHER
      );

      batch[assets.maintenance ? "update" : "set"](docRefMaintenance, {
        entity: entitiesOutAssets.ENTITY_OTHER,
        globalType: typeOutAssets,
        entityType: typeCollOutAssets,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.MAINTENANCE",
          defaultMessage: "All property operations expenses"
        }),
        annualCost: moneyState.maintenance,
        ...configurationMaintenance
      });
    }

    //////  Vacations
    if (+moneyState.vacation) {
      let docRefVacations = assets.vacations
        ? firestore.collection(collectionOutOther).doc(assets.vacations.id)
        : firestore.collection(collectionOutOther).doc();

      const configurationVacations = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_LEISURE
      );

      batch[assets.vacations ? "update" : "set"](docRefVacations, {
        entity: entitiesOutFamily.ENTITY_LEISURE,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.VACATIONS",
          defaultMessage: "Family Vacations"
        }),
        annualCost: moneyState.vacation,
        isVacation: true,
        ...configurationVacations
      });
    }

    return { docRefPrimaryHome };
  };

  handleSaveApproximation = (batch, useUid, assets) => {
    const { intl } = this.props;
    const { firestore } = this.props;

    const approximationState = this.state.approximation;

    /// groceries
    if (+approximationState.groceries) {
      let docRefGroceries = assets.groceries
        ? firestore.collection(collectionOutFamily).doc(assets.groceries.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationGroceries = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_NECESSITIES
      );

      batch[assets.groceries ? "update" : "set"](docRefGroceries, {
        entity: entitiesOutFamily.ENTITY_NECESSITIES,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.GROCERIES",
          defaultMessage: "Family Groceries"
        }),
        annualCost: approximationState.groceries * 12,
        ...configurationGroceries
      });
    }

    /// diningOut
    if (+approximationState.diningOut) {
      let docRefDiningOut = assets.diningOut
        ? firestore.collection(collectionOutFamily).doc(assets.diningOut.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationDiningOut = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_LEISURE
      );

      batch[assets.diningOut ? "update" : "set"](docRefDiningOut, {
        entity: entitiesOutFamily.ENTITY_LEISURE,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.DINING",
          defaultMessage: "Family Dining Out"
        }),
        annualCost: approximationState.diningOut * 12,
        isDining: true,
        ...configurationDiningOut
      });
    }
    /// dependent
    if (+approximationState.dependentCare) {
      let docRefDependent = assets.dependent
        ? firestore.collection(collectionOutFamily).doc(assets.dependent.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationDependent = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_OTHER
      );

      batch[assets.dependent ? "update" : "set"](docRefDependent, {
        entity: entitiesOutFamily.ENTITY_OTHER,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.DEPENDENT",
          defaultMessage: "Dependent care"
        }),
        annualCost: approximationState.dependentCare * 12,
        isDependent: true,
        ...configurationDependent
      });
    }
    /// medical
    if (+approximationState.medicalAndHealth) {
      let docRefMedical = assets.medical
        ? firestore.collection(collectionOutFamily).doc(assets.medical.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationMedical = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_MEDICAL
      );

      batch[assets.medical ? "update" : "set"](docRefMedical, {
        entity: entitiesOutFamily.ENTITY_MEDICAL,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.MEDICAL",
          defaultMessage: "Family medical"
        }),
        annualCost: approximationState.medicalAndHealth * 12,
        ...configurationMedical
      });
    }
    /// transport
    if (+approximationState.transportation) {
      let docRefTransportation = assets.transport
        ? firestore.collection(collectionOutFamily).doc(assets.transport.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationTransport = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_OTHER
      );

      batch[assets.transport ? "update" : "set"](docRefTransportation, {
        entity: entitiesOutFamily.ENTITY_OTHER,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.TRANSPORTATION",
          defaultMessage: "Family transportation"
        }),
        annualCost: approximationState.transportation * 12,
        isTransport: true,
        ...configurationTransport
      });
    }
    /// insurance
    if (+approximationState.insurance) {
      let docRefInsurance = assets.insurance
        ? firestore.collection(collectionOutFamily).doc(assets.insurance.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationInsurance = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_INSURANCE
      );

      batch[assets.insurance ? "update" : "set"](docRefInsurance, {
        entity: entitiesOutFamily.ENTITY_INSURANCE,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.LIFE_INSURANCE",
          defaultMessage: "Family various insurance"
        }),
        annualCost: approximationState.insurance * 12,
        ...configurationInsurance
      });
    }

    /// otherBills
    if (+approximationState.other) {
      let docRefOtherBills = assets.otherBills
        ? firestore.collection(collectionOutFamily).doc(assets.otherBills.id)
        : firestore.collection(collectionOutFamily).doc();

      const configurationOtherBills = setDefaultAnnualChange(
        typeOutFamily,
        entitiesOutFamily.ENTITY_OTHER
      );

      batch[assets.otherBills ? "update" : "set"](docRefOtherBills, {
        entity: entitiesOutFamily.ENTITY_OTHER,
        globalType: typeOutFamily,
        entityType: typeCollOutFamily,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.OTHER_FAMILY_EXPENSES",
          defaultMessage: "Other family expenses"
        }),
        annualCost: approximationState.other * 12,
        isOtherBills: true,
        ...configurationOtherBills
      });
    }
  };

  handleSaveAssets = (batch, useUid, assets) => {
    const { intl } = this.props;
    const { firestore } = this.props;

    let currentSet = this.state.wizardTemplate || "default";

    if (!WIZARD_DEFAULTS[currentSet]) {
      currentSet = "default";
    }

    const assetsState = this.state.assets;
    const moneyState = this.state.money;

    /// Use General
    if (+assetsState.homesVehicles) {
      if (moneyState.collectingRents) {
        if (assets.useGeneral) {
          const docRef = firestore
            .collection(collectionUse)
            .doc(assets.useGeneral.id);

          batch.delete(docRef);
        }
      } else {
        const primaryHomeValue = this.state.money.propertyTax
          ? this.state.money.propertyTax / taxPropertyValue
          : 0;

        if (+assetsState.homesVehicles > primaryHomeValue) {
          let docRefHomesVehicles = assets.useGeneral
            ? firestore.collection(collectionUse).doc(assets.useGeneral.id)
            : firestore.collection(collectionUse).doc();

          const configurationUseGeneral = setDefaultAnnualChange(
            typeUse,
            useEntities.ENTITY_HOME
          );
          configurationUseGeneral.taxType = optionsValuesHomeTaxType.INTEREST;
          configurationUseGeneral.taxRate = defaultHomeTaxRate;

          const price =
            +assetsState.homesVehicles - primaryHomeValue ||
            WIZARD_DEFAULTS[currentSet].assets.HOMES_VEHICLES_DEFAULT;

          const NewDate = new Date();

          batch[assets.useGeneral ? "update" : "set"](docRefHomesVehicles, {
            entity: useEntities.ENTITY_HOME,
            globalType: typeUse,
            user: useUid,
            isWizard: true,

            description: intl.formatMessage({
              id: "WIZARD.DEFAULT_DSC.MULTIPLE_HOMES",
              defaultMessage: "Homes"
            }),

            type: optionsValuesHome.MULTIPLE,
            purchasePrice: price,
            value: price,
            purchaseDate: NewDate,

            ...configurationUseGeneral
          });
        }
      }
    }

    /// Savings & Retirement: RetirementGeneral
    if (+assetsState.retirementFunds) {
      let docRefRetirementGeneral = assets.retirementGeneral
        ? firestore
            .collection(collectionSavings)
            .doc(assets.retirementGeneral.id)
        : firestore.collection(collectionSavings).doc();

      const configurationRetirementGeneral = setDefaultAnnualChange(
        typeSavings,
        entitiesSavings.ENTITY_GENERAL
      );

      batch[assets.retirementGeneral ? "update" : "set"](
        docRefRetirementGeneral,
        {
          entity: entitiesSavings.ENTITY_GENERAL,
          globalType: typeSavings,
          user: useUid,
          isWizard: true,

          description: intl.formatMessage({
            id: "WIZARD.DEFAULT_DSC.RETIREMENT_FUNDS",
            defaultMessage: "Various retirement funds"
          }),
          taxCategory: optionsTaxCategoryValues.ORDINARY_INCOME,
          value: assetsState.retirementFunds,
          isRetirement: true,
          ...configurationRetirementGeneral
        }
      );
    }
    /// Savings & Retirement: SavingsGeneral
    if (+assetsState.purposelySaved) {
      let docRefSavingsGeneral = assets.savingsGeneral
        ? firestore.collection(collectionSavings).doc(assets.savingsGeneral.id)
        : firestore.collection(collectionSavings).doc();

      const configurationSavingsGeneral = {
        configuration: {
          annualChangeType: valuesInflation.INFLATION,
          annualChange: INFLATION
        }
      };

      batch[assets.savingsGeneral ? "update" : "set"](docRefSavingsGeneral, {
        entity: entitiesSavings.ENTITY_GENERAL,
        globalType: typeSavings,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.SAVINGS_FUNDS",
          defaultMessage: "My Savings with a purpose"
        }),
        taxCategory: optionsTaxCategoryValues.ORDINARY_INCOME,
        value: assetsState.purposelySaved,
        isSavings: true,

        ...configurationSavingsGeneral
      });
    }
    /// Investments
    if (+assetsState.invested) {
      let docRefInvestments = assets.investments
        ? firestore.collection(collectionInvested).doc(assets.investments.id)
        : firestore.collection(collectionInvested).doc();

      const configurationInvestments = setDefaultAnnualChange(
        typeInvested,
        entitiesInvested.ENTITY_STOCKS
      );

      batch[assets.investments ? "update" : "set"](docRefInvestments, {
        entity: entitiesInvested.ENTITY_STOCKS,
        globalType: typeInvested,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.MY_STOCKS",
          defaultMessage: "My Stocks portfolio"
        }),
        value: assetsState.invested,
        purchaseValue: assetsState.invested,
        ...configurationInvestments
      });
    }
    /// Life Insurance
    if (+assetsState.deathBenefit) {
      let docRefLifeInsurance = assets.lifeInsurance
        ? firestore.collection(collectionSavings).doc(assets.lifeInsurance.id)
        : firestore.collection(collectionSavings).doc();

      const NewDate = new Date();

      const configurationLifeInsurance = setDefaultAnnualChange(
        typeSavings,
        entitiesSavings.ENTITY_LIFE_INSURANCE
      );

      batch[assets.lifeInsurance ? "update" : "set"](docRefLifeInsurance, {
        entity: entitiesSavings.ENTITY_LIFE_INSURANCE,
        globalType: typeSavings,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.SAVINGS_LIFE_INSURANCE",
          defaultMessage: "Unknown life insurance"
        }),
        owner: "You",
        insuranceDate: NewDate,
        type: optionsInsuranceTypeValue.TERM,
        deathBenefit: assetsState.deathBenefit,
        term: 10,
        ...configurationLifeInsurance
      });
    }
    /// ACCOUNT
    if (+assetsState.checkingOrSaving) {
      let docRefAccounts = assets.accountSavings
        ? firestore.collection(collectionAccounts).doc(assets.accountSavings.id)
        : firestore.collection(collectionAccounts).doc();

      batch[assets.accountSavings ? "update" : "set"](docRefAccounts, {
        entity: entitiesAccounts.ENTITY_SAVINGS,
        globalType: typeAccounts,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.ACCOUNT_SAVINGS",
          defaultMessage: "Checking and Savings"
        }),
        balance: assetsState.checkingOrSaving,
        annualChangeType: valuesInflation.CUSTOM,
        interestRate: "0"
      });
    }
  };

  handleSaveAssetsDebt = (
    batch,
    useUid,
    assets,
    { docRefPrimaryHome } = {}
  ) => {
    const { intl } = this.props;
    const { firestore } = this.props;

    let currentSet = this.state.wizardTemplate || "default";

    if (!WIZARD_DEFAULTS[currentSet]) {
      currentSet = "default";
    }

    const assetsState = this.state.assetsDebt;
    const NewDate = new Date();

    /// Use Mortgage
    if (+assetsState.remains) {
      let docRefMortgage = assets.mortgage
        ? firestore.collection(collectionUse).doc(assets.mortgage.id)
        : firestore.collection(collectionUse).doc();

      batch[assets.mortgage ? "update" : "set"](docRefMortgage, {
        entity: entitiesDebt.ENTITY_HOME,
        globalType: typeDebt,
        user: useUid,
        isWizard: true,

        assetId: docRefPrimaryHome ? docRefPrimaryHome.id : null,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.DEBT_MORTGAGE",
          defaultMessage: "My Mortgage"
        }),
        loanAmount: assetsState.remains,
        loanDate: NewDate,
        loanType: optionsRepaymentValues.MONTHLY_AMOUNT,
        monthlyPayment: assetsState.remains / 320
        // ...configurationMortgage
      });

      if (docRefPrimaryHome) {
        batch.update(docRefPrimaryHome, {
          debtId: docRefMortgage.id
        });
      }
    } else {
      if (assets.mortgage) {
        const docRef = firestore
          .collection(collectionUse)
          .doc(assets.mortgage.id);

        batch.delete(docRef);
      }
    }

    /// Use General Debt
    if (+assetsState.interestDebt) {
      let docRefMortgage = assets.interestDebt
        ? firestore.collection(collectionUse).doc(assets.interestDebt.id)
        : firestore.collection(collectionUse).doc();

      batch[assets.interestDebt ? "update" : "set"](docRefMortgage, {
        entity: entitiesDebt.ENTITY_GENERAL,
        globalType: typeDebt,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.DEBT_HIGH",
          defaultMessage: "High interest debt"
        }),
        loanAmount: assetsState.interestDebt,
        interestRate:
          WIZARD_DEFAULTS[currentSet].assetsDebt.INTEREST_DEBT_INTEREST_DEFAULT
      });
    } else {
      if (assets.interestDebt) {
        const docRef = firestore
          .collection(collectionUse)
          .doc(assets.interestDebt.id);

        batch.delete(docRef);
      }
    }

    /// Use OTHER Debt
    if (+assetsState.otherDebt) {
      let docRefMortgage = assets.otherDebt
        ? firestore.collection(collectionUse).doc(assets.otherDebt.id)
        : firestore.collection(collectionUse).doc();

      batch[assets.otherDebt ? "update" : "set"](docRefMortgage, {
        entity: entitiesDebt.ENTITY_OTHER,
        globalType: typeDebt,
        user: useUid,
        isWizard: true,

        description: intl.formatMessage({
          id: "WIZARD.DEFAULT_DSC.DEBT_OTHER",
          defaultMessage: "Student or other loan"
        }),
        loanAmount: assetsState.otherDebt,
        interestRate:
          WIZARD_DEFAULTS[currentSet].assetsDebt.INTEREST_DEBT_INTEREST_DEFAULT,

        loanDate: NewDate,
        loanType: optionsRepaymentValues.MONTHLY_AMOUNT,
        monthlyPayment: assetsState.remains / 120
      });
    } else {
      if (assets.otherDebt) {
        const docRef = firestore
          .collection(collectionUse)
          .doc(assets.otherDebt.id);

        batch.delete(docRef);
      }
    }
  };

  handleSaveStep = step => () => {
    const { wizard, auth, firestore } = this.props;

    const formState = this.state[step];

    const { isChanged, changedFields, wizardTemplate } = this.state;

    if (!isChanged[step]) {
      return;
    }

    const userUID = auth.uid;

    const newDate = new Date();

    const saveObject = {
      changedFields: changedFields,
      wizardTemplate: wizardTemplate
    };

    Object.keys(formState).forEach(fieldName => {
      saveObject[`${step}_${fieldName}`] = formState[fieldName] || "";
    });

    if (wizard && wizard.id) {
      firestore.update(`wizard/${wizard.id}`, {
        updated: new Date(),
        ...saveObject
      });
    } else {
      firestore.add(
        { collection: "wizard" },
        {
          created: newDate,
          updated: newDate,

          user: userUID,

          ...saveObject
        }
      );
    }

    this.setState(prevState => ({
      isChanged: {
        ...prevState.isChanged,
        [step]: false
      }
    }));
  };

  handleChangeStep = step => () => {
    if (stepArray.indexOf(step) !== -1) {
      this.setState({ step });
    }
  };

  handleDefaultTemplateChange = ev => {
    const { name, value } = ev.target;

    const newState = this.state;
    let changedFields = this.state.changedFields
      ? [...this.state.changedFields]
      : [];

    newState.wizardTemplate = value;
    const defaultState = this.updateDefaults(newState);
    let state = mergeLodash(newState, defaultState);

    state.isChanged.family = true;
    if (!~changedFields.indexOf("wizardTemplate")) {
      changedFields.push("wizardTemplate");
    }
    state.changedFields = changedFields;

    this.setState(state);
  };

  handleInputNeedsChange = (category, { name, checked }) => ev => {
    const newState = this.state;

    let changedFields = this.state.changedFields
      ? [...this.state.changedFields]
      : [];

    if (!~changedFields.indexOf(name)) {
      changedFields.push(name);
    }

    newState[category][name] = checked;

    newState.isChanged[category] = true;
    newState.changedFields = changedFields;

    this.setState(newState);
  };

  handleInputChange = (category, nameRange) => (ev, valueRange) => {
    const { name, value } = ev.target;

    const resName = name || nameRange;

    const target = ev.target;

    const newState = this.state;

    let changedFields = this.state.changedFields
      ? [...this.state.changedFields]
      : [];

    newState[category][resName] = value || valueRange;
    const defaultState = this.updateDefaults(newState, { currentField: name });
    let state = mergeLodash(defaultState, newState);

    if (!~changedFields.indexOf(resName)) {
      changedFields.push(resName);
    }

    state.isChanged[category] = true;
    state.changedFields = changedFields;

    this.setState(state);
  };

  handleOnClose = () => {
    const { onClose } = this.props;

    this.handleSaveStep(this.state.step)();
    onClose();
    this.handleSetNotFirstTime();
  };

  render() {
    const { classes, onClose, open, isMobile } = this.props;

    const finishNotLastStep = {
      text: (
        <FormattedMessage
          id="WIZARD.COMMON.WARNING.FINISH_NOT_LAST"
          defaultMessage="You clicked Finish before adjusting all numbers. If you would like to continue with default or previously set numbers, click Continue. Otherwise click Cancel and click Next until you completed all adjustments."
        />
      ),
      title: <FormattedMessage id="APP.WARNING" defaultMessage="Warning" />,
      okButton: <FormattedMessage id="APP.CONTINUE" defaultMessage="Continue" />
    };

    const currentStep = this.state.step;

    const CurrentItem = WizardSteps[currentStep];
    const formState = this.state[currentStep];

    const currentIndex = stepArray.indexOf(currentStep);
    const prevStep = stepArray[currentIndex - 1] || "";
    const nextStep = stepArray[currentIndex + 1] || "";

    return (
      <>
        <DialogCommon
          isOpen={this.state.isDialogNotLastStepFinish}
          title={finishNotLastStep.title}
          text={finishNotLastStep.text}
          okButton={finishNotLastStep.okButton}
          handleAgreeAndClose={this.handleDialogFinishNotLastAgree}
          handleClose={this.handleDialogFinishNotLastClose}
        />

        <Dialog
          onClose={this.handleOnClose}
          open={open}
          fullScreen={isMobile}
          fullWidth={true}
          maxWidth={"lg"}
          scroll="paper"
          className={classes.container}
          classes={{
            paperScrollPaper: currentStep
              ? classes.dialogWizardPaper
              : classes.dialogWizardPaperWelcome
          }}
        >
          <CurrentItem
            isMobile={isMobile}
            classes={classes}
            onClose={onClose}
            onCloseDialog={this.handleOnClose}
            formState={formState}
            wizardTemplate={this.state.wizardTemplate}
            fullState={this.state}
            currentStep={currentStep}
            prevStep={prevStep}
            nextStep={nextStep}
            handleSaveStep={this.handleSaveStep}
            handleChangeStep={this.handleChangeStep}
            handleInputChange={this.handleInputChange}
            handleInputNeedsChange={this.handleInputNeedsChange}
            handleDefaultTemplateChange={this.handleDefaultTemplateChange}
            handleFinishAndSaveAssets={this.handleFinishAndSaveAssets}
          />
        </Dialog>
      </>
    );
  }
}

function mapStateToProps({
  firebase: { auth, profile },
  firestore: { data, ordered, status },
  ui: { isMobile, profileProvider }
}) {
  const family = ordered && ordered.family && ordered.family[0];
  const wizard = ordered && ordered.wizard && ordered.wizard[0];

  const userUid = profileProvider || auth.uid;
  const childs =
    data &&
    data.users &&
    data.users[userUid] &&
    data.users[userUid].childsWizard;
  return {
    isMobile,
    auth,
    profile,
    profileProvider: userUid,
    family,
    wizard,
    childs,
    assetsAll: getAssetsAllWizardData(ordered),
    transactionsAll: getTransactionsAllDataWizard(ordered),
    firestoreStatus: status
  };
}
/////////////////////////////////////////////////////////////////////////////////////
export default compose(
  withFirebase,
  withFirestore,
  connect(
    mapStateToProps,
    { setUI }
  ),
  firestoreConnect(props => {
    const profileUid = props.auth.uid;

    if (!profileUid) {
      return [];
    }

    const queries = [
      {
        collection: "users",
        doc: profileUid,
        subcollections: [
          {
            collection: "childs",
            orderBy: ["childName", "asc"],
            storeAs: "childsWizard"
          }
        ]
      },
      {
        collection: "userAssets",
        where: [["user", "==", profileUid], ["isWizard", "==", true]],
        storeAs: "assetsAllWizard"
      },
      {
        collection: "transactions",
        where: [["user", "==", profileUid], ["isWizard", "==", true]],
        storeAs: "transactionsAllWizard"
      },
      {
        collection: "wizard",
        where: [["user", "==", profileUid]],
        storeAs: "wizard"
      }
    ];

    return queries;
  }),
  withRouter,
  injectIntl
)(withStyles(styles)(DialogWizard));

function setDefaultAnnualChange(globalType, entityKey) {
  let defaultAnnualChange;
  if (defaultChanges[globalType]) {
    if (defaultChanges[globalType][entityKey]) {
      defaultAnnualChange = defaultChanges[globalType][entityKey];
    } else {
      defaultAnnualChange = defaultChanges[globalType].default || 0;
    }
  }

  let defaultAnnualChangeType;
  if (defaultChangesType[globalType]) {
    if (defaultChangesType[globalType][entityKey]) {
      defaultAnnualChangeType = defaultChangesType[globalType][entityKey];
    } else {
      defaultAnnualChangeType = defaultChangesType[globalType].default || 0;
    }
  }

  if (defaultAnnualChange && defaultAnnualChangeType) {
    return {
      configuration: {
        annualChangeType: defaultAnnualChangeType,
        annualChange: defaultAnnualChange
      }
    };
  }

  return {};
}
