import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  "@global": {
    html: {
      height: "100%"
    },
    body: {
      height: "100%"
    },
    "#root": {
      height: "100%"
    }
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

const IsLoadingAuth = props => {
  const { classes } = props;

  return (
    <main className={classes.container}>
      <CssBaseline />

      <CircularProgress />
    </main>
  );
};

export default withStyles(styles)(IsLoadingAuth);
