import React, { Component } from "react";
import PropTypes from "prop-types";

import Dropdown, {
  DropdownContent,
  DropdownTrigger
} from "react-simple-dropdown";

import { FormattedMessage } from "react-intl-v3";

import ToolbarSearch from "components/_common/FormControl/ToolbarSearch";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { withStyles } from "@material-ui/core";

import "./MainToolbar.scss";

class MainToolbar extends Component {
  state = {
    anchorEl: null
  };

  handleClickDropDown = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseDropDown = handle => ev => {
    this.setState({ anchorEl: null });
    if (handle) {
      handle();
    }
  };

  _renderDropDownOld = () => {
    return (
      <Dropdown>
        <DropdownTrigger>
          Type{" "}
          <img
            className="dropdown__icon"
            src="/assets/img/icon/svg/select_arrow.svg"
            alt=""
          />
        </DropdownTrigger>

        <DropdownContent>
          <ul>
            <li>
              <button>Type</button>
            </li>
            <li>
              <button>Cost</button>
            </li>
          </ul>
        </DropdownContent>
      </Dropdown>
    );
  };

  render() {
    const { title, titleI18n, classes, isHideSort } = this.props;
    const { anchorEl } = this.state;

    // TODO Search waiting design

    const sortBy = (
      <>
        {" "}
        <IconButton
          className={classes.button}
          aria-label="More"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClickDropDown}
        >
          Type{" "}
          <img
            className="dropdown__icon"
            src="/assets/img/icon/svg/select_arrow.svg"
            alt=""
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseDropDown()}
        >
          <MenuItem onClick={() => {}}>Type</MenuItem>
          <MenuItem onClick={() => {}}>Value</MenuItem>
        </Menu>
      </>
    );

    return (
      <section className="main-toolbar">
        <div className="main-toolbar__title">
          {!!title &&
            !!titleI18n && (
              <FormattedMessage id={titleI18n} defaultMessage={title} />
            )}
        </div>

        {this.props.rightPanel && (
          <div className="main-toolbar__tools">
            {!isHideSort && (
              <div className="main-toolbar__sort-by">
                <span className="main-toolbar__sort-by-label">Sort by:</span>
                {sortBy}
              </div>
            )}

            <div className="s">
              <ToolbarSearch />
            </div>
          </div>
        )}
      </section>
    );
  }
}

MainToolbar.propTypes = {
  title: PropTypes.string,
  titleI18n: PropTypes.string,
  rightPanel: PropTypes.bool,
  isHideSort: PropTypes.bool
};

const styles = theme => ({
  button: {
    padding: "6px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "21px"
  }
});

export default withStyles(styles)(MainToolbar);
