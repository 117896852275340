import React, { Component } from "react";
import PropTypes from "prop-types";

import IconContainer from "../_common/IconContainer";
import * as Icons from "components/_common/icons";
import { FormattedMessage } from "react-intl-v3";
import { formatMoneyInt } from "../../lib/helpers";
import ReactResizeDetector from "react-resize-detector";

const graphAssetTaxCat = {
  taxable: { color: "orange" },
  taxDeferred: { color: "purple" },
  taxAdvantage: { color: "green" },

  homeUse: { color: "green" },
  otherUse: { color: "purple" }
};

class Statistic extends Component {
  static propTypes = {
    header: PropTypes.string,
    headerI18n: PropTypes.string,
    transKey: PropTypes.string,
    icon: PropTypes.any,
    iconColor: PropTypes.any,
    data: PropTypes.object,
    isShowGraph: PropTypes.bool,
    isWhatIf: PropTypes.bool,
    isDetailed: PropTypes.bool,
    isTaxes: PropTypes.bool,
    headersHeight: PropTypes.object,
    subCategoryState: PropTypes.object,
    handleSelectStatCategory: PropTypes.func,
    handleResetStatCategory: PropTypes.func,
    reactResizeHeader: PropTypes.func
  };

  updateCardsDimensions = (widht, height) => {
    this.props.reactResizeHeader(height + 17);
  };

  render() {
    const {
      header,
      headerI18n,
      icon,
      iconColor,
      data,
      transKey,
      isShowGraph,
      isWhatIf,
      subCategoryState,
      handleSelectStatCategory,
      handleResetStatCategory,
      isDetailed,
      isTaxes,
      reactResizeHeader,
      maxHeight
    } = this.props;

    const Icon = Icons[icon] || Icons.IconOther;

    const { all: allDefault, ...restDefault } = data.default;

    return (
      <div
        className={`statistic__item ${
          !!handleResetStatCategory ? "statistic__item--low" : ""
        }`}
      >
        <div
          className={`statistic__item-header statistic__item-header-icon ${
            isTaxes ? "statistic__item-header-border" : ""
          } ${!isDetailed && !isTaxes ? "statistic__item-header-short" : ""}
              `}
          style={maxHeight && maxHeight > 54 ? { height: maxHeight } : {}}
        >
          {reactResizeHeader && (
            <ReactResizeDetector
              handleHeight
              handleWidth
              onResize={this.updateCardsDimensions}
            />
          )}
          <div
            className={`statistic__item-icon ${
              !!handleResetStatCategory ? "statistic__item-icon--cursor" : ""
            }`}
            onClick={handleResetStatCategory}
          >
            {!!handleResetStatCategory ? (
              <IconContainer color={"brand"}>
                <Icons.IconArrowLeft color={"brand"} width={24} height={24} />
              </IconContainer>
            ) : (
              <IconContainer color={iconColor}>
                <Icon color={iconColor} width={24} height={24} />
              </IconContainer>
            )}
          </div>
          <div className="statistic__item-row">
            <div className="statistic__item-title" title={headerI18n}>
              <FormattedMessage id={headerI18n} defaultMessage={header} />
            </div>
          </div>
          <div className="statistic__item-row">
            <div className="statistic__item-count ">
              ${formatMoneyInt(data.default.all)}
            </div>

            {isWhatIf && (
              <div className="statistic__item-count statistic__index-count--if">
                ${formatMoneyInt(data.whatIf.all)}
              </div>
            )}
          </div>
        </div>

        {(isDetailed || isTaxes) && (
          <div
            className={`statistic__item-body ${
              !isDetailed && isTaxes ? "statistic__item-body--taxes-short" : ""
            }`}
          >
            {data.default &&
              Object.keys(restDefault).map(key => {
                const percent = parseInt(
                  (data.default[key] / data.default.all) * 100
                );
                const percentWhatIf = parseInt(
                  (data.whatIf[key] / data.whatIf.all) * 100
                );

                let transKeyFull = transKey + key;

                if (
                  transKeyFull === "DASHBOARD.STATISTIC.LABEL.ASSETS.savings" &&
                  data.default[key] < 0
                ) {
                  transKeyFull =
                    "DASHBOARD.STATISTIC.LABEL.ASSETS.SIMULATED_OVERDRAFT";
                }

                const isSubCategoryExists =
                  subCategoryState &&
                  subCategoryState[key] &&
                  handleSelectStatCategory;

                return (
                  <div className="statistic__index" key={key}>
                    <div
                      className={`statistic__index-title ${
                        isSubCategoryExists
                          ? "statistic__index-title--action"
                          : ""
                      }`}
                      onClick={
                        isSubCategoryExists
                          ? handleSelectStatCategory(key)
                          : null
                      }
                    >
                      <FormattedMessage id={transKeyFull} />
                    </div>

                    <div className="statistic__index-container">
                      <div className="statistic__index-count">
                        ${formatMoneyInt(data.default[key])}
                      </div>

                      {isWhatIf && !isShowGraph && (
                        <div className="statistic__index-count statistic__index-count--if">
                          ${formatMoneyInt(data.whatIf[key])}
                        </div>
                      )}
                    </div>

                    {isShowGraph && isDetailed && !!percent && (
                      <div
                        className={`statistic__index-progressbar statistic__index-progressbar--${graphAssetTaxCat[key].color}`}
                      >
                        <div
                          style={{
                            width: `calc((100% - 40px)*${percent / 100})`
                          }}
                          className={`statistic__index-degree statistic__index-degree--${graphAssetTaxCat[key].color}`}
                        />
                        <div className="statistic__index-percent">
                          {percent}%
                        </div>
                      </div>
                    )}

                    {isShowGraph && isWhatIf && (
                      <>
                        <div className="statistic__index-title statistic__index-title--if">
                          <FormattedMessage
                            id="DASHBOARD.STATISTIC.LABEL.WHAT_IF"
                            defaultMessage="What-If"
                          />
                        </div>

                        <div className="statistic__index-container">
                          <div className="statistic__index-count statistic__index-count--if">
                            ${formatMoneyInt(data.whatIf[key])}
                          </div>
                        </div>

                        {isShowGraph && isDetailed && !!percentWhatIf && (
                          <div
                            className={`statistic__index-progressbar statistic__index-progressbar--${graphAssetTaxCat[key].color}`}
                          >
                            <div
                              style={{
                                width: `calc((100% - 40px)*${percentWhatIf /
                                  100})`
                              }}
                              className={`statistic__index-degree statistic__index-degree--${graphAssetTaxCat[key].color}`}
                            />
                            <div className="statistic__index-percent">
                              {percentWhatIf}%
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

export const DataWithBar = ({
  transKey,
  labelI18n,
  entityKey,
  percent,
  data,
  value
}) => {
  return (
    <div className="statistic__index">
      <div className={"statistic__index-title"}>
        <FormattedMessage id={labelI18n || transKey + entityKey} />
      </div>

      <div className="statistic__index-container">
        <div className="statistic__index-count">
          ${formatMoneyInt(value || data[entityKey])}
        </div>
      </div>

      {!!percent && (
        <div
          className={`statistic__index-progressbar statistic__index-progressbar--${graphAssetTaxCat[entityKey].color}`}
        >
          <div
            style={{ width: `calc((100% - 40px)*${percent / 100})` }}
            className={`statistic__index-degree statistic__index-degree--${graphAssetTaxCat[entityKey].color}`}
          />
          <div className="statistic__index-percent">{percent}%</div>
        </div>
      )}
    </div>
  );
};

DataWithBar.propTypes = {
  transKey: PropTypes.any,
  entityKey: PropTypes.any,
  percent: PropTypes.any,
  data: PropTypes.any,
  value: PropTypes.any
};

export default Statistic;
