import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconBigInsurance = ({
  height = 76,
  width = 76,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  // const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 76 76"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M39.2256 23.3525V18.3867H36.7739V23.3525C23.1503 23.9985 12.2578 35.2514 12.2578 49.0319V51.4835H14.7094C14.7094 48.7794 16.9085 46.5803 19.6127 46.5803C22.3168 46.5803 24.5159 48.7794 24.5159 51.4835H26.9675C26.9675 48.7794 29.1666 46.5803 31.8707 46.5803C34.5748 46.5803 36.7739 48.7794 36.7739 51.4835V71.0964C36.7739 73.8005 38.973 75.9996 41.6772 75.9996C44.3813 75.9996 46.5804 73.8005 46.5804 71.0964V68.6448H44.1288V71.0964C44.1288 72.4485 43.0292 73.548 41.6772 73.548C40.3251 73.548 39.2256 72.4485 39.2256 71.0964V51.4835C39.2256 48.7794 41.4246 46.5803 44.1288 46.5803C46.8329 46.5803 49.032 48.7794 49.032 51.4835H51.4836C51.4836 48.7794 53.6827 46.5803 56.3868 46.5803C59.091 46.5803 61.2901 48.7794 61.2901 51.4835H63.7417V49.0319C63.7417 35.2514 52.8492 23.9985 39.2256 23.3525ZM24.587 46.0691C23.2766 44.8654 21.5286 44.1287 19.6127 44.1287C17.8352 44.1287 16.2037 44.7624 14.9313 45.8154C16.3263 35.7613 24.1677 27.7543 34.1311 26.0787C28.5377 31.2601 25.0969 38.4127 24.587 46.0691ZM44.1288 44.1287C41.5742 44.1287 39.3187 45.4378 37.9997 47.4224C36.6808 45.4378 34.4253 44.1287 31.8707 44.1287C30.0296 44.1287 28.3514 44.8151 27.0594 45.9379C27.6699 38.0658 31.6488 30.8005 37.9997 26.0456C44.3506 30.8005 48.3296 38.0658 48.9401 45.9379C47.6481 44.8163 45.9699 44.1287 44.1288 44.1287ZM56.3868 44.1287C54.4709 44.1287 52.7229 44.8654 51.4125 46.0703C50.9026 38.4139 47.4617 31.2614 41.8684 26.0799C51.8317 27.7543 59.6732 35.7625 61.0682 45.8166C59.7958 44.7624 58.1643 44.1287 56.3868 44.1287Z"
        fill="#07051D"
        fillOpacity="0.85"
      />
      <path d="M61.2895 0H58.8379V4.90323H61.2895V0Z" fill="#6A68FB" />
      <path d="M46.5805 0H44.1289V4.90323H46.5805V0Z" fill="#6A68FB" />
      <path d="M31.8715 0H29.4199V4.90323H31.8715V0Z" fill="#6A68FB" />
      <path d="M61.2895 14.71H58.8379V19.6132H61.2895V14.71Z" fill="#6A68FB" />
      <path
        d="M61.2895 7.35449H58.8379V12.2577H61.2895V7.35449Z"
        fill="#6A68FB"
      />
      <path
        d="M53.936 11.0322H51.4844V15.9355H53.936V11.0322Z"
        fill="#6A68FB"
      />
      <path
        d="M61.2895 22.0645H58.8379V26.9677H61.2895V22.0645Z"
        fill="#6A68FB"
      />
      <path
        d="M53.936 18.3867H51.4844V23.2899H53.936V18.3867Z"
        fill="#6A68FB"
      />
      <path
        d="M53.936 3.67773H51.4844V8.58096H53.936V3.67773Z"
        fill="#6A68FB"
      />
      <path d="M46.5805 14.71H44.1289V19.6132H46.5805V14.71Z" fill="#6A68FB" />
      <path
        d="M46.5805 7.35449H44.1289V12.2577H46.5805V7.35449Z"
        fill="#6A68FB"
      />
      <path
        d="M39.2251 11.0322H36.7734V15.9355H39.2251V11.0322Z"
        fill="#6A68FB"
      />
      <path
        d="M39.2251 3.67773H36.7734V8.58096H39.2251V3.67773Z"
        fill="#6A68FB"
      />
      <path d="M31.8715 14.71H29.4199V19.6132H31.8715V14.71Z" fill="#6A68FB" />
      <path
        d="M31.8715 7.35449H29.4199V12.2577H31.8715V7.35449Z"
        fill="#6A68FB"
      />
      <path
        d="M24.5161 11.0322H22.0645V15.9355H24.5161V11.0322Z"
        fill="#6A68FB"
      />
      <path
        d="M24.5161 18.3867H22.0645V23.2899H24.5161V18.3867Z"
        fill="#6A68FB"
      />
      <path
        d="M24.5161 3.67773H22.0645V8.58096H24.5161V3.67773Z"
        fill="#6A68FB"
      />
      <path
        d="M17.1606 7.35449H14.709V12.2577H17.1606V7.35449Z"
        fill="#6A68FB"
      />
      <path d="M17.1606 14.71H14.709V19.6132H17.1606V14.71Z" fill="#6A68FB" />
      <path
        d="M17.1606 22.0645H14.709V26.9677H17.1606V22.0645Z"
        fill="#6A68FB"
      />
      <path d="M17.1606 0H14.709V4.90323H17.1606V0Z" fill="#6A68FB" />
    </svg>
  );
};

IconBigInsurance.propTypes = {
  // strokeWidth: PropTypes.number,
  // opacity: PropTypes.number,
  // color: PropTypes.oneOf([
  //   "none",
  //   "ui",
  //   "brand",
  //   "yellow",
  //   "green",
  //   "red",
  //   "yellow",
  //   "textTetrieary",
  //   "textSecondary",
  //   "grey",
  //   "white"
  // ])
};

export default IconBigInsurance;
