import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { actionTypes } from "redux-firestore";

import { createFirestoreInstance } from "redux-firestore";

let config;
switch (process.env.REACT_APP_RUNTIME_CONFIG) {
  case "dev7":
    config = require("../config/dev7.js").default;
    break;
  case "release":
    config = require("../config/release.js").default;
    break;
  case "life":
    config = require("../config/life.js").default;
    break;
  default:
    config = require("../config/dev.js").default;
}

firebase.initializeApp(config);
firebase.firestore();
firebase.database();

const rrfConfig = {
  userProfile: "users", // firebase root where user profiles are stored
  useFirestoreForProfile: true,
  updateProfileOnLogin: true,
  // presence: "presence",
  // sessions: "sessions",

  // profileParamsToPopulate: [
  //   'contacts:users'
  // ],

  attachAuthIsReady: true,
  // enableLogging: true, // enable/disable Firebase's database logging,

  onAuthStateChanged: (authData, firebase, dispatch) => {
    // Clear redux-firestore state if auth does not exist (i.e logout)
    if (!authData) {
      dispatch({ type: actionTypes.CLEAR_DATA });
    }
  }
};

export const rrfProps = {
  firebase,
  config: rrfConfig,
  createFirestoreInstance
};
