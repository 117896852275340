// money
export const WIZARD_FAMILY = {
  AGE: 41,
  DEPENDENTS: 2
};
// money
export const WIZARD_MONEY = {
  SALARY_DEFAULT_SINGLE: 100000,
  SALARY_DEFAULT_MARRIED: 175000,
  SALARY_MIN: 60000,
  SALARY_MAX: 500000,
  RENTS_DEFAULT: 0,
  RENTS_MIN: 0,
  RENTS_MAX: 500000,
  PROPERTY_TAX_DEFAULT: 15000,
  PROPERTY_TAX_MIN: 5000,
  PROPERTY_TAX_MAX: 100000,
  PROPERTY_TAX_STEP: 1000,
  HOUSE_VALUE_DEFAULT: 500000,
  HOUSE_VALUE_MIN: 150000,
  HOUSE_VALUE_MAX: 10000000,
  HOUSE_VALUE_STEP: 10000,
  MONTHLY_RENT_DEFAULT: 3000,
  MONTHLY_RENT_MIN: 500,
  MONTHLY_RENT_MAX: 30000,
  HOA_DEFAULT: 500,
  HOA_MIN: 100,
  HOA_MAX: 35000,
  VACATION_DEFAULT: 3000,
  VACATION_MIN: 0,
  VACATION_MAX: 60000
};

// approximation
export const WIZARD_APPROXIMATION = {
  GROCERIES_DEFAULT: 600,
  GROCERIES_MIN: 250,
  GROCERIES_MAX: 3000,
  DINING_DEFAULT: 250,
  DINING_MIN: 100,
  DINING_MAX: 5000,
  DEPENDENT_DEFAULT_MULT: 1000,
  DEPENDENT_MIN: 0,
  DEPENDENT_MAX: 50000,
  MEDICAL_DEFAULT_MULT: 50,
  MEDICAL_MIN: 50,
  MEDICAL_MAX: 10000,
  TRANSPORTATION_DEFAULT: 500,
  TRANSPORTATION_MIN: 0,
  TRANSPORTATION_MAX: 5000,
  INSURANCE_DEFAULT: 0,
  INSURANCE_MIN: 0,
  INSURANCE_MAX: 5000,
  OTHER_DEFAULT: 50,
  OTHER_MIN: 0,
  OTHER_MAX: 10000
};

// Assets
export const WIZARD_ASSETS = {
  HOMES_VEHICLES_DEFAULT: {
    amount: [0, 150000, 350000, 600000, 800000],
    age: [0, 30, 36, 41, 60]
  },
  HOMES_VEHICLES_MIN: 0,
  HOMES_VEHICLES_MAX: 5000000,

  RETIREMENT_DEFAULT: {
    amount: [10000, 50000, 150000, 350000, 500000],
    age: [0, 30, 36, 41, 60]
  },
  RETIREMENT_MIN: 0,
  RETIREMENT_MAX: 1000000,

  SAVED_DEFAULT: {
    amount: [0, 25000, 100000, 200000, 0],
    age: [0, 30, 36, 41, 60]
  },
  SAVED_MIN: 0,
  SAVED_MAX: 1000000,

  DEATH_DEFAULT: {
    amount: [0, 50000, 250000, 500000, 500000],
    age: [0, 30, 36, 41, 60]
  },
  DEATH_MIN: 0,
  DEATH_MAX: 10000000,

  INVESTED_DEFAULT: {
    amount: [10000, 50000, 150000, 300000, 100000],
    age: [0, 30, 36, 41, 60]
  },
  INVESTED_MIN: 0,
  INVESTED_MAX: 5000000,

  CHECKING_SAVING_DEFAULT: {
    amount: [10000, 50000, 85000, 125000, 80000],
    age: [0, 30, 36, 41, 60]
  },
  CHECKING_SAVING_MIN: 5000,
  CHECKING_SAVING_MAX: 1000000
};

// ASSETS DEBT
export const WIZARD_ASSETS_DEBT = {
  DEBT_REMAINS_DEFAULT: {
    amount: [0, 50000, 250000, 300000, 100000],
    age: [0, 30, 36, 41, 60]
  },
  DEBT_REMAINS_MIN: 0,
  DEBT_REMAINS_MAX: 5000000,

  INTEREST_DEBT_DEFAULT: 0,
  INTEREST_DEBT_MIN: 0,
  INTEREST_DEBT_MAX: 2500000,
  INTEREST_DEBT_INTEREST_DEFAULT: "12",

  OTHER_DEBT_DEFAULT: 0,
  OTHER_DEBT_MIN: 0,
  OTHER_DEBT_MAX: 200000,
  OTHER_DEBT_STEP: 1000,
  OTHER_DEBT_INTEREST_DEFAULT: "6"
};

export const WIZARD_DEFAULTS_DEFAULT = {
  meta: {
    title: "Default",
    description: "Default data"
  },
  family: WIZARD_FAMILY,
  money: WIZARD_MONEY,
  approximation: WIZARD_APPROXIMATION,
  assets: WIZARD_ASSETS,
  assetsDebt: WIZARD_ASSETS_DEBT
};
