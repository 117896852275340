import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { styles } from "./styles";

import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl-v3";
import * as ROUTES from "../../constants/routes";
import DialogWelcome from "./DialogWelcome";
import DialogWhyNeed from "./DialogWhyNeed";

import { questions } from "constants/interests_quiz";

class Quiz extends Component {
  static propTypes = {
    auth: PropTypes.object,
    profile: PropTypes.object,
    firebase: PropTypes.object,
    firestore: PropTypes.object,

    classes: PropTypes.object
  };

  state = {
    savedMoney: null,
    planEvents: null,
    unexpectedEvents: null,
    needPlanning: null,
    needAdvice: null,

    dialogWelcomeIsOpen: false,
    dialogWhyNeedItOpen: false,
    isReadyMoveFurther: false
  };

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (
      this.props.profile.isLoaded &&
      !prevProps.profile.isLoaded &&
      !this.props.profile.notNewUser
    ) {
      this.handleFirstOpen();
    }
  }

  handleMoveToDashboard = () => {
    this.setState({
      isReadyMoveFurther: true
    });
  };

  handleUpdateUser = () => {
    const {
      firestore,
      auth: { uid }
    } = this.props;

    const {
      savedMoney,
      planEvents,
      unexpectedEvents,
      needPlanning,
      needAdvice
    } = this.state;

    firestore
      .update(
        { collection: "users", doc: uid },
        {
          interests: {
            savedMoney,
            planEvents,
            unexpectedEvents,
            needPlanning,
            needAdvice
          }
        }
      )
      .then(() => {
        this.handleMoveToDashboard();
      })
      .catch(err => {
        // TODO HANDLE ERRORS for update
        console.log("---", err);
      });
  };

  handleFirstOpen = () => {
    const {
      firestore,
      auth: { uid }
    } = this.props;

    firestore.update({ collection: "users", doc: uid }, { notNewUser: true });

    this.setState({
      dialogWelcomeIsOpen: true
    });
  };

  handleChangeInput = ev => {
    const { name, value } = ev.target;

    this.setState({
      [name]: value
    });
  };

  handleOpenDialogWhyNeedIt = () => {
    this.setState({
      dialogWhyNeedItOpen: true
    });
  };

  handleCloseDialogs = () => {
    this.setState({ dialogWhyNeedItOpen: false, dialogWelcomeIsOpen: false });
  };

  _renderQuestion = id => {
    const { classes } = this.props;

    const question = questions[id];

    return (
      <Card className={classes.card} key={id}>
        <CardContent className={classes.cardContent}>
          <FormattedMessage
            id={question.i18nId}
            defaultMessage={question.defaultMessage}
          />
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="Gender"
              name={question.name}
              className={classes.radioGroup}
              value={this.state[question.name]}
              onChange={this.handleChangeInput}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={<FormattedMessage id="QUIZ.YES" defaultMessage="Yes" />}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={<FormattedMessage id="QUIZ.NO" defaultMessage="No" />}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  isAllQuestionsAnswered = () => {
    const {
      savedMoney,
      planEvents,
      unexpectedEvents,
      needPlanning,
      needAdvice
    } = this.state;

    return (
      savedMoney && planEvents && unexpectedEvents && needPlanning && needAdvice
    );
  };

  render() {
    const { profile, classes } = this.props;

    const isAllQuestionsAnswered = this.isAllQuestionsAnswered();

    return (
      <>
        {this.state.isReadyMoveFurther && <Redirect to={ROUTES.DASHBOARD} />}

        <DialogWelcome
          open={this.state.dialogWelcomeIsOpen}
          onClose={this.handleCloseDialogs}
          name={profile.firstName}
        />

        <DialogWhyNeed
          open={this.state.dialogWhyNeedItOpen}
          onClose={this.handleCloseDialogs}
        />

        <main className={classes.main}>
          <div className={classes.logoTop}>
            <img src="/assets/img/logo.png" alt="" />
          </div>

          <CssBaseline />
          <Paper className={classes.paper}>
            <Typography className={classes.heading} component="h1" variant="h5">
              <FormattedMessage
                id="QUIZ.HEADING"
                defaultMessage="We’re excellent at predicting the future. Reading minds, not so much"
              />
            </Typography>

            <Typography
              className={classes.subheading}
              component="p"
              variant="subheading"
            >
              <FormattedMessage
                id="QUIZ.HEADING2"
                defaultMessage="These are the most common reasons people financial advice. What’s your’s?"
              />
            </Typography>

            <Typography
              className={classes.text}
              component="p"
              variant="subtitle1"
            >
              <FormattedMessage
                id="QUIZ.CAPTION"
                defaultMessage="These are the most common reasons people financial advice. What’s your’s?"
              />
            </Typography>

            <Typography className={classes.tipsButton}>
              <Button onClick={this.handleOpenDialogWhyNeedIt}>
                <FormattedMessage
                  id="QUIZ.WHY_NEED_IT.BUTTON"
                  defaultMessage="Why do we need this information?"
                />
              </Button>
            </Typography>

            {Object.keys(questions).map(key => this._renderQuestion(key))}

            {!isAllQuestionsAnswered && (
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleMoveToDashboard}
              >
                <FormattedMessage id="QUIZ.BUTTON.SKIP" />
              </Button>
            )}

            {!!isAllQuestionsAnswered && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleUpdateUser}
              >
                <FormattedMessage id="QUIZ.BUTTON.CONTINUE" />
              </Button>
            )}
          </Paper>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(Quiz);
