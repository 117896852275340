import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconSupport = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M5.90033 2.19921L10.0019 6.30078C10.0019 6.30078 10.0019 7.5 8.80267 8.80078C7.50189 10 6.30267 10 6.30267 10L2.20111 5.89843C1.60345 8.19921 2.40033 11 4.10345 12.6992C6.70111 15.3008 9.90033 13 10.6035 13.6992C14.2011 17.3008 17.1035 21 17.3027 21.1992C18.4003 22.3008 20.2011 22.3008 21.2011 21.3008C22.3027 20.1992 22.2011 18.5 21.1035 17.3984C21.0019 17.3008 17.1035 14.1992 13.5019 10.6016C12.8027 9.89843 15.3027 6.80078 12.6035 4.10156C11.0019 2.39843 8.20111 1.60156 5.90033 2.19921ZM20.2011 19C20.2011 19.6992 19.7011 20.1992 19.0019 20.1992C18.3027 20.1992 17.8027 19.6992 17.8027 19C17.8027 18.3008 18.3027 17.8008 19.0019 17.8008C19.7011 17.8008 20.2011 18.3008 20.2011 19Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

IconSupport.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSupport;
