import React from "react";
import PropTypes from "prop-types";

import { colors } from "constants/styles";

const IconGoal = ({
  height = 24,
  width = 24,
  color = "textTetrieary",
  iconTitle = "",
  opacity = 1
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <g opacity={opacity}>
        <path
          d="M10.9994 3V7.7168L2.46035 17.7617L2.44472 17.7812C1.47257 19.0454 2.436 21 4.03066 21H19.9682C21.5628 21 22.5244 19.0455 21.5541 17.7812L21.5404 17.7617L12.9994 7.7168V6L15.9994 4.58594L12.9994 3H10.9994ZM11.9994 9.62891L15.3295 13.5449L14.0287 15L11.9994 13L10.9994 14L9.5541 12.5039L11.9994 9.62891Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

IconGoal.propTypes = {
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconGoal;
