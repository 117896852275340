import React, { Component } from "react";
// import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl-v3";
import { formatMoneyIntWithSign } from "../../lib/helpers";
import HouseholdCard from "../HouseholdMembers/HouseholdCard";
import Grid from "@material-ui/core/Grid";
import * as Icons from "../_common/icons";

class DashboardAdvisorOverall extends Component {
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   return !isEqual(this.props, nextProps);
  // }

  _renderOverallStat = () => {
    const { firstYear, members, data } = this.props;

    let kids = null;
    if (members) {
      let kidsAges = [];
      let kidsCount = 0;

      Object.keys(members).forEach(memberKey => {
        if (memberKey !== "You" && memberKey !== "Spouse") {
          kidsCount++;

          kidsAges.push(members[memberKey].age);
        }
      });

      kids = kidsCount ? (
        <span>
          {kidsCount}{" "}
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.KIDS"
            defaultMessage="kids"
          />
          : {kidsAges.join(", ")}
        </span>
      ) : (
        <FormattedMessage
          id="MY_ADVISOR.STAT.LABEL.NO_KIDS"
          defaultMessage="No kids"
        />
      );
    }

    const productsUser = data.products.You.all ? (
      <>
        {!!data.products.You.IRA && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconRetirement height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.IRA"
                defaultMessage="IRA"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.IRA)}
            </div>
          </div>
        )}
        {!!data.products.You["401K"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconRetirement height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.401K"
                defaultMessage="401K"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You["401K"])}
            </div>
          </div>
        )}
        {!!data.products.You.lifeInsurance && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconUmbrella height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE"
                defaultMessage="Life Insurance"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.lifeInsurance)}
            </div>
          </div>
        )}

        {!!data.products.You.annuity && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconDeferredComp height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.ANNUITY"
                defaultMessage="Annuity"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.You.annuity)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    const productsSpouse = data.products.Spouse.all ? (
      <>
        {!!data.products.Spouse.IRA && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconRetirement height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.IRA"
                defaultMessage="IRA"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.IRA)}
            </div>
          </div>
        )}
        {!!data.products.Spouse["401K"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconRetirement height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.401K"
                defaultMessage="401K"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse["401K"])}
            </div>
          </div>
        )}
        {!!data.products.Spouse.lifeInsurance && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconUmbrella height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE"
                defaultMessage="Life Insurance"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.lifeInsurance)}
            </div>
          </div>
        )}

        {!!data.products.Spouse.annuity && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconDeferredComp height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.ANNUITY"
                defaultMessage="Annuity"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.Spouse.annuity)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    const productsOther = data.products.other.all ? (
      <>
        {!!data.products.other["529college"] && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconStudent height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.529_FUND"
                defaultMessage="529 Fund"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.other["529college"])}
            </div>
          </div>
        )}
        {!!data.products.other.other && (
          <div className="my-advisor__item-content">
            <div className="my-advisor__item-label">
              <Icons.IconOther height={24} width={24} color={"ui"} />

              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.OTHER"
                defaultMessage="Other"
              />
              {`: `}
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.products.other.other)}
            </div>
          </div>
        )}
      </>
    ) : (
      <FormattedMessage
        id="MY_ADVISOR.STAT.LABEL.NO_PRODUCT_INFO"
        defaultMessage="No product information"
      />
    );

    return (
      <HouseholdCard
        fullWidth
        // style={{
        //   borderRadius: "4px 0 0 4px",
        //   borderRight: `1px solid ${colors.divider}`,
        //   boxShadow: "none"
        // }}
        classNames="my-advisor__item-container my-advisor__item-container--first"
      >
        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRIMARY_PROVIDER"
                defaultMessage="Primary provider"
              />
            </div>
            <div className="my-advisor__item-value">
              {members && members.You && (
                <span>
                  {members.You.name}: {members.You.age}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.SECONDARY_PROVIDER"
                defaultMessage="Secondary provider"
              />
            </div>
            <div className="my-advisor__item-value">
              {members && members.Spouse && (
                <span>
                  {members.Spouse.name}: {members.Spouse.age}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.FAMILY"
                defaultMessage="Family"
              />
            </div>
            <div className="my-advisor__item-value">{kids}</div>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.GROSS_INCOME"
                defaultMessage="Gross income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.lifeInsurance.userAnnualIncome)}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.GROSS_INCOME"
                defaultMessage="Gross income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(data.lifeInsurance.spouseAnnualIncome)}
            </div>
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PASSIVE_INCOME"
                defaultMessage="Passive Income"
              />
            </div>
            <div className="my-advisor__item-value">
              {formatMoneyIntWithSign(
                firstYear ? firstYear.MoneyIn.all - firstYear.MoneyIn.salary : 0
              )}
            </div>
          </Grid>
        </Grid>

        <div className="my-advisor__item-content-half-divider" />

        <Grid
          container
          item
          xs={12}
          className="my-advisor__container"
          spacing={32}
        >
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--ui">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>
            {productsUser}
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--ui">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>

            {productsSpouse}
          </Grid>
          <Grid item xs={4} lg={4} className="my-advisor__item">
            <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--ui ">
              <FormattedMessage
                id="MY_ADVISOR.STAT.LABEL.PRODUCTS"
                defaultMessage="Products"
              />
            </div>

            {productsOther}
          </Grid>
        </Grid>
      </HouseholdCard>
    );
  };

  _renderMajorOther = () => {
    const { firstYear } = this.props;

    const monthlyIncome = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--green">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_INCOME"
            defaultMessage="Monthly Income"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.all : 0)}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.JOBS"
              defaultMessage="Jobs"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.salary : 0)}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.RENTS"
              defaultMessage="Rents"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.realEstate : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.OTHER"
              defaultMessage="Other"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyIn.other : 0)}
          </div>
        </div>
      </>
    );

    const monthlyExpenses = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--red">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_EXPENSES"
            defaultMessage="Monthly Expenses"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.MoneyOut.other +
                    firstYear.MoneyOut.asset +
                    firstYear.MoneyOut.personal
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LIVING"
              defaultMessage="Living"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyOut.personal : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.ASSETS"
              defaultMessage="Assets"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyOut.asset : 0)}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.OTHER"
              defaultMessage="Other"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(firstYear ? firstYear.MoneyOut.other : 0)}
          </div>
        </div>
      </>
    );

    const monthlyNetIncome = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--yellow">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MONTHLY_NET_INCOME"
            defaultMessage="Monthly Net Income"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.all - firstYear.MoneyOut.all : 0
            )}
          </span>
        </div>
      </>
    );

    return (
      <HouseholdCard
        fullWidth
        style={{
          borderRadius: "0 4px 4px 0",
          boxShadow: "none"
        }}
        classNames="my-advisor__item-container my-advisor__item-container--third"
      >
        {monthlyIncome}

        <div className="my-advisor__item-content-divider" />

        {monthlyExpenses}

        <div className="my-advisor__item-content-divider" />

        {monthlyNetIncome}
      </HouseholdCard>
    );
  };

  _renderMajorAssets = () => {
    const { firstYear } = this.props;

    const liquidAssets = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--green">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.LIQUID_ASSETS"
            defaultMessage="Liquid Assets"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.AssetsDetail.cash.accounts +
                    firstYear.Assets.investments
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.CASH"
              defaultMessage="Cash"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.AssetsDetail.cash.accounts : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.BROKERAGE"
              defaultMessage="Brokerage"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Assets.investments : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LIFE_INSURANCE_CASH"
              defaultMessage="Life Insurance Cash"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.AssetsDetail.cash.lifeInsurance : 0
            )}
          </div>
        </div>
      </>
    );

    const illiquidAssets = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--red">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.ILLIQUID_ASSETS"
            defaultMessage="Illiquid Assets"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.MoneyIn.realEstate + firstYear.Assets.retirement
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.REAL_ESTATE"
              defaultMessage="Real Estate"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.MoneyIn.realEstate : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.RETIREMENT_SAVINGS"
              defaultMessage="Retirement & Savings"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Assets.retirement : 0
            )}
          </div>
        </div>

        {/*<div className="my-advisor__item-content">*/}
        {/*  <div className="my-advisor__item-label">*/}
        {/*    <FormattedMessage*/}
        {/*      id="MY_ADVISOR.STAT.LABEL.BUSINESS_INTEREST"*/}
        {/*      defaultMessage="Business Interest"*/}
        {/*    />*/}
        {/*    {`: `}*/}
        {/*  </div>*/}
        {/*  <div className="my-advisor__item-value">$250K</div>*/}
        {/*</div>*/}
      </>
    );

    const majorLiabilities = (
      <>
        <div className="my-advisor__item-title my-advisor__item-title--primary my-advisor__item-title--yellow">
          <FormattedMessage
            id="MY_ADVISOR.STAT.LABEL.MAJOR_LIABILITIES"
            defaultMessage="Major Liabilities"
          />

          <span className="my-advisor__item-title-value">
            {formatMoneyIntWithSign(
              firstYear
                ? firstYear.Liabilities.loans + firstYear.Liabilities.mortgage
                : 0
            )}
          </span>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.MORTGAGES"
              defaultMessage="Mortgages"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Liabilities.mortgage : 0
            )}
          </div>
        </div>

        <div className="my-advisor__item-content">
          <div className="my-advisor__item-label">
            <FormattedMessage
              id="MY_ADVISOR.STAT.LABEL.LOANS"
              defaultMessage="Loans"
            />
            {`: `}
          </div>
          <div className="my-advisor__item-value">
            {formatMoneyIntWithSign(
              firstYear ? firstYear.Liabilities.loans : 0
            )}
          </div>
        </div>
      </>
    );

    return (
      <HouseholdCard
        fullWidth
        // style={{
        //   borderRadius: 0,
        //   borderRight: `1px solid ${colors.divider}`,
        //   boxShadow: "none"
        // }}
        classNames="my-advisor__item-container my-advisor__item-container--second"
      >
        {liquidAssets}

        <div className="my-advisor__item-content-divider" />

        {illiquidAssets}

        <div className="my-advisor__item-content-divider" />

        {majorLiabilities}
      </HouseholdCard>
    );
  };

  render() {
    const { containerWidth } = this.props;

    return (
      <Grid
        className="graphics"
        container
        spacing={0}
        style={{ maxWidth: containerWidth }}
      >
        <Grid item container xs={12} lg={6} className="statistic__grid-item">
          {this._renderOverallStat()}
        </Grid>

        <Grid item container xs={12} lg={3} className="statistic__grid-item">
          {this._renderMajorAssets()}
        </Grid>

        <Grid item container xs={12} lg={3} className="statistic__grid-item">
          {this._renderMajorOther()}
        </Grid>
      </Grid>
    );
  }
}

DashboardAdvisorOverall.propTypes = {};

export default DashboardAdvisorOverall;
