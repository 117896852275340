import { createMuiTheme } from "@material-ui/core/styles";
import { colors } from "constants/styles";

const defaultTheme = createMuiTheme();

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["PT Root UI"].join(","),
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 12
    },
    button: {
      textTransform: "unset"
    }
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        fontSize: 14,
        color: colors.textSecondary
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 0,
        marginBottom: 16
      }
    },

    MuiInputLabel: {
      root: {
        color: colors.textSecondary,
        "&$focused": {
          color: colors.ui
        }
      }
    },
    MuiFormLabel: {
      required: {
        color: colors.red
      },
      asterisk: {
        color: colors.red
      }
    },
    MuiFormControlSelect: {
      minWidth: 80
    },
    MuiTooltip: {
      tooltip: {
        padding: "10px 16px",
        fontSize: 14,
        lineHeight: "21px",

        display: "flex",
        alignItems: "center",
        letterSpacing: "0.5px",

        color: colors.white,

        background: "rgba(7, 5, 29, 0.85)",

        boxShadow: "0px 4px 16px rgba(30, 50, 90, 0.2)",
        borderRadius: 4
      }
    },
    FormLabelClasses: {
      root: {}
    },
    MuiSlider: {
      thumb: {
        backgroundColor: colors.brand
      },
      trackBefore: {
        backgroundColor: colors.brand
      },
      trackAfter: {
        backgroundColor: colors.inputUnderline
      }
    },

    MuiInput: {
      error: {
        "&:hover:not(.important):not(.important):not(.important):not(.important):not(.important):after": {
          height: 1,
          borderBottomColor: colors.red,
          borderBottomWidth: 2
        },
        "&:not(.important):not(.important):not(.important):not(.important):not(.important):after": {
          height: 1,
          borderBottomColor: colors.red,
          borderBottomWidth: 1
        }
      },
      underline: {
        "&:not(.important):not(.important):not(.important):not(.important):before": {
          borderColor: colors.inputUnderline,
          backgroundColor: colors.divider,
          borderBottomWidth: 1,
          height: 1
        },
        "&:hover:not(.important):not(.important):not(.important):not(.important):after": {
          borderColor: colors.ui,
          backgroundColor: colors.ui,
          borderBottom: `1px solid ${colors.ui}`,
          height: 1
        },
        "&:hover:not(.important):not(.important):not(.important):not(.important):before": {
          borderColor: colors.ui,
          backgroundColor: colors.ui,
          borderBottomWidth: 1,
          height: 1
        },
        "&:not(.$MuiInput-error):not(.important):not(.important):after": {
          borderColor: colors.brand,
          borderBottomWidth: 1,
          height: 1
        }
      }
    },
    MuiInputAdornment: {
      root: {
        MuiTypography: {
          colorTextSecondary: {
            "&:not(.important)": {
              color: colors.textPrimary
            }
          }
        }
      },
      positionStart: {
        marginRight: "2px"
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent"
        }
      },
      icon: {
        color: colors.grey
      }
    },
    MuiMenu: {
      paper: {
        maxHeight: "50vh"
      }
    },
    MuiCheckbox: {
      root: {
        "&$checked:not(.important)": {
          color: colors.brand
        },
        "&$checked$disabled:not(.important)": {
          opacity: 0.6
        }
      },
      checked: {}
    },

    MuiSwitch: {
      colorSecondary: {
        color: colors.brand
      },
      checked: {
        "&:not(.important):not(.important):not(.important)": {
          color: colors.brand
        }
      },
      iconChecked: {
        "&:not(.important)": {
          color: colors.brand
        }
      },

      // "&.colorSecondary$checked": {
      //   color: colors.brand
      // },
      bar: {
        "&:not(.important):not(.important)": {
          backgroundColor: colors.brand
        },
        ".MuiSwitch.checked  + &:not(.important):not(.important):not(.important)": {
          backgroundColor: colors.brand
        }
      }
    },

    MuiMenuItem: {
      root: {
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        color: colors.textPrimary
      }
    },
    "@media (hover: none)": {
      MuiListItem: {
        button2: {
          "&:hover": {
            "@media (hover: none)": {
              backgroundColor: "transparent",
              textDecoration: "none"
            }
          }
        }
        // button: {
        //   textDecoration: "none",
        //
        //   "&:hover": {
        //     textDecoration: "none",
        //     backgroundColor: "transparent",
        //
        //     transition: "none",
        //
        //     // Reset on touch devices, it doesn't add specificity
        //     "@media (hover: none)": {
        //       backgroundColor: "transparent",
        //       textDecoration: "none"
        //     },
        //     "@media not (hover: none)": {
        //       backgroundColor: "transparent",
        //       textDecoration: "none"
        //     }
        //   }
        // }
      }
    },

    MuiPickersModal: {
      dialogRoot: {
        [defaultTheme.breakpoints.down("md")]: {
          marginTop: "55px"
        }
      },
      dialog: {
        minHeight: "305px"
      }
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: "calc(100% - 55px)"
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        height: "70px",
        backgroundColor: colors.brand
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        fontSize: "18px"
      }
    }
  }
});
