export function getWizardDefault(value, arrayCondition, arrayValues) {
  let result = 0;

  for (let key = 0; key < arrayCondition.length; key++) {
    const sum = arrayCondition[key];

    if (value < sum) {
      break;
    }

    result = arrayValues[key];
  }

  return result;
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatMoneyIntWithSign(
  amount,
  { isNotShowCurrency = false } = {}
) {
  const res = amount < 0 ? formatMoneyInt(amount * -1) : formatMoneyInt(amount);

  if (amount < 0) {
    if (isNotShowCurrency) {
      return `-${res}`;
    }

    return `-$${res}`;
  }

  if (isNotShowCurrency) {
    return res;
  }

  return `$${res}`;
}

export function formatMoneyInt(amount) {
  return formatMoney(amount, 0);
}

export function trimString(string = "", length = 80) {
  return string.length > length ? string.substring(0, length) + "..." : string;
}

export function ie11() {
  // IE 11 polyfills
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector;
  }
  if (!Element.prototype.closest) {
    Element.prototype.closest = function(css) {
      let node = this;

      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
  }
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function cloneObject(obj) {
  const clone = {};
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (obj[i] != null && typeof obj[i] == "object") {
        clone[i] = cloneObject(obj[i]);
      } else {
        clone[i] = obj[i];
      }
    }
  }
  return clone;
}

export function cloneObjectWithArray(obj) {
  const clone = {};
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (
        obj[i] != null &&
        typeof obj[i] == "object" &&
        !Array.isArray(obj[i])
      ) {
        clone[i] = cloneObject(obj[i]);
      } else {
        clone[i] = obj[i];
      }
    }
  }
  return clone;
}

export function getIsAccessEditView(
  globalType,
  entitiesScheme,
  rights,
  { auth, profileProvider, isEdit = false } = {}
) {
  const entityScheme = entitiesScheme[globalType];
  const isOwnProfile = profileProvider === auth.uid;

  if (isOwnProfile) {
    return true;
  }

  if (
    rights &&
    (rights.mainAccess !== "1" ||
      !rights[entityScheme.rightShare] ||
      !rights[entityScheme.rightShare] ||
      rights[entityScheme.rightLevel] !== "1")
  ) {
    return false;
  }

  if (isEdit && rights && rights[entityScheme.rightLevel] !== "1") {
    return false;
  }

  return true;
}

export function debounce(f, ms) {
  let isCooldown = false;

  return function() {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}

function desc(aOriginal, bOriginal, orderBy, orderType) {
  let a = aOriginal[orderBy];
  let b = bOriginal[orderBy];

  if (
    orderType === "date" ||
    orderBy === "lastViewed" ||
    orderBy === "lastLogin"
  ) {
    a = (aOriginal[orderBy] && aOriginal[orderBy].seconds) || 0;
    b = (bOriginal[orderBy] && bOriginal[orderBy].seconds) || 0;
  }

  if (orderType === "dateString") {
    a =
      (aOriginal &&
        aOriginal.metadata &&
        aOriginal.metadata[orderBy] &&
        new Date(aOriginal.metadata[orderBy])) ||
      0;
    b =
      (bOriginal &&
        bOriginal.metadata &&
        bOriginal.metadata[orderBy] &&
        new Date(bOriginal.metadata[orderBy])) ||
      0;
  }

  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy, orderType) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy, orderType)
    : (a, b) => -desc(a, b, orderBy, orderType);
}

export const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export function roundToThousand(value) {
  return Math.round(value / 1000) * 1000;
}
