export function emailValid(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// const REQUIRED_STRENGTH = 53;
export function calcPasswordStrength(pass) {
  let score = 0;

  if (!pass) {
    return score;
  }
  const letters = {};

  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 6.0 / letters[pass[i]];
  }

  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += variations[check] ? 1 : 0;
  }

  score += parseInt((variationCount - 1) * 10);

  return score > 100 ? 100 : score;
}
