import React from "react";

import { colors } from "constants/styles";

const IconLogoMobile = ({
  height = 16,
  width = 89,
  color = "white",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 89 16"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M22.7 15.796C26.264 15.796 28.53 13.53 28.97 10.428H26.066C25.648 12.166 24.482 13.332 22.7 13.332C20.412 13.332 18.762 11.682 18.762 8.47V7.326C18.762 4.07 20.368 2.464 22.7 2.464C24.526 2.464 25.626 3.564 26.066 4.928H28.97C28.53 2.244 26.286 0 22.7 0C18.696 0 15.99 2.706 15.99 7.326V8.47C15.99 13.134 18.652 15.796 22.7 15.796Z"
        fill={fill}
      />
      <path
        d="M38.5283 15.796C42.3563 15.796 44.6883 13.464 44.6883 9.328V0.198H42.0483V9.328C42.0483 12.034 40.7283 13.332 38.5283 13.332C36.3503 13.332 35.0083 11.99 35.0083 9.328V0.198H32.3683V9.328C32.3683 13.508 34.6563 15.796 38.5283 15.796Z"
        fill={fill}
      />
      <path
        d="M57.5357 15.598H60.5277L57.2277 9.306C59.1637 8.58 60.0877 7.04 60.0877 4.994C60.0877 2.002 58.2837 0.198 54.1697 0.198H48.8677V15.598H51.5077V9.79H54.6977L57.5357 15.598ZM54.1697 2.53C56.3697 2.53 57.3157 3.476 57.3157 4.994C57.3157 6.49 56.3477 7.458 54.1697 7.458H51.5077V2.53H54.1697Z"
        fill={fill}
      />
      <path
        d="M67.6806 15.598H70.7606L76.0406 0.198H73.2686L69.2206 12.518L64.8206 0.198H61.9606L67.6806 15.598Z"
        fill={fill}
      />
      <path
        d="M79.0041 15.598H88.9041V13.266H81.6441V8.844H87.5841V6.512H81.6441V2.53H88.9041V0.198H79.0041V15.598Z"
        fill={fill}
      />
      <path
        d="M6.05655 3.72837L4.07625 0.559898C3.93554 0.334765 3.68878 0.197998 3.42329 0.197998H0.694698C0.392305 0.197998 0.20795 0.530619 0.368218 0.787048L4.13155 6.80837L6.05655 3.72837Z"
        fill={fill}
      />
      <path
        d="M7.41858 12.0676L9.34358 8.98762L13.1069 15.0089C13.2672 15.2654 13.0828 15.598 12.7804 15.598H10.0518C9.78634 15.598 9.53958 15.4612 9.39887 15.2361L7.41858 12.0676Z"
        fill={fill}
      />
      <path
        d="M7.5845 3.46279C8.85387 1.43181 11.08 0.197998 13.475 0.197998L5.89049 12.3332C4.62113 14.3642 2.39503 15.598 0 15.598L7.5845 3.46279Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconLogoMobile;
