import React from "react";

import Login from "./Login";
import { withFirebase, withFirestore } from "react-redux-firebase";
import {
  getAuthExpireInState,
  getAuthIsEmptyState,
  getAuthIsLoadedState,
  getProfileIsAdminApprovedState,
  getProfileIsLoadedState
} from "store/selectors/firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { bootData } from "AC/ui";

const LoginConnected = props => {
  return <Login {...props} />;
};

function mapStateToProps(state) {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    isLoaded: getAuthIsLoadedState(state),
    isEmpty: getAuthIsEmptyState(state),
    expires: getAuthExpireInState(state),
    isAdminApproved: getProfileIsAdminApprovedState(state),
    isLoadedProfile: getProfileIsLoadedState(state),
    firestoreStatus: state.firestore.status
  };
}

export default compose(
  connect(
    mapStateToProps,
    { bootData }
  ),
  withFirestore,
  withFirebase
)(LoginConnected);
