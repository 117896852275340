import React from "react";

import { colors } from "constants/styles";
import PropTypes from "prop-types";

const IconSaving = ({
  height = 24,
  width = 24,
  color = "brand",
  iconTitle = ""
}) => {
  const fill = colors[color] || colors.brand;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>{iconTitle}</title>
      <path
        d="M10.2548 2C8.73499 2 7.5032 3.2151 7.5032 4.71429C7.5032 5.41276 7.77863 6.04248 8.21797 6.52381H12.2916C12.731 6.04248 13.0064 5.41276 13.0064 4.71429C13.0064 3.2151 11.7746 2 10.2548 2ZM19.4268 6.52381L16.7325 8.11951C16.0296 7.69001 15.2379 7.32749 14.3553 7.05924L14.1332 7.71484C14.0084 8.08399 13.6579 8.33333 13.2626 8.33333H7.24703C6.85171 8.33333 6.50114 8.08489 6.3764 7.71484L6.30475 7.50102C4.73251 8.31664 3.66372 9.58987 3.19308 11.09C3.10385 11.0621 3.01081 11.0478 2.9172 11.0476C2.67394 11.0476 2.44065 11.1429 2.26864 11.3126C2.09663 11.4823 2 11.7124 2 11.9524C2 12.1923 2.09663 12.4225 2.26864 12.5921C2.44065 12.7618 2.67394 12.8571 2.9172 12.8571C2.9172 13.9105 3.27654 14.984 3.90247 15.9726L4.62441 20.2437C4.69778 20.6807 5.08056 21 5.52907 21H7.64293C8.09144 21 8.47421 20.6807 8.54759 20.2437L8.65686 19.5969C9.58634 19.9096 10.5842 20.0952 11.6306 20.0952C12.2676 20.0952 12.8866 20.0429 13.4847 19.9486L13.8573 20.56C14.0233 20.8332 14.3209 21 14.6437 21H16.6752C17.2539 21 17.6873 20.4786 17.5745 19.9185L17.3004 18.5649C18.2194 17.9912 18.9685 17.2802 19.4949 16.4762H20.5446C20.9656 16.4762 21.3331 16.1941 21.435 15.7906L21.9724 13.67C22.0889 13.2113 21.8272 12.7414 21.3723 12.5921L20.0914 12.1715C19.8435 11.3764 19.4147 10.6012 18.8195 9.89193C19.1538 9.03514 19.4268 7.91663 19.4268 6.52381ZM17.5924 11.9524C18.0987 11.9524 18.5096 12.3577 18.5096 12.8571C18.5096 13.3566 18.0987 13.7619 17.5924 13.7619C17.0861 13.7619 16.6752 13.3566 16.6752 12.8571C16.6752 12.3577 17.0861 11.9524 17.5924 11.9524Z"
        fill={fill}
      />
    </svg>
  );
};

IconSaving.propTypes = {
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.oneOf([
    "none",
    "ui",
    "brand",
    "yellow",
    "green",
    "red",
    "yellow",
    "textTetrieary",
    "textSecondary",
    "grey",
    "white"
  ])
};

export default IconSaving;
